import { TEMPLATES_TYPES } from 'consts/templatesConsts'
import { groupBy } from 'lodash'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

export default ({ isHiUser }) => {
  const { files: practiceReducerSavedFiles } = useSelector(state => state.practiceReducer.savedFiles)
  const multiPracticeReducerSavedFiles = useSelector(state => state.multiPracticeReducer.context.savedFiles)

  const systemFiles = practiceReducerSavedFiles.filter(file => file.type === TEMPLATES_TYPES.SYSTEM_FILE)

  const files = useMemo(
    () => (isHiUser ? [...multiPracticeReducerSavedFiles, ...systemFiles] : practiceReducerSavedFiles),
    [isHiUser, multiPracticeReducerSavedFiles, practiceReducerSavedFiles, systemFiles]
  )

  const allFiles = useMemo(
    () => [
      ...files.map(file => ({
        ...file,
        name: file.title
      }))
    ],
    [files]
  )

  const practiceFiles = useMemo(() => allFiles.filter(file => file.type === TEMPLATES_TYPES.FILE), [allFiles])
  const systemFilesByCategory = useMemo(
    () =>
      groupBy(
        allFiles.filter(file => file.type === TEMPLATES_TYPES.SYSTEM_FILE),
        systemFile => systemFile.category
      ),
    [allFiles]
  )

  return {
    practiceFiles,
    systemFilesByCategory
  }
}
