import React, { useState, useMemo } from 'react'
import { makeStyles } from '@material-ui/core'
import MessageTypeWrapper from '../MessageTypeWrapper'
import MessageImageViewer from './Common/MessageImageViewer'

const useStyles = makeStyles(() => ({
  bubble: {
    padding: '8px 15px 5px 15px !important'
  },
  message: {
    alignItems: 'center'
  },
  messageUserInfo: {
    marginLeft: 5
  },
  preview: {
    marginBottom: 20
  },
  timestamp: {
    marginTop: 5,
    alignSelf: 'flex-end',
    color: 'rgba(0, 19, 53, 0.45)'
  }
}))

const ImageV2Message = ({
  id,
  content,
  isLocal,
  cache,
  alignLeft,
  name,
  userType,
  isFirstMessage,
  isLastMessage,
  timestamp,
  withMessageActions
}) => {
  const classes = useStyles()
  const jsonContent = useMemo(() => JSON.parse(content), [content])

  const [isImageViewerOpen, setImageViewerState] = useState(false)

  return (
    <MessageTypeWrapper
      isFirstMessage={isFirstMessage}
      isLastMessage={isLastMessage}
      alignLeft={alignLeft}
      name={name}
      userType={userType}
      className={classes.bubble}
      infoClassName={classes.messageUserInfo}
      id={id}
      withMessageActions={withMessageActions}
    >
      <MessageImageViewer
        isLocal={isLocal}
        image={jsonContent.s3Object}
        text={jsonContent.text}
        isImageViewerOpen={isImageViewerOpen}
        setImageViewerState={setImageViewerState}
        cache={cache}
        timestamp={timestamp}
        alignLeft={alignLeft}
        messageClassName={classes.message}
        timestampClassName={classes.timestamp}
        previewClassName={classes.preview}
        name={name}
      />
    </MessageTypeWrapper>
  )
}

export default React.memo(ImageV2Message)
