import clinical from './clinical'
import missingInfo from './missingInfo'
import other from './other'
import scheduling from './scheduling'
import patientQuestion from './patientQuestion'
import { NotifyPracticeReasons } from 'consts/giConsts'
import specificMembersAttention from './specificMembersAttention'

export default {
  [NotifyPracticeReasons.Clinical]: clinical,
  [NotifyPracticeReasons.MissingInfo]: missingInfo,
  [NotifyPracticeReasons.Other]: other,
  [NotifyPracticeReasons.Scheduling]: scheduling,
  [NotifyPracticeReasons.PatientQuestion]: patientQuestion,
  [NotifyPracticeReasons.SpecificMembersAttention]: specificMembersAttention
}
