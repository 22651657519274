import moment from 'moment'
import { Grid, makeStyles } from '@material-ui/core'
import Actions from 'actions'
import PrimaryButton from 'components/common/buttons/PrimaryButton'
import { Download, Share, ShareScan } from 'components/common/icons'
import BaseModal from 'components/common/modals/BaseModal'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import useRolePermissions from 'hooks/useRolePermissions'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { withOrientationChange } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { trackEvent } from 'utils/analyticsUtils'
import { downloadMedia, loadImage } from 'utils/mediaUtils'
import { isLandscape, isMobile } from 'utils/mobileUtils'
import ModalGallery from '../ModalGallery'
import NegativeFeedback from '../NegativeFeedback'
import ScanSummaryActiveImage from './ScanSummaryActiveImage'
import ScanSummaryRate from './ScanSummaryRate'
import { drawBackgroundWithLayers } from 'utils/canvasUtils'
import { logError } from 'utils/logUtils'
import { IMAGE_MODES, SCAN_SUMMARY_SECTIONS } from 'consts/scanSummaryConsts'
import ScanSummaryPanels from '../Sections/ScanSummaryPanels'
import useScanSummaryPanels from '../Sections/useScanSummaryPanels'
import useFeatureFlags from 'hooks/useFeatureFlags'
import ScanActionButton from '../../ScanActionButton'
import actions from 'actions'
import usePreventive from '../Preventive/usePreventive'
import ScanSummaryModalTitle from './ScanSummaryModalTitle'
import { TIME_FORMAT_6 } from 'consts/dateTimeConsts'
import NoScanSummary from './NoScanSummary'
import useScans from 'hooks/useScans'
import { partition } from 'lodash'

const GALLERY_SPACING = 15

const useStyles = ({ isMobile, modalGalleryWidth, isLandscape, withPanels }) =>
  makeStyles({
    root: {
      height: '100%',
      width: '100%',
      overflowY: isLandscape && isMobile ? 'hidden' : undefined
    },
    modalClassName: {
      width: '100%',
      maxHeight: 'calc(100vh - 20px)',
      maxWidth: withPanels ? 1200 : 900,
      padding: '0 0px'
    },
    modalContent: {
      overflowX: 'hidden',
      overflowY: isLandscape && isMobile ? 'hidden' : undefined,
      paddingBottom: isMobile ? '40px !important' : 0,
      zIndex: 15000
    },
    container: {
      display: 'flex',
      zIndex: 1500
    },
    imageBody: {
      width: withPanels ? 'calc(100% - 290px)' : '100%'
    },
    downloadIcon: {
      fill: 'var(--text-color-1)',
      minHeight: 20,
      marginRight: 10
    },
    disabledDownload: {
      fill: 'var(--text-color-1)'
    },
    title: {
      width: '100%',
      padding: '15px 36px 5px 36px !important',
      marginBottom: isLandscape && isMobile ? 25 : 0,
      height: !isLandscape && isMobile ? 90 : '8%'
    },
    timestamp: {
      color: '#80879A',
      justifyContent: 'center',
      display: 'flex'
    },
    patientName: {
      fontWeight: 'normal'
    },
    timestampMobile: {
      fontFamily: 'Dazzed',
      fontSize: 14,
      marginLeft: 10,
      color: '#80879A'
    },
    activeImage: {
      width: !isLandscape && isMobile ? '100%' : `calc(100% - ${modalGalleryWidth} - ${GALLERY_SPACING}px)`
    },
    imageGallery: {
      position: 'relative',
      minWidth: 0
    },
    panelsContainer: {
      width: 280
    },
    modelVersion: {
      position: 'absolute',
      background: 'aquamarine',
      right: 10
    }
  })({ isMobile, modalGalleryWidth, isLandscape, withPanels })

const EDITED_IMAGES_INITIAL_STATE = {
  center: '',
  lower: '',
  upper: '',
  right: '',
  left: ''
}

const ScanSummaryModal = () => {
  const modalGalleryWidth = isMobile() ? '20vw' : 175

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { permissions } = useRolePermissions()

  const { isOpen, scanId, activeImage: initActiveImage } = useSelector(state => state.treatmentReducer.scanSummaryModal)
  const grinScans = useSelector(state => state.patientsReducer.patient?.grinScans?.items || [])

  const { viewScanSummaryModelVersion } = useFeatureFlags()
  const { scanIdToNumberDict } = useScans()

  const [activeImage, setActiveImage] = useState()
  const [selectedScanId, setSelectedScanId] = useState()
  const [imagesMode, setImagesMode] = useState(IMAGE_MODES.sharpened)

  const {
    preventiveLayers,
    displayedPreventiveLayers,
    isAnyPreventiveLayerVisible,
    togglePreventive,
    togglePreventiveLayer,
    toggleAllPreventiveLayers
  } = usePreventive({ scanId, setImagesMode, defaultImageMode: IMAGE_MODES.sharpened })

  const patientName = useSelector(state => state.patientsReducer.patient?.details?.name)
  const { isLoading } = useSelector(state => state.treatmentReducer)

  const [selectedImages, setSelectedImages] = useState([])
  const [isNegativeFeedback, setIsNegativeFeedback] = useState(false)
  const [clickedFeedback, setClickedFeedback] = useState(null)
  const [editedImages, setEditedImages] = useState(EDITED_IMAGES_INITIAL_STATE)

  const scan = useMemo(() => grinScans.find(scan => scan.id === selectedScanId), [grinScans, selectedScanId])
  const items = useMemo(() => JSON.parse(scan?.scanSummaryData || '{}'), [scan])

  const { sections, currentSectionId, handleSectionSelected } = useScanSummaryPanels({ items })
  const classes = useStyles({
    isMobile: isMobile(),
    modalGalleryWidth,
    withPanels: sections.length > 0,
    isLandscape: isLandscape()
  })

  const scanTimestamp = useMemo(() => scan && moment(scan.createdAt).format(TIME_FORMAT_6), [scan])
  const scanNumber = useMemo(() => scanIdToNumberDict?.[scan?.id] + 1, [scan, scanIdToNumberDict])
  const scanHasNoScanSummary = useMemo(() => !items || scan?.scanSummaryStatus !== 'completed', [scan, items])
  const hasPreventiveLayering = useMemo(
    () =>
      sections.find(section => section.id === SCAN_SUMMARY_SECTIONS.PREVENTIVE) && items[IMAGE_MODES.preventiveLayers],
    [items, sections]
  )
  const imagesIsNotSelected = useMemo(() => !selectedImages.length, [selectedImages])
  const itemsValues = useMemo(() => Object.entries(items?.normalImages || {}), [items])
  const activeImagesSet = useMemo(() => items?.[imagesMode || 'normalImages'] || {}, [items, imagesMode])
  const preventiveImagesSet = useMemo(() => items?.['preventiveImages'] || {}, [items])
  const preventiveLayersImagesSet = useMemo(() => items?.[IMAGE_MODES.preventiveLayers] || {}, [items])
  const activeImageS3 = useMemo(() => activeImagesSet?.[activeImage], [activeImage, activeImagesSet])
  const isPositiveReview = useMemo(() => {
    return isLoading
      ? clickedFeedback === 'good'
      : items?.feedbackData?.feedBack
      ? items?.feedbackData?.feedBack === 'good'
      : null
  }, [items?.feedbackData?.feedBack, isLoading, clickedFeedback])
  const currentImageIndex = useMemo(
    () => itemsValues?.findIndex(item => item[0] === activeImage),
    [itemsValues, activeImage]
  )

  const toggleSelected = useCallback(
    (e, key) => {
      e.stopPropagation()
      setSelectedImages(
        selectedImages.includes(key) ? selectedImages.filter(selected => selected !== key) : [...selectedImages, key]
      )
    },
    [selectedImages]
  )

  const toggleAllImages = useCallback(async () => {
    if (selectedImages.length === itemsValues.length) {
      setSelectedImages([])
    } else {
      setSelectedImages(itemsValues.map(item => item[0]))
    }
  }, [selectedImages, itemsValues])

  const downloadImages = useCallback(async () => {
    trackEvent('Scan summary - download images clicked', {
      preventiveLayers: displayedPreventiveLayers,
      poses: selectedImages
    })

    const isNewMediaArchitecture = (
      activeImagesSet[selectedImages[0]] || items['normalImages'][selectedImages[0]]
    )?.key?.includes('private')

    let payload = {
      name: `${patientName}-scan-${scanNumber}-${imagesMode}`,
      isNewMediaArchitecture
    }

    if (isAnyPreventiveLayerVisible) {
      try {
        let images = []
        const displayedCanvas = document.getElementById('preventive-layers-canvas')

        for (const selectedPose of selectedImages) {
          let layersImages = []
          let canvas = document.createElement('canvas')
          canvas.height = displayedCanvas.height
          canvas.width = displayedCanvas.width

          const backgroundImage = await loadImage(await downloadMedia(items['normalImages'][selectedPose]))

          for (let layer of displayedPreventiveLayers) {
            layersImages.push(await loadImage(await downloadMedia(preventiveLayersImagesSet[selectedPose][layer])))
          }

          drawBackgroundWithLayers({
            canvas,
            backgroundImage,
            layersImages
          })

          images.push({
            data: canvas.toDataURL(),
            filename: selectedPose,
            name: `${patientName}-scan-${scanNumber}-${imagesMode}`,
            key: `${selectedPose}.png`
          })
        }

        payload.files = images
        payload.isLocal = true
      } catch (err) {
        logError('An error occured while trying to prepare preventive layers for download', err)
        trackEvent('Scan summary - Download preventive images failed')
        dispatch(Actions.downloadPatientFilesZipFailed())
      }
    } else {
      const [editedImagesToDownload, imagesToDownload] = partition(
        selectedImages,
        currSelectedPose => !!editedImages[currSelectedPose]
      )

      payload.files = editedImagesToDownload.map(editedImageToDownload => ({
        isLocal: true,
        data: editedImages[editedImageToDownload],
        filename: editedImageToDownload,
        name: `${patientName}-scan-${scanNumber}-edited`,
        key: `${editedImageToDownload}.png`
      }))

      payload.files = [
        ...payload.files,
        ...imagesToDownload.map(selected => {
          const fileS3Object = activeImagesSet[selected] || items['normalImages'][selected]
          return {
            key: fileS3Object.key,
            bucket: fileS3Object.bucket,
            name: `${patientName}-scan-${scanNumber}-${imagesMode}`,
            outputFilename: selected
          }
        })
      ]
    }

    dispatch(Actions.downloadPatientFilesZip(payload))
    trackEvent('Summary_DownloadClicked', {
      amount: selectedImages.length,
      positions: selectedImages,
      preventiveLayersSelected: displayedPreventiveLayers
    })
  }, [
    displayedPreventiveLayers,
    selectedImages,
    activeImagesSet,
    items,
    patientName,
    scanNumber,
    imagesMode,
    isAnyPreventiveLayerVisible,
    dispatch,
    preventiveLayersImagesSet,
    editedImages
  ])

  const handleSendSelectedImages = useCallback(() => {
    dispatch(
      Actions.sendSelectedScanSummaryImages({
        selectedImages,
        editedImages,
        originalImages: items[imagesMode]
      })
    )
    const annotatedPositions = Object.keys(editedImages).filter(key => editedImages[key])

    trackEvent('Summary_SendClicked', {
      amount: selectedImages.length,
      positions: selectedImages,
      annotatedPositions,
      imagesMode
    })
  }, [dispatch, selectedImages, editedImages, items, imagesMode])

  const calculatePrevIndex = useCallback(
    index => {
      const newImageIndex = index ? index - 1 : itemsValues.length - 1
      const [, url] = itemsValues[newImageIndex]
      return url ? newImageIndex : calculatePrevIndex(newImageIndex)
    },
    [itemsValues]
  )

  const handleNavigateBack = useCallback(() => {
    const newImageIndex = calculatePrevIndex(currentImageIndex)
    const [position] = itemsValues[newImageIndex]
    setActiveImage(position)
  }, [itemsValues, currentImageIndex, setActiveImage, calculatePrevIndex])

  const calculateNextIndex = useCallback(
    index => {
      const newImageIndex = index === itemsValues.length - 1 ? 0 : index + 1
      const [, url] = itemsValues[newImageIndex]
      return url ? newImageIndex : calculateNextIndex(newImageIndex)
    },
    [itemsValues]
  )

  const handleNavigateForward = useCallback(() => {
    const newImageIndex = calculateNextIndex(currentImageIndex)
    const [position] = itemsValues[newImageIndex]
    setActiveImage(position)
  }, [itemsValues, currentImageIndex, setActiveImage, calculateNextIndex])

  const handleSubmitReview = useCallback(
    (isPositive, feedbackData) => {
      setClickedFeedback(isPositive ? 'good' : 'bad')
      trackEvent('Scan Summary - Feedback Submitted', { feedback: isPositive ? 'good' : 'bad', scanId })
      dispatch(Actions.scanSummaryReviewed({ scanId, feedbackData, scanNumber }))
      setIsNegativeFeedback(false)
    },

    [scanId, dispatch, scanNumber]
  )

  const handleSubmitPreventiveReview = useCallback(
    ({ preventiveFeedback, preventiveFeedbackParameters }) => {
      trackEvent('Scan Summary - Preventive Feedback Submitted', { preventiveFeedback, scanId })
      dispatch(
        Actions.submitPreventiveFeedback({
          scanId,
          feedbackData: {
            ...(items?.feedbackData || {}),
            preventiveFeedback,
            preventiveFeedbackParameters
          },
          scanNumber
        })
      )
      setIsNegativeFeedback(false)
    },
    [dispatch, scanId, scanNumber, items]
  )

  const handleClose = useCallback(() => {
    if (isNegativeFeedback) {
      handleSubmitReview(false, { feedBack: 'bad' })
    }
    dispatch(
      Actions.setScanSummaryModalOpen({
        isOpen: false
      })
    )
    setSelectedImages([])
    setSelectedScanId(null)
    setActiveImage(null)
    setIsNegativeFeedback(false)
    setImagesMode(IMAGE_MODES.sharpened)
  }, [isNegativeFeedback, dispatch, handleSubmitReview])

  const handleShareScan = useCallback(() => {
    trackEvent('Share scan modal - opened', {
      analyticSource: 'Scan summry modal'
    })
    dispatch(actions.toggleShareNewScanModal({ isOpen: true, grinScanId: scanId }))
  }, [dispatch, scanId])

  useEffect(() => {
    isOpen && setEditedImages(EDITED_IMAGES_INITIAL_STATE)
  }, [isOpen])

  useEffect(() => {
    if (!selectedScanId && scanId) {
      setSelectedScanId(scanId)
    }
  }, [scanId, selectedScanId])

  useEffect(() => {
    if (!activeImage && initActiveImage) {
      setActiveImage(initActiveImage)
    }
  }, [activeImage, initActiveImage, scanId, selectedScanId])

  return (
    isOpen && (
      <BaseModal
        titleImageComponent={
          <ScanSummaryModalTitle
            isLandscape={isLandscape()}
            isMobile={isMobile()}
            isNegativeFeedback={isNegativeFeedback}
            patientName={patientName}
            scanNumber={scanNumber}
            scanTimestamp={scanTimestamp}
            selectedScanId={selectedScanId}
            setSelectedScanId={setSelectedScanId}
          />
        }
        open={isOpen}
        handleClose={handleClose}
        className={isMobile() ? '' : classes.modalClassName}
        titleContainerClassName={classes.title}
        rootClassName={isMobile() && classes.root}
        contentClassName={classes.modalContent}
        variant={isMobile() ? 'fullscreen' : 'primary'}
      >
        {scanHasNoScanSummary ? (
          <NoScanSummary />
        ) : isMobile() || !isNegativeFeedback ? (
          <Grid container direction="row" justifyContent="space-evenly" alignItems="stretch">
            {viewScanSummaryModelVersion && <span className={classes.modelVersion}>version: {items.modelVersion}</span>}
            <Grid item style={{ width: '100%' }}>
              <Grid container direction="row">
                <Grid item>
                  <ScanSummaryPanels
                    className={classes.panelsContainer}
                    items={items}
                    currentSectionId={currentSectionId}
                    onSectionSelected={handleSectionSelected}
                    sections={sections}
                    preventiveProps={{
                      feedbackData: items?.feedbackData,
                      hasPreventiveLayering: hasPreventiveLayering,
                      imagesMode: imagesMode,
                      isAnyPreventiveLayerVisible: isAnyPreventiveLayerVisible,
                      onSubmitPreventiveReview: handleSubmitPreventiveReview,
                      onTogglePreventiveMode: togglePreventive,
                      preventiveLayers: preventiveLayers,
                      toggleAllLayers: toggleAllPreventiveLayers,
                      togglePreventiveLayer: togglePreventiveLayer
                    }}
                    treatmentTrackingProps={{
                      scanId: selectedScanId
                    }}
                  />
                </Grid>
                <Grid item className={classes.imageBody}>
                  {!isMobile() && (
                    <Grid container direction="row" alignItems="center" style={{ marginBottom: 10 }}>
                      <Grid item xs={3}>
                        <DazzedParagraph16>{t('dialogs.scanSummary.patientName', { patientName })}</DazzedParagraph16>
                      </Grid>
                      <Grid item xs={6}>
                        <DazzedParagraph16 className={classes.timestamp}>{scanTimestamp}</DazzedParagraph16>
                      </Grid>
                      {permissions.shareScan && !isMobile() && !!items?.normalImages?.center && (
                        <Grid item>
                          <ScanActionButton icon={<ShareScan />} onClick={handleShareScan}>
                            {t('pages.patients.selectedPatient.timeline.actions.shareScan')}
                          </ScanActionButton>
                        </Grid>
                      )}
                    </Grid>
                  )}
                  <Grid
                    container
                    direction={isMobile() && !isLandscape() ? 'column' : 'row'}
                    justifyContent="center"
                    wrap="nowrap"
                  >
                    <Grid item xs={isMobile() ? undefined : 10} className={classes.activeImage}>
                      <ScanSummaryActiveImage
                        activeImage={activeImageS3}
                        editedImages={editedImages}
                        activeImagePosition={activeImage}
                        sharpenedImages={imagesMode !== IMAGE_MODES.normal}
                        preventiveImages={hasPreventiveLayering ? preventiveLayersImagesSet : preventiveImagesSet}
                        displayedPreventiveLayers={displayedPreventiveLayers}
                        setEditedImages={setEditedImages}
                        showPreventiveLayers={isAnyPreventiveLayerVisible}
                        setSharpenedImages={toggle => {
                          if (imagesMode === IMAGE_MODES.preventive) {
                            trackEvent('Scan Summary - Preventive Toggled Off', {
                              scanId
                            })
                          }
                          setImagesMode(toggle ? IMAGE_MODES.sharpened : IMAGE_MODES.normal)
                        }}
                        onNavigateBack={handleNavigateBack}
                        onNavigateForward={handleNavigateForward}
                      />
                    </Grid>
                    <Grid item {...(isMobile() ? {} : { xs: 2 })} className={classes.imageGallery}>
                      <ModalGallery
                        items={itemsValues}
                        editedImages={editedImages}
                        selectedImages={selectedImages}
                        toggleSelected={toggleSelected}
                        setActive={setActiveImage}
                        activeImage={activeImage}
                        onToggleAll={toggleAllImages}
                      />
                      {isMobile() && !isLandscape() && (
                        <ScanSummaryRate
                          isPositiveReview={isPositiveReview}
                          setIsNegativeFeedback={setIsNegativeFeedback}
                          onSubmitReview={handleSubmitReview}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {!isMobile() && (
              <Grid item xs={12}>
                <ScanSummaryRate
                  isPositiveReview={isPositiveReview}
                  setIsNegativeFeedback={setIsNegativeFeedback}
                  onSubmitReview={handleSubmitReview}
                />
              </Grid>
            )}
            {!isMobile() && (
              <Grid item xs={12}>
                <Grid container direction="row" justifyContent="center" spacing={2}>
                  {permissions.scanSummaryAnnotations && (
                    <Grid item>
                      <PrimaryButton
                        label={t('dialogs.scanSummary.sendSelected', { numberOfImages: selectedImages.length })}
                        onClick={handleSendSelectedImages}
                        disabled={
                          imagesIsNotSelected || imagesMode === IMAGE_MODES.preventive || isAnyPreventiveLayerVisible
                        }
                        fontSize="14px"
                        width="200px"
                        icon={<Share className={imagesIsNotSelected ? classes.disabledDownload : ''} />}
                      />
                    </Grid>
                  )}
                  <Grid item>
                    <PrimaryButton
                      label={t('dialogs.scanSummary.download')}
                      onClick={downloadImages}
                      disabled={imagesIsNotSelected || isAnyPreventiveLayerVisible}
                      fontSize="14px"
                      width="175px"
                      color="var(--bg-color-13)"
                      icon={<Download className={classes.downloadIcon} />}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        ) : (
          <NegativeFeedback
            images={itemsValues}
            onSubmitReview={handleSubmitReview}
            onClose={() =>
              dispatch(
                Actions.setScanSummaryModalOpen({
                  isOpen: false
                })
              )
            }
          />
        )}
      </BaseModal>
    )
  )
}

export default withOrientationChange(ScanSummaryModal)
