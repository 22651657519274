import moment from 'moment'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import usePracticeGuidelines from './usePracticeGuidelines'
import TrackingGuidelines from '../TrackingGuidelines'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'

const PracticeGuidelines = () => {
  const { t } = useTranslation()

  const { practiceGuidelines } = useSelector(state => state.multiPracticeReducer)
  const practiceMembers = useSelector(state => state.profileReducer.practiceMembers.data)

  const lastUpdatedBy = useMemo(
    () => practiceMembers?.find(pm => pm.id === practiceGuidelines?.creatorDoctorId)?.name,
    [practiceGuidelines, practiceMembers]
  )
  const lastUpdated = useMemo(() => moment(practiceGuidelines.createdAt).format('MMM D, YYYY'), [practiceGuidelines])
  const parsedCategories = useMemo(() => JSON.parse(practiceGuidelines.categories || '{}'), [practiceGuidelines])
  const administrativeInfo = useMemo(
    () => JSON.parse(practiceGuidelines.administrativeInfo || '{}'),
    [practiceGuidelines]
  )
  const { categories } = usePracticeGuidelines({ categoriesData: parsedCategories })

  return !isEmpty(practiceGuidelines) ? (
    <TrackingGuidelines
      lastUpdated={lastUpdated}
      lastUpdatedBy={lastUpdatedBy}
      categories={categories}
      categoriesData={parsedCategories}
      administrativeInfo={administrativeInfo}
    />
  ) : (
    <div>
      <DazzedParagraph14>{t('dialogs.hiPatientGuidelinesViewer.tabs.patientBrief.emptyState')}</DazzedParagraph14>
    </div>
  )
}

export default PracticeGuidelines
