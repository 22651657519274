import { TreatmentTypes } from './treatmentConsts'

export const ScanTrackingStatus = {
  Pending: 'pending',
  Submitted: 'submitted',
  NotRequired: 'not-required'
}

export const TreatmentTrackOptions = {
  OnTrack: 'on-track',
  OffTrack: 'not-on-track'
}

export const TrackingSelectionModes = {
  None: 'none',
  Teeth: 'teeth',
  Arches: 'arches'
}

export const TrackingConditionsTypes = {
  AligersFit: 'aligners-fit',
  MissingAttachments: 'missing-attachments',
  PassiveWire: 'passive-wire',
  WireOutOfBracket: 'wire-out-of-bracket',
  BrokenWire: 'broken-wire',
  MissingOTie: 'missing-o-tie',
  MissingBracket: 'missing-bracket',
  BrokenChain: 'broken-chain',
  CritiallyBadHygiene: 'critical-hygiene',
  BadHygiene: 'bad-hygiene',
  InvalidScan: 'invalid-scan',
  Other: 'other'
}

export const TrackingConditions = {
  [TrackingConditionsTypes.AligersFit]: {
    id: TrackingConditionsTypes.AligersFit,
    selectionMode: TrackingSelectionModes.Teeth,
    treatmentTypes: [TreatmentTypes.Aligners],
    trackingStatus: [TreatmentTrackOptions.OffTrack]
  },
  [TrackingConditionsTypes.MissingAttachments]: {
    id: TrackingConditionsTypes.MissingAttachments,
    selectionMode: TrackingSelectionModes.Teeth,
    treatmentTypes: [TreatmentTypes.Aligners],
    trackingStatus: [TreatmentTrackOptions.OffTrack]
  },
  [TrackingConditionsTypes.PassiveWire]: {
    id: TrackingConditionsTypes.PassiveWire,
    selectionMode: TrackingSelectionModes.Teeth,
    treatmentTypes: [TreatmentTypes.Braces],
    trackingStatus: [TreatmentTrackOptions.OffTrack]
  },
  [TrackingConditionsTypes.WireOutOfBracket]: {
    id: TrackingConditionsTypes.WireOutOfBracket,
    selectionMode: TrackingSelectionModes.Teeth,
    treatmentTypes: [TreatmentTypes.Braces],
    trackingStatus: [TreatmentTrackOptions.OffTrack]
  },
  [TrackingConditionsTypes.BrokenWire]: {
    id: TrackingConditionsTypes.BrokenWire,
    selectionMode: TrackingSelectionModes.Teeth,
    treatmentTypes: [TreatmentTypes.Braces],
    trackingStatus: [TreatmentTrackOptions.OffTrack]
  },
  [TrackingConditionsTypes.MissingOTie]: {
    id: TrackingConditionsTypes.MissingOTie,
    selectionMode: TrackingSelectionModes.Teeth,
    treatmentTypes: [TreatmentTypes.Braces],
    trackingStatus: [TreatmentTrackOptions.OffTrack]
  },
  [TrackingConditionsTypes.MissingBracket]: {
    id: TrackingConditionsTypes.MissingBracket,
    selectionMode: TrackingSelectionModes.Teeth,
    treatmentTypes: [TreatmentTypes.Braces],
    trackingStatus: [TreatmentTrackOptions.OffTrack]
  },
  [TrackingConditionsTypes.BrokenChain]: {
    id: TrackingConditionsTypes.BrokenChain,
    selectionMode: TrackingSelectionModes.Teeth,
    treatmentTypes: [TreatmentTypes.Braces],
    trackingStatus: [TreatmentTrackOptions.OffTrack]
  },
  [TrackingConditionsTypes.BadHygiene]: {
    id: TrackingConditionsTypes.BadHygiene,
    selectionMode: TrackingSelectionModes.Teeth,
    treatmentTypes: Object.values(TreatmentTypes),
    trackingStatus: [TreatmentTrackOptions.OnTrack, TreatmentTrackOptions.OffTrack]
  },
  [TrackingConditionsTypes.BadHygiene]: {
    id: TrackingConditionsTypes.BadHygiene,
    selectionMode: TrackingSelectionModes.Teeth,
    treatmentTypes: Object.values(TreatmentTypes),
    trackingStatus: [TreatmentTrackOptions.OnTrack, TreatmentTrackOptions.OffTrack]
  },
  [TrackingConditionsTypes.CritiallyBadHygiene]: {
    id: TrackingConditionsTypes.CritiallyBadHygiene,
    selectionMode: TrackingSelectionModes.Teeth,
    treatmentTypes: Object.values(TreatmentTypes),
    trackingStatus: [TreatmentTrackOptions.OffTrack]
  },
  [TrackingConditionsTypes.Other]: {
    id: TrackingConditionsTypes.Other,
    selectionMode: TrackingSelectionModes.None,
    treatmentTypes: Object.values(TreatmentTypes),
    trackingStatus: [TreatmentTrackOptions.OffTrack, TreatmentTrackOptions.OnTrack],
    withNote: true
  }
}

export const GI_RESULT_MSG_TYPE = 'gi-result'

export const NotifyPracticeReasons = {
  Clinical: 'clinical',
  Scheduling: 'scheduling',
  MissingInfo: 'missingInfo',
  PatientQuestion: 'patientQuestion',
  SpecificMembersAttention: 'specificMembersAttention',
  Other: 'other'
}
