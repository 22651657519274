import React, { useCallback } from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import ActionCheckbox from './ActionCheckbox'

const StayInAlignerUntilReplacedOrAdvance = ({ shouldStayInAligner, shouldAdvance, onChange }) => {
  const { t } = useTranslation()

  const handleToggleShouldStayInAligner = useCallback(
    isChecked => {
      onChange({
        shouldStayInAligner: isChecked,
        shouldAdvance: false
      })
    },
    [onChange]
  )

  const handleToggleShouldAdvance = useCallback(
    isChecked => {
      onChange({
        shouldAdvance: isChecked,
        shouldStayInAligner: false
      })
    },
    [onChange]
  )

  return (
    <Grid container direction="column" style={{ minWidth: 450 }}>
      <Grid item>
        <ActionCheckbox
          variant="radio"
          checked={shouldStayInAligner}
          onChange={handleToggleShouldStayInAligner}
          caption={t(
            'pages.accountSettings.practiceGuidelines.actions.stayInAlignerUntilReplacedOrAdvance.stayInAligner'
          )}
        />
      </Grid>
      <Grid item>
        <ActionCheckbox
          variant="radio"
          checked={shouldAdvance}
          onChange={handleToggleShouldAdvance}
          caption={t('pages.accountSettings.practiceGuidelines.actions.stayInAlignerUntilReplacedOrAdvance.advance')}
        />
      </Grid>
    </Grid>
  )
}

export default StayInAlignerUntilReplacedOrAdvance
