import React, { useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import { Trash2 } from 'components/common/icons'
import SelectInput from 'components/common/SelectInput'
import { conditionTypes } from 'consts/automatedActionConsts'
import AlignerNumberCondition from './AlignerNumberCondition'
import TreatmentStatusUpdated from './TreatmentStatusUpdatedCondition'
import DateRangeCondition from './DateRangeCondition'
import DuringDaysCondition from './DuringDaysCondition'
import PatientScanCountCondition from './PatientScanCountCondition'
import PracticeHasFeatureFlag from './PracticeHasFeatureFlagCondition'
import OverdueDaysConditions from './OverdueDaysConditions'
import PracticeOnPlan from './PracticeOnPlan'
import PatientStatus from './PatientStatus'
import RemainingAlignersCondition from './RemainingAlignersCondition'
import PatientHasTags from './PatientHasTags'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative'
  },
  conditionsSelector: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  selectContainer: {
    width: '90%'
  },
  and: {
    color: '#727B8C',
    fontWeight: 600,
    position: 'absolute',
    top: 25,
    left: -30,
    cursor: 'pointer'
  }
}))

const AutomationRuleConditionSelector = ({
  condition = {},
  setCondition = condition => {},
  conditionsOptions = [],
  onDelete = () => {},
  hideConditionTypeInput = false,
  errors,
  isSystem
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const handleConditionChanged = useCallback(condition => setCondition(condition), [setCondition])

  const conditionComponents = useMemo(
    () => ({
      [conditionTypes.DateRange]: DateRangeCondition,
      [conditionTypes.DuringDays]: DuringDaysCondition,
      [conditionTypes.AlignerNumber]: AlignerNumberCondition,
      [conditionTypes.TreatmentStatusUpdated]: TreatmentStatusUpdated,
      [conditionTypes.PatientScanCount]: PatientScanCountCondition,
      [conditionTypes.PracticeHasFeatureFlag]: PracticeHasFeatureFlag,
      [conditionTypes.OverdueDays]: OverdueDaysConditions,
      [conditionTypes.PracticeOnPlan]: PracticeOnPlan,
      [conditionTypes.PatientStatus]: PatientStatus,
      [conditionTypes.RemainingAligners]: RemainingAlignersCondition,
      [conditionTypes.PatientHasTags]: PatientHasTags
    }),
    []
  )

  return (
    <Grid container alignItems="center" spacing={2} className={classes.root}>
      {!hideConditionTypeInput && (
        <Grid item xs={10} className={classes.conditionsSelector}>
          <span className={classes.and}>{t('pages.accountSettings.automations.editorModal.and')}</span>
          <SelectInput
            style={{ bright: true, thick: true }}
            options={conditionsOptions}
            value={condition.type}
            onChange={option => setCondition({ type: option.value, params: {} })}
            placeholder={t('pages.accountSettings.automations.editorModal.conditionPlaceholder')}
            keepErrorContainerWhenInactive={false}
            containerClassName={classes.selectContainer}
          />
          <Trash2 className={classes.removeCondition} onClick={onDelete} />
        </Grid>
      )}
      {conditionComponents[condition?.type] && (
        <Grid item>
          {React.createElement(conditionComponents[condition?.type], {
            onChange: handleConditionChanged,
            params: condition.params,
            errors,
            isSystem
          })}
        </Grid>
      )}
    </Grid>
  )
}

export default AutomationRuleConditionSelector
