import React from 'react'
import { Trans } from 'react-i18next'
import BaseGrinNotification from './BaseGrinNotification/BaseGrinNotification'
import useSelectPatient from 'hooks/useSelectPatient'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'

/**
 * @param {NotificationComponentProps} props
 */
const PatientAssignedNotification = ({ grinNotification, options }) => {
  const selectPatient = useSelectPatient({
    patientId: grinNotification?.relatedPatientId
  })

  return (
    <BaseGrinNotification
      grinNotification={grinNotification}
      isLastItem={options.isLastItem}
      showPracticeName={options.showPracticeName}
      profilePicture={grinNotification.relatedPatientSM.profilePicture}
      profilePictureUserName={grinNotification.relatedPatientSM.name}
      onClick={selectPatient}
    >
      <DazzedParagraph14 strong>
        <Trans
          i18nKey={`dialogs.notificationCenterDialog.feed.notifications.patientAssigned.body`}
          values={{
            patientName: grinNotification.relatedPatientSM?.name,
            assignerName: grinNotification.payload.assignerName
          }}
        />
      </DazzedParagraph14>
    </BaseGrinNotification>
  )
}

export default PatientAssignedNotification
