import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import scanPlacholder from 'resources/scan-placeholder.png'
import scanReviewPlacholder from 'resources/scanreview-placeholder.png'
import { Skeleton } from '@material-ui/lab'

const useStyles = makeStyles(theme => ({
  preview: {
    maxHeight: 210,
    maxWidth: 210,
    borderRadius: 8
  },
  previewSkeleton: {
    width: 210,
    height: 141,
    borderRadius: 8
  }
}))

const ScanVideoPlaceholder = ({ isScanReview }) => {
  const classes = useStyles()

  const [isLoadingPreview, setIsLoadingPreview] = useState(false)

  useEffect(() => {
    setIsLoadingPreview(true)
  }, [])

  return (
    <>
      <img
        src={isScanReview ? scanReviewPlacholder : scanPlacholder}
        className={classes.preview}
        style={{ display: isLoadingPreview ? 'none' : 'block' }}
        alt={''}
        onLoad={() => setIsLoadingPreview(false)}
      />
      <Skeleton
        variant="rect"
        className={classes.previewSkeleton}
        style={{ display: isLoadingPreview ? 'block' : 'none' }}
      />
    </>
  )
}

export default ScanVideoPlaceholder
