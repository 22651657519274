import { makeStyles } from '@material-ui/core'
import React from 'react'

import { Trans, useTranslation } from 'react-i18next'

const useStyles = makeStyles({
  validationError: {
    flexDirection: 'column',
    textAlign: 'center',
    display: 'flex',
    height: '100%',
    width: '100%',
    border: '1px dashed #abb0b8',
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'default',
    fontWeight: 500,
    fontSize: 15,
    padding: 40
  }
})

const ValidationErrorContent = ({ providerOptions, provider, validationError }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.validationError}>
      <div style={{ display: 'inline' }}>
        <Trans
          i18nKey={`dialogs.patientInfo.txTrackerSetup.validationErrors.${validationError}`}
          values={{ provider: providerOptions.find(p => p.value === provider).label }}
        />
        <a href="mailto:support@get-grin.com">{t('dialogs.patientInfo.txTrackerSetup.validationErrors.contactUs')}</a>
      </div>
    </div>
  )
}

export default ValidationErrorContent
