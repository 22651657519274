import * as React from 'react'

export default () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_396_9"
      style={{ 'mask-type': 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="22"
      height="22"
    >
      <rect width="22" height="22" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_396_9)">
      <rect x="12" y="8" width="6" height="2" fill="#3C52EF" />
      <rect x="6" y="12" width="12" height="2" fill="#3C52EF" />
      <rect x="6" y="16" width="12" height="2" fill="#3C52EF" />
      <path d="M4 7L6.5 9.5L13 3" stroke="#3C52EF" strokeWidth="2" />
      <path d="M4 21.5L18.5 7" stroke="white" strokeWidth="2" />
      <path d="M4 19.5L18.5 5" stroke="#3C52EF" strokeWidth="2" />
    </g>
  </svg>
)
