import _ from 'lodash'
import React, { useEffect, useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import BaseModal from 'components/common/modals/BaseModal'
import Actions from 'actions'
import { AsyncStatus } from 'consts'
import MessageHistorySkeleton from './MessageHistorySkeleton'
import MessageAuditItem from './MessageAuditItem'

const useStyles = makeStyles(theme => ({
  root: {
    width: 640
  },
  body: {
    paddingBottom: 0
  },
  auditContainer: {
    maxHeight: 400,
    height: 400,
    overflowY: 'scroll'
  },
  gradientOverlay: {
    position: 'absolute',
    bottom: 0,
    height: 50,
    width: '100%',
    background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 75%)'
  }
}))

const MessageHistoryModal = ({ isOpen, onClose = () => {}, messageId = '' }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { loadStatus, message } = useSelector(state => state.chatReducer.messageActions.viewHistory)

  const audit = useMemo(() => _.sortBy(JSON.parse(message?.audit || '[]'), 'timestamp').reverse(), [message])

  useEffect(() => {
    if (isOpen && messageId) {
      dispatch(Actions.fetchMessageAudit({ messageId }))
    }
  }, [isOpen, messageId, dispatch])

  return (
    <BaseModal
      open={isOpen}
      handleClose={onClose}
      title={t('pages.patients.selectedPatient.chat.messageActions.messageHistoryModal.title')}
      withCloseIcon
      primaryLabel={t('general.close')}
      onPrimaryBtnClick={onClose}
      className={classes.root}
      contentClassName={classes.body}
    >
      <div style={{ position: 'relative' }}>
        {loadStatus === AsyncStatus.Loading ? (
          <MessageHistorySkeleton />
        ) : (
          <div className={classes.auditContainer}>
            {audit.map(auditItem => (
              <div key={auditItem.timestamp}>
                <MessageAuditItem message={message} auditItem={auditItem} />
              </div>
            ))}
            <div style={{ marginBottom: 50 }} />
          </div>
        )}
        <div className={classes.gradientOverlay} />
      </div>
    </BaseModal>
  )
}

export default MessageHistoryModal
