import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import DazzedHeading13 from 'components/common/text/DazzedHeading13'
import Tooltip from 'components/common/Tooltip'
import { Assignee2 } from 'components/common/icons'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'utils/mobileUtils'
import ProfilePicture from 'components/common/ProfilePicture'

const useStyles = makeStyles(theme => ({
  container: {
    padding: isMobile() ? '0px' : '9px 5px 6px 8px'
  },
  mobileTitle: {
    color: '#828999',
    marginRight: 5
  },
  clickable: {
    cursor: 'pointer'
  },
  avatarContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  assigneeName: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: 'max(85px, 4vw)'
  },
  assignMemberWrapper: {
    cursor: 'pointer',
    marginLeft: 10
  },
  item: {
    display: 'flex'
  }
}))

const AssignPatientTrigger = ({ isAssigned, assigneeTag, assignee }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Grid container>
      <Grid item>
        <Tooltip
          arrow={true}
          value={
            isAssigned
              ? t('pages.patients.selectedPatient.header.changeAssignee')
              : t('pages.patients.selectedPatient.header.assignPracticeMember')
          }
          placement="bottom"
        >
          <Grid container spacing={1} alignItems="center">
            {isAssigned && (
              <Grid item className={classes.avatarContainer}>
                <ProfilePicture
                  size="tiny"
                  photo={assignee?.avatar}
                  name={assignee?.name || ''}
                  email={assignee?.email}
                />
              </Grid>
            )}
            <Grid item className={classes.item}>
              <DazzedHeading13 className={classes.assigneeName}>
                {isAssigned ? assigneeTag.value : t('pages.patients.selectedPatient.header.unassigned')}
              </DazzedHeading13>
            </Grid>
            <Grid item className={classes.item}>
              <Assignee2 />
            </Grid>
          </Grid>
        </Tooltip>
      </Grid>
    </Grid>
  )
}

export default AssignPatientTrigger
