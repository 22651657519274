import * as React from 'react'

const ConfirmIPR = () => (
  <svg width="27" height="23" viewBox="0 0 27 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.5 8V12C7.5 12.8284 6.82843 13.5 6 13.5H2C1.17157 13.5 0.5 12.8284 0.5 12V8C0.5 6.067 2.067 4.5 4 4.5C5.933 4.5 7.5 6.067 7.5 8Z"
      fill="#527195"
      stroke="#527195"
    />
    <path
      d="M18.5 8V12C18.5 12.8284 17.8284 13.5 17 13.5H13C12.1716 13.5 11.5 12.8284 11.5 12V8C11.5 6.067 13.067 4.5 15 4.5C16.933 4.5 18.5 6.067 18.5 8Z"
      fill="#527195"
      stroke="#527195"
    />
    <path d="M8 0V18" stroke="#527195" />
    <path d="M11 0V18" stroke="#527195" />
    <path d="M14 17.5L17.5 21L25.5 13" stroke="#527195" strokeWidth="2.5" />
  </svg>
)

export default ConfirmIPR
