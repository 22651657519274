import { useMediaQuery, useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Actions from 'actions'
import BaseModal from 'components/common/modals/BaseModal'
import React, { useCallback, useMemo, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useDispatch, useSelector } from 'react-redux'
import { trackEvent } from 'utils/analyticsUtils'
import RequestTxTrackerRerunModal from './RequestTxTrackerRerunModal'
import useTxTrackerQuickNavigation from './useTxTrackerQuickNavigation'
import SingleSTLView from './SingleSTLView'
import CompareSTLsView from './CompareSTLsView/CompareSTLsView'
import useTreatmentTracker from './useTreatmentTracker'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    padding: 16
  },
  dialogContent: {
    padding: '0 24px 24px 24px!important'
  },
  disclaimerContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: 'inherit'
  },
  disclaimer: {
    paddingTop: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#727B8C',
    fontSize: 13
  }
}))

const TreatmentTrackerModal = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const theme = useTheme()
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down('md'))

  const { patient } = useSelector(state => state.patientsReducer)
  const { isOpen, grinScanId } = useSelector(state => state.treatmentReducer.treatmentTrackerModal)
  const { alignerNumber, archModels, calculatedTreatmentType, endAlignerNumber, initAlignerNumber, scanNumber } =
    useTreatmentTracker({
      grinScanId
    })
  const patientGrinScans = useSelector(state => state.patientsReducer.patient?.grinScans?.items || [])

  const [isRequestRerunModalOpen, setIsRequestRerunModalOpen] = useState(false)
  const [isCompareStlsModeOn, setIsCompareStlsModeOn] = useState(false)

  const {
    navigateToNextScan,
    navigateToPreviousScan,
    hasNextScan,
    hasPreviousScan,
    allTxTrackerScans,
    navigateToScanByScanId
  } = useTxTrackerQuickNavigation({
    currentGrinScanId: grinScanId,
    grinScans: patientGrinScans
  })

  const grinScan = useMemo(() => patientGrinScans.find(scan => scan.id === grinScanId), [grinScanId, patientGrinScans])
  const parsedTxTrackerData = useMemo(
    () => JSON.parse(grinScan?.treatmentTrackerData || '{}'),
    [grinScan?.treatmentTrackerData]
  )

  const handleClose = useCallback(() => {
    trackEvent('Treatment tracker popup - closed')
    setIsCompareStlsModeOn(false)
    dispatch(Actions.setTreatmentTrackerModalOpen({ isOpen: false }))
  }, [dispatch])

  const handleOpenRequestRerunModal = useCallback(() => {
    trackEvent('Treatment tracker - request rerun modal opened', { grinScanId: grinScan?.id, scanNumber })
    setIsRequestRerunModalOpen(true)
  }, [grinScan, scanNumber])

  useHotkeys('ArrowDown', navigateToNextScan, { enabled: isOpen })
  useHotkeys('ArrowUp', navigateToPreviousScan, { enabled: isOpen })

  return (
    isOpen && (
      <>
        <BaseModal
          open={isOpen}
          handleClose={handleClose}
          className={classes.root}
          contentClassName={classes.dialogContent}
          disableEnforceFocus
        >
          <div className={classes.disclaimerContainer}>
            {isCompareStlsModeOn ? (
              <CompareSTLsView
                hasNextScan={hasNextScan}
                navigateToNextScan={navigateToNextScan}
                hasPreviousScan={hasPreviousScan}
                navigateToPreviousScan={navigateToPreviousScan}
                scanNumber={scanNumber}
                alignerNumber={alignerNumber}
                grinScan={grinScan}
                patient={patient}
                allTxTrackerScans={allTxTrackerScans}
                navigateToScanByScanId={navigateToScanByScanId}
                isCompareStlsModeOn={isCompareStlsModeOn}
                setIsCompareStlsModeOn={setIsCompareStlsModeOn}
                parsedTxTrackerData={parsedTxTrackerData}
                onOpenRequestRerunModal={handleOpenRequestRerunModal}
                archModels={archModels}
                calculatedTreatmentType={calculatedTreatmentType}
                isSmallerScreen={isSmallerScreen}
                initAlignerNumber={initAlignerNumber}
                endAlignerNumber={endAlignerNumber}
              />
            ) : (
              <SingleSTLView
                hasNextScan={hasNextScan}
                navigateToNextScan={navigateToNextScan}
                hasPreviousScan={hasPreviousScan}
                navigateToPreviousScan={navigateToPreviousScan}
                scanNumber={scanNumber}
                alignerNumber={alignerNumber}
                grinScan={grinScan}
                patient={patient}
                allTxTrackerScans={allTxTrackerScans}
                navigateToScanByScanId={navigateToScanByScanId}
                isCompareStlsModeOn={isCompareStlsModeOn}
                setIsCompareStlsModeOn={setIsCompareStlsModeOn}
                parsedTxTrackerData={parsedTxTrackerData}
                handleOpenRequestRerunModal={handleOpenRequestRerunModal}
                archModels={archModels}
                calculatedTreatmentType={calculatedTreatmentType}
                isSmallerScreen={isSmallerScreen}
                initAlignerNumber={initAlignerNumber}
                endAlignerNumber={endAlignerNumber}
                onOpenRequestRerunModal={handleOpenRequestRerunModal}
              />
            )}
            {!isCompareStlsModeOn && (
              <div className={classes.disclaimer}>{t('dialogs.treatmentTracker.disclaimer')}</div>
            )}
          </div>
        </BaseModal>
        <RequestTxTrackerRerunModal
          isOpen={isRequestRerunModalOpen}
          onClose={() => setIsRequestRerunModalOpen(false)}
          scanId={grinScan?.id}
          scanNumber={scanNumber}
          analyticsPayload={{
            grinScanId: grinScan?.id,
            scanNumber
          }}
        />
      </>
    )
  )
}

export default TreatmentTrackerModal
