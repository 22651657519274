import * as React from 'react'

export default ({ color = 'black' }) => (
  <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 0.0439453C0.769526 0.0439453 -1.12379 3.68036 0.728765 6.32687L4 11.0001L7.27123 6.32686C9.12379 3.68036 7.23047 0.0439453 4 0.0439453ZM4 5.522C4.90763 5.522 5.64341 4.78622 5.64341 3.87859C5.64341 2.97095 4.90763 2.23517 4 2.23517C3.09236 2.23517 2.35658 2.97095 2.35658 3.87859C2.35658 4.78622 3.09236 5.522 4 5.522Z"
      fill={color}
    />
  </svg>
)
