import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { isMobile } from 'utils/mobileUtils'
import BeforeAfterDialogDesktop from './BeforeAfterDialog.Desktop'
import BeforeAfterDialogMobile from './BeforeAfterDialog.mobile'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getObjectUrl } from 'utils/mediaUtils'
import Actions from 'actions'
import { trackEvent } from 'utils/analyticsUtils'
import useRolePermissions from 'hooks/useRolePermissions'
import { SCAN_SUMMARY_POSES } from 'consts/scanSummaryConsts'
import { AsyncStatus } from 'consts'

export const BeforeAfterTabs = {
  StillImage: 'stillImage',
  Morph: 'morph'
}

const BeforeAfterDialogHOC = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { permissions } = useRolePermissions()

  const {
    isOpen,
    assetStatus,
    asset: imageAsset,
    patientId,
    lastScanId,
    firstScanId,
    error,
    params,
    morph
  } = useSelector(state => state.treatmentReducer.beforeAfter)
  const patient = useSelector(state => state.patientsReducer.patient)
  const doctor = useSelector(state => state.profileReducer.doctor)

  const [messageText, setMessageText] = useState('')
  const [selectedPose, setSelectedPose] = useState(SCAN_SUMMARY_POSES.center)
  const [currentTab, setCurrentTab] = useState(BeforeAfterTabs.StillImage)

  const tabOptions = useMemo(
    () =>
      [
        {
          key: BeforeAfterTabs.StillImage,
          label: t(`dialogs.beforeAfter.assetOptions.${BeforeAfterTabs.StillImage}`),
          isVisible: true
        },
        {
          key: BeforeAfterTabs.Morph,
          label: t(`dialogs.beforeAfter.assetOptions.${BeforeAfterTabs.Morph}`),
          isVisible: true
        }
      ].filter(t => t.isVisible),
    [t]
  )

  const asset = useMemo(
    () => (currentTab === BeforeAfterTabs.Morph ? morph.asset?.morphS3Object : imageAsset),
    [imageAsset, morph, currentTab]
  )

  const imgSrc = useMemo(() => (getObjectUrl(asset): ''), [asset])
  const loadStatus = useMemo(
    () => (currentTab === BeforeAfterTabs.StillImage ? assetStatus : morph.status || AsyncStatus.Loading),
    [currentTab, assetStatus, morph]
  )
  const assetError = useMemo(
    () => (currentTab === BeforeAfterTabs.Morph ? morph.error : error),
    [morph, error, currentTab]
  )

  const analyticsMetadata = useMemo(
    () => ({
      lastScanId,
      firstScanId,
      patientId: patient.id,
      currentTab
    }),
    [lastScanId, patient?.id, firstScanId, currentTab]
  )

  const handleClose = useCallback(() => {
    dispatch(Actions.toggleBeforeAfterDialog({ open: false }))
    setSelectedPose(SCAN_SUMMARY_POSES.center)
    setCurrentTab(BeforeAfterTabs.StillImage)
  }, [dispatch])

  const fetchStillImageAsset = useCallback(() => {
    dispatch(
      Actions.fetchBeforeAfterAsset({
        patientId,
        lastScanId,
        firstScanId,
        pose: selectedPose
      })
    )
  }, [dispatch, patientId, lastScanId, firstScanId, selectedPose])

  const handleRetry = useCallback(() => {
    trackEvent('Before After - retry clicked', {
      patientId,
      firstScanId,
      lastScanId
    })

    fetchStillImageAsset()
  }, [fetchStillImageAsset, patientId, lastScanId, firstScanId])

  const handleShareOptionClicked = useCallback(
    action => {
      trackEvent('Before After - action', {
        action,
        ...analyticsMetadata,
        isMobile: isMobile()
      })
    },
    [analyticsMetadata]
  )

  const sendAssetInChat = useCallback(() => {
    if (!patient || !asset || !permissions.sendChatMessages) {
      return
    }

    handleShareOptionClicked('send in chat')

    dispatch(
      Actions.sendChatMessage({
        members: [patient.username, patient.doctor.username],
        roomId: patient.user?.rooms.items?.[0].roomId,
        type: 'beforeAfter',
        content: JSON.stringify({
          shareAsset: asset,
          text: messageText
        }),
        patientUsername: patient.username,
        readBy: doctor.username,
        patientDoctorUsername: patient?.doctor?.username
      })
    )

    handleClose()
  }, [patient, doctor, asset, dispatch, handleClose, handleShareOptionClicked, messageText, permissions])

  const fetchMorphAssetData = useCallback(() => {
    dispatch(
      Actions.fetchBeforeAfterMorph({
        beforeScanId: params.beforeScanId,
        afterScanId: params.afterScanId,
        pose: params.pose
      })
    )
  }, [dispatch, params])

  const handleSubmitFeedback = useCallback(
    ({ rating, freeText }) => {
      dispatch(
        Actions.submitBeforeAfterFeedback({
          rating,
          freeText,
          patientId,
          lastScanId
        })
      )
    },
    [dispatch, patientId, lastScanId]
  )

  const handleSelectTab = useCallback(tab => {
    setCurrentTab(tab)
    trackEvent(`Before After - tab changed`, { tab })
  }, [])

  useEffect(() => {
    if (isOpen && selectedPose) {
      fetchStillImageAsset()
    } else {
      setMessageText(t(`dialogs.beforeAfter.chatMessage.${BeforeAfterTabs.StillImage}`))
    }
  }, [isOpen, fetchStillImageAsset, t, selectedPose])

  useEffect(() => {
    setMessageText(t(`dialogs.beforeAfter.chatMessage.${currentTab}`))
  }, [currentTab, t])

  useEffect(() => {
    if (currentTab === BeforeAfterTabs.Morph && assetStatus === AsyncStatus.Completed && !morph.status) {
      fetchMorphAssetData()
    }
  }, [currentTab, assetStatus, fetchMorphAssetData, morph.status])

  return isMobile() ? (
    <BeforeAfterDialogMobile
      isOpen={isOpen}
      handleClose={handleClose}
      patient={patient}
      asset={asset}
      sendAssetInChat={permissions.sendChatMessages && sendAssetInChat}
      imgSrc={imgSrc}
      handleShareOptionClicked={handleShareOptionClicked}
      assetStatus={assetStatus}
      analyticsMetadat={analyticsMetadata}
      handleSubmitFeedback={handleSubmitFeedback}
      handleRetry={handleRetry}
      error={assetError}
    />
  ) : (
    <BeforeAfterDialogDesktop
      selectedPose={selectedPose}
      setSelectedPose={setSelectedPose}
      lastScanId={lastScanId}
      isOpen={isOpen}
      handleClose={handleClose}
      patient={patient}
      asset={asset}
      imgSrc={imgSrc}
      sendAssetInChat={permissions.sendChatMessages && sendAssetInChat}
      handleShareOptionClicked={handleShareOptionClicked}
      assetStatus={loadStatus}
      analyticsMetadata={analyticsMetadata}
      handleSubmitFeedback={handleSubmitFeedback}
      handleRetry={handleRetry}
      error={assetError}
      messageText={messageText}
      setMessageText={setMessageText}
      currentTab={currentTab}
      setCurrentTab={handleSelectTab}
      tabs={tabOptions}
    />
  )
}
export default BeforeAfterDialogHOC
