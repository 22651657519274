import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import BaseModal from 'components/common/modals/BaseModal'
import { Grid } from '@material-ui/core'
import BeforeAfterSharingOptions from './BeforeAfterSharingOptions'
import OggHeading40 from 'components/common/text/OggHeading40'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import ShareProgressSocialMediasIcon from 'components/common/icons/BeforeAfter/ShareProgressSocialMedias'
import ShareImageWithPatient from 'components/common/icons/BeforeAfter/ShareImageWithPatient'
import BeforeAfterHearts from 'resources/beforeAfter/before-after-hearts.png'
import BeforeAfterEmojis from 'resources/beforeAfter/before-after-emojis.png'
import BeforeAfterFeedback from './BeforeAfterFeedback'
import BeforeAfterAsset from './BeforeAfterAsset'
import GrinTabs from 'components/common/tabs/GrinTabs'
import GrinTab from 'components/common/tabs/GrinTab'
import { BeforeAfterTabs } from './BeforeAfterDialogHOC'
import { AsyncStatus } from 'consts'

const useStyles = makeStyles(theme => ({
  root: {
    width: '1400px',
    maxWidth: '1400px',
    padding: '80px 18px 0 68px'
  },
  socialMedias: {
    margin: '20px 0 12px 0'
  },
  heartsIcon: {
    top: '0px',
    left: '0px',
    position: 'absolute',
    width: '87px',
    height: '87px'
  },
  emojisIcon: {
    position: 'absolute',
    top: '0px',
    right: '22px',
    width: '109px',
    height: '73px'
  },
  sharingOptionsContainer: {
    margin: '24px 0 24px 0'
  },
  tabsContainer: {
    borderBottom: '1px solid var(--border-color-7)'
  },
  tab: {
    flexGrow: 'unset',
    flexBasis: 'unset'
  }
}))

const BeforeAfterDialog = ({
  isOpen,
  lastScanId,
  selectedPose,
  setSelectedPose,
  handleClose,
  patient,
  asset,
  sendAssetInChat,
  imgSrc,
  handleShareOptionClicked,
  assetStatus,
  analyticsMetadata,
  handleSubmitFeedback,
  handleRetry,
  error,
  messageText,
  setMessageText,
  tabs = [],
  currentTab,
  setCurrentTab = () => {}
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <BaseModal
      className={classes.root}
      open={isOpen}
      handleClose={handleClose}
      secondaryLabel={t('general.close')}
      onSecondaryBtnClick={handleClose}
      primaryLabel={sendAssetInChat ? t('dialogs.beforeAfter.submitButton') : ''}
      isPrimaryDisabled={!patient || !asset}
      largerPrimaryButton
      buttonsWidth={300}
      onPrimaryBtnClick={sendAssetInChat}
      primaryBtnIcon={<ShareImageWithPatient disabled={!asset} />}
    >
      <img src={BeforeAfterHearts} className={classes.heartsIcon} alt="before-after-hearts" />
      <img src={BeforeAfterEmojis} className={classes.emojisIcon} alt="before-after-emojis" />

      <Grid container direction="row" justifyContent="space-between" spacing={3}>
        <Grid item xs={6}>
          <OggHeading40 textAlign="start">{t('dialogs.beforeAfter.title')}</OggHeading40>
          <div className={classes.socialMedias}>
            <ShareProgressSocialMediasIcon />
          </div>
          <DazzedParagraph14 strong>
            {t('dialogs.beforeAfter.body')}
            <br />
            <br />
            {t('dialogs.beforeAfter.body_hashtags')}
          </DazzedParagraph14>
          <div className={classes.sharingOptionsContainer}>
            <BeforeAfterSharingOptions disabled={!asset} assetUrl={imgSrc} onOptionClicked={handleShareOptionClicked} />
          </div>
          {assetStatus === AsyncStatus.Completed && (
            <BeforeAfterFeedback analyticsMetadata={analyticsMetadata} onSubmit={handleSubmitFeedback} />
          )}
        </Grid>
        <Grid item xs={6}>
          <Grid container direction="column" spacing={2}>
            {tabs.length > 1 && (
              <Grid item>
                <GrinTabs
                  value={currentTab}
                  variant="fullWidth"
                  onChange={(e, value) => setCurrentTab(value)}
                  className={classes.tabsContainer}
                >
                  {tabs.map(tab => (
                    <GrinTab
                      key={tab.key}
                      label={tab.label}
                      marginRight="0px"
                      value={tab.key}
                      className={classes.tab}
                    />
                  ))}
                </GrinTabs>
              </Grid>
            )}
            <Grid item>
              <BeforeAfterAsset
                scanId={lastScanId}
                setSelectedPose={setSelectedPose}
                assetStatus={assetStatus}
                error={error}
                onRetry={handleRetry}
                imgSrc={imgSrc}
                message={messageText}
                setMessage={setMessageText}
                isMorph={currentTab === BeforeAfterTabs.Morph}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </BaseModal>
  )
}

export default BeforeAfterDialog
