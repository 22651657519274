import React, { useEffect } from 'react'
import WebappOverlayLoader from 'resources/webapp-overlay-loader.gif'
import { makeStyles } from '@material-ui/styles'
import { Backdrop } from '@material-ui/core'
import useQueryParams from 'hooks/useQueryParams'
import { useHistory } from 'react-router'
import { ROUTES } from 'consts'
import { useDispatch } from 'react-redux'
import Actions from 'actions'
import { trackEvent } from 'utils/analyticsUtils'
import moment from 'moment'

const useStyles = makeStyles(theme => ({
  loader: {
    zIndex: 999,
    backgroundColor: '#f5f5f590',
    color: 'var(--border-color-5)'
  }
}))

const Landing = props => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const { queryParams } = useQueryParams()

  const { PatientID: pmsPatientId, PatientName: pmsPatientName, pms, action } = queryParams

  useEffect(() => {
    trackEvent('PMS Redirect', { pms, pmsPatientName, pmsPatientId, action, queryParams })
  }, [pms, pmsPatientName, pmsPatientId, action, queryParams])

  useEffect(() => {
    if (action === 'CreateRecordsPatient') {
      history.push(ROUTES.PATIENTS)
      const birthDate = moment(queryParams.PatientBirthDate, 'MM/DD/YYYY')
      dispatch(
        Actions.setCreateRecordsPatientModalOpen({
          isOpen: true,
          initialValues: {
            firstName: queryParams.PatientFirstName,
            lastName: queryParams.PatientLastName,
            birthDate: birthDate.isValid() ? birthDate.toDate() : null,
            note: queryParams.PatientID
          }
        })
      )
    } else {
      if (!pmsPatientId && !pmsPatientName) {
        history.push(ROUTES.PATIENTS)
        dispatch(Actions.pmsRedirectFailed({ message: 'Missing query params', pmsPatientId, pmsPatientName }))
      } else {
        dispatch(
          Actions.pmsRedirectSearchLinkedPatient({
            pmsPatientId,
            pmsPatientName
          })
        )
      }
    }
  }, [dispatch, history, pmsPatientId, pmsPatientName, queryParams, action])

  return (
    <Backdrop className={classes.loader} open>
      <img src={WebappOverlayLoader} alt="Loader" />
    </Backdrop>
  )
}

export default Landing
