import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import EmptyStateIcon from '../Icons/EmptyStateIcon'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'

const useStyles = makeStyles(theme => ({
  label: {
    color: 'rgba(149, 156, 196, 1)'
  }
}))

const NotificationCenterEmptyState = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid container direction="column" alignItems="center" justifyContent="center" style={{ height: '100%' }}>
      <Grid item style={{ marginBottom: 8 }}>
        <EmptyStateIcon />
      </Grid>
      <Grid item>
        <DazzedParagraph16 bold className={classes.label}>
          {t('dialogs.notificationCenterDialog.emptyState.title')}
        </DazzedParagraph16>
      </Grid>
      <Grid item>
        <DazzedParagraph16 strong className={classes.label}>
          {t('dialogs.notificationCenterDialog.emptyState.subtitle')}
        </DazzedParagraph16>
      </Grid>
    </Grid>
  )
}

export default NotificationCenterEmptyState
