import * as React from 'react'

const other = ({ color = '#001335', style }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <circle cx="8" cy="8" r="6" stroke={color} />
      <circle cx="5" cy="8" r="1" fill={color} />
      <circle cx="8" cy="8" r="1" fill={color} />
      <circle cx="11" cy="8" r="1" fill={color} />
    </svg>
  )
}

export default other
