import { Grid, makeStyles } from '@material-ui/core'
import PatientStatus from 'components/Patients/PatientStatus'
import GrinHighlighter from 'components/common/GrinHighlighter'
import ProfilePicture from 'components/common/ProfilePicture'
import Tooltip from 'components/common/Tooltip'
import { Check1, Copy } from 'components/common/icons'
import React from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  resultsItem: {
    fontSize: 12,
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 3,
    marginBottom: 10,
    cursor: 'pointer',
    transition: 'background 0.3s ease',
    '&:hover': {
      background: '#e4e4e4',
      borderRadius: 6
    }
  },
  name: {
    fontSize: 14,
    fontWeight: 'bold',
    marginRight: 5
  },
  email: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  avatar: {
    marginRight: 20
  },
  detailsContainer: {
    width: '90%',
    display: 'flex',
    alignItems: 'center'
  },
  actionContainer: {
    width: '10%',
    display: 'flex',
    justifyContent: 'end'
  },
  highlighter: {
    fontSize: 30,
    backgroundColor: 'red'
  }
}))
const PatientResultItem = ({ searchTerm, patientSM, handlePatientSelected, copiedItemId, onCopyId }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid container className={classes.resultsItem}>
      <Grid xs={11} className={classes.detailsContainer} onClick={e => handlePatientSelected(e, patientSM)}>
        <Grid item xs={2}>
          <ProfilePicture
            photo={patientSM.patient?.photo}
            name={patientSM.name}
            size="medium"
            className={classes.avatar}
          />
        </Grid>
        <Grid item xs={7} onClick={e => e.stopPropagation()}>
          <Grid direction="column" style={{ cursor: 'text' }}>
            <Grid>
              <GrinHighlighter
                className={classes.name}
                searchWords={[searchTerm]}
                autoEscape={true}
                textToHighlight={patientSM.name}
              />
              <GrinHighlighter
                searchWords={[searchTerm]}
                autoEscape={true}
                textToHighlight={`(${patientSM.doctorName})`}
              />
            </Grid>
            <Grid className={classes.email}>
              <GrinHighlighter searchWords={[searchTerm]} autoEscape={true} textToHighlight={patientSM.email} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <PatientStatus status={patientSM.status} />
        </Grid>
      </Grid>
      <Grid xs={1} className={classes.actionContainer}>
        <Tooltip value={t('dialogs.quickActions.copyId')} placement="top" arrow>
          {patientSM.id === copiedItemId ? (
            <Check1 color={'green'} />
          ) : (
            <Copy onClick={() => onCopyId({ id: patientSM.id, itemType: 'patient' })} />
          )}
        </Tooltip>
      </Grid>
      <Grid></Grid>
    </Grid>
  )
}

export default PatientResultItem
