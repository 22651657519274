import React, { useCallback, useMemo, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import GrinScanTooltip from 'components/Patients/Timeline/V2/MiniTimeline/GrinScanTooltip'
import { trackEvent } from 'utils/analyticsUtils'
import { ScanFeedPageSectionNames } from 'consts/timelineConsts'
import { useSelector } from 'react-redux'
import GrinScanIcon from './ScanThemeIcons/GrinScanIcon'
import useScanOralScore from '../../useScanOralScore'

const useStyles = makeStyles(theme => ({
  grinScanTimelineItemRoot: {
    position: 'relative',
    textAlign: 'center',
    lineHeight: 0.8,
    cursor: 'pointer',
    marginBottom: 3
  }
}))

const GrinScanTimelineItem = ({
  timelineItem,
  onSelect = section => {},
  isSelected = false,
  theme,
  analyticsPayload = {}
}) => {
  const scanTooltipAnchorEl = useRef()

  const { treatment } = useSelector(state => state.treatmentReducer)
  const totalAlignerNumber = useMemo(() => treatment?.totalApplianceNumber, [treatment])
  const trackStatus = useMemo(() => timelineItem?.payload?.trackStatus, [timelineItem])
  const oralScores = useScanOralScore({ oralScores: timelineItem?.payload?.oralScores })
  const { txTrackerTeethSummary } = timelineItem?.payload?.txTrackerSummary || {}

  const [isScanTooltipOpen, setIsScanTooltipOpen] = useState(false)
  const [scanTooltipOpenTime, setScanTooltipOpenTime] = useState(null)

  const handleMouseEnter = useCallback(() => {
    setIsScanTooltipOpen(true)
    setScanTooltipOpenTime(new Date().getTime())
  }, [])

  const handleMouseLeave = useCallback(() => {
    setIsScanTooltipOpen(false)

    const totalSecondsWasOpen = (new Date().getTime() - scanTooltipOpenTime) / 1000
    if (totalSecondsWasOpen > 0.2) {
      trackEvent('Mini Timeline - scan hover', {
        ...analyticsPayload,
        scanNumber: timelineItem.payload.scanNumber,
        totalSecondsWasOpen
      })
    }
  }, [scanTooltipOpenTime, timelineItem, analyticsPayload])

  const classes = useStyles({ trackStatus })

  return (
    <div
      className={classes.grinScanTimelineItemRoot}
      ref={scanTooltipAnchorEl}
      onClick={() => onSelect()}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <GrinScanTooltip
        anchorEl={scanTooltipAnchorEl.current}
        isOpen={isScanTooltipOpen}
        date={timelineItem.eventDate}
        scanNumber={timelineItem?.payload?.scanNumber}
        alignerNumber={timelineItem?.payload?.alignerNumber}
        totalAlignerNumber={totalAlignerNumber}
        scans={timelineItem?.payload?.scans}
        scanType={timelineItem?.payload?.scanType}
        urgency={timelineItem?.payload?.urgency}
        trackStatus={trackStatus}
        oralScores={oralScores}
        onTrackReasonsClick={() => onSelect(ScanFeedPageSectionNames.ScanTracking)}
      />
      <span>
        <GrinScanIcon
          isSelected={isSelected}
          theme={theme}
          trackStatus={trackStatus}
          oralScore={oralScores.hygieneScore}
          txTrackerSummary={txTrackerTeethSummary}
        />
      </span>
    </div>
  )
}

export default React.memo(GrinScanTimelineItem)
