import * as React from 'react'

const InfoTransparent = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="7" cy="7" r="6" stroke="#727B8C" strokeWidth="0.75" />
    <path
      d="M6.35938 8.85938V8.8125C6.36458 8.3151 6.41667 7.91927 6.51562 7.625C6.61458 7.33073 6.75521 7.09245 6.9375 6.91016C7.11979 6.72786 7.33854 6.5599 7.59375 6.40625C7.7474 6.3125 7.88542 6.20182 8.00781 6.07422C8.13021 5.94401 8.22656 5.79427 8.29688 5.625C8.36979 5.45573 8.40625 5.26823 8.40625 5.0625C8.40625 4.80729 8.34635 4.58594 8.22656 4.39844C8.10677 4.21094 7.94661 4.06641 7.74609 3.96484C7.54557 3.86328 7.32292 3.8125 7.07813 3.8125C6.86458 3.8125 6.65885 3.85677 6.46094 3.94531C6.26302 4.03385 6.09766 4.17318 5.96484 4.36328C5.83203 4.55339 5.75521 4.80208 5.73438 5.10938H4.75C4.77083 4.66667 4.88542 4.28776 5.09375 3.97266C5.30469 3.65755 5.58203 3.41667 5.92578 3.25C6.27214 3.08333 6.65625 3 7.07813 3C7.53646 3 7.9349 3.09115 8.27344 3.27344C8.61458 3.45573 8.8776 3.70573 9.0625 4.02344C9.25 4.34115 9.34375 4.70313 9.34375 5.10938C9.34375 5.39583 9.29948 5.65495 9.21094 5.88672C9.125 6.11849 9 6.32552 8.83594 6.50781C8.67448 6.6901 8.47917 6.85156 8.25 6.99219C8.02083 7.13542 7.83724 7.28646 7.69922 7.44531C7.5612 7.60156 7.46094 7.78776 7.39844 8.00391C7.33594 8.22005 7.30208 8.48958 7.29688 8.8125V8.85938H6.35938ZM6.85938 11.1719C6.66667 11.1719 6.5013 11.1029 6.36328 10.9648C6.22526 10.8268 6.15625 10.6615 6.15625 10.4688C6.15625 10.276 6.22526 10.1107 6.36328 9.97266C6.5013 9.83464 6.66667 9.76562 6.85938 9.76562C7.05208 9.76562 7.21745 9.83464 7.35547 9.97266C7.49349 10.1107 7.5625 10.276 7.5625 10.4688C7.5625 10.5964 7.52995 10.7135 7.46484 10.8203C7.40234 10.9271 7.31771 11.013 7.21094 11.0781C7.10677 11.1406 6.98958 11.1719 6.85938 11.1719Z"
      fill="#727B8C"
    />
  </svg>
)

export default InfoTransparent
