import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import ActionCheckbox from './ActionCheckbox'

const PauseScans = ({ isChecked, onChange }) => {
  const { t } = useTranslation()

  const handleChecked = useCallback(
    checked => {
      onChange({ isChecked: checked })
    },
    [onChange]
  )

  return (
    <ActionCheckbox
      checked={isChecked}
      onChange={handleChecked}
      caption={t('pages.accountSettings.practiceGuidelines.actions.pauseScans')}
    />
  )
}

export default PauseScans
