import * as React from 'react'

export default () => (
  <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="7" cy="6.5" r="6" stroke="#3C52EF" />
    <path
      d="M6.30664 7.3021V7.4271H6.43164H7.11998H7.24498V7.3021V7.26871C7.24875 7.03877 7.27289 6.85306 7.31463 6.70872C7.3558 6.56635 7.42093 6.4464 7.50908 6.34662L7.50975 6.34585C7.60095 6.24088 7.72524 6.13771 7.8856 6.03744C8.06296 5.92856 8.21589 5.80249 8.34325 5.65877C8.47405 5.51334 8.57385 5.34799 8.64233 5.16353C8.7134 4.97725 8.74785 4.77177 8.74785 4.54876C8.74785 4.23059 8.67422 3.94258 8.52419 3.68823C8.37623 3.43412 8.16579 3.2348 7.89626 3.09071C7.62629 2.94533 7.31261 2.875 6.95937 2.875C6.63271 2.875 6.3319 2.93964 6.05909 3.07092L6.05909 3.07092L6.05875 3.07108C5.78655 3.20306 5.56583 3.39467 5.39852 3.64461L5.39851 3.64461L5.39812 3.64521C5.23073 3.89839 5.14131 4.19928 5.12514 4.54288L5.11898 4.67376H5.25H5.97275H6.08957L6.09747 4.55721C6.11172 4.34704 6.16355 4.18833 6.24444 4.07254C6.32992 3.95019 6.43407 3.86329 6.55726 3.80818C6.68677 3.75024 6.82045 3.72156 6.95937 3.72156C7.12126 3.72156 7.26513 3.75499 7.39333 3.81993C7.52082 3.8845 7.62146 3.97544 7.69724 4.09407C7.77055 4.20881 7.80951 4.34722 7.80951 4.51434C7.80951 4.65037 7.78547 4.77095 7.7394 4.87789L7.7394 4.87789L7.73877 4.87939C7.69286 4.98992 7.63039 5.08687 7.55132 5.1711C7.46924 5.25659 7.37662 5.3309 7.27315 5.39407C7.07852 5.51127 6.90968 5.64059 6.76773 5.78255C6.61773 5.93254 6.50545 6.12529 6.42789 6.35595C6.34931 6.58961 6.31054 6.89496 6.30665 7.26638H6.30664V7.26769V7.3021ZM7.1212 10.0384L7.12196 10.0379C7.21748 9.97965 7.29422 9.90198 7.35061 9.806C7.41023 9.70784 7.44001 9.59953 7.44001 9.48375C7.44001 9.30863 7.37583 9.15556 7.25139 9.03112C7.12695 8.90667 6.97388 8.8425 6.79876 8.8425C6.62364 8.8425 6.47057 8.90667 6.34612 9.03112C6.22168 9.15556 6.1575 9.30863 6.1575 9.48375C6.1575 9.65887 6.22168 9.81194 6.34612 9.93638C6.47057 10.0608 6.62364 10.125 6.79876 10.125C6.91532 10.125 7.02389 10.0967 7.1212 10.0384Z"
      fill="#3C52EF"
      stroke="#3C52EF"
      strokeWidth="0.25"
    />
  </svg>
)
