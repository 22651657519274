import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { generateScanNumberDict, getAggregateScans } from 'utils/scanUtils'

export default () => {
  const grinScans = useSelector(state => state.patientsReducer.patient?.grinScans?.items || [])
  const timelineItems = useSelector(state => state.timelineReducer?.mainFeed?.timelineItems?.data || [])

  const mappedTimelineItems = useMemo(
    () =>
      timelineItems.map(timelineItem => ({
        ...timelineItem,
        payload: JSON.parse(timelineItem.payload || '{}')
      })),
    [timelineItems]
  )

  const getAssociatedTimelineItem = useCallback(
    grinScanId => mappedTimelineItems?.find(item => item.payload.scans?.find(scan => scan.id === grinScanId)),
    [mappedTimelineItems]
  )

  const aggregatedScans = useMemo(() => getAggregateScans(grinScans), [grinScans])

  const scanIdToNumberDict = useMemo(() => generateScanNumberDict(aggregatedScans), [aggregatedScans])

  const getScanNumberByTimelineItemData = useCallback(
    grinScanId => getAssociatedTimelineItem(grinScanId)?.payload?.scanNumber,
    [getAssociatedTimelineItem]
  )

  return {
    scanIdToNumberDict,
    aggregatedScans,
    mappedTimelineItems,
    getScanNumberByTimelineItemData
  }
}
