import { GrinNotificationStatuses } from '@grin-rnd/grin-api-sdk/dist/Enums/Notifications'
import MarkNotificationAsRead from 'components/NotificationCenter/Icons/NotificationActions/MarkNotificationAsRead'
import MarkNotificationAsUnread from 'components/NotificationCenter/Icons/NotificationActions/MarkNotificationAsUnread'
import ClearNotification from 'components/NotificationCenter/Icons/NotificationActions/ClearNotification'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSetGrinNotificationStatusMutation } from 'store/modules/NotificationCenter'
import { Copy } from 'components/common/icons'
import { getEnvironment } from 'utils/awsUtils'

/**
 * @param {Object} props
 * @param {GrinNotification} props.grinNotification
 */
export default ({ grinNotification }) => {
  const { t } = useTranslation()
  const [setNotificationStatus, { isLoading }] = useSetGrinNotificationStatusMutation()

  const actions = useMemo(
    () =>
      [
        {
          key: 'copy-id',
          tooltip: t('dialogs.notificationCenterDialog.feed.actions.copyId'),
          icon: <Copy />,
          condition: () => getEnvironment() !== 'production',
          onClick: () => navigator.clipboard.writeText(grinNotification.id)
        },
        {
          key: 'markAsRead',
          tooltip: t('dialogs.notificationCenterDialog.feed.actions.markAsRead'),
          icon: <MarkNotificationAsRead />,
          condition: grinNotification.status === GrinNotificationStatuses.New,
          onClick: () =>
            setNotificationStatus({ grinNotificationId: grinNotification.id, status: GrinNotificationStatuses.Read })
        },
        {
          key: 'markAsUnread',
          tooltip: t('dialogs.notificationCenterDialog.feed.actions.markAsUnread'),
          icon: <MarkNotificationAsUnread />,
          condition: grinNotification.status === GrinNotificationStatuses.Read,
          onClick: () =>
            setNotificationStatus({ grinNotificationId: grinNotification.id, status: GrinNotificationStatuses.New })
        },
        {
          key: 'clear',
          tooltip: t('dialogs.notificationCenterDialog.feed.actions.clear'),
          icon: <ClearNotification />,
          condition: grinNotification.status !== GrinNotificationStatuses.Deleted,
          onClick: () =>
            setNotificationStatus({ grinNotificationId: grinNotification.id, status: GrinNotificationStatuses.Deleted })
        }
      ].filter(action => action.condition),
    [t, grinNotification, setNotificationStatus]
  )

  return { actions, isLoading }
}
