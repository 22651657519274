import React, { useMemo } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import _ from 'lodash'
import ToothGridItem from './ToothGridItem'
import { ORTHO_TEETH_LIST, ORTHO_TEETH_TO_TEETH_NUMBER } from 'consts/teethConsts'

const useStyles = makeStyles(theme => ({
  teethRow: {
    [theme.breakpoints.down('md')]: {
      margin: '0 10px 3px 0'
    },
    [theme.breakpoints.up('md')]: {
      margin: '0 10px 20px 0'
    }
  }
}))

const TeethGrid = ({ teethData, isTxGoalsEnabled, currentScan, scanNumber, treatmentType, disabled }) => {
  const classes = useStyles()

  const matrix = useMemo(() => {
    const [UL, UR, LL, LR] = _.chunk(ORTHO_TEETH_LIST, 8)
    return [
      [UL, UR],
      [LL, LR]
    ]
  }, [])

  const mappedTeethData = useMemo(
    () =>
      Object.keys(teethData).reduce(
        (finalTeethData, toothNumber) => ({
          ...finalTeethData,
          [toothNumber]: {
            ...teethData[toothNumber],
            pastMonthSpeed: teethData[toothNumber].pastMonthSpeed
          }
        }),
        {}
      ),
    [teethData]
  )

  return (
    <Grid container direction="column" justifyContent="center">
      {matrix.map((row, rowI) => (
        <Grid item key={`teeth-row-${rowI}`} className={classes.teethRow}>
          <Grid container justifyContent="center" spacing={1}>
            {row.map((section, sectionI) => (
              <Grid item key={`teeth-section-${sectionI}`} style={{ marginRight: 10 }}>
                <Grid container justifyContent="center" spacing={1}>
                  {section.map(tooth => (
                    <Grid item key={`teeth-tooth-${tooth}`}>
                      <ToothGridItem
                        currentScan={currentScan}
                        toothTitle={tooth}
                        toothNumber={ORTHO_TEETH_TO_TEETH_NUMBER[tooth]}
                        toothData={mappedTeethData[ORTHO_TEETH_TO_TEETH_NUMBER[tooth]]}
                        isTxGoalsEnabled={isTxGoalsEnabled}
                        treatmentType={treatmentType}
                        disabled={disabled}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      ))}
    </Grid>
  )
}

export default TeethGrid
