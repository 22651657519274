import React from 'react'
import { useCountry } from 'hooks/useCountry'
import FormInput from 'components/common/FormInput'
import SelectInput from 'components/common/SelectInput'
import { COUNTRY_IN } from 'consts/countryConsts'

const StateInput = ({ country, label = '', placeholder = '', disabled = false, value, setValue }) => {
  const { states, countriesWithRequiredStates } = useCountry(country)

  return country === COUNTRY_IN ? (
    <FormInput
      title={label}
      placeholder={placeholder}
      style={{ bright: true, thick: true }}
      value={value}
      setValue={setValue}
    />
  ) : (
    <SelectInput
      disabled={disabled || !states.length}
      label={label}
      style={{ bright: true, thick: true }}
      value={value}
      values={states.map(state => state.key)}
      required={countriesWithRequiredStates.includes(country)}
      keepErrorContainerWhenInactive={false}
      onChange={({ value }) => setValue(value)}
    />
  )
}

export default StateInput
