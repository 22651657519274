import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useDispatch, useSelector } from 'react-redux'
import GrinMenu from 'components/common/menu/GrinMenu'
import { useResizeDetector } from 'react-resize-detector'
import SearchInput from './SearchInput'
import { Grid } from '@material-ui/core'
import ClearFilterButton from './ClearFilterButton'
import { trackEvent } from 'utils/analyticsUtils'
import PracticeSearchLoadingState from './PracticeSearchLoadingState'
import PracticeSearchResult from './PracticeSearchResult'
import { useDebounce } from 'use-debounce'
import Actions from 'actions'
import PracticeSearchEmptyState from './PracticeSearchEmptyState'

/**
 * @typedef {Object} PracticeSearchResult
 * @property {string} id - The ID of the practice.
 * @property {string} email - The email of the account owner.
 * @property {string} name - The personal name of the account owner.
 * @property {string} practiceName - The name of the practice.
 * @property {S3Object} photo - The profile picture of the account owner / practice.
 *
 * @typedef {React.FC<{isOpen: boolean, selectedPractice?: PracticeSearchResult}>} TriggerComponent
 * @typedef {React.FC<{value: string, setValue: (string) => void}>} SearchInputComponent
 * @typedef {React.FC<{searchResult: PracticeSearchResult, className: string, onClick: () => void}>} SearchResultComponent
 */

const useStyles = makeStyles(theme => ({
  practiceSearchMenu: {
    width: ({ width }) => width || 'auto',
    boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.3)',
    padding: '8px 0'
  },
  menuItem: {
    padding: '8px 16px',
    width: '100%'
  }
}))

/**
 * A modular component to search for practices in Grin.
 * @param {Object} props
 * @param {string} [props.selectedPracticeId] - The ID of the selected practice.
 * @param {(selectedPractice: PracticeSearchResult) => void} props.onSelect - A callback function to be called when a practice is selected.
 * @param {number} [props.debounceTime] - The debounce time for the search input.
 * @param {Object} [props.analyticsPayload] - An object containing analytics data.
 * @param {React.RefObject} [props.customAnchorEl] - A reference to the anchor element for the `GrinMenu`.
 * @param {Object} [props.transformOrigin] - An object containing the transform origin for the `GrinMenu`.
 * @param {string} [props.className] - A class name to be applied to the `GrinMenu`.
 * @param {string} [props.menuItemClassName] - A class name to be applied to each row in the `GrinMenu`.
 * @param {TriggerComponent} [props.TriggerComponent] - A component used as the trigger for the `GrinMenu`.
 * @param {SearchInputComponent} [props.SearchInputComponent] - A component used as the search input.
 * @param {SearchResultComponent} [props.SearchResultComponent] - A component used to display a search result.
 * @param {React.ComponentClass} [props.LoadingStateComponent] - A component used to display loading state.
 * @param {React.ComponentClass} [props.EmptyStateComponent] - A component used to display an empty state.
 * @returns
 */
const PracticeSearchV3 = ({
  selectedPracticeId = null,
  onSelect = () => {},
  debounceTime = 300,
  analyticsPayload = {},
  customAnchorEl,
  transformOrigin,
  className = '',
  menuItemClassName = '',
  TriggerComponent,
  SearchInputComponent = SearchInput,
  SearchResultComponent = PracticeSearchResult,
  LoadingStateComponent = PracticeSearchLoadingState,
  EmptyStateComponent = PracticeSearchEmptyState
}) => {
  const dispatch = useDispatch()

  const { width } = useResizeDetector({ targetRef: customAnchorEl })
  const { doctorSearchIsLoading: isLoading, doctorSearchResults: results } = useSelector(state => state.appReducer)

  const [isOpen, setIsOpen] = useState(false)
  const [searchInput, setSearchInput] = useState('')
  const [debouncedSearchInput] = useDebounce(searchInput, debounceTime)

  const selectedPractice = useMemo(
    () => results.find(result => result.id === selectedPracticeId),
    [results, selectedPracticeId]
  )

  const fetchResults = useCallback(() => {
    dispatch(Actions.requestSearchDoctor(debouncedSearchInput))
  }, [debouncedSearchInput, dispatch])

  const setSelection = useCallback(
    selectedPractice => {
      onSelect(selectedPractice)
    },
    [onSelect]
  )

  const handleSelectPractice = useCallback(
    selectedPractice => {
      setSelection(selectedPractice)
      setIsOpen(false)
      trackEvent('Practice Search - practice selected', {
        ...analyticsPayload,
        practice: selectedPractice
      })
    },
    [setSelection, analyticsPayload]
  )

  const handleClearFilter = useCallback(() => {
    setSelection(null)
    setIsOpen(false)
    setSearchInput('')
    trackEvent('Practice Search - clear filter button clicked', analyticsPayload)
  }, [setSelection, analyticsPayload])

  const handleOpen = useCallback(() => {
    setIsOpen(true)
    trackEvent('Practice Search - menu opened', analyticsPayload)
  }, [analyticsPayload])

  const handleClose = useCallback(() => {
    setIsOpen(false)
    trackEvent('Practice Search - menu closed', analyticsPayload)
  }, [analyticsPayload])

  useEffect(() => {
    if (isOpen) {
      fetchResults()
    }
  }, [debouncedSearchInput, fetchResults, isOpen])

  const classes = useStyles({ width })
  const menuItemClass = useMemo(
    () => [classes.menuItem, menuItemClassName].join(' '),
    [classes.menuItem, menuItemClassName]
  )

  return (
    <GrinMenu
      open={isOpen}
      onOpen={handleOpen}
      onClose={handleClose}
      triggerComponent={React.createElement(TriggerComponent, {
        isOpen,
        selectedPractice
      })}
      transformOrigin={transformOrigin}
      customAnchorEl={customAnchorEl.current}
      className={[classes.practiceSearchMenu, className].join(' ')}
    >
      <Grid container direction="column">
        <Grid item className={menuItemClass}>
          {React.createElement(SearchInputComponent, {
            value: searchInput,
            setValue: setSearchInput
          })}
        </Grid>
        <Grid item>
          <ClearFilterButton onClick={handleClearFilter} className={menuItemClass} />
        </Grid>
        {isLoading ? (
          <Grid item className={menuItemClass}>
            {React.createElement(LoadingStateComponent)}
          </Grid>
        ) : results.length === 0 ? (
          <Grid item className={menuItemClass}>
            {React.createElement(EmptyStateComponent)}
          </Grid>
        ) : (
          results.map(result => (
            <Grid item key={result.id}>
              {React.createElement(SearchResultComponent, {
                searchResult: result,
                className: menuItemClass,
                onClick: () => handleSelectPractice(result)
              })}
            </Grid>
          ))
        )}
      </Grid>
    </GrinMenu>
  )
}

export default PracticeSearchV3
