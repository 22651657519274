import Actions from 'actions'
import React, { useEffect, useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { CircularProgress, Grid } from '@material-ui/core'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { AsyncStatus, ROUTES } from 'consts'
import DSOAdmins from './DSOAdmins'
import DSOPractices from './DSOPractices'
import OggHeading40 from 'components/common/text/OggHeading40'
import { useTranslation } from 'react-i18next'
import useRolePermissions from 'hooks/useRolePermissions'
import useFeatureFlags from 'hooks/useFeatureFlags'
import DSOInvitedDoctors from './DSOInvitedDoctors'

const useStyles = makeStyles(theme => ({
  dsoMembersContainer: {
    minWidth: 850
  }
}))

const DSOMembers = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()
  const history = useHistory()

  const { doctor } = useSelector(state => state.profileReducer)
  const { data: dsoPracticesData, status } = useSelector(state => state.multiPracticeReducer.dsoPractices)

  const { permissions } = useRolePermissions()
  const { dsoInvitePractice: dsoInvitePracticeFF } = useFeatureFlags()

  const isLoading = useMemo(() => {
    // The page is waiting for the doctor data to load before making the request for the DSO data,
    // so while this is happening we need show a loader.
    return status === AsyncStatus.Loading || !doctor?.id
  }, [status, doctor])

  useEffect(() => {
    if (!permissions.dsoMembers) {
      history.push(ROUTES.ACCOUNT_SETTINGS)
    }
  }, [history, permissions.dsoMembers, doctor])

  useEffect(() => {
    if (!dsoPracticesData && doctor?.id) {
      dispatch(Actions.mpFetchDsoPractices())
    }
  }, [dispatch, dsoPracticesData, doctor])

  return isLoading ? (
    <Grid container justifyContent="center">
      <CircularProgress color="secondary" />
    </Grid>
  ) : (
    <Grid container direction="column" className={classes.dsoMembersContainer}>
      <Grid item>
        <OggHeading40 textAlign="left">{t('pages.accountSettings.dsoMembers.title')}</OggHeading40>
      </Grid>
      {dsoPracticesData && (
        <>
          {dsoInvitePracticeFF && (
            <Grid item>
              <DSOInvitedDoctors invitedPractices={dsoPracticesData.invitedPractices} />
            </Grid>
          )}
          <Grid item>
            <DSOAdmins dsoUsers={dsoPracticesData.dsoUsers} />
          </Grid>
          <Grid item>
            <DSOPractices practices={dsoPracticesData.practices} invitedPractices={dsoPracticesData.invitedPractices} />
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default DSOMembers
