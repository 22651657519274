import React from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  disabledOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: ({ backgroundColor }) => backgroundColor,
    opacity: ({ opacity }) => opacity
  }
}))

/**
 * Creates a color overlay to make a disabled effect.
 *
 * The parent element must have `position: relative`
 * @prop className - a className that will be added to the root element.
 * @prop backgroundColor - the tint of the overlay effect. Default is white.
 * @prop opacity - The strength of the overlay effect. Default is 0.5.
 */
const DisabledOverlay = ({ className = '', backgroundColor = '#fff', opacity = 0.5 }) => {
  const classes = useStyles({ backgroundColor, opacity })

  return <div className={[classes.disabledOverlay, className].join(' ')} />
}

export default DisabledOverlay
