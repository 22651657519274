import { createSlice } from '@reduxjs/toolkit'
import Actions from 'actions'
import { NotificationCenterTabs, NotificationCenterTabsConfiguration } from 'consts/notificationCenterConsts'
import { bulkAction } from 'store/modules/NotificationCenter'
import { extractBulkActionSettings } from 'utils/notificationCenterUtils'
import { GrinNotificationBulkActions } from '@grin-rnd/grin-api-sdk/dist/Enums/Notifications'

const initialState = {
  isOpen: false,
  selectedTab: NotificationCenterTabs.All,
  filters: {
    notificationTypes: [],
    unreadOnly: true,
    lastMarkAllAsReadDate: null,
    lastClearAllDate: null,
    practiceId: null
  },
  isBulkActionInProgress: false
}

const notificationCenterSlice = createSlice({
  name: 'notificationCenter',
  initialState,
  reducers: {
    setNotificationCenterOpen: (state, { payload }) => {
      state.isOpen = payload.isOpen
    },
    switchTab: (state, { payload }) => {
      state.selectedTab = payload.tab
      state.filters.notificationTypes = NotificationCenterTabsConfiguration[payload.tab].NotificationTypes
    },
    toggleUnreadOnly: (state, { payload }) => {
      state.filters.unreadOnly = payload
    },
    setSelectedPracticeId: (state, { payload }) => {
      state.filters.practiceId = payload
    }
  },
  extraReducers: builder => {
    builder.addCase(Actions.DOCTOR_DETAILS_RECEIVED, (state, { payload }) => {
      const { lastClearAllDate, lastMarkAllAsReadDate } = extractBulkActionSettings(payload.doctor?.user?.appSettings)
      state.filters.lastMarkAllAsReadDate = lastMarkAllAsReadDate
      state.filters.lastClearAllDate = lastClearAllDate
    })
    builder.addMatcher(bulkAction.matchPending, state => {
      state.isBulkActionInProgress = true
    })
    builder.addMatcher(bulkAction.matchFulfilled, (state, { payload }) => {
      state.isBulkActionInProgress = false
      state.filters.lastMarkAllAsReadDate = payload[GrinNotificationBulkActions.MarkAllAsRead]?.lastRequestedAt
      state.filters.lastClearAllDate = payload[GrinNotificationBulkActions.ClearAll]?.lastRequestedAt
    })
    builder.addMatcher(bulkAction.matchRejected, state => {
      state.isBulkActionInProgress = false
    })
  }
})

export const { setNotificationCenterOpen, switchTab, toggleUnreadOnly, setSelectedPracticeId } =
  notificationCenterSlice.actions

export default notificationCenterSlice.reducer
