import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import NeedsAttention from 'components/common/icons/NeedsAttention'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import usePatientBriefQuickActions from '../usePatientBriefQuickActions'
import PatientBriefPracticeView from './PatientBriefPracticeView/PatientBriefPracticeView'
import PracticeReplySection from './PracticeReplySection'
import reasonIcons from 'components/common/icons/notifyPractice/index'
import useRolePermissions from 'hooks/useRolePermissions'
import useFeatureFlags from 'hooks/useFeatureFlags'
import DisabledOverlay from 'components/common/DisabledOverlay'
import Tooltip from 'components/common/Tooltip'

const useStyles = makeStyles(theme => ({
  needsAttentionHeader: {
    padding: '10px 20px',
    borderRadius: '10px 10px 0px 0px',
    background: '#FFF2D7',
    width: '100%',
    display: 'flex',
    alignItems: 'flex-end'
  },
  needsAttentionTitle: {
    color: '#DB615D',
    fontSize: 16,
    fontWeight: 600,
    marginLeft: 10
  },
  needsAttentionBody: {
    padding: '10px 20px',
    borderRadius: '0px 0px 10px 10px',
    background: '#FFFAF0'
  },
  needsAttentionReasonContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  needsAttentionReason: {
    paddingLeft: 5,
    fontWeight: 500,
    color: '#727B8C'
  },
  needsAttentionNote: {
    fontWeight: 500,
    paddingBottom: 10
  },
  practiceReply: {
    border: '1px solid #B2B2B2',
    borderRadius: 10
  },
  practiceActionsContainer: {
    marginTop: 5
  },
  container: {
    position: 'relative'
  }
}))

const PatientBriefQuickActionsContent = ({
  reason,
  note,
  patientId,
  needsAttentionTagApplied,
  actionable,
  disabledOverlayTooltip
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { permissions } = useRolePermissions()
  const { hiTools: hiToolsFF } = useFeatureFlags()
  const { handleResolveNeedsAttention } = usePatientBriefQuickActions({ patientId })
  const { isSaving } = useSelector(state => state.practiceReducer.resolveNeedsAttention)

  return (
    <Grid container direction="column">
      {needsAttentionTagApplied && (
        <div className={classes.container}>
          <Tooltip
            arrow
            placement="bottom"
            disableTooltip={actionable || !disabledOverlayTooltip}
            value={disabledOverlayTooltip}
          >
            {!actionable && <DisabledOverlay />}
            <Grid item>
              <Grid container direction="row" className={classes.needsAttentionHeader}>
                <Grid item>
                  <NeedsAttention />
                </Grid>
                <Grid item>
                  <DazzedParagraph14 className={classes.needsAttentionTitle}>
                    {t(`common.tags.needsAttention`)}
                  </DazzedParagraph14>
                </Grid>
              </Grid>
              <Grid container direction="column" className={classes.needsAttentionBody}>
                <Grid item className={classes.needsAttentionReasonContainer}>
                  {React.createElement(reasonIcons[reason])}
                  <DazzedParagraph14 className={classes.needsAttentionReason}>
                    {t(`dialogs.notifyPractice.reasons.${reason}`)}
                  </DazzedParagraph14>
                </Grid>
                {note && (
                  <Grid item>
                    <DazzedParagraph14 className={classes.needsAttentionNote}>{note}</DazzedParagraph14>
                  </Grid>
                )}
                {permissions.needsAttentionResolving && (
                  <PracticeReplySection
                    onSubmit={handleResolveNeedsAttention}
                    isSaving={isSaving}
                    patientId={patientId}
                    reason={reason}
                  />
                )}
              </Grid>
            </Grid>
          </Tooltip>
        </div>
      )}
      {hiToolsFF && (
        <Grid item>
          <PatientBriefPracticeView />
        </Grid>
      )}
    </Grid>
  )
}

export default PatientBriefQuickActionsContent
