import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import BasePrompt from './Prompts/BasePrompt'
import InvalidResultsWarningIcon from 'components/common/icons/treatmentTracker/InvalidResultsWarningIcon'
import { trackEvent } from 'utils/analyticsUtils'
import moment from 'moment'

const useStyles = makeStyles(theme => ({
  invalidResultsPromptRoot: {
    background: 'rgba(255, 242, 215, 1)'
  }
}))

const InvalidResultsPrompt = ({
  onRerunRequestClicked = () => {},
  rerunRequest = {},
  originalScanTxTrackerDetails = {},
  treatmentType,
  grinScan = {}
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [isOpen, setIsOpen] = useState(false)

  const isRerunRequestPending = useMemo(() => rerunRequest?.status === 'pending', [rerunRequest])

  const areResultsInvalid = useMemo(() => {
    if (treatmentType === 'Braces') {
      return false
    }

    // For backwards compatibility, we first check that the originalScanTxTrackerDetails object actually has values before comparing them.
    if (
      originalScanTxTrackerDetails.withAligner !== undefined &&
      originalScanTxTrackerDetails.withAligner !== grinScan.withAligner
    ) {
      return true
    }

    if (
      originalScanTxTrackerDetails.applianceIndex !== undefined &&
      originalScanTxTrackerDetails.applianceIndex !== Number(grinScan.applianceIndex)
    ) {
      return true
    }

    return false
  }, [originalScanTxTrackerDetails, grinScan, treatmentType])

  const handleDismiss = useCallback(() => {
    trackEvent('Treatment Tracker - invalid results prompt dismissed')
    setIsOpen(false)
  }, [])

  useEffect(() => {
    setIsOpen(areResultsInvalid)
  }, [areResultsInvalid])

  return (
    <BasePrompt
      className={classes.invalidResultsPromptRoot}
      icon={<InvalidResultsWarningIcon />}
      isOpen={isOpen}
      onDismiss={handleDismiss}
      text={
        isRerunRequestPending
          ? t('dialogs.treatmentTracker.messages.rerunRequestPending', {
              requestedAt: moment(rerunRequest.requestedAt).format('lll')
            })
          : t('dialogs.treatmentTracker.invlidResultsPrompt.promptText')
      }
      buttonLabel={isRerunRequestPending ? '' : t('dialogs.treatmentTracker.invlidResultsPrompt.requestReRunButton')}
      buttonOnClick={isRerunRequestPending ? null : onRerunRequestClicked}
    />
  )
}

export default InvalidResultsPrompt
