import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { ButtonBase, Grid } from '@material-ui/core'
import ProfilePicture from 'components/common/ProfilePicture'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'

const useStyles = makeStyles(theme => ({
  resultItemRoot: {
    justifyContent: 'flex-start'
  }
}))

/**
 * @type {import('./PracticeSearchV3').SearchResultComponent}
 */
const PracticeSearchResult = ({ searchResult = {}, className = '', onClick }) => {
  const classes = useStyles()

  return (
    <ButtonBase className={[classes.resultItemRoot, className].join(' ')} onClick={onClick}>
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <ProfilePicture name={searchResult.practiceName} photo={searchResult.photo} size="small" />
        </Grid>
        <Grid item>
          <DazzedParagraph14 color="black" strong>
            {searchResult.practiceName}
          </DazzedParagraph14>
        </Grid>
      </Grid>
    </ButtonBase>
  )
}

export default PracticeSearchResult
