import * as React from 'react'

const OtherMethodIsSetupWarning = () => (
  <svg width="105" height="105" viewBox="0 0 105 105" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="17" width="88" height="88" rx="16" fill="#F7F7F7" />
    <path
      d="M29 59.3333V46H42.3333V59.3333H29ZM32.3333 56H39V49.3333H32.3333V56ZM29 76V62.6667H42.3333V76H29ZM32.3333 72.6667H39V66H32.3333V72.6667ZM45.6667 59.3333V46H59V59.3333H45.6667ZM49 56H55.6667V49.3333H49V56ZM55.6667 76V72.6667H59V76H55.6667ZM45.6667 66V62.6667H49V66H45.6667ZM49 69.3333V66H52.3333V69.3333H49ZM45.6667 72.6667V69.3333H49V72.6667H45.6667ZM49 76V72.6667H52.3333V76H49ZM52.3333 72.6667V69.3333H55.6667V72.6667H52.3333ZM52.3333 66V62.6667H55.6667V66H52.3333ZM55.6667 69.3333V66H59V69.3333H55.6667Z"
      fill="#001335"
    />
    <circle cx="83" cy="22" r="19" fill="#3C52EF" stroke="white" strokeWidth="6" />
    <rect x="81" y="12" width="4" height="13" fill="white" />
    <rect x="81" y="28" width="4" height="4" fill="white" />
  </svg>
)

export default OtherMethodIsSetupWarning
