import moment from 'moment'
import momenttz from 'moment-timezone'
import { removePlus } from '../stringUtils'
import { termsVersion } from 'components/Auth/TermsDoc'
import { objectsDiff } from '../generalUtils'
import { isEmpty } from 'lodash'
import i18n from 'resources/locales/i18n'

export const mapToCreateDoctorInput = ([action, { authReducer }]) => {
  const {
    name,
    firstName,
    lastName,
    degrees,
    username,
    emailAddress,
    address,
    address2,
    practiceName,
    city,
    zip,
    state,
    country,
    phoneValue,
    countryCode,
    practicePhoneValue,
    practiceCountryCode,
    practiceEmailAddress,
    hasLocatorConsent,
    clinicHomepageUrl,
    bio,
    clinicLogo,
    roleDescription,
    photo
  } = authReducer
  return {
    name: firstName ? `${firstName} ${lastName}` : name,
    degrees,
    username,
    email: emailAddress,
    hasLocatorConsent,
    phoneNumber: phoneValue ? phoneValue.substring(removePlus(countryCode).length) : '',
    countryCode: countryCode ? '+' + removePlus(countryCode) : '',
    bio,
    roleDescription,
    photo,
    clinic: {
      practiceName,
      address1: address,
      address2,
      city,
      zip,
      state,
      country,
      phone: practicePhoneValue ? practicePhoneValue.substring(removePlus(countryCode).length) : '',
      countryCode: practiceCountryCode ? '+' + removePlus(practiceCountryCode) : '',
      practicePhone: practicePhoneValue ? practicePhoneValue.substring(removePlus(countryCode).length) : '',
      practiceCountryCode: practiceCountryCode ? '+' + removePlus(practiceCountryCode) : '',
      email: practiceEmailAddress,
      homepageUrl: clinicHomepageUrl,
      logo: clinicLogo
    }
  }
}

export const mapToCreateDoctorInputQuickSignUp = values => {
  return {
    billingInfo: {
      paymentMethodId: values.billingInfo.paymentMethodId
    },
    practiceCountry: values.country,
    doctorDto: mapToCreateDoctorInput([
      null,
      {
        authReducer: {
          ...values,
          degrees: null
        }
      }
    ]),
    timezone: momenttz.tz.guess(),
    termsVersion: termsVersion(values.country),
    groups: values.groups?.map(g => g.key),
    password: values.password
  }
}

export const getFullAddress = clinic =>
  [clinic?.address1, clinic?.address2, clinic?.city, clinic?.state, clinic?.zip, clinic?.country]
    .filter(prop => prop)
    .join(', ')

export const mapToDoctorsDashboard = (doctors, relevantFFs) =>
  doctors.map(doctor => {
    const contacts = doctor.user?.messagingPreferences?.contacts
    const contactsToDashboard = (contacts || []).reduce((final, contact, i) => {
      return {
        ...final,
        [`contact${i + 1}Email`]: contact?.email,
        [`contact${i + 1}Phone`]: contact?.phone
      }
    }, {})
    const featureFlags = JSON.parse(doctor.user?.featureFlags?.flags || '{}')
    const flags = Object.keys(featureFlags)
      .filter(flag => !!featureFlags[flag] && relevantFFs.includes(flag))
      .map(flag => i18n.t(`featureFlags.${flag}`))
      .join(',')

    return {
      id: doctor.id,
      name: doctor.name,
      grinPlanKey: doctor.accountOwnerId ? doctor.accountOwner?.user?.grinPlanKey : doctor.user?.grinPlanKey,
      email: doctor.email,
      fullAddress: getFullAddress(doctor.clinic),
      phone: doctor.clinic?.phone,
      practiceName: doctor.clinic?.practiceName || doctor.accountOwner?.clinic?.practiceName,
      clinicEmail: doctor.clinic?.email || doctor.accountOwner?.email || doctor.email,
      clinicPhone: doctor.clinic?.phone,
      createdAt: moment(doctor.createdAt).format('LL'),
      program: doctor.user?.program || '',
      _deleted: doctor._deleted,
      featureFlags: flags || '',
      termsVersion: doctor.user?.termsVersion,
      groups: doctor.user?.groups?.filter(group => !!group).join(', '),
      jobTitle: doctor.roleDescription,
      ...(contactsToDashboard || {})
    }
  })

export const mapToDoctorPlansModel = doctorPlans => ({
  ...doctorPlans,
  email: doctorPlans.email?.toLowerCase()
})

export const mapToUpdateDoctorInput = ({
  user,
  patients,
  billingInfo,
  _deleted,
  _lastChangedAt,
  updatedAt,
  tags,
  ...restDoctor
}) => {
  delete restDoctor.messageTemplates
  delete restDoctor.accountOwner
  delete restDoctor.user
  delete restDoctor.patients
  delete restDoctor.tags
  delete restDoctor.patients
  delete restDoctor.leads
  return restDoctor
}

export const mapToMemberObject = member => ({
  name: `${member?.firstName} ${member?.lastName}`,
  firstName: member?.firstName,
  lastName: member?.lastName,
  accessType: member?.accessType,
  dateAdded: member?.createdAt,
  username: member?.username,
  status: member?.auth_status,
  addedBy: member?.addedBy,
  email: member?.email,
  id: member?.id,
  grinUserId: member?.user?.id
})

export const mapDSOToMemberObject = member => ({
  ...member,
  status: member?.auth_status,
  grinUserId: member?.user?.id
})

export const mapToPracticeMembersCollection = ({ membersLeads = [], confirmedMembers = [], accountOwner }) => {
  const members = [accountOwner, ...confirmedMembers]
  return members
    .filter(members => members.auth_status !== 'canceled')
    .map(member => ({
      name: member.name,
      accessType: member.id === accountOwner.id ? 'owner' : member.accessType,
      roleDescription: member.roleDescription,
      dateAdded:
        member.id === accountOwner.id
          ? accountOwner.createdAt
          : (membersLeads.find(invitedMember => invitedMember.email === member.email) || {}).createdAt,
      status: member.id === accountOwner.id ? 'confirmed' : member.auth_status,
      addedBy: (membersLeads.find(invitedMember => invitedMember.email === member.email) || {}).addedBy,
      username: member.username,
      id: member.id,
      email: member.email,
      phoneNumber: member.phoneNumber || '',
      countryCode: member.countryCode || '',
      _version: member._version,
      messagingPreferences: member?.user?.messagingPreferences,
      avatar: member.photo,
      grinUserId: member.user?.id
    }))
    .concat(membersLeads.filter(member => member.auth_status === 'invited').map(mapToMemberObject))
}

export const mapToUpdatePracticeMemberInputForSupport = (initial, forVerify) => {
  const { phoneNumber, countryCode, accessType, roleDescription, name, contactEmail, id, _version } = forVerify
  let inputs = {}

  const details = objectsDiff(
    {
      phoneNumber: phoneNumber.replace('+', '').slice(countryCode.replace('+', '').length),
      countryCode,
      accessType,
      roleDescription,
      name
    },
    initial
  )

  if (!isEmpty(details)) {
    inputs = {
      ...inputs,
      details: {
        ...details,
        id,
        _version
      }
    }
  }

  if (initial.messagingPreferences?.contacts[0]?.email !== contactEmail) {
    inputs = {
      ...inputs,
      messagingPreferences: {
        id: initial.messagingPreferences.id,
        _version: initial.messagingPreferences._version,
        contacts: [
          {
            ...initial.messagingPreferences?.contacts[0],
            email: contactEmail
          }
        ]
      }
    }
  }

  return inputs
}

export const updateSelectedDoctor = (updatedData, selectedDoctor) => {
  if (!selectedDoctor) {
    return null
  }
  const { doctor, messagingPreferences } = updatedData

  if (selectedDoctor && doctor?.id === selectedDoctor.id) {
    return {
      ...selectedDoctor,
      ...doctor,
      user: {
        ...selectedDoctor.user,
        ...doctor.user,
        messagingPreferences: messagingPreferences ?? selectedDoctor.user.messagingPreferences
      }
    }
  }

  if (selectedDoctor && doctor?.id === selectedDoctor.accountOwnerId) {
    return {
      ...selectedDoctor,
      accountOwner: {
        ...selectedDoctor.accountOwner,
        ...doctor,
        user: {
          ...selectedDoctor.accountOwner.user,
          ...doctor.user,
          messagingPreferences: messagingPreferences ?? selectedDoctor.accountOwner.user.messagingPreferences
        }
      }
    }
  }

  return selectedDoctor
}

export const mapToCreatePracticeMemberInput = practiceMember => {
  delete practiceMember.errors
  delete practiceMember.isLoading
  delete practiceMember.password
  delete practiceMember.passwordConfirmation
  delete practiceMember.country
  practiceMember.name = practiceMember.firstName + ' ' + practiceMember.lastName
  delete practiceMember.firstName
  delete practiceMember.lastName

  return practiceMember
}

const extractServicesFromAccountOwner = accountOwner => {
  return {
    rc: {
      flagTurnedOn: !!JSON.parse(accountOwner?.user?.featureFlags?.flags || '{}').remoteConsultation,
      available: !!JSON.parse(accountOwner?.user?.appSettings || '{}').isRcEnabled
    },
    ortho: {
      flagTurnedOn: !!JSON.parse(accountOwner?.user?.featureFlags?.flags || '{}').ortho,
      available: !!JSON.parse(accountOwner?.user?.appSettings || '{}').isOrthoEnabled
    },
    doctorLocator: {
      available: accountOwner?.hasLocatorConsent,
      bio: accountOwner?.bio
    }
  }
}

export const mapToCombinedDoctorPracticeEntity = fullDoctor => {
  if (!fullDoctor) {
    return {}
  }

  const accountOwner = fullDoctor.accountOwner || fullDoctor
  const practiceDetails = accountOwner.clinic
  const { clinic, ...doctor } = fullDoctor
  const { grinPlanKey, planOverrides, stripeCustomerId } = accountOwner.user

  return {
    doctor,
    accountOwner,
    practice: {
      details: practiceDetails,
      billing: {
        grinPlanKey,
        planOverrides,
        stripeCustomerId
      },
      services: {
        ...extractServicesFromAccountOwner(accountOwner)
      }
    }
  }
}
