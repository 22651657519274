import * as React from 'react'

const SortArrows = props => {
  return (
    <svg width="9" height="18" viewBox="0 0 9 18" fill="none" xmlns="http://www.w3.org/2000/svg" style={props.style}>
      <path d="M1 5.5L4.5 2L8 5.5" stroke="#3C52EF" strokeWidth="1.5" />
      <path d="M4.5 2V16.5" stroke="#3C52EF" strokeWidth="1.5" />
    </svg>
  )
}

export default SortArrows
