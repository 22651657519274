import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'

/**
 * A custom React hook that calculates the relative time (`fromNow`) of a given date and updates it at a specified interval.
 *
 * @param {Object} props
 * @param {import('moment').MomentInput} props.date
 * @param {number} props.refreshInterval
 * @param {boolean} props.withoutSuffix - Controls whether the "ago" suffix is added.
 * @returns {string} The relative time string (e.g. "5 minutes")
 */
const useDynamicTimeAgo = ({ date, refreshInterval = 10000, withoutSuffix = false }) => {
  const [dateLabel, setDateLabel] = useState('')

  const updateDateLabel = useCallback(() => {
    setDateLabel(moment(date).fromNow(withoutSuffix))
  }, [date, withoutSuffix])

  useEffect(() => {
    updateDateLabel()

    if (moment(date).diff(moment(), 'day') >= 1) {
      return null
    }

    const interval = setInterval(updateDateLabel, 30000)
    return () => clearInterval(interval)
  }, [updateDateLabel, date])

  return dateLabel
}

export default useDynamicTimeAgo
