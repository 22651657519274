import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import GrinFloatingPopup from 'components/common/menu/GrinFloatingPopup'
import DazzedParagraph11 from 'components/common/text/DazzedParagraph11'
import { TreatmentTypes } from 'consts/treatmentConsts'
import React, { useCallback, useMemo, useState } from 'react'
import { trackEvent } from 'utils/analyticsUtils'
import { TeethTrackingStatuses } from '../txTrackerConsts'
import ToothPopup from './ToothTooltip/ToothPopup'
import useTeethTracking from './useTeethTracking'

const progressStyle = {
  borderRadius: 10,
  width: '100%',
  backgroundColor: '#BDD3EE',
  height: 4
}

const useStyles = ({ backgroundColor, borderColor, progressPrecantage }) =>
  makeStyles(theme => ({
    toothPopupContainer: {
      padding: 20
    },
    status: {
      position: 'relative',
      borderRadius: 7,
      border: ({ borderColor }) => `2px solid ${borderColor ? borderColor : 'var(--border-color-38)'}`,
      backgroundColor: ({ backgroundColor }) => backgroundColor || 'transparent',
      [theme.breakpoints.down('md')]: {
        width: 40,
        height: 28
      },
      [theme.breakpoints.up('md')]: {
        width: 48,
        height: 32
      },
      cursor: 'pointer'
    },
    toothLabel: {
      color: 'var(--text-color-61)'
    },
    progressContainer: {
      position: 'relative',
      marginTop: 2,
      zIndex: 1,
      ...progressStyle
    },
    progress: {
      ...progressStyle,
      position: 'absolute',
      width: ({ progressPrecantage }) => `${progressPrecantage}%`,
      top: 0,
      left: 0,
      zIndex: 2,
      backgroundColor: '#6F8BC7'
    },
    tooltip: {
      maxWidth: '1000px !important'
    },
    asterisk: {
      fontSize: 17,
      color: '#858585',
      fontWeight: 500,
      fontFamily: 'inter',
      position: 'absolute',
      right: 2,
      top: -4
    }
  }))({ borderColor, backgroundColor, progressPrecantage })

const ToothGridItem = ({
  toothTitle,
  toothNumber,
  toothData,
  isTxGoalsEnabled,
  currentScan,
  treatmentType,
  disabled
}) => {
  const { getToothTrackingStatus, getAsteriskData, trackingStatuses, getToothMovementPrecantage } = useTeethTracking()

  const [showPopup, setShowPopup] = useState(false)

  const toothTrackingStatus = useMemo(
    () => getToothTrackingStatus({ toothData, treatmentType }),
    [getToothTrackingStatus, toothData, treatmentType]
  )
  const { shouldApplyAsterisk } = useMemo(() => getAsteriskData(toothData), [getAsteriskData, toothData])
  const shouldShowToothIcon = useMemo(
    () =>
      treatmentType === TreatmentTypes.Aligners ||
      toothTrackingStatus === TeethTrackingStatuses.MissingTooth ||
      toothData?.pastMonthSpeed ||
      toothData?.pastMonthSpeed === 0 ||
      !toothData?.pastMonthSpeed ||
      toothData?.unknown,
    [toothData, treatmentType, toothTrackingStatus]
  )

  const currentToothTrackingStatus = useMemo(
    () => trackingStatuses[toothTrackingStatus],
    [toothTrackingStatus, trackingStatuses]
  )

  const handleClick = useCallback(() => {
    if (
      disabled ||
      toothTrackingStatus === TeethTrackingStatuses.MissingTooth ||
      toothTrackingStatus === TeethTrackingStatuses.Untracked ||
      toothTrackingStatus === TeethTrackingStatuses.Unknown
    ) {
      return
    }

    trackEvent('Treatment tracker modal - Teeth tracking - Tooth clicked', {
      toothNumber
    })
    setShowPopup(true)
  }, [toothTrackingStatus, toothNumber, disabled])

  const toothMovementPrecentage = useMemo(() => {
    if (
      toothTrackingStatus === TeethTrackingStatuses.MissingTooth ||
      toothTrackingStatus === TeethTrackingStatuses.Untracked
    ) {
      return null
    }

    const { currentProgress, totalPlannedMovement } = toothData

    return getToothMovementPrecantage({ currentProgress, totalPlannedMovement })
  }, [getToothMovementPrecantage, toothTrackingStatus, toothData])

  const classes = useStyles({
    borderColor: currentToothTrackingStatus?.borderColor,
    backgroundColor: currentToothTrackingStatus?.backgroundColor,
    progressPrecantage: toothMovementPrecentage
  })

  return (
    <GrinFloatingPopup
      open={showPopup}
      arrow
      withCloseIcon={false}
      containerClassName={classes.toothPopupContainer}
      triggerComponent={
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <DazzedParagraph11 thickness="bold" className={classes.toothLabel}>
              {toothTitle}
            </DazzedParagraph11>
          </Grid>
          <Grid item>
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              className={classes.status}
              onClick={handleClick}
            >
              {shouldShowToothIcon && <Grid item>{currentToothTrackingStatus?.icon}</Grid>}
              {shouldApplyAsterisk && <div className={classes.asterisk}>*</div>}
            </Grid>
          </Grid>
          {isTxGoalsEnabled && toothMovementPrecentage != null && (
            <div className={classes.progressContainer}>
              <div className={classes.progress} />
            </div>
          )}
        </Grid>
      }
    >
      <ToothPopup
        currentScan={currentScan}
        toothData={toothData}
        toothName={toothTitle}
        toothNumber={toothNumber}
        isTxGoalsEnabled={isTxGoalsEnabled}
        treatmentType={treatmentType}
        onClickAway={() => setShowPopup(false)}
      />
    </GrinFloatingPopup>
  )
}

export default ToothGridItem
