import { GrinNotificationTypes } from '@grin-rnd/grin-api-sdk/dist/Enums/Notifications'
import BaseGrinNotification from './BaseGrinNotification/BaseGrinNotification'
import { logError } from 'utils/logUtils'
import NewMessageNotification from './NewMessageNotification'
import NewScanNotification from './NewScanNotification'
import PatientAssignedNotification from './PatientAssignedNotification'
import NeedsAttentionNotification from './NeedsAttentionNotification'

const notificationComponentByType = {
  [GrinNotificationTypes.NewMessage]: NewMessageNotification,
  [GrinNotificationTypes.NewScan]: NewScanNotification,
  [GrinNotificationTypes.PatientAssigned]: PatientAssignedNotification,
  [GrinNotificationTypes.NeedsAttention]: NeedsAttentionNotification
}

const factory = notificationType => {
  const notificationComponent = notificationComponentByType[notificationType]
  if (!notificationComponent) {
    logError(`Notification center - missing notification component for type: ${notificationType}`)
    return BaseGrinNotification
  }

  return notificationComponent
}

export default factory
