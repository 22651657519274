import Actions from '../actions'
import { upsertGrinScanReviewsList } from '../utils/milestoneUtils'
import { replaceItem, updateWhere } from '../utils/arrayUtils'
import { mapToGrinScansDto } from 'utils/mappers/treatmentMapper'
import { chain, groupBy } from 'lodash'
import { StatusTypesOrder } from 'utils/statusUtils'
import { AsyncStatus } from 'consts'
import { updatePairedScansInList } from 'utils/scanUtils'

const initialState = {
  treatment: {},
  grinScans: null,
  isFetching: false,
  isLoading: false,
  isSavingPreventiveFeedback: false,
  viewScanDialog: {
    open: false,
    type: null,
    s3Object: null
  },
  scanReviewEditorDialog: {
    open: false,
    scan: null,
    uploads: {}
  },
  scannerTypes: {
    isLoading: false,
    list: []
  },
  uploadStl: {
    isSaving: false
  },
  preliminaryPlanProducts: {
    data: [],
    isLoading: false
  },
  preliminaryPlanDialog: {
    open: false
  },
  statuses: {
    isLoading: false,
    loadingFailed: false,
    data: {},
    types: [],
    isSaving: false,
    didSaveFailed: false
  },
  medicalRecordsFiles: {},
  isSubmittingScanFeedback: false,
  annotations: {
    isLoading: false,
    base64Image: null
  },
  beforeAfter: {
    isOpen: false,
    assetStatus: null,
    asset: null,
    params: {},
    morph: {
      status: null,
      asset: {}
    },
    error: null,
    patientId: null,
    lastScanId: null,
    firstScanId: null
  },
  compareScans: {
    isOpen: false,
    linkEnabled: true,
    left: {
      scanIndex: 0,
      selectedTile: 0,
      withAligners: false
    },
    right: {
      scanIndex: 0,
      selectedTile: 0,
      withAligners: false
    }
  },
  replaceScanSummaryImageModal: {
    isOpen: false,
    isSaving: false
  },
  shareNewScanModal: {
    isOpen: false,
    isLoading: false,
    grinScanId: null
  },
  viewSharedScanModal: {
    isOpen: false,
    referralId: null,
    grinScanId: null
  },
  regenerateScanModal: {
    isOpen: false,
    grinScanId: null,
    isLoading: false
  },
  shareScanAttachFilesModal: {
    isOpen: false,
    isUploading: false,
    uploadFiles: []
  },
  scanSummaryModal: {
    isOpen: false,
    scanId: null
  },
  treatmentTrackerModal: {
    isOpen: false
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.FETCH_REJECTED:
      return {
        ...state,
        isLoading: false
      }
    case Actions.PATIENT_REQUESTED:
      return {
        ...state,
        grinScans: null,
        medicalRecordsFiles: initialState.medicalRecordsFiles
      }
    case Actions.PATIENT_RECEIVED:
      return {
        ...state,
        treatment: action.payload.patient?.treatments?.items[0],
        grinScans: action.payload.patient?.grinScans?.items
      }
    case Actions.GRIN_SCANS_RECEIVED:
      return {
        ...state,
        grinScans: action.payload
      }
    case Actions.UPDATE_TREATMENT:
      return {
        ...state,
        isLoading: true
      }
    case Actions.UPDATE_TREATMENT_FAILED:
      return {
        ...state,
        isLoading: false
      }
    case Actions.UPDATE_TREATMENT_RECEIVED:
      return {
        ...state,
        isLoading: false,
        treatment: action.payload.treatment
      }
    case Actions.REQUEST_UPDATE_STAGE:
      return {
        ...state,
        isLoading: true
      }

    case Actions.REQUEST_PATIENT_TREATMENT:
      return {
        ...state,
        isFetching: true
      }
    case Actions.PATIENT_TREATMENT_RECEIVED:
      return {
        ...state,
        treatment: action.payload.treatment,
        isFetching: false
      }
    case Actions.PATIENT_TREATMENT_FETCH_FAILED:
      return {
        ...state,
        isFetching: false
      }
    case Actions.OPEN_SCAN_VIEW_DIALOG:
      return {
        ...state,
        viewScanDialog: {
          open: true,
          s3Object: action.payload.s3Object,
          type: action.payload.type,
          isLocal: action.payload.isLocal,
          cache: action.payload.cache
        }
      }
    case Actions.CLOSE_SCAN_VIEW_DIALOG:
      return {
        ...state,
        viewScanDialog: {
          open: false,
          s3Object: null,
          type: null
        }
      }
    case Actions.OPEN_PRELIMINARY_PLAN_DIALOG:
      return {
        ...state,
        preliminaryPlanDialog: {
          open: true
        }
      }
    case Actions.CLOSE_PRELIMINARY_PLAN_DIALOG:
      return {
        ...state,
        preliminaryPlanDialog: {
          open: false
        }
      }

    case Actions.FETCH_SCAN_FOR_REVIEW:
      return {
        ...state,
        scanReviewEditorDialog: {
          ...state.scanReviewEditorDialog,
          isLoading: true,
          open: true
        }
      }
    case Actions.FETCH_SCAN_FOR_REVIEW_FAILED:
      return {
        ...state,
        scanReviewEditorDialog: {
          ...state.scanReviewEditorDialog,
          isLoading: false,
          open: false
        }
      }
    case Actions.FETCH_SCAN_FOR_REVIEW_RECEIVED:
      return {
        ...state,
        scanReviewEditorDialog: {
          ...state.scanReviewEditorDialog,
          isLoading: false,
          scan: action.payload
        }
      }
    case Actions.OPEN_SCAN_REVIEW_EDITOR:
      return {
        ...state,
        scanReviewEditorDialog: {
          ...state.scanReviewEditorDialog,
          open: true,
          scan: action.payload
        }
      }
    case Actions.CLOSE_SCAN_REVIEW_EDITOR:
      return {
        ...state,
        scanReviewEditorDialog: {
          ...state.scanReviewEditorDialog,
          open: false,
          scan: null
        }
      }
    case Actions.REQUEST_SEND_SCAN_REVIEW:
      return {
        ...state,
        grinScans: upsertGrinScanReviewsList(state.grinScans, action.payload),
        scanReviewEditorDialog: {
          ...state.scanReviewEditorDialog,
          isSending: true,
          uploads: {
            ...state.scanReviewEditorDialog.uploads,
            [action.payload.s3UniqueId]: {
              originalPayload: action.payload,
              status: AsyncStatus.Loading
            }
          }
        }
      }
    case Actions.SEND_SCAN_REVIEW_FAILED:
      return {
        ...state,
        isLoading: false,
        scanReviewEditorDialog: {
          ...state.scanReviewEditorDialog,
          isSending: false,
          uploads: {
            ...state.scanReviewEditorDialog.uploads,
            [action.payload.s3UniqueId]: {
              ...(state.scanReviewEditorDialog.uploads[action.payload.s3UniqueId] || {}),
              status: AsyncStatus.Failed
            }
          }
        }
      }
    case Actions.SEND_SCAN_REVIEW_RECEIVED:
      return {
        ...state,
        isLoading: false,
        scanReviewEditorDialog: {
          ...state.scanReviewEditorDialog,
          isSending: false,
          uploads: {
            ...state.scanReviewEditorDialog.uploads,
            [action.payload.s3UniqueId]: {
              ...(state.scanReviewEditorDialog.uploads[action.payload.s3UniqueId] || {}),
              status: AsyncStatus.Completed
            }
          }
        }
      }
    case Actions.FETCH_SCANNER_TYPES_LIST:
      return {
        ...state,
        scannerTypes: {
          ...state.scannerTypes,
          isLoading: true
        }
      }
    case Actions.FETCH_SCANNER_TYPES_LIST_RECEIVED:
      return {
        ...state,
        scannerTypes: {
          ...state.scannerTypes,
          isLoading: false,
          list: action.payload
        }
      }
    case Actions.FETCH_SCANNER_TYPES_LIST_FAILED:
      return {
        ...state,
        scannerTypes: {
          ...state.scannerTypes,
          isLoading: false
        }
      }
    case Actions.UPLOAD_INITIAL_STL_FILES:
    case Actions.UPLOAD_STL_FILES:
      return {
        ...state,
        uploadStl: {
          ...state.uploadStl,
          isSaving: true
        }
      }
    case Actions.UPLOAD_STL_FILES_FAILED:
    case Actions.UPLOAD_STL_FILES_RECEIVED:
      return {
        ...state,
        uploadStl: {
          ...state.uploadStl,
          isSaving: false
        }
      }
    case Actions.FETCH_PRELIMINARY_PLAN_PRODUCTS:
      return {
        ...state,
        preliminaryPlanProducts: {
          ...state.preliminaryPlanProducts,
          isLoading: true
        }
      }
    case Actions.FETCH_PRELIMINARY_PLAN_PRODUCTS_RECEIVED:
      return {
        ...state,
        preliminaryPlanProducts: {
          ...state.preliminaryPlanProducts,
          isLoading: false,
          data: action.payload
        }
      }
    case Actions.FETCH_PRELIMINARY_PLAN_PRODUCTS_FAILED:
      return {
        ...state,
        preliminaryPlanProducts: {
          ...state.preliminaryPlanProducts,
          isLoading: false
        }
      }
    case Actions.SCAN_SUMMARY_REVIEWED:
      return {
        ...state,
        isLoading: true
      }
    case Actions.SCAN_SUMMARY_REVIEWED_RECEIVED:
      return {
        ...state,
        grinScans: updateWhere(
          state.grinScans,
          scan => scan.id === action.payload.scanId,
          scan => (scan.scanSummaryData = action.payload.scanSummaryData)
        ),
        isLoading: false,
        isSavingPreventiveFeedback: false
      }
    case Actions.SCAN_SUMMARY_REVIEWED_FAILED:
      return {
        ...state,
        isLoading: false,
        isSavingPreventiveFeedback: false
      }
    case Actions.UPDATE_GRIN_SCAN_APPLIANCE:
      if (!action.payload.totalApplianceNumber) {
        return state
      }

      return {
        ...state,
        treatment: {
          ...state.treatment,
          totalApplianceNumber: action.payload.totalApplianceNumber
        }
      }
    case Actions.UPDATE_GRIN_SCAN_APPLIANCE_RECEIVED:
      const { scan } = action.payload
      return {
        ...state,
        grinScans: scan ? updatePairedScansInList({ scans: state.grinScans, updatedScan: scan }) : state.grinScans
      }
    case Actions.FETCH_STATUSES:
    case Actions.FETCH_STATIC_DATA:
      return {
        ...state,
        statuses: {
          ...state.statuses,
          isLoading: true,
          loadingFailed: false
        }
      }
    case Actions.FETCH_STATIC_DATA_FAILED:
      return {
        ...state,
        statuses: {
          ...state.statuses,
          isLoading: false,
          loadingFailed: true
        }
      }
    case Actions.FETCH_STATUSES_FAILED:
      return {
        ...state,
        statuses: {
          ...state.statuses,
          isLoading: false,
          loadingFailed: true
        }
      }
    case Actions.FETCH_STATIC_DATA_RECEIVED:
      const { statuses } = action.payload
      const grinStatuses = statuses.filter(status => !status.program.includes('whitening'))

      const { data, types } = {
        data: chain(grinStatuses)
          .sortBy(status => StatusTypesOrder[status.type])
          .groupBy('program')
          .value(),
        types: Object.keys(groupBy(grinStatuses, 'type'))
      }

      return {
        ...state,
        statuses: {
          ...state.statuses,
          data,
          types,
          isLoading: false,
          loadingFailed: false
        }
      }
    case Actions.UPDATE_TREATMENT_STATUS:
      return {
        ...state,
        statuses: {
          ...state.statuses,
          isSaving: true,
          didSaveFailed: false
        }
      }
    case Actions.UPDATE_TREATMENT_STATUS_FAILED:
      return {
        ...state,
        statuses: {
          ...state.statuses,
          isSaving: false,
          didSaveFailed: true
        }
      }
    case Actions.UPDATE_TREATMENT_STATUS_RECEIVED:
      return {
        ...state,
        statuses: {
          ...state.statuses,
          isSaving: false,
          didSaveFailed: false
        }
      }
    case Actions.UPDATE_SCAN_FEEDBACK: {
      return {
        ...state,
        isSubmittingScanFeedback: true
      }
    }
    case Actions.UPDATE_SCAN_FEEDBACK_FAILED: {
      return {
        ...state,
        isSubmittingScanFeedback: false
      }
    }
    case Actions.UPDATE_SCAN_FEEDBACK_RECEIVED: {
      return {
        ...state,
        isSubmittingScanFeedback: false,
        grinScans: state.grinScans
          ? updateWhere(
              state.grinScans,
              scan => scan.id === action.payload.id,
              scan => {
                scan.metadata = action.payload.metadata
                scan._version = action.payload._version
              }
            )
          : state.grinScans
      }
    }
    case Actions.FETCH_MEDICAL_RECORDS:
    case Actions.DELETE_MEDICAL_RECORD:
    case Actions.UPLOAD_MEDICAL_RECORD: {
      return {
        ...state,
        isLoading: true
      }
    }
    case Actions.DELETE_MEDICAL_RECORD_RECEIVED: {
      return {
        ...state,
        treatment: {
          ...state.treatment,
          _version: action.payload._version,
          medicalRecordsData: JSON.stringify(action.payload.updatedMedicalRecords)
        },
        isLoading: false
      }
    }
    case Actions.UPLOAD_MEDICAL_RECORD_RECEIVED: {
      const medicalRecordFile = action.payload.medicalRecordObject
      return {
        ...state,
        treatment: {
          ...state.treatment,
          ...action.payload.treatment
        },
        medicalRecordsFiles: {
          ...state.medicalRecordsFiles,
          [medicalRecordFile.id]: medicalRecordFile.file
        },
        isLoading: false
      }
    }
    case Actions.UPLOAD_MEDICAL_RECORD_FAILED:
    case Actions.FETCH_MEDICAL_RECORDS_FAILED: {
      return {
        ...state,
        isLoading: false
      }
    }
    case Actions.FETCH_MEDICAL_RECORDS_RECEIVED: {
      return {
        ...state,
        isLoading: false,
        medicalRecordsFiles: action.payload
      }
    }
    case Actions.FETCH_BASE_64_IMAGE:
      return {
        ...state,
        annotations: {
          ...state.annotations,
          isLoading: true
        }
      }
    case Actions.FETCH_BASE_64_IMAGE_RECEIVED:
      return {
        ...state,
        annotations: {
          ...state.annotations,
          base64Image: action.payload,
          isLoading: false
        }
      }
    case Actions.FETCH_BASE_64_IMAGE_FAILED:
      return {
        ...state,
        annotations: {
          ...state.annotations,
          isLoading: false
        }
      }
    case Actions.CLEAR_BASE_64_IMAGE:
      return {
        ...state,
        annotations: {
          ...state.annotations,
          base64Image: null
        }
      }
    case Actions.SUBMIT_PREVENTIVE_FEEDBACK:
      return {
        ...state,
        isSavingPreventiveFeedback: true
      }
    case Actions.FETCH_LEAD_SCANS_RECEIVED: {
      return {
        ...state,
        grinScans: mapToGrinScansDto(action.payload)
      }
    }
    case Actions.FETCH_LEAD_SCANS_FAILED: {
      return {
        ...state,
        grinScans: []
      }
    }
    case Actions.TOGGLE_BEFORE_AFTER_DIALOG: {
      return {
        ...state,
        beforeAfter: {
          ...state.beforeAfter,
          isOpen: action.payload.open,
          patientId: action.payload.patientId,
          lastScanId: action.payload.scanId,
          firstScanId: action.payload.firstScanId,
          morph: initialState.beforeAfter.morph
        }
      }
    }
    case Actions.FETCH_BEFORE_AFTER_ASSET: {
      return {
        ...state,
        beforeAfter: {
          ...state.beforeAfter,
          assetStatus: AsyncStatus.Loading,
          asset: null,
          error: initialState.beforeAfter.error,
          morph: {
            ...state.beforeAfter.morph,
            status: initialState.beforeAfter.morph.status
          }
        }
      }
    }
    case Actions.FETCH_BEFORE_AFTER_ASSET_FAILED: {
      return {
        ...state,
        beforeAfter: {
          ...state.beforeAfter,
          assetStatus: AsyncStatus.Failed,
          error:
            action.payload?.code === 'invalidValue' || action.payload?.code === 'unprocessableEntity'
              ? 'invalidValue'
              : 'unexpectedError'
        }
      }
    }
    case Actions.FETCH_BEFORE_AFTER_ASSET_RECEIVED: {
      return {
        ...state,
        beforeAfter: {
          ...state.beforeAfter,
          asset: action.payload.assetS3Object,
          params: action.payload.params,
          assetStatus: AsyncStatus.Completed
        }
      }
    }
    case Actions.FETCH_BEFORE_AFTER_MORPH:
      return {
        ...state,
        beforeAfter: {
          ...state.beforeAfter,
          morph: {
            ...state.beforeAfter.morph,
            status: AsyncStatus.Loading
          }
        }
      }
    case Actions.FETCH_BEFORE_AFTER_MORPH_FAILED:
      return {
        ...state,
        beforeAfter: {
          ...state.beforeAfter,
          morph: {
            ...state.beforeAfter.morph,
            status: AsyncStatus.Failed,
            error: 'morphGenerationFailed'
          }
        }
      }
    case Actions.FETCH_BEFORE_AFTER_MORPH_RECEIVED:
      const morphStatus =
        action.payload.status === 'completed' // This is the async job status
          ? AsyncStatus.Completed
          : action.payload.status === 'pending'
          ? AsyncStatus.Loading
          : AsyncStatus.Failed

      return {
        ...state,
        beforeAfter: {
          ...state.beforeAfter,
          morph: {
            ...state.beforeAfter.morph,
            status: morphStatus,
            error: morphStatus === AsyncStatus.Failed ? 'morphGenerationFailed' : null,
            asset: action.payload
          }
        }
      }
    case Actions.SUBMIT_SCAN_TRACKING_DATA_RECEIVED:
      return {
        ...state,
        grinScans: updateWhere(
          state.grinScans,
          scan => scan.id === action.payload.grinScan?.id,
          scan => {
            scan.trackingStatus = action.payload.grinScan.trackingStatus
            scan.trackingDetails = action.payload.grinScan.trackingDetails
          }
        )
      }
    case Actions.SCAN_SUMMARY_LIVE_UPDATE_RECEIVED:
      const grinScan = action.payload
      return {
        ...state,
        grinScans: grinScan ? replaceItem(state.grinScans, grinScan) : state.grinScans
      }
    case Actions.SET_COMPARE_SCANS_DIALOG_OPEN:
      return {
        ...state,
        compareScans: {
          ...initialState.compareScans,
          left: {
            ...initialState.compareScans.left,
            ...(action.payload.left || {})
          },
          right: {
            ...initialState.compareScans.right,
            ...(action.payload.right || {})
          },
          isOpen: action.payload.isOpen
        }
      }
    case Actions.COMPARE_SCANS_SET_LINK_SCANS_ENABLED:
      return {
        ...state,
        compareScans: {
          ...state.compareScans,
          linkEnabled: action.payload,
          ...(action.payload
            ? {
                left: {
                  ...initialState.compareScans.left,
                  scanIndex: state.compareScans.left.scanIndex
                },
                right: {
                  ...initialState.compareScans.right,
                  scanIndex: state.compareScans.right.scanIndex
                }
              }
            : {})
        }
      }
    case Actions.COMPARE_SCANS_SET_SCAN_SELECTION: {
      const newState = {
        ...state,
        compareScans: {
          ...state.compareScans
        }
      }

      newState.compareScans[action.payload.side].scanIndex = action.payload.scanIndex
      if (state.compareScans.linkEnabled) {
        newState.compareScans.right.selectedTile = action.payload.selectedTile
        newState.compareScans.right.withAligners = action.payload.withAligners
        newState.compareScans.left.selectedTile = action.payload.selectedTile
        newState.compareScans.left.withAligners = action.payload.withAligners
      } else {
        newState.compareScans[action.payload.side].selectedTile = action.payload.selectedTile
        newState.compareScans[action.payload.side].withAligners = action.payload.withAligners
      }
      return newState
    }
    case Actions.TOGGLE_REPLACE_SCAN_SUMMARY_MODAL:
      return {
        ...state,
        replaceScanSummaryImageModal: {
          isOpen: action.payload
        }
      }

    case Actions.REPLACE_ORAL_IMAGE:
      return {
        ...state,
        replaceScanSummaryImageModal: {
          ...state.replaceScanSummaryImageModal,
          isSaving: true
        }
      }
    case Actions.REPLACE_ORAL_IMAGE_RECEIVED:
      return {
        ...state,
        replaceScanSummaryImageModal: {
          ...state.replaceScanSummaryImageModal,
          isSaving: false,
          isOpen: false
        }
      }
    case Actions.REPLACE_ORAL_IMAGE_FAILED:
      return {
        ...state,
        replaceScanSummaryImageModal: {
          ...state.replaceScanSummaryImageModal,
          isSaving: false
        }
      }
    case Actions.TOGGLE_SHARE_NEW_SCAN_MODAL:
      return {
        ...state,
        shareNewScanModal: {
          ...state.shareNewScanModal,
          isOpen: action.payload.isOpen,
          grinScanId: action.payload?.grinScanId
        }
      }
    case Actions.TOGGLE_VIEW_SAHRED_SCAN_MODAL:
      return {
        ...state,
        viewSharedScanModal: {
          ...state.viewSharedScanModal,
          isOpen: action.payload.isOpen,
          grinScanId: action.payload?.grinScanId,
          referralId: action.payload?.referralId
        }
      }
    case Actions.SHARE_SCAN_TO_REFERRAL:
      return {
        ...state,
        shareNewScanModal: {
          ...state.shareNewScanModal,
          isLoading: true
        }
      }
    case Actions.SHARE_SCAN_TO_REFERRAL_FAILED:
      return {
        ...state,
        shareNewScanModal: {
          ...state.shareNewScanModal,
          isLoading: false
        }
      }
    case Actions.SHARE_SCAN_TO_REFERRAL_RECEIVED:
      return {
        ...state,
        shareNewScanModal: {
          ...state.shareNewScanModal,
          isLoading: false,
          isOpen: false
        }
      }
    case Actions.TOGGLE_REGENERATE_SCAN_MODAL:
      return {
        ...state,
        regenerateScanModal: {
          ...state.regenerateScanModal,
          isOpen: action.payload.isOpen,
          grinScanId: action.payload?.grinScanId
        }
      }
    case Actions.REGENERATE_SCAN:
      return {
        ...state,
        regenerateScanModal: {
          ...state.regenerateScanModal,
          isLoading: true
        }
      }
    case Actions.REGENERATE_SCAN_RECEIVED:
      return {
        ...state,
        regenerateScanModal: initialState.regenerateScanModal
      }
    case Actions.REGENERATE_SCAN_FAILED:
      return {
        ...state,
        regenerateScanModal: {
          ...state.regenerateScanModal,
          isLoading: false
        }
      }
    case Actions.TOGGLE_SHARE_SCAN_ATTACH_FILES_MODAL:
      const { isOpen } = action.payload

      return {
        ...state,
        shareScanAttachFilesModal: {
          isOpen,
          uploadedFiles: [],
          isUploading: false
        }
      }
    case Actions.UPLOAD_SHARE_SCAN_ATTACHMENTS:
      return {
        ...state,
        shareScanAttachFilesModal: {
          ...state.shareScanAttachFilesModal,
          isUploading: true
        }
      }
    case Actions.UPLOAD_SHARE_SCAN_ATTACHMENTS_RECEIVED:
      return {
        ...state,
        shareScanAttachFilesModal: {
          ...state.shareScanAttachFilesModal,
          uploadedFiles: action.payload
        }
      }
    case Actions.UPLOAD_SHARE_SCAN_ATTACHMENTS_FAILED:
      return {
        ...state,
        shareScanAttachFilesModal: {
          ...state.shareScanAttachFilesModal,
          isUploading: false
        }
      }
    case Actions.SET_SCAN_SUMMARY_MODAL_OPEN:
      return {
        ...state,
        scanSummaryModal: {
          isOpen: action.payload.isOpen,
          scanId: action?.payload?.scanId,
          activeImage: action?.payload?.activeImage
        }
      }
    case Actions.SET_TREATMENT_TRACKER_MODAL_OPEN:
      return {
        ...state,
        treatmentTrackerModal: {
          isOpen: action.payload.isOpen,
          grinScanId: action.payload.grinScanId
        }
      }
    default:
      return state
  }
}
