import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import BaseGrinNotification from './BaseGrinNotification/BaseGrinNotification'
import useSelectPatient from 'hooks/useSelectPatient'
import GenericNotificationBody from './BaseGrinNotification/GenericNotificationBody'
import IconAndText from 'components/common/IconAndText'
import ImageMessage from '../Icons/ImageMessage'

/**
 * @param {NotificationComponentProps} props
 */
const NewMessageNotification = ({ grinNotification, options }) => {
  const { t } = useTranslation()

  const selectPatient = useSelectPatient({ patientId: grinNotification?.relatedPatientId })

  const handleClick = useCallback(() => {
    selectPatient()
  }, [selectPatient])

  return (
    <BaseGrinNotification
      grinNotification={grinNotification}
      isLastItem={options.isLastItem}
      showPracticeName={options.showPracticeName}
      profilePicture={grinNotification.relatedPatientSM.profilePicture}
      profilePictureUserName={grinNotification.relatedPatientSM.name}
      onClick={handleClick}
    >
      <GenericNotificationBody
        title={grinNotification.relatedPatientSM.name}
        text={
          grinNotification.bundleCount > 1 ? (
            t('dialogs.notificationCenterDialog.feed.notifications.newMessage.bundled', {
              count: grinNotification.bundleCount
            })
          ) : grinNotification.payload.messageType === 'image' ? (
            <IconAndText
              icon={<ImageMessage />}
              text={t('dialogs.notificationCenterDialog.feed.notifications.newMessage.image')}
            />
          ) : (
            `"${grinNotification.payload.messagePreview}"`
          )
        }
        withEllipsis
      />
    </BaseGrinNotification>
  )
}

export default NewMessageNotification
