import React, { useMemo } from 'react'
import moment from 'moment'
import TrackingGuidelines from '../TrackingGuidelines'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { useTranslation } from 'react-i18next'
import usePatientBriefViewer from './usePatientBriefViewer'
import { Grid } from '@material-ui/core'

const PatientBrief = props => {
  const { t } = useTranslation()

  const { categories, latestPatientBrief, hasPatientBriefLoaded, lastUpdatedBy } = usePatientBriefViewer()

  const lastUpdated = useMemo(() => moment(latestPatientBrief?.createdAt).format('MMM D, YYYY'), [latestPatientBrief])

  return hasPatientBriefLoaded ? (
    <TrackingGuidelines
      lastUpdated={lastUpdated}
      lastUpdatedBy={lastUpdatedBy}
      categories={categories}
      categoriesData={latestPatientBrief.categories}
      generalNote={latestPatientBrief.practiceNotes}
    />
  ) : (
    <Grid container justifyContent="center" alignItems="center" style={{ height: '100%' }}>
      <Grid item>
        <DazzedParagraph14 textAlign="center">
          {t('dialogs.hiPatientGuidelinesViewer.tabs.patientBrief.emptyState')}
        </DazzedParagraph14>
      </Grid>
    </Grid>
  )
}

export default PatientBrief
