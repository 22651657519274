import React, { useCallback } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import MembersTable from '../PracticeMembers/Table/MembersTable'
import { useTranslation } from 'react-i18next'
import DSOAddMemberButton from './DSOAddMemberButton'
import { trackEvent } from 'utils/analyticsUtils'
import { useHistory } from 'react-router'
import { AsyncStatus, ROUTES } from 'consts'
import { useSelector } from 'react-redux'

const useStyles = makeStyles(theme => ({
  dsoAdminTitle: {
    marginTop: 20,
    marginBottom: 10
  }
}))

const DSOAdmins = ({ dsoUsers }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()

  const { status } = useSelector(state => state.multiPracticeReducer.dsoPractices)

  const handleAddDSO = useCallback(() => {
    trackEvent('DSO Members - Add DSO clicked')
    history.push(ROUTES.DSO_MEMBERS_ADD_USER, { isDSO: true })
  }, [history])

  return (
    <Grid container direction="column">
      <Grid item>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item className={classes.dsoAdminTitle}>
            <DazzedParagraph16 bold>{t('pages.accountSettings.dsoMembers.title')}</DazzedParagraph16>
          </Grid>
          <Grid item>
            <DSOAddMemberButton isDSO onClick={handleAddDSO} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <MembersTable
          practiceMembers={dsoUsers}
          isLoading={status === AsyncStatus.Loading}
          hiddenColumns={['accessType', 'status']}
        />
      </Grid>
    </Grid>
  )
}

export default DSOAdmins
