import * as React from 'react'

const Left = ({ isSelected }) => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="24" cy="24" r="24" fill={isSelected ? '#B9C7FF' : '#DEEBFA'} />
    <path
      d="M9 15.7782C9 14.2065 10.213 12.9013 11.7805 12.7863L13.068 12.6918C19.3007 12.2347 25.564 12.95 31.5331 14.8008L34.2769 15.6515C36.7882 16.4302 38.5 18.7531 38.5 21.3824V26.3832C38.5 29.0124 36.7882 31.3354 34.2769 32.114L31.5331 32.9647C25.564 34.8155 19.3007 35.5308 13.068 35.0737L11.7805 34.9792C10.213 34.8643 9 33.559 9 31.9873V15.7782Z"
      fill={isSelected ? '#4151E6' : '#8FB1DA'}
    />
    <path
      d="M31.5 22.0335C31.5 21.3725 31.7626 20.7387 32.2299 20.2713C32.9884 19.5128 34.1471 19.3248 35.1066 19.8045L35.871 20.1867C36.8694 20.6859 37.5 21.7063 37.5 22.8225C37.5 23.4735 36.9723 24.0012 36.3213 24.0012H32.9738C32.1598 24.0012 31.5 23.3414 31.5 22.5274V22.0335Z"
      fill="white"
      stroke={isSelected ? '#4151E6' : '#8FB1DA'}
    />
    <path
      d="M31.5 25.969C31.5 26.6299 31.7626 27.2638 32.2299 27.7311C32.9884 28.4896 34.1471 28.6777 35.1066 28.1979L35.871 27.8157C36.8694 27.3165 37.5 26.2962 37.5 25.18C37.5 24.529 36.9723 24.0012 36.3213 24.0012H32.9738C32.1598 24.0012 31.5 24.6611 31.5 25.475V25.969Z"
      fill="white"
      stroke={isSelected ? '#4151E6' : '#8FB1DA'}
    />
    <path
      d="M24.5 22.2535C24.5 20.9085 25.3607 19.7143 26.6367 19.289C27.2005 19.101 27.8062 19.0778 28.3828 19.2219L28.934 19.3597C30.4421 19.7367 31.5 21.0917 31.5 22.6462C31.5 23.3945 30.8933 24.0012 30.145 24.0012H25.9276C25.1392 24.0012 24.5 23.3621 24.5 22.5736V22.2535Z"
      fill="white"
      stroke={isSelected ? '#4151E6' : '#8FB1DA'}
    />
    <path
      d="M24.5 25.7489C24.5 27.094 25.3607 28.2881 26.6367 28.7135C27.2005 28.9014 27.8062 28.9247 28.3828 28.7805L28.934 28.6427C30.4421 28.2657 31.5 26.9107 31.5 25.3563C31.5 24.6079 30.8933 24.0012 30.145 24.0012H25.9276C25.1392 24.0012 24.5 24.6404 24.5 25.4288V25.7489Z"
      fill="white"
      stroke={isSelected ? '#4151E6' : '#8FB1DA'}
    />
    <path
      d="M20.5 20.3336C20.5 19.6311 20.8686 18.9801 21.471 18.6186C22.1044 18.2386 22.8956 18.2386 23.529 18.6186C24.1314 18.9801 24.5 19.6311 24.5 20.3336V22.7483C24.5 23.4403 23.939 24.0012 23.247 24.0012H21.753C21.061 24.0012 20.5 23.4403 20.5 22.7483V20.3336Z"
      fill="white"
      stroke={isSelected ? '#4151E6' : '#8FB1DA'}
    />
    <path
      d="M20.5 27.6688C20.5 28.3714 20.8686 29.0224 21.471 29.3838C22.1044 29.7638 22.8956 29.7638 23.529 29.3838C24.1314 29.0224 24.5 28.3714 24.5 27.6688V25.2542C24.5 24.5622 23.939 24.0012 23.247 24.0012H21.753C21.061 24.0012 20.5 24.5622 20.5 25.2542V27.6688Z"
      fill="white"
      stroke={isSelected ? '#4151E6' : '#8FB1DA'}
    />
    <path
      d="M16.5 19.9744C16.5 19.4769 16.6854 18.9972 17.0201 18.6291C17.8136 17.7562 19.1864 17.7562 19.9799 18.6291C20.3146 18.9972 20.5 19.4769 20.5 19.9744V23.0148C20.5 23.5596 20.0584 24.0012 19.5136 24.0012H17.4864C16.9416 24.0012 16.5 23.5596 16.5 23.0148V19.9744Z"
      fill="white"
      stroke={isSelected ? '#4151E6' : '#8FB1DA'}
    />
    <path
      d="M16.5 28.028C16.5 28.5255 16.6854 29.0052 17.0201 29.3734C17.8136 30.2462 19.1864 30.2462 19.9799 29.3734C20.3146 29.0052 20.5 28.5255 20.5 28.028V24.9876C20.5 24.4429 20.0584 24.0012 19.5136 24.0012H17.4864C16.9416 24.0012 16.5 24.4429 16.5 24.9876V28.028Z"
      fill="white"
      stroke={isSelected ? '#4151E6' : '#8FB1DA'}
    />
    <path
      d="M12.5 20.369C12.5 19.6058 12.7145 18.8581 13.1189 18.211C13.7568 17.1904 15.2432 17.1904 15.8811 18.211C16.2855 18.8581 16.5 19.6058 16.5 20.369V23.0012C16.5 23.5535 16.0523 24.0012 15.5 24.0012H13.5C12.9477 24.0012 12.5 23.5535 12.5 23.0012V20.369Z"
      fill="white"
      stroke={isSelected ? '#4151E6' : '#8FB1DA'}
    />
    <path
      d="M12.5 27.6335C12.5 28.3966 12.7145 29.1443 13.1189 29.7915C13.7568 30.8121 15.2432 30.8121 15.8811 29.7915C16.2855 29.1443 16.5 28.3966 16.5 27.6335V25.0012C16.5 24.4489 16.0523 24.0012 15.5 24.0012H13.5C12.9477 24.0012 12.5 24.4489 12.5 25.0012V27.6335Z"
      fill="white"
      stroke={isSelected ? '#4151E6' : '#8FB1DA'}
    />
    <path
      d="M9.5 19.6639C9.5 19.3625 9.59083 19.068 9.76066 18.8189C10.3561 17.9456 11.6439 17.9456 12.2393 18.8189C12.4092 19.068 12.5 19.3625 12.5 19.6639V23.0012C12.5 23.5535 12.0523 24.0012 11.5 24.0012H10.5C9.94772 24.0012 9.5 23.5535 9.5 23.0012V19.6639Z"
      fill="white"
      stroke={isSelected ? '#4151E6' : '#8FB1DA'}
      strokeWidth="0.5"
    />
    <path
      d="M9.5 28.3385C9.5 28.64 9.59083 28.9344 9.76066 29.1835C10.3561 30.0568 11.6439 30.0568 12.2393 29.1835C12.4092 28.9344 12.5 28.64 12.5 28.3385V25.0012C12.5 24.4489 12.0523 24.0012 11.5 24.0012H10.5C9.94772 24.0012 9.5 24.4489 9.5 25.0012V28.3385Z"
      fill="white"
      stroke={isSelected ? '#4151E6' : '#8FB1DA'}
      strokeWidth="0.5"
    />
  </svg>
)

export default Left
