import React, { forwardRef, useCallback, useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { CardMedia, Fade, Grid } from '@material-ui/core'
import { getCloudFrontUrl } from 'utils/storageUtils'
import { getObjectUrl } from 'utils/mediaUtils'
import ScanViewTiles from './ScanViewTiles'
import { isMobile } from 'utils/mobileUtils'
import PreventiveLayers from 'components/Patients/Timeline/ScanSummary/Preventive/PreventiveLayers'
import PreventivePanel from 'components/Patients/Timeline/ScanSummary/Preventive/PreventivePanel'
import { IMAGE_MODES, ScanSummaryStatus } from 'consts/scanSummaryConsts'
import useFeatureFlags from 'hooks/useFeatureFlags'
import ScanSummaryIncompleteState from './ScanSummaryIncompleteState'
import LinkButton from '../buttons/LinkButton'
import ScanSummaryRating from './ScanSummaryRating'
import ImagesViewer from '../ImagesViewer'
import ImageDrawingModal from '../modals/ImageDrawingModal'
import useScanViewDrawing from './useScanViewDrawing'
import ScanMarkupActions from './ScanMarkupActions'
import { useHotkeys } from 'react-hotkeys-hook'
import PoseNavigationButton from './PoseNavigationButton'
import { FullScreen } from '../icons'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    flexWrap: 'nowrap',
    userSelect: 'none'
  },
  selectedMediaContainer: {
    height: '80%',
    width: '100%',
    textAlign: 'center',
    position: 'relative',
    '&.clickable': {
      cursor: 'pointer'
    }
  },
  cardMediaContainer: {
    position: 'relative'
  },
  fullScreenIcon: {
    position: 'absolute',
    right: 25,
    bottom: 25,
    '&:hover': {
      boxShadow: '1px 3px 7px #9a9ea6'
    }
  },
  cardMedia: {
    borderRadius: 8,
    marginBottom: 10,
    padding: '0 4px 4px 4px',
    objectFit: 'cover',
    aspectRatio: 1.66,
    '&::-webkit-media-controls-panel': {
      backgroundImage: 'none !important' // Removes the weird gradient from the video element
    }
  },
  mediaOverlayActionsContainer: {
    position: 'absolute',
    left: 16,
    bottom: 16
  },
  preventiveCardMedia: {
    borderRadius: 8,
    marginBottom: 5,
    padding: '0 4px 4px 4px',
    width: '100%',
    aspectRatio: 1.66
  },
  scanSummaryIncompleteState: {
    padding: 24
  }
}))

/**
 * @prop grinScanVideo - The scan s3 object: ` { region, bucket, key } `
 * @prop scanSummaryStatus - (Optional) See the enum `ScanSummaryStatus` for possible values. Default value is `completed`.
 * @prop scanSummaryData - The **raw** scan summary data.
 * @prop selectedOptionIndex - The index of the selected media option.
 * @prop onOptionSelect - A callback that's fired when one of the media options is selected with the new selected index and the option data. Should set the value passed into `selectedOptionIndex` to the new index.
 * @prop mediaClassName - (Optional) A class name to assign to the primary media component.
 * @prop defaultImagesMode - (Optional) The default scan summary images mode to display. See `IMAGE_MODES` enum. Default value is `normal`. Usually it's either `normal` or `sharpened`.
 * @prop hidePreventive - (Optional) A boolean to completely toggle on/off the preventive view. Default value is `false`.
 * @prop onPlay - (Optional) A callback that's fired when the scan video start playing.
 * @prop scanSummaryImageActions - (Optional) A list of custom actions to render. Each action should have the following structure: `{ icon, label, onClick, isDisabled }`
 * @prop withPosesSelection - (Optional) A boolean to control whether scan summary images selection is enabled. Default value is `false`.
 * @prop selectedPoses - Required if `withPosesSelection` is `true`.
 * @prop setSelectedPoses - Required if `withPosesSelection` is `true`.
 * @prop withScanSummaryRating - (Optional) A boolean that controls whether the scan summary rating feature is visible.
 * @prop scanSummaryRating - Required if `withScanSummaryRating` is `true`. See `ScanSummaryRatingOptions` for possible values.
 * @prop setScanSummaryRating - Required if `withScanSummaryRating` is `true`.
 * @prop preventivePanelProps - props that will be spreaded into the PreventivePanel component. Should be used for styling purposes only!
 * @prop openImagesInViewer - (Optional) If `true`, when a scan summary image is selected, clicking on it will open the image in ImageViewer.
 * @prop withImageDrawing - Whether to show the Mark Image button. **Note:** image drawing should be implemented within ScanView in the future.
 * @prop onMarkImageClicked - (Optional) A callback that's fired when the Mark Image button is clicked. Can be used for analytics.
 * @prop sketchedPoses - (Optional) A dictionary of poses to their sketched images. Required if `withImageDrawing` is enabled.
 * @prop setSketchedPoses - (Optional) a callback to set the `sketchesPoses` value. Required if `withImageDrawing` is enabled.
 * @prop withKeyboardShortcuts - (Optional) Controls whether pose navigation keyboard shortcuts are enabled. Default is `false`.
 * @prop imageDrawingModalProps - Custom props that will be passed to the `ImageDrawingModal`. Note that the modal is managed by this component, so use carefully (pass styling props, custom actions, etc). @see ImageDrawingModal
 *
 * @prop `preventiveLayers, displayedPreventiveLayers, isAnyPreventiveLayerVisible, onTogglePreventiveLayer, onToggleAllPreventiveLayers, onClearActiveLayers`: use the `usePreventive` hook.
 */
const ScanView = forwardRef(
  (
    {
      grinScanVideo,
      scanSummaryStatus = ScanSummaryStatus.Completed,
      scanSummaryData = '{}',
      selectedOptionIndex = 0,
      onOptionSelect = (index, optionData) => {},
      mediaClassName = '',
      defaultImagesMode = IMAGE_MODES.normal,
      hidePreventive = false,
      preventiveLayers,
      displayedPreventiveLayers,
      isAnyPreventiveLayerVisible,
      onTogglePreventiveLayer,
      onToggleAllPreventiveLayers,
      onClearActiveLayers,
      scanSummaryStateComponents = {},
      onPlay,
      rootClassName = '',
      actionsContainerClassName = '',
      optionMediaClassName = '',
      selectedOptionClassName = '',
      unselectedOptionClassName = '',
      scanSummaryImageActions = [],
      withPosesSelection = false,
      selectedPoses = [],
      setSelectedPoses,
      withScanSummaryRating = false,
      scanSummaryRating = '',
      setScanSummaryRating = () => {},
      preventivePanelProps = {},
      openImagesInViewer = false,
      onImageViewerOpened = ({ pose }) => {},
      onPoseChecked = ({ pose }) => {},
      onPoseUnchecked = ({ pose }) => {},
      withImageDrawing = false,
      onMarkImageClicked = ({ pose }) => {},
      sketchedPoses = {},
      setSketchedPoses = () => {},
      withKeyboardShortcuts = false,
      imageDrawingModalProps = {},
      analyticsPayload = {}
    },
    mediaRef
  ) => {
    const classes = useStyles()
    const featureFlags = useFeatureFlags()

    const [isImageViewerOpen, setIsImageViewerOpen] = useState()
    const [currentImageDimensions, setCurrentImageDimensions] = useState({ height: 0, width: 0 })
    const [isImageDrawingModalOpen, setIsImageDrawingModalOpen] = useState(false)

    const parsedScanSummaryData = useMemo(() => JSON.parse(scanSummaryData || '{}'), [scanSummaryData])
    const parsedPreventiveImages = useMemo(
      () => parsedScanSummaryData[IMAGE_MODES.preventiveLayers],
      [parsedScanSummaryData]
    )

    const options = useMemo(() => {
      const scanSummaryImages = parsedScanSummaryData?.[defaultImagesMode] || []
      return [
        {
          type: 'video',
          src: `${getCloudFrontUrl()}/${grinScanVideo.key}#t=2`
        },
        ...Object.keys(scanSummaryImages).map(pose => ({
          type: 'img',
          src: getObjectUrl(scanSummaryImages[pose]),
          pose
        }))
      ]
    }, [parsedScanSummaryData, grinScanVideo, defaultImagesMode])

    const selectedOption = useMemo(() => options[selectedOptionIndex] || options[0], [options, selectedOptionIndex])

    const isSelectedOptionClickable = useMemo(
      () => openImagesInViewer && selectedOption?.type === 'img',
      [openImagesInViewer, selectedOption]
    )

    const shouldDisplayPreventiveLayers = useMemo(
      () =>
        !hidePreventive &&
        featureFlags?.scanSummaryPreventive &&
        parsedPreventiveImages &&
        isAnyPreventiveLayerVisible &&
        selectedOption.type !== 'video',
      [
        featureFlags?.scanSummaryPreventive,
        isAnyPreventiveLayerVisible,
        parsedPreventiveImages,
        selectedOption?.type,
        hidePreventive
      ]
    )

    const shouldDisplayPreventiveLegend = useMemo(
      () =>
        !hidePreventive &&
        featureFlags?.scanSummaryPreventive &&
        Object.values(parsedPreventiveImages || {}).filter(image => !!image).length > 0,
      [featureFlags?.scanSummaryPreventive, hidePreventive, parsedPreventiveImages]
    )

    const imageUrls = useMemo(() => options.filter(opt => opt.type !== 'video').map(opt => opt.src), [options])

    const {
      setCurrentPoseSketch,
      displayedPoseImage,
      currentPoseSketch,
      isSketchVisible,
      setIsSketchVisible,
      isCurrentPoseSketched,
      clearCurrentPoseSketch
    } = useScanViewDrawing({
      currentPose: selectedOption.pose,
      setSketchedPoses,
      sketchedPoses,
      poseOriginalImageSrc: selectedOption.src
    })

    const handleSelectedMediaClicked = useCallback(() => {
      if (isSelectedOptionClickable) {
        setIsImageViewerOpen(true)
        onImageViewerOpened({ pose: selectedOption.pose })
      }
    }, [isSelectedOptionClickable, onImageViewerOpened, selectedOption])

    const handleOptionSelected = useCallback(
      (optionIndex, eventSource = '') => {
        const optionData = options[optionIndex]
        onOptionSelect(optionIndex, optionData, eventSource)
      },
      [onOptionSelect, options]
    )

    const handleMarkImageClicked = useCallback(
      e => {
        e.stopPropagation()
        setIsImageDrawingModalOpen(true)
        onMarkImageClicked({ pose: selectedOption.pose })
      },
      [selectedOption, onMarkImageClicked]
    )

    const handleMediaLoaded = useCallback(e => {
      setCurrentImageDimensions({
        height: e.target.naturalHeight,
        width: e.target.naturalWidth
      })
    }, [])

    const handleNextPose = useCallback(
      eventSource => {
        const index = (selectedOptionIndex + 1) % options.length
        handleOptionSelected(index, eventSource)
      },
      [handleOptionSelected, selectedOptionIndex, options.length]
    )

    const handlePreviousPose = useCallback(
      eventSource => {
        const index = (selectedOptionIndex - 1 + options.length) % options.length
        handleOptionSelected(index, eventSource)
      },
      [handleOptionSelected, selectedOptionIndex, options.length]
    )

    useEffect(() => {
      if (selectedOption.type === 'video' && isAnyPreventiveLayerVisible) {
        onClearActiveLayers()
      }
    }, [onClearActiveLayers, isAnyPreventiveLayerVisible, selectedOption.type])

    useHotkeys('ArrowRight', () => handleNextPose('keyboard-shortcut'), { enabled: withKeyboardShortcuts })
    useHotkeys('ArrowLeft', () => handlePreviousPose('keyboard-shortcut'), { enabled: withKeyboardShortcuts })

    return (
      <>
        <Grid
          container
          direction="column"
          className={[classes.root, rootClassName].join(' ')}
          justifyContent="space-between"
        >
          <Grid
            item
            className={[
              classes.selectedMediaContainer,
              isSelectedOptionClickable ? 'clickable' : '',
              'patient-scan'
            ].join(' ')}
            onClick={handleSelectedMediaClicked}
          >
            {selectedOption &&
              (shouldDisplayPreventiveLayers ? (
                <PreventiveLayers
                  backgroundImageUrl={selectedOption?.src}
                  preventiveImages={parsedPreventiveImages}
                  position={selectedOption?.pose}
                  displayedLayers={displayedPreventiveLayers}
                  className={[classes.preventiveCardMedia, mediaClassName].join(' ')}
                />
              ) : (
                <div className={classes.cardMediaContainer}>
                  <CardMedia
                    className={[classes.cardMedia, mediaClassName].join(' ')}
                    component={selectedOption.type}
                    src={selectedOption.type === 'video' ? selectedOption.src : displayedPoseImage}
                    controls={true}
                    autoPlay={isMobile()}
                    ref={mediaRef}
                    onPlay={onPlay}
                    onLoad={handleMediaLoaded}
                  />
                  {isSelectedOptionClickable && <FullScreen className={classes.fullScreenIcon} />}
                </div>
              ))}
            <PoseNavigationButton
              isNext={true}
              onClick={() => handleNextPose('navigation-arrows')}
              keyboardShortcut={withKeyboardShortcuts && '→'}
            />
            <PoseNavigationButton
              isNext={false}
              onClick={() => handlePreviousPose('navigation-arrows')}
              keyboardShortcut={withKeyboardShortcuts && '←'}
            />
            {withImageDrawing && (
              <Fade in={selectedOption?.type === 'img'} exit={false}>
                <div className={classes.mediaOverlayActionsContainer}>
                  <ScanMarkupActions
                    onMarkImageClicked={handleMarkImageClicked}
                    isSketchVisible={isSketchVisible}
                    setIsSketchVisible={setIsSketchVisible}
                    isSketched={isCurrentPoseSketched}
                    onClearAllClicked={clearCurrentPoseSketch}
                    analyticsPayload={analyticsPayload}
                  />
                </div>
              </Fade>
            )}
          </Grid>
          <Grid item>
            {scanSummaryStatus === ScanSummaryStatus.Completed ? (
              <div className={actionsContainerClassName}>
                <div>
                  <ScanViewTiles
                    selectedOptionIndex={selectedOptionIndex}
                    options={options}
                    onOptionSelect={opt => handleOptionSelected(opt, 'tiles')}
                    optionMediaClassName={optionMediaClassName}
                    selectedOptionClassName={selectedOptionClassName}
                    unselectedOptionClassName={unselectedOptionClassName}
                    withPosesSelection={withPosesSelection}
                    selectedPoses={selectedPoses}
                    setSelectedPoses={setSelectedPoses}
                    onPoseChecked={onPoseChecked}
                    onPoseUnchecked={onPoseUnchecked}
                  />
                </div>
                {(scanSummaryImageActions?.length || withScanSummaryRating) && (
                  <div style={{ padding: '14px 14px 0px 14px' }}>
                    <Grid container justifyContent="space-between">
                      {scanSummaryImageActions.length && (
                        <Grid item>
                          <Grid container spacing={2}>
                            {scanSummaryImageActions.map(action => (
                              <Grid item key={`scan-summary-action-${action.label}`}>
                                <LinkButton
                                  icon={action.icon}
                                  disabled={action.isDisabled}
                                  label={action.label}
                                  onClick={action.onClick}
                                  fontSize={14}
                                />
                              </Grid>
                            ))}
                          </Grid>
                        </Grid>
                      )}
                      {withScanSummaryRating && (
                        <Grid item>
                          <ScanSummaryRating rating={scanSummaryRating} setRating={setScanSummaryRating} />
                        </Grid>
                      )}
                    </Grid>
                  </div>
                )}
                {shouldDisplayPreventiveLegend && (
                  <div style={{ padding: '14px' }}>
                    <PreventivePanel
                      {...preventivePanelProps}
                      isAnyPreventiveLayerVisible={isAnyPreventiveLayerVisible}
                      toggleAllLayers={onToggleAllPreventiveLayers}
                      hasPreventiveLayering={!!parsedPreventiveImages}
                      preventiveLayers={preventiveLayers}
                      togglePreventiveLayer={onTogglePreventiveLayer}
                      imagesMode={IMAGE_MODES.preventive}
                      shouldDisplayFeedback={false}
                      disabled={selectedOption.type === 'video'}
                    />
                  </div>
                )}
              </div>
            ) : (
              <ScanSummaryIncompleteState scanSummaryStatus={scanSummaryStatus} />
            )}
          </Grid>
        </Grid>
        {openImagesInViewer && (
          <ImagesViewer
            activeIndex={selectedOptionIndex - 1}
            isVisible={isImageViewerOpen}
            onClose={() => setIsImageViewerOpen(false)}
            imageUrls={imageUrls}
          />
        )}
        {withImageDrawing && selectedOption?.type === 'img' && (
          <ImageDrawingModal
            isOpen={isImageDrawingModalOpen}
            onClose={() => setIsImageDrawingModalOpen(false)}
            imageSrc={currentPoseSketch}
            onDrawingComplete={setCurrentPoseSketch}
            canvasHeight={currentImageDimensions.height}
            canvasWidth={currentImageDimensions.width}
            analyticsPayload={analyticsPayload}
            {...imageDrawingModalProps}
          />
        )}
      </>
    )
  }
)

export default React.memo(ScanView)
