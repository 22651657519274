import { forgetUserProfile, getAuthProfiles, setCustomProfileDisplayName } from 'logic/loginProfilesLogic'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

export default ({ searchValue }) => {
  const [authProfiles, setAuthProfiles] = useState({})
  const doctorId = useSelector(state => state.profileReducer.doctor?.id)

  const profiles = useMemo(() => {
    return Object.values(authProfiles)
      .map(authProfile => ({
        username: authProfile.username,
        doctorId: authProfile.doctorId,
        displayName: authProfile.displayName,
        avatar: authProfile.avatar,
        email: authProfile.email,
        isCurrentlyLogged: authProfile.doctorId === doctorId,
        lastLoginDate: authProfile.lastLoginDate
      }))
      .sort((acct1, acct2) => {
        if (acct1.isCurrentlyLogged) {
          return -1
        }
        return new Date(acct2.lastLoginDate).getTime() - new Date(acct1.lastLoginDate).getTime()
      })
    // eslint-disable-next-line
  }, [doctorId, authProfiles])

  const userAccounts = useMemo(() => {
    if (!searchValue) {
      return profiles
    }

    const filterByProps = ['email', 'displayName']
    return profiles.filter(acct =>
      filterByProps.some(prop => acct[prop]?.toLowerCase().includes(searchValue.toLowerCase()))
    )
  }, [profiles, searchValue])

  const setProfileDisplayName = useCallback(({ username, displayName }) => {
    const updatedProfiles = setCustomProfileDisplayName({ username, displayName })
    setAuthProfiles(updatedProfiles)
  }, [])

  const forgetProfile = useCallback(({ username }) => {
    const updatedProfiles = forgetUserProfile({ username })
    setAuthProfiles(updatedProfiles)
  }, [])

  useEffect(() => {
    setAuthProfiles(getAuthProfiles())
  }, [])

  return {
    totalProfiles: profiles.length,
    userAccounts,
    setProfileDisplayName,
    forgetProfile
  }
}
