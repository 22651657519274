import React, { useMemo } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import QRCode from 'qrcode.react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import useMFA from 'components/Auth/useMFA'

const useStyles = makeStyles(theme => ({
  divider: {
    height: 160,
    width: 1,
    backgroundColor: 'var(--border-color-28)'
  },
  breakSpacesText: {
    whiteSpace: 'pre'
  }
}))

const InitialStep = () => {
  const classes = useStyles()
  const { getQrCodeLink } = useMFA()
  const { t } = useTranslation()

  const { username, email } = useSelector(state => state.profileReducer.doctor)
  const { totpCode } = useSelector(state => state.profileReducer.mfaSettings.setupModal)

  const qrCodeLink = useMemo(
    () => getQrCodeLink({ username, email, secret: totpCode }),
    [email, getQrCodeLink, totpCode, username]
  )

  const displayTotpCode = useMemo(
    () => totpCode.replace(/(.{4})(?=.)/g, '$1-').replace(/((.{4}-){3}.{4})(-)/g, '$1-\n'),
    [totpCode]
  )

  return (
    <Grid container direction="column">
      <Grid item style={{ marginBottom: 40 }}>
        <DazzedParagraph14 strong className={classes.breakSpacesText}>
          {t('pages.accountSettings.mfaSettings.setupModal.authApp.description')}
        </DazzedParagraph14>
      </Grid>
      <Grid item>
        <Grid container alignItems="center" justifyContent="space-evenly">
          <Grid item style={{ display: 'flex', justifyContent: 'center' }}>
            <QRCode value={qrCodeLink} size={160} id={`otp-qr-code`} />
          </Grid>
          <Grid item className={classes.divider}></Grid>
          <Grid item>
            <Grid container direction="column" alignItems="center" justifyContent="center" spacing={2}>
              <Grid item>
                <DazzedParagraph14
                  strong
                  textAlign="center"
                  color="var(--text-color-11)"
                  className={classes.breakSpacesText}
                >
                  {t('pages.accountSettings.mfaSettings.setupModal.authApp.alternativeText')}
                </DazzedParagraph14>
              </Grid>
              <Grid item>
                <DazzedParagraph14 bold textAlign="center" className={classes.breakSpacesText}>
                  {displayTotpCode}
                </DazzedParagraph14>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default InitialStep
