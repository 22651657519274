import React, { useCallback, useEffect, useState } from 'react'
import { Close as CloseIcon, MovementHint, ZoomHint } from 'components/common/icons'
import { Grid, IconButton, makeStyles } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { Trans, useTranslation } from 'react-i18next'
import { trackEvent } from 'utils/analyticsUtils'
import useAppSettings from 'hooks/useAppSettings'
import RotateHint from 'components/common/icons/RotateHint'

const useStyles = makeStyles(theme => ({
  hintsOverlayContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 3,
    width: '100%',
    height: '100%'
  },
  hintsOverlay: {
    borderRadius: 10,
    backgroundColor: 'white',
    opacity: 0.9,
    padding: 10,
    height: '50%',
    width: '70%',
    boxShadow: '1px 5px 10px -1px gray',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
    maxWidth: 750,
    maxHeight: 350
  },
  container: {
    height: '100%',
    width: '100%'
  },
  text: {
    whiteSpace: 'pre'
  }
}))

const HAS_SEEN_OVERLAY_HINTS_KEY = 'hasSeenOverlayHints'

const HintsOverlay = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const appSettings = useAppSettings()

  const [isOpen, setIsOpen] = useState(null)

  const handleClose = useCallback(() => {
    trackEvent('Treatment tracker - Hints modal - close clicked')
    setIsOpen(false)
    localStorage.setItem(HAS_SEEN_OVERLAY_HINTS_KEY, 'true')
  }, [])

  useEffect(() => {
    if (isOpen === null) {
      const hasSeenOverlayHints = localStorage.getItem(HAS_SEEN_OVERLAY_HINTS_KEY)

      if (hasSeenOverlayHints !== 'true') {
        setIsOpen(true)
      }
    }
  }, [appSettings, isOpen])

  return (
    isOpen && (
      <div className={classes.hintsOverlayContainer}>
        <div className={classes.hintsOverlay}>
          <IconButton onClick={handleClose}>
            <CloseIcon color="black" />
          </IconButton>
          <Grid container alignItems="center" justifyContent="space-evenly" wrap="nowrap" className={classes.container}>
            <Grid item xs={7}>
              <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{ borderRight: '1px solid var(--text-color-22)' }}
              >
                <Grid item style={{ marginBottom: 20 }}>
                  <Grid container alignItems="center">
                    <Grid item style={{ marginRight: 50 }}>
                      <RotateHint />
                    </Grid>
                    <Grid item>
                      <MovementHint />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <DazzedParagraph14 className={classes.text} textAlign="center">
                    <Trans i18nKey={t('dialogs.treatmentTracker.hints.movement')} />
                  </DazzedParagraph14>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={5}>
              <Grid container direction="column" alignItems="center" justifyContent="center">
                <Grid item style={{ marginBottom: 35 }}>
                  <ZoomHint />
                </Grid>
                <Grid item>
                  <DazzedParagraph14 bold className={classes.text}>
                    {t('dialogs.treatmentTracker.hints.zoom')}
                  </DazzedParagraph14>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    )
  )
}

export default HintsOverlay
