import * as React from 'react'

export default () => (
  <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0H6.25V1.875C6.25 3.60089 4.85089 5 3.125 5C1.39911 5 0 3.60089 0 1.875V0Z" fill="#3C52EF" />
    <path
      d="M0.5 8.12503C0.5 6.67528 1.67525 5.50003 3.125 5.50003C4.57474 5.50003 5.75 6.67528 5.75 8.12503V9.50003H0.5V8.12503Z"
      stroke="#3C52EF"
    />
  </svg>
)
