import React, { useMemo } from 'react'
import { downloadFileFromUrl } from 'utils/fileUtils'
import { getObjectUrl } from 'utils/mediaUtils'
import ImageViewerWrapper from './ImageViewerWrapper'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  imageViewer: {
    transition: 'opacity 0.00001ms ease-in-out !important'
  }
}))

/**
 * A component for viewing multiple images in a fancy popup.
 * Note: If both provided, both `imageS3Objects` and `imageUrls` lists are joined and all images are presented.
 *
 * @prop isVisible - a boolean to control whether the popup is open.
 * @prop onClose - a callback that's fired when the user closes the popup. Should set `isVisible` to `false`.
 * @prop imageS3Objects - list of S3 objects to display.
 * @prop imageUrls - list of image srcs to display.
 * @prop activeIndex - (Optional) The default image index to display.
 */
const ImagesViewer = ({ imageS3Objects = [], imageUrls = [], onClose, isVisible, activeIndex }) => {
  const classes = useStyles()

  const imagesSrc = useMemo(
    () => [
      ...imageS3Objects.map(s3Object => {
        const imageUrl = getObjectUrl(s3Object)
        return {
          src: imageUrl,
          alt: s3Object.key,
          downloadUrl: imageUrl
        }
      }),
      ...imageUrls.map(imageUrl => {
        return {
          src: imageUrl,
          downloadUrl: imageUrl
        }
      })
    ],
    [imageS3Objects, imageUrls]
  )

  const customToolbar = toolbarItems =>
    toolbarItems.map(item => {
      if (item.key === 'download') {
        return {
          key: item.key,
          render: (
            <div>
              <i className="react-viewer-icon react-viewer-icon-download"></i>
            </div>
          ),
          onClick: async activeImage => await downloadFileFromUrl(activeImage.src)
        }
      }

      return item
    })

  return isVisible ? (
    <ImageViewerWrapper
      downloadable
      noImgDetails
      attribute={false}
      changeable={false}
      showTotal={false}
      scalable={false}
      drag={false}
      disableMouseZoom
      zIndex={999999}
      zoomSpeed={0.8}
      noNavbar={imagesSrc.length === 1}
      activeIndex={activeIndex}
      visible={isVisible}
      onClose={onClose}
      images={imagesSrc}
      customToolbar={customToolbar}
      className={classes.imageViewer}
    />
  ) : (
    <></>
  )
}

export default ImagesViewer
