export const ViewTypes = {
  Regular: 'regular',
  Occlusal: 'occlusal'
}

export const TeethTrackingStatuses = {
  Tracking: 'tracking',
  TrackingNoMovement: 'trackingNoMovement',
  TrackingNoMovementPlanned: 'trackingNoMovementPlanned',
  PartiallyTracking: 'partiallyTracking',
  NotTracking: 'notTracking',
  NoMovement: 'noMovement',
  NoSignificantMovement: 'noSignificantMovement',
  Unknown: 'unknown',
  MissingTooth: 'missingTooth',
  Fast: 'fast',
  Moderate: 'moderate',
  Slow: 'slow',
  MissingSpeedInfo: 'missingSpeedInfo'
}

export const TreatmentSTLsStatuses = {
  PendingFilesTransfer: 'pending-files-transfer',
  Completed: 'completed'
}

export const StlProviders = {
  '3M': '3m',
  Spark: 'spark',
  uLab: 'ulab',
  Align: 'align',
  KLOwen: 'klowen',
  Other: 'other'
}

export const STL_BY_REQUEST_PROVIDERS = ['spark']
export const PROGRESS_TYPES = {
  Overall: 'Progress',
  Rotation: 'Rotation',
  Translation: 'Translation'
}

export const TxTrackerValidationSteps = {
  SelectProvider: 'selectProvider',
  PreUpload: 'preUpload'
}
