import * as React from 'react'

export default () => (
  <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="33" cy="33" r="32" stroke="#8FB4FF" />
    <path
      d="M31.1712 40.2479V38.6471C31.1821 37.2858 31.3454 36.1423 31.6612 35.2167C31.977 34.291 32.478 33.4688 33.1641 32.7501C33.8501 32.0204 34.7486 31.2853 35.8594 30.5448C36.8068 29.8914 37.58 29.2543 38.179 28.6336C38.7779 28.002 39.2135 27.3431 39.4858 26.657C39.7689 25.9709 39.9105 25.2195 39.9105 24.4028C39.9105 23.1286 39.6056 22.0069 38.9957 21.0377C38.3968 20.0576 37.58 19.2953 36.5455 18.7508C35.5218 18.1954 34.3674 17.9177 33.0824 17.9177C31.7973 17.9177 30.6321 18.2063 29.5866 18.7834C28.5412 19.3606 27.7027 20.1719 27.071 21.2174C26.4394 22.2629 26.1072 23.4934 26.0746 24.9092H24C24.0327 23.1776 24.4465 21.6476 25.2415 20.319C26.0473 18.9904 27.1309 17.9558 28.4922 17.2153C29.8535 16.4638 31.3835 16.0881 33.0824 16.0881C34.7921 16.0881 36.3113 16.453 37.6399 17.1826C38.9685 17.9013 40.0085 18.8923 40.7599 20.1556C41.5223 21.4189 41.9034 22.8564 41.9034 24.4681C41.9034 25.4809 41.7401 26.412 41.4134 27.2614C41.0866 28.1 40.5748 28.895 39.8778 29.6464C39.1809 30.3978 38.277 31.1601 37.1662 31.9333C36.1534 32.6303 35.353 33.2946 34.7649 33.9262C34.1877 34.5578 33.7794 35.2439 33.5398 35.9844C33.3002 36.725 33.1804 37.6125 33.1804 38.6471V40.2479H31.1712ZM32.2166 50.2124C31.7266 50.2124 31.3073 50.0436 30.9588 49.706C30.6212 49.3575 30.4524 48.9383 30.4524 48.4482C30.4524 47.9691 30.6212 47.5552 30.9588 47.2067C31.3073 46.8583 31.7266 46.684 32.2166 46.684C32.7067 46.684 33.1205 46.8583 33.4581 47.2067C33.8066 47.5552 33.9808 47.9691 33.9808 48.4482C33.9808 48.7749 33.8991 49.0744 33.7358 49.3467C33.5833 49.608 33.371 49.8204 33.0987 49.9837C32.8374 50.1362 32.5433 50.2124 32.2166 50.2124Z"
      fill="#8FB4FF"
    />
  </svg>
)
