import { Fade, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import useRolePermissions from 'hooks/useRolePermissions'
import useShowSnackbar from 'hooks/useShowSnackbar'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useUpdateNoteMutation } from 'store/modules/PatientNotes'
import { trackEvent } from 'utils/analyticsUtils'
import CreatorInfo from './CreatorInfo'
import DeleteNoteModal from './DeleteNoteModal'
import NoteContent from './NoteContent'
import NoteItemActions from './NoteItemActions'

const useStyles = makeStyles(theme => ({
  noteItemRoot: {
    position: 'relative',
    width: '100%',
    backgroundColor: ({ isHovered }) => (isHovered ? '#e5e5e5' : '#F3F3F4'),
    borderRadius: 10,
    padding: 5,
    marginBottom: 12,
    transition: 'background-color 0.3s ease-in-out'
  }
}))

const NoteItem = React.forwardRef(({ id, body, patientGrinUserId, isPinned, metadata, createdAt }, ref) => {
  const { t } = useTranslation()
  const doctor = useSelector(state => state.profileReducer.doctor)
  const { permissions } = useRolePermissions()

  const [isHovered, setIsHovered] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [currentBody, setCurrentBody] = useState(body)
  const [isDeleteNoteModalOpen, setIsDeleteModalOpen] = useState(false)

  const parsedMetadata = JSON.parse(metadata || '{}')

  const shouldDisplayActions = useMemo(
    () => isHovered && !isEditMode && permissions.addNotes,
    [isEditMode, isHovered, permissions]
  )

  const classes = useStyles({ isHovered })

  const [updateNote, { isError: isFailedUpdatingNote }] = useUpdateNoteMutation()
  useShowSnackbar({
    isOpen: isFailedUpdatingNote,
    message: t('pages.patients.patientSwitchableView.notes.generalError')
  })

  const handleToggleEditMode = useCallback(() => {
    trackEvent('Patient notes - Edit mode toggled', {
      toggle: !isEditMode ? 'off' : 'true'
    })
    setIsEditMode(!isEditMode)
  }, [isEditMode])

  const handleBodySaved = useCallback(async () => {
    trackEvent('Patient notes - New Body saved')
    setIsEditMode(!isEditMode)
    await updateNote({ id, variables: { body: currentBody, grinUserId: patientGrinUserId } })
  }, [currentBody, patientGrinUserId, id, isEditMode, updateNote])

  const handleBodyDiscarded = useCallback(() => {
    trackEvent('Patient notes - Body discarded')
    setCurrentBody(body)
    setIsEditMode(false)
  }, [body])

  const handleDeleteNoteClicked = useCallback(() => {
    trackEvent('Patient notes - Delete note modal opened')
    setIsDeleteModalOpen(true)
  }, [])

  const handleTogglePinnedNote = useCallback(async () => {
    trackEvent('Patient note: Note pin toggled', {
      noteId: id,
      isPinned: !isPinned,
      source: 'Switchable view'
    })
    await updateNote({ id, variables: { isPinned: !isPinned, grinUserId: patientGrinUserId } })
  }, [patientGrinUserId, id, isPinned, updateNote])

  const isNeedsAttentionNote = useMemo(() => parsedMetadata.noteType?.includes('needsAttention'), [parsedMetadata])

  return (
    <>
      <Grid
        container
        direction="column"
        className={classes.noteItemRoot}
        ref={ref}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Grid item>
          <CreatorInfo
            userId={parsedMetadata.creatorUserId}
            noteParsedMetadata={parsedMetadata}
            createdAt={createdAt}
          />
        </Grid>
        <Grid item>
          <NoteContent
            isEditMode={isEditMode}
            body={currentBody}
            noteParsedMetadata={parsedMetadata}
            setBody={setCurrentBody}
            onBodySaved={handleBodySaved}
            onBodyDiscarded={handleBodyDiscarded}
            isHovered={isHovered}
          />
        </Grid>
        {shouldDisplayActions && (
          <Fade in={shouldDisplayActions} timeout={500}>
            <Grid item>
              <NoteItemActions
                isEditable={doctor.user.id === parsedMetadata.creatorUserId && !isNeedsAttentionNote}
                isDeletable={doctor.user.id === parsedMetadata.creatorUserId && !isNeedsAttentionNote}
                isPinned={isPinned}
                onToggleEditMode={handleToggleEditMode}
                onDeleteNoteClicked={handleDeleteNoteClicked}
                onTogglePinnedNoteClicked={handleTogglePinnedNote}
              />
            </Grid>
          </Fade>
        )}
      </Grid>
      {isDeleteNoteModalOpen && (
        <DeleteNoteModal isOpen={isDeleteNoteModalOpen} setIsOpen={setIsDeleteModalOpen} noteId={id} />
      )}
    </>
  )
})

export default NoteItem
