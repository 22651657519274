import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import MembersTable from '../PracticeMembers/Table/MembersTable'
import SecondaryButton from 'components/common/buttons/SecondaryButton'
import AddDoctor from 'components/common/icons/AddDoctor'
import { trackEvent } from 'utils/analyticsUtils'
import DSOInviteDoctorModal from './DSOInviteDoctorModal'

const DSOInvitedDoctors = ({ invitedPractices = [] }) => {
  const { t } = useTranslation()
  const [isInviteDoctorModalOpen, setIsInviteDoctorModalOpen] = useState(false)

  const handleInviteDoctorClicked = useCallback(() => {
    trackEvent('DSO members - invite doctor button clicked')
    setIsInviteDoctorModalOpen(true)
  }, [])

  return (
    <div>
      <div style={{ margin: '40px 0' }}>
        <SecondaryButton
          label={t('pages.accountSettings.dsoMembers.inviteDoctor.button')}
          icon={<AddDoctor />}
          iconPosition="left"
          onClick={handleInviteDoctorClicked}
        />
      </div>
      {invitedPractices?.length > 0 && (
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <DazzedParagraph16 bold>{t('pages.accountSettings.dsoMembers.invitedDoctors')}</DazzedParagraph16>
          </Grid>
          <Grid item>
            <MembersTable practiceMembers={invitedPractices} hiddenColumns={['status', 'jobTitle']} />
          </Grid>
        </Grid>
      )}
      <DSOInviteDoctorModal isOpen={isInviteDoctorModalOpen} onClose={() => setIsInviteDoctorModalOpen(false)} />
    </div>
  )
}

export default DSOInvitedDoctors
