import React from 'react'
import FallbackGrinScanItemIcon from 'components/common/icons/timelineV2/mini-timeline/NormalGrinScanItem'
import OralScoreGrinScanItem from 'components/common/icons/timelineV2/mini-timeline/OralScoreGrinScanItem'
import { OralScoreColorCodes } from 'consts/oralScoreConsts'

const OralScoreIcon = ({ score, iconSize }) => {
  return score ? (
    <OralScoreGrinScanItem size={iconSize} color={OralScoreColorCodes[score]} />
  ) : (
    <FallbackGrinScanItemIcon />
  )
}

export default OralScoreIcon
