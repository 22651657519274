export default props => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="12" fill="#26C36F" />
    <rect x="6.5" y="10.5" width="11" height="7" rx="1.5" fill="white" stroke="white" />
    <path
      d="M9 10V7.75C9 6.23122 10.2312 5 11.75 5V5C13.2688 5 14.5 6.23122 14.5 7.75V10"
      stroke="white"
      strokeWidth="2"
    />
  </svg>
)
