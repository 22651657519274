import * as React from 'react'

const ReleaseImageEmptyState = () => (
  <svg width="360" height="180" viewBox="0 0 360 180" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="360" height="180" rx="16" fill="#001335" />
    <g clipPath="url(#clip0_56_59)">
      <path
        d="M184.79 87.2137C187.188 86.7177 189.556 86.0877 191.886 85.3266C190.494 74.4819 179.011 74.3624 179.011 74.3624C174.284 74.3624 170.4 75.9982 167.359 79.2697C164.421 82.4505 162.952 86.3687 162.952 91.0244C162.952 95.6349 164.368 99.4692 167.201 102.527C170.243 105.799 174.305 107.435 179.389 107.435C182.191 107.435 184.726 107.116 186.992 106.477C189.258 105.838 190.898 105.199 191.912 104.559L191.927 90.4237C191.927 90.3571 191.913 90.2913 191.884 90.2316C191.855 90.172 191.813 90.12 191.76 90.08C191.708 90.0401 191.648 90.0131 191.583 90.0015C191.519 89.9898 191.453 89.9937 191.391 90.0128C189.581 90.5879 185.108 91.836 180.127 92.6239V95.5118C181.811 95.5433 182.929 95.6956 183.481 95.9687C184.331 96.4103 185.131 97.3382 185.131 98.7531V100.124C185.133 100.559 185.05 100.99 184.887 101.391C184.723 101.793 184.482 102.156 184.177 102.461C183.415 103.212 182.194 103.717 180.242 103.717C176.633 103.717 173.661 102.043 172.58 99.5135C171.283 96.4787 171.449 94.8089 171.449 90.9106C171.449 86.1631 171.482 84.5668 172.997 82.0419C174.224 79.9969 176.614 79.0441 178.676 79.1061C178.676 79.1061 182.197 78.7904 183.827 81.9933C184.991 84.285 184.79 87.2137 184.79 87.2137Z"
        fill="white"
      />
      <path
        d="M217.973 83.4295C218.978 83.4295 219.821 83.0887 220.501 82.407C220.83 82.0889 221.09 81.7045 221.266 81.2782C221.441 80.8519 221.528 80.393 221.521 79.9307C221.521 78.9044 221.181 78.0442 220.501 77.3502C219.821 76.6562 218.978 76.3094 217.973 76.3098C217.519 76.3023 217.069 76.3912 216.651 76.5707C216.233 76.7502 215.856 77.0166 215.545 77.3527C214.877 78.0468 214.543 78.9069 214.543 79.9332C214.543 80.8773 214.883 81.6959 215.563 82.3891C216.243 83.0823 217.046 83.4291 217.973 83.4295Z"
        fill="white"
      />
      <path
        d="M202.452 85.3728C202.73 85.2246 203.061 85.4617 203.061 85.7818L203.055 90.5236C203.862 89.3984 204.814 88.3883 205.883 87.52C206.914 86.6842 207.96 86.2662 209.023 86.2662C211.055 86.2662 212.072 87.0403 212.072 88.5885C212.072 89.3946 211.769 90.2384 211.162 91.1198C210.555 92.0013 209.918 92.4429 209.25 92.4446C208.937 92.4356 208.628 92.3646 208.341 92.2356C207.998 92.0922 207.664 91.9294 207.339 91.748C206.489 91.3177 205.913 91.1013 205.61 91.0987C204.73 91.0987 204.04 91.4317 203.54 92.0976C203.039 92.7635 202.789 93.9323 202.788 95.604V102.757C202.788 102.874 202.795 102.991 202.809 103.107V106.646L196.209 106.642V88.1265C196.209 88.1265 199.537 86.9283 202.452 85.3728Z"
        fill="white"
      />
      <path
        d="M214.922 106.644V103.474C214.922 103.451 214.922 103.429 214.922 103.406V93.3733C214.922 93.3209 214.922 93.2723 214.922 93.2212V88.0915C214.922 88.0915 217.963 86.9553 220.915 85.4899C220.982 85.4577 221.055 85.4431 221.129 85.4475C221.202 85.452 221.274 85.4753 221.336 85.5153C221.398 85.5553 221.45 85.6107 221.486 85.6764C221.522 85.7421 221.541 85.816 221.541 85.8913V92.4166C221.531 92.692 221.525 92.9528 221.525 93.1879V103.36C221.525 103.43 221.531 103.5 221.541 103.57V106.644H214.922Z"
        fill="white"
      />
      <path
        d="M238.389 106.632V103.564C238.395 103.486 238.399 103.405 238.399 103.318V94.1646C238.399 94.0483 238.394 93.9358 238.389 93.8246L238.341 93.2751C238.244 92.5446 238.022 91.9414 237.671 91.4704C237.185 90.8207 236.548 90.4956 235.759 90.4952C235.247 90.4893 234.739 90.5758 234.256 90.7508C233.726 90.9611 233.222 91.2339 232.754 91.5637C232.511 91.7188 232.39 91.9974 232.39 92.3996V103.315C232.39 103.398 232.39 103.475 232.398 103.55V106.647H225.792V88.1448C228.929 86.9427 231.017 85.9349 231.99 85.4377C232.057 85.4045 232.13 85.3891 232.204 85.3929C232.278 85.3966 232.349 85.4195 232.412 85.4594C232.475 85.4992 232.526 85.5547 232.562 85.6206C232.598 85.6864 232.617 85.7605 232.617 85.8359V89.52C233.769 88.4362 234.846 87.631 235.848 87.1044C236.85 86.5778 238.003 86.3145 239.307 86.3145C240.976 86.3145 242.341 86.9184 243.402 88.1262C244.463 89.334 244.994 90.9598 244.995 93.0035V103.315C244.995 103.378 244.995 103.439 245 103.498V106.643H238.387"
        fill="white"
      />
    </g>
    <path d="M96.3843 104.318C125.267 104.318 149.284 86.4937 154.229 63" stroke="#FFBD39" strokeWidth="3.09884" />
    <defs>
      <clipPath id="clip0_56_59">
        <rect width="82.0476" height="33.0734" fill="white" transform="translate(162.952 74.3624)" />
      </clipPath>
    </defs>
  </svg>
)

export default ReleaseImageEmptyState
