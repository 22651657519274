import { GrinNotificationTypes } from '@grin-rnd/grin-api-sdk/dist/Enums/Notifications'

export const MessagingPreferenceEntities = {
  NeedsAttention: 'NeedsAttention',
  Patient: 'Patient',
  Scan: 'Scan',
  Message: 'Message',
  Assignee: 'Assignee',
  RCLead: 'RCLead',
  ServiceAccountNewLead: 'ServiceAccountNewLead'
}

export const NOTIFICATION_CENTER_MAX_NOTIFICATIONS_ON_BELL = 99
export const NOTIFICATION_CENTER_POLLING_INTERVAL_MS = 30 * 1000
export const NOTIFICATION_CENTER_PAGE_SIZE = 15

export const NotificationCenterTabs = {
  All: 'all',
  Scans: 'scans',
  Messages: 'messages',
  Assigned: 'assigned',
  NewPatients: 'newPatients',
  NeedsAttention: 'needsAttention'
}

export const NotificationCenterTabsConfiguration = {
  [NotificationCenterTabs.All]: {
    NotificationTypes: [],
    MessagingPreferenceEntities: Object.values(MessagingPreferenceEntities)
  },
  [NotificationCenterTabs.Scans]: {
    NotificationTypes: [GrinNotificationTypes.NewScan, GrinNotificationTypes.UrgentScan],
    MessagingPreferenceEntities: [MessagingPreferenceEntities.Scan]
  },
  [NotificationCenterTabs.Messages]: {
    NotificationTypes: [GrinNotificationTypes.NewMessage],
    MessagingPreferenceEntities: [MessagingPreferenceEntities.Message]
  },
  [NotificationCenterTabs.Assigned]: {
    NotificationTypes: [GrinNotificationTypes.PatientAssigned],
    MessagingPreferenceEntities: [MessagingPreferenceEntities.Assignee]
  },
  [NotificationCenterTabs.NewPatients]: {
    NotificationTypes: [
      GrinNotificationTypes.PatientJoined,
      GrinNotificationTypes.NewRcLead,
      GrinNotificationTypes.NewRecordsAppLead
    ],
    MessagingPreferenceEntities: [
      MessagingPreferenceEntities.Patient,
      MessagingPreferenceEntities.RCLead,
      MessagingPreferenceEntities.ServiceAccountNewLead
    ]
  },
  [NotificationCenterTabs.NeedsAttention]: {
    NotificationTypes: [GrinNotificationTypes.NeedsAttention],
    MessagingPreferenceEntities: [MessagingPreferenceEntities.NeedsAttention]
  }
}
