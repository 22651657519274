const CLEAR_REQUEST_PROVIDER_STLS_STATUS = 'CLEAR_REQUEST_PROVIDER_STLS_STATUS'
const REQUEST_PROVIDER_STLS = 'REQUEST_PROVIDER_STLS'
const REQUEST_PROVIDER_STLS_RECEIVED = 'REQUEST_PROVIDER_STLS_RECEIVED'
const REQUEST_PROVIDER_STLS_FAILED = 'REQUEST_PROVIDER_STLS_FAILED'
const SET_TREATMENT_TRACKER_MODAL_OPEN = 'SET_TREATMENT_TRACKER_MODAL_OPEN'
const UPLOAD_TX_TRACKER_STLS_COMPLETED = 'UPLOAD_TX_TRACKER_STLS_COMPLETED'
const TOGGLE_TX_TRACKER_MODAL_OPEN = 'TOGGLE_TX_TRACKER_MODAL_OPEN'
const TX_TRACKER_REQUEST_RERUN = 'TX_TRACKER_REQUEST_RERUN'
const TX_TRACKER_REQUEST_RERUN_RECEIVED = 'TX_TRACKER_REQUEST_RERUN_RECEIVED'
const TX_TRACKER_REQUEST_RERUN_FAILED = 'TX_TRACKER_REQUEST_RERUN_FAILED'
const TX_TRACKER_COMPARE_UPDATE_VIEW = 'TX_TRACKER_COMPARE_UPDATE_VIEW'
const TX_TRACKER_COMPARE_REMOVE_VIEW = 'TX_TRACKER_COMPARE_REMOVE_VIEW'
const TX_TRACKER_COMPARE_SET_SYNC = 'TX_TRACKER_COMPARE_SET_SYNC'
const TX_TRACKER_COMPARE_UPDATE_VIEWER_MODE = 'TX_TRACKER_COMPARE_UPDATE_VIEWER_MODE'
const TX_TRACKER_COMPARE_SET_POSE = 'TX_TRACKER_COMPARE_SET_POSE'
const TX_TRACKER_COMPARE_UPDATE_VIEWER_SETTINGS = 'TX_TRACKER_COMPARE_UPDATE_VIEWER_SETTINGS'

const txTrackerCompareUpdateViewerSettings = payload => ({
  type: TX_TRACKER_COMPARE_UPDATE_VIEWER_SETTINGS,
  payload
})

const txTrackerCompareSetPose = payload => ({
  type: TX_TRACKER_COMPARE_SET_POSE,
  payload
})

const txTrackerCompareUpdateViewerMode = payload => ({
  type: TX_TRACKER_COMPARE_UPDATE_VIEWER_MODE,
  payload
})

const txTrackerCompareRemoveView = payload => ({
  type: TX_TRACKER_COMPARE_REMOVE_VIEW,
  payload
})

const txTrackerCompareUpdateView = payload => ({
  type: TX_TRACKER_COMPARE_UPDATE_VIEW,
  payload
})

const txTrackerCompareSetSync = payload => ({
  type: TX_TRACKER_COMPARE_SET_SYNC,
  payload
})

const setTreatmentTrackerModalOpen = payload => ({
  type: SET_TREATMENT_TRACKER_MODAL_OPEN,
  payload
})

const toggleTxTrackerModalOpen = payload => ({
  type: TOGGLE_TX_TRACKER_MODAL_OPEN,
  payload
})

const requestProviderStls = payload => ({
  type: REQUEST_PROVIDER_STLS,
  payload
})

const requestProviderStlsReceived = () => ({
  type: REQUEST_PROVIDER_STLS_RECEIVED
})

const requestProviderStlsFailed = () => ({
  type: REQUEST_PROVIDER_STLS_FAILED
})

const uploadTxTrackerStlsCompleted = payload => ({
  type: UPLOAD_TX_TRACKER_STLS_COMPLETED,
  payload
})

const clearProviderStlsRequestStatus = () => ({
  type: CLEAR_REQUEST_PROVIDER_STLS_STATUS
})

const txTrackerRequestRerun = payload => ({
  type: TX_TRACKER_REQUEST_RERUN,
  payload
})

const txTrackerRequestRerunReceived = payload => ({
  type: TX_TRACKER_REQUEST_RERUN_RECEIVED,
  payload
})

const txTrackerRequestRerunFailed = payload => ({
  type: TX_TRACKER_REQUEST_RERUN_FAILED,
  payload
})

export default {
  SET_TREATMENT_TRACKER_MODAL_OPEN,
  REQUEST_PROVIDER_STLS,
  REQUEST_PROVIDER_STLS_RECEIVED,
  REQUEST_PROVIDER_STLS_FAILED,
  UPLOAD_TX_TRACKER_STLS_COMPLETED,
  CLEAR_REQUEST_PROVIDER_STLS_STATUS,
  TX_TRACKER_REQUEST_RERUN,
  TX_TRACKER_REQUEST_RERUN_RECEIVED,
  TX_TRACKER_REQUEST_RERUN_FAILED,
  TOGGLE_TX_TRACKER_MODAL_OPEN,
  TX_TRACKER_COMPARE_UPDATE_VIEW,
  TX_TRACKER_COMPARE_SET_SYNC,
  TX_TRACKER_COMPARE_REMOVE_VIEW,
  TX_TRACKER_COMPARE_UPDATE_VIEWER_MODE,
  TX_TRACKER_COMPARE_SET_POSE,
  TX_TRACKER_COMPARE_UPDATE_VIEWER_SETTINGS,
  txTrackerCompareUpdateViewerSettings,
  txTrackerCompareSetPose,
  txTrackerCompareUpdateViewerMode,
  txTrackerCompareRemoveView,
  txTrackerCompareSetSync,
  txTrackerCompareUpdateView,
  toggleTxTrackerModalOpen,
  clearProviderStlsRequestStatus,
  uploadTxTrackerStlsCompleted,
  setTreatmentTrackerModalOpen,
  requestProviderStls,
  requestProviderStlsReceived,
  requestProviderStlsFailed,
  txTrackerRequestRerun,
  txTrackerRequestRerunReceived,
  txTrackerRequestRerunFailed
}
