import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { makeStyles } from '@material-ui/core'
import Actions from 'actions'
import BaseModal from 'components/common/modals/BaseModal'
import images from 'resources'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { ROUTES } from 'consts'
import { trackEvent } from 'utils/analyticsUtils'
import { COGNITO_MFA_METHODS } from 'consts/mfaConsts'
import { isMfaRequiredForCurrentUser } from 'utils/authUtils'

const useStyles = () =>
  makeStyles({
    container: {
      width: '700px !important'
    },
    content: {
      whiteSpace: 'pre'
    },
    title: {
      fontSize: 16
    }
  })()

export default () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()

  const { isOpen, wasDisplayed } = useSelector(state => state.profileReducer.activateMFAModal)
  const { cognitoUser } = useSelector(state => state.authReducer)

  const handleClose = useCallback(() => {
    trackEvent('Activate MFA Modal - Close clicked')
    dispatch(
      Actions.setActivateMfaModalOpen({
        isOpen: false
      })
    )
  }, [dispatch])

  const handleSetupClicked = useCallback(() => {
    trackEvent('Activate MFA Modal - Set up MFA clicked')
    history.push(ROUTES.MFA_SETTINGS)
    dispatch(
      Actions.setActivateMfaModalOpen({
        isOpen: false
      })
    )
  }, [dispatch, history])

  useEffect(() => {
    if (isMfaRequiredForCurrentUser() && cognitoUser?.preferredMFA === COGNITO_MFA_METHODS.noMFA && !wasDisplayed) {
      trackEvent('Activate MFA Modal - Displayed')
      dispatch(
        Actions.setActivateMfaModalOpen({
          isOpen: true,
          wasDisplayed: true
        })
      )
    }
  }, [cognitoUser, dispatch, wasDisplayed])

  return (
    <BaseModal
      open={isOpen}
      handleClose={handleClose}
      variant="alert"
      title={t('dialogs.activateMFAModal.title')}
      titleClassName={classes.title}
      primaryLabel={t('dialogs.activateMFAModal.primaryLabel')}
      secondaryLabel={t('dialogs.activateMFAModal.secondaryLabel')}
      largerButtons
      primaryButtonWidth={290}
      onPrimaryBtnClick={handleSetupClicked}
      onSecondaryBtnClick={handleClose}
      titleImageComponent={<img src={images.mfaInactive} alt="" />}
      className={classes.container}
    >
      <DazzedParagraph14 strong textAlign="center" className={classes.content}>
        {t('dialogs.activateMFAModal.description')}
      </DazzedParagraph14>
    </BaseModal>
  )
}
