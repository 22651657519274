import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import { Box, Button, Link } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import SwooshLargeIcon from '../common/icons/SwooshLarge1'
import WithLoader from '../common/WithLoader'
import Actions from '../../actions'
import ROUTES from '../../consts/routesConsts'
import FormInput from '../common/FormInput'
import useQueryParams from 'hooks/useQueryParams'

const useSignUpStyles = makeStyles(theme => ({
  signUp: {
    padding: '100px 0',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main
  },
  signUpTitle: {
    fontFamily: 'Ogg',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '40px',
    lineHeight: '40px',
    marginBottom: '64px',
    color: 'var(--text-color-1)'
  },
  signUpSwoosh: {
    position: 'absolute',
    top: '0',
    left: '0'
  },
  nextButton: {
    background: 'var(--border-color-1)',
    color: 'var(--text-color-1)',
    width: '478px',
    borderRadius: '34px',
    '&:hover': {
      background: 'var(--border-color-1)'
    }
  },
  noFill: {
    background: 'none',
    border: 'none',
    boxShadow: 'none',
    '&:hover': {
      background: 'none',
      transition: 'color 0.2s ease',
      color: 'gray',
      boxShadow: 'none'
    }
  }
}))

const useAccountFormStyles = makeStyles({
  accountForm: {
    maxWidth: '478px'
  }
})

const AccountForm = () => {
  const classes = useAccountFormStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { emailAddress, password, passwordConfirmation, errors } = useSelector(state => state.authReducer)
  const { queryParams } = useQueryParams()

  useEffect(() => {
    if (queryParams.email) {
      const email = queryParams.email.toLowerCase().replaceAll(' ', '+')
      dispatch(
        Actions.changeAuthForm({
          key: 'emailAddress',
          value: email
        })
      )
    }
  }, [queryParams, dispatch])

  return (
    <div className={classes.accountForm}>
      <Box mb="20px">
        <FormInput
          title={t('pages.signUp.email')}
          placeholder={t('pages.signUp.emailPlaceholder')}
          type="email"
          validationRules={{ required: true }}
          value={emailAddress}
          allowAutoComplete={true}
          errorMessage={errors.email}
          setValue={emailAddress =>
            dispatch(
              Actions.changeAuthForm({
                key: 'emailAddress',
                value: emailAddress?.toLowerCase()
              })
            )
          }
        />
      </Box>
      <Box mb="20px">
        <FormInput
          title={t('pages.signUp.password')}
          placeholder={t('pages.signUp.passwordPlaceholder')}
          validationRules={{ required: true }}
          value={password}
          type="password"
          errorMessage={errors.password}
          setValue={password =>
            dispatch(
              Actions.changeAuthForm({
                key: 'password',
                value: password
              })
            )
          }
        />
      </Box>
      <Box mb="20px">
        <FormInput
          title={t('pages.signUp.confirmPassword')}
          placeholder={t('pages.signUp.confirmPasswordPlaceholder')}
          type="password"
          validationRules={{ required: true }}
          value={passwordConfirmation}
          errorMessage={errors.passwordConfirmation}
          setValue={passwordConfirmation =>
            dispatch(
              Actions.changeAuthForm({
                key: 'passwordConfirmation',
                value: passwordConfirmation
              })
            )
          }
        />
      </Box>
    </div>
  )
}

const SignUp = () => {
  const classes = useSignUpStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const isLoading = useSelector(state => state.authReducer.isLoading)

  const handleFormSubmit = e => {
    e.preventDefault()
    dispatch(Actions.completeAccountInfo())
  }

  return (
    <form className={classes.signUp} onSubmit={handleFormSubmit}>
      <SwooshLargeIcon className={classes.signUpSwoosh} />
      <Box display="flex" flexDirection="column" justifyContent="center">
        <div className={classes.signUpTitle}>{t('pages.signUp.createAccount')}</div>
        <AccountForm />
        <Box display="flex" justifyContent="center" mt="20px">
          <WithLoader isLoading={isLoading}>
            <Button variant="contained" type="submit" className={classes.nextButton}>
              {t('general.next')}
            </Button>
          </WithLoader>
        </Box>
        <Box display="flex" justifyContent="center" mt="20px">
          <Link component={RouterLink} to={ROUTES.SIGN_IN}>
            <Button variant="contained" className={`${classes.nextButton} ${classes.noFill}`}>
              {t('pages.signUp.backToSignIn')}
            </Button>
          </Link>
        </Box>
      </Box>
    </form>
  )
}

export default SignUp
