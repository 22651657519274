import { HygieneScoreValueMapping, OralScoreValueMapping, RiskValuekMapping } from 'consts/oralScoreConsts'
import { calculateOralScore } from 'logic/oralScoresLogic'
import { useMemo } from 'react'

/**
 * Calculates the aggregated Oral Score based on the oralScores dictionary returned from the scan summary model and converts the numeric values to `OralScores` enum.
 *
 * Note: In the future we might calculate and save this on the TimelineItem in the backend. When this is done we should adjust this hook.
 *
 * @param {Object} args
 * @param {{hygiene_score: number, dental_risk: number, perio_risk: number}} args.oralScores The raw oralScores dictionary that was generated by the model. See `oralScoreConsts.js` for more info.
 * @returns An object with the oral score values mapped to OralScore enum. If the original oral score object is not valid, the returned object would be empty.
 */
export default ({ oralScores }) => {
  const hasOralScores = useMemo(() => oralScores && !oralScores?.errorCode, [oralScores])

  const oralScoreNumeric = useMemo(() => {
    if (!oralScores || oralScores.errorCode) {
      return null
    }

    return calculateOralScore(oralScores)
  }, [oralScores])

  return hasOralScores
    ? {
        oralScoreNumeric,
        oralScore: OralScoreValueMapping[oralScoreNumeric],
        hygieneScore: HygieneScoreValueMapping[oralScores?.oral_hygiene_score],
        perioRisk: RiskValuekMapping[oralScores?.periodontal_risk],
        dentalRisk: RiskValuekMapping[oralScores?.dental_risk]
      }
    : {}
}
