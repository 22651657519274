import React, { useCallback } from 'react'
import { ButtonBase } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Tooltip from 'components/common/Tooltip'
import { trackEvent } from 'utils/analyticsUtils'

const useStyles = makeStyles(theme => ({
  notificationActionItem: {
    padding: '4px 4px',
    filter: ({ disabled }) => (disabled ? 'grayscale(80%) brightness(1.2)' : 'none')
  }
}))

const NotificationActionItem = ({
  actionKey,
  icon,
  tooltip,
  onClick = () => {},
  disabled = false,
  analyticsPayload = {}
}) => {
  const classes = useStyles({ disabled })

  const handleClick = useCallback(
    e => {
      e.stopPropagation()

      if (!disabled) {
        onClick()
        trackEvent(`Notification Center - notification action clicked`, {
          ...analyticsPayload,
          action: actionKey
        })
      }
    },
    [onClick, disabled, actionKey, analyticsPayload]
  )

  return (
    <Tooltip value={tooltip} placement="bottom">
      <ButtonBase className={classes.notificationActionItem} onClick={handleClick} disabled={disabled}>
        {icon}
      </ButtonBase>
    </Tooltip>
  )
}

export default NotificationActionItem
