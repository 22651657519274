import Actions from 'actions'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export default () => {
  const dispatch = useDispatch()
  const { patient, fetchedScansLeadId } = useSelector(state => state.patientsReducer)

  useEffect(() => {
    if (patient?.id && !patient?.grinScans?.items?.length && fetchedScansLeadId !== patient.id) {
      dispatch(Actions.fetchLeadScans({ leadId: patient.id }))
    }
  }, [dispatch, fetchedScansLeadId, patient])
}
