import React, { useCallback, useState } from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/styles'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import UserAccountItem from './UserAccountItem'
import { switchAccount } from 'logic/loginProfilesLogic'
import { trackEvent } from 'utils/analyticsUtils'
import AuthProfilesSearch from './AuthProfilesSearch'
import { scrollBar } from 'styles/theme'
import useAuthProfiles from './useAuthProfiles'
import { useSelector } from 'react-redux'
import DisabledOverlay from 'components/common/DisabledOverlay'

const useStyles = makeStyles(theme => ({
  authProfilesRoot: {
    position: 'relative',
    backgroundColor: 'rgba(245, 250, 254, 1)'
  },
  profileItem: {
    padding: '8px 16px'
  },
  divider: {
    background: 'rgba(232, 232, 232, 1)',
    width: 'calc(100% - 32px)',
    height: 1,
    margin: '0 auto'
  },
  profilesListContainer: {
    maxHeight: 300,
    overflowY: 'auto',
    ...scrollBar,
    '&::-webkit-scrollbar': {
      backgroundColor: 'rgba(245, 250, 254, 1)',
      width: 5
    }
  }
}))

const AuthProfiles = ({ className = '' }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [searchValue, setSearchValue] = useState('')
  const { totalProfiles, userAccounts, setProfileDisplayName, forgetProfile } = useAuthProfiles({ searchValue })
  const { isLoading } = useSelector(state => state.profileReducer)

  const handleSwitchAccount = useCallback(username => {
    trackEvent(`Switch account clicked`, { accountUsername: username })
    switchAccount(username)
  }, [])

  return totalProfiles <= 1 ? (
    <></>
  ) : (
    <Grid container direction="column" className={[classes.authProfilesRoot, className].join(' ')}>
      {isLoading && <DisabledOverlay />}
      <Grid item className={classes.profileItem}>
        <Grid container direction="row" alignItems="center" justifyContent="space-between">
          <Grid item>
            <DazzedParagraph14 bold color="var(--text-color-62)">
              {t('common.appMenu.authProfiles.title')}
            </DazzedParagraph14>
          </Grid>
          <Grid item>
            <AuthProfilesSearch value={searchValue} setValue={setSearchValue} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <div className={classes.profilesListContainer}>
          {userAccounts.map((acct, i) => (
            <React.Fragment key={acct.doctorId}>
              <div>
                <UserAccountItem
                  username={acct.username}
                  displayName={acct.displayName}
                  avatar={acct.avatar}
                  lastLoginDate={acct.lastLoginDate}
                  email={acct.email}
                  isCurrentlyLogged={acct.isCurrentlyLogged}
                  className={classes.profileItem}
                  onClick={acct.isCurrentlyLogged ? null : () => handleSwitchAccount(acct.username)}
                  setProfileDisplayName={displayName => setProfileDisplayName({ username: acct.username, displayName })}
                  forgetProfile={() => forgetProfile({ username: acct.username })}
                  disabled={isLoading}
                />
              </div>
              {i < userAccounts.length - 1 && <div className={classes.divider} />}
            </React.Fragment>
          ))}
        </div>
      </Grid>
    </Grid>
  )
}

export default AuthProfiles
