import React, { useCallback, useMemo } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import SelectInput from 'components/common/SelectInput'
import TriggerToActionArrow from 'components/common/icons/TriggerToActionArrow'
import { useTranslation } from 'react-i18next'
import { TriggerTypes, actionTypes } from 'consts/automatedActionConsts'
import { Message, PatientTag, AutomationsAssignPatient } from 'components/common/icons'
import useRolePermissions from 'hooks/useRolePermissions'

const useStyles = makeStyles({
  label: {
    color: 'var(--text-color-11)'
  },
  dropdown: {
    maxHeight: '350px !important'
  }
})

const RuleEditor = ({ triggerType, setTriggerType, actionType, setActionType, setConditions, errors }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { permissions } = useRolePermissions()

  const triggerTypesOptions = useMemo(
    () =>
      Object.values(TriggerTypes).map(triggerTypeValue => ({
        value: triggerTypeValue,
        label: t(`pages.accountSettings.automations.triggerTypes.${triggerTypeValue}`)
      })),
    [t]
  )

  const actionTypesOptions = useMemo(
    () =>
      [
        {
          value: actionTypes.SendMessage,
          label: t(`pages.accountSettings.automations.actionTypes.${actionTypes.SendMessage}`),
          icon: (
            <div style={{ marginTop: 6 }}>
              <Message />
            </div>
          ),
          condition: permissions.sendChatMessages
        },
        {
          value: actionTypes.AddPatientTag,
          label: t(`pages.accountSettings.automations.actionTypes.${actionTypes.AddPatientTag}`),
          icon: (
            <div style={{ marginTop: 6 }}>
              <PatientTag />
            </div>
          ),
          condition: true
        },
        {
          value: actionTypes.AssignPatient,
          label: t(`pages.accountSettings.automations.actionTypes.${actionTypes.AssignPatient}`),
          icon: (
            <div style={{ marginTop: 6 }}>
              <AutomationsAssignPatient />
            </div>
          ),
          condition: true
        },
        {
          value: actionTypes.ShowAnnouncement,
          label: t(`pages.accountSettings.automations.actionTypes.${actionTypes.ShowAnnouncement}`),
          icon: <></>,
          condition: permissions.opsDashboardSystemAutomations
        },
        {
          value: actionTypes.NotifyPractice,
          label: t(`pages.accountSettings.automations.actionTypes.${actionTypes.NotifyPractice}`),
          icon: <></>,
          condition: permissions.opsDashboardSystemAutomations
        }
      ].filter(({ condition }) => condition),
    [t, permissions]
  )

  const handleTriggerTypeChange = useCallback(
    newTriggerType => {
      setConditions([])
      setTriggerType(newTriggerType)
    },
    [setConditions, setTriggerType]
  )

  return (
    <Grid container alignItems="center">
      <Grid item xs={5}>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <DazzedParagraph16 bold className={classes.label}>
              {t('pages.accountSettings.automations.editorModal.triggerTypeLabel')}
            </DazzedParagraph16>
          </Grid>
          <Grid item>
            <SelectInput
              style={{ bright: true, thick: true }}
              options={triggerTypesOptions}
              value={triggerType}
              onChange={option => handleTriggerTypeChange(option.value)}
              placeholder={t('pages.accountSettings.automations.editorModal.triggerTypePlaceholder')}
              dropdownClassName={classes.dropdown}
              errorMessage={errors.requiredFields?.triggerType}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <TriggerToActionArrow style={{ marginTop: 15 }} />
      </Grid>
      <Grid item xs={5}>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <DazzedParagraph16 bold className={classes.label}>
              {t('pages.accountSettings.automations.editorModal.actionTypeLabel')}
            </DazzedParagraph16>
          </Grid>
          <Grid item>
            <SelectInput
              style={{ bright: true, thick: true }}
              options={actionTypesOptions}
              value={actionType}
              onChange={option => setActionType(option.value)}
              placeholder={t('pages.accountSettings.automations.editorModal.actionPlaceholder')}
              dropdownClassName={classes.dropdown}
              errorMessage={errors.requiredFields?.actionType}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default RuleEditor
