import DazzedHeading20 from 'components/common/text/DazzedHeading20'
import React from 'react'
import TreatmentTrackerModalHeader from './TreatmentTrackerModalHeader'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import InvalidResultsPrompt from './InvalidResultsPrompt'
import { ScanSummaryStatus } from 'consts/scanSummaryConsts'
import PendingState from './States/PendingState'
import FailedState from './States/FailedState'
import ArchesViewer from '../ArchesViewer/ArchesViewer'
import TeethTracking from '../TeethTracking/TeethTracking'
import ScanDetails from './ScanDetails'

const useStyles = makeStyles(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  trackingViewerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    height: '100%'
  },
  trackingData: {
    display: 'flex',
    flexDirection: 'column',
    flex: 3,
    backgroundColor: 'var(--bg-color-76)',
    borderRadius: 32,
    height: '100%',
    position: 'relative'
  }
}))

const SingleSTLView = ({
  hasNextScan,
  navigateToNextScan,
  hasPreviousScan,
  navigateToPreviousScan,
  scanNumber,
  alignerNumber,
  grinScan,
  patient,
  allTxTrackerScans,
  navigateToScanByScanId,
  isCompareStlsModeOn,
  setIsCompareStlsModeOn,
  parsedTxTrackerData,
  onOpenRequestRerunModal,
  archModels,
  calculatedTreatmentType,
  isSmallerScreen,
  initAlignerNumber,
  endAlignerNumber
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <div className={classes.content}>
      <DazzedHeading20>{t('dialogs.treatmentTracker.title')}</DazzedHeading20>
      <div style={{ marginBottom: 10 }}>
        <TreatmentTrackerModalHeader
          hasNextScan={hasNextScan}
          navigateToNextScan={navigateToNextScan}
          hasPreviousScan={hasPreviousScan}
          navigateToPreviousScan={navigateToPreviousScan}
          scanNumber={scanNumber}
          alignerNumber={alignerNumber}
          scanCreatedAt={grinScan.createdAt}
          patient={patient}
          allTxTrackerScans={allTxTrackerScans}
          navigateToScanByScanId={navigateToScanByScanId}
        />
      </div>
      <div style={{ height: '100%' }}>
        <div className={classes.trackingViewerContainer}>
          <div className={classes.trackingData}>
            <InvalidResultsPrompt
              treatmentType={calculatedTreatmentType}
              grinScan={grinScan}
              originalScanTxTrackerDetails={parsedTxTrackerData.originalScanDetails}
              rerunRequest={parsedTxTrackerData.rerunRequest}
              onRerunRequestClicked={onOpenRequestRerunModal}
            />
            {grinScan?.treatmentTrackerStatus === ScanSummaryStatus.Pending ? (
              <PendingState />
            ) : grinScan?.treatmentTrackerStatus === ScanSummaryStatus.Failed ? (
              <FailedState
                onRerunRequestClicked={onOpenRequestRerunModal}
                requestStatus={parsedTxTrackerData?.rerunRequest?.status}
                requestedAt={parsedTxTrackerData?.rerunRequest?.requestedAt}
              />
            ) : (
              <>
                <div style={{ flex: 3 }}>
                  <ArchesViewer
                    baseArchModels={archModels?.baseArchModels}
                    endArchModels={archModels?.endArchModels}
                    currentArchModels={archModels?.currentArchModels}
                    currentTxTrackerData={parsedTxTrackerData}
                    treatmentType={calculatedTreatmentType}
                    alignerNumber={grinScan?.applianceIndex}
                    scanNumber={scanNumber}
                    isSmallerScreen={isSmallerScreen}
                    initAlignerNumber={initAlignerNumber}
                    endAlignerNumber={endAlignerNumber}
                    isCompareStlsModeOn={isCompareStlsModeOn}
                    setIsCompareStlsModeOn={setIsCompareStlsModeOn}
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <TeethTracking
                    treatmentTrackerData={parsedTxTrackerData}
                    treatmentType={calculatedTreatmentType}
                    currentScan={grinScan}
                  />
                </div>
              </>
            )}
          </div>
          <div style={{ marginLeft: 10, flex: 1 }}>
            <ScanDetails grinScan={grinScan} />
          </div>
        </div>
      </div>
    </div>
  )
}
export default SingleSTLView
