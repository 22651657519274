import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles, CircularProgress, Grid } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import Actions from 'actions'
import ProfilePicture from 'components/common/ProfilePicture'
import { getEmailPrefix } from 'utils/generalUtils'
import PatientActionsMenu from './PatientActionsMenu'
import DazzeParagraph16 from 'components/common/text/DazzedParagraph16'
import PatientStatus from '../PatientStatus'
import GrinRadio from 'components/common/GrinRadio'
import usePatientTags from 'hooks/usePatientTags'
import useRolePermissions from 'hooks/useRolePermissions'
import { trackEvent } from 'utils/analyticsUtils'
import { createIdForTest } from 'utils/testingUtils.js'
import usePatientSpecialCondition, { SpecialConditions } from './usePatientSpecialCondition'
import PatientSpecialCondition from './PatientSpecialCondition'
import useSpecificMemberAttention from 'hooks/useSpecificMemberAttention'

const useStyles = ({ isDummyRoom, isDeleted }) =>
  makeStyles(theme => ({
    mainContainer: {
      borderBottom: '1px solid var(--border-color-3)',
      padding: '15px 0px 15px 10px',
      background: ({ isDummyRoom }) => (isDummyRoom ? '#e9ecee3d !important' : 'initial'),
      opacity: ({ isDeleted }) => (isDeleted ? 0.4 : 1),
      whiteSpace: 'nowrap',
      cursor: ({ isDeleted }) => (isDeleted ? 'cursor' : 'pointer')
    },
    container: {
      width: ({ isDummyRoom }) => (isDummyRoom ? '80%' : 'calc(80% - 20px)'),
      flexWrap: 'nowrap'
    },
    doctorNameContainer: {
      width: '100%',
      marginBottom: 5,
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    },
    doctorName: {
      fontSize: 10,
      fontWeight: 600,
      fontFamily: 'Dazzed'
    },
    unreadCount: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'var(--bg-color-13)',
      minWidth: 17,
      height: 17,
      fontSize: 12,
      borderRadius: 25,
      color: 'white',
      fontWeight: 600,
      padding: '0px 3px 0px 3px'
    },
    patientItemHeader: {
      fontSize: 16,
      fontWeight: 600,
      color: 'var(--primary-color)',
      marginBottom: 6,
      display: 'flex',
      justifyContent: 'space-between'
    },
    labelBox: {
      width: '70%'
    },
    patientName: {
      color: ({ isDummyRoom }) => (isDummyRoom ? 'gray' : 'initial'),
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    nameContainer: {
      width: '100%'
    },
    profilePictureContainer: {
      position: 'relative',
      marginRight: 20
    },
    loader: {
      color: theme.palette.primary.main,
      position: 'absolute',
      zIndex: 1,
      top: 0,
      left: 0
    },
    patientStatusText: {
      color: 'var(--text-color-11)'
    },
    unreadDot: {
      height: 10,
      width: 10,
      background: 'var(--bg-color-7)',
      marginRight: 8,
      borderRadius: '50%',
      opacity: 0
    },
    visibleUnreadDot: {
      opacity: 1
    },
    strong: {
      fontWeight: 'bold'
    },
    checkbox: {
      padding: 5
    },
    urgentUnreadDot: {
      background: 'var(--bg-color-41)'
    }
  }))({ isDummyRoom, isDeleted })

const PatientsListItem = ({
  id,
  name,
  firstName,
  lastName,
  email,
  username,
  statusKey,
  doctorName,
  program,
  profilePicture,
  isLoading,
  lastMessagePreview,
  lastMessageTimestamp,
  roomId,
  patient,
  isLead,
  isDummyRoom,
  isChatRoomResolved,
  patientTestId,
  isSelected
}) => {
  const isDeleted = useMemo(() => statusKey?.includes('deleted'), [statusKey])

  const classes = useStyles({ isDummyRoom, isDeleted })
  const dispatch = useDispatch()
  const { permissions } = useRolePermissions()

  const {
    selectedPatients,
    allSelected,
    excluded,
    isModeOn: isBroadcastMode
  } = useSelector(state => state.chatReducer.broadcast)
  const { data: statuses } = useSelector(state => state.treatmentReducer.statuses)
  const { tags: patientTags, needsAttentionTagDetails } = usePatientTags({ patient })

  const { specialCondition, overdueDays } = usePatientSpecialCondition({ patient, patientTags })
  const { selectedPracticeMembersTooltip, isAwaitingCurrentMembersAttention } = useSpecificMemberAttention({
    selectedPracticeMembersIds: needsAttentionTagDetails?.selectedPracticeMembersIds
  })

  const programStatuses = useMemo(() => statuses[program] || [], [statuses, program])
  const status = useMemo(() => programStatuses.find(option => option.key === statusKey), [programStatuses, statusKey])
  const hasUnreadMessages = useMemo(
    () => permissions.sendChatMessages && !isChatRoomResolved,
    [isChatRoomResolved, permissions]
  )

  const isPatientSelected = useMemo(
    () => (allSelected ? !excluded.find(id => id === patient?.id) : !!selectedPatients.find(id => id === patient?.id)),
    [allSelected, excluded, selectedPatients, patient?.id]
  )

  const handleClickSelectPatient = useCallback(
    e => {
      e.stopPropagation()
      isPatientSelected
        ? dispatch(Actions.unselectPatientForBroadcast(patient?.id))
        : dispatch(Actions.selectPatientForBroadcast(patient?.id))
    },
    [dispatch, isPatientSelected, patient?.id]
  )

  const handlePatientListItemClicked = useCallback(() => {
    if (!isDummyRoom) {
      if (isDeleted) return
      trackEvent('Patients list item - Patient selected', {
        isOverdue: !!overdueDays,
        status,
        patientId: id
      })
      dispatch(
        Actions.chatRoomSelected({
          roomId,
          patientId: id,
          previewDetails: {
            name,
            photo: profilePicture
          }
        })
      )
    }
  }, [isDummyRoom, dispatch, roomId, overdueDays, status, id, isDeleted, name, profilePicture])

  return (
    <Grid
      id={createIdForTest({ componentId: 'patient-list-item', title: patientTestId })}
      container
      justifyContent="space-between"
      alignItems="center"
      className={classes.mainContainer}
      style={{ background: isSelected ? 'var(--bg-color-11)' : 'initial' }}
      onClick={handlePatientListItemClicked}
    >
      {isBroadcastMode && (
        <Grid item xs={1}>
          {status.isChatAllowed && (
            <GrinRadio
              size="small"
              checked={isPatientSelected}
              onClick={handleClickSelectPatient}
              className={classes.checkbox}
            />
          )}
        </Grid>
      )}
      <Grid item xs={isBroadcastMode ? 8 : 9}>
        <Grid container alignItems="center" className={classes.container}>
          <Grid item id={createIdForTest({ componentId: 'unread-dot', title: patientTestId })}>
            <div
              className={[
                classes.unreadDot,
                hasUnreadMessages && classes.visibleUnreadDot,
                specialCondition === SpecialConditions.Urgent && classes.urgentUnreadDot
              ].join(' ')}
            />
          </Grid>
          <Grid item className={classes.profilePictureContainer}>
            {isLoading && <CircularProgress thickness={2} size={60} className={classes.loader} />}
            <ProfilePicture photo={profilePicture} name={name} email={email} size="large" />
          </Grid>
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="flex-start"
            className={classes.labelBox}
          >
            <Grid item style={{ width: '100%' }}>
              <DazzeParagraph16 bold={hasUnreadMessages} className={classes.patientName}>
                {name || getEmailPrefix(email)}
              </DazzeParagraph16>
            </Grid>
            {permissions.searchByDoctor && (
              <Grid item className={classes.doctorNameContainer}>
                <span className={classes.doctorName} title={doctorName}>
                  • {doctorName}
                </span>
              </Grid>
            )}
            <Grid item>
              {status && (
                <PatientStatus
                  bold={hasUnreadMessages}
                  status={status}
                  textClassName={classes.patientStatusText}
                  size="large"
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={1} style={{ marginTop: 3 }}>
        {status?.maxScansPerPeriod ? (
          <PatientSpecialCondition
            condition={specialCondition}
            patientTestId={patientTestId}
            overdueDays={overdueDays}
            isAwaitingCurrentMembersAttention={isAwaitingCurrentMembersAttention}
            specificMembersAttention={selectedPracticeMembersTooltip}
          />
        ) : (
          <></>
        )}
      </Grid>
      <Grid item xs={2}>
        {!isDummyRoom && permissions.patientListItemActions && (
          <PatientActionsMenu
            key={`patients-actions-menu-${patient?.id}`}
            isDeleted={isDeleted}
            id="patientQuickActions"
            iconComponent={<MoreVert />}
            isLoading={isLoading}
            patient={{
              ...patient,
              username,
              details: { ...patient?.details, firstName, lastName }
            }}
            isLead={isLead}
            roomId={roomId}
            hasUnreadMessages={hasUnreadMessages}
            status={status}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default React.memo(PatientsListItem)
