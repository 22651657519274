import { Grid, makeStyles } from '@material-ui/core'
import React from 'react'
import TagsList from '../../common/tags/TagsList'
import ProfilePicture from 'components/common/ProfilePicture'
import DazzedHeading18 from 'components/common/text/DazzedHeading18'
import TagsSkeleton from './TagsSkeleton'
import { useTranslation } from 'react-i18next'
import Tooltip from 'components/common/Tooltip'

const useStyles = makeStyles({
  container: {
    paddingLeft: 10
  },
  avatar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '0 20px'
  },
  patientName: {
    lineHeight: '32px',
    cursor: 'pointer',
    whiteSpace: 'nowrap'
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    maxWidth: '85%'
  },
  tooltip: {
    background: 'var(--bg-color-12)'
  },
  arrowTooltip: {
    color: 'var(--bg-color-12)'
  }
})

const PatientsDetails = ({
  patient,
  handleOpenPatientCard,
  tags,
  canDeleteNeedsAttention,
  handleDeleteTag,
  permissions,
  isLoading = false
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid container className={classes.container}>
      <Grid
        item
        id="patient-room-header-patient-card-button"
        className={classes.avatar}
        onClick={handleOpenPatientCard}
      >
        <Tooltip
          arrow
          value={t('pages.patients.selectedPatient.header.patientCardTooltip')}
          placement="bottom"
          classes={{ arrow: classes.arrowTooltip, tooltip: classes.tooltip }}
        >
          <ProfilePicture
            size="large"
            photo={patient.photo}
            name={patient.details.name}
            email={patient.details.email}
          />
        </Tooltip>
      </Grid>
      <Grid className={classes.item}>
        <Grid container direction="column">
          <Grid item onClick={handleOpenPatientCard}>
            <DazzedHeading18 className={classes.patientName}>{patient?.details?.name}</DazzedHeading18>
          </Grid>
          <Grid item>
            {isLoading ? (
              <TagsSkeleton numberOfTags={2} />
            ) : (
              <TagsList
                tags={tags}
                onDeleteTag={handleDeleteTag}
                canDeleteCustom={permissions.editPatientTags}
                canDeleteNeedsAttention={canDeleteNeedsAttention}
                canDeleteScan={false}
                showIcon={permissions.editPatientTags}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PatientsDetails
