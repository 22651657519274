import * as React from 'react'

export default () => (
  <svg
    width="20"
    height="17"
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginTop: 6 }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 2.24969C0 1.00722 1.00722 0 2.24969 0H9.24844C10.4909 0 11.4981 1.00722 11.4981 2.24969V5.74906C11.4981 6.99153 10.4909 7.99875 9.24844 7.99875H7.07814C6.88566 9.03863 6.10286 9.8941 5.04773 10.1579L3.2458 10.6084L3.12453 10.1233L2.77098 9.76973C3.23737 9.30335 3.49938 8.6708 3.49938 8.01123V7.99875H2.24969C1.00722 7.99875 0 6.99154 0 5.74907V2.24969ZM4.24086 9.32882L4.8052 9.18773C5.58021 8.99398 6.12391 8.29762 6.12391 7.49875C6.12391 7.22261 6.34777 6.99875 6.62391 6.99875H9.24844C9.93863 6.99875 10.4981 6.43925 10.4981 5.74906V2.24969C10.4981 1.5595 9.93863 1 9.24844 1H2.24969C1.55951 1 1 1.55951 1 2.24969V5.74907C1 6.43925 1.55951 6.99875 2.24969 6.99875H3.99938C4.27552 6.99875 4.49938 7.22261 4.49938 7.49875V8.01123C4.49938 8.46745 4.40997 8.91446 4.24086 9.32882ZM8.81102 3.62453H2.68711V2.62453H8.81102V3.62453ZM2.68711 5.37422H6.62391V4.37422H2.68711V5.37422Z"
      fill="#3C52EF"
    />
    <path
      d="M10.0904 7.95708C9.67931 6.82647 10.7382 5.71298 11.888 6.06678L13.1821 6.46496C13.6009 6.59381 14.0487 6.59381 14.4674 6.46496L15.7615 6.06678C16.9114 5.71298 17.9702 6.82647 17.5591 7.95709L17.4993 8.12153C17.2359 8.84594 17.0619 9.59979 16.9812 10.3664L16.6625 13.3941C16.5842 14.1377 15.5704 14.2949 15.2707 13.6099L14.4921 11.8302C14.2375 11.2483 13.412 11.2483 13.1574 11.8302L12.3788 13.6099C12.0791 14.2949 11.0653 14.1377 10.9871 13.3941L10.6683 10.3664C10.5876 9.59979 10.4137 8.84594 10.1502 8.12153L10.0904 7.95708Z"
      stroke="white"
      strokeWidth="4"
      strokeLinejoin="round"
    />
    <path
      d="M10.0904 7.95708C9.67931 6.82647 10.7382 5.71298 11.888 6.06678L13.1821 6.46496C13.6009 6.59381 14.0487 6.59381 14.4674 6.46496L15.7615 6.06678C16.9114 5.71298 17.9702 6.82647 17.5591 7.95709L17.4993 8.12153C17.2359 8.84594 17.0619 9.59979 16.9812 10.3664L16.6625 13.3941C16.5842 14.1377 15.5704 14.2949 15.2707 13.6099L14.4921 11.8302C14.2375 11.2483 13.412 11.2483 13.1574 11.8302L12.3788 13.6099C12.0791 14.2949 11.0653 14.1377 10.9871 13.3941L10.6683 10.3664C10.5876 9.59979 10.4137 8.84594 10.1502 8.12153L10.0904 7.95708Z"
      stroke="#3C52EF"
      strokeLinejoin="round"
    />
  </svg>
)
