import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactSketchCanvas } from 'react-sketch-canvas'
import { CircularProgress, Grid, makeStyles, useMediaQuery } from '@material-ui/core'
import BaseModal from 'components/common/modals/BaseModal'
import ConfirmationModal from 'components/common/ConfirmationModal'
import ButtonsGroup from 'components/common/annotations/ButtonsGroup'
import { hexToRgba } from 'utils/colorUtils'
import { isEmpty } from 'lodash'
import {
  DEFAULT_LINE_WIDTH,
  DEFAULT_BRUSH_OPACITY,
  DEFAULT_LINE_COLOR,
  DEFAULT_RGBA_COLOR,
  DRAW_CONFIG_NAME
} from 'consts/annotationsConsts'
import { trackEvent } from 'utils/analyticsUtils'
import LinkButton from '../buttons/LinkButton'
import { Download1 } from '../icons'
import { base64ToFile, downloadFile } from 'utils/fileUtils'
import { resizeImageToBase64 } from 'utils/canvasUtils'
import { logError } from 'utils/logUtils'

const useStyles = () =>
  makeStyles({
    modal: {
      maxWidth: '100%'
    },
    modalContent: {
      marginTop: 60,
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    canvas: {
      border: 'none !important'
    },
    loaderContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 12
    },
    sliderWrapper: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      marginTop: 24,
      marginBottom: 100
    },
    drawingContainer: {
      backgroundColor: 'var(--bg-color-4)'
    }
  })()

/**
 * This modal implements the capability to sketch over an image.
 *
 * **Note about canvas height and width:** the canvas height is capped based on the screen's height. In case the provided dimensions exceed that
 * limitation, the component automatically adjusts the height and width while maintaining the original aspect ratio.
 *
 * @param {Object} props
 * @param {boolean} props.isOpen - A boolean that controls whether the modal is open or not.
 * @param {Function} props.onClose - A callback that's called when the modal should be closed (should set isOpen to false).
 * @param {number} props.canvasHeight - The desired canvas height in pixels. See the note on the component description for more details.
 * @param {number} props.canvasWith - The desired canvas width in pixels. See the note on the component description for more details.
 * @param {string} props.imgSrc - The background image's src.
 * @param {Function} props.onDrawingComplete - A callback that will be fired when the user finishes drawing. It's called with the final image.
 * @param {Object} props.analyticsPayload - An object that will be added to the analytics.
 * @param {Array<{icon: any, label: string, onClick: Function, disabled: boolean}>} props.customActions - Additional actions that will be rendered under the drawing panel. Each action's onClick will be called with the current image in base64.
 */
const ImageDrawingModal = ({
  isOpen,
  onClose = () => {},
  canvasWidth,
  canvasHeight,
  imageSrc,
  onDrawingComplete = () => {},
  analyticsPayload = {},
  customActions = [],
  isLoading = false
}) => {
  const classes = useStyles()
  const canvasRef = useRef()
  const { t } = useTranslation()
  const isSmallScreen = useMediaQuery('(max-width:1490px)')

  const [eraseMode, setEraseMode] = useState(false)
  const [drawColor, setDrawColor] = useState(DEFAULT_LINE_COLOR)
  const [drawOpacity, setDrawOpacity] = useState(DEFAULT_BRUSH_OPACITY)
  const [drawRgbaColor, setDrawRgbaColor] = useState(DEFAULT_RGBA_COLOR)
  const [lineWidth, setLineWidth] = useState(DEFAULT_LINE_WIDTH)
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const [backgroundImageBase64, setBackgroundImageBase64] = useState()

  const canvasDimensions = useMemo(() => {
    const aspectRatio = canvasHeight > 0 ? canvasWidth / canvasHeight : 1
    const maxHeight = isSmallScreen ? 450 : 700
    const height = Math.min(maxHeight, canvasHeight)
    const width = height * aspectRatio

    return {
      height,
      width
    }
  }, [canvasWidth, canvasHeight, isSmallScreen])

  const saveConfig = useCallback(() => {
    const config = JSON.stringify({
      drawColor,
      drawOpacity,
      drawRgbaColor,
      lineWidth
    })
    localStorage.setItem(DRAW_CONFIG_NAME, config)
  }, [drawColor, lineWidth, drawOpacity, drawRgbaColor])

  const sendActionClickedAnalytics = useCallback(
    (action, props = {}) => {
      trackEvent('Image Drawing - Action clicked', { action, ...props, ...analyticsPayload })
    },
    [analyticsPayload]
  )

  const exportImage = useCallback(async () => {
    return canvasRef.current.exportImage()
  }, [])

  const closeModal = useCallback(() => {
    setIsConfirmationModalOpen(false)
    saveConfig()
    onClose()
  }, [saveConfig, onClose])

  const handleCancel = useCallback(async () => {
    const paths = await canvasRef.current.exportPaths()
    !!paths.length ? setIsConfirmationModalOpen(true) : closeModal()
  }, [closeModal])

  const handleFinishClicked = useCallback(async () => {
    const image = await exportImage()
    onDrawingComplete(image)
    saveConfig()
    sendActionClickedAnalytics('Done')
    closeModal()
  }, [onDrawingComplete, saveConfig, sendActionClickedAnalytics, closeModal, exportImage])

  /** Editor Action Buttons */

  const handleEraseMode = useCallback(
    value => {
      setEraseMode(value)
      canvasRef.current.eraseMode(value)

      if (value) {
        sendActionClickedAnalytics('Erase Mode')
      }
    },
    [sendActionClickedAnalytics]
  )

  const handleChangeColor = useCallback(
    color => {
      setDrawColor(color)
      const rgbaColor = hexToRgba(color, drawOpacity)
      setDrawRgbaColor(rgbaColor)
      handleEraseMode(false)

      sendActionClickedAnalytics('Color Changed', {
        fromColor: drawColor,
        toColor: color
      })
    },
    [setDrawColor, handleEraseMode, drawOpacity, drawColor, sendActionClickedAnalytics]
  )

  const handleChangeOpacity = useCallback(
    (event, newOpacity) => {
      setDrawOpacity(newOpacity)
      const rgbaColor = hexToRgba(drawColor, newOpacity)
      setDrawRgbaColor(rgbaColor)
    },
    [setDrawOpacity, drawColor]
  )

  const handleChangeBrushWidth = useCallback(
    (event, newWidth) => {
      setLineWidth(newWidth)
    },
    [setLineWidth]
  )

  const handleClearCanvas = useCallback(() => {
    canvasRef.current.clearCanvas()
    sendActionClickedAnalytics('ClearAll')
  }, [sendActionClickedAnalytics])

  const handleUndo = useCallback(() => {
    canvasRef.current.undo()
    sendActionClickedAnalytics('Undo')
  }, [sendActionClickedAnalytics])

  const handleRedo = useCallback(() => {
    canvasRef.current.redo()
    sendActionClickedAnalytics('Redo')
  }, [sendActionClickedAnalytics])

  const handleCustomActionClicked = useCallback(
    async action => {
      const imageBase64 = await exportImage()
      action.onClick(imageBase64)
      sendActionClickedAnalytics(action.label)
    },
    [sendActionClickedAnalytics, exportImage]
  )

  const imageActions = useMemo(
    () =>
      customActions.concat([
        {
          label: t('general.download'),
          icon: <Download1 />,
          onClick: imageBase64 => {
            const imageFile = base64ToFile(imageBase64.split(',')[1], 'Sketch.png', 'image/png')
            downloadFile(imageFile)
          }
        }
      ]),
    [customActions, t]
  )

  useEffect(() => {
    const drawConfig = JSON.parse(localStorage.getItem(DRAW_CONFIG_NAME) || '{}')
    if (!isEmpty(drawConfig)) {
      setDrawColor(drawConfig.drawColor)
      setDrawOpacity(drawConfig.drawOpacity)
      setLineWidth(drawConfig.lineWidth)
      setDrawRgbaColor(drawConfig.drawRgbaColor)
    }
  }, [])

  useEffect(() => {
    if (backgroundImageBase64) {
      return
    }

    const load = async () => {
      try {
        const base64 = await resizeImageToBase64({
          imageSrc,
          height: canvasDimensions.height,
          width: canvasDimensions.width
        })
        setBackgroundImageBase64(base64)
        trackEvent(`Image Drawing - background image loaded`, { ...analyticsPayload })
      } catch (ex) {
        logError(`ImageDrawingModal: failed to load imageSrc: ${ex.message}`, { error: ex, canvasDimensions })
      }
    }
    load()
  }, [canvasDimensions, imageSrc, backgroundImageBase64, analyticsPayload])

  useEffect(() => {
    if (isOpen && imageSrc) {
      setBackgroundImageBase64(null)
    }
  }, [imageSrc, isOpen])

  return (
    <>
      <BaseModal
        open={isOpen}
        handleClose={handleCancel}
        className={classes.modal}
        contentClassName={classes.modalContent}
        primaryLabel={t('general.done')}
        onPrimaryBtnClick={handleFinishClicked}
        secondaryLabel={t('general.cancel')}
        onSecondaryBtnClick={handleCancel}
      >
        <div className={classes.drawingContainer}>
          {isLoading && (
            <div className={classes.loaderContainer}>
              <CircularProgress size={60} color="secondary" />
            </div>
          )}
          <ReactSketchCanvas
            ref={canvasRef}
            className={classes.canvas}
            canvasColor="var(--bg-color-4)"
            backgroundImage={backgroundImageBase64}
            width={canvasDimensions.width}
            height={canvasDimensions.height}
            style={{ justifySelf: 'center' }}
            exportWithBackgroundImage
            strokeColor={drawColor}
            strokeWidth={lineWidth}
            eraserWidth={lineWidth * 3}
          />
          <ButtonsGroup
            canvasWidth={canvasDimensions.width}
            eraseMode={eraseMode}
            drawWidth={lineWidth}
            drawColor={drawColor}
            drawOpacity={drawOpacity}
            defaultLineWidth={DEFAULT_LINE_WIDTH}
            defaultBrushOpacity={DEFAULT_BRUSH_OPACITY}
            onEraseMode={handleEraseMode}
            onUndoAction={handleUndo}
            onRedoAction={handleRedo}
            onSaveConfig={saveConfig}
            onClearCanvas={handleClearCanvas}
            onChangeWidth={handleChangeBrushWidth}
            onChangeColor={handleChangeColor}
            onChangeOpacity={handleChangeOpacity}
            sliderClassName={classes.sliderWrapper}
            fullHeightSlider
          />
        </div>
        <Grid container alignItems="center" justifyContent="flex-end" style={{ marginTop: 0 }} spacing={2}>
          {imageActions.map(action => (
            <Grid item key={action.label}>
              <LinkButton
                label={action.label}
                icon={action.icon}
                fontSize={14}
                disabled={action.disabled}
                onClick={() => handleCustomActionClicked(action)}
              />
            </Grid>
          ))}
        </Grid>
      </BaseModal>
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        title={t('dialogs.scanSummary.annotations.modal.title')}
        text={t('dialogs.scanSummary.annotations.modal.subtitle')}
        acceptButtonText={t('dialogs.scanSummary.annotations.modal.primaryBtnLabel')}
        declineButtonText={t('dialogs.scanSummary.annotations.modal.secondaryBtnLabel')}
        onConfirm={closeModal}
        onClose={() => setIsConfirmationModalOpen(false)}
        smallText
        largerButtons
      />
    </>
  )
}

export default React.memo(ImageDrawingModal)
