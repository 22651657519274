export const ARCH_DEFAULT_COLOR = 0xaaaaaa
export const DEVIATION_COLORS = {
  MINOR_OFFSET: 0x73b36d,
  SMALL_OFFSET: 0xffb11a,
  SIGNIFICANT_OFFSET: 0xff2a2a
}
export const BRACES_DEVIATION_ARCH_COLOR = 0x00d6e4
export const UNKNOWN_TOOTH_COLOR = 0x7da5fa
export const ARCHES_DEVIATION_LIMITS = {
  MINOR_OFFSET: 0.2,
  SMALL_OFFSET: 0.3
}
export const MONTH_SPEED_LIMITS = {
  FAST: 0.5,
  MINOR: 0.3,
  TINY: 0.1
}
export const ORIGIN_ARHC_MODELS_TYPES = {
  BASE: 'base',
  CURRENT: 'current',
  END: 'end'
}
export const BASE_DEFAULT_OPACITY = 0.7
export const OVERLAY_DEFAULT_OPACITY = 1
export const UPPER_BASE_NAME = 'upperBase'
export const LOWER_BASE_NAME = 'lowerBase'
