import { useMemo } from 'react'
import useTeethTracking from '../useTeethTracking'
import MuiTooltip from '@mui/material/Tooltip'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  dot: {
    pointerEvents: 'all',
    cursor: 'pointer'
  },
  tooltip: {
    backgroundColor: 'var(--text-color-27) !important',
    color: 'white !important',
    fontSize: '14px !important',
    fontWeight: '500 !important'
  },
  arrow: {
    color: 'var(--text-color-27) !important'
  }
}))

const GraphDot = ({ cx, cy, payload }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { trackingStatuses } = useTeethTracking()
  const { alignerNumber, scanNumber } = payload

  const trackingStatusStyle = useMemo(
    () => trackingStatuses[payload.trackingStatus],
    [payload?.trackingStatus, trackingStatuses]
  )
  const tooltipTitle = useMemo(() => {
    const alignerNumberText = payload.alignerNumber
      ? ` - ${t('dialogs.treatmentTracker.teethTracking.table.tooltip.alignerNumber', {
          alignerNumber
        })}`
      : ''

    return `${t('dialogs.treatmentTracker.teethTracking.table.tooltip.scanNumber', {
      scanNumber
    })}${alignerNumberText}`
  }, [alignerNumber, payload.alignerNumber, scanNumber, t])

  return !payload.value ? (
    <></>
  ) : (
    <MuiTooltip
      placement="top"
      title={tooltipTitle}
      arrow
      classes={{
        tooltip: classes.tooltip,
        arrow: classes.arrow
      }}
    >
      {payload.currentScanCreatedAt === payload.createdAt ? (
        <circle
          cx={cx}
          cy={cy}
          r={9}
          fill={trackingStatusStyle.backgroundColor}
          stroke={'#4151E6'}
          strokeDasharray="1"
          strokeWidth="2"
          className={classes.dot}
        />
      ) : (
        <circle
          cx={cx}
          cy={cy}
          r={5}
          fill={trackingStatusStyle.backgroundColor}
          stroke={trackingStatusStyle.dotBorderColor}
          strokeWidth={payload.shouldApplyAsterisk ? '0' : '3'}
          className={classes.dot}
        />
      )}
    </MuiTooltip>
  )
}

export default GraphDot
