import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid, IconButton } from '@material-ui/core'
import DazzedParagraph16 from '../text/DazzedParagraph16'
import PrimaryButton from '../buttons/PrimaryButton'
import SecondaryButton from '../buttons/SecondaryButton'
import { Close, TooltipArrow } from '../icons'
import GrinPopper from './GrinPopper'

const useStyles = makeStyles(theme => ({
  menu: {
    borderRadius: 10
  },
  container: {
    padding: '30px 20px 20px 20px'
  },
  closeIconContainer: {
    position: 'absolute',
    right: 10,
    top: 10,
    cursor: 'pointer',
    zIndex: 999
  },
  childrenContainer: {
    width: '100%'
  }
}))

const GrinFloatingPopup = ({
  children,
  onClose = () => {},
  arrow = false,
  arrowPlacement = 'bottom',
  className = '',
  containerClassName = '',
  triggerComponent,
  onOpen = () => {},
  open,
  customAnchorEl,
  title = '',
  onTitleClick = () => {},
  titleClassName,
  titleIcon,
  secondaryBtnLabel = '',
  onSecondaryBtnClick = () => {},
  primaryBtnLabel = '',
  onPrimaryBtnClick = () => {},
  isPrimaryBtnDisabled = false,
  primaryBtnIcon = <></>,
  buttonProps = {},
  withCloseIcon = true,
  isLoading = false,
  placement = 'bottom',
  grinPopperProps = {},
  automaticallyOpen
}) => {
  const classes = useStyles()

  return (
    <GrinPopper
      className={`${classes.menu} ${className}`}
      onOpen={onOpen}
      onClose={onClose}
      open={open}
      automaticallyOpen={automaticallyOpen}
      triggerComponent={triggerComponent}
      customAnchorEl={customAnchorEl}
      placement={placement}
      arrowPlacement={arrowPlacement}
      arrowComponent={arrow ? <TooltipArrow /> : null}
      {...grinPopperProps}
    >
      <>
        {withCloseIcon && (
          <IconButton className={classes.closeIconContainer} aria-label="Close" onClick={onClose}>
            <Close color="black" />
          </IconButton>
        )}
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          className={[classes.container, containerClassName].join(' ')}
          spacing={2}
          onKeyDown={e => {
            e.stopPropagation()
          }}
        >
          {(title || titleIcon) && (
            <Grid item>
              <Grid container alignItems="center" spacing={1} onClick={onTitleClick}>
                {titleIcon && <Grid item>{titleIcon}</Grid>}
                <Grid item>
                  <DazzedParagraph16 strong className={titleClassName}>
                    {title}
                  </DazzedParagraph16>
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item className={classes.childrenContainer}>
            {children}
          </Grid>
          {(secondaryBtnLabel || primaryBtnLabel) && (
            <Grid item>
              <Grid container spacing={2} justifyContent="center" alignItems="center">
                {secondaryBtnLabel && (
                  <Grid item>
                    <SecondaryButton
                      label={secondaryBtnLabel}
                      onClick={onSecondaryBtnClick}
                      isLoading={isLoading}
                      {...buttonProps}
                    />
                  </Grid>
                )}
                {primaryBtnLabel && (
                  <Grid item>
                    <PrimaryButton
                      label={primaryBtnLabel}
                      onClick={onPrimaryBtnClick}
                      isLoading={isLoading}
                      disabled={isPrimaryBtnDisabled}
                      icon={primaryBtnIcon}
                      {...buttonProps}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </>
    </GrinPopper>
  )
}

export default GrinFloatingPopup
