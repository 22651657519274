import React, { useCallback, useMemo } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { useTranslation } from 'react-i18next'
import { actionTypes } from 'consts/automatedActionConsts'
import TextAreaAutosize from 'components/common/TextareaAutosize'
import ErrorMessage from 'components/common/text/ErrorMessage'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'

const useStyles = props =>
  makeStyles({
    messageInput: {
      border: 'none!important'
    },
    messageInputContainer: {
      borderRadius: 10,
      border: ({ errorMessage }) => `1px solid ${errorMessage ? 'red' : 'var(--bg-color-65)'}`,
      paddingLeft: 10
    }
  })(props)

const NotifyPractice = ({ onChange, params, errors, isTimeBased }) => {
  const errorMessage = useMemo(() => errors.actions?.[actionTypes.NotifyPractice], [errors])
  const classes = useStyles({ errorMessage })
  const { t } = useTranslation()

  const handleParamsChange = useCallback(
    note => {
      onChange({
        type: actionTypes.NotifyPractice,
        params: {
          note: note ?? (params?.note || '')
        }
      })
    },
    [onChange, params]
  )

  return (
    <Grid container direction="column">
      <Grid item>
        <DazzedParagraph14 strong>
          {t('pages.accountSettings.automations.editorModal.notifyPracticeNoteLabel')}
        </DazzedParagraph14>
      </Grid>
      <Grid item>
        <DazzedParagraph12>
          {t('pages.accountSettings.automations.editorModal.insertPatientStatusNote')}
        </DazzedParagraph12>
      </Grid>
      <Grid item xs={12} className={classes.messageInputContainer}>
        <TextAreaAutosize
          inputStyle={{ fontSize: 14, fontWeight: 500, height: 100, overflow: 'auto' }}
          rowsMax={4}
          style={{ bright: true }}
          className={classes.messageInput}
          placeholder={t('pages.accountSettings.automations.editorModal.sendMessagePlaceholder')}
          keepErrorContainerWhenInactive={false}
          value={params?.note || ''}
          setValue={handleParamsChange}
        />
      </Grid>
      {errorMessage && (
        <Grid item>
          <ErrorMessage active={!!errorMessage} text={errorMessage} />
        </Grid>
      )}
    </Grid>
  )
}
export default NotifyPractice
