import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import ConfirmationModal from 'components/common/ConfirmationModal'
import { useDispatch, useSelector } from 'react-redux'
import { trackEvent } from 'utils/analyticsUtils'
import Actions from 'actions'

const useStyles = makeStyles(theme => ({
  modalRoot: {
    width: '450px!important'
  }
}))

const SwitchWithAlignerModal = ({
  isOpen,
  setIsOpen,
  grinScanId,
  isDoubleScan,
  isCurrentlyWithAligner,
  analyticsPayload = {}
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { isLoading } = useSelector(state => state.timelineReducer.scanFeed.switchScanWithAligners)

  const handleConfirm = useCallback(() => {
    trackEvent(`Switch scan with / without aligners - Confirm clicked`, analyticsPayload)
    dispatch(
      Actions.timelineSwitchScanWithAligners({
        grinScanId,
        newWithAlignerValue: !isCurrentlyWithAligner
      })
    )
    setIsOpen(false)
  }, [analyticsPayload, dispatch, grinScanId, isCurrentlyWithAligner, setIsOpen])

  return (
    <ConfirmationModal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      onConfirm={handleConfirm}
      acceptButtonText={t('general.confirm')}
      isLoading={isLoading}
      className={classes.modalRoot}
      title={t(`pages.patients.selectedPatient.timelineV2.switchWithAlignersModal.title`)}
      text={t(
        `pages.patients.selectedPatient.timelineV2.switchWithAlignersModal.text.${
          isDoubleScan ? 'doubleScan' : 'singleScan'
        }`,
        {
          newState: isDoubleScan
            ? isCurrentlyWithAligner
              ? t('general.withAligners')
              : t('general.withoutAligners')
            : isCurrentlyWithAligner
            ? t('general.withoutAligners')
            : t('general.withAligners')
        }
      )}
    />
  )
}

export default SwitchWithAlignerModal
