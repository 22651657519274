import { createApi } from '@reduxjs/toolkit/query/react'
import { API, graphqlOperation } from 'aws-amplify'
import { fetchAll, GraphQLMutationTypes, mutateEntity } from 'utils/graphqlUtils'
import { ModuleTags } from './modules/consts'

export const REQUEST_TYPE = {
  GRAPHQL: 'graphql',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
  GET: 'GET',
  GRAPHQL_FETCH_ALL: 'GRAPHQL_FETCH_ALL',
  GRAPHQL_MUTATION: 'GRAPHQL_MUTATION'
}

/**
 * Executes an API request based on the given request type (GraphQL, REST methods, or Fetch All).
 *
 * @async
 * @function grinBaseQuery
 * @param {Object} args - The arguments for the API request.
 * @param {Object} [args.data={}] - The request payload data (used for REST API requests).
 * @param {Object} [args.variables={}] - The variables for GraphQL queries.
 * @param {string} [args.queryName=''] - The query name, used only for GraphQL Fetch All requests.
 * @param {string} [args.apiName='grinServerlessApi'] - The API name (used for REST API requests).
 * @param {string} [args.path='/'] - The API endpoint path.
 * @param {string} [args.type=REQUEST_TYPE.GRAPHQL] - The type of request (GraphQL, GET, POST, PUT, DELETE, GRAPHQL_FETCH_ALL, GRAPHQL_MUTATION). @See REQUEST_TYPE
 * @param {Object} [args.query={}] - The GraphQL query operation or fetch request.
 * @param {string} [args.entityType=GraphQLMutationTypes.Update] - The mutation type used for GraphQL mutations (Update/Delete). @see GraphQLMutationTypes
 * @param {string} [args.mutationType=] - The entity type used for GraphQL mutations.
 * @param {string} [args.fields] - The fields to be returned or updated in a GraphQL mutation.
 * @param {string} [args.id] - The entity ID used in GraphQL mutations.
 *
 * @returns {Promise<Object>} - Resolves with `{ data: result }` if successful, otherwise returns `{ error }`.
 *
 * @throws {Error} Returns an error object if the API request fails.
 */
const grinBaseQuery = async args => {
  const {
    data = {},
    variables = {},
    apiName = 'grinServerlessApi',
    path = '/',
    type = REQUEST_TYPE.GRAPHQL,
    query = {},
    queryName = '',
    entityType = '',
    mutationType = GraphQLMutationTypes.Update,
    fields,
    id
  } = args

  try {
    let result
    switch (type) {
      case REQUEST_TYPE.GRAPHQL:
        result = await API.graphql(graphqlOperation(query, variables))
        break
      case REQUEST_TYPE.POST:
        result = await API.post(apiName, path, { body: data })
        break
      case REQUEST_TYPE.PUT:
        result = await API.put(apiName, path, { body: data })
        break
      case REQUEST_TYPE.DELETE:
        result = await API.del(apiName, path)
        break
      case REQUEST_TYPE.GRAPHQL_FETCH_ALL:
        result = await fetchAll(query, variables, queryName)
        break
      case REQUEST_TYPE.GRAPHQL_MUTATION:
        result = await mutateEntity({ id, input: variables, mutationType, entityType, fields })
        break
      case REQUEST_TYPE.GET:
      default:
        result = await API.get(apiName, path)
        break
    }

    return result.data ? result : { data: result }
  } catch (error) {
    return { error }
  }
}

export const api = createApi({
  baseQuery: grinBaseQuery,
  tagTypes: Object.values(ModuleTags),
  endpoints: () => ({})
})
