import * as React from 'react'

const Center = ({ isSelected }) => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="24" cy="24" r="24" fill={isSelected ? '#B9C7FF' : '#DEEBFA'} />
    <path
      d="M39 15.7782C39 14.2065 37.787 12.9013 36.2195 12.7863L34.932 12.6918C28.6993 12.2347 22.436 12.95 16.4669 14.8008L13.7231 15.6515C11.2118 16.4302 9.5 18.7531 9.5 21.3824V26.3832C9.5 29.0124 11.2118 31.3354 13.7231 32.114L16.4669 32.9647C22.436 34.8155 28.6993 35.5308 34.932 35.0737L36.2195 34.9792C37.787 34.8643 39 33.559 39 31.9873V15.7782Z"
      fill={isSelected ? '#4151E6' : '#8FB1DA'}
    />
    <path
      d="M16.5 22.0335C16.5 21.3725 16.2374 20.7387 15.7701 20.2713C15.0116 19.5128 13.8529 19.3248 12.8934 19.8045L12.129 20.1867C11.1306 20.6859 10.5 21.7063 10.5 22.8225C10.5 23.4735 11.0277 24.0012 11.6787 24.0012H15.0262C15.8402 24.0012 16.5 23.3414 16.5 22.5274V22.0335Z"
      fill="white"
      stroke={isSelected ? '#4151E6' : '#8FB1DA'}
    />
    <path
      d="M16.5 25.969C16.5 26.6299 16.2374 27.2638 15.7701 27.7311C15.0116 28.4896 13.8529 28.6777 12.8934 28.1979L12.129 27.8157C11.1306 27.3165 10.5 26.2962 10.5 25.18C10.5 24.529 11.0277 24.0012 11.6787 24.0012H15.0262C15.8402 24.0012 16.5 24.6611 16.5 25.475V25.969Z"
      fill="white"
      stroke={isSelected ? '#4151E6' : '#8FB1DA'}
    />
    <path
      d="M23.5 22.2535C23.5 20.9085 22.6393 19.7143 21.3633 19.289C20.7995 19.101 20.1938 19.0778 19.6172 19.2219L19.066 19.3597C17.5579 19.7367 16.5 21.0917 16.5 22.6462C16.5 23.3945 17.1067 24.0012 17.855 24.0012H22.0724C22.8608 24.0012 23.5 23.3621 23.5 22.5736V22.2535Z"
      fill="white"
      stroke={isSelected ? '#4151E6' : '#8FB1DA'}
    />
    <path
      d="M23.5 25.7489C23.5 27.094 22.6393 28.2881 21.3633 28.7135C20.7995 28.9014 20.1938 28.9247 19.6172 28.7805L19.066 28.6427C17.5579 28.2657 16.5 26.9107 16.5 25.3563C16.5 24.6079 17.1067 24.0012 17.855 24.0012H22.0724C22.8608 24.0012 23.5 24.6404 23.5 25.4288V25.7489Z"
      fill="white"
      stroke={isSelected ? '#4151E6' : '#8FB1DA'}
    />
    <path
      d="M27.5 20.3336C27.5 19.6311 27.1314 18.9801 26.529 18.6186C25.8956 18.2386 25.1044 18.2386 24.471 18.6186C23.8686 18.9801 23.5 19.6311 23.5 20.3336V22.7483C23.5 23.4403 24.061 24.0012 24.753 24.0012H26.247C26.939 24.0012 27.5 23.4403 27.5 22.7483V20.3336Z"
      fill="white"
      stroke={isSelected ? '#4151E6' : '#8FB1DA'}
    />
    <path
      d="M27.5 27.6688C27.5 28.3714 27.1314 29.0224 26.529 29.3838C25.8956 29.7638 25.1044 29.7638 24.471 29.3838C23.8686 29.0224 23.5 28.3714 23.5 27.6688V25.2542C23.5 24.5622 24.061 24.0012 24.753 24.0012H26.247C26.939 24.0012 27.5 24.5622 27.5 25.2542V27.6688Z"
      fill="white"
      stroke={isSelected ? '#4151E6' : '#8FB1DA'}
    />
    <path
      d="M31.5 19.9744C31.5 19.4769 31.3146 18.9972 30.9799 18.6291C30.1864 17.7562 28.8136 17.7562 28.0201 18.6291C27.6854 18.9972 27.5 19.4769 27.5 19.9744V23.0148C27.5 23.5596 27.9416 24.0012 28.4864 24.0012H30.5136C31.0584 24.0012 31.5 23.5596 31.5 23.0148V19.9744Z"
      fill="white"
      stroke={isSelected ? '#4151E6' : '#8FB1DA'}
    />
    <path
      d="M31.5 28.028C31.5 28.5255 31.3146 29.0052 30.9799 29.3734C30.1864 30.2462 28.8136 30.2462 28.0201 29.3734C27.6854 29.0052 27.5 28.5255 27.5 28.028V24.9876C27.5 24.4429 27.9416 24.0012 28.4864 24.0012H30.5136C31.0584 24.0012 31.5 24.4429 31.5 24.9876V28.028Z"
      fill="white"
      stroke={isSelected ? '#4151E6' : '#8FB1DA'}
    />
    <path
      d="M35.5 20.369C35.5 19.6058 35.2855 18.8581 34.8811 18.211C34.2432 17.1904 32.7568 17.1904 32.1189 18.211C31.7145 18.8581 31.5 19.6058 31.5 20.369V23.0012C31.5 23.5535 31.9477 24.0012 32.5 24.0012H34.5C35.0523 24.0012 35.5 23.5535 35.5 23.0012V20.369Z"
      fill="white"
      stroke={isSelected ? '#4151E6' : '#8FB1DA'}
    />
    <path
      d="M35.5 27.6335C35.5 28.3966 35.2855 29.1443 34.8811 29.7915C34.2432 30.8121 32.7568 30.8121 32.1189 29.7915C31.7145 29.1443 31.5 28.3966 31.5 27.6335V25.0012C31.5 24.4489 31.9477 24.0012 32.5 24.0012H34.5C35.0523 24.0012 35.5 24.4489 35.5 25.0012V27.6335Z"
      fill="white"
      stroke={isSelected ? '#4151E6' : '#8FB1DA'}
    />
    <path
      d="M38.5 19.6639C38.5 19.3625 38.4092 19.068 38.2393 18.8189C37.6439 17.9456 36.3561 17.9456 35.7607 18.8189C35.5908 19.068 35.5 19.3625 35.5 19.6639V23.0012C35.5 23.5535 35.9477 24.0012 36.5 24.0012H37.5C38.0523 24.0012 38.5 23.5535 38.5 23.0012V19.6639Z"
      fill="white"
      stroke={isSelected ? '#4151E6' : '#8FB1DA'}
      strokeWidth="0.5"
    />
    <path
      d="M38.5 28.3385C38.5 28.64 38.4092 28.9344 38.2393 29.1835C37.6439 30.0568 36.3561 30.0568 35.7607 29.1835C35.5908 28.9344 35.5 28.64 35.5 28.3385V25.0012C35.5 24.4489 35.9477 24.0012 36.5 24.0012H37.5C38.0523 24.0012 38.5 24.4489 38.5 25.0012V28.3385Z"
      fill="white"
      stroke={isSelected ? '#4151E6' : '#8FB1DA'}
      strokeWidth="0.5"
    />
  </svg>
)

export default Center
