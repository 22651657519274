import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import AdministrativeInfo from 'components/common/icons/PracticeGuidelines/AdministrativeInfo'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import FormInput from 'components/common/FormInput'
import PhoneNumberInput from 'components/common/PhoneNumberInput'

const GrinGuruForm = ({ values = {}, setValues = () => {}, errors = {} }) => {
  const { t } = useTranslation()

  const phoneNumber = useMemo(() => `${values.phone?.countryCode || ''}${values.phone?.phoneNumber}`, [values])
  const updateValue = useCallback((key, value) => setValues({ ...values, [key]: value }), [values, setValues])

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <span style={{ lineHeight: 0.8 }}>
              <AdministrativeInfo />
            </span>
          </Grid>
          <Grid item>
            <DazzedParagraph14 strong>
              {t('pages.accountSettings.practiceGuidelines.administrativeInfo.grinGuruDetails')}
            </DazzedParagraph14>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormInput
              title={t('general.firstName')}
              value={values.firstName || ''}
              setValue={value => updateValue('firstName', value)}
              style={{ bright: true, thick: true }}
              errorMessage={errors.firstName}
            />
          </Grid>
          <Grid item xs={6}>
            <FormInput
              title={t('general.lastName')}
              value={values.lastName || ''}
              setValue={value => updateValue('lastName', value)}
              style={{ bright: true, thick: true }}
              errorMessage={errors.lastName}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <FormInput
          title={t('general.email')}
          value={values.email || ''}
          setValue={value => updateValue('email', value)}
          style={{ bright: true, thick: true }}
          errorMessage={errors.email}
        />
      </Grid>
      <Grid item xs={6}>
        <PhoneNumberInput
          style={{ bright: true, thick: true }}
          errorMessage={errors.phone}
          value={phoneNumber}
          errorMessageColor="red"
          setPhone={(phone, countryCode) =>
            updateValue('phone', {
              countryCode,
              phoneNumber: phone
            })
          }
        />
      </Grid>
    </Grid>
  )
}

export default GrinGuruForm
