export default props => (
  <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 3L11 0L22 3V7.21687C22 12.6873 19.2052 17.7785 14.59 20.7155L11 23L7.41 20.7155C2.79483 17.7785 0 12.6873 0 7.21687V3Z"
      fill="#3C52EF"
    />
    <rect x="5.5" y="9.5" width="11" height="7" rx="1.5" fill="white" stroke="white" />
    <path
      d="M8 9V6.75C8 5.23122 9.23122 4 10.75 4V4C12.2688 4 13.5 5.23122 13.5 6.75V9"
      stroke="white"
      strokeWidth="2"
    />
  </svg>
)
