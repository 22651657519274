import * as React from 'react'

export default ({ ...props }) => {
  return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="5.5" width="2" height="13" fill="#001335" />
      <rect x="13" y="5.5" width="2" height="13" transform="rotate(90 13 5.5)" fill="#001335" />
    </svg>
  )
}
