import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { DeleteNoteIcon, EditNoteIcon, PinNoteIcon, UnpinNoteIcon } from 'components/common/icons'
import Tooltip from 'components/common/Tooltip'
import { useTranslation } from 'react-i18next'

const NOTE_ITEM_ACTION_KEYS = {
  Pin: 'pin',
  Delete: 'delete',
  Edit: 'edit'
}

const useStyles = makeStyles(theme => ({
  noteItemActionsRoot: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    zIndex: 1,
    bottom: 0,
    right: 15,
    backgroundColor: 'white',
    cursor: 'pointer',
    padding: '5px 5px 0px 5px',
    borderRadius: 8,
    boxShadow: '0px 4px 4px 0px #00000040'
  },
  action: {
    padding: '0px 8px 0px 8px'
  }
}))

const NoteItemActions = ({
  isEditable,
  isDeletable,
  isPinned,
  message,
  onToggleEditMode,
  onDeleteNoteClicked,
  onTogglePinnedNoteClicked
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const actions = useMemo(
    () =>
      [
        {
          key: NOTE_ITEM_ACTION_KEYS.Pin,
          icon: isPinned ? <UnpinNoteIcon /> : <PinNoteIcon />,
          tooltip: isPinned
            ? t('pages.patients.patientSwitchableView.notes.noteActions.pin.unpinTooltip')
            : t('pages.patients.patientSwitchableView.notes.noteActions.pin.tooltip'),
          onClick: onTogglePinnedNoteClicked,
          condition: () => true
        },
        {
          key: NOTE_ITEM_ACTION_KEYS.Delete,
          icon: <DeleteNoteIcon />,
          tooltip: t('pages.patients.patientSwitchableView.notes.noteActions.delete.tooltip'),
          onClick: onDeleteNoteClicked,
          condition: () => isDeletable
        },
        {
          key: NOTE_ITEM_ACTION_KEYS.Edit,
          icon: <EditNoteIcon />,
          tooltip: t('pages.patients.patientSwitchableView.notes.noteActions.edit.tooltip'),
          onClick: onToggleEditMode,
          condition: () => isEditable
        }
      ].filter(action => action.condition()),
    [isDeletable, isEditable, isPinned, onDeleteNoteClicked, onToggleEditMode, onTogglePinnedNoteClicked, t]
  )

  return (
    <div className={classes.noteItemActionsRoot}>
      {actions.map(action => (
        <Tooltip placement="top" key={`note-action-${action.key}`} arrow value={action.tooltip}>
          <div className={classes.action} onClick={action.onClick}>
            {action.icon}
          </div>
        </Tooltip>
      ))}
    </div>
  )
}

export default NoteItemActions
