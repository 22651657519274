import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'
import PaymentItem from './PaymentItem'
import DazzedParagraph16 from '../../../common/text/DazzedParagraph16'
import Actions from '../../../../actions'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { Table, TableBody } from '@material-ui/core'
import { scrollBar } from 'styles/theme'

export const useStyles = makeStyles({
  doctorCardContent: {
    overflow: 'hidden',
    overflowY: 'auto',
    ...scrollBar
  },
  centeredContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
})

const PaymentHistoryTab = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const accountOwner = useSelector(state =>
    state.supportReducer.doctorsDashboard.selectedDoctor.accountOwnerId
      ? state.supportReducer.doctorsDashboard.selectedDoctor.accountOwner
      : state.supportReducer.doctorsDashboard.selectedDoctor
  )
  const { isLoading, data } = useSelector(state => state.supportReducer.doctorsDashboard.transactions)

  useEffect(() => {
    if (!data) {
      dispatch(Actions.supportFetchDoctorTransactions(accountOwner.username))
    }
  }, [data, dispatch, accountOwner])

  return isLoading ? (
    <div className={classes.centeredContainer}>
      <CircularProgress />
    </div>
  ) : !!data?.length ? (
    <div className={classes.doctorCardContent}>
      <Table>
        <TableBody>
          {data.map(transaction => (
            <PaymentItem
              key={transaction.id}
              type={transaction.type}
              description={transaction.description}
              date={moment(transaction.createdAt).format('MM.DD.YY')}
              creditCardLast4={transaction.creditCardLast4}
              creditCardBrand={transaction.creditCardBrand}
              scopesQuantity={transaction.scopesQuantity}
              price={transaction.amount}
              creatorUserId={transaction.creatorUserId}
            />
          ))}
        </TableBody>
      </Table>
    </div>
  ) : (
    <div className={classes.centeredContainer}>
      <DazzedParagraph16>{t('dialogs.doctorCardEditor.noPaymentsText')}</DazzedParagraph16>
    </div>
  )
}

export default PaymentHistoryTab
