import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  paper: {
    width: '530px',
    borderRadius: '5px 5px 40px 5px;'
  },
  content: {
    fontSize: 15,
    width: '100%',
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: 'var(--bg-color-4)',
    borderRadius: '5px 5px 40px 5px',
    color: 'white',
    boxShadow: '2px 2px 6px #00000094',
    padding: 20
  },
  title: {
    fontSize: 25,
    fontWeight: 500,
    marginBottom: 40
  },
  label: {
    textAlign: 'left',
    flex: 3
  },
  header: {
    textAlign: 'left',
    fontSize: '16px',
    fontWeight: 600,
    marginBottom: '22px'
  },
  inputLabelContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20,
    paddingRight: 20
  },
  inputWrapper: {
    flex: 10,
    display: 'flex',
    alignItems: 'center',
    '& input': {
      width: '100%',
      height: 36,
      outline: 'none',
      border: 'none',
      padding: '0 15px',
      fontSize: 14,
      fontFamily: 'Dazzed, Arial'
    },
    '& input:disabled': {
      color: 'lightgray'
    }
  },
  inputEditButton: {
    position: 'absolute',
    right: 12
  },
  featureFlagsContainer: {
    padding: '0px 12px'
  },
  actionButtonsContainer: {
    margin: '20px 0',
    display: 'flex',
    justifyContent: 'space-around'
  },
  sendBtn: {
    background: 'var(--border-color-1)',
    color: 'var(--text-color-1)',
    width: '40%',
    fontFamily: 'Dazzed, Arial',
    textTransform: 'none',
    borderRadius: '34px',
    '&:disabled': {
      background: '#3c52ef99',
      border: '1px solid black',
      color: 'gray'
    },
    '&:hover': {
      background: 'rgb(121, 135, 237)'
    }
  },
  cancelBtn: {
    background: 'var(--border-color-7)',
    color: 'var(--text-color-3)',
    width: '40%',
    fontFamily: 'Dazzed, Arial',
    textTransform: 'none',
    borderRadius: '34px',
    borderColor: 'var(--border-color-1)',
    '&:disabled': {
      background: '#3c52ef99',
      border: '1px solid black',
      color: 'gray'
    },
    '&:hover': {
      background: '#2f3969'
    }
  },
  cardContent: {
    maxHeight: 'calc(100vh - 360px)',
    overflowY: 'scroll',
    paddingRight: 10,
    '&::-webkit-scrollbar': {
      width: 3,
      background: 'none'
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'var(--text-color-11)'
    }
  },
  infoText: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontWeight: 500
  },
  datePicker: {
    marginTop: 5
  },
  nameError: {
    padding: 0,
    paddingLeft: 8,
    marginTop: -14,
    color: 'var(--text-color-17)'
  },
  tabWithButtonContainer: {
    height: '100%'
  },
  tabWithButtonContent: {
    overflow: 'hidden',
    height: 'calc(100% - 55px)',
    overflowY: 'auto',
    width: '100%'
  },
  divider50: {
    height: 50
  },
  divider20: {
    height: 20
  },
  bioInputContainer: {
    padding: 12
  }
})
