import Papa from 'papaparse'
import { downloadMedia, getObjectForDownload } from './mediaUtils'
import JSZip from 'jszip'
import { v4 } from 'uuid'
import fileExtension from 'file-extension'

export const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  return new Blob(byteArrays, { type: contentType })
}

export const base64ToFile = (base64Url, fileName, type) => {
  try {
    const blob = b64toBlob(base64Url, type)
    return new File([blob], fileName, { type })
  } catch (ex) {
    console.error('An error occured while trying to parse base64 to file', base64Url)
  }
}

export const dataUrlToBase64 = dataUrl => dataUrl?.replace('data:', '').replace(/^.+,/, '')

export const downloadFile = (file, fileName) => {
  let link = document.createElement('a')
  link.download = fileName || file.name
  link.href = URL.createObjectURL(file)
  link.click()
}

export const downloadFileFromUrl = async (url, fileName) => {
  const file = await getObjectForDownload(url)
  if (file) {
    fileName = fileName || new URL(url).pathname.slice(1)
    downloadFile(file, fileName)
  }
}

export const blobToBase64 = blob => {
  const reader = new FileReader()
  reader.readAsDataURL(blob)
  return new Promise(resolve => {
    reader.onloadend = () => {
      resolve(reader.result)
    }
  })
}

export const exportToCsv = ({ data, fileName }) => {
  const csv = Papa.unparse(data)

  const encodedUri = window.URL.createObjectURL(new Blob([csv], { type: 'text/csv' }))
  const link = document.createElement('a')

  link.setAttribute('href', encodedUri)
  link.setAttribute('download', fileName)
  link.click()
}

export const fileTypeToMessageType = fileType => {
  if (fileType.includes('image') || fileType.includes('gif')) {
    return 'image'
  } else if (fileType.includes('video')) {
    return 'video'
  } else if (fileType.includes('pdf')) {
    return 'pdf'
  }
}

export const generateZipFromS3Objects = async s3Objects => {
  const downloadResponse = await Promise.all(s3Objects.map(attachment => downloadMedia(attachment)))
  const zip = new JSZip()
  s3Objects.forEach((attachment, index) =>
    zip.file(`${v4()}.${fileExtension(attachment.key)}`, downloadResponse[index], { base64: true })
  )
  const zipFile = await zip.generateAsync({ type: 'blob' })
  return zipFile
}
