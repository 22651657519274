import * as React from 'react'

export default ({ style }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ fontFamily: 'auto', display: 'inline-flex', ...style }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.8234 13.7831C19.4646 15.1522 17.5813 16 15.5 16C11.3579 16 8 12.6422 8 8.5C8 6.4187 8.84778 4.5354 10.2168 3.17664C6.10034 4.00397 3 7.63989 3 12C3 16.9706 7.02942 21 12 21C16.3601 21 19.996 17.8996 20.8234 13.7831Z"
        fill="#527195"
      />
      <path
        d="M15.5 3.55023L15.9592 5.23414C16.3319 6.60058 17.3994 7.66807 18.7658 8.04073L20.4497 8.49998L18.7658 8.95923C17.3994 9.33189 16.3319 10.3994 15.9592 11.7658L15.5 13.4497L15.0408 11.7658C14.6681 10.3994 13.6006 9.33189 12.2342 8.95923L10.5503 8.49998L12.2342 8.04073C13.6006 7.66807 14.6681 6.60058 15.0408 5.23414L15.5 3.55023Z"
        fill="#527195"
      />
    </svg>
  )
}
