import { Grid, ListItemIcon, makeStyles, MenuItem } from '@material-ui/core'
import SecondaryButton from 'components/common/buttons/SecondaryButton'
import { Circle } from 'components/common/icons'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import React from 'react'
import { useHistory } from 'react-router'
import { trackEvent } from 'utils/analyticsUtils'
import { createIdForTest } from 'utils/testingUtils.js'

const useStyles = makeStyles({
  menuItem: {
    fontSize: '16px',
    lineHeight: '25px',
    padding: '12px 24px',
    color: 'var(--primary-color)',
    minHeight: '45px',
    fontWeight: 500,
    '&:hover': {
      fontWeight: 600
    }
  },
  buttonContainer: {
    padding: '10px',
    textAlign: 'center'
  },
  indicator: {
    marginLeft: 5,
    marginTop: 2
  },
  profileItemIcon: {
    minWidth: 30
  },
  subtitle: {
    fontWeight: 500,
    color: 'var(--text-color-62)'
  }
})

const AppProfileMenuItem = React.forwardRef(
  (
    {
      id = '',
      label,
      to,
      variant = 'default',
      handleClose,
      onClickExtra = () => {},
      disabled = false,
      hasIndicator = false,
      icon = null,
      subtitle = ''
    },
    ref
  ) => {
    const history = useHistory()
    const classes = useStyles()

    if (variant === 'button') {
      return (
        <div className={classes.buttonContainer}>
          <SecondaryButton
            id={id}
            label={label}
            fontSize={16}
            fontWeight={500}
            disabled={disabled}
            width="100%"
            onClick={() => {
              onClickExtra()
              handleClose()
              history.push(to)
            }}
          />
        </div>
      )
    }

    const idForTestsAndAnalytics = createIdForTest({ componentId: 'header-menu', title: label })
    return (
      <MenuItem
        id={idForTestsAndAnalytics}
        classes={{ root: classes.menuItem }}
        onClick={() => {
          trackEvent('App Header - Side menu', { action: idForTestsAndAnalytics })
          onClickExtra()
          handleClose()
          history.push(to)
        }}
      >
        <div>
          <Grid container alignItems="center">
            {icon && <ListItemIcon className={classes.profileItemIcon}>{icon}</ListItemIcon>}
            {label && <Grid item>{label}</Grid>}
          </Grid>
          {subtitle && (
            <Grid container alignItems="center">
              <Grid item className={classes.profileItemIcon}></Grid>
              <Grid item>
                <DazzedParagraph12 className={classes.subtitle}>{subtitle}</DazzedParagraph12>
              </Grid>
            </Grid>
          )}
        </div>
        {hasIndicator && <Circle className={classes.indicator} />}
      </MenuItem>
    )
  }
)

export default AppProfileMenuItem
