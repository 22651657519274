import { MobileTabs } from 'consts/appConsts'
import Actions from '../actions'
import { getPatientsListExpanded } from '../utils/storageUtils'
import { getKistOrder } from 'utils/storageUtils'
import { isMobile } from 'utils/mobileUtils'
import { removeWhere, upsertItem } from 'utils/arrayUtils'

const initialState = {
  isFetchingStaticData: false,
  doctorSearchResults: [],
  doctorSearchIsLoading: false,
  snackbarOpen: false,
  snackbarText: '',
  snackbarType: 'info',
  snackbarDuration: 3000,
  isAwsConfigured: false,
  loadedAt: new Date(),
  isPatientsListExpanded: getPatientsListExpanded(),
  subscribeToNotificationsAttepmts: 0,
  hasNotificationsIndicator: isMobile() && !!getKistOrder(),
  alert: {
    isOpen: false,
    type: '',
    title: '',
    message: '',
    primaryButtonText: ''
  },
  prompt: {
    isOpen: false,
    type: '',
    title: '',
    message: '',
    primaryButton: null,
    secondaryButton: null
  },
  rcLandingPage: {
    isContentVisible: true,
    footerVariant: 'compact'
  },
  mobile: {
    isMobile: false,
    selectedTab: MobileTabs.INBOX
  },
  appconfig: {},
  appFeedbackDialog: {
    isOpen: false,
    isSubmitting: false,
    title: null,
    description: '',
    logo: null,
    inputPlaceholder: '',
    displayNote: true
  },
  about: {
    isLoading: false,
    articles: null,
    metrics: null
  },
  forceRefresh: {
    isOpen: false,
    isDismissable: true,
    variant: 'newUpdate'
  },
  quickActions: {
    isOpen: false
  },
  publishedReleaseNotes: {
    data: {}
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.SIGNOUT_RECEIVED:
      return initialState
    case Actions.FETCH_REJECTED:
      return {
        ...state,
        snackbarOpen: !!(action.payload?.showSnackbar && action.payload?.errorMessage),
        snackbarText: action.payload?.showSnackbar && action.payload?.errorMessage,
        snackbarType: 'error',
        snackbarDuration: 4000
      }
    case Actions.SHOW_SNACKBAR:
      return {
        ...state,
        snackbarOpen: true,
        snackbarType: action.payload.snackbarType,
        snackbarText: action.payload.snackbarText,
        snackbarDuration: action.payload.snackbarTime !== undefined ? action.payload.snackbarTime : 4000
      }
    case Actions.HIDE_SNACKBAR:
      return {
        ...state,
        snackbarOpen: false
      }
    case Actions.RESET_PASSWORD_SUBMIT_RECEIVED:
    case Actions.UPDATE_DOCTOR_DETAILS_RECEIVED:
    case Actions.UPDATE_PRACTICE_DETAILS_RECEIVED:
      return {
        ...state,
        snackbarOpen: action.payload?.showSnackbar !== false,
        snackbarType: 'success',
        snackbarText: action.payload?.snackbarText
      }
    case Actions.REQUEST_SEARCH_DOCTOR:
      return {
        ...state,
        doctorSearchIsLoading: true
      }
    case Actions.DOCTOR_SEARCH_RECEIVED:
      return {
        ...state,
        doctorSearchResults: action.payload,
        doctorSearchIsLoading: false
      }
    case Actions.DOCTOR_SEARCH_FAILED:
      return {
        ...state,
        doctorSearchIsLoading: false
      }
    case Actions.CLEAR_DOCTOR_SEARCH:
      return {
        ...state,
        doctorSearchResults: []
      }
    case Actions.UPDATE_DOCTOR_PLANS_RECEIVED:
      return {
        ...state,
        snackbarOpen: true,
        snackbarType: 'success',
        snackbarText: 'Doctor Plans were succesfully updated'
      }
    case Actions.SUPPORT_REQUEST_UPDATE_DOCTOR_RECEIVED:
      return {
        ...state,
        snackbarOpen: true,
        snackbarType: 'success',
        snackbarText: 'Doctor was updated successfully'
      }
    case Actions.UPDATE_PATIENT_DETAILS_RECEIVED:
      return {
        ...state,
        snackbarOpen: true,
        snackbarType: 'success',
        snackbarText: 'Patient was updated successfully'
      }
    case Actions.IMPORT_ALLOWED_DOCTOR_RECEIVED:
      const added = action.payload.allowedDoctors.length
      const total = action.payload.totalRequests.length
      return {
        ...state,
        snackbarOpen: true,
        snackbarType: added ? 'success' : 'info',
        snackbarText: added
          ? `${added} out of ${total} Doctor Plan${total === 1 ? '' : 's'} ${
              added === 1 ? 'was' : 'were'
            } succesfully imported`
          : 'All doctors already existed'
      }
    case Actions.IMPORT_DOCTOR_PLANS_CORRUPTED_CSV:
      return {
        ...state,
        snackbarOpen: true,
        snackbarType: 'error',
        snackbarText: action.payload
      }
    case Actions.SAVE_RELEASE_NOTES_RECEIVED:
      return {
        ...state,
        snackbarOpen: true,
        snackbarType: 'success',
        snackbarText: 'Release notes were successfully saved',
        snackbarDuration: 4000
      }
    case Actions.SAVE_RELEASE_NOTES_FAILED:
      return {
        ...state,
        snackbarOpen: true,
        snackbarType: 'error',
        snackbarText: 'Failed to save release notes',
        snackbarDuration: 4000
      }
    case Actions.AWS_CONFIGURED:
      return {
        ...state,
        isAwsConfigured: true
      }
    case Actions.FORCE_REFRESH:
      return {
        ...state,
        forceRefresh: {
          ...state.forceRefresh,
          isOpen: true,
          isDismissable: action.payload?.isDismissable,
          variant: action.payload?.variant
        }
      }

    case Actions.DISMISS_FORCE_REFRESH:
      return {
        ...state,
        forceRefresh: {
          ...initialState.forceRefresh
        }
      }
    case Actions.SHOW_ALERT:
      return {
        ...state,
        alert: {
          isOpen: true,
          type: action.payload.type,
          title: action.payload.title || '',
          message: action.payload.message || '',
          primaryButtonText: action.payload.primaryButtonText || '',
          ...action.payload
        }
      }
    case Actions.HIDE_ALERT:
      return {
        ...state,
        alert: {
          ...initialState.alert
        }
      }
    case Actions.SHOW_PROMPT:
      return {
        ...state,
        prompt: {
          isOpen: true,
          type: action.payload.type,
          ...action.payload
        }
      }
    case Actions.HIDE_PROMPT:
      return {
        ...state,
        prompt: {
          ...initialState.prompt
        }
      }
    case Actions.SET_RC_LANDING_PAGE_FOOTER_VARIANT:
      return {
        ...state,
        rcLandingPage: {
          ...state.rcLandingPage,
          footerVariant: action.payload || 'compact'
        }
      }
    case Actions.SET_RC_CONTENT_VISIBLE:
      return {
        ...state,
        rcLandingPage: {
          ...state.rcLandingPage,
          isContentVisible: action.payload
        }
      }
    case Actions.SET_PATIENTS_LIST_EXPANDED:
      return {
        ...state,
        isPatientsListExpanded: action.payload
      }
    case Actions.UPDATE_THEME_COLORS_RECEIVED:
      return {
        ...state,
        snackbarOpen: action.payload?.showSnackbar !== false,
        snackbarType: 'success',
        snackbarText: action.payload?.snackbarText
      }
    case Actions.UPDATE_THEME_COLORS_FAILED:
      return {
        ...state,
        snackbarOpen: action.payload?.showSnackbar !== false,
        snackbarType: 'error',
        snackbarText: action.payload?.snackbarText
      }
    case Actions.MOBILE_SET_SELECTED_TAB: {
      return {
        ...state,
        mobile: {
          ...state.mobile,
          selectedTab: action.payload
        }
      }
    }
    case Actions.SET_IS_MOBILE:
      return {
        ...state,
        mobile: {
          ...state.mobile,
          isMobile: action.payload
        }
      }
    case Actions.REQUEST_SUBSCRIBE_TO_NOTIFICATIONS:
      return {
        ...state,
        subscribeToNotificationsAttepmts: state.subscribeToNotificationsAttepmts + 1
      }
    case Actions.NOTIFICATION_RECEIVED:
    case Actions.PUBSUB_RESET:
      return {
        ...state,
        subscribeToNotificationsAttepmts: initialState.subscribeToNotificationsAttepmts
      }
    case Actions.FETCH_APP_CONFIG_RECEIVED:
      return {
        ...state,
        appconfig: action.payload
      }
    case Actions.SET_HAS_NOTIFICATIONS_INDICATOR:
      return {
        ...state,
        hasNotificationsIndicator: action.payload
      }
    case Actions.TOGGLE_APP_FEEDBACK_DIALOG:
      return {
        ...state,
        appFeedbackDialog: {
          ...state.appFeedbackDialog,
          isOpen: action.payload.value,
          title: action.payload.title,
          description: action.payload.description,
          logo: action.payload.logo,
          inputPlaceholder: action.payload.inputPlaceholder,
          displayNote: action.payload?.displayNote ?? true,
          contextText: action.payload.contextText || ''
        }
      }
    case Actions.SUBMIT_APP_FEEDBACK: {
      return {
        ...state,
        appFeedbackDialog: {
          ...state.appFeedbackDialog,
          isSubmitting: true
        }
      }
    }
    case Actions.SUBMIT_APP_FEEDBACK_RECEIVED:
    case Actions.SUBMIT_APP_FEEDBACK_FAILED: {
      return {
        ...state,
        appFeedbackDialog: {
          ...state.appFeedbackDialog,
          isSubmitting: false,
          isOpen: false
        }
      }
    }
    case Actions.FETCH_ABOUT_DATA:
      return {
        ...state,
        about: {
          ...state.about,
          isLoading: true
        }
      }
    case Actions.FETCH_ABOUT_DATA_RECEIVED:
      return {
        ...state,
        about: {
          ...state.about,
          ...action.payload,
          isLoading: false
        }
      }
    case Actions.FETCH_ABOUT_DATA_FAILED:
      return {
        ...state,
        about: {
          ...state.about,
          articles: [],
          isLoading: false
        }
      }
    case Actions.SUPPORT_SAVE_ARTICLE:
      return {
        ...state,
        about: {
          ...state.about,
          articles: upsertItem({ array: state.about.articles, item: action.payload, mergeOldProperties: true })
        }
      }
    case Actions.SUPPORT_DELETE_ARTICLE:
      return {
        ...state,
        about: {
          ...state.about,
          articles: removeWhere(state.about.articles, article => article.id === action.payload)
        }
      }
    case Actions.FETCH_STATIC_DATA:
      return {
        ...state,
        isFetchingStaticData: true
      }
    case Actions.FETCH_STATIC_DATA_RECEIVED:
      return {
        ...state,
        isFetchingStaticData: false,
        publishedReleaseNotes: {
          data: action.payload.releaseNotes
        }
      }
    case Actions.FETCH_STATIC_DATA_FAILED:
      return {
        ...state,
        isFetchingStaticData: false
      }
    case Actions.TOGGLE_QUICK_ACTIONS_OPEN:
      return {
        ...state,
        quickActions: {
          ...state.quickActions,
          isOpen: action.payload
        }
      }
    default:
      return state
  }
}
