import * as React from 'react'

export default () => (
  <svg width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 0V13.5" stroke="#3C52EF" />
    <path d="M14.5 2H21C22.1046 2 23 2.89543 23 4V9.5C23 10.6046 22.1046 11.5 21 11.5H14.5" stroke="#3C52EF" />
    <path d="M9.5 2H3C1.89543 2 1 2.89543 1 4V9.5C1 10.6046 1.89543 11.5 3 11.5H9.5" stroke="#3C52EF" />
    <path
      d="M15.7069 4.21266C15.8207 3.87123 16.1984 3.69603 16.5326 3.82969L17.6905 4.29285C17.8892 4.37232 18.1108 4.37232 18.3095 4.29285L19.4674 3.82969C19.8016 3.69603 20.1793 3.87123 20.2931 4.21266C20.4296 4.622 20.481 5.05354 20.43 5.48199C20.2794 6.74609 19.8861 9.41665 19.25 9.41665C18.4167 9.41665 19.25 7.33331 18 7.33331C16.75 7.33331 17.5832 9.41665 16.75 9.41665C16.1139 9.41665 15.7206 6.74609 15.57 5.48199C15.519 5.05354 15.5704 4.622 15.7069 4.21266Z"
      fill="#3C52EF"
    />
    <path
      d="M3.70688 4.21266C3.8207 3.87123 4.19845 3.69603 4.53261 3.82969L5.69051 4.29285C5.88918 4.37232 6.11082 4.37232 6.30949 4.29285L7.46739 3.82969C7.80155 3.69603 8.1793 3.87123 8.29312 4.21266C8.42956 4.622 8.48101 5.05354 8.42998 5.48199C8.27943 6.74609 7.88611 9.41665 7.25 9.41665C6.41667 9.41665 7.25 7.33331 6 7.33331C4.75 7.33331 5.58322 9.41665 4.75 9.41665C4.1139 9.41665 3.72057 6.74609 3.57002 5.48199C3.51899 5.05354 3.57044 4.622 3.70688 4.21266Z"
      fill="#3C52EF"
    />
  </svg>
)
