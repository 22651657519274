import React, { useMemo } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { useTranslation } from 'react-i18next'
import useTeethTracking from '../useTeethTracking'
import { PROGRESS_TYPES } from '../../txTrackerConsts'

const progressStyle = {
  borderRadius: 10,
  width: 72,
  backgroundColor: '#E5E9FF',
  height: 4
}

const useStyles = ({ progressPrecantage }) =>
  makeStyles(theme => ({
    progressContainer: {
      position: 'relative',
      ...progressStyle
    },
    progress: {
      ...progressStyle,
      width: ({ progressPrecantage }) => `${progressPrecantage}%`,
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 2,
      backgroundColor: 'var(--text-color-25)'
    },
    graphTitle: {
      color: '#6080AC',
      marginTop: 10,
      marginBottom: 5
    },
    tab: {
      cursor: 'pointer'
    },
    tabsContainer: {
      marginBottom: 10
    }
  }))({ progressPrecantage })

const ProgressInfo = ({ toothName, toothData, actualProgress, plannedProgress, progressType, isTxGoalsEnabled }) => {
  const { t } = useTranslation()
  const { getToothMovementPrecantage, getAsteriskData } = useTeethTracking()

  const { explanation } = useMemo(() => getAsteriskData(toothData), [getAsteriskData, toothData])

  const teethMovementPrecantage = useMemo(
    () => getToothMovementPrecantage({ currentProgress: actualProgress, totalPlannedMovement: plannedProgress }),
    [getToothMovementPrecantage, actualProgress, plannedProgress]
  )

  const classes = useStyles({ progressPrecantage: teethMovementPrecantage })

  return isTxGoalsEnabled ? (
    <Grid container alignItems="center" justifyContent="space-between">
      <Grid item>
        <DazzedParagraph14 strong>
          <span style={{ fontWeight: 600 }}>{`${toothName} - `}</span>
          {t(
            `dialogs.treatmentTracker.teethTracking.table.tooltip.${
              progressType === PROGRESS_TYPES.Rotation ? 'degreesProgressWithGoal' : 'mmProgressWithGoal'
            }`,
            {
              current: Number(actualProgress).toFixed(2),
              total: Number(plannedProgress).toFixed(2)
            }
          )}
          &nbsp;
          {explanation ? ` (${explanation})` : ''}
        </DazzedParagraph14>
      </Grid>
      <Grid item>
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <div className={classes.progressContainer}>
              <div className={classes.progress} />
            </div>
          </Grid>
          <Grid item>
            <DazzedParagraph14 strong>{`${teethMovementPrecantage}%`}</DazzedParagraph14>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <Grid container alignItems="center" justifyContent="space-between">
      <Grid item>
        <DazzedParagraph14 strong>
          <span style={{ fontWeight: 600 }}>{`${toothName} - `}</span>
          {t(
            `dialogs.treatmentTracker.teethTracking.table.tooltip.${
              progressType === PROGRESS_TYPES.Rotation ? 'degreesProgress' : 'mmProgress'
            }`,
            {
              current: Number(actualProgress).toFixed(2)
            }
          )}
          &nbsp;
          {explanation ? ` (${explanation})` : ''}
        </DazzedParagraph14>
      </Grid>
    </Grid>
  )
}

export default ProgressInfo
