import * as React from 'react'

const DisableMFAWarning = () => (
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M36.5359 6C38.0755 3.33334 41.9245 3.33333 43.4641 6L71.1769 54C72.7165 56.6667 70.792 60 67.7128 60H12.2872C9.20799 60 7.28348 56.6667 8.82308 54L36.5359 6Z"
      stroke="#3C52EF"
      strokeWidth="1.5"
    />
    <rect x="38" y="22" width="4" height="20" fill="#3C52EF" />
    <rect x="38" y="45" width="4" height="4" fill="#3C52EF" />
  </svg>
)

export default DisableMFAWarning
