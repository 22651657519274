import { CircularProgress, Grid, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Actions from 'actions'
import { selectPickableTags } from '../../../selectors/profileSelector'
import PrimaryButton from '../buttons/PrimaryButton'
import AddTagIcon from '../icons/AddTag'
import GrinMenu from '../menu/GrinMenu'
import TagsPickerInput from './TagsPickerInput'
import TagsPickerList from './TagsPickerList'
import { isUserOfAnyAdminRole } from 'utils/authUtils'
import { trackEvent } from 'utils/analyticsUtils'
import BaseModal from 'components/common/modals/BaseModal'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'

const useStyles = makeStyles(theme => ({
  menu: {
    width: 'fit-content'
  },
  actionBtnContainer: {
    padding: 8
  },
  actionBtn: {
    padding: 8,
    height: 'auto'
  },
  progressContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'var(--bg-color-28)',
    zIndex: 999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

const TagsPicker = ({ showIcon = true }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const tags = useSelector(selectPickableTags)
  const doctorId = useSelector(state => state.profileReducer.doctor.id)
  const { patient } = useSelector(state => state.patientsReducer)
  const { count: numOfpatientsWithTag, isLoading: isLoadingPatientsWithTag } = useSelector(
    state => state.patientsReducer.patientsByTag
  )

  const [input, setInput] = useState('')
  const [isHiddenTagsDropdownOpen, setIsHiddenTagsDropdownOpen] = useState(false)
  const [tagToDelete, setTagToDelete] = useState(null)

  const normalizedInput = useMemo(() => input?.trim().toLowerCase(), [input])

  const isTagAssigned = useCallback(
    input =>
      patient?.patientTags?.items?.find(
        patientTag => patientTag.tag.value.toLowerCase() === input && !patientTag._deleted
      ),
    [patient]
  )

  const isInputValid = useMemo(
    () => !!input && !!normalizedInput && !isTagAssigned(normalizedInput),
    [isTagAssigned, input, normalizedInput]
  )

  const filteredTags = useMemo(
    () =>
      tags
        .filter(tag => tag.value.toLowerCase().includes(normalizedInput) && tag.type !== 'assignee')
        .sort((tag1, tag2) => tag2.count - tag1.count),
    [tags, normalizedInput]
  )

  const handleAddTag = useCallback(() => {
    if (isInputValid) {
      trackEvent('Tags picker - Custom tag added', {
        tag: input.trim()
      })
      dispatch(Actions.assignPatientTag({ tag: input.trim(), patientId: patient?.id }))
      setInput('')
    }
  }, [isInputValid, patient, input, dispatch])

  const handleSelectTag = useCallback(
    tag => {
      trackEvent('Tags picker - tag selected', {
        tag: tag.value
      })
      dispatch(Actions.assignPatientTag({ tag: tag.value, patientId: patient?.id }))
    },
    [dispatch, patient]
  )

  const onDeleteTag = useCallback(
    tag => {
      trackEvent('Tags picker - delete tag clicked', {
        tagId: tag.id,
        tagValue: tag.value,
        doctorId
      })
      setTagToDelete(tag)
      dispatch(Actions.fetchPatientsWithTag(tag))
    },
    [dispatch, doctorId]
  )

  const handleCloseDeleteTagConfirmation = useCallback(() => {
    trackEvent('Tags picker - tag deletion canceled', {
      tagId: tagToDelete.id,
      tagValue: tagToDelete.value,
      doctorId
    })
    setTagToDelete(null)
  }, [doctorId, tagToDelete])

  const handleDeleteTag = useCallback(() => {
    trackEvent('Tags picker - tag deletion confirmed', {
      tagId: tagToDelete.id,
      tagValue: tagToDelete.value,
      doctorId
    })
    dispatch(Actions.deleteDoctorTag(tagToDelete))
    setTagToDelete(null)
  }, [dispatch, doctorId, tagToDelete])

  const handlePickerOpen = useCallback(() => {
    if (!tags?.length) {
      if (isUserOfAnyAdminRole()) {
        dispatch(Actions.mpFetchCustomTagsByDoctor({ doctorId: patient?.doctor.id }))
      } else {
        dispatch(Actions.fetchDoctorTags())
      }
    }
    setIsHiddenTagsDropdownOpen(true)
  }, [dispatch, patient, tags])

  return (
    <div>
      <GrinMenu
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        className={classes.menu}
        open={isHiddenTagsDropdownOpen}
        onOpen={handlePickerOpen}
        onClose={() => setIsHiddenTagsDropdownOpen(false)}
        triggerComponent={
          showIcon ? (
            <IconButton size="small" id="tagsList-add-tag">
              <AddTagIcon />
            </IconButton>
          ) : null
        }
      >
        <TagsPickerInput value={input} setValue={setInput} onEnterPressed={handleAddTag} />
        <TagsPickerList tags={filteredTags} onTagSelected={handleSelectTag} onDeleteTag={onDeleteTag} />
        <div className={classes.actionBtnContainer}>
          <PrimaryButton
            className={classes.actionBtn}
            width="100%"
            label={t('pages.patients.selectedPatient.tags.addTag')}
            disabled={!isInputValid}
            onClick={handleAddTag}
          />
        </div>
      </GrinMenu>
      <BaseModal
        title={t('pages.patients.selectedPatient.tags.deleteTagTitle')}
        variant="alert"
        open={!!tagToDelete}
        handleClose={handleCloseDeleteTagConfirmation}
        secondaryLabel={t('general.cancel')}
        primaryLabel={t('general.delete')}
        onSecondaryBtnClick={handleCloseDeleteTagConfirmation}
        onPrimaryBtnClick={handleDeleteTag}
        reverseActionsOrder
        largerButtons
        primaryButtonId="deleteTag-confirm"
        secondaryButtonId="deleteTag-cancel"
      >
        <Grid container justifyContent="center" alignItems="center" style={{ padding: 10 }}>
          <Grid item>
            {isLoadingPatientsWithTag ? (
              <CircularProgress />
            ) : (
              <DazzedParagraph16 textAlign="center">
                {numOfpatientsWithTag === 0 ? (
                  t('pages.patients.selectedPatient.tags.deleteTagFromZeroPatients', { tag: tagToDelete?.value })
                ) : (
                  <Trans
                    values={{
                      tag: tagToDelete?.value,
                      count: numOfpatientsWithTag
                    }}
                    i18nKey={`pages.patients.selectedPatient.tags.deleteTagConfirmation${
                      numOfpatientsWithTag === 1 ? 'Single' : 'Multiple'
                    }`}
                  />
                )}
              </DazzedParagraph16>
            )}
          </Grid>
        </Grid>
      </BaseModal>
    </div>
  )
}

export default TagsPicker
