import { CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import actions from 'actions'
import EditDoctorDialog from 'components/Support/SupportDashboard/EditDoctor/EditDoctorDialog'
import { ROUTES } from 'consts'
import _ from 'lodash'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { trackEvent } from 'utils/analyticsUtils'
import DoctorResultItem from './DoctorResultItem'
import PatientResultItem from './PatientResultItem'

const useStyles = makeStyles(theme => ({
  scrollableContent: {
    fontFamily: 'Dazzed',
    maxHeight: 500,
    overflow: 'auto',
    width: '100%',
    padding: '0px 20px'
  },
  title: {
    color: '#636F8B',
    fontSize: 13,
    fontWeight: 600,
    borderBottom: '1px solid #C5CCDD'
  },
  resultsItem: {
    fontSize: 12,
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 3,
    marginBottom: 10,
    cursor: 'pointer',
    transition: 'background 0.3s ease',
    '&:hover': {
      background: '#e4e4e4',
      borderRadius: 6
    }
  },
  copiedSuccessfully: {
    border: '1px solid #dedede',
    padding: 3,
    borderRadius: 5
  },
  practiceName: {
    marginLeft: 5,
    fontWeight: 'bold',
    maxWidth: 200,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  name: {
    fontSize: 14,
    fontWeight: 'bold'
  },
  email: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  avatar: {
    marginRight: 20
  },
  detailsContainer: {
    width: '90%',
    display: 'flex',
    alignItems: 'center'
  },
  actionContainer: {
    width: '10%',
    display: 'flex',
    justifyContent: 'end'
  },
  loadMore: {
    color: '#636F8B',
    fontSize: 12,
    cursor: 'pointer',
    textAlign: 'center'
  }
}))

const SearchResults = ({ searchTerm, searchResultsGroups, isLoading, handleClose, setLoadMore }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [selectedDoctor, setSelectedDoctor] = useState(null)
  const [copiedItemId, setCopiedItemId] = useState(null)

  const handleCopyId = useCallback(({ id, itemType }) => {
    trackEvent('Quick Actions - id copied', { itemType })
    setCopiedItemId(id)
    navigator.clipboard.writeText(id)
    setTimeout(() => {
      setCopiedItemId(null)
    }, 1500)
  }, [])

  const handlePatientSelected = useCallback(
    (event, patient) => {
      trackEvent('Quick Actions - Patient Selected', { isNewTab: event.metaKey || event.ctrlKey })

      const url = `${ROUTES.PATIENTS}/${patient.id}`

      if (event.metaKey || event.ctrlKey) {
        window.open(url, '_blank', 'noopener,noreferrer')
      } else {
        dispatch(
          actions.chatRoomSelected({
            patientId: patient.id,
            previewDetails: {
              name: patient.name,
              photo: patient.profilePicture
            }
          })
        )
        handleClose()
      }
    },
    [handleClose, dispatch]
  )

  const handleDoctorSelected = useCallback(
    doctor => {
      trackEvent('Quick Actions - Doctor Selected')
      setSelectedDoctor(doctor)
    },
    [setSelectedDoctor]
  )

  const patientResults = useMemo(() => searchResultsGroups?.patient, [searchResultsGroups])
  const doctorResults = useMemo(() => searchResultsGroups?.doctor, [searchResultsGroups])

  return isLoading ? (
    <CircularProgress size={18} />
  ) : _.isEmpty(searchResultsGroups) ? (
    <div>{t('dialogs.quickActions.noResults')}</div>
  ) : (
    <>
      <div className={classes.scrollableContent}>
        {patientResults?.length > 0 && <div className={classes.title}>{t('dialogs.quickActions.patients')}</div>}
        {patientResults?.map(patientSM => (
          <PatientResultItem
            searchTerm={searchTerm}
            patientSM={patientSM}
            handlePatientSelected={handlePatientSelected}
            copiedItemId={copiedItemId}
            onCopyId={handleCopyId}
          />
        ))}
        {doctorResults?.length > 0 && <div className={classes.title}>{t('dialogs.quickActions.doctors')}</div>}
        {doctorResults?.map(doctor => (
          <DoctorResultItem
            searchTerm={searchTerm}
            doctorSM={doctor}
            setSelectedDoctor={() => handleDoctorSelected(doctor)}
            copiedItemId={copiedItemId}
            onCopyId={handleCopyId}
          />
        ))}
        {doctorResults?.length === 5 && (
          <div className={classes.loadMore} onClick={() => setLoadMore(true)}>
            {t('dialogs.quickActions.loadMoreDoctors')}
          </div>
        )}
      </div>
      <EditDoctorDialog handleClose={() => setSelectedDoctor(null)} doctorId={selectedDoctor?.id} />
    </>
  )
}

export default SearchResults
