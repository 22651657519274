import React, { useMemo } from 'react'
import InitialStep from './InitialStep'
import { SetupSteps } from '../MFASetupModalConsts'
import ConfirmationStep from './ConfirmationStep'
import CompletedStep from './CompletedStep'
import OtherMethodIsAlreadySetup from './OtherMethodIsAlreadySetup'

const SMSSetup = ({
  step,
  setStep,
  confirmationCode,
  setConfirmationCode,
  phoneNumber,
  setPhoneNumber,
  errorMessage
}) => {
  const componentByStep = useMemo(
    () => ({
      [SetupSteps.OtherMethodIsAlreadySetup]: OtherMethodIsAlreadySetup,
      [SetupSteps.Initial]: InitialStep,
      [SetupSteps.Confirmation]: ConfirmationStep,
      [SetupSteps.Completed]: CompletedStep
    }),
    []
  )

  return React.createElement(componentByStep[step], {
    confirmationCode,
    setConfirmationCode,
    phoneNumber,
    setPhoneNumber,
    errorMessage
  })
}

export default SMSSetup
