import _, { isEmpty } from 'lodash'
import React, { useCallback, useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { CircularProgress, Grid, Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Tooth } from 'components/common/icons'
import BaseModal from 'components/common/modals/BaseModal'
import DazzedHeading18 from 'components/common/text/DazzedHeading18'
import SelectInput from 'components/common/SelectInput'
import ResendInvitePatientModal from '../ResendPatientInvitationModal'
import Actions from 'actions'
import useInvitePatientData from 'components/Profile/InvitePatientModal/useInvitePatientData'
import { getPeriodTypeOptions } from 'utils/statusUtils'
import { isOnBrazilBase, isOnGrinSubscription, isOnGrinSubscriptionV2, isOnTrial } from 'utils/billingUtils'
import PatientInfoFormBody from './PatientInfoFormBody'
import PatientInfoHeader from './PatientInfoHeader'
import TreatmentSection from './TreatmentSection'
import actions from 'actions'
import useHiTools from 'hooks/useHiTools'

const useStyles = makeStyles({
  body: {
    minWidth: 800,
    maxHeight: 820,
    height: '100%',
    overflowY: 'auto'
  },
  dialogContent: {
    paddingLeft: 35,
    paddingRight: 35
  },
  sectionHeader: {
    paddingLeft: 6,
    display: 'inline-block',
    lineHeight: '20px'
  }
})

const InvitePatientModal = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const classes = useStyles()

  const {
    values,
    formErrors,
    setShouldShowErrors,
    isFetchingSeats,
    seats,
    doctorId,
    scanFrequencyDefaults,
    treatmentTypeOptions,
    treatmentStatusOptions,
    scanFrequencyOptions,
    clearFields,
    inviteNewPatient,
    validateInputs
  } = useInvitePatientData()

  const { fetchPracticeGuidelines, hiToolsAvailable } = useHiTools()

  const {
    isModalOpen: isOpen,
    presetDetails,
    isLoading: isSendingInvite
  } = useSelector(state => state.patientsReducer.invite)
  const isResendMode = useSelector(state => state.patientsReducer.invite.isResendMode)
  const grinPlan = useSelector(state => state.practiceReducer.billing.grinPlan)

  const periodTypeOptions = useMemo(() => getPeriodTypeOptions(values?.treatmentStatus), [values])
  const isSubscription = useMemo(() => isOnGrinSubscription(grinPlan) || isOnGrinSubscriptionV2(grinPlan), [grinPlan])
  const isTrial = useMemo(() => isOnTrial(grinPlan), [grinPlan])
  const isBrazilBase = useMemo(() => isOnBrazilBase(grinPlan), [grinPlan])

  const handleClose = useCallback(() => {
    clearFields()
    dispatch(Actions.searchSiblingPatients(''))
    dispatch(Actions.setInvitePatientModalVisibility(false))
  }, [dispatch, clearFields])

  const handleSubmit = useCallback(
    e => {
      e.preventDefault()
      if (!validateInputs()) {
        setShouldShowErrors(true)
        return
      }

      inviteNewPatient({
        shouldOpenPatientBriefModal: hiToolsAvailable
      })

      if (!hiToolsAvailable) {
        handleClose()
      }
    },
    [validateInputs, inviteNewPatient, hiToolsAvailable, setShouldShowErrors, handleClose]
  )

  useEffect(() => {
    if (isOpen && doctorId && _.isEmpty(seats)) dispatch(Actions.fetchDoctorSeats())
  }, [dispatch, doctorId, isOpen, seats])

  useEffect(() => {
    if (!isEmpty(presetDetails)) {
      values.setEmail(presetDetails.email)
      values.setFirstName(presetDetails.firstName)
      values.setLastName(presetDetails.lastName)
      dispatch(actions.setInvitePatientPresetDetails({}))
    }
  }, [dispatch, presetDetails, values])

  useEffect(() => {
    if (isOpen && !isSendingInvite && hiToolsAvailable) {
      handleClose()
    }
    //eslint-disable-next-line
  }, [isSendingInvite])

  useEffect(() => {
    if (isOpen && hiToolsAvailable) {
      fetchPracticeGuidelines()
    }
  }, [hiToolsAvailable, fetchPracticeGuidelines, isOpen])

  const topBarContent = useMemo(
    () =>
      isSubscription ? (
        ''
      ) : isFetchingSeats ? (
        <CircularProgress color="inherit" size={20} />
      ) : isTrial || isBrazilBase ? (
        t('dialogs.invitePatient.topBar', { seatsTaken: seats.max - seats.occupied, totalSeats: seats.max })
      ) : (
        t('dialogs.invitePatient.availableSeatsActivePatient', {
          seatsTaken: seats.max - seats.occupied,
          totalSeats: seats.max
        })
      ),
    [isFetchingSeats, isSubscription, seats?.max, seats?.occupied, t, isTrial, isBrazilBase]
  )

  if (!doctorId) return null

  if (isResendMode) return <ResendInvitePatientModal handleClose={handleClose} doctorId={doctorId} isOpen={isOpen} />

  return (
    <BaseModal
      open={isOpen}
      withCloseIcon={false}
      topBarText={topBarContent}
      title={t('dialogs.invitePatient.title')}
      contentClassName={classes.dialogContent}
      onSecondaryBtnClick={handleClose}
      secondaryLabel={t('general.cancel')}
      onPrimaryBtnClick={handleSubmit}
      primaryLabel={t('dialogs.invitePatient.inviteBtn')}
      handleClose={handleClose}
      className={classes.body}
      smothBorders
      isLoading={isSendingInvite}
    >
      <Grid container spacing={3}>
        <PatientInfoHeader values={values} />
        <PatientInfoFormBody values={values} formErrors={formErrors} />
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Tooth />
          <DazzedHeading18 className={classes.sectionHeader}>
            {t('dialogs.invitePatient.treatmentLabel')}
          </DazzedHeading18>
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            label={t('dialogs.invitePatient.treatmentTypeLabel')}
            options={treatmentTypeOptions}
            value={values.treatmentType}
            placeholder={t('dialogs.invitePatient.treatmentTypePlaceholder')}
            onChange={({ value }) => values.setTreatmentType(value)}
            style={{ bright: true, thick: true }}
            selectStyle={{ fontWeight: 500, paddingLeft: 0 }}
            keepErrorContainerWhenInactive={false}
            errorMessage={formErrors?.treatmentType}
            tooltip={t('dialogs.invitePatient.treatmentTypeTooltip')}
          />
        </Grid>
        <TreatmentSection
          values={values}
          formErrors={formErrors}
          treatmentStatusOptions={treatmentStatusOptions}
          scanFrequencyDefaults={scanFrequencyDefaults}
          scanFrequencyOptions={scanFrequencyOptions}
          periodTypeOptions={periodTypeOptions}
        />
      </Grid>
    </BaseModal>
  )
}

export default InvitePatientModal
