import React, { useCallback, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import GrinMenu from 'components/common/menu/GrinMenu'
import MiniTimelineThemeSelectorTrigger from './MiniTimelineThemeSelectorTrigger'
import { trackEvent } from 'utils/analyticsUtils'
import GrinMenuItem from 'components/common/menu/GrinMenuItem'
import { Grid } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'

const useStyles = makeStyles(theme => ({
  themeMenuItem: {
    paddingLeft: 5
  },
  themeIconContainer: {
    lineHeight: 0.8,
    textAlign: 'center',
    width: 45
  }
}))

const MiniTimelineThemeSelector = ({
  selectedTheme,
  onSelectTheme = theme => {},
  themeOptions = [],
  analyticsPayload = {}
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const selectedThemeOption = useMemo(
    () => themeOptions.find(theme => theme.type === selectedTheme),
    [themeOptions, selectedTheme]
  )

  const handleMenuOpened = useCallback(() => {
    setIsMenuOpen(true)
    trackEvent('Mini Timeline - themes menu opened', {
      ...analyticsPayload,
      themeOptions: themeOptions.map(t => t.type)
    })
  }, [themeOptions, analyticsPayload])

  const handleMenuClosed = useCallback(() => {
    setIsMenuOpen(false)
  }, [])

  const handleThemeOptionClicked = useCallback(
    theme => {
      onSelectTheme(theme.type)
      setIsMenuOpen(false)
      trackEvent('Mini Timeline - theme selected', {
        ...analyticsPayload,
        theme: theme.type
      })
    },
    [onSelectTheme, analyticsPayload]
  )

  return (
    <GrinMenu
      open={isMenuOpen}
      onOpen={handleMenuOpened}
      onClose={handleMenuClosed}
      triggerComponent={
        <MiniTimelineThemeSelectorTrigger isMenuOpen={isMenuOpen} selectedThemeIcon={selectedThemeOption?.icon} />
      }
    >
      {themeOptions.map(theme => (
        <GrinMenuItem
          key={theme.type}
          onClick={() => handleThemeOptionClicked(theme)}
          className={classes.themeMenuItem}
        >
          <Grid container alignItems="center">
            <Grid item className={classes.themeIconContainer}>
              {theme.icon}
            </Grid>
            <Grid item style={{ flex: 1 }}>
              <DazzedParagraph14 bold={theme.type === selectedTheme}>
                {t(`pages.patients.selectedPatient.timelineV2.miniTimeline.theme.${theme.type}`)}
              </DazzedParagraph14>
            </Grid>
          </Grid>
        </GrinMenuItem>
      ))}
    </GrinMenu>
  )
}

export default MiniTimelineThemeSelector
