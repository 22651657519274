import React from 'react'
import { makeStyles } from '@material-ui/styles'
import TeethSelectionGrid from 'components/common/inputs/TeethSelectionGrid/TeethSelectionGrid'
import GrinHoverPopper from 'components/common/menu/GrinHoverPopper'

const useStyles = makeStyles(theme => ({
  teethGridPopupRoot: {
    padding: '16px 0 0 16px'
  }
}))

const TeethGridPopup = ({ anchorEl, toothGridItemComponent, teeth = [], offset = { y: 10, x: 380 } }) => {
  const classes = useStyles()

  return (
    <GrinHoverPopper
      anchorEl={anchorEl}
      followMouse={false}
      offset={offset}
      grinPopperProps={{
        className: classes.teethGridPopupRoot,
        zIndex: 99999
      }}
    >
      <TeethSelectionGrid editable={false} toothGridItemComponent={toothGridItemComponent} selections={teeth} />
    </GrinHoverPopper>
  )
}

export default TeethGridPopup
