import React, { useMemo } from 'react'
import InitialStep from './InitialStep'
import { SetupSteps } from '../MFASetupModalConsts'
import ConfirmationStep from './ConfirmationStep'
import CompletedStep from './CompletedStep'

const AuthAppSetup = ({ step, setStep, confirmationCode, setConfirmationCode, errorMessage }) => {
  const componentByStep = useMemo(
    () => ({
      [SetupSteps.Initial]: InitialStep,
      [SetupSteps.Confirmation]: ConfirmationStep,
      [SetupSteps.Completed]: CompletedStep
    }),
    []
  )

  return React.createElement(componentByStep[step], {
    confirmationCode,
    setConfirmationCode,
    errorMessage
  })
}

export default AuthAppSetup
