import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import BaseModal from 'components/common/modals/BaseModal'
import { trackEvent } from 'utils/analyticsUtils'
import Actions from 'actions'
import { Grid } from '@material-ui/core'
import DazzedHeading20 from 'components/common/text/DazzedHeading20'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { PatientBriefModalMode } from 'consts/hiToolsConsts'
import PatientBriefLoadingState from './PatientBriefLoadingState'
import useHiTools from 'hooks/useHiTools'
import PatientBriefForm from './PatientBriefForm'
import { AsyncStatus, ROUTES } from 'consts'
import usePatientBriefEditor from 'components/Patients/PatientBrief/usePatientBriefEditor'
import { isEmpty } from 'lodash'
import moment from 'moment'
import { TIME_FORMAT_9 } from 'consts/dateTimeConsts'
import PatientBriefEmptyState from './PatientBriefEmptyState'
import { useHistory } from 'react-router'

const useStyles = makeStyles(theme => ({
  root: {
    width: 1100,
    maxWidth: '100%'
  },
  titleContainer: {
    width: '100%'
  },
  formContainer: {
    height: '660px',
    padding: '0 40px'
  }
}))

const EMPTY_PATIENT_BRIEF_VALUES = { customTitle: '', practiceNote: '', categories: {} }

const PatientBriefModal = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const {
    hasAnyPracticeGuidelinesConfigured,
    isLoadingPracticeGuidelines,
    practiceGuidelines,
    fetchPracticeGuidelines
  } = useHiTools()
  const { buildDefaultPatientBrief, createPatientBrief, validatePatientBrief, loadPatientBriefValues } =
    usePatientBriefEditor({ practiceGuidelines })
  const {
    isOpen,
    patientId,
    patientName,
    mode,
    saveStatus,
    a_doctor,
    doctorId,
    treatmentType,
    originalPatientBrief,
    analyticsSource
  } = useSelector(state => state.patientsReducer.patientBriefModal)

  const [values, setValues] = useState(EMPTY_PATIENT_BRIEF_VALUES)
  const [formErrors, setFormErrors] = useState({})
  const [showErrors, setShowErrors] = useState(false)

  const buttonsDisabled = useMemo(
    () => isLoadingPracticeGuidelines || saveStatus === AsyncStatus.Loading,
    [isLoadingPracticeGuidelines, saveStatus]
  )
  const newPatientWithoutGuidelines = useMemo(
    () => mode === PatientBriefModalMode.NewPatient && !hasAnyPracticeGuidelinesConfigured,
    [hasAnyPracticeGuidelinesConfigured, mode]
  )

  const handleClose = useCallback(() => {
    trackEvent('Patient Brief - modal closed', { patientId, mode })
    dispatch(Actions.togglePatientBriefModal({ isOpen: false }))
  }, [dispatch, patientId, mode])

  const handleSkip = useCallback(() => {
    trackEvent('Patient Brief - skip for now clicked', { patientId })
    handleClose()
  }, [handleClose, patientId])

  const handleResetToDefault = useCallback(() => {
    trackEvent('Patient brief - reset to default clicked', { mode, patientId })
    setValues(buildDefaultPatientBrief({ treatmentType }))
    setShowErrors(false)
  }, [buildDefaultPatientBrief, mode, patientId, treatmentType])

  const handleSubmit = useCallback(() => {
    setShowErrors(true)
    const hasErrors = !isEmpty(formErrors.categories)
    trackEvent('Patient brief - submit clicked', {
      mode,
      patientId,
      hasErrors,
      categoryErrors: formErrors.categories,
      selectedCategories: Object.keys(values.categories).filter(category => values.categories[category].isEnabled)
    })

    if (hasErrors) {
      return
    }

    createPatientBrief({
      patientId,
      a_doctor,
      doctorId,
      customTitle: values.customTitle,
      categories: values.categories,
      practiceNotes: values.practiceNotes
    })
  }, [patientId, values, mode, a_doctor, doctorId, createPatientBrief, formErrors])

  const handleNavigateToPracticeGuidelines = useCallback(() => {
    trackEvent('Patient brief - Empty state - Navigate to practice guidelines')
    dispatch(Actions.togglePatientBriefModal({ isOpen: false }))
    history.push(ROUTES.PRACTICE_GUIDELINES)
  }, [dispatch, history])

  useEffect(() => {
    setFormErrors(validatePatientBrief(values))
  }, [values, validatePatientBrief])

  useEffect(() => {
    if (isOpen) {
      trackEvent(`Patient Brief - opened`, { patientId, mode, source: analyticsSource })
      setShowErrors(false)
      fetchPracticeGuidelines()
    }
  }, [isOpen]) //eslint-disable-line

  useEffect(() => {
    if (isOpen && (mode === PatientBriefModalMode.NewPatient || !originalPatientBrief)) {
      setValues(buildDefaultPatientBrief({ treatmentType }))
    }
  }, [isOpen, mode, buildDefaultPatientBrief, treatmentType, originalPatientBrief])

  useEffect(() => {
    if (isOpen && saveStatus === AsyncStatus.Completed) {
      handleClose()
    }
  }, [isOpen, saveStatus, handleClose])

  useEffect(() => {
    if (originalPatientBrief) {
      setValues(
        loadPatientBriefValues({
          patientBrief: originalPatientBrief,
          mode
        })
      )
    }
  }, [originalPatientBrief]) //eslint-disable-line

  const titleComponent = (
    <Grid container alignItems="center" justifyContent="flex-start" style={{ padding: '0 30px' }}>
      <Grid item>
        <DazzedHeading20>{t('dialogs.patientBrief.title')}</DazzedHeading20>
      </Grid>
      <Grid item style={{ marginLeft: 32 }}>
        {mode === PatientBriefModalMode.View ? (
          <DazzedParagraph14>
            {t('dialogs.patientBrief.patientNameLabelWithCreationDate', {
              patientName,
              createdAt: moment(originalPatientBrief.createdAt).format(TIME_FORMAT_9)
            })}
          </DazzedParagraph14>
        ) : (
          <DazzedParagraph14>{t('dialogs.patientBrief.patientNameLabel', { patientName })}</DazzedParagraph14>
        )}
      </Grid>
    </Grid>
  )

  return !isLoadingPracticeGuidelines && newPatientWithoutGuidelines ? (
    <BaseModal
      open={isOpen}
      title={titleComponent}
      className={classes.root}
      titleClassName={classes.titleContainer}
      withCloseIcon
      primaryLabel={t('dialogs.patientBrief.goToPracticeGuidelines')}
      onPrimaryBtnClick={handleNavigateToPracticeGuidelines}
      secondaryLabel={t('dialogs.patientBrief.later')}
      onSecondaryBtnClick={() => handleClose()}
      largerButtons
    >
      <PatientBriefEmptyState onNavigateToPracticeGuidelines={handleNavigateToPracticeGuidelines} />
    </BaseModal>
  ) : (
    <BaseModal
      open={isOpen}
      title={titleComponent}
      className={classes.root}
      titleClassName={classes.titleContainer}
      withCloseIcon={false}
      primaryLabel={mode === PatientBriefModalMode.View ? null : t('general.confirm')}
      onPrimaryBtnClick={handleSubmit}
      isPrimaryDisabled={buttonsDisabled}
      isLoading={saveStatus === AsyncStatus.Loading}
      secondaryLabel={
        mode === PatientBriefModalMode.NewPatient ? t('dialogs.patientBrief.resetToDefault') : t('general.close')
      }
      onSecondaryBtnClick={() => (mode === PatientBriefModalMode.NewPatient ? handleResetToDefault() : handleClose())}
      largerButtons
      rightLinkButtonProps={
        mode === PatientBriefModalMode.NewPatient
          ? {
              onClick: handleSkip,
              label: t('dialogs.patientBrief.skipForNow'),
              fontSize: 14,
              underline: true
            }
          : null
      }
    >
      <Grid container direction="column" className={classes.formContainer} wrap="nowrap">
        <Grid item style={{ flex: 1 }}>
          {isLoadingPracticeGuidelines ? (
            <PatientBriefLoadingState />
          ) : (
            <PatientBriefForm
              values={values}
              setValues={setValues}
              errors={showErrors ? formErrors : {}}
              viewMode={mode === PatientBriefModalMode.View}
            />
          )}
        </Grid>
      </Grid>
    </BaseModal>
  )
}

export default PatientBriefModal
