import { Grid, makeStyles } from '@material-ui/core'
import Tooltip from 'components/common/Tooltip'
import {
  Fast,
  MissingTooth,
  Moderate,
  NotTracking,
  PartiallyTracking,
  Slow,
  Tracking,
  TrackingNoMovement,
  Unknown
} from 'components/common/icons'
import LowCertainty from 'components/common/icons/treatmentTracker/teethTrackingLegend/LowCertainty'
import NotSignificant from 'components/common/icons/treatmentTracker/teethTrackingLegend/NotSignificant'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { TreatmentTypes } from 'consts/treatmentConsts'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { MONTH_SPEED_LIMITS } from '../ArchesViewer/archesViewerConsts'
import MissingSpeedInfoLarge from 'components/common/icons/treatmentTracker/teethTrackingLegend/MissingSpeedInfoLarge'

const useStyles = makeStyles(theme => ({
  legendItem: { marginRight: 20 },
  label: {
    userSelect: 'none'
  }
}))

const TeethTrackingLegend = ({ treatmentType }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const alignersLegendItems = useMemo(
    () =>
      [
        {
          icon: <Tracking />,
          label: t('dialogs.treatmentTracker.teethTracking.legend.tracking'),
          explanation: t('dialogs.treatmentTracker.teethTracking.legend.explanation.tracking')
        },
        {
          icon: <TrackingNoMovement />,
          label: t('dialogs.treatmentTracker.teethTracking.legend.trackingNoMovement'),
          explanation: t('dialogs.treatmentTracker.teethTracking.legend.explanation.trackingNoMovement'),
          hidden: true
        },
        {
          icon: <PartiallyTracking />,
          label: t('dialogs.treatmentTracker.teethTracking.legend.partiallyTracking'),
          explanation: t('dialogs.treatmentTracker.teethTracking.legend.explanation.partiallyTracking')
        },
        {
          icon: <NotTracking />,
          label: t('dialogs.treatmentTracker.teethTracking.legend.notTracking'),
          explanation: t('dialogs.treatmentTracker.teethTracking.legend.explanation.notTracking')
        },
        {
          icon: <Unknown />,
          label: t('dialogs.treatmentTracker.teethTracking.legend.unknown'),
          explanation: t('dialogs.treatmentTracker.teethTracking.legend.explanation.unknown')
        },
        {
          icon: <LowCertainty />,
          label: t('dialogs.treatmentTracker.teethTracking.legend.lowCertainty'),
          explanation: t('dialogs.treatmentTracker.teethTracking.legend.explanation.lowCertainty')
        },
        {
          icon: <MissingTooth />,
          label: t('dialogs.treatmentTracker.teethTracking.legend.missingTooth')
        },
        {
          icon: <MissingSpeedInfoLarge />,
          label: t('dialogs.treatmentTracker.teethTracking.legend.missingSpeedInfo'),
          explanation: t('dialogs.treatmentTracker.teethTracking.legend.explanation.missingSpeedInfo')
        }
      ].filter(item => !item.hidden),
    [t]
  )

  const bracesLegendItems = useMemo(
    () => [
      {
        icon: <Fast />,
        label: t('dialogs.treatmentTracker.teethTracking.legend.fast'),
        explanation: t('dialogs.treatmentTracker.teethTracking.legend.explanation.fast', {
          fastValue: MONTH_SPEED_LIMITS.FAST
        })
      },
      {
        icon: <Moderate />,
        label: t('dialogs.treatmentTracker.teethTracking.legend.moderate'),
        explanation: t('dialogs.treatmentTracker.teethTracking.legend.explanation.moderate', {
          fastValue: MONTH_SPEED_LIMITS.FAST,
          minorValue: MONTH_SPEED_LIMITS.MINOR
        })
      },
      {
        icon: <Slow />,
        label: t('dialogs.treatmentTracker.teethTracking.legend.slow'),
        explanation: t('dialogs.treatmentTracker.teethTracking.legend.explanation.slow', {
          tinyValue: MONTH_SPEED_LIMITS.TINY,
          minorValue: MONTH_SPEED_LIMITS.MINOR
        })
      },
      {
        icon: <NotSignificant />,
        label: t('dialogs.treatmentTracker.teethTracking.legend.noSignificantMovement'),
        explanation: t('dialogs.treatmentTracker.teethTracking.legend.explanation.noSignificantMovement', {
          tinyValue: MONTH_SPEED_LIMITS.TINY
        })
      },
      {
        icon: <MissingSpeedInfoLarge />,
        label: t('dialogs.treatmentTracker.teethTracking.legend.missingSpeedInfo'),
        explanation: t('dialogs.treatmentTracker.teethTracking.legend.explanation.missingSpeedInfo')
      },
      {
        icon: <Unknown />,
        label: t('dialogs.treatmentTracker.teethTracking.legend.unknown'),
        explanation: t('dialogs.treatmentTracker.teethTracking.legend.explanation.unknown')
      },
      {
        icon: <LowCertainty />,
        label: t('dialogs.treatmentTracker.teethTracking.legend.lowCertainty'),
        explanation: t('dialogs.treatmentTracker.teethTracking.legend.explanation.lowCertainty')
      },
      {
        icon: <MissingTooth />,
        label: t('dialogs.treatmentTracker.teethTracking.legend.missingTooth')
      }
    ],
    [t]
  )

  const legendItems = useMemo(
    () => (treatmentType === TreatmentTypes.Aligners ? alignersLegendItems : bracesLegendItems),
    [alignersLegendItems, bracesLegendItems, treatmentType]
  )

  return (
    <Grid container alignItems="center" justifyContent="center" style={{ height: '100%' }}>
      {legendItems.map(item => (
        <Tooltip
          key={item.label}
          disableTooltip={!item?.explanation}
          variant="light"
          arrow
          placement="top"
          value={<DazzedParagraph14>{item.explanation}</DazzedParagraph14>}
        >
          <Grid item key={item.label} className={classes.legendItem}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>{item.icon}</Grid>
              <Grid item>
                <DazzedParagraph12 className={classes.label}>{item.label}</DazzedParagraph12>
              </Grid>
            </Grid>
          </Grid>
        </Tooltip>
      ))}
    </Grid>
  )
}

export default TeethTrackingLegend
