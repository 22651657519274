import React, { useState, useCallback, useRef, useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import GrinLabel from '../../../common/text/GrinLabel'
import { Pencil } from '../../../common/icons'
import { windInputCursor } from 'utils/inputUtils'
import DazzedHeading18 from 'components/common/text/DazzedHeading18'

const useStyles = ({ editMode, editable, minInputWith }) =>
  makeStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      width: 'fit-content',
      maxWidth: '100%',
      wordBreak: 'break-word',
      alignItems: 'center',
      position: 'relative',
      paddingRight: 15,
      lineHeight: '20px',
      fontFamily: 'Dazzed',
      fontSize: 16,
      fontWeight: 'normal',
      letterSpacing: 'normal',
      '&:hover > svg': {
        opacity: editMode ? 0 : 1
      }
    },
    title: {
      lineHeight: '21px',
      marginRight: 10,
      maxWidth: '100%',
      minWidth: editMode ? minInputWith : 0,
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    },
    label: {
      marginRight: 5,
      marginBottom: 0,
      height: 22,
      display: 'flex',
      alignItems: 'center'
    },
    value: {
      opacity: 1,
      textTransform: 'none',
      marginRight: 5,
      marginBottom: 10,
      outline: 'none',
      borderBottom: `1px dashed ${editMode ? 'var(--border-color-9)' : 'transparent'}`,
      maxWidth: '100%',
      minWidth: editMode ? minInputWith : 0,
      textOverflow: editMode ? 'clip' : 'ellipsis',
      overflow: 'hidden',
      overflowX: editMode ? 'auto' : 'hidden',
      '&::-webkit-scrollbar': {
        display: 'none'
      }
    },
    pencil: {
      marginTop: 2,
      minWidth: 12,
      cursor: 'pointer',
      opacity: 1
    },
    errorMessage: {
      color: 'var(--text-color-17)',
      fontWeight: 500,
      fontSize: '12px'
    }
  })({ editMode, editable, minInputWith })

const EditableLabel = ({
  firstName = '',
  lastName = '',
  firstNameTitle,
  lastNameTitle,
  editable = true,
  className,
  contentClassName = '',
  handleSave,
  placeholder,
  errorMessage = '',
  minInputWith = 20,
  onSetEditMode = () => {}
}) => {
  const firstInputRef = useRef(null)
  const lastInputRef = useRef(null)

  const [editMode, setEditMode] = useState(false)
  const [error, setError] = useState(false)
  const [firstNameValue, setFirstNameValue] = useState(firstName)
  const [lastNameValue, setLastNameValue] = useState(lastName)

  const classes = useStyles({ editMode, editable, minInputWith })

  const handleSetEditMode = useCallback(
    value => {
      setEditMode(value)
      onSetEditMode(value)
    },
    [onSetEditMode]
  )

  const handleClickEdit = useCallback(() => {
    handleSetEditMode(true)
  }, [handleSetEditMode])

  const handleBlurAndSave = useCallback(() => {
    const firstNameUpdatedValue = firstInputRef.current.textContent.trim()
    const lastNameUpdatedValue = lastInputRef.current.textContent.trim()

    if (!firstNameUpdatedValue.length) {
      firstInputRef.current.textContent = firstName
      setError(false)
      handleSetEditMode(false)
      return
    }

    if (!lastNameUpdatedValue.length) {
      lastInputRef.current.textContent = lastName
      setError(false)
      handleSetEditMode(false)
      return
    }

    if (firstName === firstNameUpdatedValue && lastName === lastNameUpdatedValue) {
      setError(false)
      handleSetEditMode(false)
      return
    }

    setFirstNameValue(firstNameUpdatedValue)
    setLastNameValue(lastNameUpdatedValue)
    handleSave({
      firstName: firstNameUpdatedValue,
      lastName: lastNameUpdatedValue,
      name: `${firstNameUpdatedValue} ${lastNameUpdatedValue}`
    })

    setError(false)
    handleSetEditMode(false)
  }, [firstName, lastName, handleSave, handleSetEditMode])

  const onKeyPress = useCallback(
    e => {
      if (e.key === 'Enter') {
        handleBlurAndSave()
      }
    },
    [handleBlurAndSave]
  )

  useEffect(() => {
    const handleClickOutside = event => {
      if (editMode && !firstInputRef?.current.contains(event.target) && !lastInputRef?.current.contains(event.target)) {
        handleBlurAndSave()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [editMode, firstInputRef, lastInputRef, handleBlurAndSave])

  return (
    <>
      <div className={classNames(classes.container, className)}>
        {!editMode && (
          <>
            <DazzedHeading18 className={classes.title}>{`${firstNameValue} ${lastNameValue}`}</DazzedHeading18>
            {editable && <Pencil className={classes.pencil} onClick={handleClickEdit} />}
          </>
        )}

        {editMode && (
          <>
            <div className={className}>
              {editMode && firstNameTitle && <GrinLabel className={classes.label}>{firstNameTitle}</GrinLabel>}
              <div
                ref={firstInputRef}
                className={classNames(classes.value, contentClassName)}
                contentEditable={editMode}
                suppressContentEditableWarning={editMode}
                onFocus={windInputCursor}
                onKeyPress={onKeyPress}
              >
                {firstName}
              </div>
            </div>
            {!firstName && !editMode && <div className={classes.value}>{placeholder}</div>}

            <div className={className}>
              {editMode && lastNameTitle && <GrinLabel className={classes.label}>{lastNameTitle}</GrinLabel>}
              <div
                ref={lastInputRef}
                className={classNames(classes.value, contentClassName)}
                contentEditable={editMode}
                suppressContentEditableWarning={editMode}
                onFocus={windInputCursor}
                onKeyPress={onKeyPress}
              >
                {lastName}
              </div>
            </div>
            {!lastName && !editMode && <div className={classes.value}>{placeholder}</div>}
          </>
        )}
      </div>

      {error && <div className={classes.errorMessage}>{errorMessage}</div>}
    </>
  )
}

export default EditableLabel
