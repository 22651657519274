import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import BaseModal from 'components/common/modals/BaseModal'
import { Grid } from '@material-ui/core'
import FormInput from 'components/common/FormInput'
import { isEmpty } from 'lodash'
import { isValidEmail } from 'utils/generalUtils'
import { useDispatch, useSelector } from 'react-redux'
import Actions from 'actions'
import { trackEvent } from 'utils/analyticsUtils'
import { AsyncStatus } from 'consts'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'

const useStyles = makeStyles(theme => ({
  inviteDoctorModal: {
    width: '540px',
    maxWidth: '540px'
  }
}))

const DSOInviteDoctorModal = ({ isOpen, onClose }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { loadStatus, error } = useSelector(state => state.multiPracticeReducer.dsoPractices.inviteDoctor)

  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [errors, setErrors] = useState({})
  const [didSendInvite, setDidSendInvite] = useState(false)

  const isValid = useMemo(() => isEmpty(errors), [errors])

  const validateForm = useCallback(() => {
    let updatedErrors = {}

    if (!email) {
      updatedErrors.email = t('errors.requiredField')
    } else if (!isValidEmail(email)) {
      updatedErrors.email = t('errors.invalidEmail')
    }

    if (!name.trim()) {
      updatedErrors.name = t('errors.requiredField')
    }

    setErrors(updatedErrors)
    return isEmpty(updatedErrors)
  }, [email, name, t])

  const handleSave = useCallback(() => {
    if (!validateForm()) {
      return
    }

    trackEvent(`DSO invite doctor - save clicked`)
    setDidSendInvite(true)
    dispatch(
      Actions.dsoInviteDoctor({
        name,
        email
      })
    )
  }, [validateForm, dispatch, name, email])

  useEffect(() => {
    if (!isValid) {
      validateForm()
    }
  }, [isValid, validateForm])

  useEffect(() => {
    if (!isOpen) {
      setDidSendInvite(false)
    }
  }, [isOpen])

  useEffect(() => {
    if (isOpen && didSendInvite && loadStatus === AsyncStatus.Completed) {
      setName('')
      setEmail('')
      onClose()
    }
  }, [isOpen, loadStatus, didSendInvite, onClose])

  return (
    <BaseModal
      open={isOpen}
      handleClose={onClose}
      withCloseIcon
      onSecondaryBtnClick={onClose}
      secondaryLabel={t('general.cancel')}
      primaryLabel={t('general.save')}
      onPrimaryBtnClick={handleSave}
      isPrimaryDisabled={!isValid}
      isLoading={loadStatus === AsyncStatus.Loading}
      title={t('pages.accountSettings.dsoMembers.inviteDoctor.modal.title')}
      className={classes.inviteDoctorModal}
    >
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <FormInput
            style={{ thick: true, bright: true }}
            value={email}
            setValue={setEmail}
            title={t('general.email')}
            errorMessage={errors.email}
          />
        </Grid>
        <Grid item>
          <FormInput
            style={{ thick: true, bright: true }}
            value={name}
            setValue={setName}
            title={t('general.name')}
            errorMessage={errors.name}
          />
        </Grid>
        {error && (
          <Grid item>
            <DazzedParagraph14 strong color="var(--text-color-17)">
              {error.code === 'userAlreadyExists'
                ? t('pages.accountSettings.dsoMembers.inviteDoctor.modal.userAlreadyExists')
                : t('messages.somethingWentWrongContactSupport')}
            </DazzedParagraph14>
          </Grid>
        )}
      </Grid>
    </BaseModal>
  )
}

export default DSOInviteDoctorModal
