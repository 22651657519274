import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { CompareSTLs, LayerDisplayed, LayerHidden } from 'components/common/icons'
import { Grid } from '@material-ui/core'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import { useTranslation } from 'react-i18next'
import OriginSTLPicker from './OriginSTLPicker'
import AlignersCurrentStlTooth from '../../../../resources/AlignersCurrentStlTooth.png'
import BracesCurrentStlTooth from '../../../../resources/BracesCurrentStlTooth.png'
import { TreatmentTypes } from 'consts/treatmentConsts'
import LinkButton from 'components/common/buttons/LinkButton'

const useStyles = makeStyles(theme => ({
  layerToggle: {
    cursor: 'pointer'
  },
  label: {
    marginBottom: 5,
    userSelect: 'none'
  },
  currentStlToothImage: {
    height: 21,
    width: 24
  },
  compareStlsLabel: {
    marginBottom: 5
  }
}))

const ViewerControls = ({
  viewerSettings,
  setViewerSettings,
  selectedOriginModelType,
  setSelectedOriginModelType,
  originModelOptions,
  scanNumber,
  alignerNumber,
  viewerScene,
  treatmentType,
  isCompareStlsModeOn,
  setIsCompareStlsModeOn
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const controls = useMemo(
    () => [
      {
        isVisible: viewerSettings.isCurrentLayerVisible,
        setIsVisible: value =>
          setViewerSettings({
            ...viewerSettings,
            isCurrentLayerVisible: value
          }),
        icon: (
          <img
            className={classes.currentStlToothImage}
            src={treatmentType === TreatmentTypes.Aligners ? AlignersCurrentStlTooth : BracesCurrentStlTooth}
            alt=""
          />
        ),
        component: (
          <DazzedParagraph12 strong className={classes.label}>
            {t('dialogs.treatmentTracker.viewerControls.currentScan', {
              scanNumber,
              alignerNumber: `${
                alignerNumber ? t('dialogs.treatmentTracker.viewerControls.alignerNumber', { alignerNumber }) : ''
              }`
            })}
          </DazzedParagraph12>
        )
      },
      {
        isVisible: viewerSettings.isBaseLayerVisible,
        setIsVisible: value =>
          setViewerSettings({
            ...viewerSettings,
            isBaseLayerVisible: value
          }),
        component: (
          <OriginSTLPicker
            selectedOriginModelType={selectedOriginModelType}
            setSelectedOriginModelType={setSelectedOriginModelType}
            originModelOptions={originModelOptions}
            viewerScene={viewerScene}
          />
        )
      }
    ],
    [
      alignerNumber,
      classes,
      originModelOptions,
      viewerScene,
      scanNumber,
      selectedOriginModelType,
      setSelectedOriginModelType,
      setViewerSettings,
      t,
      treatmentType,
      viewerSettings
    ]
  )

  return (
    <Grid container alignItems="center" spacing={2}>
      {controls.map(({ isVisible, icon, setIsVisible, component }, index) => (
        <Grid item key={`viewer-control-${index}`}>
          <Grid container alignItems="center" spacing={1}>
            <Grid item className={classes.layerToggle} onClick={() => setIsVisible(!isVisible)}>
              {isVisible ? <LayerDisplayed /> : <LayerHidden />}
            </Grid>
            {icon && <Grid item>{icon}</Grid>}
            {component && <Grid item>{component}</Grid>}
          </Grid>
        </Grid>
      ))}
      {!isCompareStlsModeOn && (
        <Grid item>
          <LinkButton
            icon={<CompareSTLs />}
            label={t('dialogs.treatmentTracker.viewerControls.compareSTLs')}
            labelClassName={classes.compareStlsLabel}
            onClick={() => setIsCompareStlsModeOn(!isCompareStlsModeOn)}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default ViewerControls
