import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { Sketch } from '../icons'
import PrimaryButton from '../buttons/PrimaryButton'

const useStyles = makeStyles(theme => ({
  markImageButtonRoot: {
    border: '1px solid var(--border-color-4)'
  }
}))

const MarkImageButton = ({ className = '', onClick = () => {} }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <PrimaryButton
      width="160px"
      color="var(--bg-color-39)"
      icon={<Sketch />}
      fontSize={12}
      label={t('dialogs.scanSummary.annotations.mark')}
      onClick={onClick}
      className={[classes.markImageButtonRoot, className].join(' ')}
    />
  )
}

export default MarkImageButton
