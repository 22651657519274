import React, { useCallback, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import GrinMenu from 'components/common/menu/GrinMenu'
import GrinMenuItem from 'components/common/menu/GrinMenuItem'
import { ChevronDownSmall } from 'components/common/icons'
import { useSelector } from 'react-redux'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { IconButton } from '@material-ui/core'
import { trackEvent } from 'utils/analyticsUtils'
import EditMessageDialog from './EditMessageDialog'
import DeleteMessageConfirmationModal from './DeleteMessageConfirmationModal'
import MessageHistoryModal from './MessageHistoryModal'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'

const EditableMessageTypes = ['text']
const UnDeletableMessageTypes = ['newScanReview', 'scanReview']

const useStyles = makeStyles(theme => ({
  messageActionsMenuRoot: {
    width: ({ requirePatientNewerAppVersion }) => (requirePatientNewerAppVersion ? 200 : 150)
  },
  menuTriggerContainer: {
    background: '#EAEAED',
    borderRadius: 16
  },
  patientAppUpdateRequired: {
    backgroundColor: 'rgba(251, 223, 219, 1)!important',
    whiteSpace: 'normal',
    '&:hover': {
      backgroundColor: 'rgba(251, 223, 219, 1)'
    }
  }
}))

const MessageActionsMenu = ({
  messageId,
  isVisible,
  requirePatientNewerAppVersion = false,
  menuTriggerClassName = ''
}) => {
  const classes = useStyles({ requirePatientNewerAppVersion })
  const { t } = useTranslation()

  const messages = useSelector(state => state.chatReducer.messages.items)
  const { id: grinUserId } = useSelector(state => state.profileReducer.doctor?.user)

  const [isOpen, setIsOpen] = useState(false)
  const [isEditMessageDialogOpen, setIsEditMessageDialogOpen] = useState(false)
  const [isDeleteMessageModalOpen, setIsDeleteMessageModalOpen] = useState(false)
  const [isMessageHistoryModalOpen, setIsMessageHistoryModalOpen] = useState(false)

  const message = useMemo(() => messages?.find(msg => msg.id === messageId), [messages, messageId])
  const actions = useMemo(
    () =>
      [
        {
          id: 'editMessage',
          title: t('pages.patients.selectedPatient.chat.messageActions.editMessage'),
          condition:
            !message?.isDeleted && EditableMessageTypes.includes(message?.type) && grinUserId === message?.grinUserId,
          onClick: () => setIsEditMessageDialogOpen(true)
        },
        {
          id: 'deleteMessage',
          title: t('pages.patients.selectedPatient.chat.messageActions.deleteMessage'),
          condition:
            !message?.isDeleted &&
            !UnDeletableMessageTypes.includes(message?.type) &&
            grinUserId === message?.grinUserId,
          onClick: () => setIsDeleteMessageModalOpen(true)
        },
        {
          id: 'viewChanges',
          title: t('pages.patients.selectedPatient.chat.messageActions.viewChanges'),
          condition: message?.lastEditedAt,
          onClick: () => setIsMessageHistoryModalOpen(true)
        }
      ].filter(action => action.condition),
    [t, grinUserId, message]
  )

  const handleActionClicked = useCallback(
    action => {
      trackEvent('Message actions - action clicked', { action: action.id, messageId })
      action.onClick()
      setIsOpen(false)
    },
    [messageId]
  )

  return !message || message?.isLocal || message?.isBroadcast || !actions.length ? (
    <></>
  ) : (
    <>
      <GrinMenu
        open={isOpen}
        className={classes.messageActionsMenuRoot}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        triggerComponent={
          isVisible && (
            <div className={[menuTriggerClassName, classes.menuTriggerContainer].join(' ')}>
              <IconButton size="small" className={'chat-message-menu'}>
                <ChevronDownSmall fill="rgba(0, 19, 53, 1)" />
              </IconButton>
            </div>
          )
        }
      >
        {requirePatientNewerAppVersion && (
          <GrinMenuItem nonActionable className={classes.patientAppUpdateRequired}>
            <DazzedParagraph12 thickness="strong">
              {t('pages.patients.selectedPatient.chat.messageActions.requirePatientNewerAppVersion')}
            </DazzedParagraph12>
          </GrinMenuItem>
        )}
        {actions.map(action => (
          <GrinMenuItem
            key={action.title}
            onClick={() => handleActionClicked(action)}
            className={action.id}
            nonActionable={requirePatientNewerAppVersion}
          >
            <DazzedParagraph14 strong color={requirePatientNewerAppVersion ? 'var(--text-color-38)' : '#000'}>
              {action.title}
            </DazzedParagraph14>
          </GrinMenuItem>
        ))}
      </GrinMenu>
      <EditMessageDialog
        isOpen={isEditMessageDialogOpen}
        onClose={() => setIsEditMessageDialogOpen(false)}
        content={message.content}
        messageId={messageId}
      />
      <DeleteMessageConfirmationModal
        messageId={messageId}
        isOpen={isDeleteMessageModalOpen}
        onClose={() => setIsDeleteMessageModalOpen(false)}
      />
      <MessageHistoryModal
        isOpen={isMessageHistoryModalOpen}
        onClose={() => setIsMessageHistoryModalOpen(false)}
        messageId={messageId}
      />
    </>
  )
}

export default MessageActionsMenu
