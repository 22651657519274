import React, { useMemo, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Table, TableBody, TableHead, TableRow, TableCell, CircularProgress } from '@material-ui/core'
import Actions from 'actions'
import { useTranslation } from 'react-i18next'
import MembersTableRow from 'components/Profile/PracticeMembers/Table/MembersTableRow'
import EditMemberModal from './EditMemberModal'

export const useStyles = makeStyles({
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop: -30,
    overflowY: 'auto'
  },
  centeredContainer: {
    height: 'calc(100vh - 400px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  header: {
    backgroundColor: 'var(--bg-color-14)',
    color: 'var(--text-color-6)',
    fontSize: '12px'
  },
  headerCell: {
    borderBottom: '0.5px solid var(--border-color-13)'
  }
})

const MembersTab = ({ updatedDoctor: doctor, updateSelectedDoctor }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const accountOwner = useSelector(state =>
    state.supportReducer.doctorsDashboard.selectedDoctor.accountOwnerId
      ? state.supportReducer.doctorsDashboard.selectedDoctor.accountOwner
      : state.supportReducer.doctorsDashboard.selectedDoctor
  )

  const { isLoading, data: practiceMembers } = useSelector(
    state => state.supportReducer.doctorsDashboard.practiceMembers
  )

  const [activeMember, setActiveMember] = useState(null)

  const HEADERS = useMemo(
    () => [
      { key: 'name', label: t('pages.accountSettings.practiceMembers.table.headers.name') },
      { key: 'phone', label: t('pages.accountSettings.practiceMembers.table.headers.phone') },
      { key: 'accessType', label: t('pages.accountSettings.practiceMembers.table.headers.accessType') },
      { key: 'jobTitle', label: t('pages.accountSettings.practiceMembers.table.headers.jobTitle') },
      { key: 'dateAdded', label: t('pages.accountSettings.practiceMembers.table.headers.dateAdded') },
      { key: 'status', label: t('pages.accountSettings.practiceMembers.table.headers.status') },
      { key: 'actions', label: '' }
    ],
    [t]
  )

  const handleEditMember = useCallback(
    memberId => setActiveMember(practiceMembers.find(member => member.id === memberId)),
    [practiceMembers]
  )
  const handleCloseEditMemberModal = useCallback(() => setActiveMember(null), [])

  const handleSubmitEditMemberModal = useCallback(
    values => {
      dispatch(
        Actions.supportUpdatePracticeMember({ ...values, memberId: activeMember.id, accountOwnerId: accountOwner.id })
      )

      handleCloseEditMemberModal()
    },
    [activeMember, accountOwner, dispatch, handleCloseEditMemberModal]
  )

  const handleCancelInvitation = useCallback(
    ({ memberId, name }) => dispatch(Actions.cancelPracticeMemberInvitation({ id: memberId, name })),
    [dispatch]
  )
  const handleResendInvitation = useCallback(
    ({ values, doctorId, memberId }) =>
      dispatch(Actions.resendPracticeMemberInvitation({ ...values, doctorId, id: memberId })),
    [dispatch]
  )
  const handleDeactivateUser = useCallback(memberId => dispatch(Actions.deactivatePracticeMember(memberId)), [dispatch])
  const handleActivateUser = useCallback(
    memberId => dispatch(Actions.activatePracticeMember(memberId)),

    [dispatch]
  )

  if (activeMember) {
    return (
      <EditMemberModal
        activeMember={activeMember}
        handleClose={handleCloseEditMemberModal}
        handleSubmit={handleSubmitEditMemberModal}
      />
    )
  }

  return isLoading ? (
    <div className={classes.centeredContainer}>
      <CircularProgress />
    </div>
  ) : (
    <div className={classes.content}>
      <Table>
        <TableHead className={classes.header}>
          <TableRow>
            {HEADERS.map(({ label, key }) => (
              <TableCell key={key} className={classes.headerCell}>
                {label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {practiceMembers?.map(member => (
            <MembersTableRow
              key={member.id}
              member={member}
              colums={HEADERS}
              handleEditMember={handleEditMember}
              onCancelInvitation={handleCancelInvitation}
              onResendInvitation={handleResendInvitation}
              onDeactivateUser={handleDeactivateUser}
              onActivateUser={handleActivateUser}
            />
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

export default MembersTab
