import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { NightTimeOnlyIcon } from 'components/common/icons'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  nightTimeOnlyContainer: {
    display: 'flex',
    paddingLeft: 15,
    margin: '10px 0px 10px 0px'
  },
  label: {
    color: 'black',
    fontSize: 14,
    fontWeight: 500
  }
}))

const NightTimeOnly = ({ checked }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return checked ? (
    <Grid container alignItems="center">
      <Grid item style={{ marginRight: 3 }}>
        <NightTimeOnlyIcon />
      </Grid>
      <Grid item className={classes.label}>
        {t('dialogs.patientBrief.categories.elastics.nightTimeOnlyLabel')}
      </Grid>
    </Grid>
  ) : (
    <></>
  )
}

export default NightTimeOnly
