import appActions from './appActions'
import authActions from './authActions'
import profileActions from './profileActions'
import patientsActions from './patientsActions'
import chatActions from './chatActions'
import treatmentActions from './treatmentActions'
import supportActions from './supportActions'
import releaseNoteActions from './releaseNotesActions'
import billingActions from './billingActions'
import productActions from './productActions'
import quickSignUpActions from './quickSignUpActions'
import featureFlagsActions from './featureFlagsActions'
import practiceMembersActions from './practiceMembersActions'
import pixelActions from './pixelActions'
import taskManagerActions from './taskManagerActions'
import announcementsActions from './announcementsActions'
import rcDashboardActions from './rcDashboardActions'
import practiceActions from './practiceActions'
import giActions from './giActions'
import hiActions from './hiActions'
import pmsActions from './pmsActions'
import autochartActions from './autochartActions'
import multiPractice from './multiPracticeActions'
import referralsActions from './referralsActions'
import qaActions from './qaActions'
import txTrackerActions from './txTrackerActions'
import timelineActions from './timelineActions'

export default {
  ...appActions,
  ...authActions,
  ...profileActions,
  ...patientsActions,
  ...chatActions,
  ...treatmentActions,
  ...supportActions,
  ...releaseNoteActions,
  ...billingActions,
  ...productActions,
  ...quickSignUpActions,
  ...featureFlagsActions,
  ...practiceMembersActions,
  ...pixelActions,
  ...taskManagerActions,
  ...announcementsActions,
  ...rcDashboardActions,
  ...practiceActions,
  ...giActions,
  ...hiActions,
  ...pmsActions,
  ...autochartActions,
  ...multiPractice,
  ...referralsActions,
  ...qaActions,
  ...txTrackerActions,
  ...timelineActions
}

//List of action that should now appear in Logger or Analytics
export const notTrackedActions = [
  authActions.QUICK_SIGN_UP_FORM_CHANGED,
  authActions.AUTH_FORM_CHANGED,
  authActions.BILLING_INFO_FORM_CHANGED,
  authActions.CHANGE_PROFILE_ITEM,
  appActions.CHECK_LOGIN_CHANGE,
  '@@router/LOCATION_CHANGE'
]
