import React from 'react'
import { ListItem, ListItemAvatar, ListItemText, makeStyles } from '@material-ui/core'
import ProfilePicture from 'components/common/ProfilePicture'

const useStyles = makeStyles(theme => ({
  listItem: {
    overflowX: 'hidden',
    margin: '10px 0px 10px 0px'
  }
}))

const DoctorListItem = ({ index, practiceName, photo, email, onDoctorSelected }) => {
  const classes = useStyles()

  return (
    <ListItem button onClick={onDoctorSelected} key={`doctors-search-item-${index}`} className={classes.listItem}>
      <ListItemAvatar>
        <ProfilePicture photo={photo} name={practiceName} size="small" />
      </ListItemAvatar>
      <ListItemText primary={practiceName} secondary={email} />
    </ListItem>
  )
}

export default DoctorListItem
