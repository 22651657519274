import { getEnvironment } from '../utils/awsUtils'
import moment from 'moment'
import i18n from '../resources/locales/i18n'

export const GRIN_PLANS = [
  'Grin Plan',
  '6 Months Treatment License (3M)',
  'Full Treatment License (Henry Schein)',
  'Introductory Plan (Grin)'
]

const env = getEnvironment()

export const RELEASE_NOTES_BUCKET = env === 'production' ? 'grin-release-notes' : `grin-release-notes-${env}`

export const MEDICAL_RECORDS_BUCKET = `medical-records-${getEnvironment()}`

export const RELEASE_NOTES_CLOUDFRONT_URL =
  getEnvironment() === 'production' ? 'https://d122f0ztkev02h.cloudfront.net' : 'https://d35o2y7tjiyxsp.cloudfront.net'

export const RELEASE_NOTES_PUBLISH_FILE = 'publish.json'

export const ORTHO_PROGRAM = 'ortho'
export const REMOTE_MONITORING_PROGRAM = 'rm'
export const REMOTE_CONSULTATION_PROGRAM = 'rc'
export const SERVICE_ACCOUNT_PROGRAM = 'serviceAccount'

export const PROGRAMS = [
  {
    name: 'Remote Monitoring',
    key: REMOTE_MONITORING_PROGRAM
  },
  {
    name: 'Ortho',
    key: ORTHO_PROGRAM
  },
  {
    name: 'Remote Consultation',
    key: REMOTE_CONSULTATION_PROGRAM
  }
]

export const DOCTOR_PROGRAMS = [
  {
    name: 'Remote Monitoring',
    key: REMOTE_MONITORING_PROGRAM
  },
  {
    name: 'Ortho',
    key: ORTHO_PROGRAM
  }
]

export const VERSION_LOOKUP_INTERVAL = moment.duration(10, 'minutes').asMilliseconds()
export const LOGIN_CHECK_INTERVAL = moment.duration(30, 'seconds').asMilliseconds()

export const ActionItemTypes = {
  NEW_MESSAGE: 'newMessage',
  REVIEW_FIRST_SCAN: 'reviewFirstScan',
  REVIEW_SCAN: 'reviewScan',
  UPLOAD_PATIENT_SCANS: 'uploadPatientScans',
  SCHEDULE_VISIT: 'scheduleVisit',
  TREATMENT_PROPOSAL_REQUIRED: 'treatmentProposalRequired',
  TREATMENT_PLAN_REQUIRED: 'treatmentPlanRequired',
  SET_SCAN_SETTINGS: 'setScanSettings',
  UPDATE_APPLIANCE_ARRIVAL: 'updateApplianceArrival'
}

export const MobileTabs = {
  INBOX: 'inbox',
  CHAT: 'chat',
  TIMELINE: 'timeline',
  NOTES: 'notes'
}

export const MEMBER_TYPES = {
  owner: {
    label: i18n.t('common.practiceMemberTypes.owner'),
    key: 'owner'
  },
  manager: {
    label: i18n.t('common.practiceMemberTypes.manager'),
    key: 'manager'
  },
  member: {
    label: i18n.t('common.practiceMemberTypes.member'),
    key: 'member'
  }
}

export const IMAGE_INPUT_FILE_TYPES = ['image/jpeg', 'image/png', 'image/gif']
export const DROPZONE_ACCEPTABLE_FILE_TYPES = [...IMAGE_INPUT_FILE_TYPES, 'video/mp4', 'video/mov', 'application/pdf']
export const FILE_UPLOAD_MAX_SIZE = 52428800 // 50MB in bytes
export const CHECK_AVAILABILITY_ENDPOINT = 'https://us-central1-grin-a8d30.cloudfunctions.net/checkAppAvailability'
