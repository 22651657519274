import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import BaseModal from 'components/common/modals/BaseModal'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import { InfoSmall, SmallLock } from 'components/common/icons'
import Tooltip from 'components/common/Tooltip'
import FormInput from 'components/common/FormInput'
import TextAreaAutosize from 'components/common/TextareaAutosize'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import GrinRadioGroup from 'components/common/GrinRadioGroup'
import PhoneNumberInput from 'components/common/PhoneNumberInput'
import ScanWithPatientDetails from './ScanWithPatientDetails'
import AttachFiles from './AttachFiles'
import ShareScanAttachFilesModal from './ShareScanAttachFilesModal/ShareScanAttachFilesModal'
import { SHARE_METHODS } from './shareScanConsts'

const useStyles = makeStyles({
  modal: {
    padding: '50px 30px 0px 10px',
    minWidth: 1250
  },
  content: {
    paddingRight: '0px !important'
  },
  videoContainer: {
    marginTop: 30
  }
})

const ShareNewScanModalDesktop = ({
  isOpen,
  isLoading,
  onClose,
  onShareScan,
  grinScanId,
  hasFilledAllFields,
  referralName,
  setReferralName,
  speciality,
  setSpeciality,
  shareMethod,
  setShareMethod,
  shareDestination,
  setShareDestination,
  note,
  setNote,
  files,
  setFiles,
  shareTypeOptions,
  error
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <>
      <BaseModal
        open={isOpen}
        handleClose={onClose}
        className={classes.modal}
        hideScroll
        primaryLabel={t('dialogs.shareScan.shareScanButtonLabel')}
        contentClassName={classes.content}
        onPrimaryBtnClick={onShareScan}
        isPrimaryDisabled={!hasFilledAllFields}
        largerPrimaryButton
        buttonsWidth={130}
        onSecondaryBtnClick={onClose}
        secondaryLabel={t('general.cancel')}
        isLoading={isLoading}
      >
        {isOpen && (
          <Grid container spacing={1}>
            <Grid item>
              <Grid container spacing={3}>
                <Grid item xs={5}>
                  <Grid container direction="column" spacing={1}>
                    <Grid item style={{ marginBottom: 10 }}>
                      <Grid container direction="column">
                        <Grid container alignItems="center" spacing={1}>
                          <Grid item>
                            <DazzedParagraph16 bold>{t('dialogs.shareScan.title')}</DazzedParagraph16>
                          </Grid>
                          <Grid item>
                            <Tooltip value={t('dialogs.shareScan.infoTooltip')}>
                              <InfoSmall style={{ marginTop: 10 }} />
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid container alignItems="center" spacing={1}>
                          <Grid item>
                            <SmallLock />
                          </Grid>
                          <Grid item>
                            <DazzedParagraph14 strong color="var(--text-color-59)">
                              {t('dialogs.shareScan.secureNote')}
                            </DazzedParagraph14>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={10} id="share-scan-colleague-name">
                      <FormInput
                        style={{ bright: true, thick: true }}
                        title={t('dialogs.shareScan.colleagueNamelabel')}
                        value={referralName}
                        setValue={setReferralName}
                        validationRules={{
                          required: true
                        }}
                      />
                    </Grid>
                    <Grid item xs={10} id="share-scan-colleague-specialty">
                      <FormInput
                        style={{ bright: true, thick: true }}
                        title={t('dialogs.shareScan.specialityLabel')}
                        value={speciality}
                        setValue={setSpeciality}
                        validationRules={{
                          required: true
                        }}
                      />
                    </Grid>
                    <Grid item xs={10}>
                      <Grid container direction="column">
                        <Grid item>
                          <DazzedParagraph14 strong>{t('dialogs.shareScan.shareBy.label')}</DazzedParagraph14>
                        </Grid>
                        <Grid item xs={12}>
                          <GrinRadioGroup
                            size="small"
                            value={shareMethod}
                            setValue={newMethod => {
                              setShareMethod(newMethod)
                              setShareDestination('')
                            }}
                            options={shareTypeOptions}
                            radioGroupClassName={classes.shareTypeOptions}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={10} id="share-scan-medium">
                      {shareMethod === SHARE_METHODS.email ? (
                        <FormInput
                          style={{ bright: true, thick: true }}
                          placeholder={t(`dialogs.shareScan.shareBy.emailPlaceholder`)}
                          value={shareDestination}
                          setValue={setShareDestination}
                          validationRules={{
                            required: true
                          }}
                          errorMessage={error}
                        />
                      ) : (
                        <PhoneNumberInput
                          style={{ bright: true, thick: true }}
                          setValue={setShareDestination}
                          value={shareDestination}
                        />
                      )}
                    </Grid>
                    <Grid item xs={10} id="share-scan-note-for-colleague">
                      <TextAreaAutosize
                        inputStyle={{ fontSize: 14, fontWeight: 500, height: 100, overflow: 'auto' }}
                        rowsMax={4}
                        style={{ bright: true }}
                        title={t('dialogs.shareScan.noteLabel')}
                        placeholder={t('dialogs.shareScan.notePlaceholder')}
                        value={note}
                        keepErrorContainerWhenInactive={false}
                        setValue={setNote}
                        validationRules={{
                          required: true
                        }}
                      />
                    </Grid>
                    <Grid item id="share-scan-attach-files">
                      <AttachFiles files={files} setFiles={setFiles} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={7}>
                  <ScanWithPatientDetails grinScanId={grinScanId} videoContainerClassName={classes.videoContainer} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </BaseModal>
      <ShareScanAttachFilesModal files={files} setFiles={setFiles} />
    </>
  )
}

export default React.memo(ShareNewScanModalDesktop)
