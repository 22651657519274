import Switch from '@material-ui/core/Switch'
import { makeStyles } from '@material-ui/styles'
import React from 'react'

export const GrinSwitchSizes = {
  Small: 'small',
  Default: 'default'
}

const useStyles = makeStyles(theme => ({
  root: {
    width: ({ size }) => (size === GrinSwitchSizes.Small ? 32 : 42),
    height: ({ size }) => (size === GrinSwitchSizes.Small ? 16 : 26),
    padding: 0,
    margin: ({ size }) => (size === GrinSwitchSizes.Small ? 0 : theme.spacing(1))
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: 'var(--bg-color-7)',
        opacity: 1,
        border: 'none'
      }
    },
    '&$focusVisible $thumb': {
      color: 'var(--bg-color-7)',
      border: '6px solid #fff'
    }
  },
  thumb: {
    width: ({ size }) => (size === GrinSwitchSizes.Small ? 14 : 24),
    height: ({ size }) => (size === GrinSwitchSizes.Small ? 14 : 24)
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border'])
  },
  checked: {},
  focusVisible: {}
}))

const GrinSwitch = ({ checked, onChange, onClick, disabled = false, size = GrinSwitchSizes.Default }) => {
  const classes = useStyles({ size })

  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      onClick={onClick}
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked
      }}
    />
  )
}

export default GrinSwitch
