import * as React from 'react'

const Download1 = props => {
  return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.1875 9.75V11.375C12.1875 11.8231 11.8231 12.1875 11.375 12.1875H1.625C1.17691 12.1875 0.8125 11.8231 0.8125 11.375V9.75C0.8125 9.52575 0.9945 9.34375 1.21875 9.34375C1.443 9.34375 1.625 9.52575 1.625 9.75V11.375H11.375V9.75C11.375 9.52575 11.557 9.34375 11.7812 9.34375C12.0055 9.34375 12.1875 9.52575 12.1875 9.75Z"
        fill="var(--text-color-3)"
      />
      <path
        d="M3.36913 6.78722C3.21029 6.62838 3.21029 6.37162 3.36913 6.21278C3.52798 6.05394 3.78473 6.05394 3.94357 6.21278L6.09385 8.36306V1.21875C6.09385 0.9945 6.27585 0.8125 6.5001 0.8125C6.72435 0.8125 6.90635 0.9945 6.90635 1.21875V8.36306L9.05663 6.21278C9.21548 6.05394 9.47223 6.05394 9.63107 6.21278C9.78991 6.37162 9.78991 6.62838 9.63107 6.78722L6.78732 9.63097C6.62645 9.79225 6.36848 9.78697 6.21248 9.63097L3.36913 6.78722Z"
        fill="var(--text-color-3)"
      />
    </svg>
  )
}

export default Download1
