import * as React from 'react'

export default () => (
  <svg width="24" height="27" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.032 24.0699V26.0757C13.3808 26.0757 15.2462 25.2202 16.6714 24.0699H11.032Z" fill="#3C52EF" />
    <path
      d="M1.00293 14.0407V16.0466C1.00293 18.5538 3.00875 19.0553 3.00875 19.0553C3.00875 19.0553 5.01458 25.0728 11.032 25.0728"
      stroke="#3C52EF"
      strokeWidth="2.00582"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M22.0641 15.0437H19.0553C19.0553 8.78551 16.0466 6.01747 16.0466 6.01747C13.0379 13.0379 3.00874 8.02329 3.00874 15.0437H0C0 15.0437 0 14.9253 0 11.032C0 4.01165 6.01747 0 11.032 0C15.0437 0 16.0466 2.00582 16.0466 2.00582C20.0582 2.00582 22.0641 7.02038 22.0641 11.032V15.0437Z"
      fill="#3C52EF"
    />
    <path
      d="M11.5336 19.0553C11.3571 19.0553 10.7072 19.0553 10.5307 19.0553C9.70032 19.0553 9.02637 19.7293 9.02637 20.5597C9.02637 21.3901 9.70032 22.0641 10.5307 22.0641C10.7072 22.0641 11.3571 22.0641 11.5336 22.0641C12.3641 22.0641 13.038 21.3901 13.038 20.5597C13.038 19.7293 12.3641 19.0553 11.5336 19.0553Z"
      fill="#3C52EF"
    />
    <path
      d="M12.0349 21.0611H17.0495C19.2649 21.0611 21.0611 19.2649 21.0611 17.0495V14.0407"
      stroke="#3C52EF"
      strokeWidth="2.00582"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M21.0612 18.0524H19.0554V13.0379H21.0612C22.1695 13.0379 23.0671 13.9355 23.0671 15.0437V16.0466C23.0671 17.1548 22.1695 18.0524 21.0612 18.0524Z"
      fill="#3C52EF"
    />
  </svg>
)
