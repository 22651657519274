import * as React from 'react'

const defaultColor = '#9BA3B2'
const activeColor = '#3C52EF'

export default ({ isActive = false }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="3.20068"
      y="4.30005"
      width="17.6"
      height="17.6"
      rx="2.2"
      stroke={isActive ? activeColor : defaultColor}
      strokeWidth="1.5"
    />
    <path
      d="M12.0007 6.5001V2.1001"
      stroke={isActive ? activeColor : defaultColor}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M6.50073 6.5001V2.1001"
      stroke={isActive ? activeColor : defaultColor}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M17.5007 6.5001V2.1001"
      stroke={isActive ? activeColor : defaultColor}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.8014 8.7002H3.20142V18.6002C3.20142 20.4227 4.67888 21.9002 6.50142 21.9002H17.5014C19.324 21.9002 20.8014 20.4227 20.8014 18.6002V8.7002ZM16.4014 15.3002L8.70142 12.0002V18.6002L16.4014 15.3002Z"
      fill={isActive ? activeColor : defaultColor}
    />
  </svg>
)
