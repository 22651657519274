import { makeStyles } from '@material-ui/core'
import Actions from 'actions'
import BaseModal from 'components/common/modals/BaseModal'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useDispatch, useSelector } from 'react-redux'
import { trackEvent } from 'utils/analyticsUtils'
import SearchIcon from '../../components/common/icons/Search'
import grinSpotlightLogo from './grin-spotlight-logo.png'
import SearchResults from './SearchResults'
import useQuickActions from './useQuickActions'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  },
  spotlightContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#FFFFFFE5',
    height: 66,
    width: 640,
    marginTop: 10,
    borderRadius: ({ showResults }) => (showResults ? '15px 15px 0px 0px' : 15),
    border: 'none',
    borderBottom: ({ showResults }) => (showResults ? '1px solid #C5CCDD' : 'none'),
    boxShadow: '0px 0px 24px 2px #00000040',
    fontSize: 18,
    outline: 'none'
  },
  logoContainer: { display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '0 10px' },
  logo: {
    width: 80
  },
  searchInputContainer: {
    height: 66,
    display: 'flex',
    alignItems: 'center',
    width: 515,
    borderLeft: '1px solid #C5CCDD'
  },
  searchInput: {
    fontFamily: 'Dazzed',
    width: '100%',
    height: 45,
    border: 'none',
    padding: 10,
    fontSize: 18,
    outline: 'none',
    background: 'transparent'
  },
  searchResultsContainer: {
    paddingTop: 15,
    width: 640,
    paddingBottom: 15,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '0px 0px 15px 15px',
    background: '#FFFFFFE5'
  }
}))

export default () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const isOpen = useSelector(state => state.appReducer.quickActions.isOpen)
  const [loadMore, setLoadMore] = useState(false)

  const { searchTerm, isLoading, setSearchTerm, searchResultsGroups, setSearchResults } = useQuickActions({
    loadMore,
    setLoadMore
  })

  const showResults = useMemo(() => searchResultsGroups || isLoading, [isLoading, searchResultsGroups])
  const classes = useStyles({ showResults })

  const handleClose = useCallback(() => {
    trackEvent('Quick Actions - Closed')
    setSearchTerm('')
    setSearchResults(null)
    dispatch(Actions.toggleQuickActionsOpen(false))
  }, [dispatch, setSearchTerm, setSearchResults])

  useHotkeys('escape', () => handleClose('escape'), { enabled: isOpen })

  const handleSearchTermChange = useCallback(
    ({ target }) => {
      setSearchTerm(target.value)
      setLoadMore(false)
    },
    [setSearchTerm]
  )

  useEffect(() => {
    if (isOpen) {
      trackEvent('Quick Actions - Opened')
    }
  }, [isOpen])

  return (
    <BaseModal
      open={isOpen}
      handleClose={handleClose}
      variant="fuzzy"
      title={''}
      onSecondaryBtnClick={handleClose}
      withCloseIcon={false}
      titleClassName={classes.title}
      contentClassName={classes.container}
      className={classes.container}
      esc
    >
      <div className={classes.spotlightContainer}>
        <div className={classes.logoContainer}>
          <img src={grinSpotlightLogo} className={classes.logo} alt="logo" />
        </div>
        <div className={classes.searchInputContainer}>
          <SearchIcon width={32} height={32} />
          <input
            className={classes.searchInput}
            placeholder={t('dialogs.quickActions.spotlightPlaceholder')}
            type="text"
            name="quick-actions-input"
            value={searchTerm}
            autoComplete="off"
            onChange={handleSearchTermChange}
            autoFocus
            onKeyDown={e => (e.key === 'Escape' ? handleClose() : () => {})}
          />
        </div>
      </div>
      {showResults && (
        <div className={classes.searchResultsContainer}>
          <SearchResults
            searchTerm={searchTerm}
            searchResultsGroups={searchResultsGroups}
            isLoading={isLoading}
            handleClose={handleClose}
            setLoadMore={setLoadMore}
          />
        </div>
      )}
    </BaseModal>
  )
}
