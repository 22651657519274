import { isEmpty } from 'lodash'
import { useMemo } from 'react'

export default ({ grinScans }) => {
  const treatmentTrackerData = useMemo(
    () =>
      JSON.parse(
        grinScans.find(scan => scan.treatmentTrackerStatus === 'completed' && scan.treatmentTrackerData)
          ?.treatmentTrackerData || '{}'
      ),
    [grinScans]
  )

  return isEmpty(treatmentTrackerData) ? null : treatmentTrackerData
}
