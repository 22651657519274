import useQueryParams from 'hooks/useQueryParams'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setActiveItem } from './PatientSidebar/patientSidebarSlice'
import { PatientSidebarItemKeys } from './PatientSidebar/patientSidebarConsts'

export default () => {
  const dispatch = useDispatch()
  const { setQueryParams, queryParams } = useQueryParams()

  useEffect(() => {
    if (queryParams.patientView) {
      dispatch(setActiveItem(queryParams.patientView))
    } else {
      setQueryParams({ ...queryParams, patientView: PatientSidebarItemKeys.Chat })
    }
  }, [dispatch, queryParams, setQueryParams])
}
