import { Grid, makeStyles } from '@material-ui/core'
import Actions from 'actions'
import LinkButton from 'components/common/buttons/LinkButton'
import { SendMessage } from 'components/common/icons'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import useRolePermissions from 'hooks/useRolePermissions'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { trackEvent } from 'utils/analyticsUtils'
import { isMobile } from 'utils/mobileUtils'

const useStyles = () =>
  makeStyles({
    addedOverlay: {
      position: 'absolute',
      top: 0,
      right: 0,
      width: isMobile() ? '100%' : '50%',
      height: '100%',
      zIndex: 999
    },
    sendInvitation: {
      marginTop: 10,
      fontSize: 14,
      fontWeight: 500
    }
  })()

const PatientNotInvitedState = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const { permissions } = useRolePermissions()

  const { patient } = useSelector(state => state.patientsReducer)

  const handleSendInvite = useCallback(() => {
    trackEvent('Send invite to added patient - clicked', {
      analyticSource: 'patient chat'
    })
    dispatch(Actions.showResendPatientInvitationModal({ ...patient, isRecordsOnly: true }))
  }, [dispatch, patient])

  return (
    <Grid container direction="column" alignItems="center" justifyContent="center" className={classes.addedOverlay}>
      <Grid item>
        <SendMessage />
      </Grid>
      <Grid item>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={8}>
            <DazzedParagraph14 strong textAlign="center" color="var(--text-color-57)">
              {t('pages.patients.selectedPatient.chat.patientAdded.text')}
            </DazzedParagraph14>
          </Grid>
        </Grid>
      </Grid>
      {permissions.invitePatient && (
        <Grid item>
          <LinkButton
            label={t('pages.patients.selectedPatient.chat.patientAdded.sendInvitationLink')}
            onClick={handleSendInvite}
            labelClassName={classes.sendInvitation}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default PatientNotInvitedState
