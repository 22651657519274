import Actions from 'actions'
import { Auth } from 'aws-amplify'
import Announcements from 'components/Announcements/Announcements'
import GIDashboard from 'components/GIDashboard/GIDashboard'
import PreliminaryPlanDialog from 'components/Patients/Chat/PreliminaryPlanDialog'
import Patients from 'components/Patients/Patients'
import TaskManager from 'components/TaskManager/TaskManager'
import { ROUTES } from 'consts'
import { VERSION_LOOKUP_INTERVAL } from 'consts/appConsts'
import useCheckLoginChange from 'hooks/useCheckLoginChange'
import useCssClasses from 'hooks/useCssClasses'
import useFeatureFlags from 'hooks/useFeatureFlags'
import useRolePermissions from 'hooks/useRolePermissions'
import useStaticData from 'hooks/useStaticData'
import { isEmpty } from 'lodash-es'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router'
import { isAnalystUser, isUserOfAnyAdminRole } from 'utils/authUtils'
import { isMobile } from 'utils/mobileUtils'
import { getDoctorEmail, getDoctorId, isBillingInfoRequired } from 'utils/storageUtils'
import Assets from './Assets'
import AutochartDialog from './Autochart/AutochartDialog'
import Ddx from './Ddx/Ddx'
import AppHeader from './Header/AppHeader'
import AppHeaderMobile from './Header/AppHeader.mobile'
import NotificationCenter from './NotificationCenter/NotificationCenter'
import AssignPatientDialog from './Patients/AssignPatient/AssignPatientDialog'
import BeforeAfterDialogHOC from './Patients/BeforeAfter/BeforeAfterDialogHOC'
import CreateRecordsPatientModal from './Patients/CreateRecordsPatient/CreateRecordsPatientModal'
import DeletePatientDialog from './Patients/DeletePatientDialog'
import PatientBriefModal from './Patients/PatientBrief/PatientBriefModal'
import PatientCard from './Patients/PatientCard/PatientCard'
import PatientGuidelinesViewer from './Patients/PatientGuidelines/PatientGuidelinesViewer'
import PatientsMobile from './Patients/Patients.mobile'
import DolphinLatestPatientsDialog from './Patients/Pms/Dolphin/DolphinLatestPatientsDialog'
import Landing from './Patients/Pms/RedirectFromPMS/Landing'
import PmsPatientNotFoundModal from './Patients/Pms/RedirectFromPMS/PatientNotFoundModal'
import RegenerateScanModal from './Patients/RegenerateScan/RegenerateScanModal'
import ScanReviewEditor from './Patients/ScanReview/ScanReviewEditor'
import ShareNewScanModalHOC from './Patients/ShareScan/ShareNewScanModalHOC'
import ViewSharedScanModalHOC from './Patients/ShareScan/ViewSharedScanModalHOC'
import ScanViewer from './Patients/Timeline/ScanViewer'
import TransferPatientModal from './Patients/TransferPatientModal/TransferPatientModal'
import TreatmentTrackerModal from './Patients/TreatmentTracker/TreatmentTrackerModal/TreatmentTrackerModal'
import UpdateApplianceArrivalModal from './Patients/UpdateApplianceArrivalModal'
import UploadStlFilesModal from './Patients/UploadSTLFiles/UploadStlFilesModal'
import WelcomeDoctorModal from './Patients/WelcomeDoctor/WelcomeDoctorModal'
import AccountSettings from './Profile/AccountSettings'
import ActivateMFAModal from './Profile/ActivateMFAModal/ActivateMFAModal'
import BillingSelectPlanModal from './Profile/BillingSelectPlanModal'
import BillingUpgradePlanDialog from './Profile/BillingUpgradePlanDialog'
import BillingUpgradePlanForScopesWarningModal from './Profile/BillingUpgradePlanForScopesWarningModal'
import BillingUpgradePlanWarningModal from './Profile/BillingUpgradePlanWarningModal'
import DoctorStats from './Profile/DoctorStats'
import InvitationCodeModal from './Profile/InvitePatientModal/InvitationCodeModal'
import InvitationResultModal from './Profile/InvitePatientModal/InvitationResultModal'
import InvitePatient from './Profile/InvitePatientModal/InvitePatient.mobile'
import InvitePatientModal from './Profile/InvitePatientModal/InvitePatientModal'
import LatestReleases from './Profile/LatestReleases/LatestReleases'
import NewDoctorStats from './Profile/NewDoctorStats'
import OrderGrinKitsModal from './Profile/Order/OrderGrinKitsModal'
import ScopesPaymentAccepted from './Profile/Order/ScopesPaymentAccepted'
import ShareFeedbackDialog from './Profile/ShareFeedbackDialog'
import QuickActionsDialog from './QuickActions/QuickActionsDialog'
import QuickSignUpRouter from './QuickSignUp/QuickSignUpRouter'
import RCDashboard from './RCDashboard/RCDashboard'
import ForceRefreshModal from './Support/ForceRefreshModal'
import ReleaseNotesModal from './Support/SupportDashboard/ReleaseNotes/ReleaseNotesModal'
import usePageTitle from 'hooks/usePageTitle'

const SupportDashboardRouter = React.lazy(() => import('./Support/SupportDashboard/SupportDashboardRouter'))

const { makeStyles } = require('@material-ui/core')

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  }
})

const App = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { doctor } = useSelector(state => state.profileReducer)
  const userId = useSelector(state => state.profileReducer.doctor.user?.id)
  const appconfig = useSelector(state => state.appReducer.appconfig)
  const { redirectAfterAuth } = useSelector(state => state.authReducer)

  const location = useLocation()
  const history = useHistory()
  const {
    ortho: orthoFF,
    createRecordsOnlyPatients: createRecordsOnlyPatientsFF,
    notificationCenter: notificationCenterFF
  } = useFeatureFlags()
  const [, setForceUpdate] = useState()
  const isAdmin = useMemo(() => isUserOfAnyAdminRole(), [])
  const { permissions } = useRolePermissions()
  useStaticData()
  useCheckLoginChange()

  useHotkeys('mod+k', () => dispatch(Actions.toggleQuickActionsOpen(true)), { enabled: permissions.quickActionsDialog })

  useEffect(() => {
    if (isEmpty(appconfig)) {
      dispatch(Actions.fetchAppConfig())
    }
  }, [appconfig, dispatch])

  useEffect(() => {
    if (!doctor.id && getDoctorId()) {
      if (isBillingInfoRequired()) {
        dispatch(
          Actions.resumeDoctorOnboarding({
            email: getDoctorEmail(),
            stage: 'billingInfo',
            requireBillingInSignup: true
          })
        )
      } else {
        dispatch(Actions.userAuthCompleted())
      }
    }
  }, [dispatch, doctor])

  useEffect(() => {
    if (doctor.id && !isAdmin) dispatch(Actions.requestPracticeMembers())
  }, [dispatch, doctor.id, isAdmin])

  useEffect(() => {
    doctor.id && dispatch(Actions.requestTotalPatientsForTaskManager())
  }, [dispatch, doctor.id])

  useEffect(() => {
    userId && !isEmpty(appconfig) && dispatch(Actions.configurePubsub(userId))
  }, [dispatch, userId, appconfig])

  useEffect(() => {
    if (redirectAfterAuth) {
      return
    }

    const checkIfSignedIn = async () => {
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser()
        dispatch(Actions.setLoggedCognitoUser(cognitoUser))
      } catch (err) {
        history.push(ROUTES.WELCOME)
      }

      if (location.pathname !== ROUTES.ROOT) {
        dispatch(
          Actions.setPostAuthLocation(
            location.pathname.includes(ROUTES.QUICK_SIGN_UP) || location.pathname.includes(ROUTES.UNAVAILABLE)
              ? ROUTES.PATIENTS
              : `${location.pathname}${location.search}`
          )
        )
      }
    }

    checkIfSignedIn()
  }, [location, history, dispatch, redirectAfterAuth])

  useEffect(() => {
    if (isAnalystUser() && !history.location.pathname.includes(ROUTES.SUPPORT_DASHBOARD)) {
      history.push(ROUTES.SUPPORT_DASHBOARD)
    }
  }, [history, dispatch, location])

  useEffect(() => {
    const versionCheckInterval = setInterval(() => {
      dispatch(Actions.lookForNewVersion())
    }, VERSION_LOOKUP_INTERVAL)

    return () => clearInterval(versionCheckInterval)
  }, [dispatch])

  useEffect(() => {
    if (history.location.pathname.includes(ROUTES.TASK_MANAGER) && doctor.id && !orthoFF) {
      history.push(ROUTES.PATIENTS)
    }
  }, [doctor.id, history, orthoFF])

  useCssClasses()

  useEffect(() => {
    if (isMobile()) {
      window.addEventListener('resize', setForceUpdate)
      return () => window.removeEventListener('resize', setForceUpdate)
    }
  }, [])

  const showBar = useMemo(() => !history.location.pathname.includes(ROUTES.QUICK_SIGN_UP), [history.location.pathname])

  const showTabsOnMobile = useMemo(() => {
    const routes = [ROUTES.INVITE_PATIENT, ROUTES.BROADCAST]
    return isMobile() && !routes.some(route => history.location.pathname.includes(route))
  }, [history.location.pathname])

  const rootRoute = useCallback(() => <Redirect to={ROUTES.AUTH} />, [])
  const supportRoute = useCallback(
    () => (
      <React.Suspense fallback={<div>...</div>}>
        <SupportDashboardRouter />
      </React.Suspense>
    ),
    []
  )

  usePageTitle()

  return (
    <div className={classes.root}>
      {isMobile() && showBar ? <AppHeaderMobile showTabs={showTabsOnMobile} /> : showBar && <AppHeader />}

      <Switch>
        <Route exact path={ROUTES.ROOT} render={rootRoute} />
        <Route path={ROUTES.SUPPORT_DASHBOARD} render={supportRoute} />
        <Route path={ROUTES.PATIENTS} component={isMobile() ? PatientsMobile : Patients} />
        {isMobile() && <Route path={ROUTES.INVITE_PATIENT} component={InvitePatient} />}
        <Route path={ROUTES.TASK_MANAGER} component={TaskManager} />
        <Route path={ROUTES.RC_DASHBOARD} component={RCDashboard} />
        <Route path={ROUTES.DDX} component={Ddx} />
        <Route path={ROUTES.ACCOUNT_SETTINGS} component={AccountSettings} />
        <Route path={ROUTES.LATEST_RELEASE_NOTES} component={LatestReleases} />
        <Route path={ROUTES.DOC_STATS} component={DoctorStats} />
        <Route path={ROUTES.NEW_DOC_STATS} component={NewDoctorStats} />
        <Route path={ROUTES.QUICK_SIGN_UP} component={QuickSignUpRouter} />
        <Route path={ROUTES.ASSETS} component={Assets} />
        <Route path={ROUTES.GI_DASHBOARD} component={GIDashboard} />
        <Route path={ROUTES.PMS_REDIRECT_LANDING_PAGE} component={Landing} />
        <Redirect to={ROUTES.ROOT} />
      </Switch>

      {!location.pathname.includes(ROUTES.QUICK_SIGN_UP) && <Announcements />}
      <PatientCard />
      <UploadStlFilesModal />
      <InvitePatientModal />
      <InvitationResultModal />
      <InvitationCodeModal />
      <WelcomeDoctorModal />
      <ReleaseNotesModal />
      <ForceRefreshModal />
      <DeletePatientDialog />
      <ScanViewer />
      <PreliminaryPlanDialog />
      <ScanReviewEditor />
      <BillingUpgradePlanWarningModal />
      <BillingUpgradePlanForScopesWarningModal />
      <BillingSelectPlanModal />
      <BillingUpgradePlanDialog />
      <OrderGrinKitsModal />
      <ScopesPaymentAccepted />
      <UpdateApplianceArrivalModal />
      <AssignPatientDialog />
      <TransferPatientModal />
      <ShareFeedbackDialog />
      <BeforeAfterDialogHOC />
      <AutochartDialog />
      <DolphinLatestPatientsDialog />
      <ShareNewScanModalHOC />
      <ViewSharedScanModalHOC />
      <PmsPatientNotFoundModal />
      <TreatmentTrackerModal />
      <PatientBriefModal />
      <ActivateMFAModal />
      {permissions.quickActionsDialog && <QuickActionsDialog />}
      {permissions.patientGuidelinesViewer && !isMobile() && <PatientGuidelinesViewer />}
      {permissions.regenerateScan && <RegenerateScanModal />}
      {createRecordsOnlyPatientsFF && <CreateRecordsPatientModal />}
      {notificationCenterFF && !isMobile() && <NotificationCenter />}
    </div>
  )
}

export default App
