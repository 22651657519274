import React from 'react'
import { useTranslation } from 'react-i18next'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'

const PracticeSearchEmptyState = () => {
  const { t } = useTranslation()

  return <DazzedParagraph14 strong>{t('pages.patients.patientsList.searchByDoctor.noDoctorsFound')}</DazzedParagraph14>
}

export default PracticeSearchEmptyState
