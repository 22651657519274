import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Actions from 'actions'
import { Auth } from 'aws-amplify'
import { AuthAppMethod, SMSMethod } from 'components/common/icons'
import Chevron from 'components/common/icons/ChevrolRightSmall'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import OggHeading40 from 'components/common/text/OggHeading40'
import { ROUTES } from 'consts'
import { COGNITO_MFA_METHODS, MFA_METHOD_TYPES } from 'consts/mfaConsts'
import useFeatureFlags from 'hooks/useFeatureFlags'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { trackEvent } from 'utils/analyticsUtils'
import { isMfaRequiredForCurrentUser } from 'utils/authUtils'
import MFADisableMethodModal from './MFADisableMethodModal'
import MFAMethod from './MFAMethod'
import MFASetupModal from './MFASetupModal/MFASetupModal'

const useStyles = makeStyles(theme => ({
  heading: {
    marginTop: 10,
    marginBottom: 5
  },
  container: {
    width: 700
  },
  activeLink: {
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer'
    }
  },
  chevronContainer: {
    marginLeft: 3,
    marginTop: 5,
    width: 20
  },
  methodsContainer: {
    marginTop: 10
  }
}))

const MFASettings = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const { mfa: mfaFF } = useFeatureFlags()

  const { cognitoUser } = useSelector(state => state.authReducer)

  const mfaEnabled = useMemo(() => mfaFF || isMfaRequiredForCurrentUser(), [mfaFF])

  const handleSetupAppMethod = useCallback(async () => {
    trackEvent('MFA Settings - Setup auth app method clicked')
    const updatedCognitoUser = await Auth.currentAuthenticatedUser()

    const totpSecret = await Auth.setupTOTP(updatedCognitoUser)

    dispatch(Actions.setMfaSetupModalOpen({ isOpen: true, type: MFA_METHOD_TYPES.AuthApp, totpCode: totpSecret }))
  }, [dispatch])

  const handleSetupSMSMethod = useCallback(async () => {
    trackEvent('MFA Settings - Setup SMS method clicked')
    dispatch(
      Actions.setMfaSetupModalOpen({
        isOpen: true,
        type: MFA_METHOD_TYPES.SMS,
        isAppMethodAlreadySetup: cognitoUser?.preferredMFA === COGNITO_MFA_METHODS.AuthApp
      })
    )
  }, [cognitoUser?.preferredMFA, dispatch])

  const handleDisableClicked = useCallback(
    methodByCognito =>
      dispatch(
        Actions.setDisableMfaMethodModalOpen({
          isOpen: true,
          methodType: methodByCognito
        })
      ),
    [dispatch]
  )

  const mfaMethods = useMemo(
    () => [
      {
        icon: <AuthAppMethod />,
        title: t('pages.accountSettings.mfaSettings.authAppMethod.title'),
        description: t('pages.accountSettings.mfaSettings.authAppMethod.description'),
        isRecommended: true,
        methodByCognito: COGNITO_MFA_METHODS.AuthApp,
        onClick: handleSetupAppMethod
      },
      {
        icon: <SMSMethod />,
        title: t('pages.accountSettings.mfaSettings.smsMethod.title'),
        description: t('pages.accountSettings.mfaSettings.smsMethod.description'),
        methodByCognito: COGNITO_MFA_METHODS.SMS,
        onClick: handleSetupSMSMethod
      }
    ],
    [handleSetupAppMethod, handleSetupSMSMethod, t]
  )

  const handleGoBack = useCallback(() => {
    trackEvent('MFA - Go back to profile clicked')
    history.push(ROUTES.ACCOUNT_SETTINGS)
  }, [history])

  useEffect(() => {
    if (!mfaEnabled) {
      history.push(ROUTES.ACCOUNT_SETTINGS)
    }
  }, [history, mfaEnabled])

  return (
    <>
      <div className={classes.container}>
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <DazzedParagraph12 className={classes.activeLink} onClick={handleGoBack}>
              {t('pages.accountSettings.mfaSettings.breadCrumbs.myProfile')}
            </DazzedParagraph12>
          </Grid>
          <Grid item className={classes.chevronContainer}>
            <Chevron />
          </Grid>
          <Grid item>
            <DazzedParagraph12 thickness="bold">
              {t('pages.accountSettings.mfaSettings.breadCrumbs.twoFactorAuth')}
            </DazzedParagraph12>
          </Grid>
        </Grid>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <OggHeading40 textAlign="left">{t('pages.accountSettings.mfaSettings.title')}</OggHeading40>
          </Grid>
          <Grid item>
            <DazzedParagraph12 strong>{t('pages.accountSettings.mfaSettings.description')}</DazzedParagraph12>
          </Grid>
        </Grid>
        <Grid container direction="column" spacing={2} className={classes.methodsContainer}>
          {mfaMethods.map(({ icon, title, description, isRecommended = false, methodByCognito, onClick }) => (
            <Grid item key={`mfa-method-${methodByCognito}`}>
              <MFAMethod
                icon={icon}
                title={title}
                description={description}
                isRecommended={isRecommended}
                onSetupClicked={onClick}
                onDisableClicked={() => handleDisableClicked(methodByCognito)}
                isTurnedOn={cognitoUser?.preferredMFA === methodByCognito}
              />
            </Grid>
          ))}
        </Grid>
      </div>
      <MFASetupModal />
      <MFADisableMethodModal />
    </>
  )
}

export default MFASettings
