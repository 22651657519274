import React, { useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/styles'
import NoteItem from './NoteItem/NoteItem'

const useStyles = makeStyles(theme => ({
  notesListRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    height: '100%',
    padding: 10,
    overflowY: 'auto'
  }
}))

const NotesList = ({ notes }) => {
  const classes = useStyles()
  const notesListRef = useRef(null)

  useEffect(() => {
    if (notesListRef?.current?.scrollIntoView) {
      notesListRef.current.scrollIntoView({
        behavior: 'instant'
      })
    }
  }, [notes.length])

  return (
    <div className={classes.notesListRoot} id="notes-container">
      {notes.map(({ id, body, createdAt, isPinned, metadata, grinUserId, creatorUserId }, index) => (
        <NoteItem
          id={id}
          patientGrinUserId={grinUserId}
          ref={index === notes.length - 1 ? notesListRef : null}
          key={id}
          body={body}
          createdAt={createdAt}
          isPinned={isPinned}
          metadata={metadata}
        />
      ))}
    </div>
  )
}

export default React.memo(NotesList)
