import { API, Auth, graphqlOperation } from 'aws-amplify'
import { push } from 'connected-react-router'
import { DoctorSignUpProgressOptions } from 'consts/authConsts'
import momenttz from 'moment-timezone'
import { ofType } from 'redux-observable'
import { concat, forkJoin, from, interval, of } from 'rxjs'
import {
  catchError,
  debounce,
  filter,
  ignoreElements,
  map,
  mapTo,
  mergeMap,
  pluck,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs/operators'
import { registerMixpanelUser, trackEvent } from 'utils/analyticsUtils'
import { logInfo, logWarn } from 'utils/logUtils'
import { mapToCreatePracticeMemberInput } from 'utils/mappers/doctorsMappers'
import Actions from '../actions'
import { termsVersion } from '../components/Auth/TermsDoc'
import ROUTES from '../consts/routesConsts'
import { doctorByUsername, grinUserByUsernameVersionOnly, updateGrinUserMinified } from '../graphql/customQueries'
import { grinUserByUsername } from '../graphql/queries'
import i18n from '../resources/locales/i18n'
import { getUserGroups, isUserOfAnyAdminRole, validatePasswords } from '../utils/authUtils'
import {
  clearPracticeInfoDraft,
  getDoctorId,
  getDoctorUsername,
  removeDoctorData,
  removeForceDesktop,
  setCloudFrontUrl,
  setDoctorUsername,
  setPracticeInfoDraft,
  updateS3PracticeLogoKey
} from '../utils/storageUtils'
import {
  clearExpiredTrustedBrowser,
  forgetUserProfile,
  getMostRecentlyUsedProfile,
  storeCurrentUserProfile,
  switchAccount
} from 'logic/loginProfilesLogic'

export const requestSignUpEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.SIGNUP_REQUESTED),
    withLatestFrom(state$),
    map(([, state]) => ({
      username: state.authReducer.emailAddress,
      password: state.authReducer.password
    })),
    tap(({ username }) => trackEvent('Sign up started', { email: username, signupType: 'standard' })),
    switchMap(({ username, password }) =>
      from(
        Auth.signUp({
          username,
          password,
          clientMetadata: {
            origin: 'grin-webapp'
          }
        })
      ).pipe(
        mergeMap(response => of(Actions.signUpReceived(response))),
        catchError(error => {
          if (error.code === 'UserLambdaValidationException') {
            return of(
              Actions.updateSignUpErrors({
                email: error.message
              })
            )
          }
          return of(Actions.fetchRejected(error))
        })
      )
    )
  )

export const signUpReceivedEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.SIGNUP_RECEIVED),
    withLatestFrom(state$),
    map(([, state]) => ({
      emailAddress: state.authReducer.emailAddress
    })),
    tap(({ emailAddress }) =>
      trackEvent('Sign up completed', {
        email: emailAddress,
        signupType: 'standard'
      })
    ),
    mapTo(push(ROUTES.CONFIRMATION))
  )

export const requestConfirmSignUpEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.CONFIRM_SIGNUP_REQUESTED),
    withLatestFrom(state$),
    map(([, state]) => ({
      code: state.authReducer.confirmationCode,
      emailAddress: state.authReducer.emailAddress,
      password: state.authReducer.password
    })),
    switchMap(({ emailAddress, code, password }) => {
      trackEvent('Sign up completed', {
        email: emailAddress,
        type: 'standard'
      })

      return from(
        Auth.confirmSignUp(emailAddress, code).then(() =>
          Auth.signIn(emailAddress, password, {
            origin: 'grin-webapp'
          })
        )
      ).pipe(
        mergeMap(res => of(Actions.signUpConfirmReceived(res))),
        catchError(error =>
          of(
            Actions.updateResetPasswordErrors({
              confirmationCode: error.message ?? 'error'
            })
          )
        )
      )
    })
  )

export const confirmSignUpReceivedEpic = action$ =>
  action$.pipe(ofType(Actions.CONFIRM_SIGNUP_RECEIVED), mapTo(push(ROUTES.PERSONAL_DETAILS)))

export const resetPasswordEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.RESET_PASSWORD_REQUESTED),
    withLatestFrom(state$),
    map(([, state]) => ({
      emailAddress: state.authReducer.emailAddress
    })),
    switchMap(({ emailAddress }) =>
      from(Auth.forgotPassword(emailAddress)).pipe(
        mergeMap(response => of(Actions.resetPasswordReceived(response))),
        catchError(error =>
          of(
            error.__type === 'LimitExceededException'
              ? Actions.fetchRejected({
                  errorMessage: error.message,
                  showSnackbar: true
                })
              : Actions.resetPasswordReceived(null)
          )
        )
      )
    )
  )

export const resetPasswordReceivedEpic = action$ =>
  action$.pipe(ofType(Actions.RESET_PASSWORD_RECEIVED), mapTo(push(ROUTES.SET_PASSWORD)))

export const resendConfirmationCodeEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.RESEND_CONFIRMATION_CODE),
    withLatestFrom(state$),
    map(([, state]) => ({
      emailAddress: state.authReducer.emailAddress
    })),
    switchMap(({ emailAddress }) =>
      from(Auth.forgotPassword(emailAddress)).pipe(
        mergeMap(res => of(Actions.resendConfirmationCodeReceived({ emailAddress }))),
        catchError(err => of(Actions.resendConfirmationCodeFailed(err)))
      )
    )
  )

export const resendConfirmationCodeReceivedEpic = action$ =>
  action$.pipe(
    ofType(Actions.RESEND_CONFIRMATION_CODE_RECEIVED),
    pluck('payload'),
    mergeMap(({ emailAddress }) =>
      of(
        Actions.showSnackbar({
          type: 'success',
          text: i18n.t('pages.setPassword.confirmationCodeSuccess', { emailAddress })
        })
      )
    )
  )

export const resendConfirmationCodeFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.RESEND_CONFIRMATION_CODE_FAILED),
    pluck('payload'),
    filter(({ code }) => code === 'LimitExceededException'),
    mapTo(
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('pages.setPassword.confirmationCodeFailed')
      })
    )
  )

export const resetPasswordSubmitEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.RESET_PASSWORD_SUBMIT_REQUESTED),
    withLatestFrom(state$),
    map(([, state]) => ({
      emailAddress: state.authReducer.emailAddress,
      code: state.authReducer.confirmationCode,
      password: state.authReducer.password,
      passwordConfirmation: state.authReducer.passwordConfirmation
    })),
    filter(({ password, passwordConfirmation }) => !validatePasswords(password, passwordConfirmation).errors),
    switchMap(({ emailAddress, code, password }) =>
      from(Auth.forgotPasswordSubmit(emailAddress, code, password)).pipe(
        mergeMap(() =>
          of(
            Actions.resetPasswordSubmitReceived({
              snackbarText: 'Password reset success. Please sign in with your new password'
            })
          )
        ),
        catchError(error =>
          of(
            error.code === 'CodeMismatchException'
              ? Actions.updateResetPasswordErrors({
                  confirmationCode: error.message
                })
              : Actions.fetchRejected(error)
          )
        )
      )
    )
  )

export const resetPasswordSubmitWithErrorEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.RESET_PASSWORD_SUBMIT_REQUESTED),
    withLatestFrom(state$),
    map(([, state]) => ({
      emailAddress: state.authReducer.emailAddress,
      code: state.authReducer.confirmationCode,
      password: state.authReducer.password,
      passwordConfirmation: state.authReducer.passwordConfirmation
    })),
    map(({ password, passwordConfirmation }) => validatePasswords(password, passwordConfirmation)),
    filter(validationResults => !!validationResults.errors),
    map(validationResults => Actions.updateResetPasswordErrors(validationResults.errors))
  )

export const resetPasswordSubmitReceivedEpic = action$ =>
  action$.pipe(ofType(Actions.RESET_PASSWORD_SUBMIT_RECEIVED), mapTo(push(ROUTES.SIGN_IN)))

export const createDoctorReceivedEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.CREATE_DOCTOR_RECEIVED),
    withLatestFrom(state$),
    mergeMap(() => concat(of(Actions.requestSignIn()), of(Actions.registerMixpanelUser())))
  )

export const registerMixpanelUserEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.REGISTER_MIXPANEL_USER),
    withLatestFrom(state$),
    map(([, { authReducer }]) => ({
      username: authReducer.username
    })),
    tap(({ username }) => registerMixpanelUser(username)),
    ignoreElements()
  )

export const clearPracticeInfoDraftEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.CREATE_DOCTOR_RECEIVED),
    tap(() => clearPracticeInfoDraft()),
    ignoreElements()
  )
export const resendMFACodeEpic = (action$, state$, { history }) =>
  action$.pipe(
    ofType(Actions.RESEND_MFA_CODE),
    withLatestFrom(state$),
    map(([action, { authReducer }]) => ({
      emailAddress: action.payload?.isPracticeMember ? authReducer.practiceMembers.email : authReducer.emailAddress,
      password: action.payload?.isPracticeMember ? authReducer.practiceMembers.password : authReducer.password
    })),
    switchMap(({ emailAddress, password }) =>
      from(
        Auth.signIn(emailAddress, password, {
          origin: 'grin-webapp'
        })
      ).pipe(
        mergeMap(response => {
          return concat(of(Actions.resendMFACodeReceived()), of(Actions.setUserForMFA(response)))
        }),
        catchError(error => of(Actions.resendMFACodeFailed(error)))
      )
    )
  )

export const resendMFACodeReceivedEpic = action$ =>
  action$.pipe(
    ofType(Actions.RESEND_MFA_CODE_RECEIVED),
    mapTo(
      Actions.showSnackbar({
        type: 'success',
        text: i18n.t('pages.mfa.resendSuccessMessage')
      })
    )
  )

export const resendMFACodeFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.RESEND_MFA_CODE_FAILED),
    pluck('payload'),
    filter(({ code }) => code === 'LimitExceededException'),
    mapTo(
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('pages.mfa.resendFailedMessage')
      })
    )
  )

export const requestSignInEpic = (action$, state$, { history }) =>
  action$.pipe(
    ofType(Actions.SIGNIN_REQUESTED),
    withLatestFrom(state$),
    map(([action, { authReducer }]) => ({
      emailAddress: action.payload?.isPracticeMember ? authReducer.practiceMembers.email : authReducer.emailAddress,
      password: action.payload?.isPracticeMember ? authReducer.practiceMembers.password : authReducer.password
    })),
    switchMap(({ emailAddress, password }) =>
      from(
        Auth.signIn(emailAddress, password, {
          origin: 'grin-webapp'
        })
      ).pipe(
        mergeMap(response => {
          // Handle MFA
          if (response?.challengeName === 'SOFTWARE_TOKEN_MFA' || response?.challengeName === 'SMS_MFA') {
            history.push(ROUTES.MFA_LOGIN)
            return of(Actions.setUserForMFA(response))
          }

          return of(Actions.signInReceived(response))
        }),
        catchError(error => {
          if (error.code === 'UserNotConfirmedException') {
            return of(
              Actions.resumeDoctorOnboarding({
                email: emailAddress,
                stage: 'confirmation'
              })
            )
          }
          if (error.code === 'PasswordResetRequiredException') {
            return of(Actions.resetPasswordReceived({}))
          }

          if (error.code === 'UserLambdaValidationException' || error.code === 'UnexpectedLambdaException') {
            return of(
              Actions.updateResetPasswordErrors({
                signIn: i18n.t('errors.somethingWentWrongTryAgain')
              })
            )
          }

          return of(
            Actions.updateResetPasswordErrors({
              signIn: error.message ?? 'error'
            })
          )
        })
      )
    )
  )

export const signInReceivedEvent = action$ =>
  action$.pipe(
    ofType(Actions.SIGNIN_RECEIVED),
    map(({ payload }) => ({ cognitoUser: payload })),
    tap(({ cognitoUser }) =>
      trackEvent('Platform Sign In', {
        email: cognitoUser.attributes.email,
        userGroups: getUserGroups(cognitoUser.signInUserSession?.idToken?.jwtToken)
      })
    ),
    ignoreElements()
  )

export const signInReceivedEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.SIGNIN_RECEIVED, Actions.RESIGNIN_RECEIVED),
    withLatestFrom(state$),
    map(([action, state]) => ({
      cognitoUser: action.payload,
      redirectTo: state.authReducer.redirectAfterAuth ?? ROUTES.PATIENTS
    })),
    tap(({ cognitoUser }) => setDoctorUsername(cognitoUser.username)),
    tap(setCloudFrontUrl),
    switchMap(({ cognitoUser, redirectTo }) =>
      forkJoin({
        grinUser: from(
          API.graphql(
            graphqlOperation(grinUserByUsername, {
              username: cognitoUser.username
            })
          )
        ).pipe(map(({ data }) => data?.grinUserByUsername?.items[0])),
        doctor: from(
          API.graphql(
            graphqlOperation(doctorByUsername, {
              username: cognitoUser.username
            })
          )
        ).pipe(map(({ data }) => data?.doctorByUsername?.items[0])),
        doctorPlan: from(verifyDoctor(cognitoUser.attributes.email)).pipe(map(({ doctorPlan }) => doctorPlan))
      }).pipe(
        mergeMap(results => {
          if (!results.grinUser || !results.doctor) {
            return of(
              Actions.resumeOnboarding({
                email: cognitoUser.attributes.email
              })
            )
          } else if (
            (results.doctorPlan.signupProgress === DoctorSignUpProgressOptions.AccountCreated &&
              !results.doctorPlan.requireBillingInSignup) ||
            results.doctorPlan.signupProgress === DoctorSignUpProgressOptions.BillingInfoCompleted ||
            !results.doctorPlan.signupProgress
          ) {
            return concat(
              of(Actions.signInSideEffectsCompleted({ ...results, requireBillingInfo: false })),
              of(push(redirectTo))
            )
          } else {
            return concat(
              of(Actions.signInSideEffectsCompleted({ ...results, requireBillingInfo: true })),
              of(
                Actions.resumeDoctorOnboarding({
                  email: cognitoUser.attributes.email,
                  stage: 'billingInfo',
                  requireBillingInSignup: results.doctorPlan.requireBillingInSignup
                })
              )
            )
          }
        }),
        catchError(error => of(Actions.fetchRejected(error)))
      )
    )
  )

export const updateUserLoginInfo = action$ =>
  action$.pipe(
    ofType(Actions.USER_AUTH_COMPLETED),
    map(() => ({
      doctorId: getDoctorId(),
      username: getDoctorUsername()
    })),
    switchMap(({ doctorId, username }) =>
      from(API.graphql(graphqlOperation(grinUserByUsernameVersionOnly, { username }))).pipe(
        map(res => res.data.grinUserByUsername?.items[0]),
        filter(grinUser => {
          if (!grinUser) {
            logWarn(
              `updateUserLoginInfo: user not found by username: ${username}. stopping here without updating login info`,
              { doctorId, username }
            )
            return false
          }
          return true
        }),
        map(({ id, _version }) => {
          const mutationInput = {
            id,
            _version,
            timezone: momenttz.tz.guess(),
            lastLoginDate: new Date().toISOString()
          }
          logInfo(`updateUserLoginInfo: updating user login info`, {
            doctorId,
            username,
            input: mutationInput
          })
          return mutationInput
        }),
        mergeMap(input =>
          from(API.graphql(graphqlOperation(updateGrinUserMinified, { input }))).pipe(
            tap(() =>
              logInfo(`updateUserLoginInfo: user updated successfully`, { doctorId, username, grinUserId: input.id })
            ),
            mergeMap(() => of(Actions.updateUserLoginInfoReceived())),
            catchError(err => of(Actions.updateUserLoginInfoFailed(err)))
          )
        ),
        catchError(err => of(Actions.updateUserLoginInfoFailed(err)))
      )
    )
  )

export const signOutEpic = action$ =>
  action$.pipe(
    ofType(Actions.SIGNOUT_REQUESTED),
    switchMap(() =>
      from(Auth.currentAuthenticatedUser()).pipe(
        switchMap(cognitoUser =>
          from(Auth.signOut()).pipe(
            tap(() => {
              forgetUserProfile({ username: getDoctorUsername() })
              removeDoctorData()
              removeForceDesktop()
              clearExpiredTrustedBrowser({
                idToken: cognitoUser.signInUserSession.idToken.payload,
                username: cognitoUser.attributes.sub
              })

              const lastProfile = getMostRecentlyUsedProfile()
              if (lastProfile) {
                switchAccount(lastProfile.username)
              }
            })
          )
        )
      )
    ),
    mapTo(Actions.signOutReceived())
  )

export const signOutReceivedEpic = action$ =>
  action$.pipe(ofType(Actions.SIGNOUT_RECEIVED), mapTo(push(ROUTES.WELCOME)))

export const requestResendConfirmSignUpEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.CONFIRM_RESEND_SIGNUP_REQUESTED),
    withLatestFrom(state$),
    map(([, state]) => ({
      emailAddress: state.authReducer.emailAddress
    })),
    switchMap(({ emailAddress }) =>
      from(Auth.resendSignUp(emailAddress)).pipe(
        mergeMap(response => of(Actions.resendSignUpConfirmReceived(response))),
        catchError(error => of(Actions.fetchRejected(error)))
      )
    )
  )

export const completeAccountInfoEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.ACCOUNT_INFO_COMPLETED),
    withLatestFrom(state$),
    map(([, state]) => ({
      emailAddress: state.authReducer.emailAddress
    })),
    switchMap(({ emailAddress }) =>
      from(verifyDoctor(emailAddress)).pipe(
        mergeMap(({ doctorPlan }) =>
          of(
            Actions.validateAccountInfo({
              program: doctorPlan.program,
              requireBillingInSignup: doctorPlan.requireBillingInSignup
            })
          )
        ),
        catchError(err => of(Actions.notAllowedDoctorRecevied(err)))
      )
    )
  )

export const notAllowedDoctorReceviedEpic = action$ =>
  action$.pipe(ofType(Actions.NOT_ALLOWED_DOCTOR_RECEIVED), mapTo(push(ROUTES.EMAIL_NOT_VERIFIED)))

export const updateUserProgram = action$ =>
  action$.pipe(
    ofType(Actions.VALIDATE_ACCOUNT_INFO),
    pluck('payload'),
    map(({ program, requireBillingInSignup }) =>
      Actions.changeAuthForm([
        {
          key: 'program',
          value: program
        },
        {
          key: 'requireBillingInSignup',
          value: requireBillingInSignup
        }
      ])
    )
  )

export const validateAccountInfoEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.VALIDATE_ACCOUNT_INFO),
    withLatestFrom(state$),
    map(([action, state]) => ({
      emailAddress: state.authReducer.emailAddress,
      password: state.authReducer.password,
      passwordConfirmation: state.authReducer.passwordConfirmation
    })),
    switchMap(({ emailAddress, password, passwordConfirmation }) =>
      forkJoin({
        emailStatus: from(Auth.signIn(emailAddress, 'wrong')).pipe(catchError(error => of(error.code))),
        passwordValidationResults: of(validatePasswords(password, passwordConfirmation))
      }).pipe(
        mergeMap(({ emailStatus, passwordValidationResults }) =>
          emailStatus === 'UserNotConfirmedException'
            ? of(Actions.recreateUnconfirmedUser(emailAddress))
            : of(
                Actions.updateSignUpErrors({
                  email: emailStatus !== 'UserNotFoundException' && 'This email address already exists, please sign in',
                  ...(passwordValidationResults.errors || {})
                })
              )
        )
      )
    )
  )

export const deleteUncofirmedUserEpic = action$ =>
  action$.pipe(
    ofType(Actions.RECREATE_UNCONFIRMED_USER),
    pluck('payload'),
    switchMap(email =>
      from(API.del('grinServerlessApi', `/accounts/v2/users/unconfirmed/${email}`)).pipe(
        mergeMap(() => of(Actions.requestSignUp())),
        catchError(err => of(Actions.fetchRejected(err)))
      )
    )
  )

export const signUpErrorsCleared = action$ =>
  action$.pipe(
    ofType(Actions.SIGNUP_ERRORS_UPDATED),
    pluck('payload'),
    filter(errors => !errors.email && !errors.password && !errors.passwordConfirmation),
    mapTo(Actions.requestSignUp())
  )

export const resumeDoctorOnboardingVerify = action$ =>
  action$.pipe(
    ofType(Actions.RESUME_ONBOARDING),
    pluck('payload'),
    switchMap(({ email }) =>
      from(verifyDoctor(email)).pipe(
        mergeMap(({ doctorPlan, accountOwner }) => {
          const encodedEmail = encodeURIComponent(email)
          const encodedFirstName = encodeURIComponent(doctorPlan?.name?.split(' ')[0] || '')
          const encodedLastName = encodeURIComponent(doctorPlan?.name?.split(' ')[1] || '')
          return accountOwner
            ? of(
                push({
                  pathname: ROUTES.AUTH_PRACTICE_MEMBERS_DETAILS,
                  search: `?email=${encodedEmail}&country=${accountOwner.clinic.country}&firstName=${encodedFirstName}&lastName=${encodedLastName}`
                })
              )
            : of(
                Actions.resumeDoctorOnboarding({
                  email,
                  stage: 'personalDetails',
                  requireBillingInSignup: doctorPlan.requireBillingInSignup
                })
              )
        })
      )
    )
  )

export const resumeDoctorOnboardingConfirmation = action$ =>
  action$.pipe(
    ofType(Actions.RESUME_DOCTOR_ONBOARDING),
    pluck('payload'),
    filter(({ stage }) => stage === 'confirmation'),
    mergeMap(() =>
      concat(
        of(push(ROUTES.SIGN_UP)),
        of(
          Actions.showSnackbar({
            type: 'info',
            text: "We have noticed that you didn't finish the signup process. Please complete all the steps in order to create your account"
          })
        ),
        of(Actions.updateResetPasswordErrors({}))
      )
    )
  )

export const resumeDoctorOnboardingPracticeInfo = action$ =>
  action$.pipe(
    ofType(Actions.RESUME_DOCTOR_ONBOARDING),
    pluck('payload'),
    filter(({ stage }) => stage === 'personalDetails'),
    mapTo(push(ROUTES.PERSONAL_DETAILS))
  )

export const resumeDoctorOnboardingBillingInfo = action$ =>
  action$.pipe(
    ofType(Actions.RESUME_DOCTOR_ONBOARDING),
    pluck('payload'),
    filter(({ stage }) => stage === 'billingInfo'),
    mapTo(push(ROUTES.BILLING_INFO))
  )

export const savePracticeInfoDraftTrigger = (action$, state$) =>
  action$.pipe(
    ofType(Actions.AUTH_FORM_CHANGED),
    withLatestFrom(state$),
    map(([, state]) => ({
      location: state.router.location.pathname
    })),
    filter(({ location }) => location === ROUTES.PRACTICE_INFO || location === ROUTES.PERSONAL_DETAILS),
    debounce(() => interval(500)),
    mapTo(Actions.savePracticeInfoDraft())
  )

export const savePracticeInfoDraftEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.SAVE_PRACTICE_INFO_DRAFT),
    withLatestFrom(state$),
    map(([, state]) => ({
      ...state.authReducer
    })),
    tap(
      ({
        name,
        practiceEmailAddress,
        countryCode,
        phoneValue,
        practiceName,
        address,
        city,
        state,
        zip,
        country,
        firstName,
        lastName,
        roleDescription,
        photo,
        clinicLogo,
        clinicHomepageUrl,
        practicePhoneValue,
        practiceCountryCode
      }) =>
        setPracticeInfoDraft({
          name,
          practiceEmailAddress,
          countryCode,
          phoneValue,
          practiceName,
          address,
          city,
          state,
          country,
          zip,
          firstName,
          lastName,
          roleDescription,
          photo,
          clinicLogo,
          clinicHomepageUrl,
          practicePhoneValue,
          practiceCountryCode
        })
    ),
    ignoreElements()
  )

const verifyDoctor = email => API.get('grinServerlessApi', `/accounts/v2/leads/practiceMembers/${email}`)

export const registerRCUser = (action$, state$) =>
  action$.pipe(
    ofType(Actions.REGISTER_RC_USER_REQUESTED),
    withLatestFrom(state$),
    map(([action, state]) => ({
      userDetails: action.payload,
      approved: JSON.parse(state.router.location.query?.approved || null),
      withShipping: JSON.parse(state.router.location.query?.shipping || null)
    })),
    switchMap(({ userDetails, approved, withShipping }) =>
      from(
        API.post('grinServerlessApi', '/accounts/v2/leads/rc', {
          body: {
            ...userDetails,
            program: 'rc',
            approved,
            withShipping
          }
        })
      ).pipe(
        mergeMap(response => of(Actions.registerRCUserReceived(response))),
        catchError(error => of(Actions.registerRCUserFailed(error)))
      )
    )
  )

export const registerRCUserSuccess = (action$, state$) =>
  action$.pipe(
    ofType(Actions.REGISTER_RC_USER_RECEIVED),
    withLatestFrom(state$),
    map(([, state]) => state.router.location.search),
    map(querystring => push(`${ROUTES.AUTH_RC_SUCCESS}${querystring}#body`))
  )

export const registerRCUserFail = (action$, state$) =>
  action$.pipe(
    ofType(Actions.REGISTER_RC_USER_FAILED),
    withLatestFrom(state$),
    map(([, state]) => ({
      queryString: state.router.location.search,
      rcToken: state.authReducer.rcRegister.rcToken
    })),
    map(({ queryString, rcToken }) => push(`${ROUTES.AUTH_RC_FAIL}${queryString}&rcToken=${rcToken}#body`))
  )

export const requestDoctorPublicDetailsEpic = action$ =>
  action$.pipe(
    ofType(Actions.REQUEST_DOCTOR_PUBLIC_DETAILS),
    pluck('payload'),
    switchMap(rcToken =>
      from(API.get('grinServerlessApi', `/accounts/v2/doctors/rc/${rcToken}`)).pipe(
        mergeMap(data => of(Actions.doctorPublicDetailsReceived(data))),
        catchError(err => of(Actions.doctorPublicDetailsFailed(err)))
      )
    )
  )

export const doctorRcIsUnavailable = (action$, state$) =>
  action$.pipe(
    ofType(Actions.DOCTOR_PUBLIC_DETAILS_RECEIVED),
    withLatestFrom(state$),
    map(([{ payload }, state]) => ({
      rcAvailable: payload.rcAvailable,
      queryString: state.router.location.search
    })),
    filter(({ rcAvailable }) => !rcAvailable),
    map(({ queryString }) => push(`${ROUTES.AUTH_RC_UNAVAILABLE}${queryString}`))
  )

export const doctorPublicDetailsFailedEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.DOCTOR_PUBLIC_DETAILS_FAILED),
    withLatestFrom(state$),
    map(([, state]) => ({
      queryString: state.router.location.search
    })),
    map(({ queryString, rcToken }) => push(`${ROUTES.AUTH_RC_UNAVAILABLE}${queryString}`))
  )

export const practiceMembersSignUpEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.PRACTICE_MEMBERS_SIGNUP),
    withLatestFrom(state$),
    map(([, state]) => ({
      username: state.authReducer.practiceMembers.email,
      password: state.authReducer.practiceMembers.password
    })),
    switchMap(({ username, password }) =>
      from(
        Auth.signUp({
          username,
          password,
          clientMetadata: {
            origin: 'grin-webapp'
          }
        })
      ).pipe(
        mergeMap(res => of(Actions.practiceMembersSignUpReceived(res))),
        catchError(err => of(Actions.practiceMembersSignUpFailed(err)))
      )
    )
  )

export const practiceMembersSignUpReceivedEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.PRACTICE_MEMBERS_SIGNUP_RECEIVED),
    withLatestFrom(state$),
    map(([, state]) => ({
      username: state.authReducer.practiceMembers.email,
      password: state.authReducer.practiceMembers.password
    })),
    switchMap(({ username, password }) =>
      from(
        Auth.signIn(username, password, {
          origin: 'grin-webapp'
        })
      ).pipe(
        mergeMap(res => of(Actions.practiceMembersPostSignUpCompleted())),
        catchError(err => of(Actions.practiceMembersSignUpFailed(err)))
      )
    )
  )

export const practiceMembersPostSignUpCompletedEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.PRACTICE_MEMBERS_POST_SIGNUP_COMPLETED),
    withLatestFrom(state$),
    map(([, state]) => ({
      pathname: ROUTES.AUTH_PRACTICE_MEMBERS_DETAILS,
      search: state.router.location.search
    })),
    map(locationParams => push(locationParams))
  )

export const practiceMembersSignUpFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.PRACTICE_MEMBERS_SIGNUP_FAILED),
    pluck('payload'),
    map(err => {
      if (err.code === 'UsernameExistsException') {
        return Actions.updatePracticeMembersSignUpErrors({
          userAlreadyExists: err.message
        })
      }
      if (err.code === 'UserLambdaValidationException') {
        return Actions.updatePracticeMembersSignUpErrors({
          email: err.message
        })
      }
      return Actions.showSnackbar({
        type: 'error',
        text: i18n.t('error.general')
      })
    })
  )

export const createPracticeMemberEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.CREATE_PRACTICE_MEMBER),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const practiceMemberDto = mapToCreatePracticeMemberInput({ ...state.authReducer.practiceMembers })
      return from(
        API.post('grinServerlessApi', '/accounts/v2/practiceMembers', {
          body: {
            practiceMemberDto,
            termsVersion: termsVersion(state.authReducer.practiceMembers.country),
            timezone: momenttz.tz.guess()
          }
        })
      ).pipe(
        mergeMap(res => of(Actions.createPracticeMemberReceived(res))),
        catchError(err => of(Actions.createPracticeMemberFailed(err)))
      )
    })
  )

export const createPracticeMemberReceivedEpic = action$ =>
  action$.pipe(
    ofType(Actions.CREATE_PRACTICE_MEMBER_RECEIVED),
    mergeMap(() =>
      concat(of(Actions.requestSignIn({ isNewUser: true, isPracticeMember: true })), of(Actions.registerMixpanelUser()))
    )
  )

export const createPracticeMemberFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.CREATE_PRACTICE_MEMBER_FAILED),
    mapTo(
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('messages.somethingWentWrongContactSupport')
      })
    )
  )

export const storeAccountLoginDataEpic = action$ =>
  action$.pipe(
    ofType(Actions.DOCTOR_DETAILS_RECEIVED),
    pluck('payload'),
    tap(({ practice, doctor }) => {
      const isAdmin = isUserOfAnyAdminRole()
      storeCurrentUserProfile({
        displayName: isAdmin ? doctor.name : practice?.details?.practiceName,
        avatar: isAdmin ? doctor.photo : updateS3PracticeLogoKey(practice?.details?.logo),
        email: doctor.email
      })
    }),
    ignoreElements()
  )
