import moment from 'moment'
import momenttz from 'moment-timezone'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import GrinCheckbox from 'components/common/GrinCheckbox'
import SelectInput from 'components/common/SelectInput'
import { generateHoursList } from 'utils/dateUtils'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const useStyles = makeStyles(theme => ({
  datePicker: {
    marginBottom: 0
  },
  datePickerContainer: {
    width: 90
  },
  checkboxWrapper: {
    padding: '0px!important'
  },
  timeDropdown: {
    '& .MuiInputBase-root': {
      marginTop: '0 !important'
    },
    '& .MuiSelect-root': {
      paddingLeft: 28
    }
  }
}))

const DuringHours = ({ params, conditionType, onChange }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const practiceTimezone = useSelector(state => state.practiceReducer.details.practiceTimezone)

  const [fromHour, setFromHour] = useState()
  const [tomHour, setToHour] = useState()

  const timeOptions = useMemo(() => generateHoursList(), [])

  const handleDuringHoursChecked = useCallback(
    isChecked => {
      onChange({
        type: conditionType,
        params: {
          ...params,
          withHoursFilter: isChecked
        }
      })
    },
    [conditionType, onChange, params]
  )

  const handleHoursChange = useCallback(
    ({ key, value }) => {
      const timezoneOffset = momenttz().tz(practiceTimezone).format('Z')
      onChange({
        type: conditionType,
        params: {
          ...params,
          [key]: moment(`${moment().startOf('d').format('YYYY-MM-DD')}T${value}${timezoneOffset}`)
            .utc()
            .format()
        }
      })
    },
    [conditionType, onChange, params, practiceTimezone]
  )

  useEffect(() => {
    if (params?.fromHour) {
      setFromHour(momenttz(params?.fromHour).tz(practiceTimezone).format('HH:mm'))
    }
  }, [params?.fromHour, practiceTimezone])

  useEffect(() => {
    if (params?.toHour) {
      setToHour(momenttz(params?.toHour).tz(practiceTimezone).format('HH:mm'))
    }
  }, [params?.toHour, practiceTimezone])

  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item>
        <GrinCheckbox
          checked={params?.withHoursFilter}
          onChange={handleDuringHoursChecked}
          color="var(--border-color-1)"
          className={classes.checkboxWrapper}
        />
      </Grid>
      <Grid item>
        <DazzedParagraph14 strong style={{ opacity: params?.withHoursFilter ? 1 : 0.3, userSelect: 'none' }}>
          {t('pages.accountSettings.automations.conditions.duringTheseHours')}
        </DazzedParagraph14>
      </Grid>
      <Grid item>
        <SelectInput
          disabled={!params?.withHoursFilter}
          placeholder="from"
          style={{ bright: true, thick: true }}
          options={timeOptions}
          value={fromHour}
          onChange={({ value }) => handleHoursChange({ key: 'fromHour', value })}
          keepErrorContainerWhenInactive={false}
          containerClassName={classes.timeDropdown}
        />
      </Grid>
      <Grid item>
        <SelectInput
          disabled={!params?.withHoursFilter}
          placeholder="to"
          style={{ bright: true, thick: true }}
          options={timeOptions}
          value={tomHour}
          onChange={({ value }) => handleHoursChange({ key: 'toHour', value })}
          keepErrorContainerWhenInactive={false}
          containerClassName={classes.timeDropdown}
        />
      </Grid>
    </Grid>
  )
}

export default DuringHours
