import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import { useMediaQuery } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import SelectInput from 'components/common/SelectInput'
import ScanView from 'components/common/ScanView/ScanView'
import Actions from 'actions'
import { trackEvent } from 'utils/analyticsUtils'
import PrimaryButton from 'components/common/buttons/PrimaryButton'
import useScansComparison from 'components/Patients/Timeline/ScanComparison/useScansComparison'
import MissingScanMessage from 'components/Patients/Timeline/ScanComparison/MissingScanMessage'
import STLView from './STLView'
import useTreatmentTracker from '../useTreatmentTracker'
import theme from 'styles/theme'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%'
  },
  media: {
    height: '332px!important',
    borderRadius: '12px!important'
  },
  toggleAlignersButtonContainer: {
    position: 'absolute',
    top: 15,
    left: 15,
    zIndex: 999
  },
  toggleAlignersButton: {
    padding: '6px 10px',
    fontSize: 12,
    fontWeight: 500
  }
}))

const ScanSelection = ({
  scanSelectionOptions = [],
  side,
  scanLinkage,
  onOpenRequestRerunModal,
  onSelectionChanged
}) => {
  const classes = useStyles()
  const videoRef = useRef()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down('md'))

  const { selectedScans } = useScansComparison()

  const { scanIndex, selectedTile, withAligners } = useSelector(
    state => state.treatmentReducer.compareScans[side] || {}
  )

  const selectedScan = useMemo(
    () => (withAligners ? selectedScans[side].withAligners : selectedScans[side].withoutAligners),
    [selectedScans, side, withAligners]
  )
  const selectedWithoutAlignersScan = useMemo(() => selectedScans[side].withoutAligners, [selectedScans, side])
  const { parsedTxTrackerData, archModels, calculatedTreatmentType, endAlignerNumber, initAlignerNumber, scanNumber } =
    useTreatmentTracker({
      grinScanId: selectedWithoutAlignersScan.id
    })

  const updateSelection = useCallback(
    ({ scanIndex: newScanIndex, selectedTile: newSelectedTile, withAligners: newWithAligners }) => {
      dispatch(
        Actions.compareScansSetScanSelection({
          side,
          scanIndex: newScanIndex ?? scanIndex,
          selectedTile: newSelectedTile ?? selectedTile,
          withAligners: newWithAligners ?? withAligners
        })
      )
    },
    [dispatch, side, scanIndex, selectedTile, withAligners]
  )

  const setScanIndex = useCallback(
    scanIndex => {
      trackEvent('Compare Scans - scan selected', { scanIndex, side })
      updateSelection({ scanIndex: scanIndex - 1 })
    },
    [updateSelection, side]
  )

  const setPose = useCallback(
    poseIndex => {
      trackEvent('Compare Scans - set pose', { poseIndex, side })
      updateSelection({ selectedTile: poseIndex })
    },
    [updateSelection, side]
  )

  const toggleWithAligners = useCallback(() => {
    const updatedValue = !withAligners
    trackEvent(`Compare Scans - toggle with/without aligners`, { withAligners: updatedValue ? 'Yes' : 'No', side })
    updateSelection({ withAligners: updatedValue })
  }, [updateSelection, side, withAligners])

  useEffect(() => {
    const video = videoRef.current
    if (!video) {
      return
    }

    video.side = side
    scanLinkage.registerVideo(video)
    return () => scanLinkage.unregisterVideo(video)
  }, [scanLinkage, side])

  return (
    <div container direction="column" spacing={1} className={classes.root}>
      <div class="compare-scans-select-scan">
        <SelectInput
          style={{ bright: true, thick: true }}
          options={scanSelectionOptions}
          onChange={({ value }) => setScanIndex(value)}
          value={scanIndex + 1}
        />
      </div>
      {selectedScan ? (
        <div style={{ height: '90%', width: '100%' }}>
          <div style={{ height: '65%' }}>
            <STLView
              grinScan={selectedWithoutAlignersScan}
              parsedTxTrackerData={parsedTxTrackerData}
              archModels={archModels}
              calculatedTreatmentType={calculatedTreatmentType}
              scanNumber={scanNumber}
              isSmallerScreen={isSmallerScreen}
              initAlignerNumber={initAlignerNumber}
              endAlignerNumber={endAlignerNumber}
              onOpenRequestRerunModal={onOpenRequestRerunModal}
              onSelectionChanged={onSelectionChanged}
            />
          </div>
          <div style={{ maxWidth: 430, position: 'relative' }}>
            <ScanView
              hidePreventive
              grinScanVideo={selectedScan?.video}
              scanSummaryData={selectedScan?.scanSummaryData}
              ref={videoRef}
              selectedOptionIndex={selectedTile}
              onOptionSelect={setPose}
              mediaClassName={classes.media}
            />
            {selectedScan?.scanPairId && (
              <div className={classes.toggleAlignersButtonContainer}>
                <PrimaryButton
                  color="var(--bg-color-10)"
                  width="auto"
                  height="auto"
                  label={
                    withAligners
                      ? t('pages.patients.selectedPatient.timeline.compareScans.withAligners')
                      : t('pages.patients.selectedPatient.timeline.compareScans.withoutAligners')
                  }
                  fontColor="var(--text-color-25)"
                  className={classes.toggleAlignersButton}
                  onClick={toggleWithAligners}
                />
              </div>
            )}
          </div>
        </div>
      ) : (
        <MissingScanMessage
          message={
            withAligners
              ? t('pages.patients.selectedPatient.timeline.compareScans.scanWithAlignersMissing')
              : t('pages.patients.selectedPatient.timeline.compareScans.scanWithoutAlignersMissing')
          }
        />
      )}
    </div>
  )
}

export default ScanSelection
