import React, { useMemo } from 'react'

import { makeStyles } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import UndoIcon from '@material-ui/icons/Undo'
import RedoIcon from '@material-ui/icons/Redo'
import CursorSizeSlider from './CursorSizeSlider'
import ColorPicker from './ColorPicker'
import ActionButton from './ActionButton'
import { useTranslation } from 'react-i18next'
import { Eraser1, Highlighter } from 'components/common/icons'
import { useHotkeys } from 'react-hotkeys-hook'
import { isMacOs } from 'react-device-detect'

const useStyles = ({ canvasWidth }) =>
  makeStyles({
    toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
      width: ({ canvasWidth }) => canvasWidth,
      borderRadius: 0,
      backgroundColor: 'var(--bg-color-4)',
      minWidth: 650
    },
    panel: {
      display: 'flex',
      alignItems: 'center',
      height: 50,
      padding: '0 15px',
      '& svg': {
        fill: 'var(--text-color-1)'
      }
    }
  })({ canvasWidth })

const ButtonsGroup = ({
  canvasWidth,
  drawMode,
  eraseMode,
  drawWidth,
  drawColor,
  isLoading = false,
  defaultLineWidth,
  onEraseMode,
  onClearCanvas,
  onUndoAction,
  onRedoAction,
  onChangeWidth,
  onChangeColor,
  onSaveConfig,
  children,
  showCursorSizeSlider = true,
  sliderClassName = '',
  fullHeightSlider = false
}) => {
  const classes = useStyles({ canvasWidth })
  const colors = ['#FF4444', '#FFBD39', '#4151E6', '#FFFFFF']
  const { t } = useTranslation()

  const triggerKeyCode = useMemo(() => (isMacOs ? 'meta' : 'ctrl'), [])
  const triggerKeyLabel = useMemo(() => (isMacOs ? '⌘' : 'Ctrl'), [])

  useHotkeys(`${triggerKeyCode}+c`, onClearCanvas)
  useHotkeys(`${triggerKeyCode}+z`, onUndoAction)
  useHotkeys(`${triggerKeyCode}+shift+z`, onRedoAction)

  return (
    <>
      {showCursorSizeSlider && (
        <CursorSizeSlider
          drawWidth={drawWidth}
          defaultLineWidth={defaultLineWidth}
          onChangeWidth={onChangeWidth}
          wrapperClassName={sliderClassName}
          fullHeightSlider={fullHeightSlider}
        />
      )}
      <div className={classes.toolbar}>
        <div className={classes.panel}>
          <ColorPicker colors={colors} selectedColor={drawColor} onChangeColor={onChangeColor} />
          <ActionButton
            label={t('dialogs.scanSummary.annotations.erase')}
            selected={eraseMode}
            onClick={() => onEraseMode(true)}
          >
            <Eraser1 color={eraseMode ? 'var(--text-color-10)' : 'var(--text-color-1)'} />
          </ActionButton>
          <ActionButton
            label={t('dialogs.scanSummary.annotations.markup')}
            selected={!eraseMode}
            onClick={() => onEraseMode(false)}
          >
            <Highlighter color={!eraseMode ? 'var(--text-color-10)' : 'var(--text-color-1)'} />
          </ActionButton>
          <ActionButton
            label={t('dialogs.scanSummary.annotations.clear')}
            onClick={onClearCanvas}
            tooltip={`${triggerKeyLabel} + C`}
          >
            <ClearIcon />
          </ActionButton>
          <ActionButton
            label={t('dialogs.scanSummary.annotations.undo')}
            onClick={onUndoAction}
            tooltip={`${triggerKeyLabel} + Z`}
          >
            <UndoIcon />
          </ActionButton>
          <ActionButton
            label={t('dialogs.scanSummary.annotations.redo')}
            onClick={onRedoAction}
            tooltip={`${triggerKeyLabel} + ⇧ + Z`}
          >
            <RedoIcon />
          </ActionButton>
        </div>
        <div className={classes.panel}>{children}</div>
      </div>
    </>
  )
}

export default React.memo(ButtonsGroup)
