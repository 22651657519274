import { makeStyles } from '@material-ui/core'
import React from 'react'
import BaseModal from 'components/common/modals/BaseModal'
import DazzedHeading14 from 'components/common/text/DazzedHeading14'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import MoreThanOneScanImage from './moreThanOneScan.png'

const useStyles = makeStyles({
  container: {
    padding: 20,
    textAlign: 'center',
    width: 530
  },
  titleClassName: {
    fontFamily: 'Dazzed',
    fontSize: 22,
    fontWeight: 'bold'
  },
  moreThanOneScanImage: {
    marginTop: 20,
    height: 130
  }
})

const UploadCompletedModal = ({ isOpen, onClose }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { grinScans } = useSelector(state => state.treatmentReducer)

  return (
    <BaseModal
      className={classes.container}
      open={isOpen}
      primaryLabel={t('general.gotItThanks')}
      onPrimaryBtnClick={onClose}
      titleClassName={classes.titleClassName}
      withCloseIcon={false}
      largerButtons
      title={t('dialogs.patientInfo.txTrackerSetup.uploadCompletedModal.title')}
    >
      <DazzedHeading14 regular>
        <Trans
          i18nKey={`dialogs.patientInfo.txTrackerSetup.uploadCompletedModal.${
            grinScans?.length > 0 ? 'descriptionMoreThanOneScan' : 'descriptionZeroScans'
          }`}
        />
        <img src={MoreThanOneScanImage} className={classes.moreThanOneScanImage} alt="moreThanOneScan" />
      </DazzedHeading14>
    </BaseModal>
  )
}

export default UploadCompletedModal
