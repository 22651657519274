import { Grid, makeStyles } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import { APPLIANCE_SETS_MIN_NUMBER, APPLIANCE_SETS_MAX_NUMBER } from 'consts/treatmentConsts'

const useStyles = ({ error }) =>
  makeStyles({
    alignersSets: {
      outline: 'none',
      fontSize: 16,
      textAlign: 'center',
      height: 32,
      width: 80,
      border: 'none',
      borderBottom: ({ error }) => (error ? '1px solid red' : '1px solid var(--border-color-11)'),
      paddingLeft: 14,
      '@media (max-width: 960px)': {
        paddingLeft: 0
      }
    },
    label: {
      marginRight: 7,
      '@media (max-width: 960px)': {
        marginBottom: 5
      }
    },
    errorMessage: {
      color: 'red',
      fontSize: 12
    }
  })({ error })

const AlignersSets = ({ className = '', alignersSets, setAlignersSets, errorMessage = '' }) => {
  const { t } = useTranslation()
  const classes = useStyles({ error: !!errorMessage })

  return (
    <Grid container alignItems="center" className={className}>
      <Grid item>
        <DazzedParagraph16 className={classes.label}>
          {t('dialogs.patientInfo.scanSettings.totalAlignersSets.label')}
        </DazzedParagraph16>
      </Grid>
      <Grid item>
        <input
          value={alignersSets}
          type="number"
          inputMode="numeric"
          pattern="[0-9]*"
          placeholder={t('dialogs.patientInfo.scanSettings.totalAlignersSets.placeholder')}
          className={classes.alignersSets}
          onChange={({ target }) => setAlignersSets(target.value)}
          min={APPLIANCE_SETS_MIN_NUMBER}
          max={APPLIANCE_SETS_MAX_NUMBER}
        />
      </Grid>
      {errorMessage && (
        <Grid item xs={12}>
          <div className={classes.errorMessage}>{errorMessage}</div>
        </Grid>
      )}
    </Grid>
  )
}

export default AlignersSets
