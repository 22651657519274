import React, { useRef } from 'react'
import TitleWithText from 'components/Patients/PatientGuidelines/PatientBrief/Categories/TitleWithText'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import TeethGridPopup from './TeethGridPopup'
import UntrackedToothGridItem from 'components/common/inputs/TeethSelectionGrid/UntrackedToothGridItem'

const BracesTxTracking = ({ items, note }) => {
  const { t } = useTranslation()

  const untrackedTeethRef = useRef()
  const { untrackedTeeth, wireSequence } = items

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        {untrackedTeeth && (
          <>
            <TitleWithText
              title={`${t(`dialogs.hiPatientGuidelinesViewer.items.untrackedTeeth`)}:`}
              text={<span ref={untrackedTeethRef}>{untrackedTeeth.join(', ')}</span>}
            />
            <TeethGridPopup
              anchorEl={untrackedTeethRef.current}
              teeth={untrackedTeeth}
              toothGridItemComponent={UntrackedToothGridItem}
              offset={{ y: 20, x: 400 }}
            />
          </>
        )}
      </Grid>
      <Grid item>
        {wireSequence && (
          <TitleWithText title={`${t(`dialogs.hiPatientGuidelinesViewer.items.attachments`)}:`} text={wireSequence} />
        )}
      </Grid>
    </Grid>
  )
}

export default BracesTxTracking
