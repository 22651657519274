export const DEFAULT_BACKGROUND_COLOR = 0xeff6ff

export const ViewerModes = {
  Regular: 'regular',
  Occlusal: 'occlusal'
}

export const Poses = {
  Upper: 'upper',
  Lower: 'lower',
  Right: 'right',
  Left: 'left',
  Center: 'center'
}
