import * as React from 'react'

export default ({ color = '#3C52EF' }) => (
  <svg width="15" height="8" viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.75 7L7.94874 1.19874C7.70091 0.950907 7.29909 0.950908 7.05126 1.19874L1.25 7"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
)
