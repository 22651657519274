import { Roles } from 'consts/authConsts'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export const useAllFeatureFlags = () => {
  const { t } = useTranslation()

  return useMemo(
    () => ({
      patientFeatureFlags: [
        {
          key: 'orthoSeenProposal',
          type: 'patient',
          label: t('featureFlags.orthoSeenProposal'),
          availableFor: [Roles.DevAdmin]
        },
        { key: 'rcSeenWelcome', type: 'patient', label: t('featureFlags.rcSeenWelcome') },
        {
          key: 'classifierAvailable',
          type: 'patient',
          label: t('featureFlags.classifierAvailable'),
          availableFor: [Roles.DevAdmin, Roles.OpsAdmin]
        },
        {
          key: 'classifierSettingsAvailable',
          type: 'patient',
          label: t('featureFlags.classifierSettingsAvailable'),
          availableFor: [Roles.DevAdmin]
        },
        {
          key: 'skipForceDetails',
          type: 'patient',
          label: t('featureFlags.skipForceDetails'),
          availableFor: [Roles.DevAdmin]
        },
        {
          key: 'medicalHistory',
          type: 'patient',
          label: t('featureFlags.medicalHistory'),
          availableFor: [Roles.DevAdmin]
        },
        { key: 'careCredit', type: 'patient', label: t('featureFlags.careCredit'), availableFor: [Roles.DevAdmin] },
        {
          key: 'preventiveDiagnosis',
          type: 'patient',
          label: t('featureFlags.preventiveDiagnosis'),
          availableFor: [Roles.DevAdmin, Roles.OpsAdmin]
        },
        {
          key: 'checkNotificationSettingsPopup_newPatients',
          type: 'patient',
          label: t('featureFlags.checkNotificationSettingsPopup_newPatients'),
          availableFor: [Roles.DevAdmin]
        },
        {
          key: 'pixelTwistScopeAvailable',
          type: 'patient',
          label: t('featureFlags.pixelTwistScopeAvailable'),
          availableFor: [Roles.Admin, Roles.Sales]
        },
        {
          key: 'defectiveCameraDevice',
          type: 'patient',
          label: t('featureFlags.defectiveCameraDevice'),
          availableFor: [Roles.Admin, Roles.Sales]
        },
        {
          key: 'messageModificationsAvailable',
          type: 'patient',
          label: t('featureFlags.messageModificationsAvailable'),
          availableFor: [Roles.DevAdmin]
        },
        {
          key: 'liveCallsAvailable',
          type: 'patient',
          label: t('featureFlags.liveCalls'),
          availableFor: [Roles.DevAdmin]
        },
        {
          key: 'waitForCameraReadiness',
          type: 'patient',
          label: t('featureFlags.waitForCameraReadiness'),
          availableFor: [Roles.Admin, Roles.Sales]
        }
      ].sort((a, b) => (a.label > b.label ? 1 : -1)),
      doctorFeatureFlags: [
        {
          key: 'remoteConsultation',
          type: 'doctor',
          label: t('featureFlags.remoteConsultation'),
          availableFor: [Roles.DevAdmin, Roles.OpsAdmin]
        },
        {
          key: 'ortho',
          type: 'doctor',
          label: t('featureFlags.ortho'),
          isPractice: true,
          availableFor: [Roles.DevAdmin]
        },
        {
          key: 'promotionsListA',
          type: 'doctor',
          label: t('featureFlags.promotionsListA'),
          availableFor: [Roles.DevAdmin, Roles.OpsAdmin]
        },
        {
          key: 'promotionsListB',
          type: 'doctor',
          label: t('featureFlags.promotionsListB'),
          availableFor: [Roles.DevAdmin, Roles.OpsAdmin]
        },
        {
          key: 'promotionsListC',
          type: 'doctor',
          label: t('featureFlags.promotionsListC'),
          availableFor: [Roles.DevAdmin, Roles.OpsAdmin]
        },
        {
          key: 'promotionsListD',
          type: 'doctor',
          label: t('featureFlags.promotionsListD'),
          availableFor: [Roles.DevAdmin, Roles.OpsAdmin]
        },
        {
          key: 'medicalHistory',
          type: 'doctor',
          label: t('featureFlags.medicalHistory'),
          isPractice: true,
          availableFor: [Roles.DevAdmin]
        },
        {
          key: 'scanSummaryPreventive',
          type: 'doctor',
          label: t('featureFlags.scanSummaryPreventive'),
          availableFor: [Roles.DevAdmin, Roles.OpsAdmin]
        },
        {
          key: 'autochart',
          type: 'doctor',
          label: t('featureFlags.autochart'),
          isPractice: true,
          availableFor: [Roles.DevAdmin, Roles.OpsAdmin]
        },
        { key: 'intercom', type: 'doctor', label: t('featureFlags.intercom'), availableFor: [Roles.DevAdmin] },
        {
          key: 'oralHygieneNote',
          type: 'doctor',
          label: t('featureFlags.oralHygieneNote'),
          isPractice: true,
          availableFor: [Roles.DevAdmin, Roles.OpsAdmin]
        },
        {
          key: 'viewScanSummaryModelVersion',
          type: 'doctor',
          label: t('featureFlags.viewScanSummaryModelVersion'),
          isPractice: true,
          availableFor: [Roles.DevAdmin, Roles.OpsAdmin]
        },
        {
          key: 'treatmentTracker',
          type: 'doctor',
          label: t('featureFlags.treatmentTracker'),
          isPractice: true,
          availableFor: [Roles.DevAdmin, Roles.OpsAdmin]
        },
        {
          key: 'recordsAppSkipEmail',
          type: 'doctor',
          label: t('featureFlags.recordsAppSkipEmail'),
          isPractice: true,
          availableFor: [Roles.DevAdmin, Roles.OpsAdmin]
        },
        {
          key: 'checkNotificationSettingsPopup_existingPatients',
          type: 'doctor',
          label: t('featureFlags.checkNotificationSettingsPopup_existingPatients'),
          availableFor: [Roles.DevAdmin]
        },
        {
          key: 'hiTools',
          type: 'doctor',
          label: t('featureFlags.hiTools'),
          isPractice: true,
          availableFor: [Roles.Admin, Roles.Sales]
        },
        {
          key: 'txTrackerTxGoals',
          type: 'doctor',
          label: t('featureFlags.txTrackerTxGoals'),
          isPractice: true,
          availableFor: [Roles.DevAdmin]
        },
        {
          key: 'overlayDrawingFF',
          type: 'doctor',
          label: t('featureFlags.overlayDrawingFF'),
          isPractice: true,
          availableFor: [Roles.DevAdmin, Roles.OpsAdmin]
        },
        {
          key: 'practiceAutomations',
          type: 'doctor',
          label: t('featureFlags.practiceAutomations'),
          isPractice: true,
          availableFor: [Roles.DevAdmin, Roles.OpsAdmin]
        },
        {
          key: 'mfa',
          type: 'doctor',
          label: t('featureFlags.mfa'),
          isPractice: true,
          availableFor: [Roles.Admin, Roles.OpsAdmin]
        },
        {
          key: 'dsoInvitePractice',
          type: 'doctor',
          label: t('featureFlags.dsoInvitePractice'),
          isPractice: true,
          availableFor: [Roles.DevAdmin, Roles.OpsAdmin]
        },
        {
          key: 'fixedBitrate',
          type: 'doctor',
          label: t('featureFlags.fixedBitrate'),
          isPractice: true,
          availableFor: [Roles.DevAdmin]
        },
        {
          key: 'recordsAppOffline',
          type: 'doctor',
          label: t('featureFlags.recordsAppOffline'),
          isPractice: true,
          availableFor: [Roles.DevAdmin, Roles.OpsAdmin]
        },
        {
          key: 'createRecordsOnlyPatients',
          type: 'doctor',
          label: t('featureFlags.createRecordsOnlyPatients'),
          isPractice: true,
          availableFor: [Roles.DevAdmin, Roles.OpsAdmin]
        },
        {
          key: 'txTrackerTranslationRotation',
          type: 'doctor',
          label: t('featureFlags.txTrackerTranslationRotation'),
          isPractice: true,
          availableFor: [Roles.DevAdmin, Roles.OpsAdmin]
        },
        {
          key: 'mobileOfflineIndicator',
          type: 'doctor',
          isPractice: true,
          label: t('featureFlags.mobileOfflineIndicator'),
          availableFor: [Roles.Admin, Roles.Sales]
        },
        {
          key: 'paginationIssueFF',
          type: 'doctor',
          label: t('featureFlags.paginationIssueFF'),
          availableFor: [Roles.Admin, Roles.Sales]
        },
        {
          key: 'oralHygieneScore',
          type: 'doctor',
          isPractice: true,
          label: t('featureFlags.oralHygieneScore'),
          availableFor: [Roles.DevAdmin, Roles.OpsAdmin]
        },
        {
          key: 'tagScanForNonHI',
          type: 'doctor',
          isPractice: true,
          label: t('featureFlags.tagScanForNonHI'),
          availableFor: [Roles.Admin, Roles.Sales]
        },
        {
          key: 'recordsAppFocusAreas',
          type: 'doctor',
          isPractice: true,
          label: t('featureFlags.recordsAppFocusAreas'),
          availableFor: [Roles.Admin, Roles.Sales]
        },
        {
          key: 'txTrackerMiniTimeline',
          type: 'doctor',
          isPractice: true,
          label: t('featureFlags.txTrackerMiniTimeline'),
          availableFor: [Roles.DevAdmin]
        },
        {
          key: 'notificationCenter',
          type: 'doctor',
          isPractice: true,
          label: t('featureFlags.notificationCenter'),
          availableFor: [Roles.DevAdmin, Roles.OpsAdmin]
        },
        {
          key: 'grinDesignRefresh',
          type: 'doctor',
          isPractice: true,
          label: t('featureFlags.grinDesignRefresh'),
          availableFor: [Roles.DevAdmin, Roles.OpsAdmin]
        },
        {
          key: 'liveCalls',
          type: 'doctor',
          isPractice: true,
          label: t('featureFlags.liveCalls'),
          availableFor: [Roles.DevAdmin, Roles.OpsAdmin]
        },
        {
          key: 'newMobileDesign',
          type: 'patient',
          label: t('featureFlags.newMobileDesign'),
          availableFor: [Roles.DevAdmin]
        }
      ].sort((a, b) => (a.label > b.label ? 1 : -1))
    }),
    [t]
  )
}
