import * as React from 'react'

const LimitXDays = () => (
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginBottom: -5 }}
  >
    <path d="M0 1C0 0.447715 0.447715 0 1 0H13C13.5523 0 14 0.447715 14 1V2H0V1Z" fill="#527195" />
    <rect x="2" y="5" width="2" height="2" fill="#F9F9FF" />
    <rect x="2" y="9" width="2" height="2" fill="#F9F9FF" />
    <rect x="6" y="5" width="2" height="2" fill="#F9F9FF" />
    <rect x="6" y="9" width="2" height="2" fill="#F9F9FF" />
    <rect x="10" y="5" width="2" height="2" fill="#F9F9FF" />
    <rect x="10" y="9" width="2" height="2" fill="#F9F9FF" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 3H14V4.10474H9.25281L8 5.35754V5H6V7H6.35754L5.10461 8.25287V13H0V3ZM4 5H2V7H4V5ZM2 9H4V11H2V9Z"
      fill="#527195"
    />
    <path
      d="M14.2908 6.10476L17.2674 9.08134L17.2674 13.2909L14.2908 16.2674L10.0813 16.2674L7.10471 13.2909L7.10471 9.08134L10.0813 6.10476L14.2908 6.10476Z"
      fill="#527195"
    />
  </svg>
)

export default LimitXDays
