import { Grid, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { trackEvent } from 'utils/analyticsUtils'
import { setNotificationCenterOpen } from './notificationCenterSlice'
import NotificationCenterContent from './NotificationCenterContent'
import NotificationCenterTabs from './Header/NotificationCenterTabs'
import NotificationCenterTitle from './Header/NotificationCenterTitle'
import CloseButton from './Icons/CloseButton'
import NotificationCenterFilters from './Header/NotificationCenterFilters'

const useStyles = makeStyles(theme => ({
  notificationCenterRoot: {
    minWidth: 'var(--patients-list-min-width)',
    width: 'min(20%, var(--patients-list-max-width))',
    height: '100vh',
    position: 'absolute',
    background: 'white',
    top: 0,
    left: 0,
    boxShadow: '20px 0px 32px 0px rgba(0, 0, 0, 0.25)',
    zIndex: 999,
    overflow: 'hidden',
    transition: 'all 0.15s ease-in-out',
    '&.collapsed': {
      width: 0,
      minWidth: 0,
      padding: 0
    }
  },
  notificationCenterDialogContainer: {
    position: 'relative',
    width: '100%',
    height: '100%'
  },
  closeButton: {
    position: 'absolute',
    top: 5,
    right: 2
  },
  notificationCenterBody: {
    width: '100%',
    height: '100%',
    flexWrap: 'nowrap'
  }
}))

const NotificationCenter = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { isOpen, isBulkActionInProgress } = useSelector(state => state.notificationCenterReducer)

  const handleCloseClicked = useCallback(() => {
    trackEvent(`Notification Center - close clicked`)
    dispatch(setNotificationCenterOpen({ isOpen: false }))
  }, [dispatch])

  return (
    <div className={[classes.notificationCenterRoot, !isOpen ? 'collapsed' : ''].join(' ')}>
      <div className={classes.notificationCenterDialogContainer}>
        <IconButton onClick={handleCloseClicked} className={classes.closeButton} size="small">
          <CloseButton />
        </IconButton>
        <Grid container direction="column" className={classes.notificationCenterBody}>
          <Grid item>
            <NotificationCenterTitle />
          </Grid>
          <Grid item>
            <NotificationCenterTabs isLoading={isBulkActionInProgress} />
          </Grid>
          <Grid item>
            <NotificationCenterFilters />
          </Grid>
          <Grid item style={{ flex: 1, height: 'calc(100vh - 157px)' }}>
            <NotificationCenterContent />
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default NotificationCenter
