import React from 'react'
import { makeStyles } from '@material-ui/core'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import Tooltip from '../Tooltip'

const useButtonStyles = () =>
  makeStyles({
    buttonSection: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '15px 10px',
      cursor: 'pointer'
    },
    label: {
      color: 'var(--text-color-1)',
      marginLeft: 8,
      textShadow: '1px 1px 10px rgba(150, 150, 150, 0.5)'
    },
    selectedLabel: {
      color: 'var(--text-color-10)'
    }
  })()

const ActionButton = ({
  children,
  label = '',
  onClick,
  selected,
  bold = false,
  timer,
  tooltip = '',
  tooltipPlacement = 'bottom',
  className = ''
}) => {
  const classes = useButtonStyles()
  return (
    <Tooltip value={tooltip} placement={tooltipPlacement}>
      <div className={[classes.buttonSection, className].join(' ')} onClick={onClick}>
        {children}
        {label && (
          <DazzedParagraph12 bold={bold} className={[classes.label, selected && classes.selectedLabel].join(' ')}>
            {label}
          </DazzedParagraph12>
        )}
        {timer}
      </div>
    </Tooltip>
  )
}

export default ActionButton
