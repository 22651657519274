import React, { useCallback, useMemo, useRef } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useSelector } from 'react-redux'
import { AsyncStatus } from 'consts'
import ScanFeedSkeleton from 'components/Patients/Timeline/V2/Skeletons/ScanFeedSkeleton'
import GrinScanCard from 'components/Patients/Timeline/V2/ScanFeed/GrinScanCard'
import ScanHeader from 'components/Patients/Timeline/V2/ScanFeed/ScanHeader/ScanHeader'
import useScanFeed from 'components/Patients/Timeline/V2/ScanFeed/useScanFeed'
import { useHotkeys } from 'react-hotkeys-hook'
import { trackEvent } from 'utils/analyticsUtils'
import ScanActionsSkeleton from '../Skeletons/ScanActionsSkeleton'
import EditAlignerNumberModal from './Modals/EditAlignerNumberModal'
import ScanActions from './ScanActions/ScanActions'
import ScanReferralsCard from './ScanReferralsCard/ScanReferralsCard'
import ScanReviewsCard from './ScanReviewsCard/ScanReviewsCard'
import ScanTrackingCard from './ScanTrackingCard'
import CompareScansDialog from 'components/Patients/Timeline/ScanComparison/CompareScansDialog'
import useQuickScanNavigation from './useQuickScanNavigation'
import useSectionAutoScrolling from './useSectionAutoScrolling'
import useFetchLeadScans from './useFetchLeadScans'
import SwitchWithAlignerModal from './Modals/SwitchWithAlignerModal'
import OralRecordsCard from './OralRecordsCard'
import TagScanModal from './Modals/TagScanModal'
import TxTrackerAdminModal from '../TxTrackerAdmin/TxTrackerAdminModal'
import ScanSummaryModal from '../../ScanSummary/ScanSummaryModal/ScanSummaryModal'
import OralScoresCard from './OralScoresCard'
import TxTrackerCard from './TxTrackerCard'
import useGaFlags from 'hooks/useGaFlags'

const useStyles = makeStyles(theme => ({
  scanFeedRoot: {},
  scanActionsContainer: {
    margin: `4px 12px 12px 12px`
  }
}))

const ScanFeed = () => {
  const classes = useStyles()
  const ref = useRef()

  useFetchLeadScans()

  const {
    loadStatus,
    displayedGrinScan,
    isDoubleScan,
    scanNumber,
    timelineItem,
    scanStats,
    nextScanItem,
    previousScanItem,
    grinScanWithAligner,
    grinScanIds,
    trackingDetails,
    scanReviews,
    referrals,
    treatmentTrackerData,
    totalScans,
    urgencyDetails,
    isRecordsAppScan,
    oralRecords,
    withOralScores,
    oralScores,
    withAligner,
    setWithAligner,
    isEditAlignerModalOpen,
    setIsEditAlignerModalOpen,
    isSwitchWithAlignerModalOpen,
    setIsSwitchWithAlignerModalOpen
  } = useScanFeed()

  const { patient, isLoadingPatient } = useSelector(state => state.patientsReducer)
  const { treatment } = useSelector(state => state.treatmentReducer)
  const gaFlags = useGaFlags()

  const analyticsPayload = useMemo(
    () => ({
      patientId: patient?.id,
      program: patient?.user?.program,
      scanNumber,
      grinScanId: displayedGrinScan?.id,
      withAligner,
      timelineV2: true,
      totalDisplayedScans: totalScans,
      source: 'scan-feed'
    }),
    [patient, scanNumber, displayedGrinScan, totalScans, withAligner]
  )

  const { navigateToNextScan, navigateToPreviousScan } = useQuickScanNavigation({
    nextScanTimelineItemId: nextScanItem?.id,
    previousScanTimelineItemId: previousScanItem?.id
  })

  const handleArrowDownClicked = useCallback(() => {
    if (navigateToNextScan()) {
      trackEvent('Scan Feed - up arrow key pressed', analyticsPayload)
    }
  }, [navigateToNextScan, analyticsPayload])

  const handleArrowUpClicked = useCallback(() => {
    if (navigateToPreviousScan()) {
      trackEvent('Scan Feed - down arrow key pressed', analyticsPayload)
    }
  }, [navigateToPreviousScan, analyticsPayload])

  useSectionAutoScrolling({ loadStatus })
  useHotkeys('ArrowUp', handleArrowUpClicked)
  useHotkeys('ArrowDown', handleArrowDownClicked)

  return loadStatus === AsyncStatus.Loading ? (
    <ScanFeedSkeleton />
  ) : (
    <>
      <div className={classes.scanFeedRoot} ref={ref}>
        <div className={classes.scanActionsContainer}>
          {isLoadingPatient ? (
            <ScanActionsSkeleton />
          ) : (
            <ScanActions
              analyticsPayload={analyticsPayload}
              displayedScanId={displayedGrinScan?.id}
              scanNumber={scanNumber}
              timelineItemScanIds={grinScanIds}
              withAligner={withAligner}
            />
          )}
        </div>
        <ScanHeader
          grinScanId={displayedGrinScan.id}
          scanNumber={scanNumber}
          alignerNumber={timelineItem?.payload?.alignerNumber}
          totalAlignerNumber={treatment?.totalApplianceNumber}
          numberOfReviews={scanStats.numberOfReviews}
          numberOfShares={scanStats.numberOfShares}
          scanDate={timelineItem.eventDate}
          hasNextScans={!!nextScanItem?.id}
          navigateToNextScan={navigateToNextScan}
          hasPreviousScans={!!previousScanItem?.id}
          navigateToPreviousScan={navigateToPreviousScan}
          analyticsPayload={analyticsPayload}
          trackStatus={trackingDetails?.trackStatus}
          isRecordsAppScan={isRecordsAppScan}
          urgencyDetails={urgencyDetails}
          withAligner={withAligner}
          onEditAlignerNumberClicked={() => setIsEditAlignerModalOpen(true)}
          onSwitchWithAlignersClicked={() => setIsSwitchWithAlignerModalOpen(true)}
        />
        <GrinScanCard
          patientName={patient?.details?.name}
          scanNumber={scanNumber}
          grinScanId={displayedGrinScan.id}
          videoS3Object={displayedGrinScan.video}
          scanSummaryData={displayedGrinScan.scanSummaryData}
          scanSummaryStatus={displayedGrinScan.scanSummaryStatus}
          isDoubleScan={isDoubleScan}
          withAligner={displayedGrinScan.withAligner}
          setWithAligners={isDoubleScan ? setWithAligner : null}
          analyticsPayload={analyticsPayload}
        />
        {trackingDetails && (
          <ScanTrackingCard
            trackStatus={trackingDetails.trackStatus}
            conditions={trackingDetails.conditions}
            notes={trackingDetails.notes}
          />
        )}
        {gaFlags?.txTrackerCardInTimeline && treatmentTrackerData && (
          <TxTrackerCard treatmentTrackerData={treatmentTrackerData} />
        )}
        {withOralScores && <OralScoresCard oralScores={oralScores} />}
        {oralRecords?.compositeImageSrc && (
          <OralRecordsCard
            compositeImageSrc={oralRecords.compositeImageSrc}
            date={oralRecords.date}
            patientName={patient?.details?.name}
            analyticsPayload={analyticsPayload}
          />
        )}
        {!!scanReviews.length && <ScanReviewsCard scanReviews={scanReviews} analyticsPayload={analyticsPayload} />}
        {!!referrals.length && <ScanReferralsCard referrals={referrals} analyticsPayload={analyticsPayload} />}
      </div>
      {grinScanWithAligner && (
        <EditAlignerNumberModal
          isOpen={isEditAlignerModalOpen}
          setIsOpen={setIsEditAlignerModalOpen}
          grinScanId={grinScanWithAligner.id}
          alignerNumber={timelineItem?.payload?.alignerNumber}
          totalApplianceNumber={treatment?.totalApplianceNumber}
          analyticsPayload={analyticsPayload}
        />
      )}
      <SwitchWithAlignerModal
        isOpen={isSwitchWithAlignerModalOpen}
        setIsOpen={setIsSwitchWithAlignerModalOpen}
        isCurrentlyWithAligner={withAligner}
        isDoubleScan={isDoubleScan}
        analyticsPayload={analyticsPayload}
        grinScanId={displayedGrinScan?.id}
      />
      <CompareScansDialog />
      <ScanSummaryModal />
      <TxTrackerAdminModal />
      <TagScanModal anchorEl={ref.current} scanId={displayedGrinScan?.id} />
    </>
  )
}

export default ScanFeed
