import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import GrinFloatingPopup from 'components/common/menu/GrinFloatingPopup'
import { useDispatch, useSelector } from 'react-redux'
import { trackEvent } from 'utils/analyticsUtils'
import Actions from 'actions'
import TreatmentTrackingPanel from 'components/Patients/Timeline/ScanSummary/TreatmentTracking/TreatmentTrackingPanel'
import { scrollBar } from 'styles/theme'

const useStyles = makeStyles(theme => ({
  root: {
    width: 450,
    height: 580,
    overflowY: 'scroll',
    overflowX: 'hidden',
    ...scrollBar
  },
  tagScanBody: {
    padding: 10
  }
}))

const TagScanModal = ({ anchorEl, scanId }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { isOpen } = useSelector(state => state.hiReducer.tagScanModal)

  const handleClose = useCallback(() => {
    trackEvent('Tag scan modal - Closed')
    dispatch(
      Actions.setTagScanModalOpen({
        isOpen: false
      })
    )
  }, [dispatch])

  return !scanId ? (
    <></>
  ) : (
    <GrinFloatingPopup
      placement="right-start"
      className={classes.root}
      customAnchorEl={anchorEl}
      open={isOpen}
      onClose={handleClose}
      grinPopperProps={{
        modifiers: {
          offset: {
            enabled: true,
            offset: '0, 30'
          }
        }
      }}
    >
      <div className={classes.tagScanBody}>
        <TreatmentTrackingPanel scanId={scanId} />
      </div>
    </GrinFloatingPopup>
  )
}

export default TagScanModal
