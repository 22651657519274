import React, { useCallback, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import GrinMenu from 'components/common/menu/GrinMenu'
import { trackEvent } from 'utils/analyticsUtils'
import { Grid, Slider } from '@material-ui/core'
import { ArrowDropdownDown, ArrowDropdownUp, BaseSTLTooth } from 'components/common/icons'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import GrinMenuItem from 'components/common/menu/GrinMenuItem'
import { useTranslation } from 'react-i18next'
import { BASE_DEFAULT_OPACITY } from './archesViewerConsts'
import { setObjectOpacity } from './archesViewerUtils'
import DazzedParagraph10 from 'components/common/text/DazzedParagraph10'

const useStyles = makeStyles(theme => ({
  arrow: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: 5
  },
  pickerTrigger: {
    cursor: 'pointer'
  },
  label: {
    userSelect: 'none',
    marginBottom: 7
  },
  opacityItem: {
    marginTop: 7,
    borderTop: '1px solid var(--border-color-7)'
  },
  opacityLabel: {
    marginTop: 5,
    color: 'var(--text-color-11)'
  },
  track: {
    color: 'var(--border-color-37)',
    opacity: 1
  },
  rail: {
    color: 'var(--border-color-37)',
    opacity: 1
  },
  mark: {
    color: 'var(--text-color-3)'
  },
  markLabel: {
    color: 'var(--text-color-3)'
  },
  valueLabel: {
    color: 'var(--text-color-3)'
  },
  thumb: {
    color: 'var(--text-color-3)'
  },
  sliderContainer: {
    padding: '0px 7px 0px 7px'
  }
}))

const MIN_SLIDER_LABEL = '0%'
const MAX_SLIDER_LABEL = '100%'

const OriginSTLPicker = ({ selectedOriginModelType, setSelectedOriginModelType, originModelOptions, viewerScene }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [isOpen, setIsOpen] = useState(false)

  const selectedOriginModel = useMemo(
    () => originModelOptions.find(opt => opt.type === selectedOriginModelType),
    [originModelOptions, selectedOriginModelType]
  )

  const handleOpenMenu = useCallback(() => {
    trackEvent('Treatment tracker - Model viewer - Origin STL picker opened')
    setIsOpen(true)
  }, [])

  const handleCloseMenu = useCallback(() => {
    trackEvent('Treatment tracker - Model viewer - Origin STL picker closed')
    setIsOpen(false)
  }, [])

  const handleOptionSelected = useCallback(
    option => {
      trackEvent('Treatment tracker - Model viewer - Origin STL selected', {
        selectedSTL: option.label
      })
      setIsOpen(false)
      setSelectedOriginModelType(option.type)
    },
    [setSelectedOriginModelType]
  )

  const handleOpacityChanged = useCallback(
    newOpacity => {
      const materialOpacity = newOpacity / 100

      // Find the meshs on the viewer scene of the base arches
      const baseMeshs = [...viewerScene.children.filter(child => child.name.includes('Base'))]
      baseMeshs.forEach(mesh => setObjectOpacity({ object: mesh, opacity: materialOpacity }))
    },
    [viewerScene]
  )

  return (
    <GrinMenu
      open={isOpen}
      onClose={handleCloseMenu}
      onOpen={handleOpenMenu}
      transformOrigin={{ vertical: -35, horizontal: 0 }}
      triggerComponent={
        <Grid container alignItems="center" spacing={1} className={classes.pickerTrigger}>
          <Grid item>
            <BaseSTLTooth />
          </Grid>
          <Grid item>
            <DazzedParagraph12 strong className={classes.label}>
              {selectedOriginModel.label}
            </DazzedParagraph12>
          </Grid>
          <Grid item>
            <div className={classes.arrow}>{isOpen ? <ArrowDropdownUp /> : <ArrowDropdownDown />}</div>
          </Grid>
        </Grid>
      }
    >
      <div>
        {originModelOptions.map(option => (
          <GrinMenuItem key={`origin-stl-picker-item-${option.type}`} onClick={() => handleOptionSelected(option)}>
            <DazzedParagraph12 strong>{option.label}</DazzedParagraph12>
          </GrinMenuItem>
        ))}
        <GrinMenuItem className={classes.opacityItem} nonActionable>
          <Grid container direction="column">
            <Grid item>
              <DazzedParagraph12 thickness="bold" className={classes.opacityLabel}>
                {t(`dialogs.treatmentTracker.viewerControls.opacity`)}
              </DazzedParagraph12>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 7 }}>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                wrap="nowrap"
                style={{ minWidth: 140 }}
              >
                <Grid item>
                  <DazzedParagraph10 thickness="thin">{MIN_SLIDER_LABEL} </DazzedParagraph10>
                </Grid>
                <Grid item xs={9} className={classes.sliderContainer}>
                  <Slider
                    size="small"
                    defaultValue={BASE_DEFAULT_OPACITY * 100}
                    aria-label="Opacity"
                    valueLabelDisplay="auto"
                    onChange={(event, value) => handleOpacityChanged(value)}
                    valueLabelFormat={value => value / 100}
                    classes={{
                      thumb: classes.thumb,
                      valueLabel: classes.valueLabel,
                      track: classes.track,
                      rail: classes.rail,
                      mark: classes.mark,
                      markLabel: classes.markLabel,
                      valueLabelCircle: classes.valueLabelCircle
                    }}
                  />
                </Grid>
                <Grid item>
                  <DazzedParagraph10 thickness="thin">{MAX_SLIDER_LABEL} </DazzedParagraph10>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </GrinMenuItem>
      </div>
    </GrinMenu>
  )
}

export default OriginSTLPicker
