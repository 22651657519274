import React, { useCallback, useRef } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import GrinSwitch, { GrinSwitchSizes } from 'components/common/GrinSwitch'
import { useDispatch, useSelector } from 'react-redux'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import { setSelectedPracticeId, toggleUnreadOnly } from 'components/NotificationCenter/notificationCenterSlice'
import { trackEvent } from 'utils/analyticsUtils'
import PracticeSearchV3 from 'components/Search/PracticeSearchV3/PracticeSearchV3'
import NotificationCenterPracticeFilterTrigger from './NotificationCenterPracticeFilterTrigger'
import useRolePermissions from 'hooks/useRolePermissions'

const useStyles = makeStyles(theme => ({
  filtersRoot: {
    padding: '10px 16px 8px 16px'
  }
}))

const NotificationCenterFilters = ({ disableActionButtons = true }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const ref = useRef()

  const { permissions } = useRolePermissions()
  const { filters } = useSelector(state => state.notificationCenterReducer)

  const handleToggleUnreadOnly = useCallback(
    value => {
      trackEvent(`Notification Center - unread only clicked`, { value })
      dispatch(toggleUnreadOnly(value))
    },
    [dispatch]
  )

  const handleSelectPractice = useCallback(
    practice => {
      dispatch(setSelectedPracticeId(practice?.id))
    },
    [dispatch]
  )

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      spacing={1}
      className={classes.filtersRoot}
      ref={ref}
    >
      {permissions.searchByDoctor && (
        <Grid item>
          <PracticeSearchV3
            TriggerComponent={NotificationCenterPracticeFilterTrigger}
            selectedPracticeId={filters.practiceId}
            onSelect={handleSelectPractice}
            analyticsPayload={{ source: 'notification-center' }}
            customAnchorEl={ref}
            transformOrigin={{ vertical: -45, horizontal: 0 }}
          />
        </Grid>
      )}
      <Grid item style={{ flex: 1 }} />
      <Grid item>
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <DazzedParagraph12 thickness="strong">
              {t('dialogs.notificationCenterDialog.quickActions.unreadOnly')}
            </DazzedParagraph12>
          </Grid>
          <Grid item>
            <GrinSwitch
              checked={filters.unreadOnly}
              onClick={e => handleToggleUnreadOnly(e.target.checked)}
              size={GrinSwitchSizes.Small}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default NotificationCenterFilters
