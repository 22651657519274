import { API, graphqlOperation } from 'aws-amplify'
import { push } from 'connected-react-router'
import ROUTES from 'consts/routesConsts'
import { ofType } from 'redux-observable'
import { concat, forkJoin, from, of, mapTo } from 'rxjs'
import { catchError, map, mergeMap, pluck, switchMap, withLatestFrom, filter } from 'rxjs/operators'
import Actions from '../actions'
import { doctorPlansByAccountOwnerId, doctorsByAccountOwnerId, getAccountOwner } from '../graphql/customQueries'
import { createUserNote } from '../graphql/mutations'
import i18n from '../resources/locales/i18n'
import {
  mapDSOToMemberObject,
  mapToMemberObject,
  mapToPracticeMembersCollection
} from '../utils/mappers/doctorsMappers'
import { createNoteMetadata } from 'utils/mappers/noteMappers'
import { Roles } from 'consts/authConsts'
import { mutateEntity } from 'utils/graphqlUtils'

export const requestPracticeMembersEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.REQUEST_PRACTICE_MEMBERS),
    withLatestFrom(state$),
    map(([action, state]) => action.payload?.doctorId || state.practiceReducer.accountOwner.id),
    switchMap(accountOwnerId =>
      forkJoin({
        membersLeads: API.graphql(
          graphqlOperation(doctorPlansByAccountOwnerId, {
            accountOwnerId
          })
        ).then(({ data }) => data?.doctorPlansByAccountOwnerId?.items),
        confirmedMembers: API.graphql(
          graphqlOperation(doctorsByAccountOwnerId, {
            accountOwnerId
          })
        ).then(({ data }) => data?.doctorsByAccountOwnerId?.items),
        accountOwner: API.graphql(
          graphqlOperation(getAccountOwner, {
            id: accountOwnerId
          })
        ).then(({ data }) => data?.getDoctor)
      }).pipe(
        map(mapToPracticeMembersCollection),
        mergeMap(members => of(Actions.practiceMembersReceived(members))),
        catchError(error => of(Actions.practiceMembersFailed(error)))
      )
    )
  )

export const practiceMembersFailedEpic = action$ =>
  action$.pipe(
    ofType(
      Actions.PRACTICE_MEMBERS_FAILED,
      Actions.CANCEL_PRACTICE_MEMBER_INVITATION_FAILED,
      Actions.RESEND_PRACTICE_MEMBER_INVITATION_FAILED,
      Actions.DEACTIVATE_PRACTICE_MEMBER_FAILED,
      Actions.ACTIVATE_PRACTICE_MEMBER_FAILED,
      Actions.CHANGE_PRACTICE_MEMBER_ACCESS_FAILED,
      Actions.DSO_CANCEL_PRACTICE_MEMBER_INVITATION_FAILED,
      Actions.DSO_RESEND_PRACTICE_MEMBER_INVITATION_FAILED,
      Actions.DSO_DEACTIVATE_PRACTICE_MEMBER_FAILED,
      Actions.DSO_ACTIVATE_PRACTICE_MEMBER_FAILED,
      Actions.DSO_CHANGE_PRACTICE_MEMBER_ACCESS_FAILED
    ),
    map(() =>
      Actions.showSnackbar({
        type: `error`,
        text: i18n.t('error.general')
      })
    )
  )

export const cancelPracticeMemberInvitationEpic = action$ =>
  action$.pipe(
    ofType(Actions.CANCEL_PRACTICE_MEMBER_INVITATION),
    map(({ payload }) => payload),
    switchMap(({ id, name }) =>
      from(API.del('grinServerlessApi', `/accounts/v2/practiceMembers/invite/${id}`)).pipe(
        mergeMap(() => of(Actions.cancelPracticeMemberInvitationReceived({ name, id }))),
        catchError(error => of(Actions.cancelPracticeMemberInvitationFailed(error)))
      )
    )
  )

export const dsoCancelPracticeMemberInvitationEpic = action$ =>
  action$.pipe(
    ofType(Actions.DSO_CANCEL_PRACTICE_MEMBER_INVITATION),
    map(({ payload }) => payload),
    switchMap(({ id, name, accountOwnerId }) =>
      from(API.del('grinServerlessApi', `/accounts/v2/practiceMembers/invite/${id}`)).pipe(
        mergeMap(() => of(Actions.dsoCancelPracticeMemberInvitationReceived({ name, id, accountOwnerId }))),
        catchError(error => of(Actions.dsoCancelPracticeMemberInvitationFailed(error)))
      )
    )
  )

export const cancelPracticeMemberInvitationReceivedEpic = action$ =>
  action$.pipe(
    ofType(Actions.CANCEL_PRACTICE_MEMBER_INVITATION_RECEIVED, Actions.DSO_CANCEL_PRACTICE_MEMBER_INVITATION_RECEIVED),
    pluck('payload'),
    map(({ name }) =>
      Actions.showSnackbar({
        type: 'success',
        text: i18n.t('messages.practiceMembers.cancelInvitation', { name })
      })
    )
  )

export const resendPracticeMemberInvitationEpic = action$ =>
  action$.pipe(
    ofType(Actions.RESEND_PRACTICE_MEMBER_INVITATION),
    map(({ payload }) => payload),
    switchMap(({ id, doctorId, firstName, lastName, email }) =>
      from(
        API.put('grinServerlessApi', `/accounts/v2/practiceMembers/invite/${id}`, {
          body: {
            firstName,
            lastName,
            email
          }
        })
      ).pipe(
        mergeMap(() => of(Actions.resendPracticeMemberInvitationReceived({ firstName, lastName, email, id }))),
        catchError(error => of(Actions.resendPracticeMemberInvitationFailed(error)))
      )
    )
  )

export const dsoResendPracticeMemberInvitationEpic = action$ =>
  action$.pipe(
    ofType(Actions.DSO_RESEND_PRACTICE_MEMBER_INVITATION),
    map(({ payload }) => payload),
    switchMap(({ id, doctorId, firstName, lastName, email }) =>
      from(
        API.put('grinServerlessApi', `/accounts/v2/practiceMembers/invite/${id}`, {
          body: {
            firstName,
            lastName,
            email
          }
        })
      ).pipe(
        mergeMap(() => of(Actions.dsoResendPracticeMemberInvitationReceived({ firstName, lastName, email, id }))),
        catchError(error => of(Actions.dsoResendPracticeMemberInvitationFailed(error)))
      )
    )
  )

export const resendPracticeMemberInvitationReceivedEpic = action$ =>
  action$.pipe(
    ofType(Actions.RESEND_PRACTICE_MEMBER_INVITATION_RECEIVED, Actions.DSO_RESEND_PRACTICE_MEMBER_INVITATION_RECEIVED),
    map(({ payload }) => payload),
    map(({ firstName, lastName }) =>
      Actions.showSnackbar({
        type: 'success',
        text: i18n.t('messages.practiceMembers.resendInvitation', { name: `${firstName} ${lastName}` })
      })
    )
  )

export const deactivatePracticeMemberEpic = action$ =>
  action$.pipe(
    ofType(Actions.DEACTIVATE_PRACTICE_MEMBER),
    map(({ payload }) => payload),
    switchMap(practiceMemberId =>
      from(
        API.post('grinServerlessApi', '/accounts/v2/practiceMembers/disable', {
          body: {
            practiceMemberDoctorId: practiceMemberId
          }
        })
      ).pipe(
        mergeMap(({ practiceMember }) => of(Actions.deactivatePracticeMemberReceived(practiceMember))),
        catchError(error => of(Actions.deactivatePracticeMemberFailed(error)))
      )
    )
  )

export const dsoDeactivatePracticeMemberEpic = action$ =>
  action$.pipe(
    ofType(Actions.DSO_DEACTIVATE_PRACTICE_MEMBER),
    map(({ payload }) => payload),
    switchMap(({ memberId, accountOwnerId }) =>
      from(
        API.post('grinServerlessApi', '/accounts/v2/practiceMembers/disable', {
          body: {
            practiceMemberDoctorId: memberId
          }
        })
      ).pipe(
        mergeMap(({ practiceMember }) =>
          of(Actions.dsoDeactivatePracticeMemberReceived({ practiceMember, accountOwnerId }))
        ),
        catchError(error => of(Actions.dsoDeactivatePracticeMemberFailed(error)))
      )
    )
  )

export const dsoDeactivatePracticeMemberReceivedEpic = action$ =>
  action$.pipe(
    ofType(Actions.DSO_DEACTIVATE_PRACTICE_MEMBER_RECEIVED),
    pluck('payload'),
    map(({ practiceMember }) =>
      Actions.showSnackbar({
        type: 'success',
        text: i18n.t('messages.practiceMembers.deactivateUser', { name: practiceMember.name })
      })
    )
  )

export const deactivatePracticeMemberReceivedEpic = action$ =>
  action$.pipe(
    ofType(Actions.DEACTIVATE_PRACTICE_MEMBER_RECEIVED),
    pluck('payload'),
    map(({ name }) =>
      Actions.showSnackbar({
        type: 'success',
        text: i18n.t('messages.practiceMembers.deactivateUser', { name })
      })
    )
  )

export const activatePracticeMemberEpic = action$ =>
  action$.pipe(
    ofType(Actions.ACTIVATE_PRACTICE_MEMBER),
    map(({ payload }) => payload),
    switchMap(practiceMemberId =>
      from(
        API.post('grinServerlessApi', '/accounts/v2/practiceMembers/activate', {
          body: {
            practiceMemberDoctorId: practiceMemberId
          }
        })
      ).pipe(
        mergeMap(({ practiceMember }) => of(Actions.activatePracticeMemberReceived(practiceMember))),
        catchError(error => of(Actions.activatePracticeMemberFailed(error)))
      )
    )
  )

export const dsoActivatePracticeMemberEpic = action$ =>
  action$.pipe(
    ofType(Actions.DSO_ACTIVATE_PRACTICE_MEMBER),
    map(({ payload }) => payload),
    switchMap(({ memberId, accountOwnerId }) =>
      from(
        API.post('grinServerlessApi', '/accounts/v2/practiceMembers/activate', {
          body: {
            practiceMemberDoctorId: memberId
          }
        })
      ).pipe(
        mergeMap(({ practiceMember }) =>
          of(Actions.dsoActivatePracticeMemberReceived({ practiceMember, accountOwnerId }))
        ),
        catchError(error => of(Actions.dsoActivatePracticeMemberFailed(error)))
      )
    )
  )

export const activatePracticeMemberReceivedEpic = action$ =>
  action$.pipe(
    ofType(Actions.ACTIVATE_PRACTICE_MEMBER_RECEIVED),
    pluck('payload'),
    map(({ name }) =>
      Actions.showSnackbar({
        type: 'success',
        text: i18n.t('messages.practiceMembers.activateUser', { name })
      })
    )
  )

export const dsoActivatePracticeMemberReceivedEpic = action$ =>
  action$.pipe(
    ofType(Actions.DSO_ACTIVATE_PRACTICE_MEMBER_RECEIVED),
    pluck('payload'),
    map(({ practiceMember }) =>
      Actions.showSnackbar({
        type: 'success',
        text: i18n.t('messages.practiceMembers.activateUser', { name: practiceMember.name })
      })
    )
  )

export const invitePracticeMemberEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.INVITE_PRACTICE_MEMBER),
    withLatestFrom(state$),
    map(([action, state]) => ({
      memberInput: action.payload,
      accountOwnerId: state.practiceReducer.accountOwner.id
    })),
    switchMap(({ memberInput, accountOwnerId }) =>
      from(
        API.post('grinServerlessApi', '/accounts/v2/practiceMembers/invite', {
          body: {
            ...memberInput,
            accountOwnerId
          }
        })
      ).pipe(
        mergeMap(({ newMember }) => of(Actions.invitePracticeMemberReceived(newMember))),
        catchError((err, caught) => of(Actions.invitePracticeMemberFailed(err?.response?.data)))
      )
    )
  )

export const invitePracticeMemberSuccessEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.INVITE_PRACTICE_MEMBER_RECEIVED),
    pluck('payload'),
    switchMap(member =>
      concat(
        of(
          Actions.showSnackbar({
            type: `success`,
            text: i18n.t('pages.accountSettings.practiceMembers.addPracticeMember.addPracticeMemberSuccess', {
              name: `${member.firstName} ${member.lastName}`,
              accessType: member.accessType
            })
          })
        ),
        of(push(ROUTES.PRACTICE_MEMBERS))
      )
    )
  )

export const invitePracticeMemberFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.INVITE_PRACTICE_MEMBER_FAILED),
    pluck('payload'),
    map(err =>
      err?.code === 'userAlreadyExists'
        ? Actions.practiceMemberErrorsReceived({
            email: i18n.t('pages.accountSettings.practiceMembers.addPracticeMember.userAlreadyExist')
          })
        : Actions.showSnackbar({
            type: `error`,
            text: i18n.t('error.general')
          })
    )
  )

export const dsoInvitePracticeMemberEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.DSO_INVITE_PRACTICE_MEMBER),
    withLatestFrom(state$),
    map(([action, state]) => ({
      memberInput: action.payload.memberInput,
      accountOwnerId: action.payload.practiceAccountOwnerId
    })),
    switchMap(({ memberInput, accountOwnerId }) =>
      from(
        API.post('grinServerlessApi', '/accounts/v2/practiceMembers/invite', {
          body: {
            ...memberInput,
            accountOwnerId
          }
        })
      ).pipe(
        mergeMap(({ newMember }) =>
          of(Actions.dsoInvitePracticeMemberReceived({ ...mapToMemberObject(newMember), accountOwnerId }))
        ),
        catchError((err, caught) => of(Actions.dsoInvitePracticeMemberFailed(err?.response?.data)))
      )
    )
  )

export const dsoInvitePracticeMemberSuccessEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.DSO_INVITE_PRACTICE_MEMBER_RECEIVED),
    pluck('payload'),
    switchMap(member =>
      concat(
        of(
          Actions.showSnackbar({
            type: `success`,
            text: i18n.t('pages.accountSettings.practiceMembers.addPracticeMember.addPracticeMemberSuccess', {
              name: `${member.firstName} ${member.lastName}`,
              accessType: member.accessType
            })
          })
        ),
        of(push(ROUTES.DSO_MEMBERS))
      )
    )
  )

export const dsoInvitePracticeMemberFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.DSO_INVITE_PRACTICE_MEMBER_FAILED),
    pluck('payload'),
    map(err =>
      err?.code === 'userAlreadyExists'
        ? Actions.practiceMemberErrorsReceived({
            email: i18n.t('pages.accountSettings.practiceMembers.addPracticeMember.userAlreadyExist')
          })
        : Actions.showSnackbar({
            type: `error`,
            text: i18n.t('error.general')
          })
    )
  )

export const inviteNewDSOEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.INVITE_NEW_DSO),
    withLatestFrom(state$),
    map(([action, state]) => ({
      email: action.payload.email,
      name: `${action.payload.firstName} ${action.payload.lastName}`,
      grinGroupKey: JSON.parse(state.profileReducer.doctor.user.allowed_groups_permissions || '[]')?.[0]?.groupKey
    })),
    switchMap(payload =>
      from(
        API.post('grinServerlessApi', `/accounts/v2/doctors/${Roles.DsoSupport}`, {
          body: payload
        })
      ).pipe(
        mergeMap(({ doctor: newMember }) => of(Actions.inviteNewDSOReceived(mapDSOToMemberObject(newMember)))),
        catchError(err => of(Actions.inviteNewDSOFailed(err?.response?.data)))
      )
    )
  )

export const inviteNewDSOReceivedEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.INVITE_NEW_DSO_RECEIVED),
    pluck('payload'),
    switchMap(member =>
      concat(
        of(
          Actions.showSnackbar({
            type: `success`,
            text: i18n.t('pages.accountSettings.practiceMembers.addPracticeMember.addPracticeMemberSuccess', {
              name: `${member.firstName} ${member.lastName}`,
              accessType: member.accessType
            })
          })
        ),
        of(push(ROUTES.DSO_MEMBERS))
      )
    )
  )

export const inviteNewDSOFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.INVITE_NEW_DSO_FAILED),
    pluck('payload'),
    map(err =>
      err?.code === 'userAlreadyExists'
        ? Actions.practiceMemberErrorsReceived({
            email: i18n.t('pages.accountSettings.practiceMembers.addPracticeMember.userAlreadyExist')
          })
        : Actions.showSnackbar({
            type: `error`,
            text: i18n.t('error.general')
          })
    )
  )

export const changePracticeMemberAccessEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.CHANGE_PRACTICE_MEMBER_ACCESS),
    withLatestFrom(state$),
    map(([action, state]) => ({ practiceMemberDoctorId: action.payload.id, newAccessType: action.payload.accessType })),
    switchMap(({ practiceMemberDoctorId, newAccessType }) =>
      from(
        API.put('grinServerlessApi', `/accounts/v2/practiceMembers/accessType`, {
          body: {
            practiceMemberDoctorId,
            newAccessType
          }
        })
      ).pipe(
        mergeMap(({ practiceMember }) => of(Actions.changePracticeMemberAccessReceived(practiceMember))),
        catchError(err => of(Actions.changePracticeMemberAccessFailed(err)))
      )
    )
  )

export const dsoChangePracticeMemberAccessEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.DSO_CHANGE_PRACTICE_MEMBER_ACCESS),
    pluck('payload'),
    switchMap(({ memberId, accessType, accountOwnerId }) =>
      from(
        API.put('grinServerlessApi', `/accounts/v2/practiceMembers/accessType`, {
          body: {
            practiceMemberDoctorId: memberId,
            newAccessType: accessType
          }
        })
      ).pipe(
        mergeMap(({ practiceMember }) =>
          of(Actions.dsoChangePracticeMemberAccessReceived({ practiceMember, accountOwnerId }))
        ),
        catchError(err => of(Actions.dsoChangePracticeMemberAccessFailed(err)))
      )
    )
  )

export const changePracticeMemberAccessRecievedEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.CHANGE_PRACTICE_MEMBER_ACCESS_RECEIVED),
    pluck('payload'),
    switchMap(practiceMember =>
      of(
        Actions.showSnackbar({
          type: `success`,
          text: i18n.t('pages.accountSettings.practiceMembers.addPracticeMember.changePracticeMemberAccessSuccess', {
            name: `${practiceMember.firstName} ${practiceMember.lastName}`,
            accessType: practiceMember.accessType
          })
        })
      )
    )
  )

export const dsoChangePracticeMemberAccessRecievedEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.DSO_CHANGE_PRACTICE_MEMBER_ACCESS_RECEIVED),
    pluck('payload'),
    switchMap(({ practiceMember }) =>
      of(
        Actions.showSnackbar({
          type: `success`,
          text: i18n.t('pages.accountSettings.practiceMembers.addPracticeMember.changePracticeMemberAccessSuccess', {
            name: `${practiceMember.firstName} ${practiceMember.lastName}`,
            accessType: practiceMember.accessType
          })
        })
      )
    )
  )

export const onPracticeDetailsNotificationReceivedEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.NOTIFICATION_RECEIVED),
    withLatestFrom(state$),
    map(([action, state]) => ({
      notification: action.payload,
      accountOwnerId: state.practiceReducer.accountOwner.id
    })),
    filter(
      ({ notification, accountOwnerId }) =>
        notification.entityType === 'Doctor' &&
        notification.method === 'MODIFY' &&
        notification.entityId === accountOwnerId
    ),
    mergeMap(() => of(Actions.requestDoctorDetails()))
  )

export const assignPatientToPracticeMemberEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.ASSIGN_PATIENT_TO_PRACTICE_MEMBER),
    withLatestFrom(state$),
    map(([action, state]) => ({
      patientId: action.payload.patientId,
      assigneeId: action.payload.assigneeId,
      tag: action.payload.tag,
      note: action.payload.note
    })),
    switchMap(({ patientId, assigneeId, tag, note }) =>
      from(
        API.post('grinServerlessApi', '/treatments/v2/assignees', {
          body: {
            patientId,
            assigneeId,
            tag,
            noteId: note?.id
          }
        })
      ).pipe(
        mergeMap(results => of(Actions.assignPatientToPracticeMemberReceived())),
        catchError(error => of(Actions.assignPatientToPracticeMemberFailed(error)))
      )
    )
  )

export const unassignPracticeMemberEpic = action$ =>
  action$.pipe(
    ofType(Actions.UNASSIGN_PRACTICE_MEMBER_FROM_PATIENT),
    pluck('payload'),
    switchMap(({ patientId }) =>
      from(API.del('grinServerlessApi', `/treatments/v2/assignees/${patientId}`)).pipe(
        mergeMap(() => of(Actions.unassignPracticeMemberFromPatientReceived())),
        catchError(err => of(Actions.unassignPracticeMemberFromPatientReceived(err)))
      )
    )
  )

export const assignPatientToPracticeMemberSuccess = action$ =>
  action$.pipe(
    ofType(Actions.ASSIGN_PATIENT_TO_PRACTICE_MEMBER_RECEIVED, Actions.UNASSIGN_PRACTICE_MEMBER_FROM_PATIENT_RECEIVED),
    mapTo(
      Actions.showSnackbar({
        type: 'success',
        text: i18n.t('messages.practiceMembers.assignedPatientSuccessfully')
      })
    )
  )

export const assignPatientToPracticeMemberFailed = action$ =>
  action$.pipe(
    ofType(Actions.ASSIGN_PATIENT_TO_PRACTICE_MEMBER_FAILED, Actions.UNASSIGN_PRACTICE_MEMBER_FROM_PATIENT_FAILED),
    mapTo(
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('messages.practiceMembers.assignPatientError')
      })
    )
  )

export const addNoteToPracticeMemberAssigneeEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.ASSIGN_PATIENT_TO_PRACTICE_MEMBER),
    withLatestFrom(state$),
    map(([action, state]) => ({
      patientUserId: action.payload.patientUserId,
      note: action.payload.note,
      hasNewDesignFF: action.payload.hasNewDesignFF,
      metadata: createNoteMetadata({ state, assigneeId: action.payload.assigneeId })
    })),
    filter(({ note, hasNewDesignFF }) => !!note && !hasNewDesignFF),
    switchMap(({ patientUserId, note, metadata }) =>
      from(
        API.graphql(
          graphqlOperation(createUserNote, {
            input: {
              id: note.id,
              grinUserId: patientUserId,
              body: note.noteText.trim(),
              a_doctor: note.a_doctor,
              metadata
            }
          })
        )
      ).pipe(
        map(response => response.data.createUserNote),
        mergeMap(note => of(Actions.createPatientNoteReceived(note))),
        catchError(error => of(Actions.createPatientNoteFailed(error)))
      )
    )
  )

export const setAssigneeSeenTagEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.SET_ASSIGNEE_SEEN_TAG),
    withLatestFrom(state$),
    map(([action, state]) => ({
      patientTag: action.payload.patientTag
    })),
    switchMap(({ patientTag }) => {
      const parsedMetadata = JSON.parse(patientTag?.metadata || '{}')
      parsedMetadata.unreadIndication = false
      const patientTagMetadata = JSON.stringify(parsedMetadata)

      return from(
        mutateEntity({
          id: patientTag.id,
          entityType: 'PatientTag',
          input: { metadata: patientTagMetadata },
          fields: ['_version', 'metadata']
        })
      ).pipe(mergeMap(updatedFields => of(Actions.setAssigneeSeenTagSucceded({ id: patientTag.id, ...updatedFields }))))
    })
  )
