export const OralScores = {
  LowRisk: 'low-risk',
  MediumRisk: 'medium-risk',
  HighRisk: 'high-risk',
  Poor: 'poor',
  Fair: 'fair',
  Good: 'good',
  Excellent: 'excellent',
  Bad: 'bad',
  Average: 'average',
  Best: 'best'
}

export const OralScoreCategories = {
  OralScore: 'oralScore',
  PerioRisk: 'perioRisk',
  DentalRisk: 'dentalRisk',
  Hygiene: 'hygiene'
}

/**
 * A mapping between the numeric "perio risk" and "dental risk" values generated by the model to OralScores enum.
 */
export const RiskValuekMapping = {
  1: OralScores.LowRisk,
  2: OralScores.MediumRisk,
  3: OralScores.HighRisk
}

/**
 * A mapping between the numeric "hygiene score" values generated by the model to OralScores enum.
 */
export const HygieneScoreValueMapping = {
  1: OralScores.Excellent,
  2: OralScores.Good,
  3: OralScores.Fair,
  4: OralScores.Poor
}

/**
 * A mapping between the aggregated "oral score" numeric value to OralScores enum.
 */
export const OralScoreValueMapping = {
  1: OralScores.Best,
  2: OralScores.Average,
  3: OralScores.Bad
}

const ColorCodes = {
  GreenA: 'rgba(38, 195, 111, 1)',
  GreenB: 'rgba(160, 203, 127, 1)',
  Yellow: 'rgba(255, 189, 57, 1)',
  Red: 'rgba(235, 87, 87, 1)'
}

export const OralScoreColorCodes = {
  [OralScores.LowRisk]: ColorCodes.GreenA,
  [OralScores.MediumRisk]: ColorCodes.Yellow,
  [OralScores.HighRisk]: ColorCodes.Red,
  [OralScores.Poor]: ColorCodes.Red,
  [OralScores.Fair]: ColorCodes.Yellow,
  [OralScores.Good]: ColorCodes.GreenB,
  [OralScores.Excellent]: ColorCodes.GreenA,
  [OralScores.Bad]: ColorCodes.Red,
  [OralScores.Average]: ColorCodes.Yellow,
  [OralScores.Best]: ColorCodes.GreenA
}

export const OralScoreBackgroundColors = {
  [OralScores.Bad]: 'rgba(252, 230, 230, 1)',
  [OralScores.Average]: 'rgba(255, 248, 235, 1)',
  [OralScores.Best]: 'rgba(222, 246, 233, 1)',
  [OralScores.Poor]: 'rgba(252, 230, 230, 1)',
  [OralScores.Fair]: 'rgba(255, 248, 235, 1)',
  [OralScores.Good]: 'rgba(236, 245, 229, 1)',
  [OralScores.Excellent]: 'rgba(222, 246, 233, 1)'
}
