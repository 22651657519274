import { CircularProgress, Grid, makeStyles } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import useRolePermissions from 'hooks/useRolePermissions'
import useShowSnackbar from 'hooks/useShowSnackbar'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useFetchNotesQuery } from 'store/modules/PatientNotes'
import NoteInput from './NoteInput'
import NotesEmptyState from './NotesEmptyState'
import NotesList from './NotesList'

const useStyles = makeStyles({
  notesRoot: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: 20,
    height: '100%',
    backgroundColor: 'white'
  },
  loaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
})

const Notes = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { permissions } = useRolePermissions()

  const { patient } = useSelector(state => state.patientsReducer)
  const {
    data: notes,
    isLoading,
    isError
  } = useFetchNotesQuery({ patientGrinUserId: patient?.user?.id }, { skip: !patient?.user?.id })

  const noNotes = useMemo(() => notes?.length === 0, [notes])

  useShowSnackbar({ isOpen: isError, message: t('pages.patients.patientSwitchableView.notes.fetchNotesError') })

  const [note, setNote] = useState('')

  return isLoading ? (
    <Grid container alignItems="center" justifyContent="center" style={{ height: '100%', backgroundColor: 'white' }}>
      <Grid item>
        <CircularProgress size={30} />
      </Grid>
    </Grid>
  ) : (
    <div className={classes.notesRoot}>
      <DazzedParagraph14 bold color="var(--text-color-18)">
        {t('pages.patients.patientSwitchableView.notes.title')}
      </DazzedParagraph14>
      {noNotes ? <NotesEmptyState /> : <NotesList notes={notes} />}
      {permissions.addNotes && <NoteInput note={note} setNote={setNote} />}
    </div>
  )
}

export default Notes
