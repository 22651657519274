import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import GrinCheckbox from 'components/common/GrinCheckbox'
import { NightTimeOnlyIcon } from 'components/common/icons'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { Grid } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  label: {
    fontSize: 14,
    fontWeight: 500,
    paddingLeft: 5,
    userSelect: 'none'
  },
  checkbox: {
    padding: 0
  },
  divider: {
    width: '100%',
    backgroundColor: '#CED0D4',
    height: 1,
    margin: '12px 0px 8px 0px',
    opacity: 0.5
  }
}))

const NightTimeOnly = ({ onChange, checked, viewMode }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <>
      <div className={classes.divider} />
      {viewMode ? (
        checked && (
          <Grid container alignItems="center">
            <Grid item style={{ marginRight: 3 }}>
              <NightTimeOnlyIcon />
            </Grid>
            <Grid item>
              <DazzedParagraph14 strong>
                {t('dialogs.patientBrief.categories.elastics.nightTimeOnlyLabel')}
              </DazzedParagraph14>
            </Grid>
          </Grid>
        )
      ) : (
        <GrinCheckbox
          size="small"
          captionClassName={classes.label}
          className={classes.checkbox}
          onChange={onChange}
          variant="filled"
          checked={checked}
          caption={t('dialogs.patientBrief.categories.elastics.nightTimeOnlyLabel')}
          radioBorderColor="var(--border-color-36)"
        />
      )}
    </>
  )
}

export default NightTimeOnly
