import * as React from 'react'

export default () => (
  <svg width="25" height="23" viewBox="0 0 25 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="7.5" cy="4.5" r="3.5" stroke="#001335" />
    <path d="M14 14.5C14 10.9101 11.0899 8 7.5 8C3.91015 8 1 10.9101 1 14.5" stroke="#001335" />
    <circle cx="17" cy="15" r="7" fill="#001335" stroke="white" strokeWidth="2" />
    <path
      d="M18.5324 14.1086C19.1123 13.9886 19.6853 13.8362 20.2488 13.6521C19.912 11.0289 17.1344 11 17.1344 11C15.9911 11 15.0516 11.3957 14.3161 12.187C13.6054 12.9564 13.25 13.9042 13.25 15.0304C13.25 16.1456 13.5926 17.0731 14.2777 17.8128C15.0135 18.6043 15.9962 19 17.2259 19C17.9038 19 18.5168 18.9227 19.0649 18.7681C19.6131 18.6135 20.0098 18.459 20.255 18.3044L20.2587 14.8851C20.2588 14.869 20.2553 14.8531 20.2483 14.8386C20.2413 14.8242 20.2311 14.8116 20.2185 14.8019C20.2058 14.7923 20.1912 14.7858 20.1756 14.7829C20.1601 14.7801 20.1441 14.781 20.129 14.7857C19.6913 14.9248 18.6092 15.2267 17.4044 15.4173V16.1158C17.8118 16.1235 18.0823 16.1603 18.2158 16.2264C18.4213 16.3332 18.6148 16.5576 18.6148 16.8999V17.2314C18.6154 17.3366 18.5953 17.4409 18.5557 17.538C18.5161 17.6351 18.4578 17.7231 18.3842 17.7967C18.1999 17.9785 17.9045 18.1005 17.4322 18.1005C16.5593 18.1005 15.8404 17.6957 15.579 17.0838C15.2652 16.3497 15.3053 15.9458 15.3053 15.0029C15.3053 13.8545 15.3132 13.4683 15.6799 12.8576C15.9766 12.3629 16.5547 12.1325 17.0535 12.1475C17.0535 12.1475 17.9051 12.0711 18.2995 12.8458C18.5811 13.4002 18.5324 14.1086 18.5324 14.1086Z"
      fill="white"
    />
  </svg>
)
