import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'

const useStyles = makeStyles(theme => ({
  actionContainer: {
    padding: 10
  }
}))

const GenericGuidelineAction = ({ icon, text }) => {
  const classes = useStyles()

  return (
    <Grid container alignItems="center" spacing={1} wrap="nowrap" className={classes.actionContainer}>
      <Grid item xs={2}>
        {icon}
      </Grid>
      <Grid item xs={10}>
        <DazzedParagraph14 strong>{text}</DazzedParagraph14>
      </Grid>
    </Grid>
  )
}

export default GenericGuidelineAction
