import { Roles } from 'consts/authConsts'
import { TagTypeToQueryType } from 'consts/filterConsts'
import { isEmpty } from 'lodash'
import { isUserOfRole, isUserOfAnyAdminRole } from 'utils/authUtils'

export const createPatientsListFilter = ({ isAdmin, doctorId, filter, allowedGroups }) => {
  let queryFilter = {}
  const FILTER_OPERATOR = 'eq'

  let initialFilter = {
    ...(isUserOfRole(['Admins']) || !allowedGroups?.length
      ? {}
      : buildReadersFilter(allowedGroups, 'PatientSearchModel')),
    ...(isUserOfAnyAdminRole()
      ? {
          program: { ne: 'whitening' }
        }
      : {
          doctorId: { eq: doctorId },
          statusType: { ne: 'archived' }
        })
  }

  if (filter?.tagsFilter || !!filter?.searchFilter) {
    queryFilter = buildPatientsFilter(
      { filterData: filter?.tagsFilter, operator: FILTER_OPERATOR },
      'and',
      filter?.searchFilter
    )
  }

  if (filter?.tagsFilter?.status?.includes('archived')) {
    delete initialFilter.statusType
  }

  let result = { ...initialFilter, ...queryFilter }

  if (isUserOfAnyAdminRole() && filter?.doctorFilter?.length) {
    result.and = [
      ...(result.and || []),
      {
        or: filter.doctorFilter.map(doctorId => ({
          doctorId: { eq: doctorId }
        }))
      }
    ]
  }

  return result
}

const isAllowedForMultiGroups = allowedGroups => allowedGroups.length > 0

export const buildPatientsFilter = (filter, operation = 'and', searchFilter) => {
  let queryFilter = {}
  queryFilter[operation] = []

  if (filter.filterData) {
    const { filterData, operator } = filter

    Object.keys(filterData).forEach(filterType => {
      if (filterData[filterType].length > 0) {
        let typeFilter = { or: [] }
        const newTagType = TagTypeToQueryType[filterType]

        filterData[filterType].forEach(filterValue => {
          if (filterType === 'assignee' && filterValue === 'Unassigned') {
            // Check whether isAssigned is either null or false
            typeFilter['or'].push({ and: [{ isAssigned: { ne: false } }, { isAssigned: { ne: true } }] })
            typeFilter['or'].push({ isAssigned: { eq: false } })
          } else if (filterType === 'chat' && filterValue === 'unread') {
            typeFilter['or'].push({ isChatRoomResolved: { eq: false } })
          } else if (filterType === 'scan' && filterValue === 'pendingReview') {
            typeFilter['or'].push({ trackingStatus: { eq: 'pending' } })
          } else {
            typeFilter['or'].push({ [newTagType]: { [operator]: filterValue } })
          }
        })
        queryFilter[operation].push(typeFilter)
      }
    })
  }

  if (searchFilter) {
    if (!queryFilter['or']) {
      queryFilter['or'] = []
    }

    const searchKeywords = searchFilter.split(' ')

    queryFilter['or'] = [
      ...queryFilter['or'],
      {
        // Breaking the searchFilter into words and matching each one of them brings up better results
        and: searchKeywords.map(word => ({
          name: { wildcard: `*${word}*` }
        }))
      },
      {
        name: { matchPhrasePrefix: searchFilter }
      },
      {
        email: { matchPhrasePrefix: searchFilter }
      },
      {
        communicationEmails: { matchPhrasePrefix: searchFilter }
      },
      {
        id: { eq: searchFilter }
      }
    ]
  }

  if (isEmpty(queryFilter[operation]) && (!queryFilter['or'] || queryFilter['or']?.length === 0)) {
    return {}
  }

  return queryFilter
}

export const buildReadersFilter = (allowedGroups = [], entityType, operator = 'eq') => ({
  or: allowedGroups.map(group => ({
    a_readers: {
      [operator]: `${entityType}_${group.groupKey}_R`
    }
  }))
})

export const and = (expressions = []) => {
  const nonEmptyExpressions = expressions.filter(exp => !isEmpty(exp))
  return isEmpty(nonEmptyExpressions)
    ? {}
    : {
        and: nonEmptyExpressions
      }
}

export const buildPatientSearchFilter = (filter, doctorUsername, allowedGroups) => {
  const generateExtraFilter = (isAdmin, allowedGroups, doctorUsername) => {
    if (isAdmin) {
      return {}
    }

    if (isAllowedForMultiGroups(allowedGroups)) {
      return buildReadersFilter(allowedGroups, 'PatientSearchModel')
    }

    return { doctorUsername: { eq: doctorUsername } }
  }

  const isAdmin = isUserOfRole([Roles.Admin, Roles.DevAdmin])
  const extraFilter = generateExtraFilter(isAdmin, allowedGroups, doctorUsername)

  return {
    or: [
      {
        name: { matchPhrasePrefix: filter },
        ...extraFilter
      },
      {
        email: { matchPhrasePrefix: filter },
        ...extraFilter
      },
      {
        id: { eq: filter }
      },
      {
        username: { eq: filter }
      }
    ]
  }
}

export const buildDoctorsSearchFilter = ({ filterTerm, groups, doctorIds = [], includeMembers = true }) => {
  let filterBase = includeMembers
    ? {}
    : {
        and: [
          !includeMembers
            ? {
                accessType: { eq: 'owner' },
                roles: {
                  eq: Roles.AccountOwners
                }
              }
            : {}
        ]
      }

  if (filterTerm) {
    filterBase = {
      ...filterBase,
      or: [
        {
          name: {
            matchPhrasePrefix: filterTerm
          }
        },
        {
          email: {
            matchPhrasePrefix: filterTerm
          }
        },
        {
          practiceName: {
            matchPhrasePrefix: filterTerm
          }
        },
        {
          id: { eq: filterTerm }
        },
        {
          username: { eq: filterTerm }
        }
      ]
    }
  }

  if (!groups) {
    return filterBase
  }

  const finalFilter = and([
    filterBase,
    {
      or: groups.map(group => ({
        a_readers: {
          eq: `DoctorSearchModel_${group}_R`
        }
      }))
    }
  ])

  if (doctorIds?.length) {
    finalFilter.and.push({
      or: doctorIds.map(doctorId => ({
        doctorId: { eq: doctorId }
      }))
    })
  }

  return finalFilter
}
