import Actions from 'actions'
import { AsyncStatus } from 'consts'
import { ScanTrackingStatus, TrackingConditions, TrackingConditionsTypes, TreatmentTrackOptions } from 'consts/giConsts'
import useRolePermissions from 'hooks/useRolePermissions'
import { isEmpty } from 'lodash'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { trackEvent } from 'utils/analyticsUtils'
import { sortTeeth } from 'utils/giUtils'
import { logWarn } from 'utils/logUtils'

export default ({ scanId }) => {
  const dispatch = useDispatch()

  const { status: submissionStatus } = useSelector(state => state.giReducer.scanTracking)
  const { grinScans, treatment } = useSelector(state => state.treatmentReducer)

  const [trackStatus, setTrackStatus] = useState('')
  const [notes, setNotes] = useState('')
  const [isDismissed, setIsDismissed] = useState(null)
  const [selectedConditions, setSelectedConditions] = useState({})

  const conditionOptions = useMemo(
    () => Object.values(TrackingConditions).filter(condition => condition.trackingStatus.includes(trackStatus)),
    [trackStatus]
  )

  const { isGiUser } = useRolePermissions()
  const giTrackSettings = useMemo(() => JSON.parse(treatment?.giTrackSettings || '{}'), [treatment])
  const untrackedTeeth = useMemo(() => sortTeeth(giTrackSettings?.untrackedTeeth), [giTrackSettings])
  const grinScan = useMemo(() => grinScans?.find(scan => scan.id === scanId), [scanId, grinScans])
  const grinScanForTagging = useMemo(() => {
    if (!grinScan?.scanPairId) {
      return grinScan
    }

    return grinScans?.find(scan => scan.scanPairId === grinScan.scanPairId && scan.withAligner)
  }, [grinScans, grinScan])

  const isLoading = useMemo(() => submissionStatus === AsyncStatus.Loading, [submissionStatus])
  const wasTrackingDataSubmittedBefore = useMemo(
    () => !!grinScanForTagging?.trackingStatus && grinScanForTagging?.trackingStatus !== ScanTrackingStatus.Pending,
    [grinScanForTagging]
  )
  const isNoteEnabled = useMemo(
    () =>
      Object.keys(selectedConditions).find(
        conditionType => selectedConditions[conditionType] && TrackingConditions[conditionType]?.withNote
      ),
    [selectedConditions]
  )
  const isSubmitDisabled = useMemo(() => {
    return (
      submissionStatus === AsyncStatus.Loading ||
      !trackStatus ||
      (trackStatus === TreatmentTrackOptions.OffTrack && isEmpty(selectedConditions)) ||
      (isNoteEnabled && !notes)
    )
  }, [trackStatus, submissionStatus, selectedConditions, isNoteEnabled, notes])

  const setConditionEnabled = useCallback(
    ({ conditionId, checked }) => {
      if (conditionId === TrackingConditionsTypes.Other && !checked) {
        setNotes('')
      }
      setSelectedConditions({ ...selectedConditions, [conditionId]: checked ? { selections: [] } : undefined })
    },
    [selectedConditions]
  )

  const setConditionSelections = useCallback(
    ({ conditionId, selections = [] }) => {
      setSelectedConditions({
        ...selectedConditions,
        [conditionId]: { ...selectedConditions[conditionId], selections }
      })
    },
    [selectedConditions]
  )

  const submit = useCallback(() => {
    const scanSummaryData = JSON.parse(grinScan?.scanSummaryData || '{}')
    const timeToTag = moment().diff(moment(scanSummaryData.finishedAt), 'minute')

    trackEvent(`${isGiUser ? 'GI' : 'HI'} - review done click`, {
      status: trackStatus,
      patientId: treatment?.patientId,
      scanId,
      notes,
      reasons: Object.keys(selectedConditions),
      timeToTag
    })

    dispatch(
      Actions.submitScanTrackingData({
        scanId: grinScanForTagging.id,
        trackStatus,
        notes,
        isEditing: wasTrackingDataSubmittedBefore,
        conditions: selectedConditions,
        isGiUser
      })
    )
  }, [
    grinScan,
    isGiUser,
    trackStatus,
    treatment,
    scanId,
    notes,
    selectedConditions,
    dispatch,
    grinScanForTagging,
    wasTrackingDataSubmittedBefore
  ])

  const dismiss = useCallback(() => {
    if (isGiUser) {
      logWarn('Tried to dismiss scan tracking with GI user')
      return
    }

    trackEvent('HI - review dismiss clicked', {
      patientId: treatment?.patientId,
      scanId
    })

    dispatch(
      Actions.submitScanTrackingData({
        scanId: grinScanForTagging.id,
        trackStatus: 'not-required',
        dismissScan: true,
        isEditing: wasTrackingDataSubmittedBefore,
        isGiUser: false
      })
    )
  }, [scanId, dispatch, treatment, isGiUser, wasTrackingDataSubmittedBefore, grinScanForTagging])

  useEffect(() => {
    const trackingDetails = JSON.parse(grinScanForTagging?.trackingDetails || '{}')
    setTrackStatus(trackingDetails.trackStatus)
    setNotes(trackingDetails.notes)
    setSelectedConditions(trackingDetails.conditions || {})
    setIsDismissed(trackingDetails.isDismissed)
  }, [grinScanForTagging?.trackingDetails])

  useEffect(() => {
    const trackingDetails = JSON.parse(grinScanForTagging?.trackingDetails || '{}')
    setSelectedConditions(trackingDetails?.conditions || {})
  }, [trackStatus, grinScan, grinScanForTagging])

  return {
    scanTrackingStatus: grinScanForTagging?.trackingStatus,
    isSubmitDisabled,
    isLoading,
    conditionOptions,
    giTrackSettings,
    untrackedTeeth,
    isNoteEnabled,
    isDismissed,
    wasTrackingDataSubmittedBefore,
    setConditionEnabled,
    setConditionSelections,
    submit,
    dismiss,
    values: {
      trackStatus,
      setTrackStatus,
      notes,
      setNotes,
      conditions: selectedConditions,
      setConditions: setSelectedConditions
    }
  }
}
