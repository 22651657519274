import Actions from 'actions'
import { sortBy } from 'lodash'
import { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'

export default ({ grinScans = [], currentGrinScanId }) => {
  const dispatch = useDispatch()

  const sortedScans = useMemo(() => sortBy(grinScans, 'createdAt'), [grinScans])

  const currentScanIndex = useMemo(
    () => sortedScans.findIndex(scan => scan.id === currentGrinScanId),
    [sortedScans, currentGrinScanId]
  )
  const nextScan = useMemo(
    () => sortedScans.slice(currentScanIndex + 1).find(scan => scan.treatmentTrackerStatus === 'completed'),
    [currentScanIndex, sortedScans]
  )
  const previousScan = useMemo(
    () =>
      sortedScans
        .slice(0, currentScanIndex)
        .reverse()
        .find(scan => scan.treatmentTrackerStatus === 'completed'),
    [currentScanIndex, sortedScans]
  )
  const allTxTrackerScans = useMemo(
    () => sortedScans.filter(scan => scan.treatmentTrackerStatus === 'completed'),
    [sortedScans]
  )

  const openScan = useCallback(
    scanId => {
      dispatch(
        Actions.setTreatmentTrackerModalOpen({
          isOpen: true,
          grinScanId: scanId
        })
      )
    },
    [dispatch]
  )

  const navigateToNextScan = useCallback(() => {
    if (currentScanIndex === -1) {
      return
    }

    if (nextScan) {
      openScan(nextScan.id)
    } else {
      console.log('this is the last scan with treatment tracker')
    }
  }, [currentScanIndex, nextScan, openScan])

  const navigateToPreviousScan = useCallback(() => {
    if (currentScanIndex === -1) {
      return
    }

    if (previousScan) {
      openScan(previousScan.id)
    } else {
      console.log('this is the first scan with treatment tracker')
    }
  }, [currentScanIndex, openScan, previousScan])

  const navigateToScanByScanId = useCallback(
    scanId => {
      if (scanId) {
        openScan(scanId)
      }
    },
    [openScan]
  )

  return {
    navigateToNextScan,
    navigateToPreviousScan,
    hasNextScan: !!nextScan,
    hasPreviousScan: !!previousScan,
    allTxTrackerScans,
    navigateToScanByScanId
  }
}
