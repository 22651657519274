import React, { useCallback, useState } from 'react'
import { Grid, makeStyles, useMediaQuery } from '@material-ui/core'
import { trackEvent } from 'utils/analyticsUtils'
import ScanFeedCard from 'components/Patients/Timeline/V2/ScanFeed/ScanFeedCard'
import ScanHeaderBodyXl from 'components/Patients/Timeline/V2/ScanFeed/ScanHeader/ScanHeaderBody.xl'
import ScanHeaderBodyLg from 'components/Patients/Timeline/V2/ScanFeed/ScanHeader/ScanHeaderBody.lg'
import ScanNavigationArrows from 'components/common/ScanNavigationArrows'
import useRolePermissions from 'hooks/useRolePermissions'

const useStyles = makeStyles(theme => ({
  scanHeaderRoot: {
    padding: ({ isSmallScreen }) => (isSmallScreen ? '12px 18px' : 24)
  }
}))

const ScanHeader = ({
  grinScanId,
  scanNumber,
  alignerNumber,
  withAligner,
  totalAlignerNumber,
  numberOfReviews,
  numberOfShares,
  scanDate,
  hasNextScans,
  navigateToNextScan = () => {},
  hasPreviousScans,
  navigateToPreviousScan = () => {},
  trackStatus,
  isRecordsAppScan,
  urgencyDetails = {},
  analyticsPayload,
  onEditAlignerNumberClicked = () => {},
  onSwitchWithAlignersClicked = () => {}
}) => {
  const isSmallScreen = useMediaQuery('(max-width:1920px)') // Check if the viewport max-width is *up to* 1920px
  const classes = useStyles({ isSmallScreen })
  const { permissions } = useRolePermissions()

  const [isAlignersMenuOpen, setIsAlignersMenuOpen] = useState(false)

  const handleNextScanClicked = useCallback(() => {
    trackEvent('Scan Feed - go to next scan clicked', analyticsPayload)
    navigateToNextScan()
  }, [navigateToNextScan, analyticsPayload])

  const handlePreviousScanClicked = useCallback(() => {
    trackEvent('Scan Feed - go to previous scan clicked', analyticsPayload)
    navigateToPreviousScan()
  }, [navigateToPreviousScan, analyticsPayload])

  const handleReviewsClicked = useCallback(() => {
    trackEvent('Scan Feed - Number of reviews clicked', analyticsPayload)
  }, [analyticsPayload])

  const handleSharesClicked = useCallback(() => {
    trackEvent('Scan Feed - Number of shares clicked', analyticsPayload)
  }, [analyticsPayload])

  const handleEditAlignerNumberClicked = useCallback(() => {
    trackEvent('Scan Feed - edit aligner number clicked', analyticsPayload)
    onEditAlignerNumberClicked()
  }, [analyticsPayload, onEditAlignerNumberClicked])

  const handleSwitchWithAlignerClicked = useCallback(() => {
    trackEvent('Scan Feed - switch with / without aligner clicked', analyticsPayload)
    onSwitchWithAlignersClicked()
  }, [analyticsPayload, onSwitchWithAlignersClicked])

  return (
    <ScanFeedCard className={classes.scanHeaderRoot}>
      <Grid container alignItems="center" justifyContent="space-between" spacing={3} id="timeline-item-info">
        <Grid item>
          <ScanNavigationArrows
            nextDisabled={!hasNextScans}
            onNextClicked={handleNextScanClicked}
            previousDisabled={!hasPreviousScans}
            onPreviousClicked={handlePreviousScanClicked}
          />
        </Grid>
        <Grid item style={{ flex: 1, padding: isSmallScreen ? '12px 0' : '12px' }}>
          {React.createElement(isSmallScreen ? ScanHeaderBodyLg : ScanHeaderBodyXl, {
            scanNumber,
            alignerNumber,
            totalAlignerNumber,
            numberOfShares,
            numberOfReviews,
            scanDate,
            trackStatus,
            urgencyDetails,
            isRecordsAppScan,
            onReviewsClicked: handleReviewsClicked,
            onSharesClicked: handleSharesClicked,
            withAlignerMenu: permissions.editScanAppliance,
            alignerMenuProps: {
              isOpen: isAlignersMenuOpen,
              withAligner,
              setIsOpen: setIsAlignersMenuOpen,
              onEditAlignerNumberClicked: handleEditAlignerNumberClicked,
              onSwitchWithAlignersClicked: handleSwitchWithAlignerClicked
            }
          })}
        </Grid>
      </Grid>
    </ScanFeedCard>
  )
}

export default React.memo(ScanHeader)
