import React, { useMemo } from 'react'
import FallbackGrinScanItemIcon from 'components/common/icons/timelineV2/mini-timeline/NormalGrinScanItem'
import { TeethTrackingStatuses } from 'components/Patients/TreatmentTracker/txTrackerConsts'
import Fast from 'components/common/icons/timelineV2/mini-timeline/txTracker/Fast'
import Moderate from 'components/common/icons/timelineV2/mini-timeline/txTracker/Moderate'
import Slow from 'components/common/icons/timelineV2/mini-timeline/txTracker/Slow'
import NoSignificantMovement from 'components/common/icons/timelineV2/mini-timeline/txTracker/NoSignificantMovement'
import Tracking from 'components/common/icons/timelineV2/mini-timeline/txTracker/Tracking'
import PartiallyTracking from 'components/common/icons/timelineV2/mini-timeline/txTracker/PartiallyTracking'
import NotTracking from 'components/common/icons/timelineV2/mini-timeline/txTracker/NotTracking'

const TxTrackerIcon = ({ txTrackerSummary }) => {
  const iconByTrackingStatus = useMemo(
    () => ({
      [TeethTrackingStatuses.Fast]: <Fast />,
      [TeethTrackingStatuses.Moderate]: <Moderate />,
      [TeethTrackingStatuses.Slow]: <Slow />,
      [TeethTrackingStatuses.NoSignificantMovement]: <NoSignificantMovement />,
      [TeethTrackingStatuses.Tracking]: <Tracking />,
      [TeethTrackingStatuses.PartiallyTracking]: <PartiallyTracking />,
      [TeethTrackingStatuses.NotTracking]: <NotTracking />
    }),
    []
  )

  return txTrackerSummary ? iconByTrackingStatus[txTrackerSummary] : <FallbackGrinScanItemIcon />
}

export default TxTrackerIcon
