import axios from 'axios'
import Amplify, { Auth } from 'aws-amplify'
import AWS from 'aws-sdk'
import { log, logError } from '../utils/logUtils'

const config = {}

export const getEnvironment = () => {
  switch (window.location.hostname) {
    case 'providers.get-grin.com':
    case 'preprod.d12rykp7tbh8b8.amplifyapp.com':
    case 'preprod.get-grin.com':
      return 'production'
    case 'staging.get-grin.com':
      return 'staging'
    case 'dev.get-grin.com':
      return 'idev'
    case 'tomdev.drs2kzkhrg6oi.amplifyapp.com':
    case 'rcleads.get-grin.com':
    case 'gr-992-rc-create-the-leads-tab.drs2kzkhrg6oi.amplifyapp.com':
      return 'tomdev'
    case 'localhost':
    case '127.0.0.1':
      return process.env.REACT_APP_GRIN_ENV || localStorage.getItem('env') || 'staging'
    default:
      return localStorage.getItem('env') || 'staging'
  }
}

export const getEnvironmentHostname = () => {
  switch (getEnvironment()) {
    case 'production':
      return 'https://providers.get-grin.com'
    case 'staging':
      return 'https://staging.get-grin.com'
    default:
      return 'https://dev.get-grin.com'
  }
}

export const getAppConfigUrl = () => {
  const env = getEnvironment()
  const path = '?app_type=grin-webapp&platform=' + env

  switch (env) {
    case 'production':
      return 'https://iuw2ttk53h.execute-api.us-east-1.amazonaws.com/production/appconfig' + path
    case 'staging':
      return 'https://vh4w836lu0.execute-api.us-east-1.amazonaws.com/staging/appconfig' + path
    case 'ordev':
      return 'https://1ywn6yxsq8.execute-api.us-east-1.amazonaws.com/ordev/appconfig' + path
    case 'idev':
      return 'https://06xw579uth.execute-api.us-east-1.amazonaws.com/idev/appconfig' + path
    case 'adirdev':
      return 'https://1ylmslwd49.execute-api.us-east-1.amazonaws.com/adirdev/appconfig' + path
    case 'tomdev':
      return 'https://k9e0g30q23.execute-api.us-east-1.amazonaws.com/tomdev/appconfig' + path
    default:
      return 'https://06xw579uth.execute-api.us-east-1.amazonaws.com/idev/appconfig' + path
  }
}

const configureAmplify = () => {
  const { aws_cloud_logic_custom: endpoints, ...awsExportsWithoutEndpoints } = config

  Amplify.configure({
    ...awsExportsWithoutEndpoints,
    API: {
      endpoints: endpoints.map(endpoint => ({
        ...endpoint,
        custom_header: async () => {
          try {
            const session = await Auth.currentSession()
            return {
              'x-api-key': session.getIdToken().getJwtToken()
            }
          } catch (error) {
            logError('Error getting id token to configure amplify', { error })
            return {}
          }
        }
      })),
      graphql_headers: async () => {
        const session = await Amplify.Auth.currentSession()
        return {
          Authorization: session.getIdToken().getJwtToken()
        }
      }
    },
    Analytics: {
      disabled: true
    }
  })

  Amplify.Auth.currentCredentials().then(credentials => {
    AWS.config.credentials = Amplify.Auth.essentialCredentials(credentials)
  })
}

export const initAmplify = callback =>
  axios
    .get(getAppConfigUrl())
    .then(res => {
      const awsExportsFromAppconfig = res?.data?.response?.result?.awsExports

      if (awsExportsFromAppconfig) {
        try {
          sessionStorage.setItem('awsExports', JSON.stringify(awsExportsFromAppconfig))
        } catch (err) {
          log('Could not save awsExports to session storage', {}, 'warn')
        }

        Object.assign(config, awsExportsFromAppconfig)
        configureAmplify()
        callback()
      } else {
        throw new Error('Could not fetch awsExports from appconfig')
      }
    })
    .catch(err => {
      log(err.message, { AppconfigUrl: getAppConfigUrl() }, 'warn')
      try {
        const awsExportsFallback = sessionStorage.getItem('awsExports')
        if (awsExportsFallback) {
          Object.assign(config, JSON.parse(awsExportsFallback))
          configureAmplify()
          callback()
        } else {
          throw new Error('Could not initiate awsExports')
        }
      } catch (error) {
        log(error.message, { AppconfigUrl: getAppConfigUrl() }, 'error')
      }
    })

export const getWebClientId = () => config?.aws_user_pools_web_client_id

export default config
