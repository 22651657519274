import _ from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useScanStats from 'components/Patients/Timeline/V2/useScanStats'
import Actions from 'actions'
import { TimelineItemTypes } from 'consts/timelineConsts'
import { ScanTrackingStatus } from 'consts/giConsts'
import useScanReviews from './useScanReviews'
import useScanReferrals from './useScanReferrals'
import useOralRecords from './useOralRecords'
import useScanOralScore from '../useScanOralScore'
import useFeatureFlags from 'hooks/useFeatureFlags'
import useTreatmentTracker from './useTreatmentTracker'

export default () => {
  const dispatch = useDispatch()
  const { oralHygieneScore: oralHygieneScoreFF } = useFeatureFlags()

  const { selectedTimelineItemId } = useSelector(state => state.timelineReducer)
  const { data: timelineItems } = useSelector(state => state.timelineReducer.mainFeed.timelineItems)
  const { timelineItem: rawTimelineItem, loadStatus } = useSelector(state => state.timelineReducer.scanFeed)

  const [withAligner, setWithAligner] = useState(false)
  const [isEditAlignerModalOpen, setIsEditAlignerModalOpen] = useState(false)
  const [isSwitchWithAlignerModalOpen, setIsSwitchWithAlignerModalOpen] = useState(false)

  const timelineItem = useMemo(
    () => ({
      ...(rawTimelineItem || {}),
      payload: JSON.parse(rawTimelineItem.payload || '{}')
    }),
    [rawTimelineItem]
  )

  const scanStats = useScanStats({ timelineItem })
  const oralScores = useScanOralScore({ oralScores: timelineItem.payload.oralScores })
  const withOralScores = useMemo(() => oralHygieneScoreFF && !_.isEmpty(oralScores), [oralHygieneScoreFF, oralScores])
  const isDoubleScan = useMemo(() => timelineItem.payload.scanType === 'doubleScan', [timelineItem])
  const scanNumber = useMemo(() => timelineItem.payload.scanNumber, [timelineItem])

  const grinScans = useMemo(
    () =>
      (timelineItem.payload.scanType === 'doubleScan'
        ? timelineItem.grinScans.items
        : timelineItem.grinScan
        ? [timelineItem.grinScan]
        : []
      ).map(scan => ({
        ...scan,
        metadata: JSON.parse(scan.metadata)
      })),
    [timelineItem]
  )

  const grinScanIds = useMemo(() => grinScans.map(scan => scan.id), [grinScans])

  const displayedGrinScan = useMemo(
    () => grinScans.find(scan => !!scan.withAligner === withAligner) || {},
    [withAligner, grinScans]
  )

  const grinScanWithAligner = useMemo(
    () =>
      _.minBy(
        grinScans.filter(scan => scan.applianceIndex),
        'createdAt'
      ),
    [grinScans]
  )

  const recordsAppScan = useMemo(() => grinScans.find(scan => scan.metadata?.isServiceAccount), [grinScans])

  const grinScanTimelineItems = useMemo(
    () => timelineItems.filter(item => item.type === TimelineItemTypes.GrinScan),
    [timelineItems]
  )

  const totalScans = useMemo(() => grinScanTimelineItems?.length ?? 0, [grinScanTimelineItems])

  const timelineItemIndex = useMemo(
    () => grinScanTimelineItems?.findIndex(item => item.id === selectedTimelineItemId),
    [grinScanTimelineItems, selectedTimelineItemId]
  )

  const nextScanItem = useMemo(() => {
    if (timelineItemIndex === grinScanTimelineItems.length) {
      return null
    }

    return grinScanTimelineItems[timelineItemIndex - 1]
  }, [timelineItemIndex, grinScanTimelineItems])

  const previousScanItem = useMemo(() => {
    if (timelineItemIndex === -1) {
      return null
    }

    return grinScanTimelineItems[timelineItemIndex + 1]
  }, [timelineItemIndex, grinScanTimelineItems])

  const trackingDetails = useMemo(() => {
    const trackedScan = grinScans.find(scan => scan.trackingStatus === ScanTrackingStatus.Submitted)
    if (!trackedScan) {
      return null
    }

    return JSON.parse(trackedScan.trackingDetails || '{}')
  }, [grinScans])

  const urgencyDetails = useMemo(() => timelineItem.payload.urgency || {}, [timelineItem])

  const scanReviews = useScanReviews({ grinScans })
  const referrals = useScanReferrals({ grinScans })
  const oralRecords = useOralRecords()
  const treatmentTrackerData = useTreatmentTracker({ grinScans })

  useEffect(() => {
    if (selectedTimelineItemId) {
      dispatch(
        Actions.fetchTimelineItem({
          timelineItemId: selectedTimelineItemId
        })
      )
    }
  }, [dispatch, selectedTimelineItemId])

  useEffect(() => {
    setWithAligner(!!grinScans[0]?.withAligner)
  }, [grinScans])

  return {
    loadStatus,
    timelineItem: timelineItem,
    grinScans,
    grinScanIds,
    displayedGrinScan,
    isDoubleScan,
    scanNumber,
    scanStats,
    nextScanItem,
    previousScanItem,
    grinScanWithAligner,
    trackingDetails,
    scanReviews,
    referrals,
    treatmentTrackerData,
    totalScans,
    urgencyDetails,
    isRecordsAppScan: !!recordsAppScan,
    withAligner,
    oralRecords,
    withOralScores,
    oralScores,
    setWithAligner,
    isEditAlignerModalOpen,
    setIsEditAlignerModalOpen,
    isSwitchWithAlignerModalOpen,
    setIsSwitchWithAlignerModalOpen
  }
}
