import React, { useRef, useState, useEffect, useCallback } from 'react'
import { makeStyles } from '@material-ui/core'
import review_cursor from '../../../resources/cursor_pointer.png'
import AnnotationActionButton from 'components/common/annotations/ActionButton'
import ActionButton from 'components/common/buttons/ActionButton'
import CountDown from 'components/common/CountDown'
import VideoCameraIcon from 'components/common/icons/VideoCameraIcon'
import VideoStopIcon from 'components/common/icons/VideoStop'
import Timer from './Timer'
import { useTranslation } from 'react-i18next'
import { ReactSketchCanvas } from 'react-sketch-canvas'
import { hexToRgba } from 'utils/colorUtils'
import ButtonsGroup from 'components/common/annotations/ButtonsGroup'
import {
  DEFAULT_LINE_WIDTH,
  DEFAULT_BRUSH_OPACITY,
  DEFAULT_LINE_COLOR,
  DEFAULT_RGBA_COLOR,
  DRAW_CONFIG_NAME,
  VIDEO_CONTROLS_PLACEHOLDER
} from 'consts/annotationsConsts'
import { trackEvent } from 'utils/analyticsUtils'
import useFeatureFlags from 'hooks/useFeatureFlags'

const cursorStyle = {
  cursor: `url(${review_cursor}), auto`
}

const useStyles = ({ isCapturing }) =>
  makeStyles({
    videoColumn: {
      width: '100%',
      height: isCapturing ? '95%' : '75%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    footer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'baseline'
    },
    centeredFooter: {
      justifyContent: 'center',
      alignItems: 'center'
    },
    canvasBoard: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      '&.hidden': {
        visibility: 'hidden'
      },
      '& div': {
        background: 'transparent !important'
      }
    },
    timer: {
      color: 'white',
      width: 120
    },
    videoStopIcon: {
      width: 30,
      height: 30,
      backgroundColor: 'red',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    canvas: {
      border: 'none !important',
      borderBottom: '1px dotted white !important',
      ...cursorStyle
    },
    countDown: {
      width: '100%',
      height: '100%',
      borderRadius: 0
    },
    sliderWrapper: {
      position: 'absolute',
      top: '50%',
      left: 10,
      zIndex: 10,
      height: 400,
      marginTop: 24,
      marginBottom: 150,
      borderRadius: 30,
      padding: '30px 10px 30px 0',
      backgroundColor: 'var(--bg-color-8)',
      transform: 'translateY(-50%)'
    },
    actionButtonContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'baseline'
    }
  })({ isCapturing })

const ScanReviewVideoContainer = ({
  scanId,
  videoElement,
  isCapturing,
  timerStarted,
  showCountDown,
  scanElementDimensions,
  onStartStopCaptureClicked,
  onCountDownComplete
}) => {
  const { t } = useTranslation()

  const classes = useStyles({ isCapturing })

  const videoColumnRef = useRef()
  const canvasRef = useRef()

  const [eraseMode, setEraseMode] = useState(false)
  const [drawColor, setDrawColor] = useState(DEFAULT_LINE_COLOR)
  const [drawOpacity, setDrawOpacity] = useState(DEFAULT_BRUSH_OPACITY)
  const [drawRgbaColor, setDrawRgbaColor] = useState(DEFAULT_RGBA_COLOR)
  const [drawWidth, setDrawWidth] = useState(DEFAULT_LINE_WIDTH)
  const { overlayDrawingFF } = useFeatureFlags()

  useEffect(() => {
    const drawConfig = JSON.parse(localStorage.getItem(DRAW_CONFIG_NAME))
    if (drawConfig) {
      setDrawColor(drawConfig.drawColor)
      setDrawOpacity(drawConfig.drawOpacity)
      setDrawWidth(drawConfig.drawWidth)
      setDrawRgbaColor(drawConfig.drawRgbaColor)
    }
  }, [])

  const handleEraseMode = useCallback(
    value => {
      setEraseMode(value)
      canvasRef.current.eraseMode(value)
      value && trackEvent('ScanReviewAnnotation_EraseClicked', { scanId })
    },
    [scanId]
  )

  const handleChangeColor = useCallback(
    color => {
      setDrawColor(color)
      const rgbaColor = hexToRgba(color, drawOpacity)
      setDrawRgbaColor(rgbaColor)
      handleEraseMode(false)
      trackEvent('ScanReviewAnnotation_ColorChanged', {
        fromColor: drawColor,
        toColor: color
      })
    },
    [setDrawColor, handleEraseMode, drawOpacity, drawColor]
  )

  const handleChangeOpacity = useCallback(
    (event, newOpacity) => {
      setDrawOpacity(newOpacity)
      const rgbaColor = hexToRgba(drawColor, newOpacity)
      setDrawRgbaColor(rgbaColor)
    },
    [setDrawOpacity, drawColor]
  )

  const handleChangeWidth = useCallback(
    (event, newWidth) => {
      setDrawWidth(newWidth)
    },
    [setDrawWidth]
  )

  const handleClearCanvas = useCallback(() => {
    canvasRef.current.clearCanvas()
    trackEvent('ScanReviewAnnotation_ClearAllClicked', { scanId })
  }, [scanId])

  const handleUndo = useCallback(() => {
    canvasRef.current.undo()
    trackEvent('ScanReviewAnnotation_UndoClicked', { scanId })
  }, [scanId])

  const handleRedo = useCallback(() => {
    canvasRef.current.redo()
    trackEvent('ScanReviewAnnotation_RedoClicked', { scanId })
  }, [scanId])

  const handleSaveConfig = useCallback(() => {
    const config = JSON.stringify({
      drawColor,
      drawOpacity,
      drawRgbaColor,
      drawWidth
    })
    localStorage.setItem(DRAW_CONFIG_NAME, config)
  }, [drawColor, drawWidth, drawOpacity, drawRgbaColor])

  const handleStartStopCaptureClicked = useCallback(() => {
    onStartStopCaptureClicked(!isCapturing)
    const action = isCapturing ? 'Stop' : 'Start'
    trackEvent(`ScanReviewAnnotation_${action}CaptureClicked`, { scanId })
  }, [isCapturing, onStartStopCaptureClicked, scanId])

  return (
    <div className={classes.videoColumn} ref={videoColumnRef}>
      {showCountDown && <CountDown onComplete={onCountDownComplete} className={classes.countDown} />}
      {videoElement}
      <div className={[classes.footer, !overlayDrawingFF || !isCapturing ? classes.centeredFooter : ''].join(' ')}>
        {(!overlayDrawingFF || !isCapturing) && (
          <ActionButton
            label={isCapturing ? '' : t('dialogs.scanReviewEditor.startRecording')}
            onClick={handleStartStopCaptureClicked}
            alignSelf="flex-end"
            background={isCapturing ? '#EB5757' : '#3c52ef'}
            color="white"
            iconComponent={isCapturing ? <VideoStopIcon /> : <VideoCameraIcon color="white" />}
          />
        )}

        {!overlayDrawingFF && isCapturing && (
          <div className={classes.actionButtonContainer}>
            <Timer
              start={timerStarted}
              onTimeout={() => onStartStopCaptureClicked(false)}
              timeoutSeconds={300}
              timeoutWarningGap={30}
            />
          </div>
        )}
        {overlayDrawingFF && isCapturing && (
          <ButtonsGroup
            canvasWidth="100%"
            eraseMode={eraseMode}
            drawWidth={drawWidth}
            drawColor={drawColor}
            drawOpacity={drawOpacity}
            defaultLineWidth={DEFAULT_LINE_WIDTH}
            defaultBrushOpacity={DEFAULT_BRUSH_OPACITY}
            onEraseMode={handleEraseMode}
            onUndoAction={handleUndo}
            onRedoAction={handleRedo}
            onSaveConfig={handleSaveConfig}
            onClearCanvas={handleClearCanvas}
            onChangeWidth={handleChangeWidth}
            onChangeColor={handleChangeColor}
            onChangeOpacity={handleChangeOpacity}
            sliderClassName={classes.sliderWrapper}
          >
            <AnnotationActionButton
              onClick={handleStartStopCaptureClicked}
              timer={
                <Timer
                  start={timerStarted}
                  onTimeout={() => onStartStopCaptureClicked(false)}
                  timeoutSeconds={300}
                  timeoutWarningGap={30}
                  className={classes.timer}
                />
              }
            >
              <div className={classes.videoStopIcon}>
                <VideoStopIcon />
              </div>
            </AnnotationActionButton>
          </ButtonsGroup>
        )}
      </div>
      {overlayDrawingFF && (
        <div className={[classes.canvasBoard, !isCapturing ? 'hidden' : ''].join(' ')}>
          <ReactSketchCanvas
            ref={canvasRef}
            canvasColor="transparent"
            width="100%"
            height={scanElementDimensions?.height - VIDEO_CONTROLS_PLACEHOLDER}
            strokeColor={drawRgbaColor}
            strokeWidth={drawWidth}
            className={classes.canvas}
          />
        </div>
      )}
    </div>
  )
}

export default ScanReviewVideoContainer
