import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { IconButton } from '@material-ui/core'
import ArrowLeft from '../icons/timelineV2/scan-feed/ArrowLeft'
import Tooltip from '../Tooltip'
import ArrowRight from '../icons/timelineV2/scan-feed/ArrowRight'

const useStyles = makeStyles(theme => ({
  poseNavigationButtonContainer: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)'
  },
  previous: {
    left: 12
  },
  next: {
    right: 12
  }
}))

const PoseNavigationButton = ({ onClick = () => {}, isNext = true, keyboardShortcut = '' }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const handleClick = useCallback(
    e => {
      e.stopPropagation()
      onClick()
    },
    [onClick]
  )

  return (
    <div className={[classes.poseNavigationButtonContainer, isNext ? classes.next : classes.previous].join(' ')}>
      <Tooltip
        value={
          <div>
            {isNext ? t('dialogs.scanView.nextPose') : t('dialogs.scanView.previousPose')}
            {keyboardShortcut && (
              <>
                <br />({t('common.keyboardShortcut', { shortcut: keyboardShortcut })})
              </>
            )}
          </div>
        }
      >
        <IconButton onClick={handleClick}>
          {isNext ? <ArrowRight color="white" /> : <ArrowLeft color="white" />}
        </IconButton>
      </Tooltip>
    </div>
  )
}

export default PoseNavigationButton
