export const actionTypes = {
  SendMessage: 'sendMessage',
  AddPatientTag: 'addPatientTag',
  ShowAnnouncement: 'showAnnouncement',
  AssignPatient: 'assignPatient',
  NotifyPractice: 'notifyPractice'
}

export const conditionTypes = {
  DateRange: 'dateRange',
  DuringDays: 'duringDays',
  AlignerNumber: 'alignerNumber',
  TreatmentStatusUpdated: 'treatmentStatusUpdated',
  PatientScanCount: 'patientScanCount',
  PracticeHasFeatureFlag: 'practiceHasFeatureFlag',
  ScanIsUrgent: 'scanIsUrgent',
  OverdueDays: 'overdueDays',
  PracticeOnPlan: 'practiceOnPlan',
  PatientStatus: 'patientStatus',
  RemainingAligners: 'remainingAligners',
  PatientHasTags: 'patientHasTags'
}

export const TriggerTypes = {
  IncomingMessage: 'incomingMessage',
  IncomingMessageOrGrinScan: 'incomingMessageOrGrinScan',
  IncomingGrinScan: 'incomingGrinScan',
  AlignerNumberReached: 'alignerNumberReached',
  PatientStatusChanged: 'patientStatusChanged',
  PatientOverdueForXDays: 'patientOverdueForXDays',
  RemainingAlignersReached: 'remainingAlignersReached'
}

export const TriggerConfigs = {
  [TriggerTypes.IncomingMessage]: {
    isRequired: false,
    conditions: [
      conditionTypes.DateRange,
      conditionTypes.DuringDays,
      conditionTypes.PatientStatus,
      conditionTypes.PatientHasTags
    ]
  },
  [TriggerTypes.IncomingMessageOrGrinScan]: {
    isRequired: false,
    conditions: [
      conditionTypes.DateRange,
      conditionTypes.DuringDays,
      conditionTypes.PatientStatus,
      conditionTypes.PatientHasTags
    ]
  },
  [TriggerTypes.IncomingGrinScan]: {
    isRequired: false,
    conditions: [
      conditionTypes.DateRange,
      conditionTypes.DuringDays,
      conditionTypes.PatientScanCount,
      conditionTypes.PracticeHasFeatureFlag,
      conditionTypes.ScanIsUrgent,
      conditionTypes.PracticeOnPlan,
      conditionTypes.PatientStatus,
      conditionTypes.PatientHasTags
    ]
  },
  [TriggerTypes.AlignerNumberReached]: {
    isRequired: true,
    conditions: [conditionTypes.AlignerNumber]
  },
  [TriggerTypes.RemainingAlignersReached]: {
    isRequired: true,
    conditions: [conditionTypes.RemainingAligners]
  },
  [TriggerTypes.PatientStatusChanged]: {
    isRequired: true,
    conditions: [conditionTypes.TreatmentStatusUpdated]
  },
  [TriggerTypes.PatientOverdueForXDays]: {
    isRequired: true,
    conditions: [conditionTypes.OverdueDays],
    isTimeBased: true
  }
}

export const SystemAutomationsDoctorId = 'Grin'
