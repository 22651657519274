import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useMemo } from 'react'
import { isEmpty } from 'lodash'
import actions from 'actions'

export default () => {
  const { appconfig, isFetchingStaticData } = useSelector(state => state.appReducer)
  const promotions = useSelector(state => state.chatReducer.promotions?.data || [])
  const systemTags = useSelector(state => state.profileReducer.systemTags?.data || [])
  const releaseNotes = useSelector(state => state.appReducer.publishedReleaseNotes?.data || {})
  const { data: systemTemplates } = useSelector(state => state.profileReducer.systemTemplates)
  const { data: statuses } = useSelector(state => state.treatmentReducer.statuses)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!isFetchingStaticData && (isEmpty(statuses) || !systemTemplates || !systemTags.length || !promotions.length)) {
      dispatch(actions.fetchStaticData())
    }
  }, [appconfig, dispatch, isFetchingStaticData, promotions, statuses, systemTags, systemTemplates])

  const sortedReleaseNotes = useMemo(
    () =>
      Object.values(releaseNotes).sort((release1, release2) => {
        return new Date(release2.lastModified) - new Date(release1.lastModified)
      }),
    [releaseNotes]
  )

  return {
    releaseNotes: sortedReleaseNotes
  }
}
