import { ClickAwayListener, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Actions from 'actions'
import LinkButton from 'components/common/buttons/LinkButton'
import TxTrackerToothFeedbackIcon from 'components/common/icons/treatmentTracker/TxTrackerToothFeedbackIcon'
import GrinTab from 'components/common/tabs/GrinTab'
import GrinTabs from 'components/common/tabs/GrinTabs'
import useFeatureFlags from 'hooks/useFeatureFlags'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { trackEvent } from 'utils/analyticsUtils'
import { PROGRESS_TYPES } from '../../txTrackerConsts'
import ProgressInfo from './ProgressInfo'
import ToothProgressGraph from './ToothProgressGraph'

const TOOTH_PROGRESS_GRAPH_TABS = {
  overall: {
    index: 0,
    progressType: PROGRESS_TYPES.Overall
  },
  translation: {
    index: 1,
    progressType: PROGRESS_TYPES.Translation
  },
  rotation: {
    index: 2,
    progressType: PROGRESS_TYPES.Rotation
  }
}

const useStyles = makeStyles(theme => ({
  graphTitle: {
    color: '#6080AC'
  },
  tab: {
    cursor: 'pointer'
  },
  tabsContainer: {
    marginBottom: 10
  },
  feedbackIcon: {
    marginRight: 3
  }
}))

const ToothPopup = ({
  toothData,
  toothName,
  toothNumber,
  isTxGoalsEnabled,
  currentScan,
  treatmentType,
  onClickAway
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()
  const {
    currentProgress,
    currentRotation,
    currentTranslation,
    totalPlannedMovement,
    totalPlannedRotation,
    totalPlannedTranslation
  } = toothData

  const { txTrackerTranslationRotation: txTrackerTranslationRotationFF } = useFeatureFlags()

  const { selectedTimelineItemId } = useSelector(state => state.timelineReducer)

  const [selectedTab, setSelectedTab] = useState(TOOTH_PROGRESS_GRAPH_TABS.overall.index)
  const [progressType, setProgressType] = useState(TOOTH_PROGRESS_GRAPH_TABS.overall.progressType)

  const toothActualProgress = useMemo(() => {
    switch (progressType) {
      case PROGRESS_TYPES.Overall:
        return currentProgress
      case PROGRESS_TYPES.Rotation:
        return currentRotation
      case PROGRESS_TYPES.Translation:
        return currentTranslation
      default:
        return null
    }
  }, [progressType, currentProgress, currentRotation, currentTranslation])

  const toothPlannedProgress = useMemo(() => {
    switch (progressType) {
      case PROGRESS_TYPES.Overall:
        return totalPlannedMovement
      case PROGRESS_TYPES.Rotation:
        return totalPlannedRotation
      case PROGRESS_TYPES.Translation:
        return totalPlannedTranslation
      default:
        return null
    }
  }, [progressType, totalPlannedMovement, totalPlannedRotation, totalPlannedTranslation])

  const tabs = useMemo(() => {
    return [
      {
        index: TOOTH_PROGRESS_GRAPH_TABS.overall.index,
        label: t('dialogs.treatmentTracker.teethTracking.table.tooltip.tabs.overall'),
        progressType: TOOTH_PROGRESS_GRAPH_TABS.overall.progressType,
        condition: () => true
      },
      {
        index: TOOTH_PROGRESS_GRAPH_TABS.translation.index,
        label: t('dialogs.treatmentTracker.teethTracking.table.tooltip.tabs.translation'),
        progressType: TOOTH_PROGRESS_GRAPH_TABS.translation.progressType,
        condition: () => !!currentTranslation && txTrackerTranslationRotationFF
      },
      {
        index: TOOTH_PROGRESS_GRAPH_TABS.rotation.index,
        label: t('dialogs.treatmentTracker.teethTracking.table.tooltip.tabs.rotation'),
        progressType: TOOTH_PROGRESS_GRAPH_TABS.rotation.progressType,
        condition: () => !!currentRotation && txTrackerTranslationRotationFF
      }
    ]
      .sort((a, b) => (a.index > b.index ? 1 : -1))
      .filter(tab => tab.condition())
  }, [currentRotation, currentTranslation, t, txTrackerTranslationRotationFF])

  const contextText = useMemo(() => {
    const baseText = `Tx tracker tooth ${toothName} feedback`

    return `${baseText} for timeline item: ${selectedTimelineItemId}:`
  }, [selectedTimelineItemId, toothName])

  const handleGiveFeedbackClicked = useCallback(() => {
    dispatch(
      Actions.toggleAppFeedbackDialog({
        value: true,
        title: t('dialogs.treatmentTracker.teethTracking.table.tooltip.feedback.title', { toothName }),
        description: t('dialogs.treatmentTracker.teethTracking.table.tooltip.feedback.description'),
        inputPlaceholder: t('dialogs.treatmentTracker.teethTracking.table.tooltip.feedback.placeholder'),
        displayNote: false,
        contextText
      })
    )
  }, [contextText, dispatch, t, toothName])

  const handleTabSelection = useCallback(
    (e, value) => {
      e.stopPropagation()
      const selectedTab = tabs.find(tab => tab.index === value)
      trackEvent('Treatment tracker modal - Tooth tooltip - Tab selected', {
        selectedTab: selectedTab.label
      })
      setSelectedTab(value)
      setProgressType(selectedTab.progressType)
    },
    [tabs]
  )

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Grid container direction="column" className={classes.root}>
        <Grid item>
          <GrinTabs value={selectedTab} onChange={handleTabSelection} className={classes.tabsContainer}>
            {tabs.map(tab => (
              <GrinTab
                key={`tx-tracker-tab-${tab.index}`}
                variant="fullWidth"
                label={tab.label}
                marginRight="0px"
                value={tab.index}
                className={classes.tab}
              />
            ))}
          </GrinTabs>
        </Grid>

        <Grid item>
          <Grid container style={{ alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px' }}>
            <Grid item>
              <Grid item>
                <ProgressInfo
                  toothName={toothName}
                  toothData={toothData}
                  actualProgress={toothActualProgress}
                  plannedProgress={toothPlannedProgress}
                  progressType={progressType}
                  isTxGoalsEnabled={isTxGoalsEnabled}
                />
              </Grid>
            </Grid>
            <Grid item>
              <LinkButton
                spacing={0}
                label={t('dialogs.treatmentTracker.teethTracking.table.tooltip.feedback.label')}
                icon={<TxTrackerToothFeedbackIcon />}
                iconContainerClassName={classes.feedbackIcon}
                onClick={handleGiveFeedbackClicked}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <ToothProgressGraph
            currentScan={currentScan}
            toothData={toothData}
            toothNumber={toothNumber}
            isTxGoalsEnabled={isTxGoalsEnabled}
            progressType={progressType}
            totalPlannedValue={toothPlannedProgress}
            treatmentType={treatmentType}
          />
        </Grid>
      </Grid>
    </ClickAwayListener>
  )
}

export default ToothPopup
