import CssBaseline from '@material-ui/core/CssBaseline'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { Elements as StripeElements } from '@stripe/react-stripe-js'
import { ConnectedRouter } from 'connected-react-router'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Route, Switch } from 'react-router'
import { history, store } from './store'
import TagManager from 'react-gtm-module'
import App from './components/App'
import LandingPageIndex from './components/AuthRC/v2/LandingPageIndex'
import AuthPracticeMembersRouter from './components/AuthPracticeMembers/AuthPracticeMembersRouter'
import AppLoader from './components/AppLoader'
import actions from './actions'
import AppSnackbar from './components/AppSnackbar'
import Auth from './components/Auth'
import GrinAlert from './components/common/modals/GrinAlert'
import GrinPrompt from './components/common/modals/GrinPrompt'
import GlobalDesign from './components/GlobalDesign'
import { ROUTES } from './consts'
import './resources/locales/i18n'
import './styles/index.css'
import theme from './styles/theme'
import * as Analytics from './utils/analyticsUtils'
import { loadStripe } from './utils/stripeUtils'
import { initLogger } from './utils/logUtils'
import { initAmplify } from './utils/awsUtils'
import WhiteningDashboard from 'components/Whitening/WhiteningDashboard'
import WhiteningLandingPage from 'components/Whitening/WhiteningLandingPage'
import DolphinSnackbar from 'components/PmsSnackbars/DolphinSnackbar'
import ReferralsLandingPage from 'components/Referral/ReferralLandingPage'
import ServiceUnavailable from 'components/ServiceUnavailable'
import { initApp } from 'utils/appUtils'
import UploadProviderSTLsLanding from 'components/Patients/TreatmentTracker/UploadProviderSTLs/LandingPage'

try {
  TagManager.initialize({ gtmId: 'GTM-P57JH93' })
} catch (ex) {
  console.error('Error initializing tag manager', ex)
}

const root = document.getElementById('root')
Analytics.initMixpanel(history)

initLogger()

initApp(history, ({ isAppAvailable }) => {
  if (isAppAvailable) {
    initAmplify(() => {
      renderApp()
      store.dispatch(actions.awsConfigured())
    })
  } else {
    renderUnavailableApp()
  }
})

const renderUnavailableApp = () => {
  if (root !== null) {
    ReactDOM.render(
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <GlobalDesign />
            <Switch>
              <Route path={ROUTES.UNAVAILABLE} component={ServiceUnavailable} />
            </Switch>
            <AppSnackbar />
            <DolphinSnackbar />
            <GrinAlert />
            <GrinPrompt />
            <AppLoader />
          </MuiThemeProvider>
        </ConnectedRouter>
      </Provider>,
      root
    )
  }
}

const renderApp = () => {
  if (root !== null) {
    ReactDOM.render(
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <MuiThemeProvider theme={theme}>
            <StripeElements stripe={loadStripe()}>
              <CssBaseline />
              <GlobalDesign />
              <Switch>
                <Route exact path={ROUTES.AUTH_RC} component={LandingPageIndex} />
                <Route path={ROUTES.AUTH_PRACTICE_MEMBERS} component={AuthPracticeMembersRouter} />
                <Route path={ROUTES.AUTH} component={Auth} />
                <Route path={ROUTES.WHITENING_DASHBOARD} component={WhiteningDashboard} />
                <Route path={ROUTES.WHITENING_LANDING_PAGE} component={WhiteningLandingPage} />
                <Route path={ROUTES.REFERRAL} component={ReferralsLandingPage} />
                <Route path={ROUTES.UPLOAD_PROVIDER_STLS} component={UploadProviderSTLsLanding} />
                <Route path={ROUTES.ROOT} component={App} />
              </Switch>
              <AppSnackbar />
              <DolphinSnackbar />
              <GrinAlert />
              <GrinPrompt />
              <AppLoader />
            </StripeElements>
          </MuiThemeProvider>
        </ConnectedRouter>
      </Provider>,
      root
    )
  }
}
