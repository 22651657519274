import { split, upperCase, head, toLower, startCase, mapValues, pickBy, isEmpty } from 'lodash'
import {
  COUNTRY_AT,
  COUNTRY_BE,
  COUNTRY_BG,
  COUNTRY_CA,
  COUNTRY_HR,
  COUNTRY_CY,
  COUNTRY_CZ,
  COUNTRY_EE,
  COUNTRY_FI,
  COUNTRY_FR,
  COUNTRY_DE,
  COUNTRY_GR,
  COUNTRY_HU,
  COUNTRY_IE,
  COUNTRY_IL,
  COUNTRY_IT,
  COUNTRY_LV,
  COUNTRY_LT,
  COUNTRY_LU,
  COUNTRY_MT,
  COUNTRY_NL,
  COUNTRY_PL,
  COUNTRY_PT,
  COUNTRY_RO,
  COUNTRY_SK,
  COUNTRY_SI,
  COUNTRY_ES,
  COUNTRY_GB,
  COUNTRY_US,
  COUNTRY_DK,
  COUNTRY_SE,
  COUNTRY_IN,
  COUNTRY_TR,
  COUNTRY_TC,
  COUNTRY_BR,
  COUNTRY_CH,
  COUNTRY_AU,
  COUNTRY_NZ
} from '../consts/countryConsts'

export const openUrlNewTab = url => window.open(url, '_blank')

export const titleCase = text => startCase(toLower(text))

export const pipe = (...fns) =>
  fns.reduce(
    (f, g) =>
      (...args) =>
        g(f(...args))
  )

export const scrollToTop = () => window.scrollTo(0, 0)

export const getInitials = name => {
  const [firstName, lastName] = split(upperCase(name), ' ') ?? ''
  return `${head(firstName) ?? ''}${head(lastName) ?? ''}`
}

export const getEmailPrefix = email => email?.split('@')[0]

export const filterObject = (obj, predicate) =>
  Object.keys(obj)
    .filter(key => predicate(obj[key]))
    .reduce((res, key) => Object.assign(res, { [key]: obj[key] }), {})

export const isValidEmail = email => {
  if (!email || email.trim().includes(' ')) {
    return false
  }

  var re = /^[^@\s]+@[^@\s]+\.[^@\s]+$/
  return re.test(email)
}

export const isValidNumber = phone => {
  return /^[0-9]*$/.test(phone)
}

export const getStringInitials = (str = '') => {
  if (!str || !str?.length) {
    return ''
  }

  if (str.length < 2) {
    return str[0]
  }

  const words = str.replace(/[\s-]/g, '').split('')

  // Check if there are no spaces or dashes
  if (words.length === 1) {
    // Return the first letter and the middle letter (if present) separated by a dot
    const middleIndex = Math.floor(words[0].length / 2)
    return words[0][0] + (words[0].length % 2 === 0 ? '' : '.' + words[0][middleIndex])
  }

  // Map each word to its first letter and join with dots
  return words.map(word => word[0].toUpperCase()).join('.')
}

export const isValidZipcode = (zipcode, country = COUNTRY_US) => {
  let re
  switch (country) {
    case COUNTRY_AT:
    case COUNTRY_AU:
    case COUNTRY_NZ:
    case COUNTRY_BE:
    case COUNTRY_BG:
    case COUNTRY_CY:
    case COUNTRY_DK:
    case COUNTRY_HU:
    case COUNTRY_LU:
    case COUNTRY_CH:
    case COUNTRY_SI:
      re = /(^\d{4}$)/
      break
    case COUNTRY_CA:
      re = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z]\d[ABCEGHJ-NPRSTV-Z]\d$/
      break
    case COUNTRY_HR:
    case COUNTRY_EE:
    case COUNTRY_FI:
    case COUNTRY_FR:
    case COUNTRY_DE:
    case COUNTRY_IT:
    case COUNTRY_ES:
    case COUNTRY_TR:
    case COUNTRY_TC:
      re = /(^\d{5}$)/
      break
    case COUNTRY_CZ:
    case COUNTRY_GR:
    case COUNTRY_SK:
    case COUNTRY_SE:
      re = /^\d{3}\s*\d{2}$/
      break
    case COUNTRY_IN:
    case COUNTRY_RO:
      re = /(^\d{6}$)/
      break
    case COUNTRY_IL:
      re = /(^\d{7}$)/
      break
    case COUNTRY_IE:
      re = /(?:^[AC-FHKNPRTV-Y][0-9]{2}|D6W)[ -]?[0-9AC-FHKNPRTV-Y]{4}$/
      break
    case COUNTRY_LV:
      re = /^LV[- ]{0,1}\d{4}$/
      break
    case COUNTRY_LT:
      re = /^LT[- ]{0,1}\d{5}$/
      break
    case COUNTRY_MT:
      re = /^[A-Z]{3}\s{0,1}\d{4}$/
      break
    case COUNTRY_NL:
      re = /^\d{4}\s{0,1}[A-Z]{2}$/
      break
    case COUNTRY_PL:
      re = /^\d{2}-\d{3}$/
      break
    case COUNTRY_PT:
      re = /^\d{4}-\d{3}$/
      break
    case COUNTRY_BR:
      re = /^\d{5}-\d{3}$/
      break
    case COUNTRY_GB:
      re =
        /^(([A-Z]{1,2}[0-9][A-Z0-9]?|ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA) ?[0-9][A-Z]{2}|BFPO ?[0-9]{1,4}|(KY[0-9]|MSR|VG|AI)[ -]?[0-9]{4}|[A-Z]{2} ?[0-9]{2}|GE ?CX|GIR ?0A{2}|SAN ?TA1)$/
      break
    case COUNTRY_US:
    default:
      re = /(^\d{5}$)|(^\d{5}-\d{4}$)/
      break
  }

  return re.test(zipcode)
}

export const isValidUrl = string => {
  try {
    new URL(string)
  } catch (ex) {
    return false
  }

  return true
}

export const objectsDiff = (updatedValues, initialValues) => {
  const updated = {}
  for (const prop of Object.keys(updatedValues)) {
    if (initialValues[prop] !== updatedValues[prop]) {
      updated[prop] = updatedValues[prop]
    }
  }
  return updated
}

export const toAddressString = ({ zip = '', address1 = '', city = '', state = '', country = '' } = {}) => {
  return `${address1}, ${city}, ${state} ${zip}, ${country}`
}

export const filterObjectByFunction = (obj, predicate) => mapValues(obj, subObj => pickBy(subObj, predicate))

export const mockAsyncOperation = ({
  timeout = 3000,
  shouldThrow = false,
  callback = () => {
    console.log('AsyncOperation mock completed')
  }
}) =>
  new Promise((resolve, reject) => {
    setTimeout(() => (shouldThrow ? reject('fail mocked') : resolve(callback())), timeout)
  })

/**
 * Gets a value and performs a deep check whether all nested properties are empty using lodash's `isEmpty` function.
 * @example
 * ```
 * isDeepEmpty({}) // true
 * isDeepEmpty('') // true
 * isDeepEmpty([]) // true
 * isDeepEmpty({ a: {} }) // true
 * isDeepEmpty([ { a: {} } ]) // true
 * isDeepEmpty([ { a: '' } ]) // true
 * isDeepEmpty({ a: { b: 'c' } }) // false, because a.b has a real value
 * ```
 * @param {*} value
 * @returns {boolean} true if `value` is empty in all levels
 */
export const isDeepEmpty = value => {
  if (isEmpty(value)) {
    return true
  }

  if (typeof value === 'object' && value !== null) {
    return Object.values(value).every(isDeepEmpty)
  }

  return false
}

export default {
  filterObject,
  getInitials,
  pipe,
  scrollToTop,
  titleCase,
  getEmailPrefix,
  objectsDiff
}
