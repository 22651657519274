import React from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

const UserProfileTooltip = ({ lastLoginDate, email }) => {
  const { t } = useTranslation()

  return (
    <div style={{ padding: 4 }}>
      <strong>{t('common.appMenu.authProfiles.profileTooltip.lastLogin')}</strong>
      <br />
      {moment(lastLoginDate).format('LLL')}
      <div style={{ height: 10 }} />
      <strong>{t('common.appMenu.authProfiles.profileTooltip.loginEmail')}</strong>
      <br />
      {email}
    </div>
  )
}

export default UserProfileTooltip
