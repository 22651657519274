import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import GrinChip from '../GrinChip'
import { useTranslation } from 'react-i18next'
import { Assignee } from 'components/common/icons'
import Tooltip from '../Tooltip'
import NeedsAttention from '../icons/NeedsAttention'

const useStyles = makeStyles(() => ({
  chipContainer: {
    display: 'flex'
  },
  clickable: {
    cursor: 'pointer'
  },
  assigneeChipText: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: '190px'
  }
}))

const GrinTag = ({
  tag = {},
  className,
  onDelete = () => {},
  canDeleteSystem = false,
  canDeleteCustom = true,
  canDeleteStatus = false,
  canDeleteNeedsAttention = false,
  onClick = () => {},
  showUnseenIndicator = false,
  automationsPrefix = '',
  tooltipValue = ''
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const isSystemTag = useMemo(() => tag.tagClass === 'system', [tag])
  const isAssigneeTag = useMemo(() => tag.type === 'assignee', [tag])
  const isNeedsAttentionTag = useMemo(() => tag.type === 'scan' && tag.value === 'needsAttention', [tag])
  const isUrgentTag = useMemo(() => tag.type === 'scan' && tag.value === 'urgent', [tag])

  const tagSettings = useMemo(() => JSON.parse(tag.settings || '{}'), [tag.settings])
  const deleteSystem = useMemo(() => isSystemTag && canDeleteSystem, [isSystemTag, canDeleteSystem])
  const deleteCustom = useMemo(() => tag.type === 'custom' && canDeleteCustom, [tag, canDeleteCustom])
  const deleteStatus = useMemo(() => tag.type === 'status' && canDeleteStatus, [tag, canDeleteStatus])
  const deleteNeedsAttention = useMemo(
    () => tag.value === 'needsAttention' && canDeleteNeedsAttention,
    [tag, canDeleteNeedsAttention]
  )
  const deleteAssignee = useMemo(() => tag.type === 'assignee', [tag])
  const deleteable = useMemo(
    () =>
      onDelete &&
      (isUrgentTag || deleteSystem || deleteCustom || deleteStatus || deleteAssignee || deleteNeedsAttention),
    [onDelete, isUrgentTag, deleteSystem, deleteCustom, deleteStatus, deleteAssignee, deleteNeedsAttention]
  )
  const clickable = useMemo(
    () => (isSystemTag || ['program', 'treatment'].includes(tag.type) ? deleteable : true) && !!onClick,
    [deleteable, isSystemTag, onClick, tag.type]
  )

  return (
    <Tooltip
      arrow={true}
      disableTooltip={!tooltipValue}
      onClick={onClick}
      className={classes.chipContainer}
      value={tooltipValue}
      placement="bottom"
    >
      <GrinChip
        className={[clickable ? classes.clickable : '', className].join(' ')}
        labelClassName={isAssigneeTag ? classes.assigneeChipText : null}
        text={
          tag.type === 'custom'
            ? tag.value
            : (tag.type === 'status' || tag.type === 'assignee') && tag.label
            ? tag.label
            : t(`common.tags.${tag.value}`)
        }
        variant={tagSettings.variant}
        textTransform={tag.type === 'program' && tag.value !== 'serviceAccount' ? 'uppercase' : 'none'}
        backgroundColor={tagSettings.color || 'var(--bg-color-16)'}
        textColor={tagSettings.textColor || 'var(--text-color-7)'}
        noActionIcon={!deleteable}
        onDelete={deleteable ? onDelete : null}
        icon={
          isAssigneeTag ? (
            <Assignee style={{ marginLeft: 6, marginTop: 2 }} />
          ) : isNeedsAttentionTag ? (
            <NeedsAttention style={{ marginLeft: 6, marginTop: 2 }} circleColor={tagSettings.circleColor} />
          ) : null
        }
        size={isAssigneeTag ? 'medium' : 'small'}
        showUnseenIndicator={showUnseenIndicator}
        automationsPrefix={automationsPrefix}
      />
    </Tooltip>
  )
}

export default GrinTag
