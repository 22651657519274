import React, { useCallback, useMemo, useState } from 'react'
import { loadArchModels } from './archesViewerUtils'
import Grin3DViewer from 'components/common/Grin3DViewer/Grin3DViewer'
import { Grid, makeStyles } from '@material-ui/core'
import ViewerControls from './ViewerControls'
import { useTranslation } from 'react-i18next'
import { ORIGIN_ARHC_MODELS_TYPES } from './archesViewerConsts'
import { TreatmentTypes } from 'consts/treatmentConsts'
import PoseController from './PoseController'
import HintsOverlay from './HintsOverlay'
import { Poses, ViewerModes } from 'components/common/Grin3DViewer/Grin3DViewerConsts'
import { useDispatch } from 'react-redux'
import Actions from 'actions'
import useSyncedArchesViewer from './useSyncedArchesViewer'

const useStyles = makeStyles(theme => ({
  viewerContainer: {
    position: 'relative',
    padding: 20,
    height: '100%'
  },
  viewer: {
    paddingLeft: 20,
    width: '100%',
    height: '90%'
  }
}))

const CameraVectorByOcclusalPose = {
  // This is to allow rotation on the right direction
  // since both models are rotated 180 degrees
  [Poses.Upper]: -1,
  [Poses.Lower]: 1
}

const ArchesViewer = ({
  baseArchModels,
  currentArchModels,
  endArchModels,
  currentTxTrackerData,
  treatmentType,
  scanNumber,
  alignerNumber,
  isSmallerScreen = false,
  initAlignerNumber,
  endAlignerNumber,
  isCompareStlsModeOn,
  setIsCompareStlsModeOn = () => {},
  onSceneRemoved = () => {}
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [currentPose, setCurrentPose] = useState(Poses.Center)
  const [currentViewerMode, setCurrentViewerMode] = useState(ViewerModes.Regular)
  const [viewerCamera, setViewerCamera] = useState()
  const [viewerScene, setViewerScene] = useState()
  const [cameraControls, setCameraControls] = useState()
  const [currentViewerSettings, setCurrentViewerSettings] = useState({
    isBaseLayerVisible: true,
    isCurrentLayerVisible: true
  })

  const [selectedOriginModelType, setSelectedOriginModelType] = useState(
    treatmentType === TreatmentTypes.Aligners ? ORIGIN_ARHC_MODELS_TYPES.CURRENT : ORIGIN_ARHC_MODELS_TYPES.END
  )

  const { pose, viewerMode, viewerSettings, handleSetViewerSettings, handleSetPose, handleSetViewerMode } =
    useSyncedArchesViewer({
      setCurrentPose,
      setCurrentViewerMode,
      setCurrentViewerSettings,
      currentPose,
      currentViewerMode,
      currentViewerSettings
    })
  const originModelOptions = useMemo(
    () =>
      [
        {
          type: ORIGIN_ARHC_MODELS_TYPES.BASE,
          value: baseArchModels,
          label: t(
            `dialogs.treatmentTracker.viewerControls.base${
              treatmentType === TreatmentTypes.Aligners ? 'Aligners' : ''
            }`,
            { alignerNumber: initAlignerNumber }
          ),
          condition: () => true
        },
        {
          type: ORIGIN_ARHC_MODELS_TYPES.CURRENT,
          value: currentArchModels,
          label: t(
            `dialogs.treatmentTracker.viewerControls.current${
              treatmentType === TreatmentTypes.Aligners ? 'Aligners' : ''
            }`,
            { alignerNumber }
          ),
          condition: () => treatmentType === TreatmentTypes.Aligners
        },
        {
          type: ORIGIN_ARHC_MODELS_TYPES.END,
          value: endArchModels,
          label: t(
            `dialogs.treatmentTracker.viewerControls.end${treatmentType === TreatmentTypes.Aligners ? 'Aligners' : ''}`,
            { alignerNumber: endAlignerNumber }
          ),
          condition: () => true
        }
      ].filter(originModelOption => originModelOption.condition()),
    [
      baseArchModels,
      currentArchModels,
      endAlignerNumber,
      endArchModels,
      initAlignerNumber,
      t,
      treatmentType,
      alignerNumber
    ]
  )

  const selectedOriginModel = useMemo(
    () => originModelOptions.find(option => option.type === selectedOriginModelType).value,
    [originModelOptions, selectedOriginModelType]
  )
  const archesData = useMemo(
    () =>
      [
        {
          file: selectedOriginModel.upper,
          isUpper: true,
          isBase: true
        },
        {
          file: selectedOriginModel.lower,
          isUpper: false,
          isBase: true
        },
        {
          file: currentTxTrackerData.archModels.upperS3Keys.currentActualStl,
          isUpper: true,
          isBase: false
        },
        {
          file: currentTxTrackerData.archModels.lowerS3Keys.currentActualStl,
          isUpper: false,
          isBase: false
        }
      ].filter(
        arch =>
          (arch.isBase && viewerSettings.isBaseLayerVisible) || (!arch.isBase && viewerSettings.isCurrentLayerVisible)
      ),
    [selectedOriginModel, currentTxTrackerData, viewerSettings]
  )

  const handleViewerSceneLoaded = useCallback(
    ({ scene, camera, controls }) => {
      loadArchModels({
        scene,
        archesData,
        teethData: currentTxTrackerData.teeth,
        treatmentType,
        upperArchInitRotation: { x: Math.PI * 1.5, y: 0, z: 0 }, // Rotate model on X axis to be displayed from the front
        lowerArchInitRotation: { x: Math.PI * 1.5, y: 0, z: 0 }
      })
      setViewerScene(scene)
      setViewerCamera(camera)
      setCameraControls(controls)

      if (isCompareStlsModeOn) {
        dispatch(Actions.txTrackerCompareUpdateView(controls))
      }
    },
    [archesData, currentTxTrackerData.teeth, dispatch, isCompareStlsModeOn, treatmentType]
  )

  const handleOcclusalViewerSceneLoaded = useCallback(
    ({ scene, camera, controls }) => {
      loadArchModels({
        scene,
        archesData,
        pose,
        teethData: currentTxTrackerData.teeth,
        treatmentType,
        upperArchInitRotation: currentTxTrackerData.needsRotation
          ? { x: Math.PI, y: 0, z: (-10 * Math.PI) / 180 }
          : { x: Math.PI, y: 0, z: Math.PI / 180 },
        lowerArchInitRotation: currentTxTrackerData.needsRotation
          ? { x: 0, y: 0, z: (-10 * Math.PI) / 180 }
          : { x: 0, y: 0, z: Math.PI / 180 },
        upperArchOffset: 20,
        lowerArchOffset: -20
      })
      setViewerScene(scene)
      setViewerCamera(camera)
      setCameraControls(controls)

      if (isCompareStlsModeOn) {
        dispatch(Actions.txTrackerCompareUpdateView(controls))
      }
    },
    [
      archesData,
      currentTxTrackerData.needsRotation,
      currentTxTrackerData.teeth,
      dispatch,
      isCompareStlsModeOn,
      pose,
      treatmentType
    ]
  )

  return (
    <>
      <Grid container justifyContent="space-between" className={classes.viewerContainer}>
        <Grid item xs={12}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <ViewerControls
                viewerSettings={viewerSettings}
                setViewerSettings={handleSetViewerSettings}
                selectedOriginModelType={selectedOriginModelType}
                setSelectedOriginModelType={setSelectedOriginModelType}
                originModelOptions={originModelOptions}
                scanNumber={scanNumber}
                alignerNumber={alignerNumber}
                viewerScene={viewerScene}
                treatmentType={treatmentType}
                isCompareStlsModeOn={isCompareStlsModeOn}
                setIsCompareStlsModeOn={setIsCompareStlsModeOn}
              />
            </Grid>
            {viewerCamera && (
              <Grid item xs={12}>
                <PoseController
                  camera={viewerCamera}
                  currentPose={pose}
                  onSetPose={handleSetPose}
                  viewerMode={viewerMode}
                  onSetViewerMode={handleSetViewerMode}
                  cameraControls={cameraControls}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item className={classes.viewer}>
          {viewerMode === ViewerModes.Regular ? (
            <Grin3DViewer onSceneLoaded={handleViewerSceneLoaded} onSceneRemoved={onSceneRemoved} />
          ) : (
            <Grin3DViewer
              onSceneLoaded={handleOcclusalViewerSceneLoaded}
              onSceneRemoved={onSceneRemoved}
              rotateAroundZAxis
              cameraVector={CameraVectorByOcclusalPose[pose]}
            />
          )}
        </Grid>
      </Grid>
      <HintsOverlay />
    </>
  )
}

export default ArchesViewer
