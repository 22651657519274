import { makeStyles } from '@material-ui/styles'
import useQueryParams from 'hooks/useQueryParams'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { logInfo } from 'utils/logUtils'
import { trackEvent } from 'utils/analyticsUtils'
import DazzedHeading36 from 'components/common/text/DazzedHeading36'
import { Trans, useTranslation } from 'react-i18next'
import { Grid, useMediaQuery } from '@material-ui/core'
import OggHeading40 from 'components/common/text/OggHeading40'
import LoginIcon from './LoginIcon'
import WelcomeToGrin from '../WelcomeToGrin'
import GrinLogo from './GrinLogo'
import DazzedHeading20 from 'components/common/text/DazzedHeading20'
import PrimaryButton from 'components/common/buttons/PrimaryButton'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    width: '100%'
  },
  errorTitle: {
    textAlign: 'center',
    color: 'white',
    marginTop: 100
  },
  logo: {
    margin: 40
  },
  header: {
    margin: '20px 0 50px'
  },
  sectionTitle: {
    fontWeight: 600,
    color: 'white'
  },
  separator: {
    height: '1px',
    width: '100%',
    background: 'rgba(38, 64, 110, 1)',
    margin: '60px 0 50px 0'
  },
  outlinedButton: {
    backgroundColor: 'transparent',
    borderColor: 'var(--bg-color-7)'
  }
}))

const AuthCodeFlowPage = () => {
  const isSmallScreen = useMediaQuery('(max-width:660px)')
  const classes = useStyles({ isSmallScreen })

  const { appconfig } = useSelector(state => state.appReducer)
  const { queryParams } = useQueryParams()
  const { t } = useTranslation()

  const [errorState, setErrorState] = useState()

  const mode = useMemo(() => queryParams.mode?.toLowerCase() || 'login', [queryParams])

  const hostedUiUrl = useMemo(() => {
    const oauthDomain = appconfig?.awsExports?.oauth?.domain
    if (!oauthDomain || !queryParams.redirectUri || !queryParams.clientId) {
      return null
    }

    const state = queryParams.state ? `&state=${queryParams.state}` : ''
    const isLogoutMode = mode === 'logout'

    const authUrl =
      `https://${appconfig?.awsExports?.oauth?.domain}/${isLogoutMode ? 'logout' : 'oauth2/authorize'}` +
      `?client_id=${queryParams.clientId}` +
      `&response_type=code` +
      `&scope=grin-integrations/account-pairing` +
      state +
      `&redirect_uri=${encodeURIComponent(queryParams.redirectUri)}`

    return authUrl
  }, [appconfig, queryParams, mode])

  const sendLog = useCallback(
    (message, params = {}) => {
      trackEvent(`AuthCodeFlow - ${mode}: ${message}`, { ...params, authCodeMode: mode })
      logInfo(`AuthCodeFlow - ${mode}: ${message}`, { ...params, authCodeMode: mode })
    },
    [mode]
  )

  const enterErrorState = useCallback(
    ({ errorMessage }) => {
      sendLog(`redirect error: ${errorMessage}`, {
        error: errorMessage,
        queryParams
      })
      setErrorState(true)
    },
    [queryParams, sendLog]
  )

  const handleLoginClicked = useCallback(() => {
    if (hostedUiUrl) {
      sendLog(`Login Clicked`, { hostedUiUrl })
      window.location.href = hostedUiUrl
    }
  }, [hostedUiUrl, sendLog])

  const handleSignupClicked = useCallback(() => {
    const redirectUrl = appconfig?.app?.authCodeFlow?.signUpRedirectUrl
    if (redirectUrl) {
      sendLog('Sign up clicked', { redirectUrl })
      window.location.href = redirectUrl
    }
  }, [appconfig, sendLog])

  useEffect(() => {
    sendLog(`Init`, { queryParams })

    if (!queryParams.redirectUri) {
      enterErrorState({ errorMessage: 'missing query param redirectUri' })
      return
    }

    if (!queryParams.clientId) {
      enterErrorState({ errorMessage: 'missing query param clientId' })
      return
    }
  }, [queryParams, enterErrorState, sendLog])

  return !appconfig ? (
    <WelcomeToGrin />
  ) : (
    <Grid container className={classes.root} direction="column" alignItems="center" justifyContent="center">
      <Grid item className={classes.logo}>
        <GrinLogo />
      </Grid>
      {errorState ? (
        <Grid item>
          <DazzedHeading36 className={classes.errorTitle}>{t('pages.authCode.invalidLink')}</DazzedHeading36>
        </Grid>
      ) : (
        <>
          <Grid item className={classes.header}>
            <OggHeading40 dark>
              <Trans i18nKey="pages.authCode.title" components={{ br: <br /> }} />
            </OggHeading40>
          </Grid>
          <Grid item>
            <Grid container direction="column" alignItems="center" justifyContent="center" spacing={3}>
              <Grid item>
                <DazzedHeading20 className={classes.sectionTitle}>{t('pages.authCode.loginTitle')}</DazzedHeading20>
              </Grid>
              <Grid item>
                <LoginIcon />
              </Grid>
              <Grid item>
                <PrimaryButton fontWeight="bold" onClick={handleLoginClicked} label={t('pages.authCode.loginButton')} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ width: '100%' }}>
            <div className={classes.separator} />
          </Grid>
          <Grid item>
            <Grid container direction="column" alignItems="center" justifyContent="center" spacing={2}>
              <Grid item>
                <DazzedHeading20 className={classes.sectionTitle}>{t('pages.authCode.signupTitle')}</DazzedHeading20>
              </Grid>
              <Grid item>
                <PrimaryButton
                  label={t('pages.authCode.signupButton')}
                  onClick={handleSignupClicked}
                  fontWeight="bold"
                  className={classes.outlinedButton}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default AuthCodeFlowPage
