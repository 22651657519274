import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getObjectUrl } from 'utils/mediaUtils'

export default () => {
  const { initialScan } = useSelector(state => state.timelineReducer.scanFeed.oralRecords)

  const compositeImageSrc = useMemo(() => {
    const compositeS3Object = initialScan?.oralImages?.find(img => img?.key?.includes('private/compositeRecords'))
    if (compositeS3Object) {
      return getObjectUrl(compositeS3Object)
    }

    return null
  }, [initialScan])

  return {
    compositeImageSrc,
    date: initialScan?.date
  }
}
