import { MessageReadStatuses } from 'consts/chatConsts'
import moment from 'moment'
import { useMemo } from 'react'

/**
 * @param {Object} props
 * @param {String} props.messageSendDate - The date when the message was sent.
 * @param {string} props.lastReadDate - The date when the recipient last opened the chat.
 * @param {string} props.senderUserType - The GrinUser type of the sender. If the message was not sent by a doctor, the returned status is None.
 */
export default ({ messageSendDate, lastReadDate, senderUserType }) => {
  const readStatus = useMemo(() => {
    if (!lastReadDate || !messageSendDate || senderUserType?.toLowerCase() !== 'doctor') {
      return MessageReadStatuses.None
    }

    const isRead = moment(messageSendDate).isBefore(lastReadDate)
    return isRead ? MessageReadStatuses.Read : MessageReadStatuses.Unread
  }, [messageSendDate, lastReadDate, senderUserType])

  return readStatus
}
