import { makeStyles } from '@material-ui/styles'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import DazzedParagraph16 from '../../../common/text/DazzedParagraph16'
import DazzedParagraph12 from '../../../common/text/DazzedParagraph12'
import { getTransformedDescription } from '../../../../utils/billingUtils'
import { TableRow, TableCell } from '@material-ui/core'

const useStyles = makeStyles({
  root: {
    width: '100%',
    margin: '16px 8px',
    verticalAlign: 'top'
  },
  description: {
    fontSize: 14
  },
  paymentMethod: {
    fontSize: 14
  },
  creditCadBrand: {
    textTransform: 'capitalize',
    fontSize: 14
  },
  date: {
    fontSize: 14
  },
  user: {
    marginTop: 5,
    lineHeight: '20px',
    color: 'var(--text-color-11)'
  },
  price: {
    flex: 1,
    justifyContent: 'flex-end',
    display: 'flex',
    fontFamily: 'verdana',
    fontSize: 14
  }
})

const PaymentItem = ({
  type,
  description,
  date,
  price,
  creditCardBrand,
  creditCardLast4,
  scopesQuantity,
  creatorUserId
}) => {
  const classes = useStyles()
  const practiceMembers = useSelector(state => state.supportReducer.doctorsDashboard.practiceMembers.data)

  const transformedDescription = useMemo(
    () => getTransformedDescription({ type, description, scopesQuantity }),
    [type, description, scopesQuantity]
  )

  const creatorUser = useMemo(() => {
    if (!creatorUserId) {
      return practiceMembers && practiceMembers[0]
    }
    return practiceMembers?.find(member => member?.grinUserId === creatorUserId)
  }, [practiceMembers, creatorUserId])

  return (
    <TableRow className={classes.root}>
      <TableCell>
        <DazzedParagraph16 strong className={classes.date}>
          {date}
        </DazzedParagraph16>
      </TableCell>
      <TableCell>
        <DazzedParagraph16 strong className={classes.description}>
          {transformedDescription}
        </DazzedParagraph16>
        {creatorUser && (
          <DazzedParagraph12 className={classes.user}>
            By: {creatorUser.name}
            {creatorUser.roleDescription && ` - ${creatorUser.roleDescription}`}
          </DazzedParagraph12>
        )}
      </TableCell>
      <TableCell>
        <DazzedParagraph16 strong className={classes.paymentMethod}>
          <span className={classes.creditCadBrand}>{creditCardBrand}</span> xxxx-{creditCardLast4}
        </DazzedParagraph16>
      </TableCell>
      <TableCell>
        <DazzedParagraph16 strong className={classes.price}>
          {type === 'refund' && '-'}${price}
        </DazzedParagraph16>
      </TableCell>
    </TableRow>
  )
}

export default PaymentItem
