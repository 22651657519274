import { sortBy } from 'lodash'
import { updateAllWhere } from './arrayUtils'

export const isScanEligableForBeforeAfter = scan => {
  if (!scan || scan.withAligner || scan.scanSummaryStatus !== 'completed') {
    return false
  }

  const scanSummaryData = JSON.parse(scan.scanSummaryData || '{}')
  return !!scanSummaryData.sharpenedImages.center
}

export const scanHasScanSummaryCenterPose = scan =>
  scan?.scanSummaryStatus === 'completed' && !!JSON.parse(scan.scanSummaryData || '{}')?.normalImages?.center

export const getAggregateScans = grinScans => {
  let seenPairIds = new Set()
  let aggregated = []

  grinScans = sortBy(
    grinScans.filter(scan => scan.status !== 'uploading'),
    grinScan => grinScan.createdAt
  )
  grinScans.forEach(scan => {
    if (!scan.scanPairId || !seenPairIds.has(scan.scanPairId)) {
      seenPairIds.add(scan.scanPairId)
      aggregated.push([scan])
    } else {
      aggregated = aggregated.map(scans => {
        if (scan.scanPairId === scans[0].scanPairId) {
          return [...scans, scan]
        }
        return scans
      })
    }
  })

  aggregated = aggregated.filter(scans => !scans[0].scanPairId || (scans[0].scanPairId && scans.length === 2))
  return aggregated
}

export const generateScanNumberDict = aggGrinScans => {
  let dict = {}

  aggGrinScans.forEach((currScanPair, index) => {
    currScanPair.forEach(scan => (dict[scan.id] = index))
  })

  return dict
}

/**
 * Updates a list of GrinScans by applying the specified field updates
 * to the target scan and its paired scans within the list.
 * This method does not mutate the array, it returns a reference to a new array with the updates.
 *
 * @param {object} args
 * @param {Array} args.scans - A list of GrinScan objects.
 * @param {{id: string}} args.updatedScan - An object with the updated fields to apply. Must contain an `id` field.
 */
export const updatePairedScansInList = ({ scans = [], updatedScan = {} }) => {
  if (!updatedScan.id) {
    return scans
  }

  const originalScan = scans.find(scan => scan.id === updatedScan.id)
  if (!originalScan) {
    return scans
  }

  let newList = updateAllWhere(
    scans,
    scan => scan.id === updatedScan.id,
    scan => ({
      ...scan,
      ...updatedScan
    })
  )

  if (originalScan.scanPairId) {
    const fieldsToApply = { ...updatedScan }
    delete fieldsToApply.id

    newList = updateAllWhere(
      newList,
      scan => scan.scanPairId === originalScan.scanPairId,
      scan => ({
        ...scan,
        ...fieldsToApply
      })
    )
  }

  return newList
}
