import { makeStyles } from '@material-ui/core'
import Grid from '@mui/material/Grid'
import OggHeading40 from 'components/common/text/OggHeading40'
import useStaticData from 'hooks/useStaticData'
import React from 'react'
import { useTranslation } from 'react-i18next'
import ReleaseItem from './ReleaseItem'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '40px 100px'
  },
  divider: {
    width: '100%',
    borderTop: '1px solid #E5E7EA',
    marginTop: 20
  }
}))

const LatestReleases = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { releaseNotes } = useStaticData()

  return (
    <div className={classes.root}>
      <OggHeading40 textAlign="left">{t('common.appMenu.latestReleases.title')}</OggHeading40>
      <Grid container spacing={2}>
        {releaseNotes.map((release, index) => (
          <>
            <ReleaseItem
              title={release.title}
              description={release.description}
              mediaObject={release.mediaObject}
              lastModified={release.lastModified}
              version={release.originalData.version}
              versionGuid={release.originalData.versionGuid}
              key={index}
            />
            <div className={classes.divider} />
          </>
        ))}
      </Grid>
    </div>
  )
}

export default LatestReleases
