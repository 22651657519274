import { NOTIFICATION_CENTER_POLLING_INTERVAL_MS } from 'consts/notificationCenterConsts'
import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  useFetchGrinNotificationsInfiniteQuery,
  useFetchTotalUnreadNotificationsQuery
} from 'store/modules/NotificationCenter'
import { mapNotificationStatus } from 'utils/notificationCenterUtils'

/**
 * A hook to handle fetching grin notifications and total unread notifications.
 * @param {object} [props]
 * @param {boolean} [props.refetchOnMount=false] - Whether to refetch notifications on mount or use the cached data instead (if cache exists). Defaults to `false` to prevent unnecessary refetches. *This is an RTK parameter.*
 */
export default ({ refetchOnMount = true }) => {
  const { isOpen, filters } = useSelector(state => state.notificationCenterReducer)
  const grinUserId = useSelector(state => state.profileReducer.doctor?.user?.id)

  useFetchTotalUnreadNotificationsQuery(null, {
    pollingInterval: NOTIFICATION_CENTER_POLLING_INTERVAL_MS,
    skip: !grinUserId
  })

  const {
    currentData: grinNotificationsData,
    isLoading,
    isFetching,
    isError,
    refetch,
    fetchNextPage
  } = useFetchGrinNotificationsInfiniteQuery(
    { grinUserId, filters },
    {
      skip: !grinUserId || !isOpen,
      initialPageParam: 0,
      pollingInterval: isOpen ? NOTIFICATION_CENTER_POLLING_INTERVAL_MS : 0,
      skipPollingIfUnfocused: true,
      refetchOnMountOrArgChange: refetchOnMount
    }
  )

  const mapNotification = useCallback(
    grinNotification => ({
      ...grinNotification,
      status: mapNotificationStatus({
        originalStatus: grinNotification.status,
        eventDate: grinNotification.eventDate,
        lastClearAllDate: filters.lastClearAllDate,
        lastMarkAllAsReadDate: filters.lastMarkAllAsReadDate
      })
    }),
    [filters]
  )

  const grinNotifications = useMemo(
    () => grinNotificationsData?.pages.map(page => page.items.map(mapNotification)).flat(),
    [grinNotificationsData, mapNotification]
  )
  const hasMorePages = useMemo(
    () => grinNotificationsData?.pages[grinNotificationsData.pages.length - 1].hasMore,
    [grinNotificationsData]
  )

  return {
    hasData: grinNotifications?.length > 0,
    grinNotifications,
    isLoading: isLoading || isFetching,
    isError,
    hasMorePages,
    refetch,
    fetchNextPage
  }
}
