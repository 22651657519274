import { NUM_OF_MONTHS_TO_EXPIRE_TRUSTED_BROWSER } from 'consts/authConsts'
import _ from 'lodash'
import moment from 'moment'
import { trackEvent } from 'utils/analyticsUtils'
import { getWebClientId } from 'utils/awsUtils'
import { logInfo, logWarn } from 'utils/logUtils'
import { getDoctorId, setDoctorId, setDoctorUsername } from 'utils/storageUtils'

const AUTH_PROFILES_LOCAL_STORAGE_KEY = 'GrinAuthProfiles'

/**
 * Creates a new account profile out of the currently logged in user and stores it's data on the local storage.
 */
export const storeCurrentUserProfile = ({ displayName, avatar, email }) => {
  const currentUsername = getCurrentUsername()
  const doctorId = getDoctorId()

  logInfo(`storeCurrentUserProfile - storing user profile: ${currentUsername} (doctor id: ${doctorId})`, {
    currentUsername,
    doctorId,
    avatar,
    email
  })

  updateAuthProfile({
    username: currentUsername,
    data: {
      doctorId,
      email,
      displayName,
      avatar,
      lastLoginDate: new Date().toISOString()
    }
  })
}

export const getAuthProfiles = () => JSON.parse(localStorage.getItem(AUTH_PROFILES_LOCAL_STORAGE_KEY) || '{}')

export const countAuthProfiles = () => Object.keys(getAuthProfiles()).length

export const getCurrentAuthProfile = () => getAuthProfile(getCurrentUsername())

/**
 * Switches the currently logged account and refreshes the page if succeeded.
 * @param {string} username
 */
export const switchAccount = username => {
  const authProfile = getAuthProfile(username)

  if (!authProfile) {
    logWarn(`Switch account - no auth profile for username: ${username}`)
    return
  }

  setLastAuthUser(username)
  setDoctorId(authProfile.doctorId)
  setDoctorUsername(username)

  logInfo(`Switch account - local storage variables loaded, refreshing... (username: ${username})`)

  window.location.reload()
}

export const setCustomProfileDisplayName = ({ username, displayName }) => {
  updateAuthProfile({
    username,
    withDisplayName: true,
    data: {
      displayName,
      hasCustomDisplayName: true
    }
  })

  return getAuthProfiles()
}

export const forgetUserProfile = ({ username }) => {
  const profiles = getAuthProfiles()
  delete profiles[username]
  localStorage.setItem(AUTH_PROFILES_LOCAL_STORAGE_KEY, JSON.stringify(profiles))

  return getAuthProfiles()
}

export const getMostRecentlyUsedProfile = () => {
  const profiles = Object.values(getAuthProfiles())
  const lastProfile = _.maxBy(profiles, 'lastLoginDate')
  return lastProfile
}

const getAuthProfile = username => {
  const profiles = getAuthProfiles()
  return profiles[username]
}

/**
 * Updating the profile data in the local storage with the new `data` provided while merging the preivous properties.
 *
 * @param {Object} args
 * @param {string} args.username - the profile username to update.
 * @param {Object} args.data - the profile data that will be updated.
 * @param {boolean} args.withDisplayName - if this profile already has a custom display name, this parameter must be `true` to change it.
 */
const updateAuthProfile = ({ username, data, withDisplayName = false }) => {
  const profiles = getAuthProfiles()
  const authProfile = profiles[username]

  const displayName = authProfile?.hasCustomDisplayName && !withDisplayName ? authProfile.displayName : data.displayName

  localStorage.setItem(
    AUTH_PROFILES_LOCAL_STORAGE_KEY,
    JSON.stringify({
      ...profiles,
      [username]: {
        ...authProfile,
        ...data,
        displayName,
        username
      }
    })
  )
}

const getCurrentUsername = () => localStorage.getItem(`CognitoIdentityServiceProvider.${getWebClientId()}.LastAuthUser`)

const setLastAuthUser = username =>
  localStorage.setItem(`CognitoIdentityServiceProvider.${getWebClientId()}.LastAuthUser`, username)

export const clearExpiredTrustedBrowser = ({ idToken, username }) => {
  const loginTime = moment(idToken.authTime * 1000)

  const browserExpirationTime = moment().subtract(NUM_OF_MONTHS_TO_EXPIRE_TRUSTED_BROWSER, 'months')

  if (loginTime.isBefore(browserExpirationTime)) {
    trackEvent('Trust browser has expired, clearing from local storage', { username })
    logInfo('Trust browser has expired, clearing from local storage', {
      username
    })

    const deviceKeyCache = `CognitoIdentityServiceProvider.${getWebClientId()}.${username}.deviceKey`
    const deviceGroupKeyCache = `CognitoIdentityServiceProvider.${getWebClientId()}.${username}.deviceGroupKey`

    localStorage.removeItem(deviceKeyCache)
    localStorage.removeItem(deviceGroupKeyCache)
  }
}
