import React, { useMemo, useState, useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import BaseModal from '../common/modals/BaseModal'
import { useTranslation } from 'react-i18next'
import DazzedParagraph14 from './text/DazzedParagraph14'
import DazzedParagraph16 from './text/DazzedParagraph16'
import { Grid } from '@material-ui/core'
import TextAreaAutosize from 'components/common/TextareaAutosize'
import { isMobile } from 'utils/mobileUtils'

const useStyles = makeStyles(theme => ({
  dialog: {
    width: 480,
    borderRadius: 20,
    justifyContent: 'center'
  },
  content: {
    height: isMobile() ? 'auto' : '100%'
  },
  textAreaContainer: {
    textAlign: 'center'
  },
  textArea: {
    border: '1px solid #ACB0B7',
    borderRadius: 8,
    fontFamily: 'Dazzed',
    fontSize: 14,
    height: 120
  }
}))

const ConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  text,
  acceptButtonText,
  declineButtonText,
  withSecondaryButton = true,
  title,
  additionalText = '',
  AdditionalComponent,
  largerButtons = false,
  smallText = false,
  withComment,
  commentFieldTitle = '',
  commentFieldPlaceholder = '',
  fullScreen = false,
  isLoading = false,
  className = ''
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const TextComponent = useMemo(() => (smallText ? DazzedParagraph14 : DazzedParagraph16), [smallText])
  const [comment, setComment] = useState('')

  const handleConfirm = useCallback(() => {
    onConfirm({ comment })
  }, [onConfirm, comment])

  return (
    <BaseModal
      className={[classes.dialog, className].join(' ')}
      open={isOpen}
      handleClose={onClose}
      onSecondaryBtnClick={onClose}
      onPrimaryBtnClick={handleConfirm}
      primaryLabel={acceptButtonText || t('general.accept')}
      secondaryLabel={withSecondaryButton && (declineButtonText || t('general.cancel'))}
      title={title || t('dialogs.confirmation.title')}
      variant={fullScreen ? 'fullscreen' : 'alert'}
      largerButtons={largerButtons}
      isLoading={isLoading}
    >
      <Grid container alignItems="center" justifyContent="center" className={classes.content}>
        <Grid item xs={12}>
          <TextComponent textAlign="center">{text}</TextComponent>
        </Grid>
        {additionalText && (
          <Grid item xs={12}>
            <TextComponent textAlign="center" strong>
              {additionalText}
            </TextComponent>
          </Grid>
        )}
        {AdditionalComponent && (
          <Grid item xs={12}>
            {AdditionalComponent}
          </Grid>
        )}
        {withComment && (
          <Grid container direction="column">
            <Grid item xs={12}>
              <TextComponent textAlign="center">{commentFieldTitle}</TextComponent>
            </Grid>
            <Grid item xs={12}>
              <TextAreaAutosize
                rowsMax={6}
                style={{ bright: true, thick: true }}
                className={classes.textArea}
                keepErrorContainerWhenInactive={false}
                value={comment}
                placeholder={commentFieldPlaceholder}
                inputStyle={{
                  height: 100,
                  width: '90%',
                  marginTop: 10,
                  padding: 10
                }}
                containerClassName={classes.textAreaContainer}
                setValue={setComment}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </BaseModal>
  )
}

export default ConfirmationModal
