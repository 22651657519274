import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { createPracticeMemberTagValue } from 'utils/tagUtils'
import Actions from 'actions'
import { trackEvent } from 'utils/analyticsUtils'
import usePatientTags from 'hooks/usePatientTags'
import { v4 } from 'uuid'
import useFeatureFlags from 'hooks/useFeatureFlags'
import { useCreateNoteMutation } from 'store/modules/PatientNotes'
import useShowSnackbar from 'hooks/useShowSnackbar'
import { mapToCreateNoteInput } from 'utils/mappers/noteMappers'

const UNASSIGNED = 'Unassigned'

export const useAssignPatient = ({ withUnassignOption = true }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { data: practiceMembers } = useSelector(state => state.profileReducer.practiceMembers)
  const { patient: assignPatient } = useSelector(state => state.practiceReducer.assignPatient)
  const { assigneeTag } = usePatientTags({ patient: assignPatient || {} })
  const { grinDesignRefresh: grinDesignRefreshFF } = useFeatureFlags()
  const [createNote, { isError: isFailedCreatingNote }] = useCreateNoteMutation()

  useShowSnackbar({
    isOpen: isFailedCreatingNote,
    message: t('dialogs.assignPatient.errorCreatingNote')
  })

  const [assignee, setAssignee] = useState()
  const [note, setNote] = useState('')

  const practiceMembersSelectOptions = useMemo(() => {
    const options = [
      withUnassignOption ? { value: UNASSIGNED, label: t('dialogs.assignPatient.unassigned') } : null,
      ...(practiceMembers || [])
        .filter(practiceMember => practiceMember.status !== 'invited')
        .map(practiceMember => ({
          value: practiceMember.id,
          label: createPracticeMemberTagValue(practiceMember),
          disabled: practiceMember.status === 'disabled'
        }))
    ]

    return options.filter(opt => !!opt)
  }, [practiceMembers, t, withUnassignOption])

  const handleClose = useCallback(() => {
    dispatch(Actions.setAssignPatient(null))
    setNote('')
  }, [dispatch])

  const handleSubmit = useCallback(async () => {
    const pmToAssign = practiceMembersSelectOptions.find(option => option.value === assignee)
    trackEvent('Assign patient - doctor confirm assign patient', {
      newAssignee: pmToAssign.value,
      source: 'Assign patient dialog',
      withNote: !!note
    })

    if (pmToAssign.value === UNASSIGNED) {
      dispatch(
        Actions.unassignPracticeMemberFromPatient({
          patientId: assignPatient.id,
          assigneePatientTagId: assigneeTag?.id
        })
      )
    } else {
      const notePayload = {
        id: v4(),
        noteText: note,
        a_doctor: assignPatient.a_doctor
      }

      dispatch(
        Actions.assignPatientToPracticeMember({
          patientId: assignPatient.id,
          patientUserId: assignPatient.user.id || assignPatient.id,
          assigneeId: assignee,
          tag: pmToAssign.label,
          hasNewDesignFF: grinDesignRefreshFF,
          note: note ? notePayload : null
        })
      )

      if (grinDesignRefreshFF && !!note) {
        const noteInput = mapToCreateNoteInput([note.trim()], assignee)
        const payload = {
          ...noteInput,
          a_doctor: assignPatient.a_doctor,
          metadata: JSON.stringify({
            ...JSON.parse(noteInput.metadata),
            assigneeId: assignee
          })
        }

        await createNote({ variables: payload })
      }
    }

    handleClose()
  }, [
    practiceMembersSelectOptions,
    note,
    handleClose,
    assignee,
    dispatch,
    assignPatient,
    assigneeTag,
    grinDesignRefreshFF,
    createNote
  ])

  useEffect(() => {
    setAssignee(assigneeTag?.assigneeId || UNASSIGNED)
  }, [assigneeTag])

  return {
    practiceMembersSelectOptions,
    setNote,
    note,
    assignee,
    setAssignee,
    handleSubmit,
    handleClose
  }
}
