import Amplify, { Auth, PubSub } from 'aws-amplify'
import { MqttOverWSProvider } from '@aws-amplify/pubsub'
import { ofType } from 'redux-observable'
import * as Rx from 'rxjs'
import { from, of, delayWhen, timer } from 'rxjs'
import { catchError, filter, map, mergeMap, switchMap, tap, withLatestFrom, takeUntil, pluck } from 'rxjs/operators'
import Actions from 'actions'
import { getEnvironment } from '../utils/awsUtils'
import { v4 as uuidv4 } from 'uuid'
import { logInfo } from 'utils/logUtils'
import { isUserOfRole } from 'utils/authUtils'
import { Roles } from 'consts/authConsts'

export const configurePubsubEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.CONFIGURE_PUBSUB),
    withLatestFrom(state$),
    map(([, state]) => ({
      pubsubEndpoint: state.appReducer.appconfig?.app?.pubsub?.endpoint,
      clientId: uuidv4(),
      doctor: state.profileReducer.doctor
    })),
    switchMap(({ pubsubEndpoint, clientId, doctor }) =>
      from(Auth.currentSession()).pipe(
        map(cognitoUser => cognitoUser?.accessToken?.jwtToken),
        tap(token => {
          PubSub.removePluggable('MqttOverWSProvider')
          Amplify.addPluggable(
            new MqttOverWSProvider({
              aws_pubsub_endpoint: `wss://${pubsubEndpoint}/mqtt?token=${token}`,
              clientId
            })
          )
          logInfo(`IoT configured. client id: ${clientId}`, {
            doctorId: doctor?.id,
            email: doctor?.email,
            username: doctor?.username,
            clientId
          })
        }),
        mergeMap(token =>
          of(
            pubsubEndpoint && token
              ? Actions.requestSubscribeToNotifications()
              : Actions.requestSubscribeToNotificationsFailed({
                  retriable: false,
                  message: 'missing pubsub endpoint or token',
                  pubsubEndpoint,
                  token
                })
          )
        )
      )
    )
  )

let pubsubSubscription = null
export const subscribeToPubsubNotifications = (action$, state$) =>
  action$.pipe(
    ofType(Actions.REQUEST_SUBSCRIBE_TO_NOTIFICATIONS),
    filter(() => !pubsubSubscription || pubsubSubscription?.closed),
    withLatestFrom(state$),
    map(([action, state]) => ({
      username: state.profileReducer.doctor.username,
      env: getEnvironment(),
      allowedGroups: JSON.parse(state.profileReducer.doctor.user.allowed_groups_permissions || '[]')
    })),
    map(({ allowedGroups, username, env }) => ({
      topics: [`${env}/${username}`].concat(allowedGroups.map(groupPermission => `${env}/${groupPermission.groupKey}`))
    })),
    tap(({ topics }) => logInfo(`REQUEST_SUBSCRIBE_TO_NOTIFICATIONS`, { topics })),
    switchMap(({ topics }) =>
      from(
        new Rx.Observable(observer => {
          pubsubSubscription = PubSub.subscribe(topics).subscribe(observer)
          return pubsubSubscription
        }).pipe(
          map(event => event.value),
          mergeMap(notification => of(Actions.notificationReceived(notification))),
          catchError(error =>
            of(Actions.requestSubscribeToNotificationsFailed({ error, retriable: true, message: 'IoT Error' }))
          )
        )
      )
    )
  )

export const pubsubUnsubscribe = action$ =>
  action$.pipe(
    ofType(Actions.SIGNOUT_REQUESTED),
    filter(() => pubsubSubscription && !pubsubSubscription.closed),
    tap(() => pubsubSubscription.unsubscribe())
  )

export const subscribeToNotificationsRetry = (action$, state$) =>
  action$.pipe(
    ofType(Actions.REQUEST_SUBSCRIBE_TO_NOTIFICATIONS_FAILED),
    withLatestFrom(state$),
    map(([action, state]) => ({
      retriable: action.payload?.retriable,
      grinUserId: state.profileReducer.doctor.user?.id,
      doctorId: state.profileReducer.doctor?.id,
      subscribeToNotificationsAttepmts: state.appReducer.subscribeToNotificationsAttepmts,
      maxAttempts: state.appReducer.appconfig?.app?.pubsub?.maxAttempts || 12,
      error: action.payload,
      username: state.profileReducer.doctor?.username,
      email: state.profileReducer.doctor?.email
    })),
    filter(({ retriable }) => retriable),
    delayWhen(({ subscribeToNotificationsAttepmts }) => timer(1000 * Math.pow(1.5, subscribeToNotificationsAttepmts))), // at 12, will wait about 2 minutes
    tap(payload => logInfo('PubSub retry:', payload)),
    mergeMap(({ grinUserId, subscribeToNotificationsAttepmts, maxAttempts, doctorId, error, username }) =>
      subscribeToNotificationsAttepmts >= maxAttempts
        ? of(
            Actions.pubsubConnectFailed({
              error,
              doctorId,
              maxAttempts,
              subscribeToNotificationsAttepmts,
              username
            })
          )
        : of(Actions.configurePubsub())
    )
  )

export const pubsubResetTrigger = (action$, state$) =>
  action$.pipe(
    ofType(Actions.PUBSUB_CONNECT_FAILED),
    withLatestFrom(state$),
    map(([, state]) => ({
      resetInSeconds: state.appReducer.appconfig?.app?.pubsub?.stateResetSeconds || 600,
      doctorId: state.profileReducer.doctor?.id,
      username: state.profileReducer.doctor?.username
    })),
    tap(({ resetInSeconds, doctorId, username }) =>
      logInfo(`reseting PubSub state in: ${resetInSeconds} seconds`, { doctorId, username })
    ),
    delayWhen(({ resetInSeconds }) => timer(1000 * resetInSeconds)),
    map(() => Actions.pubsubReset())
  )

export const pubsubReset = action$ =>
  action$.pipe(
    ofType(Actions.PUBSUB_RESET),
    map(() => Actions.configurePubsub())
  )

export const subscribeToContextNotificationsEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.SUBSCRIBE_TO_CONTEXT_NOTIFICATIONS),
    withLatestFrom(state$),
    map(([action, state]) => ({
      env: getEnvironment(),
      allowedGroups: JSON.parse(state.profileReducer.doctor.user.allowed_groups_permissions || '[]'),
      context: action.payload.context,
      attempt: action.payload.attempt || 1,
      grinGeneralTopic: state.appReducer.appconfig?.app?.pubsub?.grinGeneralTopic
    })),
    map(({ env, allowedGroups, context, attempt, grinGeneralTopic }) => ({
      context,
      topics: isUserOfRole([Roles.Admin]) // Not using isUserOfAnyAdminRole by intention - want to make sure this is a system admin and not a DSO or something.
        ? [`${env}/${grinGeneralTopic}/${context}`]
        : allowedGroups.map(groupPermission => `${env}/${groupPermission.groupKey}/${context}`),
      attempt
    })),
    tap(({ topics, context, attempts }) =>
      logInfo(`SUBSCRIBE_TO_CONTEXT_NOTIFICATIONS: ${context}`, {
        context,
        topics
      })
    ),
    mergeMap(({ topics, attempt, context }) =>
      from(new Rx.Observable(observer => PubSub.subscribe(topics).subscribe(observer))).pipe(
        mergeMap(notification => of(Actions.notificationReceived(notification.value))),
        takeUntil(
          action$.pipe(
            ofType(Actions.UNSUBSCRIBE_FROM_CONTEXT_NOTIFICATIONS),
            filter(action => action.payload.context === context)
          )
        ),
        catchError(error =>
          of(
            Actions.subscribeToContextNotificationsFailed({
              error,
              retriable: true,
              message: 'IoT Error',
              attempt,
              context
            })
          )
        )
      )
    )
  )

export const subscribeToContextNotificationsRetryTrigger = action$ =>
  action$.pipe(
    ofType(Actions.SUBSCRIBE_TO_CONTEXT_NOTIFICATIONS_FAILED),
    pluck('payload'),
    map(payload => ({
      ...payload,
      delay: 1000 * Math.pow(1.5, payload.attempt)
    })),
    tap(payload => logInfo(`SUBSCRIBE_TO_CONTEXT_NOTIFICATIONS_FAILED: ${payload.context}`, { ...payload })),
    filter(({ retriable }) => retriable),
    delayWhen(({ delay }) => timer(delay)),
    tap(({ context, attempt }) =>
      logInfo(`SUBSCRIBE_TO_CONTEXT_NOTIFICATIONS_FAILED: retrying. context: ${context}`, {
        context,
        attempt
      })
    ),
    map(({ context, attempt }) =>
      Actions.subscribeToContextNotifications({
        context,
        attempt: attempt + 1
      })
    )
  )
