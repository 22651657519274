import React, { useCallback, useMemo, useState } from 'react'
import { Grid, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import KebabMenu from 'components/common/icons/KebabMenu'
import GrinMenu from 'components/common/menu/GrinMenu'
import GrinMenuItem from 'components/common/menu/GrinMenuItem'
import Pencil from 'components/common/icons/Pencil3'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import DeleteAccount from 'components/common/icons/DeleteAccount'
import { trackEvent } from 'utils/analyticsUtils'

const useStyles = makeStyles(theme => ({
  userAccountActionsMenuRoot: {
    borderRadius: 8,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  }
}))

const UserAccountActionsMenu = ({ onEditDisplayNameClicked, onForgetAccountClicked }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const actions = useMemo(
    () =>
      [
        {
          icon: <Pencil color="rgba(33, 33, 33, 1)" />,
          label: t('common.appMenu.authProfiles.actions.editDisplayName'),
          onClick: onEditDisplayNameClicked,
          visible: true
        },
        {
          icon: <DeleteAccount />,
          label: t('common.appMenu.authProfiles.actions.forgetAccount'),
          onClick: onForgetAccountClicked,
          visible: !!onForgetAccountClicked
        }
      ].filter(action => action.visible),
    [t, onEditDisplayNameClicked, onForgetAccountClicked]
  )

  const handleActionClicked = useCallback((e, action) => {
    e.stopPropagation()
    e.preventDefault()
    trackEvent('App profile - action clicked', { action: action.label })
    action.onClick()
    setIsMenuOpen(false)
  }, [])

  const handleOpen = useCallback(e => {
    e.stopPropagation()
    e.preventDefault()
    setIsMenuOpen(true)
  }, [])

  const handleClose = useCallback(e => {
    e.stopPropagation()
    e.preventDefault()
    setIsMenuOpen(false)
  }, [])

  return (
    <GrinMenu
      open={isMenuOpen}
      onOpen={handleOpen}
      onClose={handleClose}
      transformOrigin={{ horizontal: 40, vertical: 0 }}
      className={classes.userAccountActionsMenuRoot}
      triggerComponent={
        <IconButton size="small" style={{ padding: 7 }}>
          <KebabMenu />
        </IconButton>
      }
    >
      {actions.map(action => (
        <GrinMenuItem key={action.label} onClick={e => handleActionClicked(e, action)}>
          <Grid container spacing={1} alignItems="center">
            <Grid item style={{ lineHeight: 0.8 }}>
              {action.icon}
            </Grid>
            <Grid item>
              <DazzedParagraph16>{action.label}</DazzedParagraph16>
            </Grid>
          </Grid>
        </GrinMenuItem>
      ))}
    </GrinMenu>
  )
}

export default UserAccountActionsMenu
