import React, { useCallback, useMemo, useState } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { Trash2, UploadedStlPlaceholder } from 'components/common/icons'
import { getObjectUrl } from 'utils/mediaUtils'
import fileExtension from 'file-extension'
import ImagesViewer from 'components/common/ImagesViewer'
import { trackEvent } from 'utils/analyticsUtils'
import { IMAGE_FILE_EXTENSIONS } from 'consts/fileConsts'

const useStyles = makeStyles(theme => ({
  attachFileLabel: {
    textDecoration: 'underline'
  },
  attachFileIcon: {
    color: 'var(--text-color-25)',
    fontSize: 16
  },
  attachFileIconContainer: {
    paddingRight: '0px !important'
  },
  attachmentItem: {
    position: 'relative',
    marginRight: 7
  },
  unselectFileContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    background: '#fff',
    boxShadow: '0px 1px 5px -1px rgba(0, 0, 0, 0.5)',
    padding: '3px 5px 0 5px',
    borderRadius: 10,
    cursor: 'pointer',
    opacity: 0,
    transition: '.3s',
    '&:hover': {
      opacity: 1
    }
  },
  attachmentPreview: {
    objectFit: 'cover',
    width: '70px',
    height: '52px',
    borderRadius: '8px',
    '&:hover': {
      '& + $unselectFileContainer': {
        opacity: 1
      }
    },
    cursor: 'pointer'
  },
  attachmentsContainer: {
    maxWidth: 385,
    overflowX: 'auto',
    '&::-webkit-scrollbar': {
      height: 8
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '70px'
    }
  }
}))

const Attachments = ({ files, onUnselectFile, editable = true }) => {
  const classes = useStyles()

  const [isImageViewerOpen, setIsImageViewerOpen] = useState(false)
  const [activeImageIndex, setActiveImageIndex] = useState()

  const imagesAttachments = useMemo(
    () => files.filter(file => IMAGE_FILE_EXTENSIONS.includes(fileExtension(file.key))),
    [files]
  )
  const imagesUrls = useMemo(() => imagesAttachments.filter(image => !!image.url), [imagesAttachments])
  const imagesS3Objects = useMemo(() => imagesAttachments.filter(image => !image.url), [imagesAttachments])

  const handleViewPreview = useCallback(fileIndex => {
    trackEvent('Share scan - Attachments - View file preview clicked')
    setActiveImageIndex(fileIndex)
    setIsImageViewerOpen(true)
  }, [])

  return (
    <>
      <Grid container alignItems="center" wrap="nowrap" className={classes.attachmentsContainer}>
        {files.map((file, fileIndex) => (
          <Grid item key={file.key} className={classes.attachmentItem}>
            {fileExtension(file.key) === 'stl' ? (
              <UploadedStlPlaceholder />
            ) : (
              <img
                src={file.url || getObjectUrl(file)}
                alt=""
                className={classes.attachmentPreview}
                onClick={() => handleViewPreview(fileIndex)}
              />
            )}
            {editable && (
              <div onClick={() => onUnselectFile(file.key)} className={classes.unselectFileContainer}>
                <Trash2 />
              </div>
            )}
          </Grid>
        ))}
      </Grid>
      <ImagesViewer
        isVisible={isImageViewerOpen}
        activeIndex={activeImageIndex}
        onClose={() => setIsImageViewerOpen(false)}
        imageS3Objects={imagesS3Objects}
        imageUrls={imagesUrls}
      />
    </>
  )
}

export default Attachments
