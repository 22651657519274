import React, { createRef, useMemo } from 'react'
import TitleWithText from 'components/Patients/PatientGuidelines/PatientBrief/Categories/TitleWithText'
import { Grid } from '@material-ui/core'
import { sortTeeth } from 'utils/giUtils'
import NightTimeOnly from '../Actions/NightTimeOnly'
import ElasticsGridPopup from './ElasticsGridPopup'

const Elastics = ({ items }) => {
  const { elastics, nightTimeOnly } = items

  const elasticItems = useMemo(
    () =>
      elastics.map(elastic => ({
        ref: createRef(),
        title: elastic.name,
        content: sortTeeth(Object.keys(elastic.selections))
          .map(tooth => `${tooth} (${elastic.selections[tooth]})`)
          .join(', ')
      })),
    [elastics]
  )

  return (
    <>
      <Grid container direction="column" spacing={2}>
        {elasticItems.map(item => (
          <>
            <Grid item key={item.title}>
              <TitleWithText title={item.title} text={<span ref={item.ref}>{item.content}</span>} />
            </Grid>
            <ElasticsGridPopup anchorEl={item.ref.current} elastics={elastics} offset={{ x: 315, y: 20 }} />
          </>
        ))}
      </Grid>
      <NightTimeOnly checked={nightTimeOnly} />
    </>
  )
}

export default Elastics
