import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { isMobile, isSafari } from 'react-device-detect'
import Lottie from 'lottie-web'
import WelcomeAnimation from '../../resources/animations/welcome-page.json'
import WelcomeAnimationMobile from '../../resources/animations/welcome-page.mobile.json'

const useStyles = makeStyles(theme => ({
  welcomeContainer: {
    height: '100vh',
    transform: 'scale(70%)',
    backgroundColor: theme.palette.primary.main
  },
  welcomeAnimation: {
    height: '100%',
    width: '100%',
    maxWidth: isSafari ? 1000 : 1500,
    margin: 'auto'
  }
}))

const WelcomeToGrin = () => {
  const classes = useStyles()

  useEffect(() => {
    Lottie.loadAnimation({
      container: document.querySelector('#welcomeAnimation'),
      animationData: isMobile ? WelcomeAnimationMobile : WelcomeAnimation,
      loop: false
    })
  }, [])

  return (
    <div className={classes.welcomeContainer}>
      <div id="welcomeAnimation" className={classes.welcomeAnimation} />
    </div>
  )
}

export default WelcomeToGrin
