import useQueryParams from 'hooks/useQueryParams'
import { useCallback } from 'react'

export default ({ nextScanTimelineItemId, previousScanTimelineItemId }) => {
  const { addQueryParam } = useQueryParams()

  const navigateToTimelineItem = useCallback(
    timelineItemId => {
      addQueryParam({ timelineItem: timelineItemId })
    },
    [addQueryParam]
  )

  const navigateToNextScan = useCallback(() => {
    if (!nextScanTimelineItemId) {
      return false
    }

    navigateToTimelineItem(nextScanTimelineItemId)
    return true
  }, [navigateToTimelineItem, nextScanTimelineItemId])

  const navigateToPreviousScan = useCallback(() => {
    if (!previousScanTimelineItemId) {
      return false
    }

    navigateToTimelineItem(previousScanTimelineItemId)
    return true
  }, [navigateToTimelineItem, previousScanTimelineItemId])

  return {
    /**
     * Navigates to the next scan if there is one, and returns true/false whether the navigation occured.
     */
    navigateToNextScan,

    /**
     * Navigates to the previous scan if there is one, and returns true/false whether the navigation occured.
     */
    navigateToPreviousScan
  }
}
