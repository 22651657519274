import { API } from 'aws-amplify'
import { StlProviders } from 'components/Patients/TreatmentTracker/txTrackerConsts'
import { TreatmentTypes } from 'consts/treatmentConsts'
import { useCallback, useMemo } from 'react'
import { logError } from 'utils/logUtils'

const ValidationErrors = {
  MoreThanOneSeries: 'moreThanOneSeries',
  DuplicatedStls: 'duplicatedStls',
  InvalidFiles: 'invalidFiles'
}

export default ({ setIsValidationInProgress, txTrackerStls, treatmentType, patientId } = {}) => {
  const providerOptionsByTreatmentType = useMemo(
    () => ({
      [TreatmentTypes.Aligners]: [
        { label: '3M', value: StlProviders['3M'] },
        { label: 'Spark', value: StlProviders.Spark },
        { label: 'uLab', value: StlProviders.uLab },
        { label: 'Align', value: StlProviders.Align }
      ],
      [TreatmentTypes.Braces]: [
        { label: 'KLOwen', value: StlProviders.KLOwen },
        { label: 'Other', value: StlProviders.Other }
      ]
    }),
    []
  )

  const providerOptions = useMemo(
    () => providerOptionsByTreatmentType[treatmentType],
    [providerOptionsByTreatmentType, treatmentType]
  )

  const runTxTrackerValidations = useCallback(
    async ({ provider, step, files }) => {
      setIsValidationInProgress(true)

      let validationsResponse

      try {
        validationsResponse = await API.post('grinServerlessApi', '/treatment-tracker/v1/validation', {
          body: {
            patientId,
            provider,
            step,
            newStls: files || []
          }
        })
      } catch (ex) {
        logError('runTxTrackerValidations: server error', { ex })
      }

      setIsValidationInProgress(false)

      return validationsResponse || {}
    },
    [setIsValidationInProgress, patientId]
  )

  return {
    runTxTrackerValidations,
    ValidationErrors,
    setIsValidationInProgress,
    providerOptions
  }
}
