import Actions from 'actions'
import { LOGIN_CHECK_INTERVAL } from 'consts/appConsts'
import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'

export default () => {
  const dispatch = useDispatch()

  const checkLoginChange = useCallback(() => dispatch(Actions.checkLoginChange()), [dispatch])

  const handleVisibilityChange = useCallback(
    e => {
      if (!document.hidden) {
        checkLoginChange()
      }
    },
    [checkLoginChange]
  )

  useEffect(() => {
    const interval = setInterval(checkLoginChange, LOGIN_CHECK_INTERVAL)
    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      clearInterval(interval)
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [checkLoginChange, handleVisibilityChange])
}
