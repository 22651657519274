import Actions from 'actions'
import { ofType } from 'redux-observable'
import { debounceTime, filter, map } from 'rxjs/operators'
import { api } from 'store/api'
import { ModuleTags } from 'store/modules/consts'

export const refreshNotificationsOnMessageReceivedEpic = action$ =>
  action$.pipe(
    ofType(Actions.NOTIFICATION_RECEIVED),
    filter(({ payload }) => payload.entityType === 'newMessage'),
    debounceTime(6000), // Accumulate live updates for 6 seconds before refreshing the notifications data.
    map(() => api.util.invalidateTags([ModuleTags.UnreadNotifications, ModuleTags.GrinNotificationsList]))
  )
