import React from 'react'
import { makeStyles } from '@material-ui/styles'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'

const useStyles = makeStyles(theme => ({
  notificationTypeLabel: {
    color: 'var(--text-color-62)',
    fontWeight: 500,
    fontSize: 13
  }
}))

const NotificationTypeLabel = ({ children, className }) => {
  const classes = useStyles()
  return (
    <DazzedParagraph14 className={[classes.notificationTypeLabel, className].join(' ')}>{children}</DazzedParagraph14>
  )
}

export default NotificationTypeLabel
