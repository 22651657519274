import React, { useState, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CircularProgress } from '@material-ui/core'
import BaseModal from 'components/common/modals/BaseModal'
import GrinTabs from 'components/common/tabs/GrinTabs'
import GrinTab from 'components/common/tabs/GrinTab'
import GeneraInfoTab from './GeneralInfoTab'
import SubscriptionTab from './SubscriptionTab'
import FeaturesTab from './FeaturesTab'
import PaymentHistoryTab from './PaymentHistoryTab'
import NotesTab from './NotesTab'
import PmsIntegrationsTab from './PmsIntegrationsTab'
import MembersTab from './MembersTab/MembersTab'
import EditDoctorDialogHeader from './EditDoctorDialogHeader'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Actions from 'actions'

export const useStyles = makeStyles({
  modal: {
    minWidth: '80%',
    height: '90%'
  },
  tabsContainer: {
    marginTop: 20,
    marginLeft: -25,
    marginRight: -24,
    borderBottom: '1px solid var(--border-color-7)'
  },
  modalContent: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  tabConteiner: {
    padding: '30px 15px 15px 15px',
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'auto'
  },
  loaderContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
})

const EditDoctorDialog = ({ handleClose, doctorId }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isLoading = useSelector(state => state.supportReducer.doctorsDashboard.isLoading)
  const doctor = useSelector(state => state.supportReducer.doctorsDashboard.selectedDoctor)
  const accountOwner = useMemo(() => doctor?.accountOwner || doctor, [doctor])

  const practiceMembers = useSelector(
    state => state.supportReducer.doctorsDashboard.practiceMembers.data?.length ?? '-'
  )
  const activeUsers = useSelector(state => state.supportReducer.doctorsDashboard.seats.data?.occupied ?? '-')

  const [selectedTab, setSelectedTab] = useState(0)

  useEffect(() => {
    if (doctorId) {
      dispatch(Actions.supportFetchDoctor(doctorId))
    }
    return () => dispatch(Actions.supportResetDoctor())
  }, [dispatch, doctorId])

  useEffect(() => {
    if (accountOwner) {
      dispatch(Actions.supportFetchPracticeMembers(accountOwner.id))
      dispatch(Actions.fetchDoctorSeatsByUsername(accountOwner.username))
    }
  }, [dispatch, accountOwner])

  const tabs = useMemo(
    () => [
      { index: 0, label: t('dialogs.doctorCardEditor.generalInfoTab'), Component: GeneraInfoTab },
      { index: 1, label: t('dialogs.doctorCardEditor.subscriptionTab'), Component: SubscriptionTab },
      { index: 2, label: t('dialogs.doctorCardEditor.featuresTab'), Component: FeaturesTab },
      { index: 3, label: t('dialogs.doctorCardEditor.paymentHistoryTab'), Component: PaymentHistoryTab },
      { index: 4, label: t('dialogs.doctorCardEditor.membersTab'), Component: MembersTab },
      { index: 5, label: t('dialogs.doctorCardEditor.notesTab'), Component: NotesTab },
      { index: 6, label: t('dialogs.doctorCardEditor.pmsIntegrationsTab'), Component: PmsIntegrationsTab }
    ],
    [t]
  )

  return (
    <BaseModal
      className={classes.modal}
      open={!!doctorId}
      largerButtons
      variant="card"
      primaryType="submit"
      hideScroll={true}
      handleClose={handleClose}
    >
      {isLoading ? (
        <div className={classes.loaderContainer}>
          <CircularProgress color="secondary" size={55} />
        </div>
      ) : (
        doctor && (
          <div className={classes.modalContent}>
            <EditDoctorDialogHeader
              doctor={accountOwner}
              activePracticeMembers={practiceMembers}
              activeUsers={activeUsers}
            />
            <div className={classes.tabsContainer}>
              <GrinTabs
                value={selectedTab}
                variant="fullWidth"
                centered
                onChange={(event, value) => setSelectedTab(value)}
              >
                {tabs.map(tab => (
                  <GrinTab key={tab.index} label={tab.label} />
                ))}
              </GrinTabs>
            </div>
            <div className={classes.tabConteiner}>
              {tabs
                .filter(tab => tab.index === selectedTab)
                .map(Tab => (
                  <Tab.Component key={Tab.index} />
                ))}
            </div>
          </div>
        )
      )}
    </BaseModal>
  )
}

export default EditDoctorDialog
