import * as React from 'react'

export default () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 10.75C5 11.1642 5.33579 11.5 5.75 11.5C6.16421 11.5 6.5 11.1642 6.5 10.75L6.5 6.5H10.75C11.1642 6.5 11.5 6.16421 11.5 5.75C11.5 5.33579 11.1642 5 10.75 5H6.5L6.5 0.75C6.5 0.335787 6.16421 0 5.75 0C5.33579 0 5 0.335787 5 0.75L5 5H0.75C0.335787 5 0 5.33579 0 5.75C0 6.16421 0.335787 6.5 0.75 6.5H5L5 10.75Z"
      fill="#3C52EF"
    />
  </svg>
)
