import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { useTranslation } from 'react-i18next'
import FormInput from 'components/common/FormInput'

const useStyles = makeStyles(theme => ({
  divider: {
    height: 160,
    width: 1,
    backgroundColor: 'var(--border-color-28)'
  },
  breakSpacesText: {
    whiteSpace: 'pre'
  }
}))

const ConfirmationStep = ({ confirmationCode, setConfirmationCode, errorMessage }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid container direction="column">
      <Grid item style={{ marginBottom: 20 }}>
        <Grid item>
          <DazzedParagraph14 strong className={classes.breakSpacesText}>
            {t('pages.accountSettings.mfaSettings.setupModal.authApp.confirmationText')}
          </DazzedParagraph14>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <FormInput
          title={t('pages.accountSettings.mfaSettings.setupModal.authApp.confirmationLabel')}
          style={{ bright: true, thick: true }}
          value={confirmationCode}
          validationRules={{ required: true }}
          setValue={value => setConfirmationCode(value)}
          errorMessage={errorMessage}
        />
      </Grid>
    </Grid>
  )
}

export default ConfirmationStep
