import React, { useCallback, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useDispatch, useSelector } from 'react-redux'
import BaseModal from 'components/common/modals/BaseModal'
import Actions from 'actions'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import { SetupSteps } from './MFASetupModalConsts'
import useMFASetupModal from './useMFASetupModal'

const useStyles = makeStyles(theme => ({
  root: {
    padding: 32,
    width: 800,
    height: 500
  },
  dialogContent: {
    padding: '0 24px 24px 24px!important'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  }
}))

const MFASetupModal = props => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { isOpen, methodType, isAppMethodAlreadySetup } = useSelector(
    state => state.profileReducer.mfaSettings.setupModal
  )

  const handleClose = useCallback(() => {
    dispatch(Actions.setMfaSetupModalOpen({ isOpen: false }))
  }, [dispatch])

  const {
    step,
    setStep,
    confirmationCode,
    setConfirmationCode,
    phoneNumber,
    setPhoneNumber,
    currentSetupMethod,
    isLoading,
    errorMessage,
    setErrorMessage
  } = useMFASetupModal({ onClose: handleClose, methodType })

  useEffect(() => {
    if (!isOpen) {
      setStep(SetupSteps.Initial)
      setConfirmationCode('')
      setPhoneNumber('')
      setErrorMessage('')
    }
  }, [isOpen, setConfirmationCode, setErrorMessage, setPhoneNumber, setStep])

  useEffect(() => {
    if (isOpen && isAppMethodAlreadySetup) {
      setStep(SetupSteps.OtherMethodIsAlreadySetup)
    }
  }, [isAppMethodAlreadySetup, isOpen, setStep])

  const enterPressed = useCallback(
    e => {
      if (e.key === 'Enter' && !e.shiftKey && step === SetupSteps.Confirmation) {
        e.preventDefault()
        currentSetupMethod.primaryClickByStep[step]()
        return false
      }
      return true
    },
    [currentSetupMethod, step]
  )

  return (
    isOpen && (
      <BaseModal
        open={isOpen}
        className={classes.root}
        handleClose={handleClose}
        contentClassName={classes.dialogContent}
        onPrimaryBtnClick={currentSetupMethod.primaryClickByStep[step]}
        primaryLabel={currentSetupMethod.primaryLabelByStep[step]}
        largerPrimaryButton
        onSecondaryBtnClick={currentSetupMethod.secondaryClickByStep[step]}
        secondaryLabel={currentSetupMethod.secondaryLabelByStep[step]}
        isLoading={isLoading}
        onKeyDown={enterPressed}
      >
        <Grid container direction="column">
          {currentSetupMethod?.shouldDisplayTitle?.[step] && (
            <Grid item>
              <DazzedParagraph16 bold>{t('pages.accountSettings.mfaSettings.title')}</DazzedParagraph16>
            </Grid>
          )}
          <Grid item style={{ marginTop: 15 }}>
            {React.createElement(currentSetupMethod?.component, {
              step,
              setStep,
              confirmationCode,
              setConfirmationCode,
              phoneNumber,
              setPhoneNumber,
              errorMessage
            })}
          </Grid>
        </Grid>
      </BaseModal>
    )
  )
}

export default MFASetupModal
