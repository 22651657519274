import * as React from 'react'

export default () => (
  <svg width="51" height="57" viewBox="0 0 51 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.7092 26.8922L8.8855 26.7519C5.93307 26.5248 3.00589 27.7711 1.8193 30.4841C0.872692 32.6484 0 35.5493 0 38.8889C0 40.7731 0.27779 42.5176 0.689917 44.069C1.99185 48.9699 7.22666 51.0289 12.2628 50.4364C14.6971 50.15 17.1643 50.4768 19.44 51.3871L21.2526 52.1121C26.7645 54.3169 32.9266 54.2418 38.3831 51.9033L39.5833 51.3889V25L34.7585 22.5876C32.6931 21.5549 31.5881 19.2557 32.0719 16.9978L32.9505 12.8976C33.2037 11.7159 33.2459 10.4988 33.075 9.30244C32.7905 7.3116 31.9264 5.4485 30.5904 3.94541L28.3931 1.47354C27.56 0.536263 26.3658 0 25.1118 0H24.7339C23.5899 0 22.5127 0.53861 21.8263 1.4538C21.1955 2.2949 20.9598 3.36797 21.1801 4.39602L22.5431 10.7568C22.7902 11.9098 22.8313 13.0975 22.6645 14.2649C22.3765 16.2808 21.4797 18.1608 20.094 19.653L14.8905 25.2568C13.8189 26.4108 12.2794 27.0129 10.7092 26.8922Z"
      fill="#E1E5FE"
    />
    <path
      d="M31.9453 51.7778C31.9453 50.0945 33.2335 48.6911 34.9106 48.5474L41.6828 47.9669C43.9158 47.7755 45.8342 49.5365 45.8342 51.7778C45.8342 54.019 43.9158 55.78 41.6828 55.5886L34.9106 55.0081C33.2335 54.8644 31.9453 53.461 31.9453 51.7778Z"
      fill="#E1E5FE"
      stroke="white"
      strokeWidth="2"
    />
    <path
      d="M30.5547 44.1389C30.5547 42.1797 32.0632 40.5507 34.0167 40.4004L43.7634 39.6507C46.3779 39.4495 48.6102 41.5167 48.6102 44.1389C48.6102 46.7611 46.3779 48.8283 43.7634 48.6272L34.0167 47.8775C32.0632 47.7272 30.5547 46.0982 30.5547 44.1389Z"
      fill="#E1E5FE"
      stroke="white"
      strokeWidth="2"
    />
    <path
      d="M29.168 35.8056C29.168 33.8516 30.6829 32.2324 32.6325 32.1024L45.151 31.2678C47.7759 31.0928 50.0013 33.1748 50.0013 35.8056C50.0013 38.4363 47.7759 40.5183 45.151 40.3433L32.6325 39.5088C30.6829 39.3788 29.168 37.7595 29.168 35.8056Z"
      fill="#E1E5FE"
      stroke="white"
      strokeWidth="2"
    />
    <path
      d="M29.168 27.4722C29.168 25.5183 30.6829 23.899 32.6325 23.769L45.151 22.9344C47.7759 22.7594 50.0013 24.8414 50.0013 27.4722C50.0013 30.103 47.7759 32.1849 45.151 32.01L32.6325 31.1754C30.6829 31.0454 29.168 29.4261 29.168 27.4722Z"
      fill="#E1E5FE"
      stroke="white"
      strokeWidth="2"
    />
  </svg>
)
