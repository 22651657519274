import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { useTranslation } from 'react-i18next'
import { OtherMethodIsSetupWarning } from 'components/common/icons'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'

const useStyles = makeStyles(theme => ({
  breakSpacesText: {
    whiteSpace: 'pre'
  }
}))

const OtherMethodIsAlreadySetup = props => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item style={{ marginBottom: 10 }}>
        <DazzedParagraph16 bold className={classes.breakSpacesText}>
          {t('pages.accountSettings.mfaSettings.setupModal.sms.otherMethodIsAlreadySetup.title')}
        </DazzedParagraph16>
      </Grid>
      <Grid item style={{ marginBottom: 20 }}>
        <DazzedParagraph14 strong textAlign="center" className={classes.breakSpacesText}>
          {t('pages.accountSettings.mfaSettings.setupModal.sms.otherMethodIsAlreadySetup.description')}
        </DazzedParagraph14>
      </Grid>
      <Grid item>
        <OtherMethodIsSetupWarning />
      </Grid>
    </Grid>
  )
}

export default OtherMethodIsAlreadySetup
