import React, { useCallback, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Trans, useTranslation } from 'react-i18next'
import BaseModal from 'components/common/modals/BaseModal'
import { trackEvent } from 'utils/analyticsUtils'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import TextArea from 'components/common/TextArea'
import { Grid } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import Actions from 'actions'
import { AsyncStatus } from 'consts'

const useStyles = makeStyles(theme => ({
  modalRoot: {
    width: '790px!important'
  },
  body: {
    width: '582px!important',
    margin: '0 auto'
  },
  textInput: {
    height: '104px!important',
    width: '100%',
    padding: '16px!important',
    border: '1px solid rgba(172, 176, 183, 1)!important',
    borderRadius: 8,
    fontFamily: 'Dazzed',
    fontSize: 14
  }
}))

const RequestTxTrackerRerunModal = ({ isOpen, onClose, analyticsPayload = {}, scanId, scanNumber }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { submitStatus } = useSelector(state => state.txTrackerReducer.requestRerun)

  const [textInput, setTextInput] = useState()
  const [didSubmit, setDidSubmit] = useState()

  const handleCancel = useCallback(() => {
    trackEvent('Treatment tracker - request rerun - cancel clicked', {
      ...analyticsPayload
    })
    onClose()
  }, [analyticsPayload, onClose])

  const handleSubmit = useCallback(() => {
    trackEvent('Treatment tracker - request rerun - submit clicked', {
      ...analyticsPayload,
      textInput
    })

    setDidSubmit(true)
    dispatch(
      Actions.txTrackerRequestRerun({
        scanId,
        scanNumber,
        notes: textInput
      })
    )
  }, [analyticsPayload, textInput, scanId, scanNumber, dispatch])

  useEffect(() => {
    if (isOpen && didSubmit && submitStatus === AsyncStatus.Completed) {
      onClose()
    }
  }, [isOpen, didSubmit, submitStatus, onClose])

  return (
    <BaseModal
      open={isOpen}
      handleClose={handleCancel}
      className={classes.modalRoot}
      variant="alert"
      title={t('dialogs.treatmentTracker.requestRerun.title')}
      secondaryLabel={t('general.cancel')}
      onSecondaryBtnClick={handleCancel}
      primaryLabel={t('dialogs.treatmentTracker.requestRerun.submitButton')}
      onPrimaryBtnClick={handleSubmit}
      isLoading={submitStatus === AsyncStatus.Loading}
      largerPrimaryButton
    >
      <Grid
        container
        direction="column"
        spacing={3}
        justifyContent="center"
        alignItems="stretch"
        className={classes.body}
      >
        <Grid item>
          <DazzedParagraph16 strong textAlign="center">
            <Trans i18nKey={t('dialogs.treatmentTracker.requestRerun.body')} />
          </DazzedParagraph16>
        </Grid>
        <Grid item>
          <TextArea
            value={textInput}
            setValue={setTextInput}
            placeholder={t('dialogs.treatmentTracker.requestRerun.inputPlaceholder')}
            className={classes.textInput}
            style={{ bright: true, thick: true }}
          />
        </Grid>
      </Grid>
    </BaseModal>
  )
}

export default RequestTxTrackerRerunModal
