import React from 'react'
import { GoBackAligners, KeepAligners as KeepAlignersIcon } from 'components/common/icons'
import { useTranslation } from 'react-i18next'
import GenericGuidelineAction from './GenericGuidelineAction'

const KeepAlignersOrGoBack = ({ shouldGoBack, shouldKeepAligner, days }) => {
  const { t } = useTranslation()

  return shouldGoBack ? (
    <GenericGuidelineAction icon={<GoBackAligners />} text={t('dialogs.hiPatientGuidelinesViewer.actions.goBack')} />
  ) : (
    shouldKeepAligner && (
      <GenericGuidelineAction
        icon={<KeepAlignersIcon />}
        text={t('dialogs.hiPatientGuidelinesViewer.actions.keepAligner', { days })}
      />
    )
  )
}

export default KeepAlignersOrGoBack
