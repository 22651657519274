import { getEmailPrefix, getInitials } from '../generalUtils'
import { mapToGrinScansDto } from './treatmentMapper'

export const getFullAddress = patient =>
  [patient?.details?.address, patient?.details?.city, patient?.details?.state, patient?.details?.zipcode]
    .filter(prop => !!prop)
    .join(', ')

export const mapToPatientDto = patient =>
  patient
    ? {
        ...patient,
        name: patient.details.name || getInitials(getEmailPrefix(patient.details.email)),
        grinScans: {
          nextToken: patient.grinScans?.nextToken,
          items: mapToGrinScansDto(patient.grinScans?.items)
        }
      }
    : {}

export const mapToUpdatePatientInput = ({
  user,
  doctor,
  initialScans,
  treatments,
  grinScans,
  _deleted,
  _lastChangedAt,
  updatedAt,
  cycles,
  name,
  actionItems,
  patientTags,
  ...patient
}) => patient

export const mapToUpdateLeadInput = patient => ({
  id: patient.id,
  _version: patient._version,
  ...(patient.details
    ? {
        email: patient.details.email,
        lastName: patient.details.lastName,
        firstName: patient.details.firstName,
        guardianFirstName: patient.details.parentFirstName,
        guardianLastName: patient.details.parentLastName,
        phone: patient.details.phone,
        countryCode: patient.details.countryCode
      }
    : {}),
  ...{
    [`${patient?.user?.program || 'rm'}Data`]: JSON.stringify({
      ...JSON.parse(patient.programData || '{}'),
      ...(patient.treatment
        ? {
            cycleInterval: patient.treatment.cycleInterval,
            cycleIntervalPeriodType: patient.treatment.cycleIntervalPeriodType,
            postConfirmationStatusKey: patient.treatment.postConfirmationStatusKey,
            treatmentType: patient.treatment.type,
            totalApplianceNumber: patient.treatment.totalApplianceNumber
          }
        : {}),
      birthdate: patient.details?.birthdate
    })
  }
})

export const mapLeadSMToPatientSM = patientSearchModel => ({
  ...patientSearchModel,
  patient: patientSearchModel.patient || {
    id: patientSearchModel.lead?.id,
    auth_status: patientSearchModel?.lead?.conversionStatus === 'added' ? 'added' : 'invited',
    details: {
      email: patientSearchModel.lead?.email,
      name: patientSearchModel.lead?.firstName + ' ' + patientSearchModel.lead?.lastName
    },
    patientTags: patientSearchModel.patientTags,
    cycles: {
      items: []
    },
    user: {
      program: patientSearchModel.lead?.program
    },
    invitationCode: patientSearchModel.lead?.invitationCode,
    guardianId: patientSearchModel.lead?.guardianId
  },
  isLead: !patientSearchModel.patient
})

export const mapLeadsSMToPatientsSM = patientSearchModels => ({
  ...patientSearchModels,
  items: patientSearchModels.items.map(mapLeadSMToPatientSM)
})

export const mapLeadDetailsToPatient = (lead, programData) => ({
  name: `${lead.firstName} ${lead.lastName}`,
  firstName: lead.firstName,
  lastName: lead.lastName,
  email: lead.email,
  phone: lead.phone,
  countryCode: lead.countryCode,
  parentFirstName: lead.guardianFirstName,
  parentLastName: lead.guardianLastName,
  parentEmail: lead.guardianEmail,
  birthdate: programData?.birthdate
})

export const mapLeadToPatient = ({ lead, patientSM = {}, leadStatus }) => {
  const programData = JSON.parse(lead[`${lead.program}Data`] || '{}')

  const leadPatient = {
    isLead: true,
    id: lead.id,
    patientDoctorId: lead.doctorId,
    guardianId: patientSM.guardianId || lead.guardianId,
    auth_status: patientSM.lead.conversionStatus === 'added' ? 'added' : 'invited',
    initialScans: {
      items: patientSM.lead.initialScans?.items || []
    },
    patientTags: patientSM.patientTags,
    treatments: {
      items: [
        {
          patientId: lead.id,
          cycleInterval: programData.cycleInterval,
          cycleIntervalPeriodType: programData.cycleIntervalPeriodType,
          postConfirmationStatusKey: programData.postConfirmationStatusKey,
          type: programData.treatmentType,
          totalApplianceNumber: programData.totalApplianceNumber,
          proposals: {
            items: []
          },
          currentStatus: { status: leadStatus }
        }
      ]
    },
    cycles: {
      items: []
    },
    details: mapLeadDetailsToPatient(lead, programData),
    owner: lead.username,
    a_doctor: lead.a_doctor,
    doctor: lead.doctor,
    actionItems: {
      items: []
    },
    _version: lead._version,
    _deleted: lead._deleted,
    _lastChangedAt: lead._lastChangedAt,
    grinScans: {
      items: []
    },
    user: {
      id: lead.id,
      isActive: true,
      program: lead.program,
      ...(patientSM
        ? {
            rooms: {
              items: [{ ...patientSM.room, roomId: patientSM.room?.id || patientSM.roomId }]
            }
          }
        : {})
    },
    programData: lead[`${lead.program}Data`]
  }
  return leadPatient
}
