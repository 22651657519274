import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { api } from 'store/api'

export default () => {
  const { practiceName } = useSelector(state => state.practiceReducer.details)
  const { data: unreadStats } = useSelector(api.endpoints.fetchTotalUnreadNotifications.select(null))

  useEffect(() => {
    let title = practiceName ? `Grin - ${practiceName}` : 'Grin'

    if (unreadStats?.total) {
      title = `${title} (${unreadStats.total})`
    }

    document.title = title
  }, [practiceName, unreadStats])
}
