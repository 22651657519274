import { Grid, makeStyles } from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { Circle } from 'components/common/icons'
import ProfilePicture from 'components/common/ProfilePicture'
import { Roles } from 'consts/authConsts'
import useBroadcast from 'hooks/useBroadcast'
import React, { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { isUserOfRole } from 'utils/authUtils'
import { isMobile } from 'utils/mobileUtils'
import { updateS3PracticeLogoKey } from 'utils/storageUtils'
import AppProfileMenu from './AppProfileMenu'
import { getCurrentAuthProfile } from 'logic/loginProfilesLogic'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'

const useStyles = makeStyles({
  appProfile: {
    cursor: 'pointer',
    marginLeft: 15,
    position: 'relative'
  },
  appProfileName: {
    color: 'var(--primary-color)',
    fontSize: isMobile() ? '14px' : '18px',
    fontWeight: 500,
    wordBreak: isMobile() ? 'break-word' : 'unset',
    marginLeft: !isMobile() ? 'unset' : 'unset'
  },
  appProfileImage: {
    width: isMobile() ? '40px' : '48px',
    height: isMobile() ? '40px' : '48px',
    borderRadius: '50%',
    marginRight: '20px'
  },
  indicator: {
    position: 'absolute',
    right: 1,
    top: 1
  }
})

const ProfileMenu = () => {
  const classes = useStyles()
  const { name } = useSelector(state => state.profileReducer.doctor)
  const practiceDetails = useSelector(state => state.practiceReducer.details)
  const hasIndicator = useSelector(state => state.appReducer.hasNotificationsIndicator)
  const [anchorEl, setAnchorEl] = useState(null)

  const { closeBroadcastMode } = useBroadcast()

  const currentProfile = getCurrentAuthProfile()
  const practiceLogo = useMemo(
    () => practiceDetails?.logo && updateS3PracticeLogoKey(practiceDetails.logo),
    [practiceDetails.logo]
  )

  const handleClick = useCallback(
    event => {
      setAnchorEl(event.currentTarget)
      closeBroadcastMode('Profile menu clicked')
    },
    [closeBroadcastMode]
  )

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  return (
    <>
      <Grid id="header-menu" container direction="row" className={classes.appProfile} onClick={handleClick}>
        <Grid item>
          <ProfilePicture
            name={practiceDetails.practiceName}
            email={practiceDetails.email}
            photo={practiceLogo}
            isAdmin={isUserOfRole([Roles.Admin])}
            size={isMobile() ? 'small' : 'medium'}
          />
        </Grid>
        {!isMobile() && (
          <Grid item style={{ marginLeft: 20 }}>
            <Grid container alignItems="center" justifyContent="flex-end" spacing={1}>
              <Grid item>
                <div className={classes.appProfileName}>{name}</div>
                <DazzedParagraph14>{currentProfile?.displayName}</DazzedParagraph14>
              </Grid>
              <Grid item>
                <ArrowDropDownIcon />
              </Grid>
            </Grid>
          </Grid>
        )}
        {isMobile() && hasIndicator && <Circle className={classes.indicator} height="10px" width="10px" />}
      </Grid>
      <AppProfileMenu anchorEl={anchorEl} handleClick={handleClick} handleClose={handleClose} />
    </>
  )
}

export default ProfileMenu
