import * as React from 'react'

const TagScan = () => (
  <svg width="25" height="15" viewBox="0 0 25 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse cx="19" cy="7.5" rx="5.5" ry="5.5" transform="rotate(90 19 7.5)" fill="#3C52EF" />
    <path d="M19 4V8" stroke="white" strokeWidth="1.5" />
    <path d="M19 9V11" stroke="white" strokeWidth="1.5" />
    <path
      d="M1 7.5C1 11.0899 3.91015 14 7.5 14C11.0899 14 14 11.0899 14 7.5C14 3.91015 11.0899 1 7.5 1C3.91015 1 1 3.91015 1 7.5Z"
      fill="#3C52EF"
      stroke="#F5FAFF"
      strokeWidth="2"
    />
    <path d="M4.5 7.5L6.5 9.5L11 5" stroke="white" strokeWidth="1.5" />
  </svg>
)

export default TagScan
