import React, { useMemo } from 'react'
import { Grid, useMediaQuery } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import AlignerMenu from './AlignerMenu'
import ScanStats from '../../ScanStats'
import OnTrackGrinScanItem from 'components/common/icons/timelineV2/mini-timeline/OnTrackGrinScanItem'
import OffTrackGrinScanItem from 'components/common/icons/timelineV2/mini-timeline/OffTrackGrinScanItem'
import { TreatmentTrackOptions } from 'consts/giConsts'
import AlignerNumber from '../../AlignerNumber'
import { UrgencyStatuses } from '@grin-rnd/grin-api-sdk/dist/Enums/Timeline'
import UrgentScanTag from '../../Tags/UrgentScanTag'
import ScanGeneralInfo from './ScanGeneralInfo'

const ScanHeaderBodyLg = ({
  scanNumber,
  alignerNumber,
  totalAlignerNumber,
  numberOfReviews,
  numberOfShares,
  onReviewsClicked,
  onSharesClicked,
  scanDate,
  withAlignerMenu,
  alignerMenuProps,
  trackStatus,
  urgencyDetails = {},
  isRecordsAppScan
}) => {
  const { t } = useTranslation()

  const isSmallScreen = useMediaQuery('(max-width:1710px)')
  const isExtraSmallScreen = useMediaQuery('(max-width:1490px)')
  const hasAnyStats = useMemo(() => numberOfReviews > 0 || numberOfShares > 0, [numberOfReviews, numberOfShares])

  return (
    <Grid container spacing={1} direction="column" style={{ position: 'relative' }}>
      <Grid item>
        <Grid container alignItems="center" justifyContent="space-between" spacing={1}>
          <Grid item>
            <Grid container alignItems="center" spacing={1}>
              {trackStatus && (
                <Grid item style={{ lineHeight: 0.8 }}>
                  {trackStatus === TreatmentTrackOptions.OnTrack ? <OnTrackGrinScanItem /> : <OffTrackGrinScanItem />}
                </Grid>
              )}
              <Grid item>
                <DazzedParagraph14 bold>
                  {t('pages.patients.selectedPatient.timelineV2.scanHeader.scanNumber', { scanNumber })}
                </DazzedParagraph14>
              </Grid>
              {!!alignerNumber && (
                <Grid item>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item>
                      <AlignerNumber alignerNumber={alignerNumber} totalAlignerNumber={totalAlignerNumber} />
                    </Grid>
                    {withAlignerMenu && (
                      <Grid item id="scan-feed-aligner-menu">
                        <AlignerMenu {...alignerMenuProps} />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}
              {urgencyDetails.urgencyStatus && urgencyDetails.urgencyStatus !== UrgencyStatuses.None ? (
                <Grid item>
                  <UrgentScanTag
                    reason={urgencyDetails.reason}
                    urgencyStatus={urgencyDetails.urgencyStatus}
                    denseMode={isExtraSmallScreen}
                  />
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
          {!hasAnyStats && (
            <Grid item>
              <ScanGeneralInfo scanDate={scanDate} isRecordsAppScan={isRecordsAppScan} />
            </Grid>
          )}
          {hasAnyStats && (
            <Grid item style={{ paddingRight: 12 }}>
              <ScanStats
                numberOfReviews={numberOfReviews}
                numberOfShares={numberOfShares}
                onReviewsClicked={onReviewsClicked}
                onSharesClicked={onSharesClicked}
                denseMode={isSmallScreen}
                fontSize={13}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      {hasAnyStats && (
        <Grid item>
          <ScanGeneralInfo scanDate={scanDate} isRecordsAppScan={isRecordsAppScan} />
        </Grid>
      )}
    </Grid>
  )
}

export default React.memo(ScanHeaderBodyLg)
