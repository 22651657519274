import Actions from 'actions'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, Switch, useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
import Confirmation from '../components/Confirmation'
import Welcome from '../components/Welcome'
import ROUTES from '../consts/routesConsts'
import useCssClasses from '../hooks/useCssClasses'
import BillingInfo from './Auth/BillingInfo/BillingInfo'
import EmailNotVerified from './Auth/EmailNotVerified'
import PersonalDetails from './Auth/PersonalDetails'
import PracticeInfo from './Auth/PracticeInfo'
import ResetPassword from './Auth/ResetPassword'
import SetPassword from './Auth/SetPassword'
import SignIn from './Auth/SignIn'
import SignUp from './Auth/SignUp'
import Terms from './Auth/Terms'
import MFALogin from './Auth/MFALogin'
import AuthCodeFlow from './Auth/AuthCodeFlow/AuthCodeFlow'

const Auth = () => {
  const location = useLocation()
  const history = useHistory()
  const email = useSelector(state => state.authReducer.emailAddress)
  const appconfig = useSelector(state => state.appReducer.appconfig)
  const dispatch = useDispatch()

  useEffect(() => {
    if (isEmpty(appconfig)) {
      dispatch(Actions.fetchAppConfig())
    }
  }, [appconfig, dispatch])

  useEffect(() => {
    const publicRoutes = [
      ROUTES.WELCOME,
      ROUTES.SIGN_IN,
      ROUTES.SIGN_UP,
      ROUTES.RESET_PASSWORD,
      ROUTES.MFA_LOGIN,
      ROUTES.AUTH_CODE_FLOW
    ]
    const path = location.pathname

    if (!publicRoutes.includes(path) && !email) {
      history.push(ROUTES.WELCOME)
    }
  }, [location, history, email])

  useCssClasses()

  return (
    <Switch>
      <Route exact path={ROUTES.AUTH} render={() => <Redirect to={ROUTES.WELCOME} />} />
      <Route path={ROUTES.WELCOME} component={Welcome} />
      <Route path={ROUTES.SIGN_IN} component={SignIn} />
      <Route path={ROUTES.MFA_LOGIN} component={MFALogin} />
      <Route path={ROUTES.SIGN_UP} component={SignUp} />
      <Route path={ROUTES.PERSONAL_DETAILS} component={PersonalDetails} />
      <Route path={ROUTES.PRACTICE_INFO} component={PracticeInfo} />
      <Route path={ROUTES.TERMS} component={Terms} />
      <Route path={ROUTES.RESET_PASSWORD} component={ResetPassword} />
      <Route path={ROUTES.BILLING_INFO} component={BillingInfo} />
      <Route path={ROUTES.CONFIRMATION} component={Confirmation} />
      <Route path={ROUTES.SET_PASSWORD} component={SetPassword} />
      <Route path={ROUTES.EMAIL_NOT_VERIFIED} component={EmailNotVerified} />
      <Route path={ROUTES.AUTH_CODE_FLOW} component={AuthCodeFlow} />
    </Switch>
  )
}

export default Auth
