import React, { useState } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import MessageUserInfo from './MessageUserInfo'
import MessageTail from './MessageTail'
import { MonochromeBroadcast } from 'components/common/icons'
import UrgentCTA from './UrgentCTA'
import MessageActionsMenu from './MessageActionsMenu/MessageActionsMenu'

const useStyles = ({ alignLeft, isFirstMessage, isLastMessage, isBroadcast, backgroundColor }) =>
  makeStyles({
    bubble: {
      background: ({ alignLeft, backgroundColor }) =>
        backgroundColor || (isBroadcast ? 'var(--bg-color-44)' : alignLeft ? '#FFFFFF' : '#2F36541A'),
      border: ({ alignLeft }) =>
        isBroadcast || !alignLeft || backgroundColor ? 'none' : '1px solid rgba(0, 19, 53, 0.3)',
      borderRadius: ({ isLastMessage, alignLeft }) =>
        isLastMessage ? (alignLeft ? '16px 16px 16px 0' : '16px 16px 0 16px') : '16px',
      lineHeight: 1.5,
      whiteSpace: 'pre-wrap',
      overflowWrap: 'anywhere',
      padding: ({ isFirstMessage }) => (isFirstMessage ? '5px 8px 8px 8px' : '8px'),
      boxSizing: 'border-box',
      wordBreak: 'break-word',
      fontFamily: 'Dazzed',
      position: 'relative'
    },
    broadcastIcon: {
      position: 'absolute',
      right: ({ alignLeft }) => (alignLeft ? 'auto' : 'calc(100% + 15px)'),
      left: ({ alignLeft }) => (alignLeft ? 'calc(100% + 15px)' : 'auto'),
      top: '50%',
      transform: 'translateY(-50%)'
    },
    menuContainer: {
      position: 'absolute',
      top: 0,
      right: 8,
      zIndex: 999
    },
    menuTrigger: {
      background: ({ alignLeft }) => (alignLeft ? '#fff' : '#EAEAED'),
      borderRadius: 16
    }
  })({ alignLeft, isFirstMessage, isLastMessage, isBroadcast, backgroundColor })

const MessageTypeWrapper = ({
  id,
  children,
  alignLeft,
  isFirstMessage,
  isLastMessage,
  name,
  userType,
  className = '',
  infoClassName = '',
  useBubble = true,
  isBroadcast,
  backgroundColor,
  isUrgent = false,
  bubbleContainerClassName = '',
  withMessageActions = true
}) => {
  const classes = useStyles({ alignLeft, isFirstMessage, isLastMessage, isBroadcast, backgroundColor })

  const [isActionsMenuVisible, setIsActionsMenuVisible] = useState(false)

  return (
    <Grid
      container
      wrap="nowrap"
      alignItems="center"
      spacing={3}
      onMouseEnter={() => setIsActionsMenuVisible(true)}
      onMouseLeave={() => setIsActionsMenuVisible(false)}
    >
      <Grid item className={bubbleContainerClassName}>
        <div className={useBubble ? [classes.bubble, className].join(' ') : ''}>
          {isFirstMessage && <MessageUserInfo name={name} userType={userType} className={infoClassName} />}
          {isLastMessage && (
            <MessageTail alignLeft={alignLeft} isBroadcast={isBroadcast} backgroundColor={backgroundColor} />
          )}
          {isBroadcast && <MonochromeBroadcast className={classes.broadcastIcon} />}
          <div className={classes.menuContainer}>
            <MessageActionsMenu
              messageId={id}
              isVisible={isActionsMenuVisible}
              requirePatientNewerAppVersion={!withMessageActions}
              menuTriggerClassName={classes.menuTrigger}
            />
          </div>
          {children}
        </div>
      </Grid>
      {isUrgent && (
        <Grid item>
          <UrgentCTA id={id} />
        </Grid>
      )}
    </Grid>
  )
}

export default React.memo(MessageTypeWrapper)
