import moment from 'moment'
import React, { useCallback, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import NoteItem from './NoteItem'
import { trackEvent } from 'utils/analyticsUtils'
import Actions from 'actions'
import { scrollBar } from 'styles/theme'
import useFeatureFlags from 'hooks/useFeatureFlags'
import { useUpdateNoteMutation } from 'store/modules/PatientNotes'

const useStyles = makeStyles(theme => ({
  notesContainer: {
    maxHeight: 350,
    width: 450,
    overflowY: 'auto',
    overflowX: 'hidden',
    ...scrollBar
  }
}))

const PinnedNotesFloatingPopupContent = ({ notes }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { grinDesignRefresh: grinDesignRefreshFF } = useFeatureFlags()
  const [updateNote] = useUpdateNoteMutation()

  const doctor = useSelector(state => state.profileReducer.doctor)
  const { user } = useSelector(state => state.patientsReducer.patient)

  const handlePinToggeled = useCallback(
    async note => {
      trackEvent('Patient note: Note pin toggled', {
        noteId: note.id,
        isPinned: !note.isPinned,
        source: 'Pinned notes popup'
      })
      if (grinDesignRefreshFF) {
        await updateNote({ id: note.id, variables: { isPinned: !note.isPinned, grinUserId: user.id } })
      } else {
        dispatch(Actions.setPatientNotePinned({ noteId: note.id, _version: note._version, isPinned: !note.isPinned }))
      }
    },
    [dispatch, grinDesignRefreshFF, updateNote, user]
  )

  useEffect(() => {
    trackEvent('Pinned notes - Tooltip opened')
  }, [])

  return (
    <Grid container direction="column" wrap="nowrap" className={classes.notesContainer}>
      {notes.map(note => (
        <Grid item key={`pinned-note-container=${note.id}`}>
          <NoteItem
            key={note.id}
            id={note.id}
            isPinned={note.isPinned}
            date={moment(note.createdAt).format('DD MMM YYYY hh:mm A')}
            onPinToggled={() => handlePinToggeled(note)}
            metadata={note.metadata}
            body={note.body}
            doctorUserId={doctor?.user?.id}
            testIdPrefix={`pinned-note-${note.id}`}
          />
        </Grid>
      ))}
    </Grid>
  )
}

export default PinnedNotesFloatingPopupContent
