import { Menu } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

const useStyles = makeStyles(theme => ({
  root: {
    padding: 0,
    boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.15)'
  },
  list: {
    padding: 0
  }
}))

const GrinMenu = ({
  id = '',
  children,
  onClose,
  transformOrigin,
  className = '',
  triggerComponent,
  anchorOrigin,
  onOpen,
  open,
  disableMenuFocus = false,
  disableCloseOnBackdropClick = false,
  disabled = false,
  customAnchorEl,
  keepMounted = true,
  onFocus = () => {}
}) => {
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = useState(null)

  const isOpen = useMemo(() => open && Boolean(anchorEl), [anchorEl, open])

  const handleClose = useCallback(
    (e, reason) => {
      if (disableCloseOnBackdropClick && reason === 'backdropClick') {
        return
      }

      setAnchorEl(null)

      if (onClose) {
        onClose(e, reason)
      }
    },
    [disableCloseOnBackdropClick, onClose]
  )

  const handleOpen = useCallback(
    e => {
      if (disabled) {
        return
      }

      setAnchorEl(customAnchorEl || e.currentTarget)
      if (onOpen) {
        onOpen(e)
      }
    },
    [onOpen, disabled, customAnchorEl]
  )

  useEffect(() => {
    setAnchorEl(customAnchorEl)
  }, [customAnchorEl])

  return (
    <>
      <span id={id} onClick={handleOpen}>
        {triggerComponent}
      </span>
      <Menu
        anchorEl={anchorEl}
        keepMounted={keepMounted}
        open={isOpen}
        disableEnforceFocus={disableMenuFocus}
        onClose={handleClose}
        classes={{
          paper: [classes.root, className].join(' '),
          list: classes.list
        }}
        transformOrigin={transformOrigin}
        anchorOrigin={anchorOrigin}
        getContentAnchorEl={null}
        onFocus={onFocus}
      >
        {isOpen && <div>{children}</div>}
      </Menu>
    </>
  )
}

export default GrinMenu
