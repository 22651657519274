import React, { useCallback, useEffect, useRef, useState } from 'react'
import GrinPopper from './GrinPopper'

/**
 * A wrapper component for the GrinPopper component, adding hover functionalities - open/close on hover and follow mouse position.
 * @prop `anchorEl` - the element that will upon mouse enter/leave the popup is opened/closed respectively.
 * @prop `followMouse` (Optional) - a boolean to determine whether the popup would follow the mouse position while open.
 * @prop `offset` (Optional) - the x,y offset that will be added to the popper position.
 * @prop `grinPopperProps` (Optional) - props that will be passed to GrinPopper. **Only styling props** should be passed to avoid unexpected behaviour!
 */
const GrinHoverPopper = ({ anchorEl, followMouse = true, offset = { x: 0, y: 0 }, grinPopperProps = {}, children }) => {
  const popperRef = useRef()

  const [isOpen, setIsOpen] = useState(false)
  const [position, setPosition] = useState({ x: 0, y: 0 })

  const handleMouseEnter = useCallback(e => {
    setIsOpen(true)

    const targetElementBoundingRect = e.target.getBoundingClientRect()
    setPosition({
      x: targetElementBoundingRect.right,
      y: targetElementBoundingRect.top
    })
  }, [])

  const handleMouseLeave = useCallback(() => {
    setIsOpen(false)
  }, [])

  const handleMouseMove = useCallback(e => {
    const popper = popperRef.current
    if (popper) {
      setPosition({
        x: e.pageX,
        y: e.pageY
      })
    }
  }, [])

  useEffect(() => {
    if (anchorEl) {
      anchorEl.onmouseenter = handleMouseEnter
      anchorEl.onmouseleave = handleMouseLeave
      anchorEl.onmousemove = followMouse ? handleMouseMove : null

      return () => {
        anchorEl.onmouseenter = null
        anchorEl.onmouseleave = null
        anchorEl.onmousemove = null
      }
    }
  }, [anchorEl, followMouse, setIsOpen, handleMouseMove, handleMouseEnter, handleMouseLeave])

  return (
    <GrinPopper
      {...grinPopperProps}
      ref={popperRef}
      automaticallyOpen
      open={isOpen}
      customAnchorEl={{
        // A virtualElement. See https://mui.com/material-ui/api/popper/
        getBoundingClientRect: () => ({
          top: position.y + offset.y,
          bottom: position.y + offset.y,
          right: position.x + offset.x,
          left: position.x + offset.x,
          width: 0,
          height: 0
        }),
        clientWidth: 0,
        clientHeight: 0
      }}
    >
      {children}
    </GrinPopper>
  )
}

export default GrinHoverPopper
