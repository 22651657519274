import { API, Auth } from 'aws-amplify'
import queryString from 'query-string'
import { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { logError, logInfo } from 'utils/logUtils'
import ZoomMtgEmbedded from '@zoom/meetingsdk/embedded'
import { getEnvironment } from 'utils/awsUtils'
import useZoomAuth from './useZoomAuth'
import { trackEvent } from 'utils/analyticsUtils'
import { useTranslation } from 'react-i18next'

const RoleTypes = {
  Host: '1',
  Normal: '0'
}

export default () => {
  const { t } = useTranslation()
  const { getZoomToken } = useZoomAuth()

  const { zoomClientId } = useSelector(state => state.appReducer.appconfig.app)
  const { name: doctorName, id } = useSelector(state => state.profileReducer.doctor)

  const [meetingSdkElement, setMeetingSdkElement] = useState()

  const zoomClient = useMemo(() => {
    ZoomMtgEmbedded.destroyClient()
    return ZoomMtgEmbedded.createClient()
  }, [])

  const initZoom = useCallback(
    async ({ zoomWidth, zoomHeight }) => {
      await zoomClient.init({
        debug: getEnvironment() !== 'production',
        leaveOnPageUnload: true,
        zoomAppRoot: meetingSdkElement,
        customize: {
          video: {
            isResizable: false,
            viewSizes: {
              default: {
                width: zoomWidth,
                height: zoomHeight
              }
            },
            popper: {
              disableDraggable: true
            }
          }
        }
      })
    },
    [meetingSdkElement, zoomClient]
  )

  const createMeeting = useCallback(async () => {
    const token = await getZoomToken()

    if (!token) {
      logError('No zoom token was found while trying to create a meeting')
      return
    }

    const currentLoggedUser = await Auth.currentAuthenticatedUser()

    try {
      logInfo('Creating zoom meeting')
      const meeting = await API.post('grinServerlessApi', `/platform/v1/zoom/meetings`, {
        headers: {
          'x-api-key': currentLoggedUser.signInUserSession.accessToken.jwtToken,
          'zoom-token': token
        },
        body: {
          topic: t('pages.patients.patientSidebar.items.liveCalls.instantMeeting'),
          agenda: t('pages.patients.patientSidebar.items.liveCalls.instantMeeting')
        }
      })

      trackEvent('Live call - Meeting created')

      logInfo('Zoom meeting created, creating user token')
      const userToken = await API.get(
        'grinServerlessApi',
        `/platform/v1/zoom/user/token?${queryString.stringify({
          meetingNumber: meeting.id,
          role: RoleTypes.Host
        })}`,
        {}
      )
      logInfo('Zoom user token created', {
        signature: userToken,
        sdkKey: zoomClientId,
        meetingNumber: meeting.id,
        userName: doctorName
      })

      trackEvent('Live call - Zoom token created for meeting')

      await zoomClient.join({
        signature: userToken,
        sdkKey: zoomClientId,
        meetingNumber: meeting.id,
        userName: doctorName
      })
      trackEvent('Live call - Joined meeting successfully')
      logInfo('User joined zoom meeting')

      return meeting.id
    } catch (error) {
      logError('An error occured while trying to create and join zoom meeting', { error, doctorId: id })
    }
  }, [getZoomToken, t, zoomClientId, doctorName, zoomClient, id])

  const loadMeetingSdkElement = useCallback(() => {
    const interval = setInterval(() => {
      const div = document.getElementById('meetingSDKElement')
      if (div) {
        setMeetingSdkElement(div)
        clearInterval(interval)
      }
    }, 100)
  }, [])

  loadMeetingSdkElement()

  return {
    meetingSdkElement,
    createMeeting,
    initZoom,
    zoomClient
  }
}
