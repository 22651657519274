import { getUserGroups, evaluateRolePermissions } from '../utils/authUtils'
import { useSelector } from 'react-redux'
import { useCallback, useMemo } from 'react'
import { DsoRoles, Roles } from 'consts/authConsts'

export default () => {
  const doctor = useSelector(state => state.profileReducer.doctor)

  const userGroups = useMemo(() => getUserGroups(), [])
  const allowedGroupPermissions = useMemo(() => JSON.parse(doctor?.user?.allowed_groups_permissions || '[]'), [doctor])
  const permissions = useMemo(() => evaluateRolePermissions(userGroups), [userGroups])

  const isUserOfRole = useCallback(role => userGroups?.includes(role), [userGroups])
  const isHiUser = useMemo(() => isUserOfRole(Roles.HI), [isUserOfRole])
  const isGiUser = useMemo(() => isUserOfRole(Roles.GI), [isUserOfRole])
  const isDsoUser = useMemo(() => DsoRoles.some(isUserOfRole), [isUserOfRole])

  return {
    userGroups,
    permissions,
    groupPermissions: allowedGroupPermissions,
    allowedGroups: allowedGroupPermissions.map(groupPermission => groupPermission.groupKey),
    isHiUser,
    isGiUser,
    isDsoUser,
    isUserOfRole
  }
}
