import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { Grid } from '@material-ui/core'
import { ArrowDropdownDown, ArrowDropdownUp } from 'components/common/icons'

const useStyles = makeStyles(theme => ({
  themeSelectorTrigger: {
    background: 'var(--bg-color-timelineV2)',
    cursor: 'pointer',
    position: 'relative',
    zIndex: 1
  }
}))

const MiniTimelineThemeSelectorTrigger = ({ isMenuOpen = false, selectedThemeIcon }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      className={classes.themeSelectorTrigger}
    >
      <Grid item>
        <Grid container alignItems="center" justifyContent="center" spacing={1}>
          <Grid item>
            <DazzedParagraph14 bold textAlign="center">
              {t('pages.patients.selectedPatient.timelineV2.miniTimeline.buttons.changeView')}
            </DazzedParagraph14>
          </Grid>
          <Grid item style={{ lineHeight: 0.8 }}>
            {isMenuOpen ? <ArrowDropdownUp /> : <ArrowDropdownDown />}
          </Grid>
        </Grid>
      </Grid>
      <Grid item>{selectedThemeIcon}</Grid>
    </Grid>
  )
}

export default MiniTimelineThemeSelectorTrigger
