import { configureStore } from '@reduxjs/toolkit'
import { routerMiddleware as createRouterMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import rootReducer from '../reducers'
import rootEpic from '../epics'
import { createEpicMiddleware } from 'redux-observable'
import { api } from './api'

export const history = createBrowserHistory()
const routerMiddleware = createRouterMiddleware(history)
const epicMiddleware = createEpicMiddleware({
  dependencies: {
    history
  }
})

export const store = configureStore({
  reducer: rootReducer(history),
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: {
        ignoredPaths: [
          'authReducer',
          'treatmentReducer',
          'profileReducer',
          'appReducer',
          'patientsReducer',
          'profileReducer',
          'giReducer',
          'hiReducer',
          'pmsReducer',
          'chatReducer',
          'pixelReducer',
          'billingReducer',
          'productReducer',
          'supportReducer',
          'timelineReducer',
          'autochartReducer',
          'practiceReducer',
          'txTrackerReducer',
          'quickSignUpReducer',
          'treatmentReducer',
          'taskManagerReducer',
          'announcementsReducer',
          'multiPracticeReducer'
        ]
      }
    }).concat(epicMiddleware, api.middleware, routerMiddleware),
  devTools: process.env.NODE_ENV !== 'production'
})

epicMiddleware.run(rootEpic)
