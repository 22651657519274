import Actions from 'actions'
import moment from 'moment'
import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { trackEvent } from 'utils/analyticsUtils'
import { isScanEligableForBeforeAfter } from 'utils/scanUtils'
import { createScansTimeLineItems } from 'utils/timelineUtils'

export default () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { grinScans, treatment } = useSelector(state => state.treatmentReducer)
  const { isOpen, linkEnabled, left, right } = useSelector(state => state.treatmentReducer.compareScans)
  const { patient } = useSelector(state => state.patientsReducer)

  const scanItems = useMemo(() => createScansTimeLineItems(grinScans), [grinScans])

  const scanSelectionOptions = useMemo(
    () =>
      scanItems.map((item, i) => {
        const params = {
          scanIndex: item.data.index,
          appliance: item.data.scans[0].appliance,
          applianceIndex: item.data.scans[0].applianceIndex,
          totalApplianceNumber: treatment.totalApplianceNumber,
          timestamp: moment(item.timestamp).format('MMMM Do, YYYY')
        }
        return {
          value: i + 1,
          label:
            treatment?.treatmentType === 'Aligners'
              ? t('pages.patients.selectedPatient.timeline.compareScans.alignersScanOptionLabel', params)
              : t('pages.patients.selectedPatient.timeline.compareScans.defaultScanOptionLabel', params),
          scans: item.data.scans
        }
      }),
    [scanItems, t, treatment]
  )

  const getScanForSide = useCallback(
    ({ side, withAligner }) => {
      const sideData = side === 'left' ? left : right
      const scans = scanSelectionOptions[sideData.scanIndex]?.scans
      if (scans?.length === 1) {
        return scans[0]
      } else {
        return scanSelectionOptions[sideData.scanIndex]?.scans?.find(scan => scan.withAligner === withAligner)
      }
    },
    [left.scanIndex, left.withAligners, right.scanIndex, right.withAligners, scanSelectionOptions] //eslint-disable-line
  )

  const selectedScans = useMemo(
    () => ({
      left: {
        withAligners: getScanForSide({ side: 'left', withAligner: true }),
        withoutAligners: getScanForSide({ side: 'left', withAligner: false })
      },
      right: {
        withAligners: getScanForSide({ side: 'right', withAligner: true }),
        withoutAligners: getScanForSide({ side: 'right', withAligner: false })
      }
    }),
    [getScanForSide]
  )

  const toggleLink = useCallback(() => {
    const value = !linkEnabled
    trackEvent('Compare Scans - toggle link', {
      value: value ? 'on' : 'off'
    })
    dispatch(Actions.compareScansSetLinkScanEnabled(value))
  }, [linkEnabled, dispatch])

  const canGenerateBeforeAfter = useMemo(
    () =>
      isScanEligableForBeforeAfter(selectedScans.left.withoutAligners) &&
      isScanEligableForBeforeAfter(selectedScans.right.withoutAligners),
    [selectedScans]
  )

  const generateBeforeAfterForSelectedScans = useCallback(() => {
    const scans = [selectedScans.left.withoutAligners, selectedScans.right.withoutAligners].sort(
      (s1, s2) => new Date(s1.createdAt).getTime() - new Date(s2.createdAt).getTime()
    )

    scans[0].id === scans[1].id
      ? dispatch(
          Actions.showAlert({
            type: 'alert',
            title: t('general.genericOops'),
            message: t('dialogs.beforeAfter.sameScansAlert'),
            primaryButtonText: t('general.close')
          })
        )
      : dispatch(
          Actions.toggleBeforeAfterDialog({
            open: true,
            firstScanId: scans[0]?.id,
            scanId: scans[1]?.id,
            patientId: patient.id,
            analytics: {
              source: 'compare-scans'
            }
          })
        )
  }, [dispatch, t, selectedScans, patient])

  useEffect(() => {
    if (!isOpen) {
      dispatch(
        Actions.compareScansSetScanSelection({
          side: 'left',
          scanIndex: 0,
          selectedTile: 0
        })
      )
      dispatch(
        Actions.compareScansSetScanSelection({
          side: 'right',
          scanIndex: scanSelectionOptions?.length - 1,
          selectedTile: 0,
          withAligners: false
        })
      )
    }
  }, [scanSelectionOptions, dispatch, isOpen])

  return {
    scanItems,
    scanSelectionOptions,
    selectedScans,
    isLinked: linkEnabled,
    canGenerateBeforeAfter,
    generateBeforeAfterForSelectedScans,
    toggleLink
  }
}
