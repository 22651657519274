import * as React from 'react'

export default () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.375 1.5C0.375 0.87868 0.87868 0.375 1.5 0.375H10.5C11.1213 0.375 11.625 0.87868 11.625 1.5V7.125C11.625 7.74632 11.1213 8.25 10.5 8.25H4.875H3.75L0.375 11.625V1.5Z"
      fill="#3C52EF"
    />
  </svg>
)
