import { API, graphqlOperation } from 'aws-amplify'
import { SEARCH_RESULT_LIMIT } from 'consts/patientsConsts'
import { searchPatientSearchModels } from 'graphql/customQueries'
import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { buildPatientSearchFilter } from 'utils/searchUtils'

export default () => {
  const { doctor } = useSelector(state => state.profileReducer)
  const [searchResults, setSearchResults] = useState([])

  const searchPatients = useCallback(
    async searchTerm => {
      if (!searchTerm) {
        setSearchResults([])
      }

      const { data } = await API.graphql(
        graphqlOperation(searchPatientSearchModels, {
          limit: SEARCH_RESULT_LIMIT,
          filter: buildPatientSearchFilter(
            searchTerm,
            doctor.username,
            JSON.parse(doctor.user?.allowed_groups_permissions || '[]')
          ),
          sort: {
            field: 'lastMessageTimestamp',
            direction: 'desc'
          }
        })
      )

      setSearchResults(data?.searchPatientSearchModels?.items || [])
    },
    [doctor]
  )

  return {
    searchPatients,
    searchResults
  }
}
