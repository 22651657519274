import React from 'react'
import { makeStyles } from '@material-ui/styles'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { ButtonBase } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  sidebarItemRoot: {
    display: 'flex',
    padding: '2px 5px 2px 5px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    lineHeight: 0.8,
    cursor: 'pointer'
  },
  icon: {
    padding: '10px 11px 10px 10px'
  },
  title: {
    userSelect: 'none'
  },
  selectedTitle: {
    color: 'var(--text-color-3)'
  }
}))

const SidebarItem = ({ icon, title, isActive = false, onClick }) => {
  const classes = useStyles()

  return (
    <ButtonBase className={classes.sidebarItemRoot} onClick={onClick}>
      <div className={classes.icon}>
        {React.createElement(icon, {
          isActive
        })}
      </div>
      <DazzedParagraph14 strong className={[classes.title, isActive ? classes.selectedTitle : ''].join(' ')}>
        {title}
      </DazzedParagraph14>
    </ButtonBase>
  )
}

export default SidebarItem
