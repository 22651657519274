import { prettyError } from '@grin-rnd/grin-api-sdk/dist/utils/ErrorUtils'
import { logError, logInfo } from './logUtils'

/**
 *
 * @param {Object} args
 * @param {Array<{items: Array<any>}>} args.pages - The InfiniteQuery pages list.
 * @param {Function} args.predicate - A function that receives an item and returns a boolean. If the function returns `true`, the item will be updated.
 * @param {Object} args.itemPatch - The object with the properties to be patched.
 */
export const patchPaginatedGraphQueryState = ({ pages = [], predicate = item => true, itemPatch = {} }) => {
  pages.forEach(page => {
    page.items.forEach(item => {
      if (predicate(item)) {
        Object.assign(item, { ...item, ...itemPatch })
      }
    })
  })
}

/**
 * Logs the outcome (success / error) and duration of an API endpoint.
 * @param {Object} args
 * @param {Promise} args.queryFulfilled - The query promise from RTK.
 * @param {string} args.endpointName - The name of the endpoint.
 * @param {Object} [args.extraParams={}] - Extra parameters, e.g. the query arguments.
 */
export const apiEndpointProfiler = async ({ queryFulfilled, endpointName, extraParams = {} }) => {
  const startTime = new Date().getTime()
  try {
    await queryFulfilled
    const took = new Date().getTime() - startTime
    logInfo(`${endpointName}: request resolved successfully. took: ${took}`, {
      ...extraParams,
      took
    })
  } catch (ex) {
    const took = new Date().getTime() - startTime
    logError(`${endpointName}: requrest rejected`, {
      ...extraParams,
      error: prettyError(ex),
      took
    })
  }
}
