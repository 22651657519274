import { useCallback, useMemo, useState } from 'react'

export default ({ sketchedPoses = {}, setSketchedPoses = () => {}, currentPose = '', poseOriginalImageSrc = '' }) => {
  const [isSketchVisible, setIsSketchVisible] = useState(true)

  const isCurrentPoseSketched = useMemo(() => !!sketchedPoses[currentPose], [currentPose, sketchedPoses])

  const currentPoseSketch = useMemo(
    () => sketchedPoses[currentPose] || poseOriginalImageSrc,
    [poseOriginalImageSrc, currentPose, sketchedPoses]
  )

  const displayedPoseImage = useMemo(() => {
    if (!isSketchVisible) {
      return poseOriginalImageSrc
    }

    return currentPoseSketch
  }, [isSketchVisible, poseOriginalImageSrc, currentPoseSketch])

  const setCurrentPoseSketch = useCallback(
    image => {
      setSketchedPoses({
        ...sketchedPoses,
        [currentPose]: image
      })
    },
    [currentPose, sketchedPoses, setSketchedPoses]
  )

  const clearCurrentPoseSketch = useCallback(() => {
    setCurrentPoseSketch(null)
  }, [setCurrentPoseSketch])

  return {
    currentPoseSketch,
    isCurrentPoseSketched,
    displayedPoseImage,
    isSketchVisible,
    setIsSketchVisible,
    setCurrentPoseSketch,
    clearCurrentPoseSketch
  }
}
