import Actions from 'actions'
import OralScoreTheme from 'components/common/icons/timelineV2/themes/OralScoreTheme'
import ScanTrackingTheme from 'components/common/icons/timelineV2/themes/ScanTrackingTheme'
import TxTrackerTheme from 'components/common/icons/timelineV2/themes/TxTrackerTheme'
import { DEFAULT_MINI_TIMELINE_THEME, MiniTimelineThemes } from 'consts/timelineConsts'
import useFeatureFlags from 'hooks/useFeatureFlags'
import usePlanLimits from 'hooks/usePlanLimits'
import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const THEME_LOCAL_STORAGE_KEY = 'miniTimeline.theme'

export default () => {
  const dispatch = useDispatch()
  const { theme } = useSelector(state => state.timelineReducer.miniTimeline)

  const {
    oralHygieneScore: oralHygieneScoreFF,
    tagScanForNonHI: tagScanForNonHIFF,
    treatmentTracker: treatmentTrackerFF,
    txTrackerMiniTimeline: txTrackerMiniTimelineFF
  } = useFeatureFlags()
  const { withScanTracking } = usePlanLimits()

  const themeOptions = useMemo(
    () =>
      [
        {
          type: MiniTimelineThemes.ScanTracking,
          icon: <ScanTrackingTheme />,
          condition: withScanTracking || tagScanForNonHIFF
        },
        {
          type: MiniTimelineThemes.OralScore,
          icon: <OralScoreTheme />,
          condition: oralHygieneScoreFF
        },
        {
          type: MiniTimelineThemes.TxTracker,
          icon: <TxTrackerTheme />,
          condition: treatmentTrackerFF && txTrackerMiniTimelineFF
        }
      ].filter(theme => theme.condition),
    [withScanTracking, tagScanForNonHIFF, oralHygieneScoreFF, treatmentTrackerFF, txTrackerMiniTimelineFF]
  )

  const selectTheme = useCallback(
    theme => {
      localStorage.setItem(THEME_LOCAL_STORAGE_KEY, theme)
      dispatch(Actions.setMiniTimelineTheme({ theme }))
    },
    [dispatch]
  )

  useEffect(() => {
    const userThemePreference = localStorage.getItem(THEME_LOCAL_STORAGE_KEY)
    const preferredTheme = themeOptions.find(theme => theme.type === userThemePreference)

    if (preferredTheme) {
      selectTheme(preferredTheme.type)
    } else {
      const fallbackTheme = themeOptions[0]?.type || DEFAULT_MINI_TIMELINE_THEME
      selectTheme(fallbackTheme)
    }
  }, [themeOptions, selectTheme])

  return {
    themeOptions,
    selectTheme,
    selectedTheme: theme
  }
}
