import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import ScanFeedCard from './ScanFeedCard'
import OralScoreTag from '../Tags/OralScoreTag'
import { OralScoreCategories, OralScoreColorCodes } from 'consts/oralScoreConsts'
import { Grid } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'

const useStyles = makeStyles(theme => ({
  scoreCategory: {
    borderRight: '1px solid rgba(171, 176, 184, 1)',
    paddingRight: 24,
    marginRight: 20,
    '&.lastItem': {
      borderRight: '0px'
    }
  },
  scoreLabel: {
    fontWeight: 'bold'
  }
}))

const OralScoresCard = ({ oralScores = {} }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <ScanFeedCard
      title={
        <OralScoreTag
          scoreValue={oralScores.hygieneScore}
          category={OralScoreCategories.Hygiene}
          boldLabel
          withBackground={false}
          withIcon
          fontSize="14px"
        />
      }
    >
      <Grid container>
        <Grid item className={classes.scoreCategory}>
          <DazzedParagraph14 strong>
            {t(`pages.patients.selectedPatient.timelineV2.oralScores.categories.${OralScoreCategories.PerioRisk}`)}
            <div className={classes.scoreLabel} style={{ color: OralScoreColorCodes[oralScores.perioRisk] }}>
              {t(`pages.patients.selectedPatient.timelineV2.oralScores.scores.${oralScores.perioRisk}`)}
            </div>
          </DazzedParagraph14>
        </Grid>
        <Grid item className={classes.scoreCategory}>
          <DazzedParagraph14 strong>
            {t(`pages.patients.selectedPatient.timelineV2.oralScores.categories.${OralScoreCategories.DentalRisk}`)}
            <div className={classes.scoreLabel} style={{ color: OralScoreColorCodes[oralScores.dentalRisk] }}>
              {t(`pages.patients.selectedPatient.timelineV2.oralScores.scores.${oralScores.dentalRisk}`)}
            </div>
          </DazzedParagraph14>
        </Grid>
        <Grid item className={[classes.scoreCategory, 'lastItem'].join(' ')}>
          <DazzedParagraph14 strong>
            {t(`pages.patients.selectedPatient.timelineV2.oralScores.categories.${OralScoreCategories.Hygiene}`)}
            <div className={classes.scoreLabel} style={{ color: OralScoreColorCodes[oralScores.hygieneScore] }}>
              {t(`pages.patients.selectedPatient.timelineV2.oralScores.scores.${oralScores.hygieneScore}`)}
            </div>
          </DazzedParagraph14>
        </Grid>
      </Grid>
    </ScanFeedCard>
  )
}

export default OralScoresCard
