import { makeStyles } from '@material-ui/core'
import React, { Fragment } from 'react'
import BaseModal from 'components/common/modals/BaseModal'
import DazzedHeading14 from 'components/common/text/DazzedHeading14'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles({
  alertModal: {
    padding: 20,
    textAlign: 'center',
    width: '50vw'
  },
  wrongFilesContainer: {
    textAlign: 'left',
    background: 'var(--bg-color-14)',
    marginTop: 15,
    borderRadius: 10,
    padding: 10,
    lineHeight: '25px'
  }
})

const InvalidFilesModal = ({ isOpen, onClose, invalidFiles }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <BaseModal
      className={classes.alertModal}
      open={isOpen}
      primaryLabel={t('general.gotItThanks')}
      onPrimaryBtnClick={onClose}
      withCloseIcon={false}
      largerButtons
      title={t('dialogs.patientInfo.txTrackerSetup.invalidFilesModal.title')}
      variant="alert"
    >
      <DazzedHeading14 regular>{t('dialogs.patientInfo.txTrackerSetup.invalidFilesModal.description')}</DazzedHeading14>
      <div className={classes.wrongFilesContainer}>
        {invalidFiles?.map((f, i) => (
          <Fragment key={i}>
            <span>{f.name}</span>
            <br />
          </Fragment>
        ))}
      </div>
    </BaseModal>
  )
}

export default InvalidFilesModal
