/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const grinUserByUsername = /* GraphQL */ `
  query GrinUserByUsername(
    $username: String
    $sortDirection: ModelSortDirection
    $filter: ModelGrinUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    grinUserByUsername(
      username: $username
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        externalIds
        identityId
        timezone
        version
        model
        deviceName
        language
        type
        a_doctor
        a_readers
        a_writers
        isActive
        owner
        program
        authConfirmationDate
        appSettings
        groups
        termsVersion
        updatedAt
        createdAt
        marketingData
        allowed_groups_permissions
        _version
        _deleted
        _lastChangedAt
        grinPlanKey
        stripeCustomerId
        planOverrides
        messagingPreferences {
          id
          reminderTime
          whiteningTime
          whiteningScanTime
          whiteningScanDays
          a_readers
          a_writers
          owner
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
        featureFlags {
          id
          featureFlagsUserId
          username
          a_readers
          a_writers
          a_doctor
          flags
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patient {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        messages {
          nextToken
          startedAt
        }
        rooms {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`

export const grinUserByUsernameTypeByADoctor = /* GraphQL */ `
query GrinUserByUsernameTypeByADoctor(
  $type: String
  $a_doctor: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelGrinUserFilterInput
  $limit: Int
  $nextToken: String
) {
  grinUserByUsernameTypeByADoctor(
    type: $type
    a_doctor: $a_doctor
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      isActive
      username
      patient {
        id
        details {
          email
        }
      }
    }
  }
}
`      
export const grinUsersByProgram = /* GraphQL */ `
  query GrinUsersByProgram(
    $program: String
    $sortDirection: ModelSortDirection
    $filter: ModelGrinUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    grinUsersByProgram(
      program: $program
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        identityId
        timezone
        version
        model
        deviceName
        language
        type
        a_doctor
        a_readers
        a_writers
        isActive
        owner
        program
        authConfirmationDate
        appSettings
        groups
        termsVersion
        updatedAt
        createdAt
        marketingData
        allowed_groups_permissions
        _version
        _deleted
        _lastChangedAt
        grinPlanKey
        stripeCustomerId
        planOverrides
        messagingPreferences {
          id
          reminderTime
          whiteningTime
          whiteningScanTime
          whiteningScanDays
          a_readers
          a_writers
          owner
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
        featureFlags {
          id
          featureFlagsUserId
          username
          a_readers
          a_writers
          a_doctor
          flags
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patient {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        messages {
          nextToken
          startedAt
        }
        rooms {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`
export const syncGrinUsers = /* GraphQL */ `
  query SyncGrinUsers(
    $filter: ModelGrinUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncGrinUsers(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        username
        identityId
        timezone
        version
        model
        deviceName
        language
        type
        a_doctor
        a_readers
        a_writers
        isActive
        owner
        program
        authConfirmationDate
        appSettings
        groups
        termsVersion
        updatedAt
        createdAt
        marketingData
        allowed_groups_permissions
        _version
        _deleted
        _lastChangedAt
        grinPlanKey
        stripeCustomerId
        planOverrides
        messagingPreferences {
          id
          reminderTime
          whiteningTime
          whiteningScanTime
          whiteningScanDays
          a_readers
          a_writers
          owner
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
        featureFlags {
          id
          featureFlagsUserId
          username
          a_readers
          a_writers
          a_doctor
          flags
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patient {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        messages {
          nextToken
          startedAt
        }
        rooms {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`
export const getGrinUser = /* GraphQL */ `
  query GetGrinUser($id: ID!) {
    getGrinUser(id: $id) {
      id
      username
      identityId
      timezone
      version
      model
      deviceName
      language
      type
      a_doctor
      a_readers
      a_writers
      isActive
      owner
      program
      authConfirmationDate
      appSettings
      groups
      termsVersion
      updatedAt
      createdAt
      marketingData
      allowed_groups_permissions
      _version
      _deleted
      _lastChangedAt
      grinPlanKey
      stripeCustomerId
      planOverrides
      messagingPreferences {
        id
        reminderTime
        whiteningTime
        whiteningScanTime
        whiteningScanDays
        reminders {
          type
          medium
        }
        engagements {
          type
          medium
        }
        entities {
          type
          medium
          filters
        }
        contacts {
          name
          phone
          countryCode
          email
        }
        a_readers
        a_writers
        owner
        updatedAt
        _version
        _deleted
        _lastChangedAt
        createdAt
        user {
          id
          externalIds
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
      }
      featureFlags {
        id
        featureFlagsUserId
        username
        a_readers
        a_writers
        a_doctor
        flags
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        user {
          id
          externalIds
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
      }
      patient {
        id
        publicId
        patientDoctorId
        username
        auth_status
        task_status
        details {
          name
          firstName
          lastName
          email
          phone
          countryCode
          birthdate
          address
          city
          zipcode
          state
          country
          ageRange
          parentFirstName
          parentLastName
          parentEmail
          address2
          treatmentType
        }
        owner
        a_doctor
        a_readers
        a_writers
        isActive
        photo {
          bucket
          region
          key
        }
        plan
        rcToRmDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          externalIds
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        initialScans {
          nextToken
          startedAt
        }
        treatments {
          nextToken
          startedAt
        }
        grinScans {
          nextToken
          startedAt
        }
        pearlScans {
          nextToken
          startedAt
        }
        cycles {
          nextToken
          startedAt
        }
        actionItems {
          nextToken
          startedAt
        }
        patientTags {
          nextToken
          startedAt
        }
      }
      doctor {
        id
        rcToken
        username
        auth_status
        email
        a_readers
        a_writers
        name
        degrees
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          email
          countryCode
          practicePhone
          practiceCountryCode
          calendarId
          availability
          homepageUrl
        }
        owner
        photo {
          bucket
          region
          key
        }
        allowedZipcodes
        startPracticeYear
        hasLocatorConsent
        bio
        contactEmail
        contactName
        phoneNumber
        countryCode
        metricType
        searchRadius
        areaOfCoverage
        accountOwnerId
        roleDescription
        accessType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          externalIds
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        patients {
          nextToken
          startedAt
        }
        accountOwner {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        scopesOrdered
        billingInfo {
          id
          owner
          payeeName
          phoneNumber
          payeeZip
          accountNumber
          routingNumber
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        messageTemplates {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
      messages {
        items {
          id
          grinUserId
          roomId
          uploadingDate
          owner
          members
          a_readers
          a_writers
          type
          content
          readBy
          href
          hrefLabel
          promotionId
          promotionData
          templateId
          metadata
          isTransferred
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      rooms {
        items {
          id
          members
          a_readers
          a_writers
          grinUserId
          roomId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`
export const listGrinUsers = /* GraphQL */ `
  query ListGrinUsers($filter: ModelGrinUserFilterInput, $limit: Int, $nextToken: String) {
    listGrinUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        identityId
        timezone
        version
        model
        deviceName
        language
        type
        a_doctor
        a_readers
        a_writers
        isActive
        owner
        program
        authConfirmationDate
        appSettings
        groups
        termsVersion
        updatedAt
        createdAt
        marketingData
        allowed_groups_permissions
        _version
        _deleted
        _lastChangedAt
        grinPlanKey
        stripeCustomerId
        planOverrides
        messagingPreferences {
          id
          reminderTime
          whiteningTime
          whiteningScanTime
          whiteningScanDays
          a_readers
          a_writers
          owner
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
        featureFlags {
          id
          featureFlagsUserId
          username
          a_readers
          a_writers
          a_doctor
          flags
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patient {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        messages {
          nextToken
          startedAt
        }
        rooms {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`
export const getMessagingPreferences = /* GraphQL */ `
  query GetMessagingPreferences($id: ID!) {
    getMessagingPreferences(id: $id) {
      id
      reminderTime
      whiteningTime
      whiteningScanTime
      whiteningScanDays
      reminders {
        type
        medium
      }
      engagements {
        type
        medium
      }
      entities {
        type
        medium
      }
      contacts {
        name
        phone
        countryCode
        email
      }
      a_readers
      a_writers
      owner
      updatedAt
      _version
      _deleted
      _lastChangedAt
      createdAt
      user {
        id
        username
        identityId
        timezone
        version
        model
        deviceName
        language
        type
        a_doctor
        a_readers
        a_writers
        isActive
        owner
        program
        authConfirmationDate
        appSettings
        groups
        termsVersion
        updatedAt
        createdAt
        marketingData
        allowed_groups_permissions
        _version
        _deleted
        _lastChangedAt
        grinPlanKey
        stripeCustomerId
        planOverrides
        messagingPreferences {
          id
          reminderTime
          whiteningTime
          whiteningScanTime
          whiteningScanDays
          a_readers
          a_writers
          owner
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
        featureFlags {
          id
          featureFlagsUserId
          username
          a_readers
          a_writers
          a_doctor
          flags
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patient {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        messages {
          nextToken
          startedAt
        }
        rooms {
          nextToken
          startedAt
        }
      }
    }
  }
`
export const listMessagingPreferencess = /* GraphQL */ `
  query ListMessagingPreferencess(
    $filter: ModelMessagingPreferencesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessagingPreferencess(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        reminderTime
        whiteningTime
        whiteningScanTime
        whiteningScanDays
        reminders {
          type
          medium
        }
        engagements {
          type
          medium
        }
        entities {
          type
          medium
        }
        contacts {
          name
          phone
          countryCode
          email
        }
        a_readers
        a_writers
        owner
        updatedAt
        _version
        _deleted
        _lastChangedAt
        createdAt
        user {
          id
          externalIds
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
      }
      nextToken
      startedAt
    }
  }
`
export const messagingPreferencesByWhiteningTime = /* GraphQL */ `
  query MessagingPreferencesByWhiteningTime(
    $whiteningTime: String
    $sortDirection: ModelSortDirection
    $filter: ModelMessagingPreferencesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagingPreferencesByWhiteningTime(
      whiteningTime: $whiteningTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        reminderTime
        whiteningTime
        whiteningScanTime
        whiteningScanDays
        reminders {
          type
          medium
        }
        engagements {
          type
          medium
        }
        entities {
          type
          medium
        }
        contacts {
          name
          phone
          countryCode
          email
        }
        a_readers
        a_writers
        owner
        updatedAt
        _version
        _deleted
        _lastChangedAt
        createdAt
        user {
          id
          externalIds
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
      }
      nextToken
      startedAt
    }
  }
`
export const messagingPreferencesByWhiteningScanTime = /* GraphQL */ `
  query MessagingPreferencesByWhiteningScanTime(
    $whiteningScanTime: String
    $sortDirection: ModelSortDirection
    $filter: ModelMessagingPreferencesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagingPreferencesByWhiteningScanTime(
      whiteningScanTime: $whiteningScanTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        reminderTime
        whiteningTime
        whiteningScanTime
        whiteningScanDays
        reminders {
          type
          medium
        }
        engagements {
          type
          medium
        }
        entities {
          type
          medium
        }
        contacts {
          name
          phone
          countryCode
          email
        }
        a_readers
        a_writers
        owner
        updatedAt
        _version
        _deleted
        _lastChangedAt
        createdAt
        user {
          id
          externalIds
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
      }
      nextToken
      startedAt
    }
  }
`
export const syncMessagingPreferences = /* GraphQL */ `
  query SyncMessagingPreferences(
    $filter: ModelMessagingPreferencesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMessagingPreferences(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        reminderTime
        whiteningTime
        whiteningScanTime
        whiteningScanDays
        reminders {
          type
          medium
        }
        engagements {
          type
          medium
        }
        entities {
          type
          medium
        }
        contacts {
          name
          phone
          countryCode
          email
        }
        a_readers
        a_writers
        owner
        updatedAt
        _version
        _deleted
        _lastChangedAt
        createdAt
        user {
          id
          externalIds
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
      }
      nextToken
      startedAt
    }
  }
`
export const getFeatureFlags = /* GraphQL */ `
  query GetFeatureFlags($id: ID!) {
    getFeatureFlags(id: $id) {
      id
      featureFlagsUserId
      username
      a_readers
      a_writers
      a_doctor
      flags
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      user {
        id
        username
        identityId
        timezone
        version
        model
        deviceName
        language
        type
        a_doctor
        a_readers
        a_writers
        isActive
        owner
        program
        authConfirmationDate
        appSettings
        groups
        termsVersion
        updatedAt
        createdAt
        marketingData
        allowed_groups_permissions
        _version
        _deleted
        _lastChangedAt
        grinPlanKey
        stripeCustomerId
        planOverrides
        messagingPreferences {
          id
          reminderTime
          whiteningTime
          whiteningScanTime
          whiteningScanDays
          a_readers
          a_writers
          owner
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
        featureFlags {
          id
          featureFlagsUserId
          username
          a_readers
          a_writers
          a_doctor
          flags
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patient {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        messages {
          nextToken
          startedAt
        }
        rooms {
          nextToken
          startedAt
        }
      }
    }
  }
`
export const listFeatureFlagss = /* GraphQL */ `
  query ListFeatureFlagss($filter: ModelFeatureFlagsFilterInput, $limit: Int, $nextToken: String) {
    listFeatureFlagss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        featureFlagsUserId
        username
        a_readers
        a_writers
        a_doctor
        flags
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        user {
          id
          externalIds
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
      }
      nextToken
      startedAt
    }
  }
`
export const featureFlagsByGrinUserId = /* GraphQL */ `
  query FeatureFlagsByGrinUserId(
    $featureFlagsUserId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelFeatureFlagsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    featureFlagsByGrinUserId(
      featureFlagsUserId: $featureFlagsUserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        featureFlagsUserId
        username
        a_readers
        a_writers
        a_doctor
        flags
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        user {
          id
          externalIds
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
      }
      nextToken
      startedAt
    }
  }
`
export const syncFeatureFlags = /* GraphQL */ `
  query SyncFeatureFlags(
    $filter: ModelFeatureFlagsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFeatureFlags(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        featureFlagsUserId
        username
        a_readers
        a_writers
        a_doctor
        flags
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        user {
          id
          externalIds
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
      }
      nextToken
      startedAt
    }
  }
`
export const getLead = /* GraphQL */ `
  query GetLead($id: ID!) {
    getLead(id: $id) {
      id
      email
      firstName
      lastName
      phone
      countryCode
      type
      status
      program
      extraData
      a_doctor
      a_readers
      a_writers
      doctorId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      doctor {
        id
        rcToken
        username
        auth_status
        email
        a_readers
        a_writers
        name
        degrees
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          email
          countryCode
          practicePhone
          practiceCountryCode
          calendarId
          availability
          homepageUrl
        }
        owner
        photo {
          bucket
          region
          key
        }
        allowedZipcodes
        startPracticeYear
        hasLocatorConsent
        bio
        contactEmail
        contactName
        phoneNumber
        countryCode
        metricType
        searchRadius
        areaOfCoverage
        accountOwnerId
        roleDescription
        accessType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          externalIds
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        patients {
          nextToken
          startedAt
        }
        accountOwner {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        scopesOrdered
        billingInfo {
          id
          owner
          payeeName
          phoneNumber
          payeeZip
          accountNumber
          routingNumber
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        messageTemplates {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
    }
  }
`
export const listLeads = /* GraphQL */ `
  query ListLeads($filter: ModelLeadFilterInput, $limit: Int, $nextToken: String) {
    listLeads(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        firstName
        lastName
        phone
        countryCode
        type
        status
        program
        extraData
        a_doctor
        a_readers
        a_writers
        doctorId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
      }
      nextToken
      startedAt
    }
  }
`
export const leadsByDoctorId = /* GraphQL */ `
  query LeadsByDoctorId(
    $doctorId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelLeadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    leadsByDoctorId(
      doctorId: $doctorId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        firstName
        lastName
        phone
        countryCode
        type
        status
        program
        extraData
        a_doctor
        a_readers
        a_writers
        doctorId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
      }
      nextToken
      startedAt
    }
  }
`
export const leadsByDoctorIdByProgram = /* GraphQL */ `
  query LeadsByDoctorIdByProgram(
    $doctorId: ID
    $program: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLeadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    leadsByDoctorIdByProgram(
      doctorId: $doctorId
      program: $program
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        firstName
        lastName
        phone
        countryCode
        type
        status
        program
        extraData
        a_doctor
        a_readers
        a_writers
        doctorId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
      }
      nextToken
      startedAt
    }
  }
`
export const leadsByDoctorIdByProgramByStatus = /* GraphQL */ `
  query LeadsByDoctorIdByProgramByStatus(
    $doctorId: ID
    $programStatus: ModelLeadLeadsByDoctorIdByProgramByStatusCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLeadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    leadsByDoctorIdByProgramByStatus(
      doctorId: $doctorId
      programStatus: $programStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        firstName
        lastName
        phone
        countryCode
        type
        status
        program
        extraData
        a_doctor
        a_readers
        a_writers
        doctorId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
      }
      nextToken
      startedAt
    }
  }
`

export const leadByEmail = /* GraphQL */ `
  query LeadByEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelLeadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    leadByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        firstName
        lastName
        phone
        countryCode
        type
        status
        program
        extraData
        a_doctor
        a_readers
        a_writers
        doctorId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
      }
      nextToken
      startedAt
    }
  }
`
export const syncLeads = /* GraphQL */ `
  query SyncLeads(
    $filter: ModelLeadFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLeads(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        email
        firstName
        lastName
        phone
        countryCode
        type
        status
        program
        extraData
        a_doctor
        a_readers
        a_writers
        doctorId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
      }
      nextToken
      startedAt
    }
  }
`
export const getPatient = /* GraphQL */ `
  query GetPatient($id: ID!) {
    getPatient(id: $id) {
      id
      publicId
      patientDoctorId
      username
      auth_status
      task_status
      details {
        name
        firstName
        lastName
        email
        phone
        countryCode
        birthdate
        address
        city
        zipcode
        state
        country
        ageRange
        parentFirstName
        parentLastName
        parentEmail
        address2
        treatmentType
      }
      owner
      a_doctor
      a_readers
      a_writers
      isActive
      photo {
        bucket
        region
        key
      }
      plan
      rcToRmDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      user {
        id
        username
        identityId
        timezone
        version
        model
        deviceName
        language
        type
        a_doctor
        a_readers
        a_writers
        isActive
        owner
        program
        authConfirmationDate
        appSettings
        groups
        termsVersion
        updatedAt
        createdAt
        marketingData
        allowed_groups_permissions
        _version
        _deleted
        _lastChangedAt
        grinPlanKey
        stripeCustomerId
        planOverrides
        messagingPreferences {
          id
          reminderTime
          whiteningTime
          whiteningScanTime
          whiteningScanDays
          a_readers
          a_writers
          owner
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
        featureFlags {
          id
          featureFlagsUserId
          username
          a_readers
          a_writers
          a_doctor
          flags
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patient {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        messages {
          nextToken
          startedAt
        }
        rooms {
          nextToken
          startedAt
        }
      }
      doctor {
        id
        rcToken
        username
        auth_status
        email
        a_readers
        a_writers
        name
        degrees
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          email
          countryCode
          practicePhone
          practiceCountryCode
          calendarId
          availability
          homepageUrl
        }
        owner
        photo {
          bucket
          region
          key
        }
        allowedZipcodes
        startPracticeYear
        hasLocatorConsent
        bio
        contactEmail
        contactName
        phoneNumber
        countryCode
        metricType
        searchRadius
        areaOfCoverage
        accountOwnerId
        roleDescription
        accessType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          externalIds
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        patients {
          nextToken
          startedAt
        }
        accountOwner {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        scopesOrdered
        billingInfo {
          id
          owner
          payeeName
          phoneNumber
          payeeZip
          accountNumber
          routingNumber
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        messageTemplates {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
      initialScans {
        items {
          id
          patientId
          date
          comment
          a_doctor
          a_patient
          a_readers
          a_writers
          scannerType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      treatments {
        items {
          id
          patientId
          type
          date
          statusId
          endDate
          stageDefaultDuration
          cycleInterval
          introMeetingDate
          defaultApplianceName
          currentApplianceIndex
          postConfirmationStatusKey
          cycleIntervalPeriodType
          whiteningProductType
          maxWhiteningTreatments
          status
          a_doctor
          a_patient
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      grinScans {
        items {
          id
          patientId
          a_doctor
          a_patient
          a_readers
          a_writers
          date
          status
          isOffCycle
          aligner
          appliance
          applianceIndex
          treatmentType
          isTransferred
          createdAt
          updatedAt
          simulationStatus
          scanPairId
          withAligner
          scanSummaryStatus
          scanSummaryData
          scanGuideAIMetadata
          metadata
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      pearlScans {
        items {
          id
          patientId
          a_doctor
          a_patient
          a_readers
          a_writers
          status
          scanUrls
          uploadedAt
          createdAt
          updatedAt
          AIData
          index
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      cycles {
        items {
          id
          patientId
          a_doctor
          a_patient
          a_readers
          a_writers
          createdAt
          updatedAt
          startDate
          originalEndDate
          actualEndDate
          index
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      actionItems {
        items {
          id
          actionItemDoctorId
          actionItemPatientId
          type
          priority
          status
          params
          a_doctor
          a_readers
          a_writers
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      patientTags {
        items {
          id
          patientTagPatientId
          patientTagTagId
          a_doctor
          a_patient
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`
export const listPatients = /* GraphQL */ `
  query ListPatients($filter: ModelPatientFilterInput, $limit: Int, $nextToken: String) {
    listPatients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        publicId
        patientDoctorId
        username
        auth_status
        task_status
        details {
          name
          firstName
          lastName
          email
          phone
          countryCode
          birthdate
          address
          city
          zipcode
          state
          country
          ageRange
          parentFirstName
          parentLastName
          parentEmail
          address2
          treatmentType
        }
        owner
        a_doctor
        a_readers
        a_writers
        isActive
        photo {
          bucket
          region
          key
        }
        plan
        rcToRmDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          externalIds
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        initialScans {
          nextToken
          startedAt
        }
        treatments {
          nextToken
          startedAt
        }
        grinScans {
          nextToken
          startedAt
        }
        pearlScans {
          nextToken
          startedAt
        }
        cycles {
          nextToken
          startedAt
        }
        actionItems {
          nextToken
          startedAt
        }
        patientTags {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`
export const patientByUsername = /* GraphQL */ `
  query PatientByUsername(
    $username: String
    $sortDirection: ModelSortDirection
    $filter: ModelPatientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    patientByUsername(
      username: $username
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        publicId
        patientDoctorId
        username
        auth_status
        task_status
        details {
          name
          firstName
          lastName
          email
          phone
          countryCode
          birthdate
          address
          city
          zipcode
          state
          country
          ageRange
          parentFirstName
          parentLastName
          parentEmail
          address2
          treatmentType
        }
        owner
        a_doctor
        a_readers
        a_writers
        isActive
        photo {
          bucket
          region
          key
        }
        plan
        rcToRmDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          externalIds
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        initialScans {
          nextToken
          startedAt
        }
        treatments {
          nextToken
          startedAt
        }
        grinScans {
          nextToken
          startedAt
        }
        pearlScans {
          nextToken
          startedAt
        }
        cycles {
          nextToken
          startedAt
        }
        actionItems {
          nextToken
          startedAt
        }
        patientTags {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`
export const patientsByDoctorId = /* GraphQL */ `
  query PatientsByDoctorId(
    $patientDoctorId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPatientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    patientsByDoctorId(
      patientDoctorId: $patientDoctorId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        publicId
        patientDoctorId
        username
        auth_status
        task_status
        details {
          name
          firstName
          lastName
          email
          phone
          countryCode
          birthdate
          address
          city
          zipcode
          state
          country
          ageRange
          parentFirstName
          parentLastName
          parentEmail
          address2
          treatmentType
        }
        owner
        a_doctor
        a_readers
        a_writers
        isActive
        photo {
          bucket
          region
          key
        }
        plan
        rcToRmDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          externalIds
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        initialScans {
          nextToken
          startedAt
        }
        treatments {
          nextToken
          startedAt
        }
        grinScans {
          nextToken
          startedAt
        }
        pearlScans {
          nextToken
          startedAt
        }
        cycles {
          nextToken
          startedAt
        }
        actionItems {
          nextToken
          startedAt
        }
        patientTags {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`
export const patientsByDoctorIdByPlan = /* GraphQL */ `
  query PatientsByDoctorIdByPlan(
    $patientDoctorId: ID
    $plan: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPatientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    patientsByDoctorIdByPlan(
      patientDoctorId: $patientDoctorId
      plan: $plan
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        publicId
        patientDoctorId
        username
        auth_status
        task_status
        details {
          name
          firstName
          lastName
          email
          phone
          countryCode
          birthdate
          address
          city
          zipcode
          state
          country
          ageRange
          parentFirstName
          parentLastName
          parentEmail
          address2
          treatmentType
        }
        owner
        a_doctor
        a_readers
        a_writers
        isActive
        photo {
          bucket
          region
          key
        }
        plan
        rcToRmDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          externalIds
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        initialScans {
          nextToken
          startedAt
        }
        treatments {
          nextToken
          startedAt
        }
        grinScans {
          nextToken
          startedAt
        }
        pearlScans {
          nextToken
          startedAt
        }
        cycles {
          nextToken
          startedAt
        }
        actionItems {
          nextToken
          startedAt
        }
        patientTags {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`
export const patientByDoctorUsername = /* GraphQL */ `
  query PatientByDoctorUsername(
    $a_doctor: String
    $sortDirection: ModelSortDirection
    $filter: ModelPatientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    patientByDoctorUsername(
      a_doctor: $a_doctor
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        publicId
        patientDoctorId
        username
        auth_status
        task_status
        details {
          name
          firstName
          lastName
          email
          phone
          countryCode
          birthdate
          address
          city
          zipcode
          state
          country
          ageRange
          parentFirstName
          parentLastName
          parentEmail
          address2
          treatmentType
        }
        owner
        a_doctor
        a_readers
        a_writers
        isActive
        photo {
          bucket
          region
          key
        }
        plan
        rcToRmDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          externalIds
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        initialScans {
          nextToken
          startedAt
        }
        treatments {
          nextToken
          startedAt
        }
        grinScans {
          nextToken
          startedAt
        }
        pearlScans {
          nextToken
          startedAt
        }
        cycles {
          nextToken
          startedAt
        }
        actionItems {
          nextToken
          startedAt
        }
        patientTags {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`
export const patientsByPublicId = /* GraphQL */ `
  query PatientsByPublicId(
    $publicId: String
    $sortDirection: ModelSortDirection
    $filter: ModelPatientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    patientsByPublicId(
      publicId: $publicId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        publicId
        patientDoctorId
        username
        auth_status
        task_status
        details {
          name
          firstName
          lastName
          email
          phone
          countryCode
          birthdate
          address
          city
          zipcode
          state
          country
          ageRange
          parentFirstName
          parentLastName
          parentEmail
          address2
          treatmentType
        }
        owner
        a_doctor
        a_readers
        a_writers
        isActive
        photo {
          bucket
          region
          key
        }
        plan
        rcToRmDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          externalIds
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        initialScans {
          nextToken
          startedAt
        }
        treatments {
          nextToken
          startedAt
        }
        grinScans {
          nextToken
          startedAt
        }
        pearlScans {
          nextToken
          startedAt
        }
        cycles {
          nextToken
          startedAt
        }
        actionItems {
          nextToken
          startedAt
        }
        patientTags {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`
export const syncPatients = /* GraphQL */ `
  query SyncPatients(
    $filter: ModelPatientFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPatients(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        publicId
        patientDoctorId
        username
        auth_status
        task_status
        details {
          name
          firstName
          lastName
          email
          phone
          countryCode
          birthdate
          address
          city
          zipcode
          state
          country
          ageRange
          parentFirstName
          parentLastName
          parentEmail
          address2
          treatmentType
        }
        owner
        a_doctor
        a_readers
        a_writers
        isActive
        photo {
          bucket
          region
          key
        }
        plan
        rcToRmDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          externalIds
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        initialScans {
          nextToken
          startedAt
        }
        treatments {
          nextToken
          startedAt
        }
        grinScans {
          nextToken
          startedAt
        }
        pearlScans {
          nextToken
          startedAt
        }
        cycles {
          nextToken
          startedAt
        }
        actionItems {
          nextToken
          startedAt
        }
        patientTags {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`
export const doctorByUsername = /* GraphQL */ `
  query DoctorByUsername(
    $username: String
    $sortDirection: ModelSortDirection
    $filter: ModelDoctorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    doctorByUsername(
      username: $username
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        rcToken
        username
        auth_status
        email
        a_readers
        a_writers
        name
        degrees
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          email
          countryCode
          practicePhone
          practiceCountryCode
          calendarId
          availability
          homepageUrl
        }
        owner
        photo {
          bucket
          region
          key
        }
        allowedZipcodes
        startPracticeYear
        hasLocatorConsent
        bio
        contactEmail
        contactName
        phoneNumber
        countryCode
        metricType
        searchRadius
        areaOfCoverage
        accountOwnerId
        roleDescription
        accessType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          externalIds
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        patients {
          nextToken
          startedAt
        }
        accountOwner {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        scopesOrdered
        billingInfo {
          id
          owner
          payeeName
          phoneNumber
          payeeZip
          accountNumber
          routingNumber
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        messageTemplates {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`
export const doctorByEmail = /* GraphQL */ `
  query DoctorByEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelDoctorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    doctorByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        rcToken
        username
        auth_status
        email
        a_readers
        a_writers
        name
        degrees
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          email
          countryCode
          practicePhone
          practiceCountryCode
          calendarId
          availability
          homepageUrl
        }
        owner
        photo {
          bucket
          region
          key
        }
        allowedZipcodes
        startPracticeYear
        hasLocatorConsent
        bio
        contactEmail
        contactName
        phoneNumber
        countryCode
        metricType
        searchRadius
        areaOfCoverage
        accountOwnerId
        roleDescription
        accessType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          externalIds
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        patients {
          nextToken
          startedAt
        }
        accountOwner {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        scopesOrdered
        billingInfo {
          id
          owner
          payeeName
          phoneNumber
          payeeZip
          accountNumber
          routingNumber
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        messageTemplates {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`
export const doctorByRcToken = /* GraphQL */ `
  query DoctorByRcToken(
    $rcToken: String
    $sortDirection: ModelSortDirection
    $filter: ModelDoctorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    doctorByRcToken(
      rcToken: $rcToken
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        rcToken
        username
        auth_status
        email
        a_readers
        a_writers
        name
        degrees
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          email
          countryCode
          practicePhone
          practiceCountryCode
          calendarId
          availability
          homepageUrl
        }
        owner
        photo {
          bucket
          region
          key
        }
        allowedZipcodes
        startPracticeYear
        hasLocatorConsent
        bio
        contactEmail
        contactName
        phoneNumber
        countryCode
        metricType
        searchRadius
        areaOfCoverage
        accountOwnerId
        roleDescription
        accessType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          externalIds
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        patients {
          nextToken
          startedAt
        }
        accountOwner {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        scopesOrdered
        billingInfo {
          id
          owner
          payeeName
          phoneNumber
          payeeZip
          accountNumber
          routingNumber
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        messageTemplates {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`
export const doctorByAccessType = /* GraphQL */ `
  query DoctorByAccessType(
    $accessType: String
    $sortDirection: ModelSortDirection
    $filter: ModelDoctorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    doctorByAccessType(
      accessType: $accessType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        rcToken
        username
        auth_status
        email
        a_readers
        a_writers
        name
        degrees
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          email
          countryCode
          practicePhone
          practiceCountryCode
          calendarId
          availability
          homepageUrl
        }
        owner
        photo {
          bucket
          region
          key
        }
        allowedZipcodes
        startPracticeYear
        hasLocatorConsent
        bio
        contactEmail
        contactName
        phoneNumber
        countryCode
        metricType
        searchRadius
        areaOfCoverage
        accountOwnerId
        roleDescription
        accessType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          externalIds
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        patients {
          nextToken
          startedAt
        }
        accountOwner {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        scopesOrdered
        billingInfo {
          id
          owner
          payeeName
          phoneNumber
          payeeZip
          accountNumber
          routingNumber
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        messageTemplates {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`
export const doctorsByAccountOwnerId = /* GraphQL */ `
  query DoctorsByAccountOwnerId(
    $accountOwnerId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelDoctorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    doctorsByAccountOwnerId(
      accountOwnerId: $accountOwnerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        rcToken
        username
        auth_status
        email
        a_readers
        a_writers
        name
        degrees
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          email
          countryCode
          practicePhone
          practiceCountryCode
          calendarId
          availability
          homepageUrl
        }
        owner
        photo {
          bucket
          region
          key
        }
        allowedZipcodes
        startPracticeYear
        hasLocatorConsent
        bio
        contactEmail
        contactName
        phoneNumber
        countryCode
        metricType
        searchRadius
        areaOfCoverage
        accountOwnerId
        roleDescription
        accessType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          externalIds
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        patients {
          nextToken
          startedAt
        }
        accountOwner {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        scopesOrdered
        billingInfo {
          id
          owner
          payeeName
          phoneNumber
          payeeZip
          accountNumber
          routingNumber
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        messageTemplates {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`
export const syncDoctors = /* GraphQL */ `
  query SyncDoctors(
    $filter: ModelDoctorFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDoctors(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        rcToken
        username
        auth_status
        email
        a_readers
        a_writers
        name
        degrees
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          email
          countryCode
          practicePhone
          practiceCountryCode
          calendarId
          availability
          homepageUrl
        }
        owner
        photo {
          bucket
          region
          key
        }
        allowedZipcodes
        startPracticeYear
        hasLocatorConsent
        bio
        contactEmail
        contactName
        phoneNumber
        countryCode
        metricType
        searchRadius
        areaOfCoverage
        accountOwnerId
        roleDescription
        accessType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          externalIds
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        patients {
          nextToken
          startedAt
        }
        accountOwner {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        scopesOrdered
        billingInfo {
          id
          owner
          payeeName
          phoneNumber
          payeeZip
          accountNumber
          routingNumber
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        messageTemplates {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`
export const getDoctor = /* GraphQL */ `
  query GetDoctor($id: ID!) {
    getDoctor(id: $id) {
      id
      rcToken
      username
      auth_status
      email
      a_readers
      a_writers
      name
      degrees
      clinic {
        practiceName
        address1
        address2
        city
        zip
        state
        country
        phone
        additionalPhone
        email
        countryCode
        practicePhone
        practiceCountryCode
        calendarId
        availability
        homepageUrl
        logo {
          bucket
          region
          key
        }
      }
      owner
      photo {
        bucket
        region
        key
      }
      allowedZipcodes
      startPracticeYear
      hasLocatorConsent
      bio
      contactEmail
      contactName
      phoneNumber
      countryCode
      metricType
      searchRadius
      areaOfCoverage
      accountOwnerId
      roleDescription
      accessType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      user {
        id
        username
        identityId
        timezone
        version
        model
        deviceName
        language
        type
        a_doctor
        a_readers
        a_writers
        isActive
        owner
        program
        authConfirmationDate
        appSettings
        groups
        termsVersion
        updatedAt
        createdAt
        marketingData
        allowed_groups_permissions
        _version
        _deleted
        _lastChangedAt
        grinPlanKey
        stripeCustomerId
        planOverrides
        messagingPreferences {
          id
          reminderTime
          whiteningTime
          whiteningScanTime
          whiteningScanDays
          a_readers
          a_writers
          owner
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
        featureFlags {
          id
          featureFlagsUserId
          username
          a_readers
          a_writers
          a_doctor
          flags
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patient {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        messages {
          nextToken
          startedAt
        }
        rooms {
          nextToken
          startedAt
        }
      }
      patients {
        items {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      accountOwner {
        id
        rcToken
        username
        auth_status
        email
        a_readers
        a_writers
        name
        degrees
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          email
          countryCode
          practicePhone
          practiceCountryCode
          calendarId
          availability
          homepageUrl
        }
        owner
        photo {
          bucket
          region
          key
        }
        allowedZipcodes
        startPracticeYear
        hasLocatorConsent
        bio
        contactEmail
        contactName
        phoneNumber
        countryCode
        metricType
        searchRadius
        areaOfCoverage
        accountOwnerId
        roleDescription
        accessType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          externalIds
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        patients {
          nextToken
          startedAt
        }
        accountOwner {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        scopesOrdered
        billingInfo {
          id
          owner
          payeeName
          phoneNumber
          payeeZip
          accountNumber
          routingNumber
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        messageTemplates {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
      scopesOrdered
      billingInfo {
        id
        owner
        payeeName
        phoneNumber
        payeeZip
        accountNumber
        routingNumber
        a_readers
        a_writers
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
      }
      messageTemplates {
        items {
          id
          type
          a_doctor
          doctorId
          title
          text
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      tags {
        items {
          id
          value
          type
          count
          owner
          settings
          tagDoctorId
          a_readers
          a_writers
          createdAt
          updatedAt
          tagClass
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`
export const listDoctors = /* GraphQL */ `
  query ListDoctors($filter: ModelDoctorFilterInput, $limit: Int, $nextToken: String) {
    listDoctors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        rcToken
        username
        auth_status
        email
        a_readers
        a_writers
        name
        degrees
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          email
          countryCode
          practicePhone
          practiceCountryCode
          calendarId
          availability
          homepageUrl
        }
        owner
        photo {
          bucket
          region
          key
        }
        allowedZipcodes
        startPracticeYear
        hasLocatorConsent
        bio
        contactEmail
        contactName
        phoneNumber
        countryCode
        metricType
        searchRadius
        areaOfCoverage
        accountOwnerId
        roleDescription
        accessType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          externalIds
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        patients {
          nextToken
          startedAt
        }
        accountOwner {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        scopesOrdered
        billingInfo {
          id
          owner
          payeeName
          phoneNumber
          payeeZip
          accountNumber
          routingNumber
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        messageTemplates {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`
export const getInitialScan = /* GraphQL */ `
  query GetInitialScan($id: ID!) {
    getInitialScan(id: $id) {
      id
      patientId
      date
      comment
      oralImages {
        bucket
        region
        key
      }
      panoramics {
        bucket
        region
        key
      }
      stls {
        bucket
        region
        key
      }
      a_doctor
      a_patient
      a_readers
      a_writers
      scannerType
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`
export const listInitialScans = /* GraphQL */ `
  query ListInitialScans($filter: ModelInitialScanFilterInput, $limit: Int, $nextToken: String) {
    listInitialScans(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        patientId
        date
        comment
        oralImages {
          bucket
          region
          key
        }
        panoramics {
          bucket
          region
          key
        }
        stls {
          bucket
          region
          key
        }
        a_doctor
        a_patient
        a_readers
        a_writers
        scannerType
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`
export const initialScansByPatientId = /* GraphQL */ `
  query InitialScansByPatientId(
    $patientId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelInitialScanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    initialScansByPatientId(
      patientId: $patientId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        patientId
        date
        comment
        oralImages {
          bucket
          region
          key
        }
        panoramics {
          bucket
          region
          key
        }
        stls {
          bucket
          region
          key
        }
        a_doctor
        a_patient
        a_readers
        a_writers
        scannerType
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`
export const syncInitialScans = /* GraphQL */ `
  query SyncInitialScans(
    $filter: ModelInitialScanFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncInitialScans(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        patientId
        date
        comment
        oralImages {
          bucket
          region
          key
        }
        panoramics {
          bucket
          region
          key
        }
        stls {
          bucket
          region
          key
        }
        a_doctor
        a_patient
        a_readers
        a_writers
        scannerType
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`
export const getTreatment = /* GraphQL */ `
  query GetTreatment($id: ID!) {
    getTreatment(id: $id) {
      id
      patientId
      type
      date
      statusId
      endDate
      simulation {
        images {
          bucket
          region
          key
        }
        videos {
          bucket
          region
          key
        }
        status
        backlog
      }
      offer {
        price
        currency
        status
        video {
          bucket
          region
          key
        }
        message
        tier
      }
      stageDefaultDuration
      cycleInterval
      introMeetingDate
      defaultApplianceName
      currentApplianceIndex
      timelineEventLog {
        eventType
        timestamp
        params
      }
      postConfirmationStatusKey
      cycleIntervalPeriodType
      whiteningProductType
      maxWhiteningTreatments
      status
      a_doctor
      a_patient
      a_readers
      a_writers
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      proposals {
        items {
          id
          treatmentId
          patientId
          a_doctor
          a_patient
          tier
          cost
          length
          periodType
          vendor
          additionalVendor
          status
          notes
          a_readers
          a_writers
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      treatmentsPlans {
        items {
          id
          treatmentId
          patientId
          a_doctor
          a_patient
          a_readers
          a_writers
          tier
          cost
          length
          periodType
          vendor
          additionalVendor
          status
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      stages {
        items {
          id
          treatmentId
          allignerId
          index
          startDate
          endDate
          duration
          alert
          status
          milestoneId
          a_doctor
          a_patient
          a_readers
          a_writers
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
      currentStatus {
        id
        statusId
        statusKey
        treatmentId
        setByUsername
        a_patient
        a_doctor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        treatment {
          id
          patientId
          type
          date
          statusId
          endDate
          stageDefaultDuration
          cycleInterval
          introMeetingDate
          defaultApplianceName
          currentApplianceIndex
          postConfirmationStatusKey
          cycleIntervalPeriodType
          whiteningProductType
          maxWhiteningTreatments
          status
          a_doctor
          a_patient
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        status {
          id
          key
          program
          type
          period
          maxScansPerPeriod
          hasLimitedScans
          shouldReceiveReminders
          isManualAssignmentAllowed
          isChatAllowed
          requireDoubleScan
          scanCyclesEnabled
          scanFrequencyDefaults
          settings
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      treatmentStatuses {
        items {
          id
          statusId
          statusKey
          treatmentId
          setByUsername
          a_patient
          a_doctor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`
export const listTreatments = /* GraphQL */ `
  query ListTreatments($filter: ModelTreatmentFilterInput, $limit: Int, $nextToken: String) {
    listTreatments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        patientId
        type
        date
        statusId
        endDate
        simulation {
          status
          backlog
        }
        offer {
          price
          currency
          status
          message
          tier
        }
        stageDefaultDuration
        cycleInterval
        introMeetingDate
        defaultApplianceName
        currentApplianceIndex
        timelineEventLog {
          eventType
          timestamp
          params
        }
        postConfirmationStatusKey
        cycleIntervalPeriodType
        whiteningProductType
        maxWhiteningTreatments
        status
        a_doctor
        a_patient
        a_readers
        a_writers
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        proposals {
          nextToken
          startedAt
        }
        treatmentsPlans {
          nextToken
          startedAt
        }
        stages {
          nextToken
          startedAt
        }
        currentStatus {
          id
          statusId
          statusKey
          treatmentId
          setByUsername
          a_patient
          a_doctor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        treatmentStatuses {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`
export const treatmentsByPatientId = /* GraphQL */ `
  query TreatmentsByPatientId(
    $patientId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTreatmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    treatmentsByPatientId(
      patientId: $patientId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        patientId
        type
        date
        statusId
        endDate
        simulation {
          status
          backlog
        }
        offer {
          price
          currency
          status
          message
          tier
        }
        stageDefaultDuration
        cycleInterval
        introMeetingDate
        defaultApplianceName
        currentApplianceIndex
        timelineEventLog {
          eventType
          timestamp
          params
        }
        postConfirmationStatusKey
        cycleIntervalPeriodType
        whiteningProductType
        maxWhiteningTreatments
        status
        a_doctor
        a_patient
        a_readers
        a_writers
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        proposals {
          nextToken
          startedAt
        }
        treatmentsPlans {
          nextToken
          startedAt
        }
        stages {
          nextToken
          startedAt
        }
        currentStatus {
          id
          statusId
          statusKey
          treatmentId
          setByUsername
          a_patient
          a_doctor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        treatmentStatuses {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`
export const syncTreatments = /* GraphQL */ `
  query SyncTreatments(
    $filter: ModelTreatmentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTreatments(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        patientId
        type
        date
        statusId
        endDate
        simulation {
          status
          backlog
        }
        offer {
          price
          currency
          status
          message
          tier
        }
        stageDefaultDuration
        cycleInterval
        introMeetingDate
        defaultApplianceName
        currentApplianceIndex
        timelineEventLog {
          eventType
          timestamp
          params
        }
        postConfirmationStatusKey
        cycleIntervalPeriodType
        whiteningProductType
        maxWhiteningTreatments
        status
        a_doctor
        a_patient
        a_readers
        a_writers
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        proposals {
          nextToken
          startedAt
        }
        treatmentsPlans {
          nextToken
          startedAt
        }
        stages {
          nextToken
          startedAt
        }
        currentStatus {
          id
          statusId
          statusKey
          treatmentId
          setByUsername
          a_patient
          a_doctor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        treatmentStatuses {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`
export const getProposal = /* GraphQL */ `
  query GetProposal($id: ID!) {
    getProposal(id: $id) {
      id
      treatmentId
      patientId
      a_doctor
      a_patient
      tier
      cost
      length
      periodType
      vendor
      additionalVendor
      status
      notes
      a_readers
      a_writers
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`
export const listProposals = /* GraphQL */ `
  query ListProposals($filter: ModelProposalFilterInput, $limit: Int, $nextToken: String) {
    listProposals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        treatmentId
        patientId
        a_doctor
        a_patient
        tier
        cost
        length
        periodType
        vendor
        additionalVendor
        status
        notes
        a_readers
        a_writers
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`
export const proposalsByTreatmentId = /* GraphQL */ `
  query ProposalsByTreatmentId(
    $treatmentId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProposalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    proposalsByTreatmentId(
      treatmentId: $treatmentId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        treatmentId
        patientId
        a_doctor
        a_patient
        tier
        cost
        length
        periodType
        vendor
        additionalVendor
        status
        notes
        a_readers
        a_writers
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`
export const proposalsByPatientId = /* GraphQL */ `
  query ProposalsByPatientId(
    $patientId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProposalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    proposalsByPatientId(
      patientId: $patientId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        treatmentId
        patientId
        a_doctor
        a_patient
        tier
        cost
        length
        periodType
        vendor
        additionalVendor
        status
        notes
        a_readers
        a_writers
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`
export const syncProposals = /* GraphQL */ `
  query SyncProposals(
    $filter: ModelProposalFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProposals(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        treatmentId
        patientId
        a_doctor
        a_patient
        tier
        cost
        length
        periodType
        vendor
        additionalVendor
        status
        notes
        a_readers
        a_writers
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`
export const getAppointment = /* GraphQL */ `
  query GetAppointment($id: ID!) {
    getAppointment(id: $id) {
      id
      patientId
      a_doctor
      a_patient
      address
      date
      a_readers
      a_writers
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`
export const listAppointments = /* GraphQL */ `
  query ListAppointments($filter: ModelAppointmentFilterInput, $limit: Int, $nextToken: String) {
    listAppointments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        patientId
        a_doctor
        a_patient
        address
        date
        a_readers
        a_writers
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`
export const appointmentsByPatientId = /* GraphQL */ `
  query AppointmentsByPatientId(
    $patientId: ID
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAppointmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    appointmentsByPatientId(
      patientId: $patientId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        patientId
        a_doctor
        a_patient
        address
        date
        a_readers
        a_writers
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`
export const syncAppointments = /* GraphQL */ `
  query SyncAppointments(
    $filter: ModelAppointmentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAppointments(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        patientId
        a_doctor
        a_patient
        address
        date
        a_readers
        a_writers
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`
export const getTreatmentPlan = /* GraphQL */ `
  query GetTreatmentPlan($id: ID!) {
    getTreatmentPlan(id: $id) {
      id
      treatmentId
      patientId
      a_doctor
      a_patient
      a_readers
      a_writers
      tier
      cost
      length
      periodType
      vendor
      additionalVendor
      status
      notes
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`
export const listTreatmentPlans = /* GraphQL */ `
  query ListTreatmentPlans(
    $filter: ModelTreatmentPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTreatmentPlans(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        treatmentId
        patientId
        a_doctor
        a_patient
        a_readers
        a_writers
        tier
        cost
        length
        periodType
        vendor
        additionalVendor
        status
        notes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`
export const treatmentPlansByTreatmentId = /* GraphQL */ `
  query TreatmentPlansByTreatmentId(
    $treatmentId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTreatmentPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    treatmentPlansByTreatmentId(
      treatmentId: $treatmentId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        treatmentId
        patientId
        a_doctor
        a_patient
        a_readers
        a_writers
        tier
        cost
        length
        periodType
        vendor
        additionalVendor
        status
        notes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`
export const treatmentPlansPatientId = /* GraphQL */ `
  query TreatmentPlansPatientId(
    $patientId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTreatmentPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    treatmentPlansPatientId(
      patientId: $patientId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        treatmentId
        patientId
        a_doctor
        a_patient
        a_readers
        a_writers
        tier
        cost
        length
        periodType
        vendor
        additionalVendor
        status
        notes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`
export const syncTreatmentPlans = /* GraphQL */ `
  query SyncTreatmentPlans(
    $filter: ModelTreatmentPlanFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTreatmentPlans(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        treatmentId
        patientId
        a_doctor
        a_patient
        a_readers
        a_writers
        tier
        cost
        length
        periodType
        vendor
        additionalVendor
        status
        notes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`
export const getStage = /* GraphQL */ `
  query GetStage($id: ID!) {
    getStage(id: $id) {
      id
      treatmentId
      allignerId
      index
      startDate
      endDate
      duration
      alert
      status
      milestoneId
      scans {
        images {
          bucket
          region
          key
        }
        video {
          bucket
          region
          key
        }
        date
      }
      a_doctor
      a_patient
      a_readers
      a_writers
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`
export const listStages = /* GraphQL */ `
  query ListStages($filter: ModelStageFilterInput, $limit: Int, $nextToken: String) {
    listStages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        treatmentId
        allignerId
        index
        startDate
        endDate
        duration
        alert
        status
        milestoneId
        scans {
          date
        }
        a_doctor
        a_patient
        a_readers
        a_writers
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`
export const stagesByTreatmentId = /* GraphQL */ `
  query StagesByTreatmentId(
    $treatmentId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelStageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    stagesByTreatmentId(
      treatmentId: $treatmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        treatmentId
        allignerId
        index
        startDate
        endDate
        duration
        alert
        status
        milestoneId
        scans {
          date
        }
        a_doctor
        a_patient
        a_readers
        a_writers
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`
export const syncStages = /* GraphQL */ `
  query SyncStages(
    $filter: ModelStageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncStages(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        treatmentId
        allignerId
        index
        startDate
        endDate
        duration
        alert
        status
        milestoneId
        scans {
          date
        }
        a_doctor
        a_patient
        a_readers
        a_writers
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`
export const getGrinScan = /* GraphQL */ `
  query GetGrinScan($id: ID!) {
    getGrinScan(id: $id) {
      id
      patientId
      a_doctor
      a_patient
      a_readers
      a_writers
      images {
        bucket
        region
        key
      }
      video {
        bucket
        region
        key
      }
      date
      status
      isOffCycle
      aligner
      appliance
      applianceIndex
      treatmentType
      isTransferred
      createdAt
      updatedAt
      simulationStatus
      scanPairId
      withAligner
      scanSummaryStatus
      scanSummaryData
      treatmentTrackerData
      treatmentTrackerStatus
      scanGuideAIMetadata
      metadata
      _version
      _deleted
      _lastChangedAt
      patient {
        id
        publicId
        patientDoctorId
        username
        auth_status
        task_status
        details {
          name
          firstName
          lastName
          email
          phone
          countryCode
          birthdate
          address
          city
          zipcode
          state
          country
          ageRange
          parentFirstName
          parentLastName
          parentEmail
          address2
          treatmentType
        }
        owner
        a_doctor
        a_readers
        a_writers
        isActive
        photo {
          bucket
          region
          key
        }
        plan
        rcToRmDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          externalIds
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        initialScans {
          nextToken
          startedAt
        }
        treatments {
          nextToken
          startedAt
        }
        grinScans {
          nextToken
          startedAt
        }
        pearlScans {
          nextToken
          startedAt
        }
        cycles {
          nextToken
          startedAt
        }
        actionItems {
          nextToken
          startedAt
        }
        patientTags {
          nextToken
          startedAt
        }
      }
      scanReviews {
        items {
          id
          grinScanId
          a_doctor
          a_patient
          a_readers
          a_writers
          reviewerDoctorId
          isTransferred
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`
export const listGrinScans = /* GraphQL */ `
  query ListGrinScans($filter: ModelGrinScanFilterInput, $limit: Int, $nextToken: String) {
    listGrinScans(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        patientId
        a_doctor
        a_patient
        a_readers
        a_writers
        images {
          bucket
          region
          key
        }
        video {
          bucket
          region
          key
        }
        date
        status
        isOffCycle
        aligner
        appliance
        applianceIndex
        treatmentType
        isTransferred
        createdAt
        updatedAt
        simulationStatus
        scanPairId
        withAligner
        scanSummaryStatus
        scanSummaryData
        scanGuideAIMetadata
        metadata
        _version
        _deleted
        _lastChangedAt
        patient {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        scanReviews {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`
export const grinScansByPatientId = /* GraphQL */ `
  query GrinScansByPatientId(
    $patientId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelGrinScanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    grinScansByPatientId(
      patientId: $patientId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        patientId
        a_doctor
        a_patient
        a_readers
        a_writers
        images {
          bucket
          region
          key
        }
        video {
          bucket
          region
          key
        }
        date
        status
        isOffCycle
        aligner
        appliance
        applianceIndex
        treatmentType
        isTransferred
        createdAt
        updatedAt
        simulationStatus
        scanPairId
        withAligner
        scanSummaryStatus
        scanSummaryData
        scanGuideAIMetadata
        metadata
        _version
        _deleted
        _lastChangedAt
        patient {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        scanReviews {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`
export const grinScansByPatientIdSorted = /* GraphQL */ `
  query GrinScansByPatientIdSorted(
    $patientId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGrinScanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    grinScansByPatientIdSorted(
      patientId: $patientId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        patientId
        a_doctor
        a_patient
        a_readers
        a_writers
        images {
          bucket
          region
          key
        }
        video {
          bucket
          region
          key
        }
        date
        status
        isOffCycle
        aligner
        appliance
        applianceIndex
        treatmentType
        isTransferred
        createdAt
        updatedAt
        simulationStatus
        scanPairId
        withAligner
        scanSummaryStatus
        scanSummaryData
        scanGuideAIMetadata
        metadata
        _version
        _deleted
        _lastChangedAt
        patient {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        scanReviews {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`
export const grinScansByStatusSorted = /* GraphQL */ `
  query GrinScansByStatusSorted(
    $status: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGrinScanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    grinScansByStatusSorted(
      status: $status
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        patientId
        a_doctor
        a_patient
        a_readers
        a_writers
        images {
          bucket
          region
          key
        }
        video {
          bucket
          region
          key
        }
        date
        status
        isOffCycle
        aligner
        appliance
        applianceIndex
        treatmentType
        isTransferred
        createdAt
        updatedAt
        simulationStatus
        scanPairId
        withAligner
        scanSummaryStatus
        scanSummaryData
        scanGuideAIMetadata
        metadata
        _version
        _deleted
        _lastChangedAt
        patient {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        scanReviews {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`
export const syncGrinScans = /* GraphQL */ `
  query SyncGrinScans(
    $filter: ModelGrinScanFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncGrinScans(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        patientId
        a_doctor
        a_patient
        a_readers
        a_writers
        images {
          bucket
          region
          key
        }
        video {
          bucket
          region
          key
        }
        date
        status
        isOffCycle
        aligner
        appliance
        applianceIndex
        treatmentType
        isTransferred
        createdAt
        updatedAt
        simulationStatus
        scanPairId
        withAligner
        scanSummaryStatus
        scanSummaryData
        scanGuideAIMetadata
        metadata
        _version
        _deleted
        _lastChangedAt
        patient {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        scanReviews {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`
export const getPearlScan = /* GraphQL */ `
  query GetPearlScan($id: ID!) {
    getPearlScan(id: $id) {
      id
      patientId
      a_doctor
      a_patient
      a_readers
      a_writers
      status
      scanUrls
      uploadedAt
      createdAt
      updatedAt
      AIData
      index
      _version
      _deleted
      _lastChangedAt
      patient {
        id
        publicId
        patientDoctorId
        username
        auth_status
        task_status
        details {
          name
          firstName
          lastName
          email
          phone
          countryCode
          birthdate
          address
          city
          zipcode
          state
          country
          ageRange
          parentFirstName
          parentLastName
          parentEmail
          address2
          treatmentType
        }
        owner
        a_doctor
        a_readers
        a_writers
        isActive
        photo {
          bucket
          region
          key
        }
        plan
        rcToRmDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          externalIds
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        initialScans {
          nextToken
          startedAt
        }
        treatments {
          nextToken
          startedAt
        }
        grinScans {
          nextToken
          startedAt
        }
        pearlScans {
          nextToken
          startedAt
        }
        cycles {
          nextToken
          startedAt
        }
        actionItems {
          nextToken
          startedAt
        }
        patientTags {
          nextToken
          startedAt
        }
      }
    }
  }
`
export const listPearlScans = /* GraphQL */ `
  query ListPearlScans($filter: ModelPearlScanFilterInput, $limit: Int, $nextToken: String) {
    listPearlScans(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        patientId
        a_doctor
        a_patient
        a_readers
        a_writers
        status
        scanUrls
        uploadedAt
        createdAt
        updatedAt
        AIData
        index
        _version
        _deleted
        _lastChangedAt
        patient {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`
export const pearlScansByPatientIdSorted = /* GraphQL */ `
  query PearlScansByPatientIdSorted(
    $patientId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPearlScanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pearlScansByPatientIdSorted(
      patientId: $patientId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        patientId
        a_doctor
        a_patient
        a_readers
        a_writers
        status
        scanUrls
        uploadedAt
        createdAt
        updatedAt
        AIData
        index
        _version
        _deleted
        _lastChangedAt
        patient {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`
export const syncPearlScans = /* GraphQL */ `
  query SyncPearlScans(
    $filter: ModelPearlScanFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPearlScans(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        patientId
        a_doctor
        a_patient
        a_readers
        a_writers
        status
        scanUrls
        uploadedAt
        createdAt
        updatedAt
        AIData
        index
        _version
        _deleted
        _lastChangedAt
        patient {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`
export const getCycle = /* GraphQL */ `
  query GetCycle($id: ID!) {
    getCycle(id: $id) {
      id
      patientId
      a_doctor
      a_patient
      a_readers
      a_writers
      createdAt
      updatedAt
      startDate
      originalEndDate
      actualEndDate
      index
      _version
      _deleted
      _lastChangedAt
      patient {
        id
        publicId
        patientDoctorId
        username
        auth_status
        task_status
        details {
          name
          firstName
          lastName
          email
          phone
          countryCode
          birthdate
          address
          city
          zipcode
          state
          country
          ageRange
          parentFirstName
          parentLastName
          parentEmail
          address2
          treatmentType
        }
        owner
        a_doctor
        a_readers
        a_writers
        isActive
        photo {
          bucket
          region
          key
        }
        plan
        rcToRmDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          externalIds
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        initialScans {
          nextToken
          startedAt
        }
        treatments {
          nextToken
          startedAt
        }
        grinScans {
          nextToken
          startedAt
        }
        pearlScans {
          nextToken
          startedAt
        }
        cycles {
          nextToken
          startedAt
        }
        actionItems {
          nextToken
          startedAt
        }
        patientTags {
          nextToken
          startedAt
        }
      }
    }
  }
`
export const listCycles = /* GraphQL */ `
  query ListCycles($filter: ModelCycleFilterInput, $limit: Int, $nextToken: String) {
    listCycles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        patientId
        a_doctor
        a_patient
        a_readers
        a_writers
        createdAt
        updatedAt
        startDate
        originalEndDate
        actualEndDate
        index
        _version
        _deleted
        _lastChangedAt
        patient {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`
export const cyclesSorted = /* GraphQL */ `
  query CyclesSorted(
    $patientId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCycleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cyclesSorted(
      patientId: $patientId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        patientId
        a_doctor
        a_patient
        a_readers
        a_writers
        createdAt
        updatedAt
        startDate
        originalEndDate
        actualEndDate
        index
        _version
        _deleted
        _lastChangedAt
        patient {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`
export const syncCycles = /* GraphQL */ `
  query SyncCycles(
    $filter: ModelCycleFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCycles(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        patientId
        a_doctor
        a_patient
        a_readers
        a_writers
        createdAt
        updatedAt
        startDate
        originalEndDate
        actualEndDate
        index
        _version
        _deleted
        _lastChangedAt
        patient {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`
export const getScanReview = /* GraphQL */ `
  query GetScanReview($id: ID!) {
    getScanReview(id: $id) {
      id
      grinScanId
      a_doctor
      a_patient
      a_readers
      a_writers
      video {
        bucket
        region
        key
      }
      reviewerDoctorId
      isTransferred
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      reviewer {
        id
        rcToken
        username
        auth_status
        email
        a_readers
        a_writers
        name
        degrees
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          email
          countryCode
          practicePhone
          practiceCountryCode
          calendarId
          availability
          homepageUrl
        }
        owner
        photo {
          bucket
          region
          key
        }
        allowedZipcodes
        startPracticeYear
        hasLocatorConsent
        bio
        contactEmail
        contactName
        phoneNumber
        countryCode
        metricType
        searchRadius
        areaOfCoverage
        accountOwnerId
        roleDescription
        accessType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          externalIds
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        patients {
          nextToken
          startedAt
        }
        accountOwner {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        scopesOrdered
        billingInfo {
          id
          owner
          payeeName
          phoneNumber
          payeeZip
          accountNumber
          routingNumber
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        messageTemplates {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
    }
  }
`
export const listScanReviews = /* GraphQL */ `
  query ListScanReviews($filter: ModelScanReviewFilterInput, $limit: Int, $nextToken: String) {
    listScanReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        grinScanId
        a_doctor
        a_patient
        a_readers
        a_writers
        video {
          bucket
          region
          key
        }
        reviewerDoctorId
        isTransferred
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        reviewer {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
      }
      nextToken
      startedAt
    }
  }
`
export const scanReviewsByGrinScanId = /* GraphQL */ `
  query ScanReviewsByGrinScanId(
    $grinScanId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelScanReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    scanReviewsByGrinScanId(
      grinScanId: $grinScanId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        grinScanId
        a_doctor
        a_patient
        a_readers
        a_writers
        video {
          bucket
          region
          key
        }
        reviewerDoctorId
        isTransferred
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        reviewer {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
      }
      nextToken
      startedAt
    }
  }
`
export const syncScanReviews = /* GraphQL */ `
  query SyncScanReviews(
    $filter: ModelScanReviewFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncScanReviews(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        grinScanId
        a_doctor
        a_patient
        a_readers
        a_writers
        video {
          bucket
          region
          key
        }
        reviewerDoctorId
        isTransferred
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        reviewer {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
      }
      nextToken
      startedAt
    }
  }
`
export const getBillingInfo = /* GraphQL */ `
  query GetBillingInfo($id: ID!) {
    getBillingInfo(id: $id) {
      id
      owner
      payeeName
      phoneNumber
      payeeZip
      accountNumber
      routingNumber
      a_readers
      a_writers
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      doctor {
        id
        rcToken
        username
        auth_status
        email
        a_readers
        a_writers
        name
        degrees
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          email
          countryCode
          practicePhone
          practiceCountryCode
          calendarId
          availability
          homepageUrl
        }
        owner
        photo {
          bucket
          region
          key
        }
        allowedZipcodes
        startPracticeYear
        hasLocatorConsent
        bio
        contactEmail
        contactName
        phoneNumber
        countryCode
        metricType
        searchRadius
        areaOfCoverage
        accountOwnerId
        roleDescription
        accessType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          externalIds
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        patients {
          nextToken
          startedAt
        }
        accountOwner {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        scopesOrdered
        billingInfo {
          id
          owner
          payeeName
          phoneNumber
          payeeZip
          accountNumber
          routingNumber
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        messageTemplates {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
    }
  }
`
export const listBillingInfos = /* GraphQL */ `
  query ListBillingInfos($filter: ModelBillingInfoFilterInput, $limit: Int, $nextToken: String) {
    listBillingInfos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        payeeName
        phoneNumber
        payeeZip
        accountNumber
        routingNumber
        a_readers
        a_writers
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
      }
      nextToken
      startedAt
    }
  }
`
export const syncBillingInfos = /* GraphQL */ `
  query SyncBillingInfos(
    $filter: ModelBillingInfoFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBillingInfos(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        owner
        payeeName
        phoneNumber
        payeeZip
        accountNumber
        routingNumber
        a_readers
        a_writers
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
      }
      nextToken
      startedAt
    }
  }
`
export const getRoom = /* GraphQL */ `
  query GetRoom($id: ID!) {
    getRoom(id: $id) {
      id
      members
      lastMessagePreview
      lastMessageTimestamp
      sortField
      a_readers
      a_writers
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      messages {
        items {
          id
          grinUserId
          roomId
          uploadingDate
          owner
          members
          a_readers
          a_writers
          type
          content
          readBy
          href
          hrefLabel
          promotionId
          promotionData
          templateId
          metadata
          isTransferred
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      users {
        items {
          id
          members
          a_readers
          a_writers
          grinUserId
          roomId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`
export const listRooms = /* GraphQL */ `
  query ListRooms($filter: ModelRoomFilterInput, $limit: Int, $nextToken: String) {
    listRooms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        members
        lastMessagePreview
        lastMessageTimestamp
        sortField
        a_readers
        a_writers
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        messages {
          nextToken
          startedAt
        }
        users {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`
export const roomsSorted = /* GraphQL */ `
  query RoomsSorted(
    $sortField: String
    $lastMessageTimestamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    roomsSorted(
      sortField: $sortField
      lastMessageTimestamp: $lastMessageTimestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        members
        lastMessagePreview
        lastMessageTimestamp
        sortField
        a_readers
        a_writers
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        messages {
          nextToken
          startedAt
        }
        users {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`
export const syncRooms = /* GraphQL */ `
  query SyncRooms(
    $filter: ModelRoomFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRooms(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        members
        lastMessagePreview
        lastMessageTimestamp
        sortField
        a_readers
        a_writers
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        messages {
          nextToken
          startedAt
        }
        users {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      grinUserId
      roomId
      uploadingDate
      owner
      members
      a_readers
      a_writers
      type
      content
      readBy
      pictures {
        bucket
        region
        key
      }
      href
      hrefLabel
      promotionId
      promotionData
      templateId
      metadata
      isTransferred
      createdAt
      updatedAt
      entityRelatedId
      isDeleted
      grinScan {
        id
        trackingStatus
        trackingDetails
        metadata
        video {
            bucket
            region
            key
          }
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`
export const listMessages = /* GraphQL */ `
  query ListMessages($filter: ModelMessageFilterInput, $limit: Int, $nextToken: String) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        grinUserId
        roomId
        uploadingDate
        owner
        members
        a_readers
        a_writers
        type
        content
        readBy
        pictures {
          bucket
          region
          key
        }
        href
        hrefLabel
        promotionId
        promotionData
        templateId
        metadata
        isTransferred
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`
export const messagesByGrinUserId = /* GraphQL */ `
  query MessagesByGrinUserId(
    $grinUserId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByGrinUserId(
      grinUserId: $grinUserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        grinUserId
        roomId
        uploadingDate
        owner
        members
        a_readers
        a_writers
        type
        content
        readBy
        pictures {
          bucket
          region
          key
        }
        href
        hrefLabel
        promotionId
        promotionData
        templateId
        metadata
        isTransferred
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`
export const messagesByRoomIdSorted = /* GraphQL */ `
  query MessagesByRoomIdSorted(
    $roomId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByRoomIdSorted(
      roomId: $roomId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        grinUserId
        roomId
        uploadingDate
        owner
        members
        a_readers
        a_writers
        type
        content
        readBy
        pictures {
          bucket
          region
          key
        }
        href
        hrefLabel
        promotionId
        promotionData
        templateId
        metadata
        isTransferred
        isBroadcast
        entityRelatedId
        isDeleted
        grinScan {
          id
          trackingStatus
          trackingDetails
          metadata
          video {
            bucket
            region
            key
          }
        }
        lastEditedAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`
export const messagesByRoomIdAndTypeSorted = /* GraphQL */ `
  query MessagesByRoomIdAndTypeSorted(
    $roomId: ID
    $typeCreatedAt: ModelMessageMessagesByRoomIdAndTypeSortedCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByRoomIdAndTypeSorted(
      roomId: $roomId
      typeCreatedAt: $typeCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        grinUserId
        roomId
        uploadingDate
        owner
        members
        a_readers
        a_writers
        type
        content
        readBy
        pictures {
          bucket
          region
          key
        }
        href
        hrefLabel
        promotionId
        promotionData
        templateId
        metadata
        isTransferred
        isBroadcast
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`
export const messagesByRoomIdSortedByUploadingDate = /* GraphQL */ `
  query MessagesByRoomIdSortedByUploadingDate(
    $roomId: ID
    $uploadingDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByRoomIdSortedByUploadingDate(
      roomId: $roomId
      uploadingDate: $uploadingDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        grinUserId
        roomId
        uploadingDate
        owner
        members
        a_readers
        a_writers
        type
        content
        readBy
        pictures {
          bucket
          region
          key
        }
        href
        hrefLabel
        promotionId
        promotionData
        templateId
        metadata
        isTransferred
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`
export const syncMessages = /* GraphQL */ `
  query SyncMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMessages(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        grinUserId
        roomId
        uploadingDate
        owner
        members
        a_readers
        a_writers
        type
        content
        readBy
        pictures {
          bucket
          region
          key
        }
        href
        hrefLabel
        promotionId
        promotionData
        templateId
        metadata
        isTransferred
        isBroadcast
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`
export const getPromotion = /* GraphQL */ `
  query GetPromotion($id: ID!) {
    getPromotion(id: $id) {
      id
      title
      provider
      description
      keywords
      categoryKey
      imageUrl
      thumbnailUrl
      externalId
      baseUrl
      featureFlagIds
      metadata
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`
export const listPromotions = /* GraphQL */ `
  query ListPromotions($filter: ModelPromotionFilterInput, $limit: Int, $nextToken: String) {
    listPromotions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        provider
        description
        keywords
        categoryKey
        imageUrl
        thumbnailUrl
        externalId
        baseUrl
        featureFlagIds
        metadata
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`
export const syncPromotions = /* GraphQL */ `
  query SyncPromotions(
    $filter: ModelPromotionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPromotions(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        title
        provider
        description
        keywords
        categoryKey
        imageUrl
        thumbnailUrl
        externalId
        baseUrl
        featureFlagIds
        metadata
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`
export const getMessageTemplate = /* GraphQL */ `
  query GetMessageTemplate($id: ID!) {
    getMessageTemplate(id: $id) {
      id
      type
      a_doctor
      doctorId
      title
      text
      attachments {
        bucket
        region
        key
      }
      a_readers
      a_writers
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      doctor {
        id
        rcToken
        username
        auth_status
        email
        a_readers
        a_writers
        name
        degrees
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          email
          countryCode
          practicePhone
          practiceCountryCode
          calendarId
          availability
          homepageUrl
        }
        owner
        photo {
          bucket
          region
          key
        }
        allowedZipcodes
        startPracticeYear
        hasLocatorConsent
        bio
        contactEmail
        contactName
        phoneNumber
        countryCode
        metricType
        searchRadius
        areaOfCoverage
        accountOwnerId
        roleDescription
        accessType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          externalIds
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        patients {
          nextToken
          startedAt
        }
        accountOwner {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        scopesOrdered
        billingInfo {
          id
          owner
          payeeName
          phoneNumber
          payeeZip
          accountNumber
          routingNumber
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        messageTemplates {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
    }
  }
`
export const listMessageTemplates = /* GraphQL */ `
  query ListMessageTemplates(
    $filter: ModelMessageTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessageTemplates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        a_doctor
        doctorId
        title
        text
        attachments {
          bucket
          region
          key
        }
        a_readers
        a_writers
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
      }
      nextToken
      startedAt
    }
  }
`
export const templatesByDoctorId = /* GraphQL */ `
  query TemplatesByDoctorId(
    $doctorId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelMessageTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    templatesByDoctorId(
      doctorId: $doctorId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        a_doctor
        doctorId
        title
        text
        attachments {
          bucket
          region
          key
        }
        a_readers
        a_writers
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
      }
      nextToken
      startedAt
    }
  }
`
export const templatesByType = /* GraphQL */ `
  query TemplatesByType(
    $type: String
    $sortDirection: ModelSortDirection
    $filter: ModelMessageTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    templatesByType(
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        a_doctor
        doctorId
        title
        text
        attachments {
          bucket
          region
          key
        }
        a_readers
        a_writers
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
      }
      nextToken
      startedAt
    }
  }
`
export const templatesByTypeAndByDoctorId = /* GraphQL */ `
  query TemplatesByTypeAndByDoctorId(
    $type: String
    $doctorId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    templatesByTypeAndByDoctorId(
      type: $type
      doctorId: $doctorId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        a_doctor
        doctorId
        title
        text
        attachments {
          bucket
          region
          key
        }
        a_readers
        a_writers
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
      }
      nextToken
      startedAt
    }
  }
`
export const syncMessageTemplates = /* GraphQL */ `
  query SyncMessageTemplates(
    $filter: ModelMessageTemplateFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMessageTemplates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        type
        a_doctor
        doctorId
        title
        text
        attachments {
          bucket
          region
          key
        }
        a_readers
        a_writers
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
      }
      nextToken
      startedAt
    }
  }
`
export const getUserRoomLink = /* GraphQL */ `
  query GetUserRoomLink($id: ID!) {
    getUserRoomLink(id: $id) {
      id
      members
      a_readers
      a_writers
      grinUserId
      roomId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      user {
        id
        username
        identityId
        timezone
        version
        model
        deviceName
        language
        type
        a_doctor
        a_readers
        a_writers
        isActive
        owner
        program
        authConfirmationDate
        appSettings
        groups
        termsVersion
        updatedAt
        createdAt
        marketingData
        allowed_groups_permissions
        _version
        _deleted
        _lastChangedAt
        grinPlanKey
        stripeCustomerId
        planOverrides
        messagingPreferences {
          id
          reminderTime
          whiteningTime
          whiteningScanTime
          whiteningScanDays
          a_readers
          a_writers
          owner
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
        featureFlags {
          id
          featureFlagsUserId
          username
          a_readers
          a_writers
          a_doctor
          flags
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patient {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        messages {
          nextToken
          startedAt
        }
        rooms {
          nextToken
          startedAt
        }
      }
    }
  }
`
export const listUserRoomLinks = /* GraphQL */ `
  query ListUserRoomLinks($filter: ModelUserRoomLinkFilterInput, $limit: Int, $nextToken: String) {
    listUserRoomLinks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        members
        a_readers
        a_writers
        grinUserId
        roomId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          externalIds
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
      }
      nextToken
      startedAt
    }
  }
`
export const userRoomLinksByRoomId = /* GraphQL */ `
  query UserRoomLinksByRoomId(
    $roomId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelUserRoomLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userRoomLinksByRoomId(
      roomId: $roomId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        members
        a_readers
        a_writers
        grinUserId
        roomId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          externalIds
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
      }
      nextToken
      startedAt
    }
  }
`
export const userRoomLinksByGrinUserId = /* GraphQL */ `
  query UserRoomLinksByGrinUserId(
    $grinUserId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelUserRoomLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userRoomLinksByGrinUserId(
      grinUserId: $grinUserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        members
        a_readers
        a_writers
        grinUserId
        roomId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          externalIds
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
      }
      nextToken
      startedAt
    }
  }
`
export const syncUserRoomLinks = /* GraphQL */ `
  query SyncUserRoomLinks(
    $filter: ModelUserRoomLinkFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserRoomLinks(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        members
        a_readers
        a_writers
        grinUserId
        roomId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          externalIds
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
      }
      nextToken
      startedAt
    }
  }
`
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      type
      readStatus
      owner
      title
      subtitle
      body
      entityType
      entityId
      method
      reminderType
      grinUserId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      user {
        id
        username
        identityId
        timezone
        version
        model
        deviceName
        language
        type
        a_doctor
        a_readers
        a_writers
        isActive
        owner
        program
        authConfirmationDate
        appSettings
        groups
        termsVersion
        updatedAt
        createdAt
        marketingData
        allowed_groups_permissions
        _version
        _deleted
        _lastChangedAt
        grinPlanKey
        stripeCustomerId
        planOverrides
        messagingPreferences {
          id
          reminderTime
          whiteningTime
          whiteningScanTime
          whiteningScanDays
          a_readers
          a_writers
          owner
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
        featureFlags {
          id
          featureFlagsUserId
          username
          a_readers
          a_writers
          a_doctor
          flags
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patient {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        messages {
          nextToken
          startedAt
        }
        rooms {
          nextToken
          startedAt
        }
      }
    }
  }
`
export const listNotifications = /* GraphQL */ `
  query ListNotifications($filter: ModelNotificationFilterInput, $limit: Int, $nextToken: String) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        readStatus
        owner
        title
        subtitle
        body
        entityType
        entityId
        method
        reminderType
        grinUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          externalIds
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
      }
      nextToken
      startedAt
    }
  }
`
export const notificationsByGrinUserId = /* GraphQL */ `
  query NotificationsByGrinUserId(
    $grinUserId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationsByGrinUserId(
      grinUserId: $grinUserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        readStatus
        owner
        title
        subtitle
        body
        entityType
        entityId
        method
        reminderType
        grinUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          externalIds
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
      }
      nextToken
      startedAt
    }
  }
`
export const notificationsByReminderType = /* GraphQL */ `
  query NotificationsByReminderType(
    $reminderType: String
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationsByReminderType(
      reminderType: $reminderType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        readStatus
        owner
        title
        subtitle
        body
        entityType
        entityId
        method
        reminderType
        grinUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          externalIds
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
      }
      nextToken
      startedAt
    }
  }
`
export const syncNotifications = /* GraphQL */ `
  query SyncNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncNotifications(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        type
        readStatus
        owner
        title
        subtitle
        body
        entityType
        entityId
        method
        reminderType
        grinUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          externalIds
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
      }
      nextToken
      startedAt
    }
  }
`
export const getDoctorPlans = /* GraphQL */ `
  query GetDoctorPlans($id: ID!) {
    getDoctorPlans(id: $id) {
      id
      name
      email
      auth_status
      firstName
      lastName
      accessType
      accountOwnerId
      owner
      plans
      program
      addedBy
      a_doctor
      a_readers
      a_writers
      doctorGroups
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      accountOwner {
        id
        rcToken
        username
        auth_status
        email
        a_readers
        a_writers
        name
        degrees
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          email
          countryCode
          practicePhone
          practiceCountryCode
          calendarId
          availability
          homepageUrl
        }
        owner
        photo {
          bucket
          region
          key
        }
        allowedZipcodes
        startPracticeYear
        hasLocatorConsent
        bio
        contactEmail
        contactName
        phoneNumber
        countryCode
        metricType
        searchRadius
        areaOfCoverage
        accountOwnerId
        roleDescription
        accessType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          externalIds
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        patients {
          nextToken
          startedAt
        }
        accountOwner {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        scopesOrdered
        billingInfo {
          id
          owner
          payeeName
          phoneNumber
          payeeZip
          accountNumber
          routingNumber
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        messageTemplates {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
    }
  }
`
export const listDoctorPlanss = /* GraphQL */ `
  query ListDoctorPlanss($filter: ModelDoctorPlansFilterInput, $limit: Int, $nextToken: String) {
    listDoctorPlanss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        auth_status
        firstName
        lastName
        accessType
        accountOwnerId
        owner
        plans
        program
        addedBy
        a_doctor
        a_readers
        a_writers
        doctorGroups
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        accountOwner {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
      }
      nextToken
      startedAt
    }
  }
`
export const doctorPlansByEmail = /* GraphQL */ `
  query DoctorPlansByEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelDoctorPlansFilterInput
    $limit: Int
    $nextToken: String
  ) {
    doctorPlansByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        auth_status
        firstName
        lastName
        accessType
        accountOwnerId
        signupProgress
        owner
        plans
        program
        addedBy
        a_doctor
        a_readers
        a_writers
        doctorGroups
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        accountOwner {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
      }
      nextToken
      startedAt
    }
  }
`
export const doctorPlansByAccountOwnerId = /* GraphQL */ `
  query DoctorPlansByAccountOwnerId(
    $accountOwnerId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelDoctorPlansFilterInput
    $limit: Int
    $nextToken: String
  ) {
    doctorPlansByAccountOwnerId(
      accountOwnerId: $accountOwnerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        auth_status
        firstName
        lastName
        accessType
        accountOwnerId
        owner
        plans
        program
        addedBy
        a_doctor
        a_readers
        a_writers
        doctorGroups
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        accountOwner {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
      }
      nextToken
      startedAt
    }
  }
`
export const doctorPlansByAccessType = /* GraphQL */ `
  query DoctorPlansByAccessType(
    $accessType: String
    $sortDirection: ModelSortDirection
    $filter: ModelDoctorPlansFilterInput
    $limit: Int
    $nextToken: String
  ) {
    doctorPlansByAccessType(
      accessType: $accessType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        auth_status
        firstName
        lastName
        accessType
        accountOwnerId
        owner
        plans
        program
        addedBy
        a_doctor
        a_readers
        a_writers
        doctorGroups
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        accountOwner {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
      }
      nextToken
      startedAt
    }
  }
`
export const syncDoctorPlans = /* GraphQL */ `
  query SyncDoctorPlans(
    $filter: ModelDoctorPlansFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDoctorPlans(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        name
        email
        auth_status
        firstName
        lastName
        accessType
        accountOwnerId
        owner
        plans
        program
        addedBy
        a_doctor
        a_readers
        a_writers
        doctorGroups
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        accountOwner {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
      }
      nextToken
      startedAt
    }
  }
`
export const getGrinPlan = /* GraphQL */ `
  query GetGrinPlan($id: ID!) {
    getGrinPlan(id: $id) {
      id
      key
      type
      displayName
      maxScopes
      maxPatients
      stripeProductId
      period
      planGroup
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`
export const listGrinPlans = /* GraphQL */ `
  query ListGrinPlans($filter: ModelGrinPlanFilterInput, $limit: Int, $nextToken: String) {
    listGrinPlans(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        key
        type
        displayName
        maxScopes
        maxPatients
        stripeProductId
        period
        planGroup
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`
export const grinPlansByKey = /* GraphQL */ `
  query GrinPlansByKey(
    $key: String
    $sortDirection: ModelSortDirection
    $filter: ModelGrinPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    grinPlansByKey(
      key: $key
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        key
        type
        displayName
        maxScopes
        maxPatients
        stripeProductId
        period
        planGroup
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`
export const syncGrinPlans = /* GraphQL */ `
  query SyncGrinPlans(
    $filter: ModelGrinPlanFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncGrinPlans(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        key
        type
        displayName
        maxScopes
        maxPatients
        stripeProductId
        period
        planGroup
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`
export const getLocale = /* GraphQL */ `
  query GetLocale($id: ID!) {
    getLocale(id: $id) {
      id
      platform
      key
      en
      es
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`
export const listLocales = /* GraphQL */ `
  query ListLocales($filter: ModelLocaleFilterInput, $limit: Int, $nextToken: String) {
    listLocales(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        platform
        key
        en
        es
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`
export const localesByPlatform = /* GraphQL */ `
  query LocalesByPlatform(
    $platform: String
    $sortDirection: ModelSortDirection
    $filter: ModelLocaleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    localesByPlatform(
      platform: $platform
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        platform
        key
        en
        es
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`
export const syncLocales = /* GraphQL */ `
  query SyncLocales(
    $filter: ModelLocaleFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLocales(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        platform
        key
        en
        es
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`
export const getTransaction = /* GraphQL */ `
  query GetTransaction($id: ID!) {
    getTransaction(id: $id) {
      id
      owner
      type
      description
      creditCardBrand
      creditCardLast4
      amount
      a_readers
      a_writers
      invoicePdf
      createdAt
      paymentMethodStripeId
      invoiceStripeId
      scopesQuantity
      relatedProductIds
      creatorUserId
      _version
      _deleted
      _lastChangedAt
      updatedAt
      creatorUser {
        id
        username
        identityId
        timezone
        version
        model
        deviceName
        language
        type
        a_doctor
        a_readers
        a_writers
        isActive
        owner
        program
        authConfirmationDate
        appSettings
        groups
        termsVersion
        updatedAt
        createdAt
        marketingData
        allowed_groups_permissions
        _version
        _deleted
        _lastChangedAt
        grinPlanKey
        stripeCustomerId
        planOverrides
        messagingPreferences {
          id
          reminderTime
          whiteningTime
          whiteningScanTime
          whiteningScanDays
          a_readers
          a_writers
          owner
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
        featureFlags {
          id
          featureFlagsUserId
          username
          a_readers
          a_writers
          a_doctor
          flags
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patient {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        messages {
          nextToken
          startedAt
        }
        rooms {
          nextToken
          startedAt
        }
      }
    }
  }
`
export const listTransactions = /* GraphQL */ `
  query ListTransactions($filter: ModelTransactionFilterInput, $limit: Int, $nextToken: String) {
    listTransactions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        type
        description
        creditCardBrand
        creditCardLast4
        amount
        a_readers
        a_writers
        invoicePdf
        createdAt
        paymentMethodStripeId
        invoiceStripeId
        scopesQuantity
        relatedProductIds
        creatorUserId
        _version
        _deleted
        _lastChangedAt
        updatedAt
        creatorUser {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
      }
      nextToken
      startedAt
    }
  }
`
export const transactionsByUsernameSorted = /* GraphQL */ `
  query TransactionsByUsernameSorted(
    $owner: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transactionsByUsernameSorted(
      owner: $owner
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        type
        description
        creditCardBrand
        creditCardLast4
        amount
        a_readers
        a_writers
        invoicePdf
        createdAt
        paymentMethodStripeId
        invoiceStripeId
        scopesQuantity
        relatedProductIds
        creatorUserId
        _version
        _deleted
        _lastChangedAt
        updatedAt
        creatorUser {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
      }
      nextToken
      startedAt
    }
  }
`
export const syncTransactions = /* GraphQL */ `
  query SyncTransactions(
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTransactions(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        owner
        type
        description
        creditCardBrand
        creditCardLast4
        amount
        a_readers
        a_writers
        invoicePdf
        createdAt
        paymentMethodStripeId
        invoiceStripeId
        scopesQuantity
        relatedProductIds
        creatorUserId
        _version
        _deleted
        _lastChangedAt
        updatedAt
        creatorUser {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
      }
      nextToken
      startedAt
    }
  }
`
export const getUserNote = /* GraphQL */ `
  query GetUserNote($id: ID!) {
    getUserNote(id: $id) {
      id
      grinUserId
      owner
      a_doctor
      createdAt
      updatedAt
      body
      a_readers
      a_writers
      _version
      _deleted
      _lastChangedAt
    }
  }
`
export const listUserNotes = /* GraphQL */ `
  query ListUserNotes($filter: ModelUserNoteFilterInput, $limit: Int, $nextToken: String) {
    listUserNotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        grinUserId
        owner
        a_doctor
        createdAt
        updatedAt
        body
        a_readers
        a_writers
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`
export const userNotesSorted = /* GraphQL */ `
  query UserNotesSorted(
    $grinUserId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userNotesSorted(
      grinUserId: $grinUserId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        grinUserId
        owner
        a_doctor
        createdAt
        updatedAt
        body
        isPinned
        metadata
        a_readers
        a_writers
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`
export const syncUserNotes = /* GraphQL */ `
  query SyncUserNotes(
    $filter: ModelUserNoteFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserNotes(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        grinUserId
        owner
        a_doctor
        createdAt
        updatedAt
        body
        a_readers
        a_writers
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`
export const getActionItem = /* GraphQL */ `
  query GetActionItem($id: ID!) {
    getActionItem(id: $id) {
      id
      actionItemDoctorId
      actionItemPatientId
      type
      priority
      status
      params
      a_doctor
      a_readers
      a_writers
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      patient {
        id
        publicId
        patientDoctorId
        username
        auth_status
        task_status
        details {
          name
          firstName
          lastName
          email
          phone
          countryCode
          birthdate
          address
          city
          zipcode
          state
          country
          ageRange
          parentFirstName
          parentLastName
          parentEmail
          address2
          treatmentType
        }
        owner
        a_doctor
        a_readers
        a_writers
        isActive
        photo {
          bucket
          region
          key
        }
        plan
        rcToRmDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          externalIds
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        initialScans {
          nextToken
          startedAt
        }
        treatments {
          nextToken
          startedAt
        }
        grinScans {
          nextToken
          startedAt
        }
        pearlScans {
          nextToken
          startedAt
        }
        cycles {
          nextToken
          startedAt
        }
        actionItems {
          nextToken
          startedAt
        }
        patientTags {
          nextToken
          startedAt
        }
      }
      doctor {
        id
        rcToken
        username
        auth_status
        email
        a_readers
        a_writers
        name
        degrees
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          email
          countryCode
          practicePhone
          practiceCountryCode
          calendarId
          availability
          homepageUrl
        }
        owner
        photo {
          bucket
          region
          key
        }
        allowedZipcodes
        startPracticeYear
        hasLocatorConsent
        bio
        contactEmail
        contactName
        phoneNumber
        countryCode
        metricType
        searchRadius
        areaOfCoverage
        accountOwnerId
        roleDescription
        accessType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          externalIds
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        patients {
          nextToken
          startedAt
        }
        accountOwner {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        scopesOrdered
        billingInfo {
          id
          owner
          payeeName
          phoneNumber
          payeeZip
          accountNumber
          routingNumber
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        messageTemplates {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
    }
  }
`
export const listActionItems = /* GraphQL */ `
  query ListActionItems($filter: ModelActionItemFilterInput, $limit: Int, $nextToken: String) {
    listActionItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        actionItemDoctorId
        actionItemPatientId
        type
        priority
        status
        params
        a_doctor
        a_readers
        a_writers
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        patient {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
      }
      nextToken
      startedAt
    }
  }
`
export const actionItemsByStatus = /* GraphQL */ `
  query ActionItemsByStatus(
    $actionItemPatientId: ID
    $statusUpdatedAt: ModelActionItemActionItemsByStatusCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelActionItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    actionItemsByStatus(
      actionItemPatientId: $actionItemPatientId
      statusUpdatedAt: $statusUpdatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        actionItemDoctorId
        actionItemPatientId
        type
        priority
        status
        params
        a_doctor
        a_readers
        a_writers
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        patient {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
      }
      nextToken
      startedAt
    }
  }
`
export const actionItemsPrioritized = /* GraphQL */ `
  query ActionItemsPrioritized(
    $actionItemPatientId: ID
    $statusPriorityUpdatedAt: ModelActionItemActionItemsPrioritizedCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelActionItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    actionItemsPrioritized(
      actionItemPatientId: $actionItemPatientId
      statusPriorityUpdatedAt: $statusPriorityUpdatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        actionItemDoctorId
        actionItemPatientId
        type
        priority
        status
        params
        a_doctor
        a_readers
        a_writers
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        patient {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
      }
      nextToken
      startedAt
    }
  }
`
export const syncActionItems = /* GraphQL */ `
  query SyncActionItems(
    $filter: ModelActionItemFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncActionItems(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        actionItemDoctorId
        actionItemPatientId
        type
        priority
        status
        params
        a_doctor
        a_readers
        a_writers
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        patient {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
      }
      nextToken
      startedAt
    }
  }
`
export const getTag = /* GraphQL */ `
  query GetTag($id: ID!) {
    getTag(id: $id) {
      id
      value
      type
      count
      owner
      settings
      tagDoctorId
      a_readers
      a_writers
      createdAt
      updatedAt
      tagClass
      _version
      _deleted
      _lastChangedAt
      doctor {
        id
        rcToken
        username
        auth_status
        email
        a_readers
        a_writers
        name
        degrees
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          email
          countryCode
          practicePhone
          practiceCountryCode
          calendarId
          availability
          homepageUrl
        }
        owner
        photo {
          bucket
          region
          key
        }
        allowedZipcodes
        startPracticeYear
        hasLocatorConsent
        bio
        contactEmail
        contactName
        phoneNumber
        countryCode
        metricType
        searchRadius
        areaOfCoverage
        accountOwnerId
        roleDescription
        accessType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          externalIds
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        patients {
          nextToken
          startedAt
        }
        accountOwner {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        scopesOrdered
        billingInfo {
          id
          owner
          payeeName
          phoneNumber
          payeeZip
          accountNumber
          routingNumber
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        messageTemplates {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
    }
  }
`
export const listTags = /* GraphQL */ `
  query ListTags($filter: ModelTagFilterInput, $limit: Int, $nextToken: String) {
    listTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        value
        type
        count
        owner
        settings
        tagDoctorId
        a_readers
        a_writers
        createdAt
        updatedAt
        tagClass
        _version
        _deleted
        _lastChangedAt
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
      }
      nextToken
      startedAt
    }
  }
`
export const tagsByDoctorId = /* GraphQL */ `
  query TagsByDoctorId(
    $tagDoctorId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tagsByDoctorId(
      tagDoctorId: $tagDoctorId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        value
        type
        count
        owner
        settings
        tagDoctorId
        a_readers
        a_writers
        createdAt
        updatedAt
        tagClass
        isDeleted
        _version
        _deleted
        _lastChangedAt
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
      }
      nextToken
      startedAt
    }
  }
`
export const tagsByType = /* GraphQL */ `
  query TagsByType(
    $type: String
    $sortDirection: ModelSortDirection
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tagsByType(
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        value
        type
        count
        owner
        settings
        tagDoctorId
        a_readers
        a_writers
        createdAt
        updatedAt
        tagClass
        _version
        _deleted
        _lastChangedAt
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
      }
      nextToken
      startedAt
    }
  }
`
export const tagsByClass = /* GraphQL */ `
  query TagsByClass(
    $tagClass: String
    $sortDirection: ModelSortDirection
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tagsByClass(
      tagClass: $tagClass
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        value
        type
        count
        owner
        settings
        tagDoctorId
        a_readers
        a_writers
        createdAt
        updatedAt
        tagClass
        _version
        _deleted
        _lastChangedAt
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
      }
      nextToken
      startedAt
    }
  }
`
export const searchTags = /* GraphQL */ `
  query SearchTags(
    $filter: SearchableTagFilterInput
    $sort: SearchableTagSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchTags(filter: $filter, sort: $sort, limit: $limit, nextToken: $nextToken, from: $from) {
      items {
        id
        value
        type
        count
        owner
        settings
        tagDoctorId
        a_readers
        a_writers
        createdAt
        updatedAt
        tagClass
        _version
        _deleted
        _lastChangedAt
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
      }
      nextToken
      total
    }
  }
`
export const syncTags = /* GraphQL */ `
  query SyncTags(
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTags(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        value
        type
        count
        owner
        settings
        tagDoctorId
        a_readers
        a_writers
        createdAt
        updatedAt
        tagClass
        _version
        _deleted
        _lastChangedAt
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
      }
      nextToken
      startedAt
    }
  }
`
export const getPatientTag = /* GraphQL */ `
  query GetPatientTag($id: ID!) {
    getPatientTag(id: $id) {
      id
      patientTagPatientId
      patientTagTagId
      a_doctor
      a_patient
      a_readers
      a_writers
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      patient {
        id
        publicId
        patientDoctorId
        username
        auth_status
        task_status
        details {
          name
          firstName
          lastName
          email
          phone
          countryCode
          birthdate
          address
          city
          zipcode
          state
          country
          ageRange
          parentFirstName
          parentLastName
          parentEmail
          address2
          treatmentType
        }
        owner
        a_doctor
        a_readers
        a_writers
        isActive
        photo {
          bucket
          region
          key
        }
        plan
        rcToRmDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          externalIds
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        initialScans {
          nextToken
          startedAt
        }
        treatments {
          nextToken
          startedAt
        }
        grinScans {
          nextToken
          startedAt
        }
        pearlScans {
          nextToken
          startedAt
        }
        cycles {
          nextToken
          startedAt
        }
        actionItems {
          nextToken
          startedAt
        }
        patientTags {
          nextToken
          startedAt
        }
      }
      tag {
        id
        value
        type
        count
        owner
        settings
        tagDoctorId
        a_readers
        a_writers
        createdAt
        updatedAt
        tagClass
        _version
        _deleted
        _lastChangedAt
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
      }
    }
  }
`
export const listPatientTags = /* GraphQL */ `
  query ListPatientTags($filter: ModelPatientTagFilterInput, $limit: Int, $nextToken: String) {
    listPatientTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        patientTagPatientId
        patientTagTagId
        a_doctor
        a_patient
        a_readers
        a_writers
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        patient {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        tag {
          id
          value
          type
          count
          owner
          settings
          tagDoctorId
          a_readers
          a_writers
          createdAt
          updatedAt
          tagClass
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`
export const tagsByPatientId = /* GraphQL */ `
  query TagsByPatientId(
    $patientTagPatientId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPatientTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tagsByPatientId(
      patientTagPatientId: $patientTagPatientId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        patientTagPatientId
        patientTagTagId
        a_doctor
        a_patient
        a_readers
        a_writers
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        patient {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        tag {
          id
          value
          type
          count
          owner
          settings
          tagDoctorId
          a_readers
          a_writers
          createdAt
          updatedAt
          tagClass
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`
export const syncPatientTags = /* GraphQL */ `
  query SyncPatientTags(
    $filter: ModelPatientTagFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPatientTags(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        patientTagPatientId
        patientTagTagId
        a_doctor
        a_patient
        a_readers
        a_writers
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        patient {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        tag {
          id
          value
          type
          count
          owner
          settings
          tagDoctorId
          a_readers
          a_writers
          createdAt
          updatedAt
          tagClass
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`
export const getDoctorSearchModel = /* GraphQL */ `
  query GetDoctorSearchModel($id: ID!) {
    getDoctorSearchModel(id: $id) {
      id
      doctorId
      username
      email
      name
      photo {
        bucket
        region
        key
      }
      firstName
      lastName
      featureFlags
      grinUserId
      practiceName
      practiceEmail
      allowedZipcodes
      a_readers
      a_writers
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      grinUser {
        id
        username
        identityId
        timezone
        version
        model
        deviceName
        language
        type
        a_doctor
        a_readers
        a_writers
        isActive
        owner
        program
        authConfirmationDate
        appSettings
        groups
        termsVersion
        updatedAt
        createdAt
        marketingData
        allowed_groups_permissions
        _version
        _deleted
        _lastChangedAt
        grinPlanKey
        stripeCustomerId
        planOverrides
        messagingPreferences {
          id
          reminderTime
          whiteningTime
          whiteningScanTime
          whiteningScanDays
          a_readers
          a_writers
          owner
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
        featureFlags {
          id
          featureFlagsUserId
          username
          a_readers
          a_writers
          a_doctor
          flags
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patient {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        messages {
          nextToken
          startedAt
        }
        rooms {
          nextToken
          startedAt
        }
      }
      doctor {
        id
        rcToken
        username
        auth_status
        email
        a_readers
        a_writers
        name
        degrees
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          email
          countryCode
          practicePhone
          practiceCountryCode
          calendarId
          availability
          homepageUrl
        }
        owner
        photo {
          bucket
          region
          key
        }
        allowedZipcodes
        startPracticeYear
        hasLocatorConsent
        bio
        contactEmail
        contactName
        phoneNumber
        countryCode
        metricType
        searchRadius
        areaOfCoverage
        accountOwnerId
        roleDescription
        accessType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          externalIds
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        patients {
          nextToken
          startedAt
        }
        accountOwner {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        scopesOrdered
        billingInfo {
          id
          owner
          payeeName
          phoneNumber
          payeeZip
          accountNumber
          routingNumber
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        messageTemplates {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
    }
  }
`
export const listDoctorSearchModels = /* GraphQL */ `
  query ListDoctorSearchModels(
    $filter: ModelDoctorSearchModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDoctorSearchModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        doctorId
        username
        email
        name
        photo {
          bucket
          region
          key
        }
        firstName
        lastName
        featureFlags
        grinUserId
        practiceName
        practiceEmail
        allowedZipcodes
        a_readers
        a_writers
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        grinUser {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
      }
      nextToken
      startedAt
    }
  }
`
export const searchDoctorSearchModels = /* GraphQL */ `
  query SearchDoctorSearchModels(
    $filter: SearchableDoctorSearchModelFilterInput
    $sort: SearchableDoctorSearchModelSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchDoctorSearchModels(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        doctorId
        username
        email
        name
        photo {
          bucket
          region
          key
        }
        firstName
        lastName
        featureFlags
        grinUserId
        practiceName
        practiceEmail
        allowedZipcodes
        a_readers
        a_writers
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        grinUser {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
      }
      nextToken
      total
    }
  }
`
export const syncDoctorSearchModels = /* GraphQL */ `
  query SyncDoctorSearchModels(
    $filter: ModelDoctorSearchModelFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDoctorSearchModels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        doctorId
        username
        email
        name
        photo {
          bucket
          region
          key
        }
        firstName
        lastName
        featureFlags
        grinUserId
        practiceName
        practiceEmail
        allowedZipcodes
        a_readers
        a_writers
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        grinUser {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
      }
      nextToken
      startedAt
    }
  }
`
export const getPatientSearchModel = /* GraphQL */ `
  query GetPatientSearchModel($id: ID!) {
    getPatientSearchModel(id: $id) {
      id
      patientPublicId
      patientId
      email
      name
      username
      firstName
      lastName
      parentFirstName
      parentLastName
      parentEmail
      doctorId
      doctorName
      doctorEmail
      doctorUsername
      tagKeywords
      lastActionItemType
      lastActionItemStatus
      openActionItems
      lastInteractionTimestamp
      isActive
      grinUserId
      roomId
      trackingStatus
      lastMessageTimestamp
      lastMessagePreview
      featureFlags
      program
      profilePicture {
        bucket
        region
        key
      }
      statusKey
      statusType
      statusId
      treatmentType
      a_readers
      a_writers
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      grinUser {
        id
        username
        identityId
        timezone
        version
        model
        deviceName
        language
        type
        a_doctor
        a_readers
        a_writers
        isActive
        owner
        program
        authConfirmationDate
        appSettings
        groups
        termsVersion
        updatedAt
        createdAt
        marketingData
        allowed_groups_permissions
        _version
        _deleted
        _lastChangedAt
        grinPlanKey
        stripeCustomerId
        planOverrides
        messagingPreferences {
          id
          reminderTime
          whiteningTime
          whiteningScanTime
          whiteningScanDays
          a_readers
          a_writers
          owner
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
        featureFlags {
          id
          featureFlagsUserId
          username
          a_readers
          a_writers
          a_doctor
          flags
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        patient {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        messages {
          nextToken
          startedAt
        }
        rooms {
          nextToken
          startedAt
        }
      }
      patient {
        id
        publicId
        patientDoctorId
        username
        auth_status
        task_status
        details {
          name
          firstName
          lastName
          email
          phone
          countryCode
          birthdate
          address
          city
          zipcode
          state
          country
          ageRange
          parentFirstName
          parentLastName
          parentEmail
          address2
          treatmentType
        }
        owner
        a_doctor
        a_readers
        a_writers
        isActive
        photo {
          bucket
          region
          key
        }
        plan
        rcToRmDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          externalIds
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        initialScans {
          nextToken
          startedAt
        }
        treatments {
          nextToken
          startedAt
        }
        grinScans {
          nextToken
          startedAt
        }
        pearlScans {
          nextToken
          startedAt
        }
        cycles {
          nextToken
          startedAt
        }
        actionItems {
          nextToken
          startedAt
        }
        patientTags {
          nextToken
          startedAt
        }
      }
      doctor {
        id
        rcToken
        username
        auth_status
        email
        a_readers
        a_writers
        name
        degrees
        clinic {
          practiceName
          address1
          address2
          city
          zip
          state
          country
          phone
          additionalPhone
          email
          countryCode
          practicePhone
          practiceCountryCode
          calendarId
          availability
          homepageUrl
        }
        owner
        photo {
          bucket
          region
          key
        }
        allowedZipcodes
        startPracticeYear
        hasLocatorConsent
        bio
        contactEmail
        contactName
        phoneNumber
        countryCode
        metricType
        searchRadius
        areaOfCoverage
        accountOwnerId
        roleDescription
        accessType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          externalIds
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        patients {
          nextToken
          startedAt
        }
        accountOwner {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        scopesOrdered
        billingInfo {
          id
          owner
          payeeName
          phoneNumber
          payeeZip
          accountNumber
          routingNumber
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        messageTemplates {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
      room {
        id
        members
        lastMessagePreview
        lastMessageTimestamp
        sortField
        a_readers
        a_writers
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        messages {
          nextToken
          startedAt
        }
        users {
          nextToken
          startedAt
        }
      }
      patientTags {
        items {
          id
          patientTagPatientId
          patientTagTagId
          a_doctor
          a_patient
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      status {
        id
        statusId
        statusKey
        treatmentId
        setByUsername
        a_patient
        a_doctor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        treatment {
          id
          patientId
          type
          date
          statusId
          endDate
          stageDefaultDuration
          cycleInterval
          introMeetingDate
          defaultApplianceName
          currentApplianceIndex
          postConfirmationStatusKey
          cycleIntervalPeriodType
          whiteningProductType
          maxWhiteningTreatments
          status
          a_doctor
          a_patient
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        status {
          id
          key
          program
          type
          period
          maxScansPerPeriod
          hasLimitedScans
          shouldReceiveReminders
          isManualAssignmentAllowed
          isChatAllowed
          requireDoubleScan
          scanCyclesEnabled
          scanFrequencyDefaults
          settings
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
    }
  }
`
export const listPatientSearchModels = /* GraphQL */ `
  query ListPatientSearchModels(
    $filter: ModelPatientSearchModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPatientSearchModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        patientPublicId
        patientId
        email
        name
        username
        firstName
        lastName
        parentFirstName
        parentLastName
        parentEmail
        doctorId
        doctorName
        doctorEmail
        doctorUsername
        tagKeywords
        lastActionItemType
        lastActionItemStatus
        openActionItems
        lastInteractionTimestamp
        isActive
        grinUserId
        roomId
        trackingStatus
        lastMessageTimestamp
        lastMessagePreview
        featureFlags
        program
        profilePicture {
          bucket
          region
          key
        }
        statusKey
        statusType
        statusId
        treatmentType
        a_readers
        a_writers
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        grinUser {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        patient {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        room {
          id
          members
          lastMessagePreview
          lastMessageTimestamp
          sortField
          a_readers
          a_writers
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        patientTags {
          nextToken
          startedAt
        }
        status {
          id
          statusId
          statusKey
          treatmentId
          setByUsername
          a_patient
          a_doctor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`
export const searchPatientSearchModels = /* GraphQL */ `
  query SearchPatientSearchModels(
    $filter: SearchablePatientSearchModelFilterInput
    $sort: SearchablePatientSearchModelSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchPatientSearchModels(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        patientPublicId
        patientId
        email
        name
        username
        firstName
        lastName
        parentFirstName
        parentLastName
        parentEmail
        doctorId
        doctorName
        doctorEmail
        doctorUsername
        tagKeywords
        lastActionItemType
        lastActionItemStatus
        openActionItems
        lastInteractionTimestamp
        isActive
        grinUserId
        roomId
        trackingStatus
        lastMessageTimestamp
        lastMessagePreview
        featureFlags
        program
        profilePicture {
          bucket
          region
          key
        }
        statusKey
        statusType
        statusId
        treatmentType
        a_readers
        a_writers
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        grinUser {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        patient {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        room {
          id
          members
          lastMessagePreview
          lastMessageTimestamp
          sortField
          a_readers
          a_writers
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        patientTags {
          nextToken
          startedAt
        }
        status {
          id
          statusId
          statusKey
          treatmentId
          setByUsername
          a_patient
          a_doctor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      total
    }
  }
`
export const syncPatientSearchModels = /* GraphQL */ `
  query SyncPatientSearchModels(
    $filter: ModelPatientSearchModelFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPatientSearchModels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        patientPublicId
        patientId
        email
        name
        username
        firstName
        lastName
        parentFirstName
        parentLastName
        parentEmail
        doctorId
        doctorName
        doctorEmail
        doctorUsername
        tagKeywords
        lastActionItemType
        lastActionItemStatus
        openActionItems
        lastInteractionTimestamp
        isActive
        grinUserId
        roomId
        lastMessageTimestamp
        lastMessagePreview
        featureFlags
        program
        profilePicture {
          bucket
          region
          key
        }
        statusKey
        statusType
        statusId
        treatmentType
        a_readers
        a_writers
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        grinUser {
          id
          username
          identityId
          timezone
          version
          model
          deviceName
          language
          type
          a_doctor
          a_readers
          a_writers
          isActive
          owner
          program
          authConfirmationDate
          appSettings
          groups
          termsVersion
          updatedAt
          createdAt
          marketingData
          allowed_groups_permissions
          _version
          _deleted
          _lastChangedAt
          grinPlanKey
          stripeCustomerId
          planOverrides
        }
        patient {
          id
          publicId
          patientDoctorId
          username
          auth_status
          task_status
          owner
          a_doctor
          a_readers
          a_writers
          isActive
          plan
          rcToRmDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        doctor {
          id
          rcToken
          username
          auth_status
          email
          a_readers
          a_writers
          name
          degrees
          owner
          allowedZipcodes
          startPracticeYear
          hasLocatorConsent
          bio
          contactEmail
          contactName
          phoneNumber
          countryCode
          metricType
          searchRadius
          areaOfCoverage
          accountOwnerId
          roleDescription
          accessType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          scopesOrdered
        }
        room {
          id
          members
          lastMessagePreview
          lastMessageTimestamp
          sortField
          a_readers
          a_writers
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        patientTags {
          nextToken
          startedAt
        }
        status {
          id
          statusId
          statusKey
          treatmentId
          setByUsername
          a_patient
          a_doctor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`
export const getStatus = /* GraphQL */ `
  query GetStatus($id: ID!) {
    getStatus(id: $id) {
      id
      key
      program
      type
      period
      maxScansPerPeriod
      hasLimitedScans
      shouldReceiveReminders
      isManualAssignmentAllowed
      isChatAllowed
      requireDoubleScan
      scanCyclesEnabled
      scanFrequencyDefaults
      settings
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`
export const listStatuss = /* GraphQL */ `
  query ListStatuss($filter: ModelStatusFilterInput, $limit: Int, $nextToken: String) {
    listStatuss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        key
        program
        type
        period
        maxScansPerPeriod
        hasLimitedScans
        shouldReceiveReminders
        isManualAssignmentAllowed
        isChatAllowed
        requireDoubleScan
        scanCyclesEnabled
        scanFrequencyDefaults
        settings
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`
export const statusByKey = /* GraphQL */ `
  query StatusByKey(
    $key: String
    $sortDirection: ModelSortDirection
    $filter: ModelStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    statusByKey(
      key: $key
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        key
        program
        type
        period
        maxScansPerPeriod
        hasLimitedScans
        shouldReceiveReminders
        isManualAssignmentAllowed
        isChatAllowed
        requireDoubleScan
        scanCyclesEnabled
        scanFrequencyDefaults
        settings
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`
export const statusesByType = /* GraphQL */ `
  query StatusesByType(
    $type: String
    $sortDirection: ModelSortDirection
    $filter: ModelStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    statusesByType(
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        key
        program
        type
        period
        maxScansPerPeriod
        hasLimitedScans
        shouldReceiveReminders
        isManualAssignmentAllowed
        isChatAllowed
        requireDoubleScan
        scanCyclesEnabled
        scanFrequencyDefaults
        settings
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`
export const statusesByProgram = /* GraphQL */ `
  query StatusesByProgram(
    $program: String
    $sortDirection: ModelSortDirection
    $filter: ModelStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    statusesByProgram(
      program: $program
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        key
        program
        type
        period
        maxScansPerPeriod
        hasLimitedScans
        shouldReceiveReminders
        isManualAssignmentAllowed
        isChatAllowed
        requireDoubleScan
        scanCyclesEnabled
        scanFrequencyDefaults
        settings
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`
export const syncStatuses = /* GraphQL */ `
  query SyncStatuses(
    $filter: ModelStatusFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncStatuses(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        key
        program
        type
        period
        maxScansPerPeriod
        hasLimitedScans
        shouldReceiveReminders
        isManualAssignmentAllowed
        isChatAllowed
        requireDoubleScan
        scanCyclesEnabled
        scanFrequencyDefaults
        settings
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`
export const getTreatmentStatus = /* GraphQL */ `
  query GetTreatmentStatus($id: ID!) {
    getTreatmentStatus(id: $id) {
      id
      statusId
      statusKey
      treatmentId
      setByUsername
      a_patient
      a_doctor
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      treatment {
        id
        patientId
        type
        date
        statusId
        endDate
        simulation {
          status
          backlog
        }
        offer {
          price
          currency
          status
          message
          tier
        }
        stageDefaultDuration
        cycleInterval
        introMeetingDate
        defaultApplianceName
        currentApplianceIndex
        timelineEventLog {
          eventType
          timestamp
          params
        }
        postConfirmationStatusKey
        cycleIntervalPeriodType
        whiteningProductType
        maxWhiteningTreatments
        status
        a_doctor
        a_patient
        a_readers
        a_writers
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        proposals {
          nextToken
          startedAt
        }
        treatmentsPlans {
          nextToken
          startedAt
        }
        stages {
          nextToken
          startedAt
        }
        currentStatus {
          id
          statusId
          statusKey
          treatmentId
          setByUsername
          a_patient
          a_doctor
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        treatmentStatuses {
          nextToken
          startedAt
        }
      }
      status {
        id
        key
        program
        type
        period
        maxScansPerPeriod
        hasLimitedScans
        shouldReceiveReminders
        isManualAssignmentAllowed
        isChatAllowed
        requireDoubleScan
        scanCyclesEnabled
        scanFrequencyDefaults
        settings
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
    }
  }
`
export const listTreatmentStatuss = /* GraphQL */ `
  query ListTreatmentStatuss(
    $filter: ModelTreatmentStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTreatmentStatuss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        statusId
        statusKey
        treatmentId
        setByUsername
        a_patient
        a_doctor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        treatment {
          id
          patientId
          type
          date
          statusId
          endDate
          stageDefaultDuration
          cycleInterval
          introMeetingDate
          defaultApplianceName
          currentApplianceIndex
          postConfirmationStatusKey
          cycleIntervalPeriodType
          whiteningProductType
          maxWhiteningTreatments
          status
          a_doctor
          a_patient
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        status {
          id
          key
          program
          type
          period
          maxScansPerPeriod
          hasLimitedScans
          shouldReceiveReminders
          isManualAssignmentAllowed
          isChatAllowed
          requireDoubleScan
          scanCyclesEnabled
          scanFrequencyDefaults
          settings
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`
export const treatmentStatusesSorted = /* GraphQL */ `
  query TreatmentStatusesSorted(
    $treatmentId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTreatmentStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    treatmentStatusesSorted(
      treatmentId: $treatmentId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        statusId
        statusKey
        treatmentId
        setByUsername
        a_patient
        a_doctor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        treatment {
          id
          patientId
          type
          date
          statusId
          endDate
          stageDefaultDuration
          cycleInterval
          introMeetingDate
          defaultApplianceName
          currentApplianceIndex
          postConfirmationStatusKey
          cycleIntervalPeriodType
          whiteningProductType
          maxWhiteningTreatments
          status
          a_doctor
          a_patient
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        status {
          id
          key
          program
          type
          period
          maxScansPerPeriod
          hasLimitedScans
          shouldReceiveReminders
          isManualAssignmentAllowed
          isChatAllowed
          requireDoubleScan
          scanCyclesEnabled
          scanFrequencyDefaults
          settings
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`
export const syncTreatmentStatuses = /* GraphQL */ `
  query SyncTreatmentStatuses(
    $filter: ModelTreatmentStatusFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTreatmentStatuses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        statusId
        statusKey
        treatmentId
        setByUsername
        a_patient
        a_doctor
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        treatment {
          id
          patientId
          type
          date
          statusId
          endDate
          stageDefaultDuration
          cycleInterval
          introMeetingDate
          defaultApplianceName
          currentApplianceIndex
          postConfirmationStatusKey
          cycleIntervalPeriodType
          whiteningProductType
          maxWhiteningTreatments
          status
          a_doctor
          a_patient
          a_readers
          a_writers
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        status {
          id
          key
          program
          type
          period
          maxScansPerPeriod
          hasLimitedScans
          shouldReceiveReminders
          isManualAssignmentAllowed
          isChatAllowed
          requireDoubleScan
          scanCyclesEnabled
          scanFrequencyDefaults
          settings
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`
export const getGroup = /* GraphQL */ `
  query GetGroup($id: ID!) {
    getGroup(id: $id) {
      id
      username
      key
      displayName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`
export const listGroups = /* GraphQL */ `
  query ListGroups($filter: ModelGroupFilterInput, $limit: Int, $nextToken: String) {
    listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        key
        displayName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        extraData
      }
      nextToken
      startedAt
    }
  }
`
export const syncGroups = /* GraphQL */ `
  query SyncGroups(
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncGroups(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        username
        key
        displayName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`
export const getRolePermission = /* GraphQL */ `
  query GetRolePermission($id: ID!) {
    getRolePermission(id: $id) {
      id
      roleKey
      description
      permissions
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`
export const listRolePermissions = /* GraphQL */ `
  query ListRolePermissions(
    $filter: ModelRolePermissionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRolePermissions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        roleKey
        description
        permissions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`
export const syncRolePermissions = /* GraphQL */ `
  query SyncRolePermissions(
    $filter: ModelRolePermissionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRolePermissions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        roleKey
        description
        permissions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`
export const getAnnouncement = /* GraphQL */ `
  query GetAnnouncement($id: ID!) {
    getAnnouncement(id: $id) {
      id
      status
      startDate
      endDate
      type
      trigger
      content
      conditions
      excludedUserEmails
      featureFlags
      createdAt
      updatedAt
      href
      settings
      _version
      _deleted
      _lastChangedAt
    }
  }
`
export const listAnnouncements = /* GraphQL */ `
  query ListAnnouncements($filter: ModelAnnouncementFilterInput, $limit: Int, $nextToken: String) {
    listAnnouncements(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        status
        startDate
        endDate
        type
        trigger
        content
        conditions
        excludedUserEmails
        featureFlags
        createdAt
        updatedAt
        href
        settings
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`
export const announcementsByStatusByDates = /* GraphQL */ `
  query AnnouncementsByStatusByDates(
    $status: String
    $startDateEndDate: ModelAnnouncementAnnouncementsByStatusByDatesCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAnnouncementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    announcementsByStatusByDates(
      status: $status
      startDateEndDate: $startDateEndDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        startDate
        endDate
        type
        trigger
        content
        conditions
        excludedUserEmails
        featureFlags
        createdAt
        updatedAt
        href
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`
export const syncAnnouncements = /* GraphQL */ `
  query SyncAnnouncements(
    $filter: ModelAnnouncementFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAnnouncements(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        status
        startDate
        endDate
        type
        trigger
        content
        conditions
        excludedUserEmails
        featureFlags
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`
