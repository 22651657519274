import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { useTranslation } from 'react-i18next'
import LinkButton from 'components/common/buttons/LinkButton'
import TwoFactorAuthIsOn from './MFASetupModal/AuthAppSetup/TwoFactorAuthIsOn'
import useRolePermissions from 'hooks/useRolePermissions'

const MFAMethod = ({
  icon,
  title,
  description,
  isRecommended = false,
  isTurnedOn = false,
  onSetupClicked = () => {},
  onDisableClicked = () => {}
}) => {
  const useStyles = makeStyles({
    root: {
      padding: '18px 18px 18px 30px',
      backgroundColor: isTurnedOn ? '#ECFFF5' : 'var(--bg-color-twoFactorMethod)',
      borderRadius: 16
    },
    recommendedText: {
      color: '#26C36F'
    }
  })

  const classes = useStyles({ isTurnedOn })
  const { t } = useTranslation()
  const { permissions } = useRolePermissions()

  return (
    <Grid container alignItems="center" wrap="nowrap" className={classes.root}>
      <Grid item style={{ marginRight: 20 }}>
        {icon}
      </Grid>
      <Grid item>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <DazzedParagraph14 bold>
              {title}
              {isRecommended && (
                <span className={classes.recommendedText}>{` ${t(
                  'pages.accountSettings.mfaSettings.recommended'
                )}`}</span>
              )}
            </DazzedParagraph14>
          </Grid>
          <Grid item>
            <DazzedParagraph14>{description}</DazzedParagraph14>
          </Grid>
          {isTurnedOn && <TwoFactorAuthIsOn />}
          <Grid item>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <LinkButton
                  underline
                  fontSize={14}
                  label={isTurnedOn ? t('general.update') : t('pages.accountSettings.mfaSettings.setUpMethod')}
                  onClick={onSetupClicked}
                />
              </Grid>
              {permissions.disableMFA && isTurnedOn && (
                <Grid item>
                  <LinkButton underline fontSize={14} label={t('general.disable')} onClick={onDisableClicked} />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default MFAMethod
