import * as React from 'react'

export default () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0224 3.96794C14.8877 2.83325 13.048 2.83325 11.9133 3.96794L4.14938 11.7319L3.98005 11.9012L3.94018 12.1373L3.32097 15.805L3.14539 16.8449L4.18536 16.6693L7.85298 16.0501L8.08912 16.0103L8.25845 15.8409L16.0224 8.07701C17.1571 6.94232 17.1571 5.10263 16.0224 3.96794ZM12.974 5.0286C13.5229 4.4797 14.4128 4.4797 14.9617 5.0286C15.5106 5.5775 15.5106 6.46745 14.9617 7.01635L13.9682 8.0099L11.9804 6.02215L12.974 5.0286ZM10.9198 7.08281L5.37938 12.6232L4.97562 15.0147L7.36713 14.6109L12.9075 9.07056L10.9198 7.08281Z"
      fill="#3C52EF"
    />
  </svg>
)
