import { makeStyles } from '@material-ui/core'
import GrinTag from './GrinTag'

const useStyles = makeStyles(() => ({
  scaleDown: {
    transform: 'scale(0.9)'
  }
}))

/**
 * NeedsAttentionDisplayOnlyTag component.
 * This component is used to display a NeedsAttention tag.
 * It is a display-only component and does not have any functionalities.
 * The tag cannot be removed or interacted with; it is purely for visual purposes.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.needsAttention - A flag indicating if attention is needed.
 */
const NeedsAttentionDisplayOnlyTag = ({
  color = 'rgb(255, 242, 215)',
  textColor = 'rgb(219, 97, 93)',
  circleColor
}) => {
  const classes = useStyles()
  return (
    <GrinTag
      className={classes.scaleDown}
      tag={{
        type: 'scan',
        value: 'needsAttention',
        tagClass: 'system',
        settings: JSON.stringify({
          color,
          textColor,
          circleColor
        })
      }}
    />
  )
}

export default NeedsAttentionDisplayOnlyTag
