import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { Grid } from '@material-ui/core'
import FormInput from 'components/common/FormInput'

const useStyles = makeStyles(theme => ({
  typicalAdvanceFrequencyInputWrapper: {
    margin: '0px 0px 0px 5px!important'
  },
  typicalAdvanceFrequencyInput: {
    maxWidth: 40
  },
  label: {
    userSelect: 'none'
  }
}))

const TypicalAdvanceFrequency = ({ checked, onChange, frequency, viewMode }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return frequency ? (
    viewMode ? (
      <Grid container alignItems="center">
        <Grid item>
          <DazzedParagraph14 strong className={classes.label}>
            {t('dialogs.patientBrief.categories.typicalAdvanceFrequency.viewTitle', { frequency })}
          </DazzedParagraph14>
        </Grid>
      </Grid>
    ) : (
      <Grid container alignItems="center">
        <Grid item>
          <DazzedParagraph14 strong className={classes.label}>
            {`${t('dialogs.patientBrief.categories.typicalAdvanceFrequency.title')}:`}
          </DazzedParagraph14>
        </Grid>
        <Grid item>
          <FormInput
            type="number"
            minValue={0}
            value={frequency || ''}
            setValue={onChange}
            style={{ bright: true, borderBottom: true }}
            keepErrorContainerWhenInactive={false}
            inputWrapperClassName={classes.typicalAdvanceFrequencyInputWrapper}
            inputClassName={classes.typicalAdvanceFrequencyInput}
          />
        </Grid>
      </Grid>
    )
  ) : (
    <></>
  )
}

export default TypicalAdvanceFrequency
