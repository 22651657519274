import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { CrossedCircle } from 'components/common/icons'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import MessageFooter from './MessageFooter'

const useStyles = makeStyles(theme => ({
  deletedMessage: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: 10
    }
  },
  timestamp: {
    marginTop: 5,
    textAlign: 'right'
  }
}))

const DeletedMessageContent = ({ timestamp, readStatus }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div>
      <div className={classes.deletedMessage}>
        <CrossedCircle color="var(--text-color-11)" />
        <DazzedParagraph14 strong color={'var(--text-color-11)'}>
          {t('pages.patients.selectedPatient.chat.messageDeleted')}
        </DazzedParagraph14>
      </div>
      <div className={classes.timestamp}>
        <MessageFooter sendDate={timestamp} readStatus={readStatus} />
      </div>
    </div>
  )
}

export default DeletedMessageContent
