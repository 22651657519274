import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Collapse, Grid } from '@material-ui/core'
import GrinSwitch from 'components/common/GrinSwitch'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import Chevron from 'components/common/icons/ChevrolRightSmall'
import { PracticeGuidelineInfo } from 'components/common/icons'
import Tooltip from 'components/common/Tooltip'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'

const useStyles = makeStyles(theme => ({
  container: {
    width: 1020
  },
  header: {
    cursor: 'pointer',
    padding: '20px 0px 5px 0px'
  },
  divider: {
    border: '1px solid var(--border-color-11)',
    opacity: 0.3
  },
  title: {
    userSelect: 'none'
  }
}))

const CollapsibleCategory = ({
  id,
  isCollapsed,
  onCollapseClick,
  withSwitch = true,
  isEnabled,
  onSwitchClick,
  icon,
  title,
  tooltip,
  errorMessage = '',
  children
}) => {
  const classes = useStyles()

  return (
    <Grid container id={id} direction="column" wrap="nowrap" className={classes.container}>
      <Grid item onClick={onCollapseClick} className={classes.header}>
        <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
          <Grid item>
            <Grid container alignItems="center" spacing={1}>
              {withSwitch && (
                <Grid item>
                  <GrinSwitch checked={isEnabled} onClick={onSwitchClick} />
                </Grid>
              )}
              {icon && (
                <Grid item>
                  <span style={{ lineHeight: 0.8 }}>{icon}</span>
                </Grid>
              )}
              <Grid item>
                <DazzedParagraph16 bold className={classes.title}>
                  {title}
                </DazzedParagraph16>
              </Grid>
              {tooltip && (
                <Grid item>
                  <Tooltip arrow placement="top" value={tooltip}>
                    <PracticeGuidelineInfo style={{ marginTop: 5 }} />
                  </Tooltip>
                </Grid>
              )}
              {errorMessage && (
                <Grid item>
                  <DazzedParagraph14 strong color="red">
                    {errorMessage}
                  </DazzedParagraph14>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item>
            <Chevron
              fill="var(--text-color-27)"
              style={{ transform: isCollapsed ? 'rotate(90deg)' : 'rotate(-90deg)' }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Collapse in={!isCollapsed} style={{ marginBottom: 20, position: 'relative' }}>
          {children}
        </Collapse>
      </Grid>
      <div className={classes.divider} />
    </Grid>
  )
}

export default CollapsibleCategory
