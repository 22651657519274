import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import Actions from 'actions'
import useAppliedPatientsFilter from './useAppliedPatientsFilter'
import { isUserOfRole } from 'utils/authUtils'
import { Roles } from 'consts/authConsts'
import { AsyncStatus } from 'consts'

export default () => {
  const dispatch = useDispatch()
  const doctor = useSelector(state => state.profileReducer.doctor)
  const { loadStatus: hiLoadStatus } = useSelector(state => state.multiPracticeReducer.assignedDoctors)
  const sortDirection = useSelector(state => state.chatReducer.sort.direction)
  const { appliedFilters } = useAppliedPatientsFilter()

  const [doctorFilter, setDoctorFilter] = useState(null)
  const [patientsSearchFilter, setPatientsSearchFilter] = useState('')

  useEffect(() => {
    if (!doctor?.id) {
      return
    }

    if (isUserOfRole([Roles.HI, Roles.GI]) && hiLoadStatus !== AsyncStatus.Completed) {
      return
    }

    dispatch(
      Actions.fetchRooms({
        tagsFilter: isEmpty(appliedFilters) ? undefined : appliedFilters,
        doctorFilter: doctorFilter ?? undefined,
        newSearch: true,
        searchFilter: patientsSearchFilter
      })
    )
  }, [appliedFilters, dispatch, patientsSearchFilter, doctorFilter, doctor?.id, hiLoadStatus, sortDirection])

  return {
    appliedFilters,
    patientsSearchFilter,
    setPatientsSearchFilter,
    doctorFilter,
    setDoctorFilter
  }
}
