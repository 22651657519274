import React from 'react'
import { useTranslation } from 'react-i18next'
import { SendBrushingInstructions as SendBrushingInstructionsIcon } from 'components/common/icons'
import GenericGuidelineAction from './GenericGuidelineAction'

const SendBrushingInstructions = () => {
  const { t } = useTranslation()

  return (
    <GenericGuidelineAction
      icon={<SendBrushingInstructionsIcon />}
      text={t('dialogs.hiPatientGuidelinesViewer.actions.sendBrushingInstructions')}
    />
  )
}

export default SendBrushingInstructions
