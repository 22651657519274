import React from 'react'
import { Trans } from 'react-i18next'
import BaseGrinNotification from './BaseGrinNotification/BaseGrinNotification'
import useSelectPatient from 'hooks/useSelectPatient'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import NeedsAttentionNotificationTitle from './NeedsAttentionNotificationTitle'
import { makeStyles } from '@material-ui/styles'
import { NotifyPracticeReasons } from 'consts/giConsts'

const useStyles = makeStyles({
  note: {
    maxWidth: 250,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
})

/**
 * @param {NotificationComponentProps} props
 */
const NeedsAttentionNotification = ({ grinNotification, options }) => {
  const classes = useStyles()
  const selectPatient = useSelectPatient({
    patientId: grinNotification?.relatedPatientId
  })

  return (
    <BaseGrinNotification
      grinNotification={grinNotification}
      isLastItem={options.isLastItem}
      showPracticeName={options.showPracticeName}
      profilePicture={grinNotification.relatedPatientSM.profilePicture}
      profilePictureUserName={grinNotification.relatedPatientSM.name}
      onClick={selectPatient}
      customTitle={<NeedsAttentionNotificationTitle reason={grinNotification.payload.reason} />}
    >
      <DazzedParagraph14 strong>
        <Trans
          i18nKey={
            grinNotification.payload.reason === NotifyPracticeReasons.SpecificMembersAttention
              ? 'dialogs.notificationCenterDialog.feed.notifications.needsAttention.specificAttention'
              : 'dialogs.notificationCenterDialog.feed.notifications.needsAttention.generic'
          }
          values={{ patientName: grinNotification.relatedPatientSM.name }}
        />
      </DazzedParagraph14>
      {grinNotification.payload.note && (
        <DazzedParagraph14 strong className={classes.note}>
          "{grinNotification.payload.note}"
        </DazzedParagraph14>
      )}
    </BaseGrinNotification>
  )
}

export default NeedsAttentionNotification
