import React, { useCallback, useMemo } from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { filterObjectByFunction } from 'utils/generalUtils'
import { ActionTypes } from 'consts/hiToolsConsts'
import NotifyPractice from './Actions/NotifyPractice'
import InstructForChewies from './Actions/InstructForChewies'
import Frequency from './Actions/Frequency'
import KeepAlignersOrGoBack from './Actions/KeepAlignersOrGoBack'
import SendBrushingInstructions from './Actions/SendBrushingInstructions'
import TypicalWireSequence from './Actions/TypicalWireSequence'
import MessagePatient from './Actions/MessagePatient'
import GuidelineItem from './GuidelineItem'
import PauseScans from './Actions/PauseScans'
import StayInAlignerUntilReplacedOrAdvance from './Actions/StayInAlignerUntilReplacedOrAdvance'
import LimitSetForXDays from './Actions/LimitSetForXDays'
import CallForAppointmentOrConfirmIPR from './Actions/CallForAppointmentOrConfirmIPR'

const PracticeGuidelineGenericCategory = ({ items }) => {
  const { t } = useTranslation()

  const actions = useMemo(
    () => [
      {
        key: ActionTypes.MessagePatient,
        component: MessagePatient
      },
      {
        key: ActionTypes.PauseScans,
        component: PauseScans
      },
      {
        key: ActionTypes.LimitSetForXDays,
        component: LimitSetForXDays
      },
      {
        key: ActionTypes.NotifyPractice,
        component: NotifyPractice
      },
      {
        key: ActionTypes.InstructForChewies,
        component: InstructForChewies
      },
      {
        key: ActionTypes.StayInAlignerUntilReplacedOrAdvance,
        component: StayInAlignerUntilReplacedOrAdvance
      },
      {
        key: ActionTypes.CallForAppointmentOrConfirmIPR,
        component: CallForAppointmentOrConfirmIPR
      },
      {
        key: ActionTypes.Frequency,
        component: Frequency
      },
      {
        key: ActionTypes.KeepAlignersOrGoBack,
        component: KeepAlignersOrGoBack
      },
      {
        key: ActionTypes.SendBrushingInstructionsToThePatient,
        component: SendBrushingInstructions
      },
      {
        key: ActionTypes.TypicalWireSequence,
        component: TypicalWireSequence
      }
    ],
    []
  )

  const isActionEnabled = useCallback(
    ({
      value,
      isChecked,
      frequency,
      shouldGoBack,
      shouldKeepAligner,
      days,
      limitDays,
      shouldStayInAligner,
      shouldAdvance,
      callForAppointment,
      confirmIPR
    }) =>
      !!value ||
      isChecked ||
      frequency ||
      shouldGoBack ||
      (shouldKeepAligner && days) ||
      (isChecked && limitDays) ||
      shouldAdvance ||
      shouldStayInAligner ||
      callForAppointment ||
      confirmIPR,
    []
  )

  const filteredItems = useMemo(() => {
    if (!items) {
      return null
    }

    // filter items with only the enabled actions and filter out the disabled ones
    const itemsWithFilteredActions = filterObjectByFunction(items, isActionEnabled)

    // filter out items which have no actions, after filtering the disabled ones
    const filteredEmptyItems = Object.keys(itemsWithFilteredActions).reduce(
      (filteredItems, itemKey) => ({
        ...filteredItems,
        ...(Object.values(itemsWithFilteredActions[itemKey]).length > 0
          ? { [itemKey]: itemsWithFilteredActions[itemKey] }
          : {})
      }),
      {}
    )

    return filteredEmptyItems
  }, [isActionEnabled, items])

  return (
    filteredItems &&
    Object.keys(filteredItems).map(itemKey => (
      <div key={itemKey} style={{ width: '100%' }}>
        <GuidelineItem title={`${t(`dialogs.hiPatientGuidelinesViewer.items.${itemKey}`)}:`}>
          <Grid container spacing={1}>
            {Object.keys(filteredItems[itemKey]).map(actionKey => (
              <Grid item xs={5} key={actionKey}>
                {React.createElement(actions.find(action => action.key === actionKey)?.component, {
                  ...(filteredItems?.[itemKey]?.[actionKey] || {})
                })}
              </Grid>
            ))}
          </Grid>
        </GuidelineItem>
      </div>
    ))
  )
}
export default PracticeGuidelineGenericCategory
