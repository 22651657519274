import * as React from 'react'

export default () => (
  <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1H13.875V17.48H9.69062V13.36H7.4375H5.18437V17.48H1V1Z" stroke="#001335" strokeLinejoin="round" />
    <rect x="4" y="8.375" width="2.49999" height="2.49999" fill="#001335" />
    <rect x="4" y="4" width="2.49999" height="2.49999" fill="#001335" />
    <rect x="8.375" y="8.375" width="2.49999" height="2.49999" fill="#001335" />
    <rect x="8.375" y="4" width="2.49999" height="2.49999" fill="#001335" />
  </svg>
)
