import React, { useCallback, useEffect, useMemo } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { TriggerConfigs } from 'consts/automatedActionConsts'
import { useTranslation } from 'react-i18next'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import useAutomationRules from 'hooks/useAutomationRules'
import AddCondiditionButton from './AddConditionButton'
import AutomationRuleConditionSelector from './AutomationRuleConditionSelector'

const useStyles = makeStyles(theme => ({
  conditionLabel: {
    color: 'var(--text-color-11)'
  },
  conditionItemContainer: {
    marginBottom: 30
  }
}))

const AutomationRuleConditionsEditor = ({ triggerType, setConditions, conditions = [], errors, isSystem }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { isConditionAllowed } = useAutomationRules()

  const triggerConfig = useMemo(() => TriggerConfigs[triggerType], [triggerType])
  const hasSingleConditionOption = useMemo(
    () => triggerConfig.isRequired && triggerConfig.conditions.length === 1,
    [triggerConfig]
  )
  const selectedConditionTypes = useMemo(() => conditions?.map(condition => condition.type), [conditions])
  const conditionsOptions = useMemo(
    () =>
      triggerConfig.conditions.filter(isConditionAllowed).map(condition => ({
        value: condition,
        label: t(`pages.accountSettings.automations.conditions.${condition}`)
      })),
    [t, triggerConfig, isConditionAllowed]
  )
  const canAddMoreConditions = useMemo(
    () => conditions.length < conditionsOptions.length,
    [conditions, conditionsOptions]
  )

  const handleAddCondition = useCallback(() => {
    setConditions([...conditions, { type: '', params: {} }])
  }, [setConditions, conditions])

  const handleConditionsChange = useCallback(
    ({ index, condition }) => {
      const updatedConditions = [...conditions]
      updatedConditions[index] = condition
      setConditions(updatedConditions)
    },
    [setConditions, conditions]
  )

  const deleteCondition = useCallback(
    index => {
      setConditions(conditions.filter((condition, i) => i !== index))
    },
    [setConditions, conditions]
  )

  useEffect(() => {
    if (conditions.length) {
      return
    }

    if (hasSingleConditionOption) {
      setConditions([{ type: conditionsOptions[0]?.value, params: {} }])
    } else {
      setConditions([{ type: '', params: {} }])
    }
  }, [triggerType, hasSingleConditionOption, conditionsOptions]) //eslint-disable-line

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <DazzedParagraph14 bold className={classes.conditionLabel}>
          {t('pages.accountSettings.automations.editorModal.conditionLabel')}
        </DazzedParagraph14>
      </Grid>
      {conditions.map((condition, i) => (
        <Grid item key={`automation-rule-condition-${i}`} className={classes.conditionItemContainer}>
          <AutomationRuleConditionSelector
            condition={condition}
            conditionsOptions={conditionsOptions.filter(
              option => condition.type === option.value || !selectedConditionTypes.includes(option.value)
            )}
            setCondition={condition => handleConditionsChange({ index: i, condition })}
            onDelete={() => deleteCondition(i)}
            hideConditionTypeInput={hasSingleConditionOption}
            errors={errors}
            isSystem={isSystem}
          />
        </Grid>
      ))}
      <Grid item xs={12}>
        {canAddMoreConditions && (
          <Grid item>
            <AddCondiditionButton onClick={handleAddCondition} />
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default AutomationRuleConditionsEditor
