import React, { useState, useCallback, useEffect } from 'react'
import { makeStyles, Grid } from '@material-ui/core'
import { createIdForTest } from 'utils/testingUtils.js'
import DazzedParagraph12 from '../../../common/text/DazzedParagraph12'
import DazzedHeading24 from '../../../common/text/DazzedHeading24'
import RecordsButtons from './RecordsButtons'
import UploadsFile from './UploadsFile'
import { partition } from 'lodash'

const useStyles = makeStyles(theme => ({
  container: {
    borderBottom: '1px solid var(--border-color-10)',
    paddingBottom: 27,
    paddingTop: 5
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 5
  },
  title: {
    marginRight: '11px',
    color: 'var(--text-color-3)'
  },
  subtitle: {
    marginTop: '8px',
    color: 'var(--text-color-14)'
  }
}))

const Uploads = ({
  placeholders,
  uploadedFiles,
  type,
  onStartUploadingItems,
  handleUploadFiles,
  handleDownloadZipFiles,
  onDeletePhoto,
  title,
  subtitle,
  isUploading,
  placeholderIcon,
  onPlusClick,
  onReplace,
  isFileReplaceable,
  supportedFileTypes,
  onItemOpen,
  previewRenderer,
  isSelectionMode = false,
  onFileToggled,
  selectedFiles,
  readOnly
}) => {
  const classes = useStyles()

  const [files, setFiles] = useState([])
  const [focusAreaImages, setFocusAreaImages] = useState([])

  const isReplaceAvailable = useCallback(
    fileUrl => onReplace && isFileReplaceable(fileUrl),
    [isFileReplaceable, onReplace]
  )

  useEffect(() => {
    const [currentFocusAreaImages, restOfFiles] = partition(uploadedFiles, file =>
      file?.key?.includes('focus-area-image')
    )
    setFocusAreaImages(currentFocusAreaImages)

    if (uploadedFiles.length <= placeholders) {
      setFiles([...Array(placeholders)].map((el, i) => restOfFiles[i] || { url: null, isLoading: false }))
    } else {
      setFiles(restOfFiles)
    }
  }, [placeholders, uploadedFiles])

  const onStartUploading = useCallback(
    count => {
      const newFiles = [...uploadedFiles]
      for (let index = 0; index < count; index++) {
        newFiles.push({
          url: null,
          isLoading: true
        })
      }
      onStartUploadingItems({
        [type]: newFiles
      })
    },
    [onStartUploadingItems, type, uploadedFiles]
  )

  const handleUpload = useCallback(
    s3Objects => {
      handleUploadFiles({ [type]: [...uploadedFiles, ...s3Objects] })
    },
    [handleUploadFiles, type, uploadedFiles]
  )

  return (
    <div className={classes.container} id={createIdForTest({ componentId: 'patient-card-records', title })}>
      <div className={classes.titleContainer}>
        <DazzedHeading24 className={classes.title}>{title}</DazzedHeading24>
        <DazzedParagraph12 className={classes.subtitle}>{subtitle}</DazzedParagraph12>
      </div>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item md={10} id={createIdForTest({ componentId: 'patient-card-records', title, elementId: 'preview' })}>
          <Grid container spacing={2}>
            {files.map(({ url, isLoading, key, position, bucket, region }, index) => (
              <UploadsFile
                url={url}
                isLoading={isLoading}
                key={key || index}
                itemKey={key}
                placeholderIcon={placeholderIcon}
                type={type}
                isSelectionMode={isSelectionMode}
                onFileToggled={() => onFileToggled({ key, bucket, region, url })}
                selectedFiles={selectedFiles}
                onDeletePhoto={onDeletePhoto}
                onOpen={onItemOpen ? () => onItemOpen({ url, key, type, position, files, index }) : null}
                onReplace={isReplaceAvailable(url) ? () => onReplace({ url, key, index }) : null}
                previewRenderer={previewRenderer ? () => previewRenderer({ url, key, type }) : null}
              />
            ))}
          </Grid>
        </Grid>
        {focusAreaImages.length > 0 && (
          <Grid
            item
            md={10}
            id={createIdForTest({ componentId: 'patient-card-records-focus-area-images', title, elementId: 'preview' })}
          >
            <Grid container spacing={2} style={{ marginTop: 5 }}>
              {focusAreaImages.map(({ url, isLoading, key, position, bucket, region }, index) => (
                <UploadsFile
                  url={url}
                  isLoading={isLoading}
                  key={key || index}
                  itemKey={key}
                  placeholderIcon={placeholderIcon}
                  type={type}
                  isSelectionMode={isSelectionMode}
                  onFileToggled={() => onFileToggled({ key, bucket, region, url })}
                  selectedFiles={selectedFiles}
                  onDeletePhoto={onDeletePhoto}
                  onOpen={
                    onItemOpen ? () => onItemOpen({ url, key, type, position, files: focusAreaImages, index }) : null
                  }
                  onReplace={isReplaceAvailable(url) ? () => onReplace({ url, key, index }) : null}
                  previewRenderer={previewRenderer ? () => previewRenderer({ url, key, type }) : null}
                />
              ))}
            </Grid>
          </Grid>
        )}
        {!isSelectionMode && (
          <Grid item md={2} id={createIdForTest({ componentId: 'patient-card-records', title, elementId: 'buttons' })}>
            <RecordsButtons
              handleUpload={handleUpload}
              onStartUploading={onStartUploading}
              handleDownloadZip={() => handleDownloadZipFiles(uploadedFiles, type)}
              isDisabled={isUploading || readOnly}
              activeDownload={!!uploadedFiles.length}
              onPlusClick={onPlusClick}
            />
          </Grid>
        )}
      </Grid>
    </div>
  )
}

export default Uploads
