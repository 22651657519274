import React from 'react'
import { CircularProgress } from '@material-ui/core'

const PracticeSearchLoadingState = () => (
  <div style={{ textAlign: 'center' }}>
    <CircularProgress size={16} />
  </div>
)

export default PracticeSearchLoadingState
