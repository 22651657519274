import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import Paused from 'components/common/icons/Paused'
import { ButtonBase, Grid } from '@material-ui/core'
import useOpenPatientCard from 'hooks/useOpenPatientCard'
import { isMobile } from 'utils/mobileUtils'
import { useDispatch, useSelector } from 'react-redux'
import Actions from 'actions'
import { CycleStatuses } from 'consts/treatmentConsts'

const useStyles = makeStyles(
  theme => ({
    root: {
      padding: ({ isMobile }) => (isMobile ? '10px' : '10px 16px'),
      backgroundColor: 'var(--bg-color-62)'
    },
    text: {
      textAlign: 'center',
      color: 'var(--text-color-20)'
    },
    icon: {
      transform: 'translateY(2px)',
      marginRight: 5
    },
    button: {
      color: 'var(--text-color-25)',
      fontWeight: 'bold',
      textDecoration: 'underline'
    }
  }),
  { isMobile: false }
)

const PausedScansBanner = () => {
  const classes = useStyles({ isMobile: isMobile() })
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const patient = useSelector(state => state.patientsReducer.patient)

  const openPatientCard = useOpenPatientCard()

  const resumeScans = useCallback(() => {
    const currentCycle = patient?.cycles.items[0]
    if (!currentCycle) {
      return
    }

    dispatch(
      Actions.setCycleStatus({
        patientId: patient.id,
        cycleId: currentCycle.id,
        status: CycleStatuses.Active
      })
    )
  }, [dispatch, patient])

  const handleButtonClick = useCallback(() => {
    if (isMobile()) {
      resumeScans()
    } else {
      openPatientCard()
    }
  }, [openPatientCard, resumeScans])

  return (
    <Grid container alignItems="center" className={classes.root}>
      <Grid item>
        <Paused color="var(--text-color-20)" className={classes.icon} />
      </Grid>
      <Grid item>
        <DazzedParagraph12 className={classes.text}>
          {isMobile() ? (
            <>{t('pages.patients.selectedPatient.timeline.banners.pausedScans.mobile.description')}&nbsp;</>
          ) : (
            <>{t('pages.patients.selectedPatient.timeline.banners.pausedScans.desktop.description')}&nbsp;&nbsp;</>
          )}
        </DazzedParagraph12>
      </Grid>
      <Grid item>
        <ButtonBase className={classes.button} onClick={handleButtonClick}>
          {isMobile()
            ? t('pages.patients.selectedPatient.timeline.banners.pausedScans.mobile.buttonText')
            : t('pages.patients.selectedPatient.timeline.banners.pausedScans.desktop.scanSettingsLink')}
        </ButtonBase>
      </Grid>
    </Grid>
  )
}

export default PausedScansBanner
