import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { Grid, IconButton } from '@material-ui/core'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import Cog from 'components/common/icons/notificationCenter/Cog'
import { trackEvent } from 'utils/analyticsUtils'
import Tooltip from 'components/common/Tooltip'
import NotificationCenterQuickActions from './NotificationCenterQuickActions'

const useStyles = makeStyles(theme => ({
  notificationCenterTitle: {
    padding: '24px 16px 8px 16px'
  }
}))

const NotificationCenterTitle = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const hanldleSettingsButtonClicked = useCallback(() => {
    trackEvent('Notification Center - notification settings button clicked')
    window.open(window.location.origin + '/profile/account-settings/notification-settings', '_blank')
  }, [])

  return (
    <Grid container className={classes.notificationCenterTitle} alignItems="center" spacing={1}>
      <Grid item>
        <DazzedParagraph16 bold>{t('dialogs.notificationCenterDialog.header.title')}</DazzedParagraph16>
      </Grid>
      <Grid item>
        <Tooltip
          variant="dark"
          placement="bottom"
          arrow
          value={t('dialogs.notificationCenterDialog.header.notificationSettingsTooltip')}
        >
          <IconButton size="small" onClick={hanldleSettingsButtonClicked}>
            <Cog />
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid item style={{ flex: 1 }} />
      <Grid item>
        <NotificationCenterQuickActions />
      </Grid>
    </Grid>
  )
}

export default NotificationCenterTitle
