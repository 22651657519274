import { API } from 'aws-amplify'
import { useCallback } from 'react'
import jwtDecode from 'jwt-decode'
import { logError, logInfo } from 'utils/logUtils'
import { prettyError } from '@grin-rnd/grin-api-sdk/dist/utils/ErrorUtils'
import { hasTokenExpried } from 'utils/authUtils'

export default () => {
  const generateZoomJwt = useCallback(async () => {
    try {
      const token = await API.get('grinServerlessApi', `/platform/v1/zoom/token`)
      logInfo('Zoom token was generated')
      localStorage.setItem('zoomToken', token)
      return token
    } catch (ex) {
      logError('An error occured while trying to get zoom token', { error: prettyError(ex) })
    }
  }, [])

  const getZoomToken = useCallback(async () => {
    let token = localStorage.getItem('zoomToken')

    if (!token || hasTokenExpried(jwtDecode(token))) {
      token = await generateZoomJwt()
    }

    return token
  }, [generateZoomJwt])

  return {
    getZoomToken
  }
}
