import React from 'react'
import { useTranslation } from 'react-i18next'
import useRolePermissions from 'hooks/useRolePermissions'
import { AddDSOUser, AddMember } from 'components/common/icons'
import LinkButton from 'components/common/buttons/LinkButton'

const DSOAddMemberButton = ({ isDSO = false, onClick }) => {
  const { permissions } = useRolePermissions()

  const { t } = useTranslation()

  return (
    permissions.addDSOUser && (
      <LinkButton
        onClick={onClick}
        icon={isDSO ? <AddDSOUser /> : <AddMember />}
        label={t(`pages.accountSettings.dsoMembers.${isDSO ? 'addDSO' : 'addPracticeMembers'}`)}
      />
    )
  )
}

export default DSOAddMemberButton
