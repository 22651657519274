import React, { useMemo, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/styles'
import Actions from 'actions'
import { Table, TableBody, TableHead, TableRow, TableCell, Grid, CircularProgress } from '@material-ui/core'
import MembersTableRow from './MembersTableRow'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'

const useStyles = makeStyles({
  table: {
    marginBottom: 40
  },
  header: {
    color: 'var(--text-color-6)',
    fontSize: '12px'
  },
  headerLabel: {
    color: 'var(--text-color-62)'
  }
})

const MembersTable = ({
  practiceMembers,
  onCancelInvitation,
  onResendInvitation,
  onDeactivateUser,
  onActivateUser,
  onChangeAccessType,
  isLoading,
  hiddenColumns = []
}) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (!practiceMembers) {
      dispatch(Actions.requestPracticeMembers())
    }
  }, [dispatch, practiceMembers])

  const classes = useStyles()
  const { t } = useTranslation()
  const HEADERS = useMemo(
    () =>
      [
        { key: 'name', label: t('pages.accountSettings.practiceMembers.table.headers.name') },
        { key: 'accessType', label: t('pages.accountSettings.practiceMembers.table.headers.accessType') },
        { key: 'jobTitle', label: t('pages.accountSettings.practiceMembers.table.headers.jobTitle') },
        { key: 'dateAdded', label: t('pages.accountSettings.practiceMembers.table.headers.dateAdded') },
        { key: 'status', label: t('pages.accountSettings.practiceMembers.table.headers.status') },
        { key: 'actions', label: '' }
      ].filter(column => !hiddenColumns.includes(column.key)),
    [t, hiddenColumns]
  )

  return isLoading ? (
    <Grid container justifyContent="center">
      <CircularProgress color="secondary" />
    </Grid>
  ) : (
    <Table className={classes.table}>
      <TableHead className={classes.header}>
        <TableRow>
          {HEADERS.map(({ label, key }) => (
            <TableCell key={key}>
              <DazzedParagraph12 thickness="strong" className={classes.headerLabel}>
                {label}
              </DazzedParagraph12>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {practiceMembers?.map(member => (
          <MembersTableRow
            key={member.id}
            member={member}
            colums={HEADERS}
            onCancelInvitation={onCancelInvitation}
            onResendInvitation={onResendInvitation}
            onDeactivateUser={onDeactivateUser}
            onActivateUser={onActivateUser}
            onChangeAccessType={onChangeAccessType}
          />
        ))}
      </TableBody>
    </Table>
  )
}

export default MembersTable
