import { cloneDeep, isEmpty, set, keyBy } from 'lodash'
import Actions from '../actions'
import { addTagIfAbsent, updateDoctorTagCounter } from '../utils/tagUtils'
import { removeWhere, updateWhere, replaceItem } from '../utils/arrayUtils'
import { mapToMemberObject } from 'utils/mappers/doctorsMappers'

const initialState = {
  doctor: {},
  accountOwnerId: null,
  showConfirmDisableAccount: false,
  isLoading: false,
  doctorPlans: {},
  showWelcomePopup: false,
  disableError: null,
  stats: {
    isLoading: false,
    dashboardUrl: null
  },
  newDashboardStats: {
    isLoading: false,
    dashboardUrls: null
  },
  grinPlan: {},
  systemTags: {
    isLoading: false,
    data: [],
    dictionary: {}
  },
  selectedTemplate: {
    text: '',
    title: ''
  },
  systemTemplates: {
    isLoading: false,
    data: null
  },
  customTemplates: {
    isLoading: false,
    data: null
  },
  welcomeMessages: {
    isLoading: false,
    data: null,
    rm: {
      isLoading: false
    },
    rc: {
      isLoading: false
    },
    serviceAccount: {
      isLoading: false
    }
  },
  areaOfCoverage: {
    isLoading: false,
    data: null
  },
  practiceMembers: {
    isLoading: false,
    data: null,
    errors: {}
  },
  zipCoordinates: {
    isLoading: false,
    data: {}
  },
  customTagsLoadingStatus: null,
  mfaSettings: {
    setupModal: {
      isOpen: false,
      methodType: null,
      totpCode: null
    },
    disableMethodModal: {
      isOpen: false,
      methodType: null
    }
  },
  activateMFAModal: {
    isOpen: false,
    wasDisplayed: false
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.SIGNOUT_RECEIVED:
      return initialState
    case Actions.DOCTOR_DETAILS_REQUESTED:
      return {
        ...state,
        isLoading: true
      }
    case Actions.DOCTOR_DETAILS_RECEIVED:
      if (isEmpty(action.payload.doctor)) return state

      return {
        ...state,
        isLoading: false,
        doctor: action.payload.doctor
      }
    case Actions.CHANGE_PROFILE_ITEM:
      return {
        ...state,
        doctor: set(cloneDeep(state.doctor), action.payload?.key, action.payload?.value)
      }
    case Actions.SIGNIN_SIDE_EFFECTS_COMPLETED:
      return {
        ...state,
        doctor: action.payload?.doctor
      }
    case Actions.CREATE_DOCTOR_RECEIVED:
      return {
        ...state,
        doctor: action.payload?.doctor
      }
    case Actions.UPDATE_DOCTOR_DETAILS_REQUESTED:
    case Actions.UPDATE_BILLING_INFO_REQUESTED:
      return {
        ...state,
        doctor: {
          ...state.doctor,
          ...action.payload
        },
        isLoading: true
      }
    case Actions.REQUEST_UPDATE_APP_SETTINGS:
      return {
        ...state,
        isLoading: true
      }
    case Actions.UPDATE_DOCTOR_DETAILS_RECEIVED:
      return {
        ...state,
        doctor: {
          ...state.doctor,
          ...action.payload?.doctor,
          patients: state.doctor.patients
        },
        isLoading: false
      }
    case Actions.TIMEZONE_UPDATED:
      return {
        ...state,
        doctor: { ...state.doctor, user: { ...state.doctor.user, ...action.payload } }
      }
    case Actions.DOCTOR_PLANS_RECEIVED:
      return {
        ...state,
        doctorPlans: action.payload
      }
    case Actions.FETCH_REJECTED:
      return {
        ...state,
        isLoading: false,
        stats: {
          ...state.stats,
          isLoading: false
        }
      }
    case Actions.SET_WELCOME_POPUP_STATE:
      return {
        ...state,
        showWelcomePopup: action.payload
      }
    case Actions.UPSERT_FEATURE_FLAGS_RECEIVED:
      return {
        ...state,
        doctor: {
          ...state.doctor,
          user: {
            ...state.doctor.user,
            featureFlags: action.payload
          }
        }
      }
    case Actions.UPDATE_MESSAGING_PREFERENCES_REQUESTED:
      return {
        ...state,
        isLoading: true
      }
    case Actions.FETCH_EMBEDDED_DASHBOARD_URL:
      return {
        ...state,
        stats: {
          ...state.stats,
          isLoading: true
        }
      }
    case Actions.EMBEDDED_DASHBOARD_URL_FAILED:
      return {
        ...state,
        stats: {
          ...state.stats,
          isLoading: false
        }
      }
    case Actions.EMBEDDED_DASHBOARD_URL_RECEIVED:
      return {
        ...state,
        stats: {
          ...state.stats,
          isLoading: false,
          dashboardUrl: action.payload
        }
      }
    case Actions.FETCH_NEW_DOCTOR_DASHBOARD_URL:
      return {
        ...state,
        newDashboardStats: {
          ...state.newDashboardStats,
          isLoading: true
        }
      }
    case Actions.NEW_DOCTOR_DASHBOARD_URL_FAILED:
      return {
        ...state,
        newDashboardStats: {
          ...state.newDashboardStats,
          isLoading: false
        }
      }
    case Actions.NEW_DOCTOR_DASHBOARD_URL_RECEIVED:
      return {
        ...state,
        newDashboardStats: {
          ...state.newDashboardStats,
          isLoading: false,
          dashboardUrls: action.payload
        }
      }
    case Actions.GRIN_PLANS_RECEIVED:
      return {
        ...state,
        grinPlan: action.payload[state.doctor.user.grinPlanKey]
      }
    case Actions.UPGRADE_PLAN_RECEIVED:
      return {
        ...state,
        grinPlan: action.payload.grinPlan,
        doctor: {
          ...state.doctor,
          user: {
            ...state.doctor.user,
            grinPlanKey: action.payload.grinPlanKey,
            planOverrides: JSON.stringify(action.payload.planOverrides)
          }
        }
      }
    case Actions.FETCH_DOCTOR_TAGS:
      return {
        ...state,
        customTagsLoadingStatus: 'loading'
      }
    case Actions.DOCTOR_TAGS_RECEIVED:
      return {
        ...state,
        doctor: {
          ...state.doctor,
          tags: {
            ...state.doctor?.tags,
            items: action.payload
          }
        },
        customTagsLoadingStatus: initialState.customTagsLoadingStatus
      }
    case Actions.DOCTOR_TAGS_FAILED: {
      return {
        ...state,
        customTagsLoadingStatus: 'error'
      }
    }
    case Actions.ASSIGN_PATIENT_TAG:
      return {
        ...state,
        doctor: updateDoctorTagCounter(state.doctor, action.payload.tag, 1)
      }
    case Actions.ASSIGN_PATIENT_TAG_RECEIVED:
      return {
        ...state,
        doctor: addTagIfAbsent(state.doctor, action.payload.tag)
      }
    case Actions.REMOVE_PATIENT_TAG:
      return {
        ...state,
        doctor: updateDoctorTagCounter(state.doctor, action.payload.tag, -1)
      }
    case Actions.FETCH_STATIC_DATA:
      return {
        ...state,
        systemTags: {
          ...state.systemTags,
          isLoading: true
        },
        systemTemplates: {
          ...state.systemTemplates,
          isLoading: true
        },
        isLoading: true
      }
    case Actions.FETCH_STATIC_DATA_RECEIVED:
      return {
        ...state,
        systemTags: {
          ...state.systemTags,
          isLoading: false,
          data: action.payload.systemTags,
          dictionary: keyBy(action.payload.systemTags, 'value')
        },
        systemTemplates: {
          ...state.systemTemplates,
          data: action.payload.systemTemplates,
          isLoading: false
        },
        isLoading: false
      }
    case Actions.FETCH_STATIC_DATA_FAILED: {
      return {
        ...state,
        systemTags: {
          ...state.systemTags,
          isLoading: false
        },
        systemTemplates: {
          ...state.systemTemplates,
          isLoading: false
        },
        isLoading: false
      }
    }
    case Actions.DELETE_DOCTOR_TAG:
      return {
        ...state,
        doctor: {
          ...state.doctor,
          tags: {
            ...state.doctor?.tags,
            items: updateWhere(
              state.doctor?.tags?.items || [],
              tag => tag.id === action.payload.id,
              tag => {
                tag.isDeleted = true
              }
            )
          }
        }
      }
    case Actions.DELETE_DOCTOR_TAG_RECEIVED:
      return {
        ...state,
        doctor: {
          ...state.doctor,
          tags: {
            ...state.doctor?.tags,
            items: replaceItem(state.doctor.tags.items, action.payload)
          }
        }
      }
    case Actions.GET_AREA_OF_COVERAGE:
      return {
        ...state,
        areaOfCoverage: {
          ...state.areaOfCoverage,
          isLoading: true
        }
      }
    case Actions.GET_AREA_OF_COVERAGE_RECEIVED:
      return {
        ...state,
        areaOfCoverage: {
          ...state.areaOfCoverage,
          isLoading: false,
          data: action.payload.areaOfCoverage
        }
      }
    case Actions.GET_AREA_OF_COVERAGE_FAILED:
      return {
        ...state,
        areaOfCoverage: {
          ...state.areaOfCoverage,
          isLoading: false
        }
      }
    case Actions.DELETE_PATIENT_RECEIVED:
      return {
        ...state,
        doctor: {
          ...state.doctor,
          patients: {
            ...state.doctor.patients,
            items: updateWhere(
              state.doctor.patients.items,
              patient => patient.id === action.payload.patientId,
              patient => (patient._deleted = true)
            )
          }
        }
      }
    case Actions.INVITE_NEW_PATIENT_RECEIVED:
      return {
        ...state,
        doctor: {
          ...state.doctor,
          patients: {
            ...state.doctor.patients,
            items: [...state.doctor.patients.items, { id: action.payload.patientId, _deleted: null }]
          }
        }
      }
    case Actions.UPDATE_SELECTED_TEMPLATE:
      return {
        ...state,
        selectedTemplate: action.payload
      }
    case Actions.CREATE_MESSAGE_TEMPLATE:
    case Actions.UPDATE_MESSAGE_TEMPLATE:
      return {
        ...state,
        isLoading: true
      }
    case Actions.CREATE_MESSAGE_TEMPLATE_RECEIVED:
      return {
        ...state,
        customTemplates: {
          data: [...state.customTemplates?.data, action.payload]
        },
        selectedTemplate: action.payload,
        isLoading: false
      }
    case Actions.DUPLICATE_MESSAGE_TEMPLATE_RECEIVED:
      return {
        ...state,
        customTemplates: {
          data: [...state.customTemplates?.data, action.payload]
        },
        selectedTemplate: action.payload,
        isLoading: false
      }
    case Actions.UPDATE_MESSAGE_TEMPLATE_RECEIVED:
      return {
        ...state,
        customTemplates: {
          data: state.customTemplates.data.map(t => (t.id === action.payload.id ? action.payload : t))
        },
        isLoading: false
      }
    case Actions.DELETE_MESSAGE_TEMPLATE_RECEIVED:
      return {
        ...state,
        customTemplates: {
          data: state.customTemplates.data.filter(t => t.id !== action.payload.id)
        },
        isLoading: false
      }
    case Actions.CREATE_MESSAGE_TEMPLATE_FAILED:
    case Actions.UPDATE_MESSAGE_TEMPLATE_FAILED:
      return {
        ...state,
        isLoading: false
      }
    case Actions.FETCH_CUSTOM_TEMPLATES:
      return {
        ...state,
        customTemplates: {
          ...state.customTemplates
        },
        isLoading: true
      }
    case Actions.CUSTOM_TEMPLATES_RECEIVED:
      return {
        ...state,
        customTemplates: {
          data: action.payload
        },
        isLoading: false
      }
    case Actions.CUSTOM_TEMPLATES_FAILED:
      return {
        ...state,
        isLoading: false
      }
    case Actions.FEATURE_FLAGS_RECEIVED:
      return {
        ...state,
        doctor: {
          ...state.doctor,
          user: {
            ...state.doctor.user,
            featureFlags: {
              ...state.doctor.user.featureFlags,
              ...action.payload
            }
          }
        }
      }
    case Actions.REQUEST_PRACTICE_MEMBERS:
    case Actions.DEACTIVATE_PRACTICE_MEMBER:
    case Actions.ACTIVATE_PRACTICE_MEMBER:
      return {
        ...state,
        practiceMembers: {
          ...state.practiceMembers,
          isLoading: true
        }
      }
    case Actions.PRACTICE_MEMBERS_RECEIVED:
      return {
        ...state,
        practiceMembers: {
          ...state.practiceMembers,
          isLoading: false,
          data: action.payload
        }
      }
    case Actions.PRACTICE_MEMBERS_FAILED:
      return {
        ...state,
        practiceMembers: {
          ...state.practiceMembers,
          isLoading: false
        }
      }
    case Actions.INVITE_PRACTICE_MEMBER:
      return {
        ...state,
        practiceMembers: {
          ...state.practiceMembers,
          isLoading: true,
          errors: initialState.practiceMembers.errors
        }
      }
    case Actions.INVITE_PRACTICE_MEMBER_RECEIVED:
      return {
        ...state,
        practiceMembers: {
          ...state.practiceMembers,
          data: state.practiceMembers.data.concat(mapToMemberObject(action.payload)),
          isLoading: false
        }
      }
    case Actions.INVITE_PRACTICE_MEMBER_FAILED:
    case Actions.CHANGE_PRACTICE_MEMBER_ACCESS_FAILED:
    case Actions.ACTIVATE_PRACTICE_MEMBER_FAILED:
    case Actions.DEACTIVATE_PRACTICE_MEMBER_FAILED:
      return {
        ...state,
        practiceMembers: {
          ...state.practiceMembers,
          isLoading: false
        }
      }
    case Actions.PRACTICE_MEMBER_ERRORS_RECEIVED:
      return {
        ...state,
        practiceMembers: {
          ...state.practiceMembers,
          isLoading: false,
          errors: action.payload
        }
      }
    case Actions.PRACTICE_MEMBER_RESET_ERRORS:
      return {
        ...state,
        practiceMembers: {
          ...state.practiceMembers,
          errors: initialState.practiceMembers.errors
        }
      }
    case Actions.CHANGE_PRACTICE_MEMBER_ACCESS_RECEIVED:
    case Actions.DEACTIVATE_PRACTICE_MEMBER_RECEIVED:
    case Actions.ACTIVATE_PRACTICE_MEMBER_RECEIVED:
      return {
        ...state,
        practiceMembers: {
          data: updateWhere(
            state.practiceMembers.data || [],
            practiceMember => practiceMember.id === action.payload.id,
            practiceMember => {
              practiceMember.accessType = action.payload.accessType
              practiceMember.status = action.payload.auth_status
            }
          ),
          isLoading: false
        }
      }
    case Actions.CANCEL_PRACTICE_MEMBER_INVITATION_RECEIVED:
      return {
        ...state,
        practiceMembers: {
          ...state.practiceMembers,
          data: removeWhere(state.practiceMembers.data || [], practiceMember => practiceMember.id === action.payload.id)
        }
      }
    case Actions.RESEND_PRACTICE_MEMBER_INVITATION_RECEIVED:
      return {
        ...state,
        practiceMembers: {
          ...state.practiceMembers,
          data: updateWhere(
            state.practiceMembers.data || [],
            practiceMember => practiceMember.id === action.payload.id,
            practiceMember => {
              practiceMember.firstName = action.payload.firstName
              practiceMember.lastName = action.payload.lastName
              practiceMember.email = action.payload.email
              practiceMember.name = `${action.payload.firstName} ${action.payload.lastName}`
            }
          )
        }
      }
    case Actions.GET_ZIP_COORDINATES:
      return {
        ...state,
        zipCoordinates: {
          ...state.zipCoordinates,
          isLoading: true
        }
      }
    case Actions.ZIP_COORDINATES_RECEIVED:
      return {
        ...state,
        zipCoordinates: {
          ...state.zipCoordinates,
          data: action.payload,
          isLoading: false
        }
      }
    case Actions.FETCH_WELCOME_MESSAGES:
      return {
        ...state,
        welcomeMessages: {
          ...state.welcomeMessages,
          isLoading: true
        }
      }
    case Actions.CREATE_WELCOME_MESSAGE:
    case Actions.UPDATE_WELCOME_MESSAGE: {
      const program = action.payload.program.toLowerCase()
      return {
        ...state,
        welcomeMessages: {
          ...state.welcomeMessages,
          [program]: {
            ...state.welcomeMessages[program],
            isLoading: true
          }
        }
      }
    }
    case Actions.WELCOME_MESSAGES_RECEIVED:
      return {
        ...state,
        welcomeMessages: {
          ...state.welcomeMessages,
          isLoading: false,
          data: action.payload || []
        }
      }
    case Actions.CREATE_WELCOME_MESSAGE_RECEIVED: {
      const program = action.payload.program.toLowerCase()
      return {
        ...state,
        welcomeMessages: {
          ...state.welcomeMessages,
          data: [...state.welcomeMessages.data, action.payload.welcomeMessage],
          [program]: {
            ...state.welcomeMessages[program],
            isLoading: false
          }
        }
      }
    }
    case Actions.UPDATE_WELCOME_MESSAGE_RECEIVED:
      return {
        ...state,
        welcomeMessages: {
          ...state.welcomeMessages,
          rc: {
            isLoading: false
          },
          rm: {
            isLoading: false
          },
          serviceAccount: {
            isLoading: false
          },
          data: updateWhere(
            state.welcomeMessages.data || [],
            welcomeMessage => welcomeMessage.id === action.payload.id,
            welcomeMessage => {
              welcomeMessage.firstName = action.payload.firstName
              welcomeMessage._version = action.payload._version
              welcomeMessage.text = action.payload.text
            }
          )
        }
      }
    case Actions.FETCH_WELCOME_MESSAGES_FAILED:
    case Actions.CREATE_WELCOME_MESSAGE_FAILED:
    case Actions.UPDATE_WELCOME_MESSAGE_FAILED:
      return {
        ...state,
        welcomeMessages: {
          ...state.welcomeMessages,
          isLoading: false,
          rc: {
            isLoading: false
          },
          rm: {
            isLoading: false
          },
          serviceAccount: {
            isLoading: false
          }
        }
      }
    case Actions.SET_MFA_SETUP_MODAL_OPEN:
      return {
        ...state,
        mfaSettings: {
          ...state.mfaSettings,
          setupModal: {
            ...state.mfaSettings.setupModal,
            isOpen: action.payload.isOpen,
            methodType: action.payload?.type,
            totpCode: action.payload?.totpCode,
            isAppMethodAlreadySetup: action.payload?.isAppMethodAlreadySetup
          }
        }
      }
    case Actions.SET_DISABLE_MFA_METHOD_MODAL_OPEN:
      return {
        ...state,
        mfaSettings: {
          ...state.mfaSettings,
          disableMethodModal: {
            ...state.mfaSettings.disableMethodModal,
            isOpen: action.payload.isOpen,
            methodType: action.payload?.type
          }
        }
      }
    case Actions.SET_TOTP_QR_CODE:
      return {
        ...state,
        mfaSettings: {
          ...state.mfaSettings,
          setupModal: {
            ...state.mfaSettings.setupModal
          }
        }
      }
    case Actions.SET_ACTIVATE_MFA_MODAL_OPEN:
      return {
        ...state,
        activateMFAModal: {
          ...state.activateMFAModal,
          isOpen: action.payload.isOpen,
          wasDisplayed: action.payload.isOpen || state.activateMFAModal.wasDisplayed
        }
      }
    default:
      return state
  }
}
