import React from 'react'
import { makeStyles } from '@material-ui/styles'
import useNotificationActions from './useNotificationActions'
import { Grid, Grow } from '@material-ui/core'
import NotificationActionItem from './NotificationActionItem'

const useStyles = makeStyles(theme => ({
  notificationActionsRoot: {
    background: '#ffffff',
    boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.25)',
    borderRadius: 6,
    padding: '0 4px'
  }
}))

/**
 * @param {Object} props
 * @param {GrinNotification} props.grinNotification
 * @param {boolean} props.isVisible
 */
const NotificationActions = ({ grinNotification, isVisible = false }) => {
  const classes = useStyles()

  const { actions, isLoading } = useNotificationActions({ grinNotification })

  return !actions.length ? (
    <></>
  ) : (
    <Grow in={isVisible}>
      <Grid
        container
        className={classes.notificationActionsRoot}
        alignItems="center"
        justifyContent="center"
        style={{ lineHeight: 0.8 }}
      >
        {actions.map((action, index) => (
          <Grid item key={action.key} style={{ lineHeight: 0.8 }}>
            <NotificationActionItem
              actionKey={action.key}
              icon={action.icon}
              tooltip={action.tooltip}
              onClick={action.onClick}
              disabled={isLoading}
              analyticsPayload={{
                grinNotificationId: grinNotification.id,
                grinNotificationType: grinNotification.type,
                status: grinNotification.status
              }}
            />
          </Grid>
        ))}
      </Grid>
    </Grow>
  )
}

export default NotificationActions
