import * as React from 'react'

export default () => (
  <svg
    width="14"
    height="13"
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginTop: 5 }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.5 3C0.5 1.61929 1.61929 0.5 3 0.5H11C12.3807 0.5 13.5 1.61929 13.5 3V7C13.5 8.38071 12.3807 9.5 11 9.5H8.45911C8.26306 10.6878 7.37924 11.6706 6.18098 11.9701L4.12127 12.4851L4 12L3.64645 11.6464C4.19297 11.0999 4.5 10.3587 4.5 9.58579V9.5H3C1.61929 9.5 0.5 8.38071 0.5 7V3ZM5.15333 11.1963L5.93845 11C6.85618 10.7706 7.5 9.94598 7.5 9C7.5 8.72386 7.72386 8.5 8 8.5H11C11.8284 8.5 12.5 7.82843 12.5 7V3C12.5 2.17157 11.8284 1.5 11 1.5H3C2.17157 1.5 1.5 2.17157 1.5 3V7C1.5 7.82843 2.17157 8.5 3 8.5H5C5.27614 8.5 5.5 8.72386 5.5 9V9.58579C5.5 10.1465 5.37968 10.6949 5.15333 11.1963ZM10.5 4.5H3.5V3.5H10.5V4.5ZM3.5 6.5H8V5.5H3.5V6.5Z"
      fill="#3C52EF"
    />
  </svg>
)
