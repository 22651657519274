import React from 'react'
import { MessagePatient as MessagePatientIcon } from 'components/common/icons'
import { useTranslation } from 'react-i18next'
import GenericGuidelineAction from './GenericGuidelineAction'

const MessagePatient = ({ isChecked }) => {
  const { t } = useTranslation()

  return (
    isChecked && (
      <GenericGuidelineAction
        text={t('dialogs.hiPatientGuidelinesViewer.actions.messagePatient')}
        icon={<MessagePatientIcon />}
      />
    )
  )
}

export default MessagePatient
