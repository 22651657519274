import React from 'react'
import { useTranslation } from 'react-i18next'
import Tooltip from 'components/common/Tooltip'
import { MessageReadStatuses } from 'consts/chatConsts'
import MessageDoubleCheckmarks from 'components/common/icons/MessageDoubleCheckmarks'

const MessageReadStatus = ({ readStatus, color }) => {
  const { t } = useTranslation()

  return readStatus === MessageReadStatuses.Read ? (
    <Tooltip placement="bottom" value={t('pages.patients.selectedPatient.chat.messageReadStatus.messageWasRead')}>
      <MessageDoubleCheckmarks color={color} />
    </Tooltip>
  ) : (
    <></>
  )
}

export default MessageReadStatus
