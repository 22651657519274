import React, { useEffect } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import PhoneNumberInput from 'components/common/PhoneNumberInput'

const useStyles = makeStyles(theme => ({
  breakSpacesText: {
    whiteSpace: 'pre'
  }
}))

const InitialStep = ({ phoneNumber, setPhoneNumber, errorMessage }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { cognitoUser } = useSelector(state => state.authReducer)

  useEffect(() => {
    if (cognitoUser.attributes?.phone_number && !phoneNumber) {
      setPhoneNumber(cognitoUser.attributes?.phone_number)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid container direction="column">
      <Grid item style={{ marginBottom: 40 }}>
        <DazzedParagraph14 strong className={classes.breakSpacesText}>
          {t('pages.accountSettings.mfaSettings.setupModal.sms.description')}
        </DazzedParagraph14>
      </Grid>
      <Grid item>
        <PhoneNumberInput
          title={t('pages.accountSettings.mfaSettings.setupModal.sms.phoneNumberLabel')}
          style={{ bright: true, thick: true }}
          value={phoneNumber}
          validationRules={{ required: true }}
          setValue={setPhoneNumber}
          errorMessage={errorMessage}
          errorMessageColor="var(--text-color-17)"
        />
      </Grid>
    </Grid>
  )
}

export default InitialStep
