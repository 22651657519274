import { combineEpics } from 'redux-observable'
import * as appEpic from './appEpic'
import * as profileEpic from './profileEpic'
import * as patientsEpic from './patientsEpic'
import * as authEpic from './authEpic'
import * as chatEpic from './chatEpic'
import * as treatmentEpic from './treatmentEpic'
import * as logEpic from './logEpic'
import * as supportEpic from './supportEpic'
import * as releaseNotesEpic from './releaseNotesEpic'
import * as billingEpic from './billingEpic'
import * as productEpic from './productEpic'
import * as quickSignUpEpic from './quickSignUpEpic'
import * as featureFlagsEpic from './featureFlagsEpic'
import * as pubsubEpic from './pubsubEpic'
import * as practiceMembersEpic from './practiceMembersEpic'
import * as pixelEpic from './pixelEpic'
import * as taskManagerEpic from './taskManagerEpic'
import * as announcementsEpic from './announcementsEpic'
import * as performanceEpic from './performanceEpic'
import * as rcDashboardEpic from './rcDashboardEpic'
import * as practiceEpic from './practiceEpic'
import * as giEpic from './giEpic'
import * as hiEpic from './hiEpic'
import * as pmsEpic from './pmsEpic'
import * as autochartEpic from './autochartEpic'
import * as multiPractice from './multiPracticeEpic'
import * as referralsEpic from './referralsEpic'
import * as qaEpic from './qaEpic'
import * as timelineEpic from './timelineEpic'
import * as txTrackerEpic from './txTrackerEpic'
import * as notificationCenterEpic from './notificationCenterEpic'

export default combineEpics(
  ...Object.values({
    ...appEpic,
    ...profileEpic,
    ...patientsEpic,
    ...authEpic,
    ...chatEpic,
    ...treatmentEpic,
    ...logEpic,
    ...supportEpic,
    ...releaseNotesEpic,
    ...featureFlagsEpic,
    ...billingEpic,
    ...productEpic,
    ...quickSignUpEpic,
    ...pubsubEpic,
    ...practiceMembersEpic,
    ...pixelEpic,
    ...taskManagerEpic,
    ...announcementsEpic,
    ...performanceEpic,
    ...rcDashboardEpic,
    ...practiceEpic,
    ...giEpic,
    ...hiEpic,
    ...pmsEpic,
    ...autochartEpic,
    ...multiPractice,
    ...referralsEpic,
    ...qaEpic,
    ...timelineEpic,
    ...txTrackerEpic,
    ...notificationCenterEpic
  })
)
