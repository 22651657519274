import React from 'react'
import InvalidResultsPrompt from '../InvalidResultsPrompt'
import { ScanSummaryStatus } from 'consts/scanSummaryConsts'
import PendingState from '../States/PendingState'
import FailedState from '../States/FailedState'
import ArchesViewer from '../../ArchesViewer/ArchesViewer'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    position: 'relative',
    marginRight: 10
  },
  archesViewer: {
    height: '100%',
    width: '100%',
    backgroundColor: 'var(--bg-color-76)',
    borderRadius: 32,
    marginBottom: 10
  }
}))

const STLView = ({
  grinScan,
  parsedTxTrackerData,
  handleOpenRequestRerunModal,
  archModels,
  calculatedTreatmentType,
  scanNumber,
  isSmallerScreen,
  initAlignerNumber,
  endAlignerNumber,
  onOpenRequestRerunModal,
  onSelectionChanged
}) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <InvalidResultsPrompt
        grinScan={grinScan}
        treatmentType={calculatedTreatmentType}
        originalScanTxTrackerDetails={parsedTxTrackerData.originalScanDetails}
        rerunRequest={parsedTxTrackerData.rerunRequest}
        onRerunRequestClicked={onOpenRequestRerunModal}
      />
      {grinScan?.treatmentTrackerStatus === ScanSummaryStatus.Pending ? (
        <PendingState />
      ) : grinScan?.treatmentTrackerStatus === ScanSummaryStatus.Failed || !grinScan?.treatmentTrackerStatus ? (
        <FailedState
          onRerunRequestClicked={onOpenRequestRerunModal}
          requestStatus={parsedTxTrackerData?.rerunRequest?.status}
          requestedAt={parsedTxTrackerData?.rerunRequest?.requestedAt}
        />
      ) : (
        <>
          <div className={classes.archesViewer}>
            <ArchesViewer
              isCompareStlsModeOn
              baseArchModels={archModels?.baseArchModels}
              endArchModels={archModels?.endArchModels}
              currentArchModels={archModels?.currentArchModels}
              currentTxTrackerData={parsedTxTrackerData}
              treatmentType={calculatedTreatmentType}
              alignerNumber={grinScan?.applianceIndex}
              scanNumber={scanNumber}
              isSmallerScreen={isSmallerScreen}
              initAlignerNumber={initAlignerNumber}
              endAlignerNumber={endAlignerNumber}
              onSceneRemoved={onSelectionChanged}
            />
          </div>
        </>
      )}
    </div>
  )
}
export default STLView
