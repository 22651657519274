import React, { useCallback, useEffect, useState } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { useTranslation } from 'react-i18next'
import FormInput from 'components/common/FormInput'
import LinkButton from 'components/common/buttons/LinkButton'
import useMFA from 'components/Auth/useMFA'
import { trackEvent } from 'utils/analyticsUtils'
import Countdown from 'components/common/CountdownV2'

const useStyles = makeStyles(theme => ({
  divider: {
    height: 160,
    width: 1,
    backgroundColor: 'var(--border-color-28)'
  },
  breakSpacesText: {
    whiteSpace: 'pre'
  },
  disabledResendLabel: {
    color: '#ABB0B8'
  }
}))

const COOLDOWN_TIME = 30

const ConfirmationStep = ({ phoneNumber, confirmationCode, setConfirmationCode, errorMessage }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { initSMSVerification } = useMFA()

  const [targetCooldown, setTargetCooldown] = useState()
  const [localErrorMessage, setLocalErrorMessage] = useState('')

  const handleResendClicked = useCallback(async () => {
    setLocalErrorMessage('')

    // Remove all + characters and add a single one at the start
    let correctedNumber = phoneNumber.replace(/\+/g, '')
    correctedNumber = `+${correctedNumber}`

    trackEvent('MFA Settings - SMS setup - resend clicked')
    const result = await initSMSVerification({ phoneNumber: correctedNumber })
    if (!result.success) {
      setLocalErrorMessage(result.error.message || t('pages.accountSettings.mfaSettings.setupModal.confirmationError'))
    } else {
      setTargetCooldown(new Date(new Date().getTime() + COOLDOWN_TIME * 1000))
    }
  }, [initSMSVerification, phoneNumber, t])

  useEffect(() => {
    setLocalErrorMessage(errorMessage)
  }, [errorMessage])

  return (
    <Grid container direction="column">
      <Grid item style={{ marginBottom: 20 }}>
        <Grid item>
          <DazzedParagraph14 strong className={classes.breakSpacesText}>
            {t('pages.accountSettings.mfaSettings.setupModal.sms.confirmationText')}
          </DazzedParagraph14>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <FormInput
          title={t('pages.accountSettings.mfaSettings.setupModal.sms.confirmationLabel')}
          style={{ bright: true, thick: true }}
          value={confirmationCode}
          validationRules={{ required: true }}
          setValue={value => setConfirmationCode(value)}
          errorMessage={localErrorMessage}
        />
      </Grid>
      <Grid item>
        <Countdown
          targetTime={targetCooldown}
          renderer={({ completed, seconds }) => (
            <LinkButton
              label={t('pages.accountSettings.mfaSettings.setupModal.sms.resendCode', {
                cooldown:
                  seconds > 0 ? t('pages.accountSettings.mfaSettings.setupModal.sms.seconds', { number: seconds }) : ''
              })}
              underline
              labelClassName={seconds > 0 ? classes.disabledResendLabel : ''}
              onClick={handleResendClicked}
              disabled={!completed}
            />
          )}
        />
      </Grid>
    </Grid>
  )
}

export default ConfirmationStep
