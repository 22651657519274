import { Grid, makeStyles } from '@material-ui/core'
import { EmailOutlined as EmailIcon } from '@material-ui/icons'
import actions from 'actions'
import ChangeUserEmailModal from 'components/Support/SupportDashboard/ChangeUserEmailModal'
import GrinHighlighter from 'components/common/GrinHighlighter'
import ProfilePicture from 'components/common/ProfilePicture'
import Tooltip from 'components/common/Tooltip'
import { Check1, Copy } from 'components/common/icons'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

const useStyles = makeStyles(theme => ({
  resultsItem: {
    fontSize: 12,
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 3,
    marginBottom: 10,
    cursor: 'pointer',
    transition: 'background 0.3s ease',
    '&:hover': {
      background: '#e4e4e4',
      borderRadius: 6
    }
  },
  name: {
    fontSize: 14,
    fontWeight: 'bold',
    marginRight: 5
  },
  email: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  avatar: {
    marginRight: 20
  },
  detailsContainer: {
    width: '90%',
    display: 'flex',
    alignItems: 'center'
  },
  actionContainer: {
    width: '10%',
    display: 'flex',
    justifyContent: 'end'
  }
}))

const DoctorResultItem = ({ searchTerm, doctorSM, setSelectedDoctor, copiedItemId, onCopyId }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [isChangeEmailModalOpened, setIsChangeEmailModalOpened] = useState(false)

  const isChangingEmail = useSelector(state => state.supportReducer.doctorsDashboard.isChangingEmail)

  const handleEmailChange = useCallback(
    ({ username, newEmail, resetPassword, sendWelcomeEmail }) =>
      dispatch(
        actions.changeDoctorEmail({
          doctorUsername: username,
          newEmail,
          resetPassword,
          sendWelcomeEmail
        })
      ),
    [dispatch]
  )

  return (
    <>
      <Grid container className={classes.resultsItem}>
        <Grid xs={11} className={classes.detailsContainer} onClick={() => setSelectedDoctor(doctorSM)}>
          <Grid item xs={2}>
            <ProfilePicture photo={null} name={doctorSM.name} size="medium" className={classes.avatar} />
          </Grid>
          <Grid item xs={7}>
            <Grid direction="column">
              <Grid>
                <GrinHighlighter
                  className={classes.name}
                  searchWords={[searchTerm]}
                  autoEscape={true}
                  textToHighlight={doctorSM.name}
                />
                <GrinHighlighter
                  searchWords={[searchTerm]}
                  autoEscape={true}
                  textToHighlight={`(${doctorSM.practiceName})`}
                />
              </Grid>
              <GrinHighlighter searchWords={[searchTerm]} autoEscape={true} textToHighlight={doctorSM.email} />
            </Grid>
          </Grid>
          <Grid item direction="column">
            <Grid className={classes.accessType}>
              {doctorSM.accessType ? t(`common.practiceMemberTypes.${doctorSM.accessType}`) : t('general.NA')}
            </Grid>
            <Grid>({doctorSM.status ? doctorSM.status : t('general.NA')})</Grid>
          </Grid>
        </Grid>
        <Grid xs={1} className={classes.actionContainer}>
          {doctorSM.id === copiedItemId ? (
            <Check1 color={'green'} />
          ) : (
            <Grid style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <Tooltip value={t('dialogs.quickActions.copyId')} placement="top" arrow>
                <Copy onClick={() => onCopyId({ id: doctorSM.id, itemType: 'doctor' })} />
              </Tooltip>
              <Tooltip value={t('dialogs.quickActions.changeEmail')} placement="top" arrow>
                <EmailIcon
                  style={{ fill: 'var(--text-color-3)', fontSize: 18 }}
                  onClick={() => setIsChangeEmailModalOpened(true)}
                />
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </Grid>
      <ChangeUserEmailModal
        isOpen={isChangeEmailModalOpened}
        userFullname={doctorSM.name}
        currentEmail={doctorSM.email}
        username={doctorSM.username}
        onSubmit={handleEmailChange}
        onCancel={() => setIsChangeEmailModalOpened(false)}
        isLoading={isChangingEmail}
        isDoctor={true}
      />
    </>
  )
}

export default DoctorResultItem
