import React, { useCallback, useState, useMemo, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import FormInput from 'components/common/FormInput'
import PrimaryButton from 'components/common/buttons/PrimaryButton'
import { isValidEmail } from 'utils/generalUtils'
import { MEMBER_TYPES } from 'consts/appConsts'
import { useDispatch } from 'react-redux'
import Actions from 'actions'
import AccessPicker from 'components/Profile/PracticeMembers/AddPracticeMember/AccessPicker'

const useStyles = makeStyles(theme => ({
  input: {
    border: '1px solid var(--border-color-11) !important',
    padding: '12px 16px',
    borderRadius: '4px',
    '&.error': {
      border: '1px solid var(--border-color-17) !important'
    },
    '& input': {
      fontSize: '12px !important',
      padding: '12px 4px',
      '&::placeholder': {
        fontSize: '12px',
        fontStyle: 'normal'
      }
    }
  },
  sectionTitle: {
    marginTop: 15,
    marginBottom: 5
  },
  buttonsContainer: {
    marginTop: 30
  }
}))

const NewMemberForm = ({
  isDSO = false,
  handleSubmit,
  handleCancel,
  errors = {},
  isLoading = false,
  existingFirstName = '',
  existingLastName = '',
  existingEmail = ''
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [firstName, setFirstName] = useState(existingFirstName)
  const [lastName, setLastName] = useState(existingLastName)
  const [email, setEmail] = useState(existingEmail)
  const [accessType, setAccessType] = useState(MEMBER_TYPES.member.key)
  const [localErrors, setLocalErrors] = useState({})

  const handleChangeEmail = useCallback(
    value => {
      if (localErrors.email) {
        const { email, ...rest } = localErrors
        setLocalErrors(rest)
      }
      setEmail(value?.toLowerCase())
    },
    [localErrors]
  )

  const onPrimaryClick = useCallback(() => {
    if (!isValidEmail(email)) {
      setLocalErrors({
        email: t('errors.invalidEmail')
      })
      return
    }
    handleSubmit({
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      email: email.trim(),
      accessType
    })
  }, [handleSubmit, firstName, lastName, email, accessType, t])

  const isPrimaryDisabled = useMemo(
    () => !firstName.trim() || !lastName.trim() || !email.trim(),
    [firstName, lastName, email]
  )

  useEffect(() => {
    setLocalErrors(errors)
  }, [errors])

  useEffect(() => {
    dispatch(Actions.practiceMemberResetErrors())
  }, [dispatch])

  return (
    <div>
      <DazzedParagraph12 thickness="bold" className={classes.sectionTitle}>
        {t(`pages.accountSettings.dsoMembers.addMember.form.${isDSO ? 'dsoName' : 'practiceMemberName'}`)}
      </DazzedParagraph12>
      <Grid container spacing={1}>
        <Grid item md={6}>
          <FormInput
            style={{ bright: true, thick: true }}
            value={firstName}
            placeholder={t('pages.accountSettings.dsoMembers.addMember.form.firstNamePlaceholder')}
            setValue={setFirstName}
            inputWrapperClassName={classes.input}
          />
        </Grid>
        <Grid item md={6}>
          <FormInput
            style={{ bright: true, thick: true }}
            value={lastName}
            placeholder={t('pages.accountSettings.dsoMembers.addMember.form.lastNamePlaceholder')}
            setValue={setLastName}
            inputWrapperClassName={classes.input}
          />
        </Grid>
      </Grid>
      <DazzedParagraph12 thickness="bold" className={classes.sectionTitle}>
        {t('pages.accountSettings.dsoMembers.addMember.form.email')}
      </DazzedParagraph12>
      <FormInput
        style={{ bright: true, thick: true }}
        value={email}
        placeholder={t('pages.accountSettings.dsoMembers.addMember.form.emailPlaceholder')}
        setValue={handleChangeEmail}
        inputWrapperClassName={classes.input}
        errorMessage={localErrors.email}
      />
      {!isDSO && <AccessPicker value={accessType} setValue={setAccessType} />}
      <Grid container justifyContent="center" spacing={3} className={classes.buttonsContainer}>
        <Grid item>
          <PrimaryButton
            label={t('general.cancel')}
            onClick={handleCancel}
            variant="outlined"
            width="180px"
            fontColor="var(--text-color-3)"
          />
        </Grid>
        <Grid item>
          <PrimaryButton
            label={t('pages.accountSettings.dsoMembers.addMember.form.sendInviteBtn')}
            onClick={onPrimaryClick}
            width="180px"
            disabled={isPrimaryDisabled}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default NewMemberForm
