import * as React from 'react'

const NeedsAttentionIcon = props => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="8.5" cy="8.5" r="7.5" stroke="#DB615D" strokeWidth="1.5" />
    <rect x="7.6665" y="4.83331" width="1.66667" height="5.16667" fill="#DB615D" />
    <rect x="7.6665" y="11.4999" width="1.66667" height="1.66667" fill="#DB615D" />
  </svg>
)

export default NeedsAttentionIcon
