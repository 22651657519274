import {
  ORTHO_PROGRAM,
  REMOTE_MONITORING_PROGRAM,
  REMOTE_CONSULTATION_PROGRAM,
  SERVICE_ACCOUNT_PROGRAM
} from './appConsts'

export const PATIENT_CARD_TABS = {
  timeline: {
    index: 0,
    programs: [ORTHO_PROGRAM]
  },
  prelimPlan: {
    index: 1,
    programs: [ORTHO_PROGRAM]
  },
  treatmentPlan: {
    index: 2,
    programs: [ORTHO_PROGRAM]
  },
  scanSettings: {
    index: 3,
    programs: [ORTHO_PROGRAM, REMOTE_CONSULTATION_PROGRAM, REMOTE_MONITORING_PROGRAM, SERVICE_ACCOUNT_PROGRAM]
  },
  appointments: {
    index: 4,
    programs: [ORTHO_PROGRAM]
  },
  medicalHistory: {
    index: 5,
    programs: [ORTHO_PROGRAM]
  },
  records: {
    index: 6,
    programs: [ORTHO_PROGRAM]
  },
  brief: {
    index: 7,
    programs: [ORTHO_PROGRAM, REMOTE_CONSULTATION_PROGRAM, REMOTE_MONITORING_PROGRAM, SERVICE_ACCOUNT_PROGRAM]
  },
  notes: {
    index: 8,
    programs: [ORTHO_PROGRAM, REMOTE_CONSULTATION_PROGRAM, REMOTE_MONITORING_PROGRAM, SERVICE_ACCOUNT_PROGRAM]
  },
  legalGuardian: {
    index: 9,
    programs: [ORTHO_PROGRAM, REMOTE_CONSULTATION_PROGRAM, REMOTE_MONITORING_PROGRAM, SERVICE_ACCOUNT_PROGRAM]
  },
  giTrackSettings: {
    index: 10,
    programs: [REMOTE_CONSULTATION_PROGRAM, REMOTE_MONITORING_PROGRAM, SERVICE_ACCOUNT_PROGRAM]
  },
  txTrackerSetup: {
    index: 11,
    programs: [REMOTE_CONSULTATION_PROGRAM, REMOTE_MONITORING_PROGRAM, SERVICE_ACCOUNT_PROGRAM]
  }
}

export const CONTENT_HEIGHT = window.screen.height > 1000 ? 450 : 400

export const UPPER_ARCH_FILENAME_PREFIX = 'upper-arch'
export const LOWER_ARCH_FILENAME_PREFIX = 'lower-arch'
export const UPPER_LOWER_ARCHES_FILENAME_PREFIX = 'upper-lower-arches'
export const LEFT_BITE_FILENAME_PREFIX = 'left-bite'
export const RIGHT_BITE_FILENAME_PREFIX = 'right-bite'

export const POSITION_KEYS = {
  [UPPER_ARCH_FILENAME_PREFIX]: 'upperArch',
  [LOWER_ARCH_FILENAME_PREFIX]: 'lowerArch',
  [UPPER_LOWER_ARCHES_FILENAME_PREFIX]: 'upperAndLowerArches',
  [RIGHT_BITE_FILENAME_PREFIX]: 'rightBite',
  [LEFT_BITE_FILENAME_PREFIX]: 'leftBite'
}
