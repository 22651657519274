import { AsyncStatus } from 'consts'
import Actions from '../actions'
import {
  addReferralToTimelineItem,
  addScanReviewToTimelineItem,
  extractMainFeedFields,
  updateTimelineItemGrinScans
} from 'utils/timelineV2Utils'
import { upsertItem } from 'utils/arrayUtils'
import { isMobile } from 'utils/mobileUtils'
import { DEFAULT_MINI_TIMELINE_THEME } from 'consts/timelineConsts'

export const TimelineVersions = {
  v1: 'v1',
  v2: 'v2'
}

const DEFAULT_TIMELINE_VERSION = TimelineVersions.v1

const initialState = {
  timelineVersion: DEFAULT_TIMELINE_VERSION,
  isTimelineV2: DEFAULT_TIMELINE_VERSION === TimelineVersions.v1,
  selectedTimelineItemId: null,
  mainFeed: {
    timelineItems: {
      loadStatus: null,
      nextToken: null,
      data: [],
      error: null
    }
  },
  miniTimeline: {
    theme: DEFAULT_MINI_TIMELINE_THEME
  },
  scanFeed: {
    loadStatus: null,
    timelineItem: {},
    switchScanWithAligners: {
      isLoading: false
    },
    oralRecords: {
      loadStatus: null,
      initialScan: null
    }
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.DOCTOR_DETAILS_RECEIVED: {
      const featureFlags = JSON.parse(action.payload?.accountOwner?.user?.featureFlags?.flags || '{}')
      const timelineVersion = !isMobile() && featureFlags.timelineV2 ? TimelineVersions.v2 : DEFAULT_TIMELINE_VERSION
      return {
        ...state,
        timelineVersion,
        isTimelineV2: timelineVersion === TimelineVersions.v2
      }
    }
    case Actions.FETCH_PATIENT_TIMELINE_ITEMS:
      return {
        ...state,
        mainFeed: {
          ...state.mainFeed,
          timelineItems: {
            ...initialState.mainFeed.timelineItems,
            loadStatus: AsyncStatus.Loading
          }
        }
      }
    case Actions.FETCH_PATIENT_TIMELINE_ITEMS_FAILED:
      return {
        ...state,
        mainFeed: {
          ...state.mainFeed,
          timelineItems: {
            ...state.mainFeed.timelineItems,
            loadStatus: AsyncStatus.Failed,
            error: action.payload
          }
        }
      }
    case Actions.FETCH_PATIENT_TIMELINE_ITEMS_RECEIVED:
      return {
        ...state,
        mainFeed: {
          ...state.mainFeed,
          timelineItems: {
            ...state.mainFeed.timelineItems,
            loadStatus: AsyncStatus.Completed,
            error: null,
            data: action.payload.data,
            nextToken: action.payload.nextToken
          }
        }
      }
    case Actions.SELECT_TIMELINE_ITEM:
      return {
        ...state,
        selectedTimelineItemId: action.payload.timelineItemId,
        scanFeed: initialState.scanFeed
      }
    case Actions.FETCH_TIMELINE_ITEM:
      return {
        ...state,
        scanFeed: {
          ...state.scanFeed,
          loadStatus: AsyncStatus.Loading
        }
      }
    case Actions.FETCH_TIMELINE_ITEM_RECEIVED:
      return {
        ...state,
        scanFeed: {
          ...state.scanFeed,
          loadStatus: AsyncStatus.Completed,
          timelineItem: action.payload.timelineItem
        }
      }
    case Actions.FETCH_TIMELINE_ITEM_FAILED:
      return {
        ...state,
        scanFeed: {
          ...state.scanFeed,
          timelineItem: initialState.scanFeed.timelineItem,
          loadStatus: AsyncStatus.Failed
        }
      }
    case Actions.UPDATE_GRIN_SCAN_APPLIANCE:
      return !state.isTimelineV2 || !action.payload.applianceIndex
        ? state
        : {
            ...state,
            scanFeed: {
              ...state.scanFeed,
              timelineItem: {
                ...state.scanFeed.timelineItem,
                payload: JSON.stringify({
                  ...JSON.parse(state.scanFeed.timelineItem.payload || '{}'),
                  alignerNumber: action.payload.applianceIndex
                })
              }
            }
          }
    case Actions.REQUEST_SEND_SCAN_REVIEW: {
      if (!state.isTimelineV2 || action.payload.isRetry) {
        return state
      }

      const { scanReviewId, scan, blob, uploadingDate, reviewer } = action.payload

      return {
        ...state,
        scanFeed: {
          ...state.scanFeed,
          timelineItem: addScanReviewToTimelineItem({
            scanId: scan.id,
            timelineItem: state.scanFeed.timelineItem,
            scanReview: {
              id: scanReviewId,
              createdAt: uploadingDate,
              isLocal: true,
              video: blob,
              reviewer
            }
          })
        }
      }
    }
    case Actions.NEW_SCAN_REVIEW_RECEIVED: {
      if (!state.isTimelineV2) {
        return state
      }

      const { scanReview } = action.payload
      return {
        ...state,
        scanFeed: {
          ...state.scanFeed,
          timelineItem: addScanReviewToTimelineItem({
            scanId: scanReview.grinScanId,
            timelineItem: state.scanFeed.timelineItem,
            scanReview
          })
        }
      }
    }
    case Actions.SHARE_SCAN_TO_REFERRAL_RECEIVED: {
      if (!state.isTimelineV2) {
        return state
      }

      const { grinScanId: scanId, referral } = action.payload
      return {
        ...state,
        scanFeed: {
          ...state.scanFeed,
          timelineItem: addReferralToTimelineItem({
            timelineItem: state.scanFeed.timelineItem,
            scanId,
            referral
          })
        }
      }
    }
    case Actions.TIMELINE_ITEM_LIVE_UPDATE_RECEIVED: {
      return {
        ...state,
        mainFeed: {
          ...state.mainFeed,
          timelineItems: {
            ...state.mainFeed.timelineItems,
            data: upsertItem({
              array: state.mainFeed.timelineItems.data,
              item: extractMainFeedFields(action.payload.timelineItem)
            })
          }
        },
        scanFeed: {
          ...state.scanFeed,
          timelineItem:
            state.scanFeed.timelineItem.id !== action.payload.timelineItem.id
              ? state.scanFeed.timelineItem
              : action.payload.timelineItem
        }
      }
    }
    case Actions.TIMELINE_SWITCH_SCAN_WITH_ALIGNERS:
    case Actions.TIMELINE_SWITCH_SCAN_WITH_ALIGNERS_FAILED: {
      return {
        ...state,
        scanFeed: {
          ...state.scanFeed,
          timelineItem: updateTimelineItemGrinScans({
            timelineItem: state.scanFeed.timelineItem,
            predicate: () => true,
            mapper: scan => ({ ...scan, withAligner: !scan.withAligner })
          }),
          switchScanWithAligners: {
            ...state.scanFeed.switchScanWithAligners,
            isLoading: action.type === Actions.TIMELINE_SWITCH_SCAN_WITH_ALIGNERS
          }
        }
      }
    }
    case Actions.TIMELINE_SWITCH_SCAN_WITH_ALIGNERS_RECEIVED: {
      return {
        ...state,
        scanFeed: {
          ...state.scanFeed,
          switchScanWithAligners: {
            ...state.scanFeed.switchScanWithAligners,
            isLoading: action.type === Actions.TIMELINE_SWITCH_SCAN_WITH_ALIGNERS
          }
        }
      }
    }
    case Actions.TIMELINE_FETCH_ORAL_RECORDS:
      return {
        ...state,
        scanFeed: {
          ...state.scanFeed,
          oralRecords: {
            ...initialState.scanFeed.oralRecords,
            loadStatus: AsyncStatus.Loading
          }
        }
      }
    case Actions.TIMELINE_FETCH_ORAL_RECORDS_FAILED:
      return {
        ...state,
        scanFeed: {
          ...state.scanFeed,
          oralRecords: {
            ...state.scanFeed.oralRecords,
            loadStatus: AsyncStatus.Failed
          }
        }
      }
    case Actions.TIMELINE_FETCH_ORAL_RECORDS_RECEIVED:
      return {
        ...state,
        scanFeed: {
          ...state.scanFeed,
          oralRecords: {
            ...state.scanFeed.oralRecords,
            loadStatus: AsyncStatus.Completed,
            initialScan: action.payload.initialScan
          }
        }
      }
    case Actions.SET_MINI_TIMELINE_THEME:
      return {
        ...state,
        miniTimeline: {
          ...state.miniTimeline,
          theme: action.payload.theme
        }
      }
    default:
      return state
  }
}
