import { formatDynamicFields } from 'utils/graphqlUtils'

export const searchAllPracticeTeamMembers = /* GraphQL */ `
  query SearchDoctorSearchModels(
    $filter: SearchableDoctorSearchModelFilterInput
    $sort: SearchableDoctorSearchModelSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchDoctorSearchModels(filter: $filter, sort: $sort, limit: $limit, nextToken: $nextToken, from: $from) {
      items {
        id
        name
        grinUserId
        photo {
          bucket
          region
          key
        }
        doctor {
          auth_status
          roleDescription
        }
      }
      nextToken
      total
    }
  }
`

export const searchPracticeAccountOwner = fields => /* GraphQL */ `
  query SearchDoctorSearchModels(
    $filter: SearchableDoctorSearchModelFilterInput
    $sort: SearchableDoctorSearchModelSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchDoctorSearchModels(filter: $filter, sort: $sort, limit: $limit, nextToken: $nextToken, from: $from) {
      items {
        ${formatDynamicFields(fields)}
      }
      nextToken
      total
    }
  }
`
