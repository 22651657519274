import * as React from 'react'

export default ({ color = 'black', size = '20' }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="10" cy="10" r="9.5" fill={color} stroke="#F5FAFF" />
    <path
      d="M10.064 2.86401L10.5388 4.57576C11.2143 7.01094 13.1171 8.91369 15.5522 9.5892L17.264 10.064L15.5522 10.5389C13.1171 11.2144 11.2143 13.1171 10.5388 15.5523L10.064 17.2641L9.58913 15.5523C8.91362 13.1171 7.01087 11.2144 4.5757 10.5389L2.86394 10.064L4.5757 9.5892C7.01087 8.91369 8.91362 7.01094 9.58913 4.57577L10.064 2.86401Z"
      fill="white"
    />
  </svg>
)
