import * as React from 'react'

export default () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.00065 11.6667C8.83398 11.6667 9.54232 11.375 10.1257 10.7917C10.709 10.2083 11.0007 9.5 11.0007 8.66667C11.0007 7.83333 10.709 7.125 10.1257 6.54167C9.54232 5.95833 8.83398 5.66667 8.00065 5.66667C7.16732 5.66667 6.45898 5.95833 5.87565 6.54167C5.29232 7.125 5.00065 7.83333 5.00065 8.66667C5.00065 9.5 5.29232 10.2083 5.87565 10.7917C6.45898 11.375 7.16732 11.6667 8.00065 11.6667ZM8.00065 10.3333C7.53398 10.3333 7.13954 10.1722 6.81732 9.85C6.4951 9.52778 6.33398 9.13333 6.33398 8.66667C6.33398 8.2 6.4951 7.80556 6.81732 7.48333C7.13954 7.16111 7.53398 7 8.00065 7C8.46732 7 8.86176 7.16111 9.18398 7.48333C9.50621 7.80556 9.66732 8.2 9.66732 8.66667C9.66732 9.13333 9.50621 9.52778 9.18398 9.85C8.86176 10.1722 8.46732 10.3333 8.00065 10.3333ZM2.66732 14C2.30065 14 1.98676 13.8694 1.72565 13.6083C1.46454 13.3472 1.33398 13.0333 1.33398 12.6667V4.66667C1.33398 4.3 1.46454 3.98611 1.72565 3.725C1.98676 3.46389 2.30065 3.33333 2.66732 3.33333H4.76732L6.00065 2H10.0007L11.234 3.33333H13.334C13.7007 3.33333 14.0145 3.46389 14.2757 3.725C14.5368 3.98611 14.6673 4.3 14.6673 4.66667V12.6667C14.6673 13.0333 14.5368 13.3472 14.2757 13.6083C14.0145 13.8694 13.7007 14 13.334 14H2.66732Z"
      fill="black"
    />
  </svg>
)
