import * as React from 'react'

export default () => (
  <svg width="71" height="71" viewBox="0 0 71 71" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.1132 13.5L36 8.5L38.8868 13.5H36.5V18.5H35.5V13.5H33.1132ZM22.25 35.5H13.5V33.1132L8.5 36L13.5 38.8867V36.5H22.25V35.5ZM35.5 53.5V58.5H33.1132L36 63.5L38.8868 58.5H36.5V53.5H35.5ZM49.75 36.5H58.5V38.8868L63.5 36L58.5 33.1132V35.5H49.75V36.5Z"
      fill="#3C52EF"
    />
    <rect x="27.25" y="22.25" width="17.5" height="27.5" rx="8.75" stroke="#3C52EF" strokeWidth="1.5" />
    <path
      d="M36 22.25V34.75M36 34.75H44.75M36 34.75H27.25"
      stroke="#3C52EF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M45 31C45 26.0294 40.9706 22 36 22V35H45V31Z" fill="#3C52EF" />
  </svg>
)
