import * as React from 'react'

const LowCertainty = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginTop: 5 }}
  >
    <rect x="1" y="1" width="18" height="18" rx="3" fill="none" stroke="#CCE1FF" strokeWidth="2" />
    <path
      d="M10.5909 7.18182L10.6591 5.30682L9.07386 6.3125L8.66477 5.59659L10.3352 4.72727L8.66477 3.85795L9.07386 3.14205L10.6591 4.14773L10.5909 2.27273H11.4091L11.3409 4.14773L12.9261 3.14205L13.3352 3.85795L11.6648 4.72727L13.3352 5.59659L12.9261 6.3125L11.3409 5.30682L11.4091 7.18182H10.5909Z"
      fill="#858585"
    />
  </svg>
)

export default LowCertainty
