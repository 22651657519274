import { Grid, useMediaQuery } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import React from 'react'
import { useTranslation } from 'react-i18next'
import AlignerMenu from './AlignerMenu'
import ScanStats from '../../ScanStats'
import { TreatmentTrackOptions } from 'consts/giConsts'
import OnTrackGrinScanItem from 'components/common/icons/timelineV2/mini-timeline/OnTrackGrinScanItem'
import OffTrackGrinScanItem from 'components/common/icons/timelineV2/mini-timeline/OffTrackGrinScanItem'
import AlignerNumber from '../../AlignerNumber'
import { UrgencyStatuses } from '@grin-rnd/grin-api-sdk/dist/Enums/Timeline'
import UrgentScanTag from '../../Tags/UrgentScanTag'
import ScanGeneralInfo from './ScanGeneralInfo'

const ScanHeaderBodyXl = ({
  scanNumber,
  alignerNumber,
  totalAlignerNumber,
  numberOfReviews,
  numberOfShares,
  onReviewsClicked,
  onSharesClicked,
  scanDate,
  withAlignerMenu,
  alignerMenuProps = {},
  trackStatus,
  urgencyDetails = {},
  isRecordsAppScan
}) => {
  const xl = useMediaQuery(theme => theme.breakpoints.up('xl'))
  const { t } = useTranslation()

  return (
    <Grid container alignItems="center" justifyContent="space-between" spacing={xl ? 2 : 1}>
      <Grid item>
        <Grid container alignItems="center" spacing={xl ? 2 : 1} justifyContent="space-between">
          {trackStatus && (
            <Grid item style={{ lineHeight: 0.8 }}>
              {trackStatus === TreatmentTrackOptions.OnTrack ? <OnTrackGrinScanItem /> : <OffTrackGrinScanItem />}
            </Grid>
          )}
          <Grid item>
            <DazzedParagraph14 bold>
              {t('pages.patients.selectedPatient.timelineV2.scanHeader.scanNumber', { scanNumber })}
            </DazzedParagraph14>
          </Grid>
          {!!alignerNumber && (
            <Grid item>
              <Grid container alignItems="center" spacing={1}>
                {withAlignerMenu && (
                  <Grid item>
                    <AlignerNumber alignerNumber={alignerNumber} totalAlignerNumber={totalAlignerNumber} />
                  </Grid>
                )}
                <Grid item>
                  <AlignerMenu {...alignerMenuProps} />
                </Grid>
              </Grid>
            </Grid>
          )}
          {urgencyDetails.urgencyStatus && urgencyDetails.urgencyStatus !== UrgencyStatuses.None ? (
            <Grid item>
              <UrgentScanTag reason={urgencyDetails.reason} urgencyStatus={urgencyDetails.urgencyStatus} />
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
      <Grid item>
        <ScanGeneralInfo isRecordsAppScan={isRecordsAppScan} scanDate={scanDate} />
      </Grid>
      <Grid item>
        <ScanStats
          numberOfReviews={numberOfReviews}
          numberOfShares={numberOfShares}
          onReviewsClicked={onReviewsClicked}
          onSharesClicked={onSharesClicked}
        />
      </Grid>
    </Grid>
  )
}

export default React.memo(ScanHeaderBodyXl)
