import { COGNITO_MFA_METHODS, MFA_METHOD_TYPES } from 'consts/mfaConsts'
import AuthAppSetup from './AuthAppSetup/AuthAppSetup'
import { SetupSteps } from './MFASetupModalConsts'
import { useCallback, useMemo, useState } from 'react'
import useMFA from 'components/Auth/useMFA'
import Actions from 'actions'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import SMSSetup from './SMSSetup/SMSSetup'

export default ({ onClose, methodType }) => {
  const dispatch = useDispatch()
  const { confirmTOTP, initSMSVerification, confirmSMS } = useMFA()
  const { t } = useTranslation()

  const { cognitoUser } = useSelector(state => state.authReducer)

  const [isLoading, setIsLoading] = useState(false)
  const [step, setStep] = useState(SetupSteps.Initial)
  const [confirmationCode, setConfirmationCode] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')

  const handleConfirmTotp = useCallback(async () => {
    setErrorMessage('')
    setIsLoading(true)

    const response = await confirmTOTP(confirmationCode)
    if (!response.success) {
      setErrorMessage(t(response.error?.message || 'pages.accountSettings.mfaSettings.setupModal.confirmationError'))
    } else {
      dispatch(
        Actions.setLoggedCognitoUser({
          ...cognitoUser,
          preferredMFA: COGNITO_MFA_METHODS.AuthApp
        })
      )
      setStep(step + 1)
    }
    setIsLoading(false)
  }, [cognitoUser, confirmTOTP, confirmationCode, dispatch, step, t])

  const handleSMSVerify = useCallback(async () => {
    setErrorMessage('')
    setIsLoading(true)

    // Remove all + characters and add a single one at the start
    let correctedNumber = phoneNumber.replace(/\+/g, '')
    correctedNumber = `+${correctedNumber}`

    const result = await initSMSVerification({ phoneNumber: correctedNumber })
    if (!result.success) {
      setErrorMessage(result.error?.message || t('pages.accountSettings.mfaSettings.setupModal.confirmationError'))
    } else {
      setStep(step + 1)
    }

    setIsLoading(false)
  }, [initSMSVerification, phoneNumber, step, t])

  const handleConfirmSMS = useCallback(async () => {
    setErrorMessage('')
    setIsLoading(true)

    const result = await confirmSMS({ confirmationCode })
    if (!result.success) {
      setErrorMessage(result.error?.message || t('pages.accountSettings.mfaSettings.setupModal.confirmationError'))
    } else {
      dispatch(
        Actions.setLoggedCognitoUser({
          ...cognitoUser,
          preferredMFA: COGNITO_MFA_METHODS.SMS
        })
      )
      setStep(step + 1)
    }
    setIsLoading(false)
  }, [cognitoUser, confirmSMS, confirmationCode, dispatch, step, t])

  const setupMethods = useMemo(
    () => [
      {
        type: MFA_METHOD_TYPES.AuthApp,
        component: AuthAppSetup,
        primaryClickByStep: {
          [SetupSteps.Initial]: () => setStep(step + 1),
          [SetupSteps.Confirmation]: handleConfirmTotp,
          [SetupSteps.Completed]: onClose
        },
        primaryLabelByStep: {
          [SetupSteps.Initial]: t('general.continue'),
          [SetupSteps.Confirmation]: t('general.continue'),
          [SetupSteps.Completed]: t('general.done')
        },
        secondaryClickByStep: {
          [SetupSteps.Initial]: onClose,
          [SetupSteps.Confirmation]: () => setStep(step - 1),
          [SetupSteps.Completed]: () => {}
        },
        secondaryLabelByStep: {
          [SetupSteps.Initial]: t('general.cancel'),
          [SetupSteps.Confirmation]: t('general.back'),
          [SetupSteps.Completed]: null
        }
      },
      {
        type: MFA_METHOD_TYPES.SMS,
        component: SMSSetup,
        primaryClickByStep: {
          [SetupSteps.OtherMethodIsAlreadySetup]: () => setStep(step + 1),
          [SetupSteps.Initial]: handleSMSVerify,
          [SetupSteps.Confirmation]: handleConfirmSMS,
          [SetupSteps.Completed]: onClose
        },
        primaryLabelByStep: {
          [SetupSteps.OtherMethodIsAlreadySetup]: t('general.continue'),
          [SetupSteps.Initial]: t('general.continue'),
          [SetupSteps.Confirmation]: t('general.continue'),
          [SetupSteps.Completed]: t('general.done')
        },
        secondaryClickByStep: {
          [SetupSteps.OtherMethodIsAlreadySetup]: onClose,
          [SetupSteps.Initial]: onClose,
          [SetupSteps.Confirmation]: () => setStep(step - 1),
          [SetupSteps.Completed]: () => {}
        },
        secondaryLabelByStep: {
          [SetupSteps.OtherMethodIsAlreadySetup]: t('general.cancel'),
          [SetupSteps.Initial]: t('general.cancel'),
          [SetupSteps.Confirmation]: t('general.back'),
          [SetupSteps.Completed]: null
        },
        shouldDisplayTitle: {
          [SetupSteps.OtherMethodIsAlreadySetup]: false,
          [SetupSteps.Initial]: true,
          [SetupSteps.Confirmation]: true,
          [SetupSteps.Completed]: true
        }
      }
    ],
    [handleConfirmSMS, handleConfirmTotp, handleSMSVerify, onClose, step, t]
  )

  const currentSetupMethod = useMemo(
    () => setupMethods.find(method => method.type === methodType),
    [methodType, setupMethods]
  )

  return {
    currentSetupMethod,
    setupMethods,
    isLoading,
    step,
    setStep,
    errorMessage,
    setErrorMessage,
    phoneNumber,
    setPhoneNumber,
    confirmationCode,
    setConfirmationCode,
    handleSMSVerify
  }
}
