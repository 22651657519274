import { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { useDebouncedCallback } from 'use-debounce'
import { logError } from 'utils/logUtils'
import { mapErrorPayload } from 'utils/mappers/logMappers'

/**
 * The maximum amount of time difference between the video durations allowed before the videos will not be linked.
 *
 * For example - if one scan is 32 seconds, the other one is 47 seconds and the threshold value is 3, the difference is 17 and the videos won't be linked.
 *
 * This is a solution we added after we enabled the option to upload dynamic scans that have difference lengths.
 */
const VIDEO_DURATION_DIFF_LINK_THRESHOLD = 3

export default ({ onPlay = () => {}, onPause = () => {}, onSeek = time => {} } = {}) => {
  const { linkEnabled } = useSelector(state => state.treatmentReducer.compareScans)
  let videos = useRef([])

  const [hasPartialLinkage, setHasPartialLinkage] = useState(false)

  const onPlayDebounced = useDebouncedCallback(onPlay, 500)
  const onPauseDebounced = useDebouncedCallback(onPause, 500)
  const onSeekDebounced = useDebouncedCallback(onSeek, 500)

  const getVideoAnalytics = useCallback(
    videoRef => ({
      side: videoRef?.side,
      playerTime: videoRef?.currentTime,
      linkEnabled
    }),
    [linkEnabled]
  )

  const calculateVideoDurationsDiff = useCallback(() => {
    const durations = videos.current.map(v => v.duration)
    const maxDuration = _.max(durations)
    const minDuration = _.min(durations)
    return maxDuration - minDuration
  }, [])

  const syncVideos = useCallback(
    (sourceVideo, callback) => {
      if (!linkEnabled) {
        return
      }

      setHasPartialLinkage(calculateVideoDurationsDiff() >= VIDEO_DURATION_DIFF_LINK_THRESHOLD)

      try {
        videos.current.forEach(video => {
          if (video.tagName === 'VIDEO' && video !== sourceVideo) {
            callback(video)
          }
        })
      } catch (ex) {
        logError(`useScanLinkage - syncVideos failed`, mapErrorPayload(ex))
      }
    },
    [linkEnabled, calculateVideoDurationsDiff]
  )

  const resetAllVideos = useCallback(() => {
    syncVideos(null, video => {
      if (video.currentTime !== 0) {
        video.currentTime = 0
      }

      if (!video.paused) {
        video.pause()
      }
    })
  }, [syncVideos])

  const handlePlay = useCallback(
    videoRef => {
      onPlayDebounced(getVideoAnalytics(videoRef))
      syncVideos(videoRef, video => {
        if (video.paused && video.currentTime < video.duration) {
          video.play()
        }
      })
    },
    [syncVideos, onPlayDebounced, getVideoAnalytics]
  )

  const handlePause = useCallback(
    videoRef => {
      if (videoRef.currentTime === videoRef.duration) {
        // Video has ended, we should not pause the other videos
        return
      }

      onPauseDebounced(getVideoAnalytics(videoRef))
      syncVideos(videoRef, video => {
        if (!video.paused) {
          video.pause()
        }
      })
    },
    [syncVideos, onPauseDebounced, getVideoAnalytics]
  )

  const handleSeek = useCallback(
    videoRef => {
      onSeekDebounced(getVideoAnalytics(videoRef))
      syncVideos(videoRef, video => {
        if (videoRef === video) {
          return
        }

        if (
          Math.abs(videoRef.currentTime - video.currentTime) > 0.2 && // Delay and error tolerance, preventing infinite loops
          Math.abs(videoRef.duration - video.duration) < VIDEO_DURATION_DIFF_LINK_THRESHOLD
        ) {
          video.currentTime = videoRef.currentTime
        }
      })
    },
    [syncVideos, onSeekDebounced, getVideoAnalytics]
  )

  const registerVideo = useCallback(
    videoRef => {
      if (videoRef.tagName !== 'VIDEO') {
        return
      }

      videos.current.push(videoRef)
      videoRef.onplaying = () => handlePlay(videoRef)
      videoRef.onpause = () => handlePause(videoRef)
      videoRef.onseeked = () => handleSeek(videoRef)

      if (linkEnabled) {
        resetAllVideos()
      }
    },
    [videos, handlePause, handlePlay, handleSeek, resetAllVideos, linkEnabled]
  )

  const unregisterVideo = useCallback(
    videoRef => {
      videos.current = videos.current.filter(v => v !== videoRef)
      videoRef.onplaying = null
      videoRef.onpause = null
      videoRef.onseeked = null
    },
    [videos]
  )

  useEffect(() => {
    if (linkEnabled) {
      resetAllVideos()
    }
  }, [linkEnabled]) // eslint-disable-line

  return {
    registerVideo,
    unregisterVideo,
    resetAllVideos,
    linkEnabled,
    videoPartialLinkage: hasPartialLinkage
  }
}
