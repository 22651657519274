import { useSelector } from 'react-redux'
import { useFetchPracticeAccountOwnerSMQuery, useFetchPracticeTeamMembersQuery } from 'store/modules/Practice'

export default () => {
  const multiPracticeContext = useSelector(state => state.multiPracticeReducer.context)
  const currentPracticeContext = useSelector(state => state.practiceReducer)

  const accountOwnerId = multiPracticeContext.accountOwnerId || currentPracticeContext.accountOwner.id
  const dso = multiPracticeContext.dso || currentPracticeContext.dso
  const hiGroup = multiPracticeContext.hiGroup || currentPracticeContext.hiGroup

  const { data: accountOwner } = useFetchPracticeAccountOwnerSMQuery({
    accountOwnerFields: `
      dso
      hiGroup
    `,
    accountOwnerId
  })

  const { data: practiceTeamMembers } = useFetchPracticeTeamMembersQuery({
    accountOwnerId,
    dso: dso || accountOwner?.dso,
    hiGroup: hiGroup || accountOwner?.hiGroup
  })

  return {
    practiceDSO: accountOwner?.dso,
    practiceHIGroup: accountOwner?.hiGroup,
    practiceTeamMembers: practiceTeamMembers || []
  }
}
