/**
 * Calculates the aggregated numeric value of the Oral Score based on the score values generated by the AI model.
 * Use `OralScoreValueMapping` to convert the value to `OralScores` enum if needed.
 *
 * The calculation is according to the description in the ticket: https://get-grin.atlassian.net/browse/GR-8717.
 *
 * @param {Object} args
 * @param {number} args.perio_risk - Perio risk value, between 1-3.
 * @param {number} args.dental_risk - Cavity risk value, between 1-3.
 * @param {number} args.hygiene_score - Hygiene score value, between 1-4.
 * @returns The calculated numeric OralScore value.
 */
export const calculateOralScore = ({ perio_risk, dental_risk, hygiene_score }) => {
  const totalRisks = Number(dental_risk > 1) + Number(perio_risk > 1)
  const highRisks = Number(dental_risk > 2) + Number(perio_risk > 2)
  const hasAnyHighRisk = highRisks > 1

  if (totalRisks <= 1 && !hasAnyHighRisk && hygiene_score <= 2) {
    return 1
  }

  if (highRisks <= 1 && hygiene_score <= 3) {
    return 2
  }

  return 3
}
