import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import ConfirmationModal from 'components/common/ConfirmationModal'
import { useDispatch } from 'react-redux'
import Actions from 'actions'
import { trackEvent } from 'utils/analyticsUtils'

const DeleteMessageConfirmationModal = ({ messageId, isOpen = false, onClose = () => {} }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleConfirm = useCallback(() => {
    trackEvent('Delete message - confirm clicked', { messageId })
    dispatch(
      Actions.softDeleteChatMessage({
        messageId
      })
    )
    onClose()
  }, [messageId, dispatch, onClose])

  return (
    <ConfirmationModal
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={handleConfirm}
      title={t('pages.patients.selectedPatient.chat.messageActions.deleteMessageConfirmationModal.title')}
      text={t('pages.patients.selectedPatient.chat.messageActions.deleteMessageConfirmationModal.content')}
    />
  )
}

export default DeleteMessageConfirmationModal
