import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import images from 'resources'
import DazzedHeading20 from 'components/common/text/DazzedHeading20'
import { useTranslation } from 'react-i18next'
import LinkButton from 'components/common/buttons/LinkButton'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'

const useStyles = makeStyles(theme => ({
  desc2: {
    whiteSpace: 'pre',
    marginBottom: 20
  }
}))

const PatientBriefEmptyState = ({ onNavigateToPracticeGuidelines }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid container direction="column" alignItems="center" justifyContent="center" spacing={2}>
      <Grid item>
        <img src={images.noPracticeGuidelinesForPatientBrief} alt="" />
      </Grid>
      <Grid item>
        <DazzedHeading20>{t('dialogs.patientBrief.emptyState.title')}</DazzedHeading20>
      </Grid>
      <Grid item>
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <DazzedParagraph16 strong>{t('dialogs.patientBrief.emptyState.description1')}</DazzedParagraph16>
          </Grid>
          <Grid item>
            <LinkButton
              underline
              label={t('dialogs.patientBrief.emptyState.link')}
              fontSize={16}
              onClick={onNavigateToPracticeGuidelines}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <DazzedParagraph16 strong textAlign="center" className={classes.desc2}>
          {t('dialogs.patientBrief.emptyState.description2')}
        </DazzedParagraph16>
      </Grid>
    </Grid>
  )
}

export default PatientBriefEmptyState
