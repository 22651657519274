import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import GrayActionBox from 'components/common/GrayActionBox'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'

export const useStyles = makeStyles({
  infoText: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontWeight: 500
  }
})

const GeneralInfoGridItem = ({ children, label = '', xs = 12, sm = 6, height = 42 }) => {
  const classes = useStyles()
  return (
    <Grid item xs={xs} sm={sm}>
      <GrayActionBox label={label} height={height}>
        <DazzedParagraph16 className={classes.infoText}>{children}</DazzedParagraph16>
      </GrayActionBox>
    </Grid>
  )
}

export default GeneralInfoGridItem
