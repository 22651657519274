import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'

const addAmbientLight = scene => {
  // Adds general ambient lightninig
  const ambientLight = new THREE.AmbientLight(0xffffff, 0.7)
  scene.add(ambientLight)
}

const createPointLight = () => {
  let pointLight = new THREE.PointLight(0xffffff, 0.3)

  // Assuming the light will be relative to camera
  // has to be far enough from camera so object will be lighten evenly
  pointLight.position.set(0, 0, -300)

  return pointLight
}

const createSceneCamera = ({
  containerElement,
  zCameraPosition,
  topOffset = 0,
  zoom,
  rotateAroundZAxis = false,
  cameraVector
}) => {
  const camera = new THREE.OrthographicCamera(
    containerElement.offsetWidth / 2,
    containerElement.offsetWidth / -2,
    containerElement.offsetHeight / 2 + topOffset,
    containerElement.offsetHeight / -2,
    0.1,
    2000
  )
  camera.zoom = zoom
  camera.position.z = zCameraPosition

  console.log('cameraVector', cameraVector)
  if (rotateAroundZAxis) {
    camera.up.set(0, 0, cameraVector)
    camera.updateProjectionMatrix()
  }

  return camera
}

export const setCameraPosition = ({ camera, x, y, z }) => {
  if (!camera) {
    return
  }

  camera.position.x = x
  camera.position.y = y
  camera.position.z = z

  camera.updateProjectionMatrix()
}

export const adjustCameraZoom = ({ camera, controls, zoomAddition }) => {
  if (!camera) {
    return
  }

  camera.zoom = camera.zoom + zoomAddition
  camera.updateProjectionMatrix()

  controls.dispatchEvent({ type: 'change' })
}

export const init3DView = ({
  containerElement,
  zCameraPosition,
  hexBgColor,
  cameraTopOffset,
  zoom = 6,
  zoomOnly = false,
  customControls,
  existingCamera,
  rotateAroundZAxis,
  cameraVector
}) => {
  const scene = new THREE.Scene()
  scene.background = new THREE.Color(hexBgColor)

  addAmbientLight(scene)
  const pointLight = createPointLight()

  let camera = existingCamera
  if (!camera) {
    camera = createSceneCamera({
      containerElement,
      zCameraPosition,
      topOffset: cameraTopOffset,
      zoom,
      rotateAroundZAxis,
      cameraVector
    })
    camera.add(pointLight)
    camera.updateProjectionMatrix()
  }

  scene.add(camera)

  const renderer = new THREE.WebGLRenderer()
  renderer.setSize(containerElement.offsetWidth, containerElement.offsetHeight)
  document.body.appendChild(renderer.domElement)

  const controls = new OrbitControls(camera, renderer.domElement)
  controls.enableRotate = !zoomOnly
  controls.mouseButtons = customControls ?? controls.mouseButtons
  controls.enableDamping = true
  controls.update()

  return {
    scene,
    camera,
    renderer,
    controls
  }
}

export const animate = ({ scene, camera, controls, stats, renderer }) => {
  const animationFrameId = requestAnimationFrame(() => animate({ scene, camera, controls, stats, renderer }))

  controls.update()
  renderer.render(scene, camera)

  return animationFrameId
}

export const findClickedSceneObjects = ({ event, camera, scene }) => {
  const raycaster = new THREE.Raycaster()
  const mouse = new THREE.Vector2()

  // Calculate normalized device coordinates (NDC)
  mouse.x = (event.clientX / window.innerWidth) * 2 - 1
  mouse.y = -(event.clientY / window.innerHeight) * 2 + 1

  // Update the picking ray with the camera and mouse position
  raycaster.setFromCamera(mouse, camera)

  // Perform intersection test
  const intersects = raycaster.intersectObjects(scene.children, true)

  return intersects
}
