import { Grid } from '@material-ui/core'
import GrinChip from 'components/common/GrinChip'
import NeedsAttentionLarge from 'components/common/icons/NeedsAttentionLarge'
import GiPositiveFeedback from 'components/common/icons/gi/GiPositiveFeedback'
import MessageOverlayInvalidScanSmall from 'components/common/icons/gi/MessageOverlayInvalidScanSmall'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { TreatmentTrackOptions } from 'consts/giConsts'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { trackEvent } from 'utils/analyticsUtils'
import HiConditionList from './HI/HiConditionsList'

const ScanMessageTrackingTooltip = ({ parsedTrackingDetails, isInvalidScan }) => {
  const { t } = useTranslation()

  useEffect(() => {
    trackEvent('Hi tooltip viewed', {
      result: parsedTrackingDetails.trackStatus === TreatmentTrackOptions.OnTrack ? 'On track' : 'Not on track'
    })
  }, [parsedTrackingDetails.trackStatus])

  if (isInvalidScan) {
    return (
      <Grid container alignItems="center">
        <Grid item xs={1}>
          <MessageOverlayInvalidScanSmall />
        </Grid>
        <Grid item xs={11}>
          <DazzedParagraph14 thickness="thin">
            {t('pages.patients.selectedPatient.chat.gi.giMessage.invalidScan')}
          </DazzedParagraph14>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container alignItems="center">
      <Grid item xs={1}>
        {parsedTrackingDetails.trackStatus === TreatmentTrackOptions.OnTrack ? (
          <GiPositiveFeedback />
        ) : (
          <NeedsAttentionLarge />
        )}
      </Grid>
      <Grid item xs={11}>
        <DazzedParagraph14 thickness="thin">
          {parsedTrackingDetails.trackStatus === TreatmentTrackOptions.OnTrack ? (
            <>
              <Grid item>{t('pages.patients.selectedPatient.chat.hi.onTrackHITooltip')}</Grid>
              {parsedTrackingDetails?.notes && (
                <Grid item>
                  <GrinChip text={parsedTrackingDetails.notes} />
                </Grid>
              )}
              <Grid item>
                <HiConditionList conditions={parsedTrackingDetails.conditions} />
              </Grid>
            </>
          ) : (
            <>
              <Grid item>
                {t('pages.patients.selectedPatient.chat.hi.notOnTrackHITooltip')}
                <HiConditionList conditions={parsedTrackingDetails.conditions} />
              </Grid>
              <Grid item style={{ marginTop: 10 }}>
                <GrinChip text={parsedTrackingDetails.notes} />
              </Grid>
            </>
          )}
        </DazzedParagraph14>
      </Grid>
    </Grid>
  )
}

export default ScanMessageTrackingTooltip
