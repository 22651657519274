import React, { useCallback, useMemo, useState } from 'react'
import SwooshSmallIcon from '../common/icons/Swoosh'
import FormInput from '../common/FormInput'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { isMobile } from 'react-device-detect'
import OggHeading40 from 'components/common/text/OggHeading40'
import useCssClasses from 'hooks/useCssClasses'
import useMFA from './useMFA'
import { useTranslation } from 'react-i18next'
import { logError } from 'utils/logUtils'
import PrimaryButton from 'components/common/buttons/PrimaryButton'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import Actions from 'actions'
import { COGNITO_MFA_METHODS } from 'consts/mfaConsts'
import LinkButton from 'components/common/buttons/LinkButton'
import { trackEvent } from 'utils/analyticsUtils'
import { Auth } from 'aws-amplify'
import BrightCheckbox from 'components/common/inputs/BrightCheckbox'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    height: '100%',
    color: 'var(--text-color-1)',
    overflowX: 'hidden',
    overflowY: 'auto'
  },
  signInSwoosh: {
    position: 'absolute',
    top: '0',
    left: '0'
  },
  title: {
    fontSize: isMobile ? 32 : 40
  },
  form: {
    maxWidth: '512px',
    minWidth: isMobile ? 'auto' : 550,
    padding: 18
  },
  inputContainer: {
    width: '100%'
  },
  resendCodeLabel: {
    color: 'var(--text-color-10)'
  }
}))

const MFALogin = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { verifyCode } = useMFA()

  const { cognitoUser } = useSelector(state => state.authReducer)

  const [code, setCode] = useState('')
  const [shouldRememberDevice, setShouldRememberDevice] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  useCssClasses(true)

  const destionationPhoneNumber = useMemo(() => cognitoUser?.challengeParam?.CODE_DELIVERY_DESTINATION, [cognitoUser])
  const isSmsMfaType = useMemo(() => cognitoUser?.challengeName === COGNITO_MFA_METHODS.SMS, [cognitoUser])

  const handleCodeEntered = useCallback(async () => {
    setIsLoading(true)

    try {
      await verifyCode({
        cognitoUser,
        confirmationCode: code,
        mfaType: cognitoUser.challengeName
      })

      const loggedUser = await Auth.currentAuthenticatedUser()

      if (shouldRememberDevice) {
        await Auth.rememberDevice()
      }
      dispatch(Actions.signInReceived(loggedUser))
    } catch (error) {
      logError('An error occured while trying to login with MFA', { error, mfaType: cognitoUser.challengeName })
      setErrorMessage(error.message)
    } finally {
      setIsLoading(false)
    }
  }, [code, cognitoUser, dispatch, shouldRememberDevice, verifyCode])

  const handleResendCode = useCallback(async () => {
    trackEvent('MFA Login - Resend code clicked')
    dispatch(Actions.resendMFACode())
  }, [dispatch])

  const handleKeyPress = useCallback(
    e => {
      if (e.code === 'Enter') {
        handleCodeEntered()
      }
    },
    [handleCodeEntered]
  )

  return (
    <Grid className={classes.root} container alignItems="center" justifyContent="center" onKeyPress={handleKeyPress}>
      <SwooshSmallIcon className={classes.signInSwoosh} />
      <Grid container direction="column" justifyContent="center" spacing={isMobile ? 3 : 2} className={classes.form}>
        <Grid item>
          <OggHeading40 className={classes.title} dark textAlign={isMobile ? 'center' : 'start'}>
            {t('pages.mfa.title')}
          </OggHeading40>
        </Grid>
        <Grid item>
          <DazzedParagraph14 strong color="white">
            {isSmsMfaType
              ? t('pages.mfa.smsDescription', { phoneNumber: destionationPhoneNumber })
              : t('pages.mfa.appDescription')}
          </DazzedParagraph14>
        </Grid>
        {isSmsMfaType && (
          <Grid item>
            <LinkButton
              underline
              label={t('pages.mfa.resendCode')}
              labelClassName={classes.resendCodeLabel}
              onClick={handleResendCode}
            />
          </Grid>
        )}
        <Grid item>
          <FormInput
            autoFocus
            containerClassName={classes.inputContainer}
            errorMessage={errorMessage}
            allowAutoComplete
            title={t('pages.mfa.codeLabel')}
            value={code}
            validationRules={{ required: true }}
            setValue={setCode}
          />
        </Grid>
        <Grid item>
          <BrightCheckbox
            color="white"
            checked={shouldRememberDevice}
            onChange={event => setShouldRememberDevice(event.target.checked)}
            label={t('pages.mfa.trustBrowser')}
          />
        </Grid>
        <Grid item>
          <PrimaryButton width={'100%'} label={t('general.enter')} onClick={handleCodeEntered} isLoading={isLoading} />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default MFALogin
