import moment from 'moment'
import React, { useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { useTranslation } from 'react-i18next'
import CustomDatePicker from 'components/common/CustomDatePicker'
import { conditionTypes } from 'consts/automatedActionConsts'
import ErrorMessage from 'components/common/text/ErrorMessage'
import DuringHours from './DuringHours'

const useStyles = makeStyles(theme => ({
  datePicker: {
    marginBottom: 0
  },
  datePickerContainer: {
    width: 90
  }
}))

const DateRangeCondition = ({ onChange, params, errors }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const errorMessage = useMemo(() => errors.conditions?.[conditionTypes.DateRange], [errors])

  const handleDateChange = useCallback(
    updatedParams => {
      onChange({
        type: conditionTypes.DateRange,
        params: {
          fromDate: moment().toISOString(),
          toDate: moment().add(1, 'day').toISOString(),
          ...params,
          ...updatedParams
        }
      })
    },
    [onChange, params]
  )

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Grid container alignItems="flex-end" wrap="nowrap" spacing={2}>
          <Grid item>
            <DazzedParagraph14>{t('general.from')}</DazzedParagraph14>
          </Grid>
          <Grid item>
            <CustomDatePicker
              value={params?.fromDate}
              handleDateChange={newDate => handleDateChange({ fromDate: moment(newDate).toISOString() })}
              disablePast
              minDateMessage=""
              format="dd-MMM"
              datePickerClassName={classes.datePicker}
              className={classes.datePickerContainer}
            />
          </Grid>
          <Grid item>
            <DazzedParagraph14>{t('general.to')}</DazzedParagraph14>
          </Grid>
          <Grid item>
            <CustomDatePicker
              value={params?.toDate}
              handleDateChange={newDate => handleDateChange({ toDate: moment(newDate).toISOString() })}
              disablePast
              format="dd-MMM"
              datePickerClassName={classes.datePicker}
              className={classes.datePickerContainer}
              minDate={params?.fromDate}
              minDateMessage=""
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <DuringHours params={params} conditionType={conditionTypes.DateRange} onChange={onChange} />
      </Grid>
      <Grid item>
        <ErrorMessage active={errorMessage} text={errorMessage} />
      </Grid>
    </Grid>
  )
}

export default DateRangeCondition
