import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'
import Actions from 'actions'
import NoteInput from '../../../NotesTab/NoteInput'
import Notes from '../../../NotesTab/Notes'

export const useStyles = makeStyles({
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  notesContent: {
    height: '100%',
    maxHeight: '100%',
    marginBottom: 20,
    overflowY: 'auto',
    paddingLeft: '5%',
    paddingRight: '5%',
    paddingBottom: 5
  },
  centeredContainer: {
    height: 'calc(100vh - 400px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
})

const NotesTab = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const doctor = useSelector(state => state.supportReducer.doctorsDashboard.selectedDoctor)
  const { isLoading, isCreating, data } = useSelector(state => state.supportReducer.doctorsDashboard.notes)

  const [note, setNote] = useState('')

  const handleAddNote = useCallback(() => {
    if (note.length) {
      dispatch(
        Actions.supportCreateDoctorNote({
          grinUserId: doctor.user.id,
          body: note.trim()
        })
      )
    }
  }, [dispatch, doctor, note])

  useEffect(() => {
    if (!data) {
      dispatch(Actions.supportFetchDoctorNotesByUserId(doctor.user.id))
    }
  }, [data, doctor, dispatch])

  useEffect(() => {
    if (!isCreating) {
      setNote('')
    }
  }, [isCreating, setNote])

  return isLoading ? (
    <div className={classes.centeredContainer}>
      <CircularProgress />
    </div>
  ) : (
    <div className={classes.content}>
      <div className={classes.notesContent}>
        <Notes list={data || []} hideActions />
      </div>
      <NoteInput note={note} setNote={setNote} isLoading={isCreating} noteRows onAddNote={handleAddNote} />
    </div>
  )
}

export default NotesTab
