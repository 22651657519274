import React from 'react'
import { useTranslation } from 'react-i18next'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  container: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
})

const NotesEmptyState = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <DazzedParagraph16 strong>{t('pages.patients.patientSwitchableView.notes.noNotes')}</DazzedParagraph16>
    </div>
  )
}

export default NotesEmptyState
