import confetti from './confetti.png'
import grinScope from './grinScope.png'
import grinScopeInfo from './grinScopeInfo.png'
import grinScopeWidthArrow from './grinScopeWidthArrow.png'
import grinScopeWidthArrowMobile from './grinScopeWidthArrow.mobile.png'
import grinScopeMini from './grinScopeMini.png'
import grinScopeMiniInfo from './grinScopeMiniInfo.png'
import grinScopeMiniWidthArrow from './grinScopeMiniWidthArrow.png'
import grinScopeMiniWidthArrowMobile from './grinScopeMiniWidthArrow.mobile.png'
import AccentColor from './AccentColorPreview.png'
import VideoPreview from './VideoPreview.png'
import quickGuideInstructions from './quickGuideInstructions.png'
import InstructionForUse from './InstructionForUse.png'
import doctorLocator from './doctorLocator.png'
import dolphinLogo from './dolphin-logo.png'
import emptyStateBackground from './emptyState/EmptyStateBackground.png'
import overduePatientsBackground from './emptyState/OverduePatientsBackground.png'
import invitedPatientsBackground from './emptyState/InvitedPatientsBackground.png'
import emptyStateConfetti from './emptyState/EmptyStateConfetti.png'
import needsAttentionBackground from './emptyState/NeedsAttentionBackground.png'
import automationRulesExample from './automationRulesExample.png'
import untrackedToothBackground from './untrackedToothBackground.png'
import noPatientBriefFloatingIcon from './noPatientBriefFloatingIcon.png'
import noPracticeGuidelinesForPatientBrief from './patientBrief/noPracticeGuidelinesForPatientBrief.png'
import mfaInactive from './MFAInactive.png'
import GrinLogoWithSwoosh from './GrinLogoWithSwoosh.png'

export default {
  confetti,
  grinScope,
  grinScopeInfo,
  grinScopeWidthArrow,
  grinScopeWidthArrowMobile,
  grinScopeMini,
  grinScopeMiniInfo,
  grinScopeMiniWidthArrow,
  grinScopeMiniWidthArrowMobile,
  AccentColor,
  VideoPreview,
  quickGuideInstructions,
  InstructionForUse,
  doctorLocator,
  dolphinLogo,
  emptyStateBackground,
  overduePatientsBackground,
  invitedPatientsBackground,
  needsAttentionBackground,
  emptyStateConfetti,
  automationRulesExample,
  untrackedToothBackground,
  noPatientBriefFloatingIcon,
  noPracticeGuidelinesForPatientBrief,
  mfaInactive,
  GrinLogoWithSwoosh
}
