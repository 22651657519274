import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import React from 'react'
import { useTranslation } from 'react-i18next'

const Frequency = ({ frequency }) => {
  const { t } = useTranslation()

  return (
    <DazzedParagraph14 strong>
      {t('dialogs.hiPatientGuidelinesViewer.actions.frequency', { days: frequency })}
    </DazzedParagraph14>
  )
}

export default Frequency
