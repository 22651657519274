import React, { useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import ProfilePicture from 'components/common/ProfilePicture'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import UserAccountActionsMenu from './UserAccountActionsMenu'
import EditDisplayNameModal from './EditDisplayNameModal'
import ConfirmationModal from 'components/common/ConfirmationModal'
import Tooltip from 'components/common/Tooltip'
import UserProfileTooltip from './UserProfileTooltip'

const useStyles = makeStyles(theme => ({
  userAccountItemRoot: {
    cursor: ({ clickable }) => (clickable ? 'pointer' : 'default'),
    backgroundColor: 'rgba(245, 250, 254, 1)',
    transition: 'background-color 0.2s ease',
    '&:hover': {
      backgroundColor: ({ clickable }) => (clickable ? 'rgba(0, 0, 0, 0.1)' : 'rgba(245, 250, 254, 1)')
    }
  },
  profileName: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '215px'
  },
  profileNameContainer: {
    padding: '0 8px',
    flex: 1
  },
  subtitle: {
    color: 'var(--text-color-62)',
    fontWeight: 500
  }
}))

const UserAccountItem = ({
  username,
  displayName,
  avatar,
  lastLoginDate,
  email,
  isCurrentlyLogged,
  className = '',
  onClick,
  setProfileDisplayName,
  forgetProfile,
  disabled = false
}) => {
  const classes = useStyles({ clickable: !!onClick })
  const { t } = useTranslation()

  const [isEditDisplayNameModalOpen, setIsEditDisplayNameModalOpen] = useState(false)
  const [isForgetAccountModalOpen, setIsForgetAccountModalOpen] = useState(false)

  const handleSaveDispalyName = useCallback(
    newDisplayName => {
      setProfileDisplayName(newDisplayName)
    },
    [setProfileDisplayName]
  )

  return (
    <>
      <Grid
        container
        alignItems="center"
        onClick={disabled ? undefined : onClick}
        className={[classes.userAccountItemRoot, className].join(' ')}
        wrap="nowrap"
      >
        <Grid item>
          <ProfilePicture name={displayName} photo={avatar} size={'medium'} />
        </Grid>
        <Grid item className={classes.profileNameContainer}>
          <Tooltip value={<UserProfileTooltip email={email} lastLoginDate={lastLoginDate} />} placement="bottom">
            <DazzedParagraph14 className={classes.profileName} bold={isCurrentlyLogged} strong={!isCurrentlyLogged}>
              {displayName}
            </DazzedParagraph14>
            {isCurrentlyLogged && (
              <DazzedParagraph12 className={classes.subtitle}>
                {t('common.appMenu.authProfiles.currentlyLogged')}
              </DazzedParagraph12>
            )}
          </Tooltip>
        </Grid>
        <Grid item>
          <UserAccountActionsMenu
            onEditDisplayNameClicked={() => setIsEditDisplayNameModalOpen(true)}
            onForgetAccountClicked={isCurrentlyLogged ? null : () => setIsForgetAccountModalOpen(true)}
          />
        </Grid>
      </Grid>
      {!disabled && (
        <>
          <EditDisplayNameModal
            displayName={displayName}
            isOpen={isEditDisplayNameModalOpen}
            setIsOpen={() => setIsEditDisplayNameModalOpen(false)}
            onSaveClicked={handleSaveDispalyName}
          />
          <ConfirmationModal
            isOpen={isForgetAccountModalOpen}
            onClose={() => setIsForgetAccountModalOpen(false)}
            onConfirm={forgetProfile}
            title={t('common.appMenu.authProfiles.forgetAccountPopup.title')}
            text={t('common.appMenu.authProfiles.forgetAccountPopup.body')}
            acceptButtonText={t('general.confirm')}
          />
        </>
      )}
    </>
  )
}

export default UserAccountItem
