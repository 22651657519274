import * as React from 'react'

export default () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 10H15V16C15 17.1046 14.1046 18 13 18H8C6.89543 18 6 17.1046 6 16V10Z"
      stroke="#3C52EF"
      strokeWidth="1.5"
    />
    <rect x="4" y="6" width="13" height="2" fill="#3C52EF" />
    <path d="M8 6C8 4.89543 8.89543 4 10 4H11C12.1046 4 13 4.89543 13 6V7H8V6Z" fill="#3C52EF" />
  </svg>
)
