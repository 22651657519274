import { Grid, makeStyles } from '@material-ui/core'
import Tooltip from 'components/common/Tooltip'
import { QuestionMarkIcon } from 'components/common/icons'
import { CONTENT_HEIGHT } from 'consts/patientCard'
import useUploadSTLs from 'hooks/useUploadSTLs'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import DriveUploaderDropzone from '../../PatientCard/TxTrackerSetup/DriveUploaderDropzone'

const useStyles = makeStyles({
  container: {
    height: CONTENT_HEIGHT,
    padding: '30px 50px 10px 5%',
    overflowY: 'auto'
  },
  recommendationContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  recommendation: {
    background: '#ECEFFF',
    borderRadius: 10,
    height: '100%',
    width: '95%',
    marginRight: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  uploadedSTLsTitleContainer: {
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'space-between'
  },
  requestInProgress: {
    color: '#FFA34D',
    fontWeight: 'bold',
    fontSize: 13,
    display: 'flex'
  }
})

const UploadProviderSTLs = ({ provider, patientId, treatmentId, treatmentType }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [isUploaderComponentLoading, setIsUploaderComponentLoading] = useState(false)

  const { status: requestSTLsStatus } = useSelector(state => state.txTrackerReducer.requestSTLs)

  const { handleRequestProviderSTLs, providerOptions, setProvider, DROPZONE_CONTAINER_ID } = useUploadSTLs({
    shouldShowDriveUploaderComponent: true,
    setIsUploaderComponentLoading,
    patientId,
    treatmentId,
    treatmentType,
    mode: 'provider'
  })

  const recommendationText = useMemo(() => t('dialogs.patientInfo.txTrackerSetup.recommendationMoreThan5'), [t])

  useEffect(() => {
    setProvider(provider)
  }, [provider, setProvider])

  return (
    <div className={classes.container}>
      <Grid container direction="column">
        <Grid container spacing={1}>
          <Grid item xs={6} className={classes.recommendationContainer}>
            <div className={classes.recommendation}>{recommendationText}</div>
            <Tooltip
              placement={'right'}
              value={t('dialogs.patientInfo.txTrackerSetup.recommendationTooltip')}
              variant="dark"
            >
              <div>
                <QuestionMarkIcon fontSize="inherit" />
              </div>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid container spacing={1} style={{ height: 300 }}>
          <Grid item xs={8} style={{ height: '100%' }}>
            <DriveUploaderDropzone
              mode="provider"
              requestStlsFromProvider={false}
              dropzoneContainerId={DROPZONE_CONTAINER_ID}
              isUploaderComponentLoading={isUploaderComponentLoading}
              provider={provider}
              providerOptions={providerOptions}
              onRequestProviderSTLs={handleRequestProviderSTLs}
              requestSTLsStatus={requestSTLsStatus}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default UploadProviderSTLs
