import DazzedHeading20 from 'components/common/text/DazzedHeading20'
import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import LinkButton from 'components/common/buttons/LinkButton'
import PatientDetails from 'components/common/PatientDetails'
import usePatientTags from 'hooks/usePatientTags'
import useScansComparison from 'components/Patients/Timeline/ScanComparison/useScansComparison'
import useScanLinkage from 'components/Patients/Timeline/ScanComparison/useScanLinkage'
import { trackEvent } from 'utils/analyticsUtils'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import ScanSelection from './ScanSelection'
import CompareSTLsLinkButton from './CompareSTLsLinkButton'
import useSTLsSync from './useSTLsSync'
import { Poses } from 'components/common/Grin3DViewer/Grin3DViewerConsts'
import { useDispatch } from 'react-redux'
import Actions from 'actions'

const useStyles = makeStyles(theme => ({
  compareStlsContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  divider: {
    display: 'block',
    width: 1,
    height: 32,
    margin: '0px 3px 0px 3px',
    backgroundColor: 'var(--text-color-22)'
  },
  headerItem: {
    marginRight: 10
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10
  },
  content: {
    marginBottom: 10
  },
  stlsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%'
  }
}))

const CompareSTLsView = ({
  hasNextScan,
  navigateToNextScan,
  hasPreviousScan,
  navigateToPreviousScan,
  scanNumber,
  alignerNumber,
  grinScan,
  patient,
  allTxTrackerScans,
  navigateToScanByScanId,
  isCompareStlsModeOn,
  setIsCompareStlsModeOn,
  parsedTxTrackerData,
  onOpenRequestRerunModal,
  archModels,
  calculatedTreatmentType,
  isSmallerScreen,
  initAlignerNumber,
  endAlignerNumber
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const classes = useStyles()
  const { onSceneRemoved } = useSTLsSync()

  const { tags } = usePatientTags({ patient, tagsTypes: ['treatment', 'program'] })

  const { scanSelectionOptions, isLinked, toggleLink } = useScansComparison()
  const scanLinkage = useScanLinkage({
    onPause: data => trackEvent('Compare STLs - video pause', data),
    onPlay: data => trackEvent('Compare STLs - video play', data),
    onSeek: data => trackEvent('Compare STLs - video seek', data)
  })

  const handleToggleLink = useCallback(() => {
    if (!isLinked) {
      // Reset the synced pose to center on link
      dispatch(Actions.txTrackerCompareSetPose(Poses.Center))
    }
    toggleLink()
  }, [dispatch, isLinked, toggleLink])

  return (
    <div className={classes.compareStlsContainer}>
      <div className={classes.headerContainer}>
        <div className={classes.headerItem}>
          <LinkButton underline label={`←${t('general.back')}`} onClick={() => setIsCompareStlsModeOn(false)} />
        </div>
        <div className={classes.headerItem}>
          <DazzedHeading20>{t('dialogs.treatmentTracker.compareSTLsTitle')}</DazzedHeading20>
        </div>
        <div className={classes.headerItem}>
          <span className={classes.divider} />
        </div>
        <div>
          <PatientDetails name={patient?.name} photo={patient?.photo} program={patient?.program} tags={tags} />
        </div>
      </div>
      <div className={classes.stlsContainer}>
        <ScanSelection
          side="left"
          scanSelectionOptions={scanSelectionOptions}
          scanLinkage={scanLinkage}
          onOpenRequestRerunModal={onOpenRequestRerunModal}
          onSelectionChanged={onSceneRemoved}
        />
        <CompareSTLsLinkButton onClick={handleToggleLink} isLinked={isLinked} />
        <ScanSelection
          side="right"
          scanSelectionOptions={scanSelectionOptions}
          scanLinkage={scanLinkage}
          onOpenRequestRerunModal={onOpenRequestRerunModal}
          onSelectionChanged={onSceneRemoved}
        />
        {scanLinkage.videoPartialLinkage && scanLinkage.linkEnabled && (
          <DazzedParagraph14 strong>
            {t('pages.patients.selectedPatient.timeline.compareScans.partialVideoLinkage')}
          </DazzedParagraph14>
        )}
      </div>
    </div>
  )
}
export default CompareSTLsView
