import { formatDynamicFields } from 'utils/graphqlUtils'

export const noteFields = `
  id
  grinUserId
  a_doctor
  createdAt
  body
  isPinned
  metadata
  _deleted
`

export const getUserNotesSorted = fields => /* GraphQL */ `
  query UserNotesSorted(
    $grinUserId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userNotesSorted(
      grinUserId: $grinUserId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ${formatDynamicFields(fields)}
      }
      nextToken
      startedAt
    }
  }
`
