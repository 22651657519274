import moment from 'moment'
import { get3DScanPosition, stripToS3Object } from '../storageUtils'
import { AWS_DATE_TIME } from '../../consts/dateTimeConsts'
import { getObjectUrl } from 'utils/mediaUtils'

const withAdditionalProperties = (photo, isSTL = false) => {
  const props = {
    ...photo,
    url: getObjectUrl(photo),
    isLoading: false
  }
  if (photo && isSTL) {
    props.position = get3DScanPosition(photo)
  }
  return props
}

export const mapInitialScanPhotos = initialScan => {
  if (!initialScan)
    return {
      oralImages: [],
      stls: [],
      panoramics: [],
      focusAreaImages: [],
      id: null
    }

  return {
    oralImages: initialScan.oralImages?.map(photo => withAdditionalProperties(photo, false)) || [],
    stls: initialScan.stls?.map(photo => withAdditionalProperties(photo, true)) || [],
    panoramics: initialScan.panoramics?.map(photo => withAdditionalProperties(photo, false)) || [],
    focusAreaImages: initialScan.focusAreaImages?.map(photo => withAdditionalProperties(photo, false)) || [],
    id: initialScan.id,
    date: initialScan.date,
    _deleted: initialScan._deleted,
    createdAt: initialScan.createdAt,
    scannerType: initialScan.scannerType
  }
}

export const mapToCreateInitialScanInput = (action, state) => {
  return {
    patientId: state.patientsReducer.patientCard.patient.id,
    a_patient: state.patientsReducer.patientCard.patient.username,
    a_doctor: state.profileReducer.doctor?.username,
    oralImages: action.payload.oralImages?.length ? action.payload.oralImages.map(stripToS3Object) : null,
    panoramics: action.payload.panoramics?.length ? action.payload.panoramics.map(stripToS3Object) : null,
    stls: action.payload.stls?.length ? action.payload.stls.map(stripToS3Object) : null,
    date: moment(action.payload.date).format(AWS_DATE_TIME)
  }
}

export const mapRecords = (records, updatedRecord = null) => {
  const existedRecords = updatedRecord
    ? records.filter(record => updatedRecord.id !== record.id && !record._deleted).concat(updatedRecord)
    : records.filter(record => !record._deleted)
  return existedRecords.map(mapInitialScanPhotos).sort((a, b) => moment(b.date).valueOf() - moment(a.date).valueOf())
}

export const filterFailedRecords = records => {
  return (records || []).filter(item => !item.isLoading)
}
