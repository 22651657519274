import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import BaseGrinNotification from './BaseGrinNotification/BaseGrinNotification'
import useSelectPatient from 'hooks/useSelectPatient'
import GenericNotificationBody from './BaseGrinNotification/GenericNotificationBody'

/**
 * @param {NotificationComponentProps} props
 */
const NewScanNotification = ({ grinNotification, options }) => {
  const { t } = useTranslation()

  const selectPatient = useSelectPatient({
    patientId: grinNotification?.relatedPatientId,
    timelineItemId: grinNotification?.relatedEntityId
  })

  const handleClick = useCallback(() => {
    selectPatient()
  }, [selectPatient])

  return (
    <BaseGrinNotification
      grinNotification={grinNotification}
      isLastItem={options.isLastItem}
      showPracticeName={options.showPracticeName}
      profilePicture={grinNotification.relatedPatientSM.profilePicture}
      profilePictureUserName={grinNotification.relatedPatientSM.name}
      onClick={handleClick}
    >
      <GenericNotificationBody
        title={grinNotification.relatedPatientSM.name}
        text={
          grinNotification.payload.alignerNumber
            ? t('dialogs.notificationCenterDialog.feed.notifications.newScan.withAligner', {
                scanNumber: grinNotification.payload.scanNumber,
                alignerNumber: grinNotification.payload.alignerNumber
              })
            : t('dialogs.notificationCenterDialog.feed.notifications.newScan.withoutAligner', {
                scanNumber: grinNotification.payload.scanNumber
              })
        }
      />
    </BaseGrinNotification>
  )
}

export default NewScanNotification
