import { AsyncStatus } from 'consts'
import { useEffect } from 'react'
import { useLocation } from 'react-router'

export default ({ loadStatus }) => {
  const { hash } = useLocation()

  useEffect(() => {
    if (hash && loadStatus === AsyncStatus.Completed) {
      const element = document.getElementById(hash.replace('#', ''))
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }, 100)
      }
    }
  }, [hash, loadStatus])
}
