import { makeStyles } from '@material-ui/core'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import usePlanLimits from 'hooks/usePlanLimits'
import usePracticeConfiguration from 'hooks/usePracticeConfiguration'
import useRolePermissions from 'hooks/useRolePermissions'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { trackEvent } from 'utils/analyticsUtils'
import ROUTES from '../../consts/routesConsts'
import useFeatureFlags from '../../hooks/useFeatureFlags'
import NavigationLink from './NavigationLink'
import UpgradeTag from './Services/UpgradeTag'
import { isUserOfAnyAdminRole } from 'utils/authUtils'

const useStyles = makeStyles({
  settings: {
    width: 135,
    marginBottom: 32
  },
  header: {
    color: 'var(--text-color-12)',
    marginBottom: 30
  },
  navLink: {
    whiteSpace: 'nowrap'
  }
})

const AccountSettingsNavigation = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    practiceMembers: isPracticeMembersFFEnabled = false,
    serviceAccount: serviceAccountFFEnabled,
    hiTools: hiToolsFF,
    practiceAutomations: practiceAutomationsFF = false
  } = useFeatureFlags()

  const { isHI: isOnHI, isLegacy1Plan, isTrialPlan } = usePlanLimits()
  const { isRcFFEnabled, isOrthoFFEnabled } = usePracticeConfiguration()
  const { permissions, isHiUser } = useRolePermissions()

  const shouldUpgradePlanForAutomations = useMemo(
    () => !practiceAutomationsFF && (isLegacy1Plan || isTrialPlan),
    [isLegacy1Plan, practiceAutomationsFF, isTrialPlan]
  )

  const personalSettings = useMemo(
    () =>
      [
        {
          label: t('pages.accountSettings.sideNavigator.myProfile'),
          route: ROUTES.ACCOUNT_SETTINGS,
          condition: () => true
        },
        {
          label: t('pages.accountSettings.sideNavigator.notificationSettings'),
          route: ROUTES.NOTIFICATION_SETTINGS,
          condition: () => permissions.accountNotificationSettings
        }
      ].filter(({ condition }) => condition()),
    [t, permissions]
  )

  const accountSettings = useMemo(
    () =>
      [
        {
          label: t('pages.accountSettings.sideNavigator.practiceProfile'),
          route: ROUTES.PRACTICE_PROFILE,
          condition: () => true
        },
        {
          label: t('pages.accountSettings.sideNavigator.dsoMembers'),
          route: ROUTES.DSO_MEMBERS,
          condition: () => permissions.dsoMembers
        },
        {
          label: t('pages.accountSettings.sideNavigator.practiceMembers'),
          route: ROUTES.PRACTICE_MEMBERS,
          condition: () => isPracticeMembersFFEnabled && permissions.practiceMembers,
          nested: true
        },
        {
          label: t('pages.accountSettings.sideNavigator.practiceGuidelines'),
          route: ROUTES.PRACTICE_GUIDELINES,
          condition: () => isOnHI && hiToolsFF && !isHiUser,
          nested: true
        },
        {
          label: t('pages.accountSettings.sideNavigator.services'),
          route: ROUTES.SERVICES,
          condition: () => permissions.accountServices && (isRcFFEnabled || isOrthoFFEnabled || serviceAccountFFEnabled)
        },
        {
          label: t('pages.accountSettings.sideNavigator.advancedOptions'),
          route: ROUTES.ADVANCED_ACTIONS,
          condition: () => !isUserOfAnyAdminRole()
        },
        {
          label: t('pages.accountSettings.sideNavigator.customMessages'),
          route: ROUTES.CUSTOM_MESSAGES,
          condition: () => permissions.sendChatMessages
        },
        {
          label: t('pages.accountSettings.sideNavigator.automations'),
          component: (
            <div>
              {t('pages.accountSettings.sideNavigator.automations')}
              {shouldUpgradePlanForAutomations && <UpgradeTag />}
            </div>
          ),
          route: ROUTES.AUTOMATIONS,
          condition: () => permissions.automations,
          nested: true
        },
        {
          label: t('pages.accountSettings.sideNavigator.billing'),
          route: ROUTES.BILLING,
          condition: () => permissions.billing,
          nested: true
        },
        {
          label: t('pages.accountSettings.sideNavigator.about'),
          route: ROUTES.ABOUT,
          condition: () => true,
          nested: true
        }
      ].filter(({ condition }) => condition()),
    [
      t,
      shouldUpgradePlanForAutomations,
      isPracticeMembersFFEnabled,
      isOnHI,
      hiToolsFF,
      isHiUser,
      permissions,
      isRcFFEnabled,
      isOrthoFFEnabled,
      serviceAccountFFEnabled
    ]
  )

  const allSettings = useMemo(
    () => [
      {
        label: t('pages.accountSettings.sideNavigator.headers.personalSettings'),
        settings: personalSettings
      },
      {
        label: t('pages.accountSettings.sideNavigator.headers.accountSettings'),
        settings: accountSettings
      }
    ],
    [t, personalSettings, accountSettings]
  )

  const handleLinkSelection = useCallback(
    label =>
      trackEvent('Account Settings - Navigation selected', {
        label
      }),
    []
  )

  return (
    <div>
      {allSettings.map(({ label, settings }) => (
        <div key={label} className={classes.settings}>
          <DazzedParagraph12 thickness="bold" className={classes.header}>
            {label}
          </DazzedParagraph12>
          {settings.map(({ label, component, route, nested }) => (
            <NavigationLink
              key={route}
              label={component || label}
              route={route}
              nested={nested}
              onClick={() => handleLinkSelection(label)}
              className={classes.navLink}
            />
          ))}
        </div>
      ))}
    </div>
  )
}

export default AccountSettingsNavigation
