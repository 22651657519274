import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import MarkImageButton from './MarkImageButton'
import { trackEvent } from 'utils/analyticsUtils'
import ActionButton from '../annotations/ActionButton'
import { Delete1, Hide, Show } from '../icons'

const useStyles = makeStyles(theme => ({
  secondaryActionButton: {
    backgroundColor: 'rgba(175, 175, 175, 0.3)',
    borderRadius: 30,
    padding: '8px 12px!important',
    marginLeft: 10,
    height: 39
  }
}))

const ScanMarkupActions = ({
  onMarkImageClicked = () => {},
  isSketched = false,
  isSketchVisible = false,
  setIsSketchVisible = () => {},
  onClearAllClicked = () => {},
  analyticsPayload = {}
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const handleClearAllClicked = useCallback(
    e => {
      e.stopPropagation()
      trackEvent('Scan summary - clear image sketch clicked', { ...analyticsPayload })
      onClearAllClicked()
    },
    [onClearAllClicked, analyticsPayload]
  )

  const handleToggleSketchVisibility = useCallback(
    e => {
      e.stopPropagation()
      trackEvent(`Scan summary - sketch visiblity toggled`, {
        ...analyticsPayload,
        sketchVisibility: isSketchVisible ? 'off' : 'on'
      })
      setIsSketchVisible(!isSketchVisible)
    },
    [setIsSketchVisible, analyticsPayload, isSketchVisible]
  )

  return (
    <Grid container alignItems="flex-end">
      <Grid item>
        <MarkImageButton onClick={onMarkImageClicked} />
      </Grid>
      {isSketched && (
        <>
          <ActionButton
            label={t('dialogs.scanSummary.annotations.clear')}
            onClick={handleClearAllClicked}
            className={classes.secondaryActionButton}
          >
            <Delete1 />
          </ActionButton>
          <ActionButton
            label={t(`dialogs.scanSummary.annotations.${isSketchVisible ? 'hide' : 'show'}`)}
            onClick={handleToggleSketchVisibility}
            className={classes.secondaryActionButton}
          >
            {isSketchVisible ? <Hide /> : <Show />}
          </ActionButton>
        </>
      )}
    </Grid>
  )
}

export default ScanMarkupActions
