import React from 'react'
import { Grid } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { useTranslation } from 'react-i18next'
import TwoFactorAuthIsOn from './TwoFactorAuthIsOn'

const CompletedStep = ({ confirmationCode, setConfirmationCode, errorMessage }) => {
  const { t } = useTranslation()

  return (
    <Grid container direction="column">
      <Grid item style={{ marginBottom: 20 }}>
        <TwoFactorAuthIsOn />
        <DazzedParagraph14 strong>{t('pages.accountSettings.mfaSettings.setupModal.doneNote')}</DazzedParagraph14>
      </Grid>
    </Grid>
  )
}

export default CompletedStep
