import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { UrgencyStatuses } from '@grin-rnd/grin-api-sdk/dist/Enums/Timeline'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import GrinChip from 'components/common/GrinChip'
import UrgentScan from 'components/common/icons/timelineV2/urgentScan'
import UrgentScanResolved from 'components/common/icons/timelineV2/UrgentScanResolved'
import Tooltip from 'components/common/Tooltip'
import DazzedParagraph11 from 'components/common/text/DazzedParagraph11'

const useStyles = makeStyles(theme => ({
  urgentScanTooltip: {
    padding: '16px!important',
    borderRadius: 10,
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)!important'
  },
  urgentScanTag: {
    padding: ({ denseMode }) => (denseMode ? '4px' : '2px 4px'),
    borderRadius: ({ denseMode }) => (denseMode ? '9px' : '6px'),
    minWidth: ({ denseMode }) => (denseMode ? 34 : 'auto')
  },
  unresolvedLabel: {
    color: 'white',
    fontWeight: 'bold',
    padding: ({ denseMode }) => (denseMode ? 0 : 2),
    fontSize: 12
  },
  resolvedLabel: {
    color: 'rgba(242, 87, 0, 1)',
    fontWeight: 400,
    padding: ({ denseMode }) => (denseMode ? 0 : 2),
    fontSize: 12
  }
}))

const UrgentScanTag = ({ urgencyStatus, reason, tooltipPlacement = 'right', denseMode = false }) => {
  const classes = useStyles({ urgencyStatus, denseMode })
  const { t } = useTranslation()

  return !urgencyStatus || urgencyStatus === UrgencyStatuses.None ? (
    <> </>
  ) : (
    <Tooltip
      placement={tooltipPlacement}
      variant="light"
      tooltipContentClassName={classes.urgentScanTooltip}
      value={
        reason && (
          <div>
            <DazzedParagraph11 thickness="bold" color={'var(--text-color-62)'}>
              {t('pages.patients.selectedPatient.timelineV2.urgentScan.urgencyReasonTitle')}
            </DazzedParagraph11>
            <DazzedParagraph14 strong>
              {t(`pages.patients.selectedPatient.chat.urgencyReasons.${reason}`)}
            </DazzedParagraph14>
          </div>
        )
      }
    >
      {urgencyStatus === UrgencyStatuses.Unresolved ? (
        <GrinChip
          text={denseMode ? '' : t('pages.patients.selectedPatient.timelineV2.urgentScan.urgent')}
          icon={<UrgentScan />}
          className={classes.urgentScanTag}
          labelClassName={classes.unresolvedLabel}
          backgroundColor={'rgba(242, 87, 0, 1)'}
        />
      ) : (
        <GrinChip
          text={denseMode ? '' : t('pages.patients.selectedPatient.timelineV2.urgentScan.resolved')}
          icon={<UrgentScanResolved />}
          className={classes.urgentScanTag}
          labelClassName={classes.resolvedLabel}
          backgroundColor={'rgba(242, 87, 0, 0.5)'}
          outlinedBackgroundColor="transparent"
          variant="outlined"
        />
      )}
    </Tooltip>
  )
}

export default UrgentScanTag
