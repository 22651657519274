import React, { useCallback, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation, Trans } from 'react-i18next'
import BaseModal from 'components/common/modals/BaseModal'
import { useDispatch, useSelector } from 'react-redux'
import Actions from 'actions'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import FeedbackHeaderIcon from 'components/common/icons/FeedbackHeaderIcon'
import TextAreaAutosize from 'components/common/TextareaAutosize'
import { trackEvent } from 'utils/analyticsUtils'

const useStyles = makeStyles(theme => ({
  root: {
    width: '573px!important',
    padding: '30px 60px'
  },
  spacer: {
    height: '40px'
  },
  textInput: {
    border: '1px solid var(--border-color-24)!important',
    borderRadius: '9px',
    padding: '16px!important',
    fontSize: '14px!important'
  },
  title: {
    whiteSpace: 'pre'
  }
}))

const ShareFeedbackDialog = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [feedbackText, setFeedbackText] = useState('')

  const { isOpen, isSubmitting, description, logo, inputPlaceholder, title, displayNote, contextText } = useSelector(
    state => state.appReducer.appFeedbackDialog
  )

  const handleClose = useCallback(() => {
    dispatch(
      Actions.toggleAppFeedbackDialog({
        value: false
      })
    )
    trackEvent('App Feedback Modal - modal closed')
  }, [dispatch])

  const submitFeedback = useCallback(() => {
    dispatch(Actions.submitAppFeedback({ feedbackText: `${contextText ? `${contextText}\n` : ''}${feedbackText}` }))
    trackEvent('App Feedback Modal - submitted', {
      feedbackText
    })
  }, [dispatch, feedbackText, contextText])

  useEffect(() => {
    if (!isOpen) {
      setFeedbackText('')
    }
  }, [isOpen])

  return (
    <BaseModal
      className={classes.root}
      open={isOpen}
      isLoading={isSubmitting}
      handleClose={handleClose}
      title={title ?? t('dialogs.shareFeedback.title')}
      titleImageComponent={logo ?? <FeedbackHeaderIcon />}
      titleClassName={classes.title}
      variant="alert"
      primaryLabel={t('general.submit')}
      onPrimaryBtnClick={submitFeedback}
      isPrimaryDisabled={!feedbackText}
      secondaryLabel={t('general.cancel')}
      onSecondaryBtnClick={handleClose}
      largerButtons
    >
      <DazzedParagraph14 textAlign="center" strong>
        {description ?? t('dialogs.shareFeedback.feedbackDescription')}
      </DazzedParagraph14>
      <div className={classes.spacer} />
      {displayNote && (
        <DazzedParagraph14 textAlign="center" strong>
          <Trans i18nKey={'dialogs.shareFeedback.paragraph'} />
        </DazzedParagraph14>
      )}
      <div className={classes.spacer} />
      <TextAreaAutosize
        className={classes.textInput}
        placeholder={inputPlaceholder ?? t('dialogs.shareFeedback.feedbackTextPlaceholder')}
        style={{ bright: true, thick: true }}
        rowsMin={4}
        rowsMax={4}
        value={feedbackText}
        setValue={setFeedbackText}
      />
    </BaseModal>
  )
}

export default ShareFeedbackDialog
