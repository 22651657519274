import momenttz from 'moment-timezone'
import TreatmentStatusUpdated from 'components/Profile/Services/Automations/AutomationRuleDisplayComponents/TreatmentStatusUpdated'
import { AutomationsAssignPatient, Message, PatientTag } from 'components/common/icons'
import { actionTypes, conditionTypes } from 'consts/automatedActionConsts'
import moment from 'moment'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import useRolePermissions from './useRolePermissions'
import { useSelector } from 'react-redux'

export default action => {
  const { t } = useTranslation()
  const { permissions } = useRolePermissions()
  const practiceTimezone = useSelector(state => state.practiceReducer.details.practiceTimezone)

  const ruleActions = useMemo(
    () => ({
      [actionTypes.AddPatientTag]: {
        icon: <PatientTag />,
        title: t(`pages.accountSettings.automations.actionTypes.${actionTypes.AddPatientTag}`),
        getContent: params =>
          t(`pages.accountSettings.automations.actionTypes.content.${actionTypes.AddPatientTag}`, {
            tag: params.tagValue
          }),
        validate: params =>
          !params?.tagValue
            ? { error: t(`pages.accountSettings.automations.actionTypes.validations.patientTagError`) }
            : {}
      },
      [actionTypes.SendMessage]: {
        icon: <Message />,
        title: t(`pages.accountSettings.automations.actionTypes.${actionTypes.SendMessage}`),
        getContent: params =>
          t(`pages.accountSettings.automations.actionTypes.content.${actionTypes.SendMessage}`, {
            message: params.message
          }),
        validate: params =>
          !params?.message
            ? { error: t(`pages.accountSettings.automations.actionTypes.validations.sendMessageError`) }
            : {}
      },
      [actionTypes.AssignPatient]: {
        icon: <AutomationsAssignPatient />,
        title: t(`pages.accountSettings.automations.actionTypes.${actionTypes.AssignPatient}`),
        getContent: params =>
          t(`pages.accountSettings.automations.actionTypes.content.${actionTypes.AssignPatient}`, {
            practiceMember: params?.assigneeName
          }),
        validate: params =>
          !params?.assigneeId
            ? { error: t(`pages.accountSettings.automations.actionTypes.validations.practiceMemberRequired`) }
            : {}
      },
      [actionTypes.ShowAnnouncement]: {
        icon: <></>,
        title: t(`pages.accountSettings.automations.actionTypes.${actionTypes.ShowAnnouncement}`),
        getContent: params =>
          t(`pages.accountSettings.automations.actionTypes.content.${actionTypes.ShowAnnouncement}`, {
            announcement: params.announcementTitle
          }),
        validate: () => {}
      },
      [actionTypes.NotifyPractice]: {
        icon: <></>,
        title: t(`pages.accountSettings.automations.actionTypes.${actionTypes.NotifyPractice}`),
        getContent: params =>
          t(`pages.accountSettings.automations.actionTypes.content.${actionTypes.NotifyPractice}`, {
            note: params?.note
          }),
        validate: params =>
          !params?.note
            ? { error: t(`pages.accountSettings.automations.actionTypes.validations.notifyPracticeRequired`) }
            : {}
      }
    }),
    [t]
  )

  const ruleConditions = useMemo(
    () => ({
      [conditionTypes.DuringDays]: {
        getContent: params => {
          const content = params.duringDays
            .map(day => `${day.charAt(0).toUpperCase()}${day.substring(1, 3)}`)
            .join(', ')

          if (params?.fromHour && params?.toHour) {
            const fromHour = momenttz(params?.fromHour).tz(practiceTimezone).format('HH:mm')
            const toHour = momenttz(params?.toHour).tz(practiceTimezone).format('HH:mm')
            return `${content}\n${fromHour} - ${toHour}`
          }
        },
        validate: params => {
          if (params.duringDays?.length === 0) {
            return { error: t('pages.accountSettings.automations.conditions.validations.duringDaysError') }
          }
          if (params?.withHoursFilter && (!params?.toHour || !params?.fromHour)) {
            return { error: t('pages.accountSettings.automations.conditions.validations.duringHoursMissingError') }
          }
          if (params?.fromHour && params?.toHour && moment(params?.fromHour).isSameOrAfter(moment(params?.toHour))) {
            return { error: t('pages.accountSettings.automations.conditions.validations.duringHoursError') }
          }
        },
        isAllowed: true
      },
      [conditionTypes.DateRange]: {
        getContent: params => {
          const content = `${moment(params.fromDate).format('MMM Do')} - ${moment(params.toDate).format('MMM Do YYYY')}`
          if (params?.fromHour && params?.toHour) {
            const fromHour = momenttz(params?.fromHour).tz(practiceTimezone).format('HH:mm')
            const toHour = momenttz(params?.toHour).tz(practiceTimezone).format('HH:mm')
            return `${content}\n${fromHour} - ${toHour}`
          }

          return content
        },
        validate: params => {
          if (moment(params.fromDate).startOf('d').isAfter(moment(params.toDate).startOf('d'))) {
            return { error: t('pages.accountSettings.automations.conditions.validations.duringDatesError') }
          }
          if (params?.isDuringHour && (!params?.toHour || params?.fromHour)) {
            return { error: t('pages.accountSettings.automations.conditions.validations.duringHoursMissingError') }
          }
          if (
            params?.fromHour &&
            params?.toHour &&
            moment(params.fromDate).isSame(moment(params.toDate), 'day') &&
            moment(params.fromHour).isSameOrAfter(moment(params.toHour))
          ) {
            return { error: t('pages.accountSettings.automations.conditions.validations.duringHoursError') }
          }
        },
        isAllowed: true
      },
      [conditionTypes.AlignerNumber]: {
        getContent: params =>
          t(`pages.accountSettings.automations.conditionTypes.${conditionTypes.AlignerNumber}`, {
            alignerNumber: params.alignerNumber
          }),
        validate: params =>
          !params.alignerNumber
            ? { error: t('pages.accountSettings.automations.conditions.validations.alignerNumberError') }
            : {},
        isAllowed: true
      },
      [conditionTypes.RemainingAligners]: {
        getContent: params =>
          t(`pages.accountSettings.automations.conditionTypes.${conditionTypes.RemainingAligners}`, {
            remainingAligners: params.remainingAligners
          }),
        validate: params =>
          !params.remainingAligners
            ? { error: t('pages.accountSettings.automations.conditions.validations.remainingAlignersError') }
            : {},
        isAllowed: true
      },
      [conditionTypes.TreatmentStatusUpdated]: {
        getContent: params => <TreatmentStatusUpdated params={params} />,
        isAllowed: true,
        validate: () => {}
      },
      [conditionTypes.PatientScanCount]: {
        getContent: params =>
          t(`pages.accountSettings.automations.conditionTypes.${conditionTypes.PatientScanCount}`, params),
        validate: params =>
          !params.scansCount
            ? { error: t('pages.accountSettings.automations.conditions.validations.scansCountError') }
            : {},
        isAllowed: permissions.opsDashboardSystemAutomations
      },
      [conditionTypes.PracticeOnPlan]: {
        getContent: params =>
          t(`pages.accountSettings.automations.conditionTypes.${conditionTypes.PracticeOnPlan}`, {
            plans: params?.plans?.map(p => p.label).join(', ')
          }),
        validate: params =>
          !params.plans
            ? { error: t('pages.accountSettings.automations.conditions.validations.practiceOnPlanError') }
            : {},
        isAllowed: permissions.opsDashboardSystemAutomations
      },
      [conditionTypes.PatientStatus]: {
        getContent: params =>
          t(`pages.accountSettings.automations.conditionTypes.${conditionTypes.PatientStatus}`, {
            statuses: params?.statuses?.map(s => s.label).join(', ')
          }),
        validate: params =>
          !params.statuses
            ? { error: t('pages.accountSettings.automations.conditions.validations.patientStatusError') }
            : {},
        isAllowed: true
      },
      [conditionTypes.PatientHasTags]: {
        getContent: params =>
          t(`pages.accountSettings.automations.conditionTypes.${conditionTypes.PatientHasTags}`, {
            tags: params?.tags?.map(t => t.label).join(', ')
          }),
        validate: params =>
          !params.tags
            ? { error: t('pages.accountSettings.automations.conditions.validations.patientHasTagsError') }
            : {},
        isAllowed: true
      },
      [conditionTypes.PracticeHasFeatureFlag]: {
        getContent: params =>
          t(`pages.accountSettings.automations.conditionTypes.${conditionTypes.PracticeHasFeatureFlag}`, params),
        validate: params =>
          !params.featureFlag
            ? { error: t('pages.accountSettings.automations.conditions.validations.practiceHasFeatureFlagError') }
            : {},
        isAllowed: permissions.opsDashboardSystemAutomations
      },
      [conditionTypes.ScanIsUrgent]: {
        getContent: params => t(`pages.accountSettings.automations.conditionTypes.${conditionTypes.ScanIsUrgent}`),
        validate: () => {},
        isAllowed: true
      },
      [conditionTypes.OverdueDays]: {
        getContent: params =>
          t(`pages.accountSettings.automations.conditionTypes.${conditionTypes.OverdueDays}`, {
            numberOfDays: params.overdueDays
          }),
        isAllowed: true,
        validate: params =>
          !params.overdueDays
            ? { error: t('pages.accountSettings.automations.conditions.validations.required') }
            : params.overdueDays < 1 || params.overdueDays > 30
            ? { error: t('pages.accountSettings.automations.conditions.validations.rangeError', { min: 1, max: 30 }) }
            : {}
      }
    }),
    [permissions, t, practiceTimezone]
  )

  const allowedRuleConditions = useMemo(
    () => Object.keys(ruleConditions).filter(conditionType => ruleConditions[conditionType].isAllowed),
    [ruleConditions]
  )

  const isConditionAllowed = useCallback(
    condtionType => {
      return allowedRuleConditions.includes(condtionType)
    },
    [allowedRuleConditions]
  )

  return {
    ruleActions,
    ruleConditions,
    allowedRuleConditions,
    isConditionAllowed
  }
}
