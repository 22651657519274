export const BEARER = 'Bearer '
export const PASSWORD_CONFIRMATION_DONT_MATCH = 'Passwords do not match'
export const INVALID_PASSWORD = `Password must be at least 8 symbols long, contain one upper case, one lower case, one number and one special symbol`

export const EIGHT_PLUS_CHARACTERS = 'eightPlusCharacters'
export const ONE_UPPER_CASE_LETTER = 'oneUpperCaseLetter'
export const ONE_NUMBER = 'oneNumber'
export const ONE_LOWER_CASE_LETTER = 'oneLowerCaseLetter'
export const ONE_SPECIAL_CHARACTER = 'oneSpecialCharacter'
export const NUM_OF_MONTHS_TO_EXPIRE_TRUSTED_BROWSER = 2

export const Roles = {
  Admin: 'Admins',
  BillingAdmin: 'BillingAdmin',
  DsoSupport: 'DSO-SUPPORT',
  DSOAdmin: 'DSOAdmin',
  Distributor: 'DISTRIBUTOR',
  Translator: 'Translators',
  Analyst: 'Analysts',
  Sales: 'Sales',
  AccountOwners: 'ACCOUNT-OWNERS',
  PracticeManagers: 'PRACTICE-MANAGERS',
  PracticeMembers: 'PRACTICE-MEMBERS',
  HI: 'HI',
  MultiPracticeManagers: 'MULTI-PRACTICE-MANAGERS',
  DevAdmin: 'DevAdmin',
  GI: 'GI',
  OpsAdmin: 'OpsAdmin'
}

export const TeamMemberStatuses = {
  confirmed: 'confirmed',
  invited: 'invited',
  disabled: 'disabled'
}

export const DoctorSignUpProgressOptions = {
  Pending: 'pending',
  AccountCreated: 'accountCreated',
  BillingInfoCompleted: 'billingInfoCompleted'
}

export const PracticeRoles = [Roles.PracticeMembers, Roles.PracticeManagers, Roles.AccountOwners]
export const PracticeAdminRoles = [Roles.PracticeManagers, Roles.AccountOwners]
export const DsoRoles = [Roles.DsoSupport, Roles.DSOAdmin]
export const AdminRoleGroups = [
  Roles.Admin,
  Roles.OpsAdmin,
  Roles.DevAdmin,
  Roles.DsoSupport,
  Roles.Distributor,
  Roles.MultiPracticeManagers,
  Roles.HI,
  Roles.GI,
  Roles.Sales
]

const notInAnyRole = (userRoles = [], excludedRoleKeys = []) => !userRoles.some(role => excludedRoleKeys.includes(role))

export const RolePermissionsMap = {
  invitePatient: PracticeRoles,
  patientListItemActions: userRoles => notInAnyRole(userRoles, [Roles.Sales]),
  billing: PracticeRoles,
  deleteConfirmedPatients: [Roles.Admin],
  reactivatePatientAccount: [Roles.Admin, Roles.Distributor],
  searchByDoctor: AdminRoleGroups,
  approvePrelimPlan: [Roles.Admin],
  submitPrelimPlan: [Roles.Admin, ...PracticeAdminRoles],
  submitTreatmentPlan: [Roles.Admin, ...PracticeAdminRoles],
  recordScanReviews: [...PracticeRoles, Roles.HI],
  shareScan: [...PracticeRoles, Roles.DsoSupport],
  accountNotificationSettings: [...PracticeRoles, Roles.DsoSupport, Roles.HI],
  notificationSettings: {
    preferenceOptions: {
      RCLead: PracticeAdminRoles,
      assignee: userRoles => notInAnyRole(userRoles, AdminRoleGroups)
    },
    filters: {
      assignee: userRoles => notInAnyRole(userRoles, AdminRoleGroups)
    }
  },
  accountServices: PracticeAdminRoles,
  automations: PracticeAdminRoles,
  accountSavedReplies: [...PracticeRoles, Roles.DsoSupport, Roles.MultiPracticeManagers, Roles.HI, Roles.GI],
  practiceAnalytics: [...PracticeRoles, Roles.Admin],
  dsoAnalytics: [Roles.DsoSupport],
  distributorAnalytics: [Roles.Distributor],
  sendChatMessages: [...PracticeRoles, Roles.DsoSupport, Roles.MultiPracticeManagers, Roles.HI, Roles.GI],
  opsDashboard: [Roles.Admin, Roles.Distributor, Roles.Analyst, Roles.Sales],
  opsDashboardEmptyState: [Roles.Admin],
  opsDashboardHIPractices: [Roles.Admin, Roles.DevAdmin, Roles.BillingAdmin],
  opsDashboardChangeDoctorEmail: [Roles.Admin],
  opsDashboardUpdateHiGroups: [Roles.DevAdmin, Roles.BillingAdmin],
  opsDashboardAllowedDoctors: [Roles.Admin, Roles.Analyst, Roles.Distributor, Roles.Sales],
  opsDashboardPractices: [Roles.Admin, Roles.Analyst, Roles.Distributor, Roles.Sales],
  opsDashboardPatients: [Roles.Admin, Roles.Analyst, Roles.Distributor],
  opsDashboardReleaseNotes: [Roles.Admin],
  opsDashboardStats: [Roles.Admin, Roles.Analyst],
  opsDashboardPromotions: [Roles.Admin],
  opsDashboardArticles: [Roles.Admin],
  opsDashboardPatientsTable: [Roles.Admin],
  opsDashboardBilling: [Roles.BillingAdmin],
  opsDashboardEditDoctor: {
    viewDialog: [...AdminRoleGroups],
    generalInfoTab: {
      read: [Roles.Sales],
      write: userRoles => notInAnyRole(userRoles, [Roles.Sales])
    },
    subscriptionTab: {
      read: [Roles.Sales],
      write: userRoles => notInAnyRole(userRoles, [Roles.Sales])
    },
    featuresTab: {
      read: [...AdminRoleGroups],
      write: [...AdminRoleGroups]
    },
    paymentHistoryTab: {
      read: [...AdminRoleGroups]
    },
    membersTab: {
      read: [...AdminRoleGroups],
      write: userRoles => notInAnyRole(userRoles, [Roles.Sales])
    },
    pmsIntegrationsTab: {
      read: [...AdminRoleGroups],
      write: [Roles.Admin, Roles.Sales]
    }
  },
  opsDashboardDownloadCsv: [Roles.Admin],
  opsDashboardEditPatientFF: [Roles.Admin],
  opsDashboardEditPatienCountry: [Roles.Admin],
  opsDashboardAnnouncements: [Roles.Admin],
  opsDashboardSystemAutomations: [Roles.DevAdmin],
  opsDashboardEditPracticeMembers: [Roles.Admin],
  opsDashboardEditScanSummarySettings: [Roles.DevAdmin],
  opsActions: {
    all: [Roles.Admin],
    copyPatients: [Roles.Sales]
  },
  editProfile: PracticeRoles,
  editPatientDetails: userRoles => notInAnyRole(userRoles, [Roles.Sales]),
  editPatientTags: [...PracticeRoles, Roles.Admin, Roles.DsoSupport, Roles.MultiPracticeManagers, Roles.HI, Roles.GI],
  editPatientScanSettings: userRoles => notInAnyRole(userRoles, [Roles.Sales]),
  editFamilyInfoTab: userRoles => notInAnyRole(userRoles, [Roles.Sales]),
  customTags: PracticeRoles,
  allowedDoctorsUploadCsv: [Roles.Admin],
  allowedDoctorsAllPrograms: [Roles.Admin],
  editScanAppliance: [...PracticeRoles, Roles.Admin, Roles.DsoSupport, Roles.MultiPracticeManagers, Roles.HI, Roles.GI],
  quickSignUp: [Roles.Admin, Roles.Sales],
  editPracticeDetails: [Roles.Admin, ...PracticeAdminRoles],
  practiceMembersPage: [Roles.Admin, ...PracticeAdminRoles],
  editPracticeMembers: [Roles.Admin, Roles.DSOAdmin, ...PracticeAdminRoles],
  addDSOUser: [Roles.DSOAdmin],
  editPracticeMemberAccessType: [...PracticeAdminRoles, Roles.DSOAdmin],
  transferPatient: [Roles.Admin],
  sendPromotionsInChat: [
    Roles.Admin,
    Roles.HI,
    Roles.DsoSupport,
    Roles.MultiPracticeManagers,
    Roles.GI,
    ...PracticeRoles
  ],
  broadcast: PracticeRoles,
  makeTemplatesPrivate: [Roles.PracticeMembers, Roles.PracticeManagers],
  uploadInitialMedicalHistoryFile: [Roles.Admin, Roles.HI, Roles.GI],
  scanSummaryAnnotations: [...PracticeRoles, Roles.DsoSupport, Roles.MultiPracticeManagers, Roles.HI, Roles.GI],
  editWelcomeMessage: PracticeAdminRoles,
  setPatientAssignee: [...PracticeRoles, Roles.HI, Roles.GI, Roles.Admin, Roles.DsoSupport],
  rcDashboard: [...PracticeRoles, Roles.Admin],
  rcDashboardAdminActions: [Roles.Admin],
  rcDashboardEmptyStateLink: userRoles => notInAnyRole(userRoles, [Roles.PracticeMembers]),
  shareScanProgress: [...PracticeRoles, Roles.DsoSupport, Roles.HI, Roles.GI],
  updateBlueDotBehaviour: PracticeAdminRoles,
  toggleUrgentScanOption: PracticeAdminRoles,
  toggleRoomReadStatus: [...PracticeRoles, Roles.DsoSupport],
  resolveScanUrgency: PracticeRoles,
  setChatRoomReadStatus: userRoles => notInAnyRole(userRoles, [Roles.Sales, Roles.Admin, Roles.Distributor]),
  addNotes: userRoles => notInAnyRole(userRoles, [Roles.Sales, Roles.Admin, Roles.Distributor]),
  viewRecords: [...AdminRoleGroups],
  manageRecords: userRoles => notInAnyRole(userRoles, [Roles.Sales, Roles.Distributor]),
  detailedEmptyState: PracticeRoles,
  treatmentTracking: [Roles.GI, Roles.HI],
  editSubmittedTrackingStatus: [Roles.HI, ...PracticeRoles],
  resolveGiScan: PracticeRoles,
  editSavedFiles: PracticeAdminRoles,
  viewGiTrackingSettings: [Roles.GI, Roles.Admin],
  editGiTrackingSettings: PracticeRoles,
  regenerateScan: [Roles.DevAdmin],
  copyScanId: [Roles.DevAdmin, Roles.OpsAdmin],
  needsAttentionResolving: [...PracticeRoles, Roles.HI, Roles.DsoSupport],
  updatePatientBrief: PracticeRoles,
  patientGuidelinesViewer: [Roles.HI, Roles.Admin],
  quickActionsDialog: [Roles.Admin],
  dsoMembers: [Roles.DSOAdmin],
  practiceMembers: PracticeRoles,
  disableMFA: PracticeRoles,
  txTrackerAdminModal: [Roles.DevAdmin]
}

export default {
  BEARER
}
