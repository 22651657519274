import * as React from 'react'

const MissingSpeedInfoLarge = () => (
  <svg
    width="9"
    height="2"
    viewBox="0 0 9 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginBottom: 4 }}
  >
    <path d="M0 1H8.5" stroke="#869ED2" strokeWidth="2" />
  </svg>
)

export default MissingSpeedInfoLarge
