import React from 'react'
import { useTranslation } from 'react-i18next'
import GenericGuidelineAction from './GenericGuidelineAction'
import { LimitXDays } from 'components/common/icons'

const LimitSetForXDays = ({ isChecked, limitDays }) => {
  const { t } = useTranslation()

  return (
    isChecked && (
      <GenericGuidelineAction
        icon={<LimitXDays />}
        text={t('dialogs.hiPatientGuidelinesViewer.actions.limitSetForXDays', { limitDays })}
      />
    )
  )
}

export default LimitSetForXDays
