import moment from 'moment'
import {
  UPPER_ARCH_FILENAME_PREFIX,
  LOWER_ARCH_FILENAME_PREFIX,
  UPPER_LOWER_ARCHES_FILENAME_PREFIX,
  LEFT_BITE_FILENAME_PREFIX,
  RIGHT_BITE_FILENAME_PREFIX
} from 'consts/patientCard'
import { API, graphqlOperation } from 'aws-amplify'
import { getPatientWithRoomWithoutScans, grinScansByPatientIdForGetPatientWithRoom } from 'graphql/customQueries'
import { fetchAll } from './graphqlUtils'

const under18AgeValues = ['Under 13', '13-15', '16-17']

export const calculateAge = ({ birthdate, ageRange }) => {
  let age = null
  if (birthdate) {
    const duration = moment.duration(moment().diff(moment(birthdate)))
    age = `${duration.years()}.${duration.months()}`
  } else if (!isNaN(ageRange)) {
    age = Number(ageRange)
  }

  return {
    age,
    under18: (age && age < 18) || under18AgeValues.includes(ageRange)
  }
}

export const birthdateFormat = date => moment(date).utcOffset(0, false).format('MMM DD, YYYY')

export const getUploadedFiles = record => {
  return (record?.stls || [])
    .filter(stl => !stl.isLoading)
    .reduce(
      (acc, currValue) => {
        acc[currValue.position] += 1
        return acc
      },
      {
        [UPPER_ARCH_FILENAME_PREFIX]: 0,
        [LOWER_ARCH_FILENAME_PREFIX]: 0,
        [UPPER_LOWER_ARCHES_FILENAME_PREFIX]: 0,
        [LEFT_BITE_FILENAME_PREFIX]: 0,
        [RIGHT_BITE_FILENAME_PREFIX]: 0
      }
    )
}

export const getPatientWithRoomAndScans = async patientId => {
  const [patient, scans] = await Promise.all([
    API.graphql(graphqlOperation(getPatientWithRoomWithoutScans, { id: patientId })).then(res => res.data.getPatient),
    fetchAll(grinScansByPatientIdForGetPatientWithRoom, { patientId }, 'grinScansByPatientId').then(allScans => ({
      items: allScans
    }))
  ])

  return { ...patient, grinScans: scans }
}
