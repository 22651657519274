import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { ButtonBase, Grid } from '@material-ui/core'
import PracticePinIcon from '../Icons/PracticePinIcon'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { ArrowDropdownDown, ArrowDropdownUp } from 'components/common/icons'

const useStyles = makeStyles(theme => ({
  label: {
    textDecoration: 'underline',
    maxWidth: 130,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: 12
  }
}))

/**
 * @type {import('components/Search/PracticeSearchV3/PracticeSearchV3').TriggerComponent}
 */
const NotificationCenterPracticeFilterTrigger = ({ isOpen, selectedPractice }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <ButtonBase disableRipple>
      <Grid container alignItems="center" spacing={1} style={{ lineHeight: 0.8 }}>
        <Grid item>
          <PracticePinIcon />
        </Grid>
        <Grid item>
          <DazzedParagraph14 color="black" strong className={classes.label}>
            {selectedPractice?.practiceName ?? t('pages.patients.patientsList.searchByDoctor.placeholder')}
          </DazzedParagraph14>
        </Grid>
        <Grid item>
          {isOpen ? <ArrowDropdownUp color="black" size={10} /> : <ArrowDropdownDown color="black" size={10} />}
        </Grid>
      </Grid>
    </ButtonBase>
  )
}

export default NotificationCenterPracticeFilterTrigger
