import Actions from 'actions'
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, TextField } from '@material-ui/core'
import { conditionTypes } from 'consts/automatedActionConsts'
import { useDispatch, useSelector } from 'react-redux'
import { Autocomplete } from '@material-ui/lab'

const PracticeOnPlan = ({ onChange = () => {}, params = {} }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const grinPlans = useSelector(state => state.billingReducer.grinPlans)

  const options = useMemo(
    () => Object.keys(grinPlans || {})?.map(planKey => ({ value: planKey, label: grinPlans[planKey].displayName })),
    [grinPlans]
  )

  useEffect(() => {
    if (!Object.keys(grinPlans).length) {
      dispatch(Actions.fetchGrinPlans())
    }
  }, [dispatch, grinPlans])

  return (
    <Grid container direction="column">
      <Grid item>
        <Autocomplete
          id="practice-on-plan-selector"
          multiple
          fullWidth
          filterSelectedOptions
          style={{ minWidth: 300 }}
          options={options}
          value={params?.plans || []}
          getOptionLabel={option => option.label}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={t('pages.accountSettings.automations.editorModal.practicePlans')}
            />
          )}
          onChange={(event, updatedSelectedPlans, reason) => {
            onChange({ type: conditionTypes.PracticeOnPlan, params: { plans: updatedSelectedPlans } })
          }}
        />
      </Grid>
    </Grid>
  )
}

export default PracticeOnPlan
