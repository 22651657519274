import React, { useEffect, useRef } from 'react'
import NoteBody from './NoteBody'
import TextAreaAutosize from 'components/common/TextareaAutosize'
import { Grid, makeStyles } from '@material-ui/core'
import { Check2, Close1 } from 'components/common/icons'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { useTranslation } from 'react-i18next'
import { scrollBar } from 'styles/theme'

const useStyles = ({ isHovered }) =>
  makeStyles(theme => ({
    input: {
      border: 'none !important',
      outline: 'none',
      fontFamily: 'Dazzed',
      fontSize: '14px !important',
      fontWeight: 500,
      padding: '0 !important',
      margin: '0 !important',
      backgroundColor: ({ isHovered }) => (isHovered ? '#E5E5E5 !important' : '#F3F3F4 !important'),
      transition: 'background-color 0.3s ease-in-out !important',
      ...scrollBar
    },
    editAction: {
      cursor: 'pointer'
    },
    label: {
      textDecoration: 'underline',
      userSelect: 'none'
    },
    icon: {
      marginRight: 5
    }
  }))({ isHovered })

const NoteContent = ({ isEditMode, body, noteParsedMetadata, setBody, onBodySaved, onBodyDiscarded, isHovered }) => {
  const { t } = useTranslation()
  const noteContentRef = useRef(null)

  const classes = useStyles({ isHovered })

  useEffect(() => {
    if (isEditMode) {
      const end = noteContentRef.current.value.length
      noteContentRef.current.focus()
      noteContentRef.current.setSelectionRange(end, end)
    }
  }, [isEditMode])

  return isEditMode ? (
    <Grid container direction="column">
      <Grid item style={{ marginLeft: 48 }}>
        <TextAreaAutosize
          ref={noteContentRef}
          rowsMax={6}
          style={{ bright: true }}
          className={classes.input}
          keepErrorContainerWhenInactive={false}
          value={body}
          setValue={setBody}
        />
      </Grid>
      <Grid item xs={12} style={{ marginRight: 5 }}>
        <Grid container justifyContent="flex-end" alignItems="center" spacing={1}>
          <Grid item onClick={onBodyDiscarded} className={classes.editAction}>
            <Grid container alignItems="center">
              <Grid item className={classes.icon}>
                <Close1 className={classes.editNoteActionIcon} />
              </Grid>
              <Grid item>
                <DazzedParagraph14 strong color="var(--text-color-3)" className={classes.label}>
                  {t('general.cancel')}
                </DazzedParagraph14>
              </Grid>
            </Grid>
          </Grid>
          <Grid item onClick={onBodySaved} className={classes.editAction}>
            <Grid container alignItems="center">
              <Grid item className={classes.icon}>
                <Check2 color="var(--text-color-3)" width={13} height={9} className={classes.editNoteActionIcon} />
              </Grid>
              <Grid item>
                <DazzedParagraph14 strong color="var(--text-color-3)" className={classes.label}>
                  {t('general.done')}
                </DazzedParagraph14>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <NoteBody body={body} noteParsedMetadata={noteParsedMetadata} />
  )
}

export default NoteContent
