import { Grid, makeStyles } from '@material-ui/core'
import actions from 'actions'
import DazzedHeading18 from 'components/common/text/DazzedHeading18'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { RELEASE_NOTES_CLOUDFRONT_URL } from 'consts/appConsts'
import moment from 'moment'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { trackEvent } from 'utils/analyticsUtils'
import ReleaseImageEmptyState from './ReleaseImageEmptyState'

const useStyles = makeStyles(theme => ({
  releaseImage: {
    width: '100%',
    borderRadius: 16,
    boxShadow: '0px 0px 21px -7px gray'
  },
  releaseItemContainer: {
    padding: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'start',
    marginTop: 20,
    borderRadius: 16,
    '&:hover': {
      backgroundColor: 'rgba(111, 111, 111, 0.05)',
      transition: 'background-color 0.3s',
      cursor: 'pointer'
    }
  },
  releaseItemDetails: {
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: 30
  }
}))

export default ({ title, description, version, versionGuid, lastModified, mediaObject }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleSelectRelease = useCallback(() => {
    trackEvent('Release notes - opened', {
      notesVersion: version
    })
    dispatch(actions.reviewVersionReleaseNotes({ version, versionGuid, description, headlineMedia: mediaObject }))
  }, [dispatch, version, versionGuid, description, mediaObject])

  return (
    <Grid xs={12} className={classes.releaseItemContainer} onClick={handleSelectRelease}>
      <Grid item xs={8} lg={9} className={classes.releaseItemDetails}>
        <Grid container direction="column" justifyContent="space-between">
          <div>
            <DazzedHeading18>{title}</DazzedHeading18>
            <DazzedParagraph14>{description}</DazzedParagraph14>
          </div>
          <DazzedParagraph12>{moment(lastModified).format('LL')}</DazzedParagraph12>
        </Grid>
      </Grid>
      <Grid item xs={4} lg={3}>
        {!mediaObject?.key ? (
          <ReleaseImageEmptyState />
        ) : (
          <img
            src={`${RELEASE_NOTES_CLOUDFRONT_URL}/${mediaObject.key}`}
            alt={title}
            className={classes.releaseImage}
          />
        )}
      </Grid>
    </Grid>
  )
}
