import * as React from 'react'

export default () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2333 11.806C13.3043 12.0728 13.1455 12.3467 12.8786 12.4177L8.52992 13.5747C8.26306 13.6457 7.98917 13.4869 7.91817 13.2201C7.84717 12.9532 8.00595 12.6793 8.27281 12.6083L11.455 11.7617L6.49932 8.88884C6.26042 8.75035 6.17902 8.44441 6.31751 8.20551C6.45601 7.96661 6.76195 7.88521 7.00085 8.0237L11.9565 10.8966L11.1099 7.71437C11.0389 7.44751 11.1977 7.17363 11.4645 7.10263C11.7314 7.03163 12.0053 7.1904 12.0763 7.45726L13.2333 11.806Z"
        fill="#001335"
        stroke="#001335"
        strokeWidth="0.5"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.266971 11.806C0.195971 12.0728 0.354747 12.3467 0.621606 12.4177L4.97032 13.5747C5.23718 13.6457 5.51107 13.4869 5.58207 13.2201C5.65307 12.9532 5.49429 12.6793 5.22744 12.6083L2.04524 11.7617L7.00093 8.88884C7.23983 8.75035 7.32123 8.44441 7.18273 8.20551C7.04424 7.96661 6.7383 7.88521 6.4994 8.0237L1.54371 10.8966L2.39035 7.71437C2.46135 7.44751 2.30257 7.17363 2.03572 7.10263C1.76886 7.03163 1.49497 7.1904 1.42397 7.45726L0.266971 11.806Z"
        fill="#001335"
        stroke="#001335"
        strokeWidth="0.5"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.39666 1.14645C6.59193 0.951184 6.90851 0.951184 7.10377 1.14645L10.2858 4.32843C10.481 4.52369 10.481 4.84027 10.2858 5.03553C10.0905 5.2308 9.77391 5.2308 9.57864 5.03553L7.25022 2.70711V8.54348C7.25022 8.81962 7.02636 9.04348 6.75022 9.04348C6.47408 9.04348 6.25022 8.81962 6.25022 8.54348V2.70711L3.92179 5.03553C3.72653 5.2308 3.40995 5.2308 3.21468 5.03553C3.01942 4.84027 3.01942 4.52369 3.21468 4.32843L6.39666 1.14645Z"
        fill="#001335"
        stroke="#001335"
        strokeWidth="0.5"
        strokeLinecap="round"
      />
    </svg>
  )
}
