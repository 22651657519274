import axios from 'axios'
import { getCloudFrontUrl, getIdTokenFromStorage, getNewMediaCloudfrontUrl } from './storageUtils'
import { fetchS3File } from './s3Utils'
import { logError, logInfo, logWarn } from './logUtils'
import { blobToBase64 } from './fileUtils'
import { getEnvironment } from './awsUtils'
import { getIdToken } from './authUtils'

export const mediaTypes = {
  savedFile: 'savedFile',
  scanSummary: 'scanSummary',
  referralAttachments: 'referralAttachments'
}

export const generateMediaPath = async ({ type, objectIdToAuthorize, keySuffix }) => {
  let objectType, isPrivate

  switch (type) {
    case 'scanReview':
      objectType = 'scanReviews'
      isPrivate = true
      break
    case mediaTypes.scanSummary:
      objectType = 'scanSummaries'
      isPrivate = true
      break
    case mediaTypes.savedFile:
      objectType = 'savedFiles'
      isPrivate = true
      break
    case mediaTypes.referralAttachments:
      objectType = 'referralAttachments'
      isPrivate = true
      break
    default:
      break
  }

  const idToken = await getIdToken()

  return `${isPrivate ? 'private' : 'public'}/${objectType}/${
    isPrivate ? `${objectIdToAuthorize}/` : ''
  }${keySuffix}?idToken=${idToken}`
}

export const putObject = async ({ path, data, contentType, headers = {} }) => {
  const response = await fetch(`${getNewMediaCloudfrontUrl()}/${path}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': contentType,
      ...headers
    },
    body: data
  })

  if (!response.ok) {
    logWarn(`putObject: failed to upload media to path: '${path}'`, {
      responseStatus: response.status,
      response: await response.json(),
      contentType
    })
    throw response
  }
}

export const generateMediaAndUpload = async ({ type, objectIdToAuthorize, keySuffix, data, contentType }) => {
  const path = await generateMediaPath({
    type,
    objectIdToAuthorize,
    keySuffix
  })
  await putObject({
    path,
    data,
    contentType
  })
}

export const getObjectUrl = s3Object =>
  !s3Object
    ? null
    : s3Object?.bucket?.includes('scans')
    ? `${getCloudFrontUrl()}/${
        s3Object.key.includes('scanSummaries') ? s3Object.key.replace('public/', '') : s3Object.key
      }`
    : `${getNewMediaCloudfrontUrl()}/${s3Object.key}?idToken=${getIdTokenFromStorage()}`

export const getObjectForDownload = async url => {
  try {
    logInfo(`New Media: downloading object`, { url })
    const file = await axios.get(url, { responseType: 'blob' })
    return file.data
  } catch (err) {
    console.error('An error occured while trying to get object from download', { url, message: err.message })
  }
}

export const isNewMediaFile = s3Object => !!(s3Object?.key?.includes('private') || s3Object?.bucket?.includes('media'))

export const downloadMedia = file =>
  isNewMediaFile(file) ? getObjectForDownload(getObjectUrl(file)) : fetchS3File(file)

export const loadImage = async imageData => {
  let finalUrl

  if (imageData.type === 'binary/octet-stream') {
    finalUrl = await blobToBase64(imageData)
  } else if (!imageData?.includes('http')) {
    finalUrl = `data:image/png;base64,${imageData}`
  } else {
    finalUrl = imageData
  }

  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => resolve(img)
    img.onerror = error => reject(logError('An error occured while loading image', { error, imageData }))
    img.src = finalUrl
  })
}

export const getImageDataURI = ({ imageUrl, useAmplifyStorage = false }) => {
  const fetchImageData = url => {
    return fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error(`Failed to fetch image: ${response.status} - ${response.statusText}`)
        }
        return response.blob()
      })
      .then(blob => {
        const reader = new FileReader()
        return new Promise(resolve => {
          reader.onloadend = () => {
            const dataUriResult = reader.result.replace('data:binary/octet-stream', 'data:image/png')
            resolve(dataUriResult)
          }
          reader.readAsDataURL(blob)
        })
      })
  }

  return new Promise((resolve, reject) => {
    if (useAmplifyStorage) {
      Storage.get(imageUrl).then(fetchImageData).then(resolve).catch(reject)
    } else {
      fetchImageData(imageUrl).then(resolve).catch(reject)
    }
  })
}

export const getNewMediaBucket = () => `media-bucket-${getEnvironment()}`
export const extractScanIdFromScanSummaryKey = scanSummaryKey => scanSummaryKey?.split?.('/')?.[2] || null
