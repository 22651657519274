import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react'
import { makeStyles } from '@material-ui/core'
import PhoneInput from 'react-phone-input-2'
import { useTranslation } from 'react-i18next'

import { beautifyPhoneNumber } from '../../../utils/stringUtils'

import GrinLabel from './GrinLabel'
import { Pencil } from '../icons'

const useStyles = ({ editMode }) =>
  makeStyles({
    сontainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 'fit-content',
      position: 'relative',
      paddingRight: 20,
      '&:hover > svg': {
        display: editMode ? 'none' : 'block'
      }
    },
    title: {
      marginRight: 5,
      marginBottom: -2,
      height: 22,
      display: 'flex',
      alignItems: 'center'
    },
    value: {
      fontSize: 14,
      display: 'flex',
      alignItems: 'center',
      opacity: 1,
      letterSpacing: 'normal',
      fontWeight: 'normal',
      textTransform: 'none',
      marginBottom: 0,
      height: 22,
      whiteSpace: 'nowrap'
    },
    pencil: {
      position: 'absolute',
      right: 0,
      bottom: 8,
      cursor: 'pointer',
      display: 'none'
    },
    icon: {
      marginRight: 10,
      paddingTop: 6
    },
    phoneInput: {
      outline: 'none',
      border: 'none',
      borderBottom: '1px dashed var(--border-color-9)',
      maxWidth: 115,
      fontFamily: 'Dazzed',
      fontSize: '14px',
      padding: 0,
      backgroundColor: 'var(--bg-color-19)'
    },
    phoneCountries: {
      display: 'none'
    }
  })({ editMode })

const EditablePhoneLabel = ({
  value = {
    countryCode: '',
    phone: ''
  },
  title = '',
  disabled,
  iconComponent,
  className,
  handleSave
}) => {
  const { t } = useTranslation()

  const inputRef = useRef(null)
  const [inputValue, setInputValue] = useState(value)
  const [editMode, setEditMode] = useState(false)

  const classes = useStyles({ editMode })

  useEffect(() => {
    if (editMode) {
      inputRef.current.numberInputRef.focus()
    }
  }, [editMode])

  const handleChangeInputValue = useCallback(
    (value, country) => {
      setInputValue({
        countryCode: value ? country.dialCode || 1 : '',
        phone: value.slice(country.dialCode?.length)
      })
    },
    [setInputValue]
  )

  const handleClickEdit = useCallback(() => {
    setEditMode(true)
  }, [])

  const handleBlurInput = useCallback(() => {
    setEditMode(false)
    if (JSON.stringify(inputValue) !== JSON.stringify(value)) {
      handleSave(inputValue)
    }
  }, [handleSave, inputValue, value])

  const labelValue = useMemo(() => {
    if (inputValue.countryCode === null || inputValue.phone === null || !(inputValue.countryCode + inputValue.phone)) {
      return t('dialogs.patientInfo.na')
    }
    return `+${beautifyPhoneNumber(inputValue.countryCode, inputValue.phone)}`
  }, [inputValue, t])

  return (
    <div className={[classes.сontainer, className].join(' ')}>
      {iconComponent ? (
        <div className={classes.icon}>{iconComponent}</div>
      ) : (
        <GrinLabel className={classes.title}>{title}</GrinLabel>
      )}
      {editMode ? (
        <PhoneInput
          value={value.countryCode === null || value.phone === null ? '' : value.countryCode + value.phone}
          onBlur={handleBlurInput}
          onChange={handleChangeInputValue}
          inputProps={{
            className: classes.phoneInput
          }}
          buttonClass={classes.phoneCountries}
          ref={inputRef}
          disableDropdown
        />
      ) : (
        <GrinLabel className={classes.value}>{labelValue}</GrinLabel>
      )}
      {!disabled && <Pencil className={classes.pencil} onClick={handleClickEdit} />}
    </div>
  )
}

export default EditablePhoneLabel
