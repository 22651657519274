import Actions from 'actions'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export default () => {
  const dispatch = useDispatch()
  const { views, isSynced } = useSelector(state => state.txTrackerReducer.compareStls)
  const { linkEnabled: isLinked } = useSelector(state => state.treatmentReducer.compareScans)

  const syncView = ({ targetView, sourceView }) => {
    targetView.target.copy(sourceView.target)
    targetView.object.position.copy(sourceView.object.position)
    targetView.object.zoom = sourceView.object.zoom
    targetView.object.quaternion.copy(sourceView.object.quaternion)

    targetView.object.updateProjectionMatrix()
  }

  const syncSecondView = useCallback(() => {
    const [firstView, secondView] = views

    syncView({ targetView: firstView, sourceView: secondView })
  }, [views])

  const syncFirstView = useCallback(() => {
    const [firstView, secondView] = views

    syncView({ targetView: secondView, sourceView: firstView })
  }, [views])

  const syncViews = useCallback(() => {
    const [firstView, secondView] = views

    firstView.addEventListener('change', syncFirstView)
    firstView.addEventListener('wheel', syncFirstView)
    secondView.addEventListener('change', syncSecondView)
    secondView.addEventListener('wheel', syncSecondView)

    dispatch(Actions.txTrackerCompareSetSync(true))
  }, [dispatch, syncFirstView, syncSecondView, views])

  const unsyncViews = useCallback(() => {
    const [firstView, secondView] = views

    if (firstView) {
      firstView.removeEventListener('change', syncFirstView)
      firstView.removeEventListener('wheel', syncFirstView)
    }
    if (secondView) {
      secondView.removeEventListener('change', syncSecondView)
      secondView.removeEventListener('wheel', syncSecondView)
    }

    dispatch(Actions.txTrackerCompareSetSync(false))
  }, [dispatch, syncFirstView, syncSecondView, views])

  const onSceneRemoved = useCallback(
    ({ controls }) => {
      dispatch(Actions.txTrackerCompareRemoveView(controls))
      unsyncViews()
    },
    [dispatch, unsyncViews]
  )

  useEffect(() => {
    if (isLinked && views.length === 2 && !isSynced) {
      syncViews()
    }
  }, [dispatch, isLinked, isSynced, syncViews, unsyncViews, views.length])

  useEffect(() => {
    if (!isLinked && views.length === 2 && isSynced) {
      unsyncViews()
    }
  }, [isLinked, isSynced, unsyncViews, views.length])

  return {
    onSceneRemoved
  }
}
