import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import LinkButton from 'components/common/buttons/LinkButton'
import { useSelector } from 'react-redux'
import { trackEvent } from 'utils/analyticsUtils'
import { useBulkActionMutation } from 'store/modules/NotificationCenter'
import { GrinNotificationBulkActions } from '@grin-rnd/grin-api-sdk/dist/Enums/Notifications'

const NotificationCenterQuickActions = () => {
  const { t } = useTranslation()

  const { filters, isBulkActionInProgress } = useSelector(state => state.notificationCenterReducer)
  const [requestBulkAction] = useBulkActionMutation()

  const handleClearAllClicked = useCallback(() => {
    trackEvent(`Notification Center - clear all clicked`)
    requestBulkAction({
      actionType: GrinNotificationBulkActions.ClearAll,
      filters
    })
  }, [requestBulkAction, filters])

  const handleMarkAllAsReadClicked = useCallback(() => {
    trackEvent(`Notification Center - mark all as read clicked`)
    requestBulkAction({
      actionType: GrinNotificationBulkActions.MarkAllAsRead,
      filters
    })
  }, [requestBulkAction, filters])

  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item>
        <LinkButton
          fontSize={12}
          label={t('dialogs.notificationCenterDialog.quickActions.clearAll')}
          onClick={handleClearAllClicked}
          bold
          disabled={isBulkActionInProgress}
        />
      </Grid>
      <Grid item>
        <LinkButton
          fontSize={12}
          label={t('dialogs.notificationCenterDialog.quickActions.markAllAsRead')}
          onClick={handleMarkAllAsReadClicked}
          bold
          disabled={isBulkActionInProgress}
        />
      </Grid>
    </Grid>
  )
}

export default NotificationCenterQuickActions
