import Actions from 'actions'
import { AsyncStatus } from 'consts'
import { removeWhere, updateWhere } from 'utils/arrayUtils'

const initialState = {
  context: {
    accountOwnerId: null,
    practiceName: null,
    savedReplies: [],
    savedFiles: [],
    customTags: [],
    grinPlan: null,
    dso: null,
    hiGroup: null
  },
  assignedDoctors: {
    data: [],
    loadStatus: null
  },
  dsoPractices: {
    data: null,
    status: null,
    inviteDoctor: {
      loadStatus: null,
      error: null
    }
  },
  savedFilesByPractice: {},
  savedRepliesByPractice: {},
  customTagsByPractice: {},
  practiceGuidelines: {},
  practiceFFs: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.MP_SET_CONTEXT_PRACTICE:
      const doctorSM = state.assignedDoctors.data?.find(doc => doc.id === action.payload.doctorId)
      return {
        ...state,
        context: {
          ...state.context,
          accountOwnerId: action.payload.doctorId,
          practiceName: doctorSM?.practiceName,
          grinPlan: action.payload.grinPlan,
          dso: doctorSM?.dso,
          hiGroup: doctorSM?.hiGroup,
          clinicData: doctorSM?.doctor?.clinic
        }
      }
    case Actions.HI_FETCH_ASSIGNED_DOCTORS:
      return {
        ...state,
        assignedDoctors: {
          ...state.assignedDoctors,
          loadStatus: AsyncStatus.Loading
        }
      }
    case Actions.HI_FETCH_ASSIGNED_DOCTORS_RECEIVED:
      return {
        ...state,
        assignedDoctors: {
          ...state.assignedDoctors,
          data: action.payload,
          loadStatus: AsyncStatus.Completed
        }
      }
    case Actions.HI_FETCH_ASSIGNED_DOCTORS_FAILED:
      return {
        ...state,
        assignedDoctors: {
          ...state.assignedDoctors,
          isLoading: false,
          loadStatus: AsyncStatus.Failed
        }
      }
    case Actions.HI_FETCH_PRACTICE_SAVED_REPLIES_RECEIVED:
      return {
        ...state,
        savedRepliesByPractice: {
          ...state.savedRepliesByPractice,
          [action.payload.doctorId]: action.payload.savedReplies
        },
        context: {
          ...state.context,
          savedReplies: action.payload.savedReplies
        }
      }
    case Actions.FETCH_SAVED_FILES_RECEIVED:
      return {
        ...state,
        context: {
          ...state.context,
          savedFiles: action?.payload?.savedFiles || []
        },
        savedFilesByPractice: {
          ...state.savedFilesByPractice,
          [action.payload.accountOwnerId]: action?.payload?.savedFiles || []
        }
      }
    case Actions.MP_FETCH_CUSTOM_TAGS_BY_DOCTOR_RECEIVED:
      return {
        ...state,
        customTagsByPractice: {
          ...state.customTagsByPractice,
          [action.payload.doctorId]: action.payload.customTags
        },
        context: {
          ...state.context,
          customTags: action.payload.customTags
        }
      }
    case Actions.PATIENT_REQUESTED:
      return {
        ...state,
        practiceGuidelines: initialState.practiceGuidelines
      }
    case Actions.HI_FETCH_PRACTICE_GUIDELINES_RECEIVED:
      return {
        ...state,
        practiceGuidelines: action.payload
      }
    case Actions.MP_FETCH_PRACTICE_FFS_RECEIVED:
      return {
        ...state,
        practiceFFs: action.payload
      }
    case Actions.MP_FETCH_DSO_PRACTICES:
      return {
        ...state,
        dsoPractices: {
          ...state.dsoPractices,
          status: AsyncStatus.Loading
        }
      }
    case Actions.MP_FETCH_DSO_PRACTICES_RECEIVED:
      return {
        ...state,
        dsoPractices: {
          ...state.dsoPractices,
          data: action.payload,
          status: AsyncStatus.Completed
        }
      }
    case Actions.MP_FETCH_DSO_PRACTICES_FAILED:
      return {
        ...state,
        dsoPractices: {
          ...state.dsoPractices,
          status: AsyncStatus.Failed
        }
      }
    case Actions.INVITE_NEW_DSO:
      return {
        ...state,
        dsoPractices: {
          ...state.dsoPractices,
          status: AsyncStatus.Loading
        }
      }
    case Actions.INVITE_NEW_DSO_RECEIVED:
      return {
        ...state,
        dsoPractices: {
          ...state.dsoPractices,
          data: {
            ...state.dsoPractices.data,
            dsoUsers: [...state.dsoPractices.data.dsoUsers, action.payload]
          },
          status: AsyncStatus.Completed
        }
      }
    case Actions.INVITE_NEW_DSO_FAILED:
      return {
        ...state,
        dsoPractices: {
          ...state.dsoPractices,
          status: AsyncStatus.Failed
        }
      }
    case Actions.DSO_INVITE_PRACTICE_MEMBER:
      return {
        ...state,
        dsoPractices: {
          ...state.dsoPractices,
          status: AsyncStatus.Loading
        }
      }
    case Actions.DSO_INVITE_PRACTICE_MEMBER_RECEIVED:
      return {
        ...state,
        dsoPractices: {
          ...state.dsoPractices,
          data: {
            ...state.dsoPractices.data,
            practices: updateWhere(
              state.dsoPractices.data.practices,
              practice => practice.accountOwnerId === action.payload.accountOwnerId,
              practice => (practice.practiceMembers = [...practice.practiceMembers, action.payload])
            )
          },
          status: AsyncStatus.Completed
        }
      }
    case Actions.DSO_INVITE_PRACTICE_MEMBER_FAILED:
      return {
        ...state,
        dsoPractices: {
          ...state.dsoPractices,
          status: AsyncStatus.Failed
        }
      }
    case Actions.DSO_CHANGE_PRACTICE_MEMBER_ACCESS:
    case Actions.DSO_DEACTIVATE_PRACTICE_MEMBER:
    case Actions.DSO_ACTIVATE_PRACTICE_MEMBER:
    case Actions.DSO_RESEND_PRACTICE_MEMBER_INVITATION:
    case Actions.DSO_CANCEL_PRACTICE_MEMBER_INVITATION:
      return {
        ...state,
        dsoPractices: {
          ...state.dsoPractices,
          staus: AsyncStatus.Loading
        }
      }
    case Actions.DSO_CHANGE_PRACTICE_MEMBER_ACCESS_RECEIVED:
    case Actions.DSO_DEACTIVATE_PRACTICE_MEMBER_RECEIVED:
    case Actions.DSO_ACTIVATE_PRACTICE_MEMBER_RECEIVED:
      return {
        ...state,
        dsoPractices: {
          isLoading: false,
          ...state.dsoPractices,
          data: {
            ...state.dsoPractices.data,
            practices: updateWhere(
              state.dsoPractices.data.practices || [],
              practice => practice.accountOwnerId === action.payload.accountOwnerId,
              practice => {
                practice.practiceMembers = updateWhere(
                  practice.practiceMembers,
                  practiceMember => practiceMember.id === action.payload.practiceMember.id,
                  practiceMember => {
                    practiceMember.accessType = action.payload.practiceMember.accessType
                    practiceMember.status = action.payload.practiceMember.auth_status
                  }
                )
              }
            )
          }
        }
      }
    case Actions.DSO_CANCEL_PRACTICE_MEMBER_INVITATION_RECEIVED:
      return {
        ...state,
        dsoPractices: {
          isLoading: false,
          ...state.dsoPractices,
          data: {
            ...state.dsoPractices.data,
            practices: updateWhere(
              state.dsoPractices.data.practices || [],
              practice => practice.accountOwnerId === action.payload.accountOwnerId,
              practice => {
                practice.practiceMembers = removeWhere(
                  practice.practiceMembers,
                  practiceMember => practiceMember.id === action.payload.id
                )
              }
            )
          }
        }
      }
    case Actions.DSO_RESEND_PRACTICE_MEMBER_INVITATION_RECEIVED:
      return {
        ...state,
        dsoPractices: {
          isLoading: false,
          ...state.dsoPractices,
          data: {
            ...state.dsoPractices.data,
            practices: updateWhere(
              state.dsoPractices.data.practices || [],
              practice => practice.accountOwnerId === action.payload.accountOwnerId,
              practice => {
                practice.practiceMembers = updateWhere(
                  practice.practiceMembers,
                  practiceMember => practiceMember.id === action.payload.id,
                  practiceMember => {
                    practiceMember.firstName = action.payload.firstName
                    practiceMember.lastName = action.payload.lastName
                    practiceMember.email = action.payload.email
                    practiceMember.name = `${action.payload.firstName} ${action.payload.lastName}`
                  }
                )
              }
            )
          }
        }
      }
    case Actions.DSO_CHANGE_PRACTICE_MEMBER_ACCESS_FAILED:
    case Actions.DSO_DEACTIVATE_PRACTICE_MEMBER_FAILED:
    case Actions.DSO_ACTIVATE_PRACTICE_MEMBER_FAILED:
    case Actions.DSO_RESEND_PRACTICE_MEMBER_INVITATION_FAILED:
    case Actions.DSO_CANCEL_PRACTICE_MEMBER_INVITATION_FAILED:
      return {
        ...state,
        dsoPractices: {
          ...state.dsoPractices,
          staus: AsyncStatus.Failed
        }
      }
    case Actions.DSO_INVITE_DOCTOR:
      return {
        ...state,
        dsoPractices: {
          ...state.dsoPractices,
          inviteDoctor: {
            ...state.dsoPractices.inviteDoctor,
            loadStatus: AsyncStatus.Loading,
            error: null
          }
        }
      }
    case Actions.DSO_INVITE_DOCTOR_RECEIVED:
      return {
        ...state,
        dsoPractices: {
          ...state.dsoPractices,
          data: {
            ...(state.dsoPractices.data || {}),
            invitedPractices: [...(state.dsoPractices.data?.invitedPractices || []), action.payload]
          },
          inviteDoctor: {
            ...state.dsoPractices.inviteDoctor,
            loadStatus: AsyncStatus.Completed,
            error: null
          }
        }
      }
    case Actions.DSO_INVITE_DOCTOR_FAILED:
      return {
        ...state,
        dsoPractices: {
          ...state.dsoPractices,
          inviteDoctor: {
            ...state.dsoPractices.inviteDoctor,
            loadStatus: AsyncStatus.Failed,
            error: action.payload.response?.data
          }
        }
      }
    case Actions.SIGNOUT_REQUESTED:
      return initialState
    default:
      return state
  }
}
