import React, { useEffect, useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import DazzedHeading16 from 'components/common/text/DazzedHeading14'
import LabeledInputContainer from 'components/common/inputs/LabeledInputContainer'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import useScanSummarySettings from './useScanSummarySettings'
import { useDispatch, useSelector } from 'react-redux'
import Actions from 'actions'
import SelectInput from 'components/common/SelectInput'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  input: {
    width: '150px!important'
  }
}))

const ScanSummarySettings = ({ enabled = false, settings = {}, onChange = () => {} }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { gaVersion, actualVersion } = useScanSummarySettings(settings)
  const scanSummaryVersions = useSelector(state => state.supportReducer.scanSummaryVersions)

  const scanSummaryVersionOptions = useMemo(
    () =>
      [{ value: '', label: t('general.none') }].concat(
        scanSummaryVersions.data.map(v => ({ label: v, value: v })) || []
      ),
    [scanSummaryVersions, t]
  )

  useEffect(() => {
    dispatch(Actions.supportFetchScanSummaryVersions())
  }, [dispatch])

  return (
    <Grid container direction="column" justifyContent="flex-start" className={classes.root}>
      <Grid item>
        <DazzedHeading16 strong>{t('dialogs.doctorCardEditor.scanSummarySettings.title')}</DazzedHeading16>
      </Grid>
      <Grid item>
        <Grid container direction="row" alignItems="flex-start">
          <Grid item xs={3}>
            <LabeledInputContainer title={t('dialogs.doctorCardEditor.scanSummarySettings.currentGA.label')}>
              <DazzedParagraph14 strong>{gaVersion}</DazzedParagraph14>
            </LabeledInputContainer>
          </Grid>
          <Grid item xs={4}>
            <SelectInput
              options={scanSummaryVersionOptions}
              value={settings.experimental || ''}
              onChange={({ value }) => onChange({ ...settings, experimental: value })}
              style={{ bright: true }}
              disabled={!enabled}
              label={t('dialogs.doctorCardEditor.scanSummarySettings.experimentalVersion.label')}
              tooltip={t('dialogs.doctorCardEditor.scanSummarySettings.experimentalVersion.tooltip')}
              selectStyle={{ width: '150px' }}
            />
          </Grid>
          <Grid item xs={3}>
            <SelectInput
              options={scanSummaryVersionOptions}
              value={settings.force || ''}
              onChange={({ value }) => onChange({ ...settings, force: value })}
              style={{ bright: true }}
              disabled={!enabled}
              label={t('dialogs.doctorCardEditor.scanSummarySettings.forceVersion.label')}
              tooltip={t('dialogs.doctorCardEditor.scanSummarySettings.forceVersion.tooltip')}
              selectStyle={{ width: '150px' }}
            />
          </Grid>
          <Grid item xs={2}>
            <LabeledInputContainer
              title={t('dialogs.doctorCardEditor.scanSummarySettings.finalVersion.label')}
              tooltip={t('dialogs.doctorCardEditor.scanSummarySettings.finalVersion.tooltip')}
            >
              <DazzedParagraph14 strong>{actualVersion}</DazzedParagraph14>
            </LabeledInputContainer>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ScanSummarySettings
