import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { GrinNotificationStatuses } from '@grin-rnd/grin-api-sdk/dist/Enums/Notifications'
import SmallDotIcon from 'components/common/icons/SmallDot'
import Tooltip from 'components/common/Tooltip'
import moment from 'moment'
import useDynamicTimeAgo from 'hooks/useDynamicTimeAgo'
import NotificationTypeLabel from './NotificationTypeLabel'

const NotificationHeader = ({
  eventDate,
  notificationType,
  titleClassName = '',
  customTitle,
  notificationStatus,
  dotIconColor = 'var(--text-color-3)'
}) => {
  const { t } = useTranslation()

  const relativeEventDate = useDynamicTimeAgo({ date: eventDate, withoutSuffix: true })

  return (
    <Grid container alignItems="center" justifyContent="space-between">
      <Grid item>
        {customTitle ? (
          customTitle
        ) : (
          <NotificationTypeLabel className={titleClassName}>
            {t(`dialogs.notificationCenterDialog.notificationTypes.${notificationType}`)}
          </NotificationTypeLabel>
        )}
      </Grid>
      <Grid item>
        <Grid container alignItems="center">
          <Grid item>
            <Tooltip value={moment(eventDate).format('LLL')} placement="bottom">
              <DazzedParagraph14 color={'var(--text-color-62)'} strong>
                {relativeEventDate}
              </DazzedParagraph14>
            </Tooltip>
          </Grid>
          {notificationStatus === GrinNotificationStatuses.New && (
            <Grid item style={{ marginLeft: 4 }}>
              <SmallDotIcon color={dotIconColor} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default NotificationHeader
