import Actions from 'actions'
import SendSelectedImages from 'components/common/icons/timelineV2/scan-feed/SendSelectedImages'
import useSendChatMessage from 'hooks/useSendChatMessage'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

export default () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [isSendInChatCooldownActive, setIsSendInChatCooldownActive] = useState(false)

  const { sendMessage } = useSendChatMessage()

  const handleSendInChat = useCallback(
    imageBase64 => {
      sendMessage({
        type: 'image',
        content: Buffer.from(imageBase64.replace(/^data:image\/\w+;base64,/, ''), 'base64'),
        cache: imageBase64
      })

      // Displaying a snackbar and disabling the button for as a better indication that the image was sent

      dispatch(
        Actions.showSnackbar({
          type: 'success',
          text: t('messages.chat.imageSentSuccessfully')
        })
      )

      setIsSendInChatCooldownActive(true)
      setTimeout(() => setIsSendInChatCooldownActive(false), 2000)
    },
    [sendMessage, t, dispatch]
  )

  const customActions = useMemo(
    () => [
      {
        icon: <SendSelectedImages color="var(--text-color-3)" />,
        label: t('pages.patients.selectedPatient.timelineV2.scanFeed.sendInChat'),
        disabled: isSendInChatCooldownActive,
        onClick: handleSendInChat
      }
    ],
    [t, handleSendInChat, isSendInChatCooldownActive]
  )

  return customActions
}
