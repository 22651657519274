import { DELETED_MESSAGE_CONTENT } from 'consts/chatConsts'
import { OH_NOTE_MSG_TYPE } from 'consts/oralHygieneNoteConsts'
import moment from 'moment'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

export default () => {
  const messages = useSelector(state => state.chatReducer.messages)
  const username = useSelector(state => state.authReducer.username)
  const scheduledMessages = useSelector(state => state.chatReducer.scheduledMessages)

  const sortedMessages = useMemo(
    () => ({
      ...messages,
      items: [
        ...(messages.items || [])
          .filter(msg => msg.content !== DELETED_MESSAGE_CONTENT) // Backwards compatibility
          .filter(msg => msg.isDeleted !== true || msg.type !== OH_NOTE_MSG_TYPE) // Only oral hygiene recommendation messages should be hidden if deleted
          .map(message => {
            const timestamp =
              message.owner === username ? message.uploadingDate || message.createdAt : message.createdAt
            return { ...message, timestamp }
          }),
        ...scheduledMessages.items?.map(scheduledMessage => ({
          ...JSON.parse(scheduledMessage.payload),
          isLocal: scheduledMessage.isLocal,
          isDeleting: scheduledMessage.isDeleting,
          _version: scheduledMessage._version,
          createdAt: scheduledMessage.createdAt,
          scheduleTime: scheduledMessage.scheduleTime,
          cache: scheduledMessage.cache,
          timestamp: scheduledMessage.scheduleTime,
          isScheduled: true
        }))
      ].sort((m1, m2) => {
        const diff = moment.utc(m1.timestamp).diff(moment.utc(m2.timestamp))

        if (diff > 0) {
          return 1
        } else if (diff < 0) {
          return -1
        } else {
          return moment.utc(m1.createdAt).isAfter(moment.utc(m2.createdAt)) ? 1 : -1
        }
      })
    }),
    [messages, username, scheduledMessages]
  )

  return sortedMessages
}
