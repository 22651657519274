import React, { useMemo } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { useTranslation } from 'react-i18next'
import GrinTag from 'components/common/tags/GrinTag'
import { useSelector } from 'react-redux'
import { FromToArrow } from 'components/common/icons'
import ScanDateLabel from 'components/common/ScanDateLabel'
import Divider from './Divider'

const useStyles = makeStyles(() => ({
  root: {
    padding: '22px 0px'
  },
  treatmentTypeChangedContainer: {
    marginBottom: 5
  }
}))

const TreatmentTypeUpdatedTimelineItem = ({ timelineItem }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { data: systemTags } = useSelector(state => state.profileReducer.systemTags)
  const oldTreatmentTag = useMemo(
    () => systemTags.find(tag => tag.value === timelineItem?.payload?.oldTreatmentType),
    [systemTags, timelineItem]
  )
  const treatmentTag = useMemo(
    () => systemTags.find(tag => tag.value === timelineItem?.payload?.treatmentType),
    [systemTags, timelineItem]
  )

  return (
    <div>
      <Grid container justifyContent="space-between" direction="column" className={classes.root}>
        <Grid item className={classes.treatmentTypeChangedContainer}>
          <Grid container alignItems="center">
            <Grid item>
              <DazzedParagraph14>
                {t('pages.patients.selectedPatient.timelineV2.mainFeed.treatmentTypeChangedItem.description')}
              </DazzedParagraph14>
            </Grid>
            <Grid item style={{ marginLeft: 4 }}>
              <GrinTag tag={oldTreatmentTag} />
            </Grid>
            <Grid item style={{ marginLeft: 4 }}>
              <FromToArrow />
            </Grid>
            <Grid item style={{ marginLeft: 4 }}>
              <GrinTag tag={treatmentTag} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <ScanDateLabel timestamp={timelineItem.eventDate} color="var(--text-color-11)" />
        </Grid>
      </Grid>
      <Divider />
    </div>
  )
}

export default TreatmentTypeUpdatedTimelineItem
