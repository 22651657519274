import React, { useMemo } from 'react'
import { makeStyles, Grid } from '@material-ui/core'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import { Pinned, Unpin } from 'components/common/icons'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'

const useStyles = makeStyles({
  container: {
    padding: 5
  },
  creator: {
    marginTop: 10,
    marginBottom: 5,
    opacity: 0.5
  },
  divider: {
    padding: '0px !important',
    width: '100%',
    height: 1,
    backgroundColor: '#E8E8E8',
    marginTop: 17
  },
  assigneeName: {
    fontWeight: 600
  },
  pinContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    cursor: 'pointer'
  }
})

const NoteItem = ({ id, body, date, isPinned, metadata, onPinToggled, doctorUserId, testIdPrefix = '' }) => {
  const classes = useStyles()

  const { creatorName, creatorRoleDescription, assigneeName } = useMemo(() => {
    const { creatorName, creatorRoleDescription, creatorUserId, assigneeName } = JSON.parse(metadata || '{}')
    return {
      creatorName: creatorName ? creatorName.trim() : '',
      creatorRoleDescription: creatorRoleDescription ? creatorRoleDescription.trim() : '',
      creatorUserId: creatorUserId ? creatorUserId.trim() : '',
      assigneeName: assigneeName ? assigneeName.trim() : ''
    }
  }, [metadata])

  return (
    <Grid container direction="column" className={classes.container}>
      <Grid item className={classes.creator}>
        <Grid container alignItems="center">
          <Grid item>
            <DazzedParagraph12>{`${creatorName} ${
              creatorRoleDescription ? `(${creatorRoleDescription})` : ''
            }`}</DazzedParagraph12>
          </Grid>
          <Grid item>
            <DazzedParagraph12 thickness="thin">{date}</DazzedParagraph12>
          </Grid>
        </Grid>
      </Grid>
      <Grid item style={{ display: 'contents' }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={10}>
            <DazzedParagraph14 strong>
              {!!assigneeName && <span className={classes.assigneeName}>{`@${assigneeName} `}</span>}
              {body.split('\n').map(line => line || <br />)}
            </DazzedParagraph14>
          </Grid>
          <Grid item xs={1} onClick={onPinToggled} id={`${testIdPrefix}-pin-button`} className={classes.pinContainer}>
            {isPinned ? <Unpin /> : <Pinned />}
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.divider} />
    </Grid>
  )
}

export default NoteItem
