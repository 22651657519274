import React from 'react'
import { makeStyles } from '@material-ui/styles'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'

const useStyles = makeStyles(theme => ({
  itemContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 20
  },
  title: {
    color: 'var(--text-color-11)'
  }
}))

const GuidelineItem = ({ title, children, className = '' }) => {
  const classes = useStyles()

  return (
    <div className={[classes.itemContainer, className].join(' ')}>
      <div>
        <DazzedParagraph14 bold className={classes.title}>
          {title}
        </DazzedParagraph14>
      </div>
      <div>{children}</div>
    </div>
  )
}

export default GuidelineItem
