import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import UntrackedTeeth from 'components/common/icons/gi/UntrackedTeeth'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import { PATIENT_CARD_TABS } from 'consts/patientCard'
import useOpenPatientCard from 'hooks/useOpenPatientCard'
import useRolePermissions from 'hooks/useRolePermissions'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { sortTeeth } from 'utils/giUtils'
import { isMobile } from 'utils/mobileUtils'

const useStyles = makeStyles(theme => ({
  icon: {
    marginTop: 3,
    marginRight: 4
  },
  untrackedTeeth: {
    color: 'var(--text-color-44)'
  },
  editButton: {
    color: 'var(--text-color-25)',
    cursor: 'pointer',
    textDecoration: 'underline',
    marginLeft: 7
  }
}))

const UntrackedTeethList = ({ teethList = [], textClassName }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const openPatientCard = useOpenPatientCard()
  const { permissions } = useRolePermissions()

  return (
    <Grid container alignItems="center">
      <Grid item className={classes.icon}>
        <UntrackedTeeth />
      </Grid>
      <Grid item>
        <DazzedParagraph12 thickness="thin" className={[classes.untrackedTeeth, textClassName].join(' ')}>
          {t('pages.patients.selectedPatient.chat.gi.untrackedTeeth', {
            teeth: sortTeeth(teethList).join(', ')
          })}
        </DazzedParagraph12>
      </Grid>
      {!isMobile() && permissions.editGiTrackingSettings && (
        <Grid item>
          <DazzedParagraph12
            className={classes.editButton}
            onClick={() => openPatientCard({ tabIndex: PATIENT_CARD_TABS.giTrackSettings.index })}
          >
            {t('general.settings')}
          </DazzedParagraph12>
        </Grid>
      )}
    </Grid>
  )
}

export default UntrackedTeethList
