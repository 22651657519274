import { Storage } from 'aws-amplify'
import { isEmpty, remove } from 'lodash'
import { ofType } from 'redux-observable'
import { forkJoin, from, iif, of } from 'rxjs'
import {
  catchError,
  filter,
  ignoreElements,
  map,
  mapTo,
  mergeMap,
  pluck,
  skipWhile,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs/operators'
import Actions from '../actions'
import { ROUTES } from '../consts'
import { RELEASE_NOTES_BUCKET, RELEASE_NOTES_PUBLISH_FILE } from '../consts/appConsts'
import { parseFeatureFlags } from '../utils/mappers/featureFlagsMappers'
import { mapEditorToDto } from '../utils/mappers/releaseNotesMapper'
import { extractMinorVersion, formatRawVersionData, isVersionPublished } from '../utils/releaseNotesUtils'
import i18n from '../resources/locales/i18n'

export const fetchPublishFileTrigger = action$ =>
  action$.pipe(ofType(Actions.DOCTOR_DETAILS_RECEIVED), pluck('payload'), mapTo(Actions.fetchPublishFile()))

export const uploadReleaseNoteMediaEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.UPLOAD_RELEASE_NOTE_MEDIA),
    withLatestFrom(state$),
    map(([action, state]) => ({
      media: action.payload.media,
      slideId: action.payload.slideId,
      versionGuid: state.releaseNotesReducer.editor.versionGuid
    })),
    switchMap(({ versionGuid, media, slideId }) =>
      from(putFile(`${versionGuid}/${media.key}`, media.data, true)).pipe(
        mergeMap(() =>
          of(
            Actions.uploadReleaseNoteMediaReceived({
              slideId,
              media: {
                key: `${versionGuid}/${media.key}`,
                bucket: RELEASE_NOTES_BUCKET
              }
            })
          )
        ),
        catchError(error => of(Actions.fetchRejected(error)))
      )
    )
  )

export const saveReleaseNotesEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.SAVE_RELEASE_NOTES),
    withLatestFrom(state$),
    map(([action, state]) => mapEditorToDto(state.releaseNotesReducer.editor)),
    switchMap(versionData =>
      from(putFile(`${versionData.versionGuid}.json`, versionData)).pipe(
        mergeMap(() => of(Actions.saveReleaseNotesReceived())),
        catchError(error => of(Actions.saveReleaseNotesFailed(error)))
      )
    )
  )

export const saveReleaseNotesFailedEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.SAVE_RELEASE_NOTES_FAILED),
    map(() =>
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('messages.somethingWentWrong')
      })
    )
  )

export const fetchAllReleaseNotesEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.FETCH_ALL_RELEASE_NOTES),
    withLatestFrom(state$),
    map(([action, state]) => state.releaseNotesReducer.publish),
    switchMap(publish =>
      from(getReleaseNotesMetadata()).pipe(
        mergeMap(versionIds =>
          iif(
            () => !versionIds || !versionIds.length,
            of(Actions.fetchAllReleaseNotesReceived([])),
            forkJoin(
              versionIds.map(({ key, lastModified }) =>
                getFile(key).then(data => ({
                  ...data,
                  lastModified: lastModified
                }))
              )
            ).pipe(
              map(data => data.filter(version => !version._deleted)),
              map(data => data.sort((v1, v2) => v2.version.localeCompare(v1.version))),
              tap(data =>
                data
                  .filter(version => isVersionPublished(publish, version))
                  .forEach(publishedVersion => (publishedVersion.isPublished = true))
              ),
              mergeMap(data => of(Actions.fetchAllReleaseNotesReceived(data))),
              catchError(err => of(Actions.fetchRejected(err)))
            )
          )
        ),
        catchError(err => of(Actions.fetchRejected(err)))
      )
    )
  )

export const fetchPublishFileEpic = action$ =>
  action$.pipe(
    ofType(Actions.FETCH_PUBLISH_FILE),
    switchMap(() =>
      from(getFile(RELEASE_NOTES_PUBLISH_FILE)).pipe(
        map(publishFile => publishFile.map(formatRawVersionData)),
        mergeMap(data => of(Actions.fetchPublishFileReceived(data))),
        catchError(err => of(Actions.fetchRejected(err)))
      )
    )
  )

export const fetchUnreviewedReleaseNotes = (action$, state$) =>
  action$.pipe(
    ofType(Actions.FETCH_PUBLISH_FILE_RECEIVED),
    withLatestFrom(state$),
    map(([action, state]) => ({
      publishFile: action.payload,
      reviewedVersion: parseFeatureFlags(state.profileReducer.doctor.user)?.releaseNotes,
      userVersion: process.env.REACT_APP_VERSION
    })),
    skipWhile(({ publishFile }) => isEmpty(publishFile)),
    map(({ publishFile, userVersion, reviewedVersion }) =>
      publishFile.find(
        ({ version }) =>
          (version === userVersion && version !== reviewedVersion) ||
          (extractMinorVersion(version) === extractMinorVersion(userVersion) &&
            extractMinorVersion(version) !== extractMinorVersion(reviewedVersion))
      )
    ),
    filter(version => !isEmpty(version)),
    map(version => Actions.reviewVersionReleaseNotes(version))
  )

export const reviewVersionReleaseNotes = (action$, state$) =>
  action$.pipe(
    ofType(Actions.REVIEW_VERSION_RELEASE_NOTES),
    withLatestFrom(state$),
    map(([action, state]) => ({
      version: action.payload.version,
      versionGuid: action.payload.versionGuid,
      isFirstTime: state.profileReducer.showWelcomePopup,
      publishedReleaseNotes: state.appReducer.publishedReleaseNotes?.data || {}
    })),
    filter(({ isFirstTime }) => !isFirstTime),
    switchMap(({ version, versionGuid, publishedReleaseNotes }) => {
      const publishedVersion = Object.values(publishedReleaseNotes).find(
        versionData => versionData.originalData?.version === version
      )
      return publishedVersion ? of(publishedVersion.originalData) : from(getFile(`${versionGuid}.json`))
    }),
    mergeMap(releaseNotes => of(Actions.reviewVersionReleaseNotesReceived(releaseNotes))),
    catchError(err => of(Actions.fetchRejected(err)))
  )

export const updateReleaseNotesFlagsEpic = action$ =>
  action$.pipe(
    ofType(Actions.REVIEW_VERSION_RELEASE_NOTES),
    pluck('payload', 'version'),
    map(version => Actions.requestUpsertFeatureFlags({ releaseNotes: version }))
  )

export const publishReleaseNotesEpic = action$ =>
  action$.pipe(
    ofType(Actions.PUBLISH_RELEASE_NOTES),
    pluck('payload'),
    switchMap(({ version, versionGuid }) =>
      from(getFile(RELEASE_NOTES_PUBLISH_FILE)).pipe(
        map(publishFile => upsertPublishedVersion(publishFile, version, versionGuid)),
        mergeMap(publishFile =>
          from(putFile(RELEASE_NOTES_PUBLISH_FILE, publishFile)).pipe(
            mergeMap(() => of(Actions.publishReleaseNotesReceived(publishFile.map(formatRawVersionData)))),
            catchError(error => of(Actions.saveReleaseNotesFailed(error)))
          )
        ),
        catchError(error => of(Actions.saveReleaseNotesFailed(error)))
      )
    )
  )

export const requestEditReleaseNotesEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.REQUEST_EDIT_RELEASE_NOTES),
    withLatestFrom(state$),
    map(([action, state]) => ({
      requestedVersionGuid: action.payload,
      versions: state.releaseNotesReducer.versions
    })),
    switchMap(({ requestedVersionGuid, versions }) =>
      iif(
        () => versions.find(version => version.versionGuid === requestedVersionGuid),
        of(Actions.openReleaseNotesEditor(versions.find(version => version.versionGuid === requestedVersionGuid))),
        from(getFile(`${requestedVersionGuid}.json`)).pipe(
          mergeMap(releaseNotes => of(Actions.openReleaseNotesEditor(releaseNotes))),
          catchError(err => of(Actions.fetchRejected(err)))
        )
      )
    )
  )

export const unpublishReleaseNotesEpic = action$ =>
  action$.pipe(
    ofType(Actions.REQUEST_UNPUBLISH_RELEASE_NOTES),
    pluck('payload'),
    switchMap(versionGuid =>
      from(getFile(RELEASE_NOTES_PUBLISH_FILE)).pipe(
        tap(publish => remove(publish, version => version.versionGuid === versionGuid)),
        mergeMap(publish =>
          from(putFile(RELEASE_NOTES_PUBLISH_FILE, publish)).pipe(
            mergeMap(() => of(Actions.unpublishReleaseNotesReceived(publish.map(formatRawVersionData)))),
            catchError(err => of(Actions.unpublishReleaseNotesFailed(err)))
          )
        ),
        catchError(err => of(Actions.unpublishReleaseNotesFailed(err)))
      )
    )
  )

export const unpublishReleaseNotesSideEffect = action$ =>
  action$.pipe(
    ofType(Actions.UNPUBLISH_RELEASE_NOTES_RECEIVED),
    pluck('payload'),
    map(newPublish => Actions.fetchPublishFileReceived(newPublish))
  )

export const unpublishReleaseNotesFailureEpic = action$ =>
  action$.pipe(
    ofType(Actions.UNPUBLISH_RELEASE_NOTES_FAILED),
    mapTo(
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('messages.somethingWentWrong')
      })
    )
  )

export const deleteReleaseNotesEpic = action$ =>
  action$.pipe(
    ofType(Actions.REQUEST_DELETE_RELEASE_NOTES),
    pluck('payload'),
    switchMap(versionGuid =>
      from(getFile(`${versionGuid}.json`)).pipe(
        tap(version => (version._deleted = true)),
        mergeMap(version =>
          from(putFile(`${versionGuid}.json`, version)).pipe(
            mergeMap(() => of(Actions.deleteReleaseNotesReceived(versionGuid))),
            catchError(err => of(Actions.deleteReleaseNotesFailed(err)))
          )
        ),
        catchError(err => of(Actions.deleteReleaseNotesFailed(err)))
      )
    )
  )

export const unpublishDeletedReleaseNotesEpic = action$ =>
  action$.pipe(
    ofType(Actions.DELETE_RELEASE_NOTES_RECEIVED),
    pluck('payload'),
    map(versionGuid => Actions.requestUnpublishReleaseNotes(versionGuid))
  )

export const closeEditorAfterDeletionEpic = (action$, state$, { history }) =>
  action$.pipe(
    ofType(Actions.DELETE_RELEASE_NOTES_RECEIVED),
    withLatestFrom(state$),
    map(([action, state]) => state.router.location.pathname.includes(ROUTES.RELEASE_NOTES_EDITOR)),
    filter(isInEditor => isInEditor),
    tap(() => history.push(ROUTES.RELEASE_NOTES_DASHBOARD)),
    ignoreElements()
  )

export const deleteReleaseNotesFailureEpic = action$ =>
  action$.pipe(
    ofType(Actions.DELETE_RELEASE_NOTES_FAILED),
    mapTo(
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('messages.somethingWentWrong')
      })
    )
  )

const upsertPublishedVersion = (publishFile, version, versionGuid) => {
  const versionIndex = publishFile.findIndex(releaseNotes => releaseNotes.versionGuid === versionGuid)
  if (versionIndex === -1) {
    return publishFile.concat({ version, versionGuid })
  }
  const updatedPublish = [...publishFile]
  updatedPublish[versionIndex] = { version, versionGuid }
  return updatedPublish
}

const storageConfig = {
  bucket: RELEASE_NOTES_BUCKET
}

const getFile = key =>
  Storage.get(key, storageConfig)
    .then(fetch)
    .then(res => res.json())

const putFile = (key, data, raw = false) => Storage.put(key, raw ? data : JSON.stringify(data), storageConfig)

const getReleaseNotesMetadata = () =>
  Storage.list('', storageConfig)
    .then(objectNames =>
      objectNames.map(object => ({
        key: object.key,
        lastModified: object.lastModified
      }))
    )
    .then(objectNames => objectNames.filter(version => version.key !== RELEASE_NOTES_PUBLISH_FILE))
    .then(objectNames => objectNames.filter(version => version.key.endsWith('.json')))
