import { ButtonBase, CircularProgress, Grid, makeStyles } from '@material-ui/core'
import Actions from 'actions'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import { AsyncStatus } from 'consts'
import { TIME_FORMAT_8 } from 'consts/dateTimeConsts'
import { GI_RESULT_MSG_TYPE } from 'consts/giConsts'
import { OH_NOTE_MSG_TYPE } from 'consts/oralHygieneNoteConsts'
import useMessageSender from 'hooks/useMessageSender'
import moment from 'moment'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { trackEvent } from 'utils/analyticsUtils'
import { getEnvironment } from 'utils/awsUtils'
import { isMobile } from 'utils/mobileUtils'
import DeletedMessageContent from './DeletedMessageContent'
import MessageAvatar from './MessageAvatar'
import MessageScheduleTimeLabel from './MessageScheduleTimeLabel'
import BeforeAfterMessage from './MessageTypes/BeforeAfterMessage'
import DoubleScanMessage from './MessageTypes/DoubleScanMessage'
import ImageMessage from './MessageTypes/ImageMessage'
import ImageV2Message from './MessageTypes/ImageV2Message'
import OralHygieneRecommendationMessage from './MessageTypes/OralHygieneNote/OralHygieneRecommendationMessage'
import PdfMessage from './MessageTypes/PdfMessage'
import PromotionMessage from './MessageTypes/PromotionMessage'
import ScanMessage from './MessageTypes/ScanMessage'
import ScanReviewMessage from './MessageTypes/ScanReviewMessage'
import TextMessage from './MessageTypes/TextMessage'
import VideoMessage from './MessageTypes/VideoMessage'
import MessageTypeWrapper from './MessageTypeWrapper'
import PreliminaryPlanMessage from './PreliminaryPlanMessage'
import DeleteScheduledMessagePopup from './ScheduledMessages/DeleteScheduledMessagePopup'
import InstantLiveCallMessage from './MessageTypes/InstantLiveCallMessage/InstantLiveCallMessage'
import useMessageReadStatus from './useMessageReadStatus'

const useMessageStyles = ({ alignLeft, isFirstMessage }) =>
  makeStyles({
    container: {
      right: ({ alignLeft }) => (alignLeft ? 'initial' : 0),
      left: ({ alignLeft }) => (alignLeft ? 0 : 'initial'),
      alignSelf: ({ alignLeft }) => (alignLeft ? 'flex-start' : 'flex-end'),
      position: 'relative',
      maxWidth: 500,
      margin: '8px 40px 0 60px',
      marginTop: ({ isFirstMessage }) => (isFirstMessage ? 25 : 8),
      paddingRight: ({ alignLeft }) => (!alignLeft ? 20 : 0),
      display: 'flex',
      flexDirection: 'column',
      minHeight: 'max-content',
      '&.isMobile': {
        marginTop: ({ isFirstMessage }) => (isFirstMessage ? 20 : 10),
        marginRight: 25,
        marginLeft: 45
      }
    },
    content: {
      minWidth: '180px',
      lineHeight: 1.5,
      display: 'flex',
      position: 'relative',
      alignItems: 'center',
      whiteSpace: 'pre-wrap',
      justifyContent: ({ alignLeft }) => (alignLeft ? 'flex-start' : 'flex-end')
    },
    contentScheduled: {
      opacity: 0.65
    },
    sendingContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      marginRight: 20,
      marginTop: 5,
      fontSize: 10
    },
    circular: {
      color: 'var(--bg-color-13)',
      margin: '0 10px'
    },
    deleteButtonContainer: {
      padding: '3px 7px'
    },
    deleteButton: {
      color: 'var(--text-color-25)',
      textDecoration: 'underline'
    }
  })({ alignLeft, isFirstMessage })

const Message = ({
  type,
  id,
  grinUserId,
  content,
  isLocal,
  cache,
  timestamp,
  owner,
  _version,
  isDeleting,
  metadata,
  isAdmin,
  isBroadcast,
  href,
  promotionData,
  previousMessageGrinUserId,
  nextMessageGrinUserId,
  scheduleTime,
  promotionId,
  grinScan,
  lastEditedAt,
  isDeleted,
  withMessageActions = true,
  s3UniqueId,
  uploadStatus // Only relevant in ScanReview, it incidates the video file upload status to s3.
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const doctor = useSelector(state => state.profileReducer.doctor)
  const patient = useSelector(state => state.patientsReducer.patient)

  const { name, photo, email, userType } = useMessageSender({ grinUserId, metadata, isAdmin })

  const alignLeft = useMemo(
    () => type === GI_RESULT_MSG_TYPE || grinUserId !== doctor.user.id,
    [grinUserId, doctor, type]
  )
  const isFirstMessage = useMemo(
    () => previousMessageGrinUserId !== grinUserId,
    [previousMessageGrinUserId, grinUserId]
  )
  const classes = useMessageStyles({ alignLeft, isFirstMessage })

  const isLastMessage = useMemo(() => nextMessageGrinUserId !== grinUserId, [nextMessageGrinUserId, grinUserId])
  const messageTimestamp = useMemo(() => moment(timestamp).format(TIME_FORMAT_8), [timestamp])
  const isMessageDeleted = useMemo(() => isDeleted === true, [isDeleted])
  const messageMetadata = useMemo(() => JSON.parse(metadata || '{}'), [metadata])
  const isUrgent = useMemo(() => messageMetadata?.isUrgent, [messageMetadata])
  const urgencyReason = useMemo(() => messageMetadata?.urgencyReason, [messageMetadata])
  const messageFileTitle = useMemo(() => messageMetadata?.fileTitle, [messageMetadata])

  const readStatusByRecipient = useMessageReadStatus({
    lastReadDate: patient?.user?.rooms?.items[0]?.lastOpenedAt,
    messageSendDate: timestamp,
    senderUserType: userType
  })

  const [isDeleteScheduledMessagePopupOpen, setIsDeleteScheduledMessagePopupOpen] = useState(false)

  const parsedContent = useMemo(
    () =>
      isLocal ||
      ['image', 'video', 'pdf', 'scanReview', 'scan', 'doubleScan', 'newScanReview'].every(
        messageType => messageType !== type
      )
        ? content
        : JSON.parse(content || '{}'),
    [isLocal, content, type]
  )

  const isBroadcastView = useMemo(() => {
    const { isPersonal } = JSON.parse(metadata || '{}')
    return isBroadcast && !isPersonal
  }, [isBroadcast, metadata])

  const isMessageDeletable = useMemo(() => scheduleTime && !isLocal && !isDeleting, [scheduleTime, isLocal, isDeleting])
  const isLoading = useMemo(() => {
    // Hide loader while waiting for live update or when sending failed
    if ((uploadStatus === AsyncStatus.Completed && isLocal) || uploadStatus === AsyncStatus.Failed) {
      return false
    }

    return uploadStatus === AsyncStatus.Loading || isDeleting || isLocal
  }, [isDeleting, isLocal, uploadStatus])

  const handleOpenDeleteScheduleMessagePopup = useCallback(() => {
    trackEvent('Chat - delete scheduled message opened')
    setIsDeleteScheduledMessagePopupOpen(true)
  }, [])

  const handleCloseDeleteScheduleMessagePopup = useCallback(() => {
    trackEvent('Chat - delete scheduled message closed')
    setIsDeleteScheduledMessagePopupOpen(false)
  }, [])

  const handleDeleteScheduledMessage = useCallback(() => {
    trackEvent('Chat - scheduled message deleted')
    dispatch(Actions.deleteScheduledMessage({ id }))
    setIsDeleteScheduledMessagePopupOpen(false)
  }, [dispatch, id])

  const handleClick = useCallback(() => {
    if (getEnvironment() !== 'production') {
      navigator.clipboard.writeText(id)
    }
  }, [id])

  return (
    <div className={[classes.container, isMobile() && 'isMobile'].join(' ')}>
      {scheduleTime && <MessageScheduleTimeLabel scheduleTime={scheduleTime} />}
      <div className={classes.content} onClick={handleClick}>
        <Grid container direction="column" style={{ width: 'auto' }}>
          <Grid item className={scheduleTime ? classes.contentScheduled : ''}>
            {isMessageDeleted ? (
              <MessageTypeWrapper
                id={id}
                alignLeft={alignLeft}
                isFirstMessage={isFirstMessage}
                isLastMessage={isLastMessage}
                name={name}
                userType={userType}
                withMessageActions={withMessageActions}
              >
                <DeletedMessageContent timestamp={messageTimestamp} readStatus={readStatusByRecipient} />
              </MessageTypeWrapper>
            ) : type === 'beforeAfter' ? (
              <BeforeAfterMessage
                id={id}
                content={parsedContent}
                isLocal={isLocal}
                cache={cache}
                alignLeft={alignLeft}
                isFirstMessage={isFirstMessage}
                isLastMessage={isLastMessage}
                name={name}
                userType={userType}
                timestamp={messageTimestamp}
                isBroadcast={isBroadcastView}
                lastEditedAt={lastEditedAt}
                withMessageActions={withMessageActions}
                readStatus={readStatusByRecipient}
              />
            ) : type === 'imageV2' ? (
              <ImageV2Message
                id={id}
                content={parsedContent}
                isLocal={isLocal}
                cache={cache}
                alignLeft={alignLeft}
                isFirstMessage={isFirstMessage}
                isLastMessage={isLastMessage}
                name={name}
                userType={userType}
                timestamp={messageTimestamp}
                isBroadcast={isBroadcastView}
                fileTitle={messageFileTitle}
                lastEditedAt={lastEditedAt}
                withMessageActions={withMessageActions}
                readStatus={readStatusByRecipient}
              />
            ) : type === 'image' ? (
              <ImageMessage
                id={id}
                content={parsedContent}
                isLocal={isLocal}
                cache={cache}
                alignLeft={alignLeft}
                isFirstMessage={isFirstMessage}
                isLastMessage={isLastMessage}
                name={name}
                userType={userType}
                timestamp={messageTimestamp}
                isBroadcast={isBroadcastView}
                fileTitle={messageFileTitle}
                lastEditedAt={lastEditedAt}
                withMessageActions={withMessageActions}
                readStatus={readStatusByRecipient}
              />
            ) : type === 'video' ? (
              <VideoMessage
                id={id}
                content={parsedContent}
                isLocal={isLocal}
                cache={cache}
                alignLeft={alignLeft}
                isFirstMessage={isFirstMessage}
                isLastMessage={isLastMessage}
                name={name}
                userType={userType}
                timestamp={messageTimestamp}
                isBroadcast={isBroadcastView}
                fileTitle={messageFileTitle}
                lastEditedAt={lastEditedAt}
                withMessageActions={withMessageActions}
                readStatus={readStatusByRecipient}
              />
            ) : type === 'pdf' ? (
              <PdfMessage
                id={id}
                content={parsedContent}
                isLocal={isLocal}
                cache={cache}
                alignLeft={alignLeft}
                isFirstMessage={isFirstMessage}
                isLastMessage={isLastMessage}
                name={name}
                userType={userType}
                timestamp={messageTimestamp}
                isBroadcast={isBroadcastView}
                fileTitle={messageFileTitle}
                lastEditedAt={lastEditedAt}
                withMessageActions={withMessageActions}
                readStatus={readStatusByRecipient}
              />
            ) : type === 'promotion' ? (
              <PromotionMessage
                id={id}
                content={parsedContent}
                alignLeft={alignLeft}
                link={href}
                promotionData={promotionData}
                isFirstMessage={isFirstMessage}
                isLastMessage={isLastMessage}
                name={name}
                userType={userType}
                timestamp={messageTimestamp}
                isBroadcast={isBroadcastView}
                promotionId={promotionId}
                lastEditedAt={lastEditedAt}
                withMessageActions={withMessageActions}
                readStatus={readStatusByRecipient}
              />
            ) : type === 'scanReview' || type === 'newScanReview' ? (
              <ScanReviewMessage
                content={parsedContent}
                isFirstMessage={isFirstMessage}
                isLastMessage={isLastMessage}
                name={name}
                userType={userType}
                type={type}
                isLocal={isLocal}
                cache={cache}
                timestamp={messageTimestamp}
                id={id}
                patientId={patient?.id}
                uploadStatus={uploadStatus}
                s3UniqueId={s3UniqueId}
                patientName={patient?.name}
                readStatus={readStatusByRecipient}
              />
            ) : type === 'scan' ? (
              <ScanMessage
                content={parsedContent}
                isFirstMessage={isFirstMessage}
                isLastMessage={isLastMessage}
                name={name}
                userType={userType}
                type={type}
                isLocal={isLocal}
                cache={cache}
                alignLeft={alignLeft}
                timestamp={messageTimestamp}
                isUrgent={isUrgent}
                urgencyReason={urgencyReason}
                id={id}
                patientId={patient?.id}
                grinScan={grinScan}
                grinScanId={grinScan?.id}
                lastEditedAt={lastEditedAt}
                readStatus={readStatusByRecipient}
              />
            ) : type === 'doubleScan' ? (
              <DoubleScanMessage
                isFirstMessage={isFirstMessage}
                isLastMessage={isLastMessage}
                content={parsedContent}
                name={name}
                userType={userType}
                type={type}
                isLocal={isLocal}
                cache={cache}
                alignLeft={alignLeft}
                timestamp={messageTimestamp}
                isUrgent={isUrgent}
                urgencyReason={urgencyReason}
                id={id}
                patientId={patient?.id}
                grinScan={grinScan}
                lastEditedAt={lastEditedAt}
                readStatus={readStatusByRecipient}
              />
            ) : type === 'instantLiveCall' ? (
              <InstantLiveCallMessage
                id={id}
                content={parsedContent}
                alignLeft={alignLeft}
                isFirstMessage={isFirstMessage}
                isLastMessage={isLastMessage}
                name={name}
                userType={userType}
                timestamp={messageTimestamp}
                isBroadcast={isBroadcastView}
                lastEditedAt={lastEditedAt}
                withMessageActions={withMessageActions}
                readStatus={readStatusByRecipient}
              />
            ) : type === 'proposal' ? (
              <PreliminaryPlanMessage />
            ) : type === GI_RESULT_MSG_TYPE ? (
              <></> // Deprecated at 1.73.0
            ) : type === OH_NOTE_MSG_TYPE ? (
              <OralHygieneRecommendationMessage
                id={id}
                content={content}
                timestamp={messageTimestamp}
                grinScanId={grinScan?.id}
                lastEditedAt={lastEditedAt}
              />
            ) : (
              type !== 'transfer' && (
                <TextMessage
                  id={id}
                  isFirstMessage={isFirstMessage}
                  isLastMessage={isLastMessage}
                  name={name}
                  userType={userType}
                  content={content}
                  alignLeft={alignLeft}
                  timestamp={messageTimestamp}
                  isBroadcast={isBroadcastView}
                  metadata={messageMetadata}
                  lastEditedAt={lastEditedAt}
                  withMessageActions={withMessageActions}
                  readStatus={readStatusByRecipient}
                />
              )
            )}
            {isLastMessage && (
              <MessageAvatar alignLeft={alignLeft} email={email} messageType={type} name={name} photo={photo} />
            )}
          </Grid>
          <Grid item className={classes.deleteButtonContainer}>
            {isMessageDeletable && (
              <>
                <ButtonBase onClick={handleOpenDeleteScheduleMessagePopup}>
                  <DazzedParagraph12 className={classes.deleteButton}>{t('general.delete')}</DazzedParagraph12>
                </ButtonBase>
                <DeleteScheduledMessagePopup
                  isOpen={isDeleteScheduledMessagePopupOpen}
                  onClose={handleCloseDeleteScheduleMessagePopup}
                  onConfirm={handleDeleteScheduledMessage}
                />
              </>
            )}
          </Grid>
        </Grid>
      </div>
      <div className={classes.messageInfoContainer}>
        {isLoading && (
          <div className={classes.sendingContainer} id="patient-room-chat-sending-loader">
            <CircularProgress className={classes.circular} size={16} />
            {isDeleting
              ? t('pages.patients.selectedPatient.chat.deleting')
              : uploadStatus === AsyncStatus.Loading
              ? t('pages.patients.selectedPatient.chat.uploading')
              : t('pages.patients.selectedPatient.chat.sending')}
          </div>
        )}
      </div>
    </div>
  )
}

export default React.memo(Message)
