import React, { useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import GeneralPracticeInfoIcon from 'components/common/icons/PracticeGuidelines/GeneralPracticeInfo'
import { generateHoursList } from 'utils/dateUtils'
import SelectInput from 'components/common/SelectInput'
import { Clock } from 'components/common/icons'
import CategoryNote from '../CategoryNote'
import DaysOfWeekSelector from './DaysOfWeekSelector'

const useStyles = makeStyles(theme => ({
  timeSelectInput: {
    maxWidth: 230
  },
  day: {
    padding: 8,
    width: 40,
    height: 40,
    borderRadius: '100%',
    color: 'var(--text-color-25)',
    border: '1px solid var(--text-color-25)',
    cursor: 'pointer',
    userSelect: 'none'
  },
  selected: {
    backgroundColor: 'var(--bg-color-43)',
    color: 'white'
  },
  notesContainer: {
    padding: '0px!important',
    margin: '0px!important'
  },
  notesTitle: {
    fontWeight: '500!important'
  }
}))

const GeneralPracticeInfo = ({ values = {}, setValues = () => {}, errors = {} }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const timeOptions = useMemo(() => generateHoursList(30, '00:00', '23:30', 'hh:mm a'), [])
  const updateValue = useCallback((key, value) => setValues({ ...values, [key]: value }), [values, setValues])

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <span style={{ lineHeight: 0.8 }}>
              <GeneralPracticeInfoIcon />
            </span>
          </Grid>
          <Grid item>
            <DazzedParagraph14 strong>
              {t('pages.accountSettings.practiceGuidelines.administrativeInfo.generalPracticeInfo.title')}
            </DazzedParagraph14>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <DaysOfWeekSelector
          days={values?.practiceDays}
          updateDays={updatedDays => updateValue('practiceDays', updatedDays)}
        />
      </Grid>
      <Grid item>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item>
              <SelectInput
                label={t('pages.accountSettings.practiceGuidelines.administrativeInfo.generalPracticeInfo.openingHour')}
                containerClassName={classes.timeSelectInput}
                style={{ bright: true, thick: true }}
                options={timeOptions}
                value={values.openingHour || ''}
                onChange={({ value }) => updateValue('openingHour', value)}
                iconComponent={<Clock />}
                errorMessage={errors.openingHour}
                keepErrorContainerWhenInactive={false}
              />
            </Grid>
            <Grid item style={{ marginLeft: 32 }}>
              <SelectInput
                label={t('pages.accountSettings.practiceGuidelines.administrativeInfo.generalPracticeInfo.closingHour')}
                containerClassName={classes.timeSelectInput}
                style={{ bright: true, thick: true }}
                options={timeOptions}
                value={values.closingHour || ''}
                onChange={({ value }) => updateValue('closingHour', value)}
                iconComponent={<Clock />}
                errorMessage={errors.closingHour}
                keepErrorContainerWhenInactive={false}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={10}>
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <DazzedParagraph14 strong>
              {t('pages.accountSettings.practiceGuidelines.administrativeInfo.supplyPickupNote')}
            </DazzedParagraph14>
          </Grid>
          <Grid item>
            <CategoryNote
              containerClassName={classes.notesContainer}
              titleClassName={classes.notesTitle}
              customTitle=" "
              note={values.supplyPickupNote || ''}
              onChange={value => updateValue('supplyPickupNote', value)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={10}>
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <DazzedParagraph14 strong>
              {t('pages.accountSettings.practiceGuidelines.administrativeInfo.emergenciesNote')}
            </DazzedParagraph14>
          </Grid>
          <Grid item>
            <CategoryNote
              containerClassName={classes.notesContainer}
              titleClassName={classes.notesTitle}
              note={values.emergenciesNote || ''}
              customTitle=" "
              onChange={value => updateValue('emergenciesNote', value)}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default GeneralPracticeInfo
