import * as React from 'react'

export default () => (
  <svg width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="38" cy="38" r="36" stroke="#3C52EF" strokeWidth="2.45455" />
    <path
      d="M35.3236 45.2244V45.0291C35.3453 42.9573 35.5623 41.3085 35.9745 40.0828C36.3866 38.8571 36.9724 37.8646 37.7317 37.1053C38.491 36.346 39.4021 35.6464 40.4652 35.0064C41.1051 34.6159 41.68 34.1549 42.1898 33.6234C42.6997 33.081 43.101 32.4573 43.3939 31.7522C43.6976 31.0472 43.8495 30.2662 43.8495 29.4093C43.8495 28.3463 43.6 27.4242 43.101 26.6433C42.602 25.8623 41.9349 25.2602 41.0997 24.8372C40.2645 24.4142 39.3371 24.2027 38.3174 24.2027C37.428 24.2027 36.571 24.3871 35.7467 24.7559C34.9223 25.1247 34.2335 25.705 33.6803 26.4968C33.1271 27.2887 32.8071 28.3246 32.7203 29.6045H28.6201C28.7069 27.7605 29.1842 26.1823 30.0519 24.8698C30.9306 23.5573 32.0858 22.5539 33.5176 21.8597C34.9603 21.1655 36.5602 20.8184 38.3174 20.8184C40.2265 20.8184 41.8861 21.198 43.2963 21.9573C44.7172 22.7166 45.8128 23.7579 46.5829 25.0813C47.3639 26.4046 47.7544 27.9124 47.7544 29.6045C47.7544 30.7977 47.57 31.877 47.2012 32.8424C46.8433 33.8078 46.3226 34.6701 45.6392 35.4294C44.9667 36.1887 44.1532 36.8612 43.1986 37.447C42.2441 38.0436 41.4794 38.6727 40.9045 39.3344C40.3296 39.9852 39.912 40.7608 39.6516 41.6611C39.3913 42.5614 39.2503 43.6841 39.2286 45.0291V45.2244H35.3236ZM37.4063 54.8566C36.6036 54.8566 35.9148 54.5691 35.3399 53.9942C34.765 53.4193 34.4776 52.7306 34.4776 51.9279C34.4776 51.1252 34.765 50.4364 35.3399 49.8615C35.9148 49.2866 36.6036 48.9991 37.4063 48.9991C38.209 48.9991 38.8978 49.2866 39.4726 49.8615C40.0475 50.4364 40.335 51.1252 40.335 51.9279C40.335 52.4594 40.1994 52.9475 39.9282 53.3922C39.6679 53.837 39.3154 54.1949 38.8706 54.4661C38.4367 54.7264 37.9486 54.8566 37.4063 54.8566Z"
      fill="#3C52EF"
    />
  </svg>
)
