import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, IconButton } from '@material-ui/core'
import ArrowDown from 'components/common/icons/timelineV2/scan-feed/ArrowDown'
import ArrowUp from 'components/common/icons/timelineV2/scan-feed/ArrowUp'
import Tooltip from 'components/common/Tooltip'

const ScanNavigationArrows = ({ previousDisabled, onPreviousClicked, nextDisabled, onNextClicked }) => {
  const { t } = useTranslation()

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Tooltip
          value={
            <div>
              {t('pages.patients.selectedPatient.scanNavigationArrows.previousScanTooltip')}
              <br />({t('common.keyboardShortcut', { shortcut: '↑' })})
            </div>
          }
          placement="top"
        >
          <span>
            <IconButton size="small" onClick={onPreviousClicked} disabled={previousDisabled}>
              <ArrowUp color={previousDisabled ? 'var(--text-color-4)' : 'var(--text-color-3)'} />
            </IconButton>
          </span>
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip
          value={
            <div>
              {t('pages.patients.selectedPatient.scanNavigationArrows.nextScanTooltip')}
              <br />({t('common.keyboardShortcut', { shortcut: '↓' })})
            </div>
          }
          placement="bottom"
        >
          <span>
            <IconButton size="small" onClick={onNextClicked} disabled={nextDisabled}>
              <ArrowDown color={nextDisabled ? 'var(--text-color-4)' : 'var(--text-color-3)'} />
            </IconButton>
          </span>
        </Tooltip>
      </Grid>
    </Grid>
  )
}

export default ScanNavigationArrows
