import * as React from 'react'

const MagnifyingGlassMinus = ({ color = '#4151E6' }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="7" cy="7" r="6" stroke="#3C52EF" strokeWidth="2" />
    <path d="M12 12L14.5 14.5" stroke="#3C52EF" strokeWidth="3" strokeLinecap="round" />
    <path d="M4 7H10" stroke="#3C52EF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export default MagnifyingGlassMinus
