import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import ScanThumbnail from '../../ScanThumbnail'
import { getObjectUrl } from 'utils/mediaUtils'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import ScanDateLabel from 'components/common/ScanDateLabel'

const useStyles = makeStyles(theme => ({
  scanReviewThumbnail: {
    width: '180px'
  }
}))

const ScanReviewItem = ({
  video,
  isLocal = false,
  createdAt,
  reviewerName,
  reviewerRoleDescription,
  withAligner,
  onClick
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const videoUrl = useMemo(() => (isLocal ? URL.createObjectURL(video) : getObjectUrl(video)), [video, isLocal])

  return (
    <Grid container direction="column">
      <Grid item>
        <ScanThumbnail
          mediaType="video"
          withAligner={withAligner}
          renderAlignerIconOverlay={withAligner !== null}
          src={videoUrl}
          onClick={onClick}
          containerClassName={classes.scanReviewThumbnail}
        />
      </Grid>
      <Grid item>
        <DazzedParagraph14 bold>
          {t('pages.patients.selectedPatient.timelineV2.scanFeed.scanReviews.reviewBy')}
        </DazzedParagraph14>
      </Grid>
      <Grid item>
        <DazzedParagraph14 strong>
          {reviewerName}
          {reviewerRoleDescription && <span style={{ fontWeight: 400 }}> ({reviewerRoleDescription})</span>}
        </DazzedParagraph14>
      </Grid>
      <Grid item>
        <ScanDateLabel timestamp={createdAt} />
      </Grid>
    </Grid>
  )
}

export default ScanReviewItem
