import React from 'react'
import { useTranslation } from 'react-i18next'
import GenericGuidelineAction from './GenericGuidelineAction'
import { CallForAppointment, ConfirmIPR } from 'components/common/icons'

const CallForAppointmentOrConfirmIPR = ({ callForAppointment, confirmIPR }) => {
  const { t } = useTranslation()

  return callForAppointment ? (
    <GenericGuidelineAction
      icon={<CallForAppointment />}
      text={t('dialogs.hiPatientGuidelinesViewer.actions.callForAppointment')}
    />
  ) : (
    confirmIPR && (
      <GenericGuidelineAction icon={<ConfirmIPR />} text={t('dialogs.hiPatientGuidelinesViewer.actions.confirmIPR')} />
    )
  )
}

export default CallForAppointmentOrConfirmIPR
