import React from 'react'
import { makeStyles } from '@material-ui/styles'
import GrinHoverPopper from 'components/common/menu/GrinHoverPopper'
import ElasticsTeethSelectionGrid from 'components/Patients/PatientBrief/CategoryComponents/Actions/Elastics/ElasticsTeethSelectionGrid'
import { Grid } from '@material-ui/core'
import ElasticListItem from 'components/Patients/PatientBrief/CategoryComponents/Actions/Elastics/ElasticListItem'

const useStyles = makeStyles(theme => ({
  elasticsGridPopup: {
    padding: '16px'
  },
  gridContainer: {
    marginTop: 10,
    border: '1px solid var(--border-color-7)',
    borderRadius: 7
  }
}))

const ElasticsGridPopup = ({ anchorEl, elastics = [], offset = { y: 10, x: 380 } }) => {
  const classes = useStyles()

  return (
    <GrinHoverPopper
      anchorEl={anchorEl}
      followMouse={false}
      offset={offset}
      grinPopperProps={{
        className: classes.elasticsGridPopup,
        zIndex: 99999
      }}
    >
      <Grid container>
        {elastics.map((elastic, i) => (
          <Grid item key={`elastic-item-${i}`} style={{ marginRight: 32 }}>
            <ElasticListItem
              color={elastic.color}
              name={elastic.name}
              elasticSelections={elastic.selections}
              showActionButtons={false}
            />
          </Grid>
        ))}
      </Grid>
      <div className={classes.gridContainer}>
        <ElasticsTeethSelectionGrid elastics={elastics} editable={false} />
      </div>
    </GrinHoverPopper>
  )
}

export default ElasticsGridPopup
