import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import MessageReadStatus from './MessageReadStatus'
import Tooltip from 'components/common/Tooltip'
import moment from 'moment'
import { MessageReadStatuses } from 'consts/chatConsts'

const useStyles = makeStyles(theme => ({
  label: {
    fontSize: 10,
    fontWeight: 500,
    color: ({ color }) => color
  }
}))

const MessageFooter = ({
  sendDate,
  lastEditedAt,
  readStatus,
  labelClassName = '',
  color = 'rgba(0, 19, 53, 0.45)'
}) => {
  const classes = useStyles({ color })
  const { t } = useTranslation()

  return (
    <Grid container alignItems="center" justifyContent="flex-end">
      {readStatus === MessageReadStatuses.Read && (
        <Grid item style={{ marginRight: 5 }}>
          <MessageReadStatus readStatus={readStatus} color={color} />
        </Grid>
      )}
      {lastEditedAt && (
        <Grid item>
          <Tooltip
            placement="bottom"
            value={t('pages.patients.selectedPatient.chat.lastEditedAtTooltip', {
              date: moment(lastEditedAt).format('LLL')
            })}
          >
            <span className={[classes.label, labelClassName].join(' ')}>
              {t('pages.patients.selectedPatient.chat.edited')} •{' '}
            </span>
          </Tooltip>
        </Grid>
      )}
      <Grid item>
        <span className={[classes.label, labelClassName].join(' ')}>{sendDate}</span>
      </Grid>
    </Grid>
  )
}

export default MessageFooter
