import React from 'react'
import { Grid } from '@material-ui/core'
import ScanDateLabel from 'components/common/ScanDateLabel'
import ScanByServiceAccount from 'components/Patients/Timeline/V1/ScanByServiceAccount'

const ScanGeneralInfo = ({ scanDate, isRecordsAppScan }) => {
  return (
    <Grid container alignItems="center">
      <Grid item>
        <ScanDateLabel timestamp={scanDate} />
      </Grid>
      {isRecordsAppScan && (
        <>
          <Grid item style={{ color: 'var(--text-color-62)' }}>
            &nbsp;•&nbsp;
          </Grid>
          <Grid item>
            <ScanByServiceAccount />
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default ScanGeneralInfo
