export const TimelineFeedTypes = {
  MainFeed: 'main-feed',
  ScanFeed: 'scan-feed'
}

/**
 * The values are based off the `type` field in `TimelineItem`.
 * In addition to these, we add more item types that are only relevant to the client display (such as `date`).
 */
export const TimelineItemTypes = {
  Date: 'date',
  GrinScan: 'grinScan',
  TreamtentTypeUpdated: 'treatmentTypeUpdated',
  StatusUpdated: 'statusUpdated'
}

export const ScanFeedPageSectionNames = {
  Reviews: 'reviews',
  Shares: 'shares',
  ScanTracking: 'tracking',
  TxTracker: 'tx-tracker'
}

export const MiniTimelineThemes = {
  ScanTracking: 'scan-tracking',
  OralScore: 'oral-score',
  TxTracker: 'tx-tracker'
}

export const DEFAULT_MINI_TIMELINE_THEME = MiniTimelineThemes.ScanTracking
export const DEFAULT_NUM_OF_DISPLAYED_ITEMS = 15
