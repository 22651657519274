import { AsyncStatus } from 'consts'
import Actions from '../actions'
import { Poses, ViewerModes } from 'components/common/Grin3DViewer/Grin3DViewerConsts'

const initialState = {
  requestSTLs: {
    status: null
  },
  txTrackerAdminModal: {
    isOpen: false
  },
  requestRerun: {
    submitStatus: null
  },
  compareStls: {
    views: [],
    syncedViewerMode: ViewerModes.Regular,
    syncedPose: Poses.Center,
    syncedViewerSettings: {
      isBaseLayerVisible: true,
      isCurrentLayerVisible: true
    }
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.REQUEST_PROVIDER_STLS:
      return {
        ...state,
        requestSTLs: {
          status: AsyncStatus.Loading
        }
      }
    case Actions.REQUEST_PROVIDER_STLS_RECEIVED:
      return {
        ...state,
        requestSTLs: {
          status: AsyncStatus.Completed
        }
      }
    case Actions.REQUEST_PROVIDER_STLS_FAILED:
      return {
        ...state,
        requestSTLs: {
          status: AsyncStatus.Failed
        }
      }
    case Actions.CLEAR_REQUEST_PROVIDER_STLS_STATUS:
      return {
        ...state,
        requestSTLs: {
          status: null
        }
      }
    case Actions.TOGGLE_TX_TRACKER_MODAL_OPEN:
      return {
        ...state,
        txTrackerAdminModal: {
          isOpen: action.payload
        }
      }
    case Actions.TX_TRACKER_REQUEST_RERUN:
      return {
        ...state,
        requestRerun: {
          ...state.requestRerun,
          submitStatus: AsyncStatus.Loading
        }
      }
    case Actions.TX_TRACKER_REQUEST_RERUN_RECEIVED:
      return {
        ...state,
        requestRerun: {
          ...state.requestRerun,
          submitStatus: AsyncStatus.Completed
        }
      }
    case Actions.TX_TRACKER_REQUEST_RERUN_FAILED:
      return {
        ...state,
        requestRerun: {
          ...state.requestRerun,
          submitStatus: AsyncStatus.Failed
        }
      }
    case Actions.TX_TRACKER_COMPARE_UPDATE_VIEW:
      return {
        ...state,
        compareStls: {
          ...state.compareStls,
          views: state.compareStls.views.length === 2 ? [action.payload] : [...state.compareStls.views, action.payload],
          isSynced: false
        }
      }
    case Actions.TX_TRACKER_COMPARE_SET_SYNC:
      return {
        ...state,
        compareStls: {
          ...state.compareStls,
          isSynced: action.payload
        }
      }
    case Actions.TX_TRACKER_COMPARE_REMOVE_VIEW:
      return {
        ...state,
        compareStls: {
          ...state.compareStls,
          views: state.compareStls.views.filter(view => view.object.uuid !== action.payload.object.uuid)
        }
      }
    case Actions.TX_TRACKER_COMPARE_UPDATE_VIEWER_MODE:
      return {
        ...state,
        compareStls: {
          ...state.compareStls,
          syncedViewerMode: action.payload
        }
      }
    case Actions.TX_TRACKER_COMPARE_SET_POSE:
      return {
        ...state,
        compareStls: {
          ...state.compareStls,
          syncedPose: action.payload
        }
      }
    case Actions.TX_TRACKER_COMPARE_UPDATE_VIEWER_SETTINGS:
      return {
        ...state,
        compareStls: {
          ...state.compareStls,
          syncedViewerSettings: action.payload
        }
      }

    default:
      return state
  }
}
