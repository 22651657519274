import * as React from 'react'

export default () => {
  return (
    <svg width="24" height="11" viewBox="0 0 24 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 11C14.9624 11 12.5 8.53757 12.5 5.5C12.5 2.46243 14.9624 0 18 0C21.0376 0 23.5 2.46243 23.5 5.5C23.5 8.53757 21.0376 11 18 11ZM17 6V2H19V6H17ZM17 7V9H19V7H17Z"
        fill="#001335"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 5.5C0 8.53757 2.46243 11 5.5 11C8.53757 11 11 8.53757 11 5.5C11 2.46243 8.53757 0 5.5 0C2.46243 0 0 2.46243 0 5.5ZM4.49995 8.91421L9.70706 3.70711L8.29285 2.29289L4.49995 6.08579L3.20706 4.79289L1.79285 6.20711L4.49995 8.91421Z"
        fill="#001335"
      />
    </svg>
  )
}
