import * as React from 'react'

const MessageDoubleCheckmarks = ({ color = '#939AAA' }) => {
  return (
    <svg width="25" height="9" viewBox="0 0 25 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.7163 0.175518C25.0474 0.446759 25.0959 0.935032 24.8247 1.26611L19.5218 7.7387C19.3846 7.90617 19.1836 8.00861 18.9675 8.02122C18.7514 8.03383 18.5398 7.95546 18.3841 7.80508L17.2533 6.71332L18.3299 5.59831L18.8558 6.10605L23.6258 0.283855C23.897 -0.0472188 24.3853 -0.0957227 24.7163 0.175518ZM19.6835 0.175519C20.0146 0.44676 20.0631 0.935032 19.7919 1.26611L14.489 7.7387C14.347 7.91203 14.1369 8.01541 13.913 8.02218C13.689 8.02895 13.4731 7.93844 13.3209 7.774L10.4314 4.6524C10.1407 4.3383 10.1596 3.84799 10.4737 3.55726C10.7878 3.26652 11.2781 3.28546 11.5688 3.59956L13.8539 6.06825L18.593 0.283855C18.8642 -0.0472187 19.3525 -0.0957226 19.6835 0.175519ZM7.08534 2.81198C7.08534 4.2794 5.89576 5.46898 4.42834 5.46898C2.96091 5.46898 1.77133 4.2794 1.77133 2.81198C1.77133 1.34456 2.96091 0.154976 4.42834 0.154976C5.89576 0.154976 7.08534 1.34456 7.08534 2.81198ZM1.71536 5.09032C0.6874 5.71043 0 6.83793 0 8.12598H8.85668C8.85668 6.83793 8.16928 5.71043 7.14131 5.09032C6.49145 5.86334 5.51727 6.35464 4.42834 6.35464C3.33941 6.35464 2.36523 5.86334 1.71536 5.09032Z"
        fill={color}
      />
    </svg>
  )
}

export default MessageDoubleCheckmarks
