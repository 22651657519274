export const MFA_METHOD_TYPES = {
  SMS: 'sms',
  AuthApp: 'authApp'
}

export const COGNITO_MFA_METHODS = {
  AuthApp: 'SOFTWARE_TOKEN_MFA',
  SMS: 'SMS_MFA',
  noMFA: 'NOMFA'
}
