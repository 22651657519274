import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import { ScanSummaryStatus } from 'consts/scanSummaryConsts'
import TxTrackerPending from 'components/common/icons/timelineV2/scan-actions/TxTrackerPending'
import TxTrackerFailed from 'components/common/icons/timelineV2/scan-actions/TxTrackerFailed'

const useStyles = makeStyles(theme => ({
  statusIconContainer: {
    lineHeight: 0.8,
    marginLeft: 4
  }
}))

const TxTrackerButtonContent = ({ treatmentTrackerStatus }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid container alignItems="center">
      <Grid item>{t('pages.patients.selectedPatient.timeline.actions.treatmentTracker')}</Grid>
      {treatmentTrackerStatus !== ScanSummaryStatus.Completed && (
        <Grid item className={classes.statusIconContainer}>
          {treatmentTrackerStatus === ScanSummaryStatus.Pending ? <TxTrackerPending /> : <TxTrackerFailed />}
        </Grid>
      )}
    </Grid>
  )
}

export default TxTrackerButtonContent
