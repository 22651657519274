import { MenuItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React from 'react'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: '8px',
    paddingBottom: '8px'
  },
  nonActionable: {
    cursor: 'default',
    '&.Mui-focusVisible, &:hover': {
      backgroundColor: '#fff'
    },
    '& > .MuiTouchRipple-root': {
      display: 'none',
      backgroundColor: 'ref'
    }
  }
}))

const GrinMenuItem = ({ onClick = () => {}, className, children, nonActionable = false }) => {
  const classes = useStyles()

  return (
    <MenuItem
      onClick={nonActionable ? undefined : onClick}
      className={[classes.root, nonActionable ? classes.nonActionable : '', className].join(' ')}
    >
      {children}
    </MenuItem>
  )
}

export default GrinMenuItem
