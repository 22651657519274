import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Dialog, DialogContent, DialogTitle, Grid } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import PrimaryButton from '../common/buttons/PrimaryButton'
import SecondaryButton from '../common/buttons/SecondaryButton'
import SvgSwoosh from '../common/icons/Swoosh'
import SvgSwooshEllipse from '../common/icons/SwooshEllipse'
import Actions from '../../actions'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'utils/mobileUtils'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import OggHeading40 from 'components/common/text/OggHeading40'
import DazzedHeading24 from 'components/common/text/DazzedHeading24'

export const ForceRefreshModalVariants = {
  NewUpdate: 'newUpdate',
  LoginChanged: 'loginChanged'
}

const useStyles = makeStyles(theme => ({
  modal: {
    padding: 16,
    overflow: 'hidden',
    borderRadius: '20px',
    maxWidth: '520px'
  },
  title: {
    marginBottom: 10,
    textAlign: 'center',
    position: 'relative'
  },
  newUpdateTitle: {
    fontSize: isMobile() ? 35 : 40
  },
  content: {
    fontSize: 16,
    lineHeight: '24px',
    marginBottom: 16,
    fontWeight: 500
  },
  actions: {
    marginTop: 5,
    marginBottom: 5,
    maxWidth: isMobile() ? '100%' : '80%',
    margin: '0 auto'
  },
  swoosh: {
    position: 'absolute',
    top: 30,
    left: 0
  },
  swooshEllipse: {
    position: 'absolute',
    bottom: -130,
    right: -71
  }
}))

const ForceRefreshModal = props => {
  const dispatch = useDispatch()
  const { isOpen, isDismissable, variant } = useSelector(state => state.appReducer.forceRefresh)
  const { t } = useTranslation()
  const classes = useStyles({ variant })

  return (
    <Dialog
      open={isOpen}
      onClose={() => {}}
      classes={{
        paper: classes.modal
      }}
    >
      <DialogTitle>
        {variant === ForceRefreshModalVariants.NewUpdate ? (
          <OggHeading40 className={[classes.title, classes.newUpdateTitle].join(' ')}>
            {t(`dialogs.forceRefresh.${variant}.title`)}
          </OggHeading40>
        ) : (
          <DazzedHeading24 className={classes.title}>{t(`dialogs.forceRefresh.${variant}.title`)}</DazzedHeading24>
        )}
      </DialogTitle>
      <DialogContent className={classes.content}>
        {variant === ForceRefreshModalVariants.NewUpdate && (
          <div>
            <SvgSwoosh className={classes.swoosh} />
            <SvgSwooshEllipse className={classes.swooshEllipse} />
          </div>
        )}
        <DazzedParagraph16 strong textAlign="center">
          {t(`dialogs.forceRefresh.${variant}.body`)}
        </DazzedParagraph16>
      </DialogContent>
      <Grid
        container
        justifyContent="center"
        direction={isMobile() ? 'column-reverse' : 'row'}
        spacing={1}
        className={classes.actions}
      >
        {isDismissable ? (
          <Grid item md={6} xs={12}>
            <SecondaryButton
              label={t('dialogs.forceRefresh.later')}
              onClick={() => dispatch(Actions.dismissForceRefresh())}
              width="100%"
            />
          </Grid>
        ) : (
          <></>
        )}
        <Grid item md={6} xs={12}>
          <PrimaryButton
            label={t('dialogs.forceRefresh.refresh')}
            onClick={() => window.location.reload()}
            width="100%"
          />
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default ForceRefreshModal
