import Actions from 'actions'
import {
  STL_BY_REQUEST_PROVIDERS,
  TreatmentSTLsStatuses,
  TxTrackerValidationSteps
} from 'components/Patients/TreatmentTracker/txTrackerConsts'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { trackEvent } from 'utils/analyticsUtils'

/**
 * @param runTxTrackerValidations `Function from the useTxTrackerValidations hook, being used in different steps (gets a step parameter, like - "providerChanged/beforeUpload")`
 * @param ValidationErrors Consts for Validation Error options
 * @param mode Optional - provider | null/practice. Provider is when external provider (like spark) uploads the STLs in a dedicated public landing page we created for them. null/practice is the default - for practice
 */
export default ({
  setIsUploaderComponentLoading,
  runTxTrackerValidations,
  ValidationErrors,
  patientId,
  treatmentId,
  treatmentType,
  txTrackerStls,
  mode
}) => {
  const DROPZONE_CONTAINER_ID = 'dropzoneContainer'
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [provider, setProvider] = useState(null)
  const [validationError, setValidationError] = useState(null)
  const [invalidFiles, setInvalidFiles] = useState(null)
  const [isUploadCompletedOpen, setIsUploadCompletedOpen] = useState(false)

  const driveUploaderId = useSelector(state => state.appReducer.appconfig?.webapp?.driveUploaderId)

  const driveFolderName = useMemo(
    () => `@txTracker@${patientId}@${treatmentId}@${provider}@${treatmentType}`,
    [patientId, treatmentId, provider, treatmentType]
  )

  const {
    status: treatmentStlsStatus,
    stls,
    provider: existingStlsProvider
  } = useMemo(() => JSON.parse(txTrackerStls || '{}'), [txTrackerStls])

  const handleRequestProviderSTLs = useCallback(
    () => dispatch(Actions.requestProviderStls({ patientId, provider })),
    [dispatch, provider, patientId]
  )

  const handleDriveUploaderInitEvent = useCallback(
    ({ status, results, instance }) => {
      setIsUploaderComponentLoading(false)

      document.getElementsByClassName('uploader__heading')[0].innerText = t(
        'dialogs.patientInfo.txTrackerSetup.dragAndDropHere'
      )

      instance.setCustomSubfolderName(driveFolderName)
      instance.setHook('beforeupload', async (done, files) => {
        const filesToUpload = Array.from(files).map(file => ({ name: file.name }))
        const { validationError, invalidFiles = [] } = await runTxTrackerValidations({
          provider,
          step: TxTrackerValidationSteps.PreUpload,
          files: filesToUpload
        })

        switch (validationError) {
          case ValidationErrors.InvalidFiles: {
            trackEvent('STL Upload Validation Error', {
              validationError,
              provider,
              treatmentType,
              files: filesToUpload
            })
            setInvalidFiles(invalidFiles)
            break
          }
          case ValidationErrors.DuplicatedStls: {
            trackEvent('STL Upload Validation Error', {
              validationError,
              provider,
              treatmentType,
              files: filesToUpload
            })
            setValidationError(validationError)
            break
          }
          default: {
            done()
          }
        }
      })
    },
    [
      driveFolderName,
      provider,
      treatmentType,
      runTxTrackerValidations,
      ValidationErrors,
      setIsUploaderComponentLoading,
      t
    ]
  )

  const handleDriveUploaderDoneEvent = useCallback(
    ({ status, results, instance }) => {
      const { folder } = results

      setIsUploadCompletedOpen(true)
      trackEvent('STL Upload Completed', {
        provider,
        treatmentType,
        totalFiles: results.files?.length,
        linkToDrive: results.folder.link
      })
      dispatch(
        Actions.updateTreatment({
          treatmentId,
          txTrackerStls: JSON.stringify({ stls, status: TreatmentSTLsStatuses.PendingFilesTransfer })
        })
      )
      dispatch(Actions.triggerCopyStlsFromGDriveToS3({ driveFolderName: folder.name }))
    },
    [dispatch, treatmentId, stls, provider, treatmentType]
  )

  const driveUploaderCallbackFunc = useCallback(
    (status, results, instance) => {
      switch (status) {
        case 'init': {
          handleDriveUploaderInitEvent({ status, results, instance })
          break
        }
        case 'done': {
          handleDriveUploaderDoneEvent({ status, results, instance })
          break
        }
        default: {
          break
        }
      }
    },
    [handleDriveUploaderDoneEvent, handleDriveUploaderInitEvent]
  )

  // Unfortunately we have to do all kind of shit in order to work with the DriveUploader component.
  // For each provider change we need to completely unload and reload the DriveUploader component & Script in order for the
  // 'init' event to be called and set the relevant subFolderName (which relies on provider and patient's state)
  useEffect(() => {
    const existingDriveUploaderScript = document.getElementById('driveUploaderScript')
    const driveUploaderComponent = document.getElementById('driveUploaderComponent')

    if (existingDriveUploaderScript) {
      existingDriveUploaderScript.remove()
      window.driveUploaderCallbackFunc = null
      driveUploaderComponent?.remove()
    }
    if ((!validationError && provider && !STL_BY_REQUEST_PROVIDERS.includes(provider)) || mode === 'provider') {
      const head = document.querySelector('head')
      const driveUploaderScript = document.createElement('script')
      const driveUploaderCallbackScript = document.createElement('script')

      driveUploaderScript.id = 'driveUploaderScript'
      window.driveUploaderCallbackFunc = driveUploaderCallbackFunc
      driveUploaderScript.src = `https://driveuploader.com/upload/${driveUploaderId}/embed.js?callback=driveUploaderCallbackFunc`

      driveUploaderCallbackScript.innerHTML = driveUploaderCallbackFunc.toString()

      const fileUploadDivToAppend = document.createElement('div')
      fileUploadDivToAppend.id = 'driveUploaderComponent'
      fileUploadDivToAppend.className = 'driveuploader-fill'
      fileUploadDivToAppend.setAttribute('style', 'height: 100%; width: 100%')

      const dropzone = document.getElementById(DROPZONE_CONTAINER_ID)

      dropzone.appendChild(fileUploadDivToAppend)
      head.appendChild(driveUploaderScript)
      head.appendChild(driveUploaderCallbackScript)
    }
  }, [driveUploaderCallbackFunc, provider, driveUploaderId, mode, DROPZONE_CONTAINER_ID, validationError])

  return {
    DROPZONE_CONTAINER_ID,
    provider,
    setProvider,
    validationError,
    setValidationError,
    invalidFiles,
    setInvalidFiles,
    isUploadCompletedOpen,
    setIsUploadCompletedOpen,
    handleRequestProviderSTLs,
    treatmentStlsStatus,
    stls,
    existingStlsProvider
  }
}
