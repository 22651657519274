import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isDeepEmpty, isValidEmail } from 'utils/generalUtils'

export default ({ administrativeInfo = {} }) => {
  const { t } = useTranslation()

  const [errors, setErrors] = useState({})

  const hasErrors = useMemo(() => !isDeepEmpty(errors), [errors])

  const validateAdministrativeInfo = useCallback(() => {
    let errors = {
      grinGuruDetails: {},
      generalPracticeInfo: {}
    }

    if (!administrativeInfo.grinGuruDetails?.firstName) {
      errors.grinGuruDetails.firstName = t('errors.requiredField')
    }

    if (!administrativeInfo.grinGuruDetails?.lastName) {
      errors.grinGuruDetails.lastName = t('errors.requiredField')
    }

    if (!isValidEmail(administrativeInfo.grinGuruDetails?.email)) {
      errors.grinGuruDetails.email = t('errors.invalidEmail')
    }

    if (
      !administrativeInfo.grinGuruDetails?.phone?.countryCode ||
      !administrativeInfo.grinGuruDetails?.phone?.phoneNumber
    ) {
      errors.grinGuruDetails.phone = t('errors.requiredField')
    }

    if (!administrativeInfo.generalPracticeInfo?.openingHour) {
      errors.generalPracticeInfo.openingHour = t('errors.requiredField')
    }

    if (!administrativeInfo.generalPracticeInfo?.closingHour) {
      errors.generalPracticeInfo.closingHour = t('errors.requiredField')
    }

    if (!isDeepEmpty(errors)) {
      errors.general = t('pages.accountSettings.practiceGuidelines.administrativeInfo.generalError')
    }

    return errors
  }, [t, administrativeInfo])

  const validate = useCallback(() => {
    const errors = {
      administrativeInfo: validateAdministrativeInfo()
    }

    const isValid = isDeepEmpty(errors)
    if (!isValid) {
      errors.general = t('pages.accountSettings.practiceGuidelines.generalValidationError')
    }

    setErrors(errors)
    return isValid
  }, [validateAdministrativeInfo, t])

  useEffect(() => {
    if (hasErrors) {
      validate()
    }
  }, [hasErrors, validate])

  return {
    errors,
    hasErrors,
    validate
  }
}
