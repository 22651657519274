import React, { useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { Collapse, Grid } from '@material-ui/core'
import Chevron from 'components/common/icons/ChevronDownSmall'

const useStyles = makeStyles(theme => ({
  guildelineCategoryRoot: {
    backgroundColor: 'var(--bg-color-73)'
  },
  categoryHeader: {
    cursor: ({ isCollapsible }) => (isCollapsible ? 'pointer' : 'default')
  },
  categoryTitle: {
    padding: '5px 0px 5px 10px'
  },
  firstCategory: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8
  },
  lastCategory: {
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8
  },
  categoryBody: {
    padding: '10px 15px 8px 15px',
    backgroundColor: 'var(--bg-color-74)'
  }
}))

const GuidelineCategory = ({
  title,
  icon,
  children,
  isCollapsible = true,
  isFirstCategory = false,
  isLastCategory = false,
  headerClassName = '',
  titleClassName = ''
}) => {
  const classes = useStyles({ isCollapsible })

  const [isCollapsed, setIsCollapsed] = useState(false)

  const handleHeaderClick = useCallback(() => {
    if (isCollapsible) {
      setIsCollapsed(!isCollapsed)
    }
  }, [isCollapsed, isCollapsible])

  return (
    <div
      className={[
        classes.guildelineCategoryRoot,
        isFirstCategory ? classes.firstCategory : '',
        isLastCategory ? classes.lastCategory : ''
      ].join(' ')}
    >
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        className={[classes.categoryHeader, headerClassName].join(' ')}
        onClick={handleHeaderClick}
      >
        <Grid item>
          <Grid container alignItems="center" className={classes.categoryTitle}>
            {icon && (
              <Grid item style={{ marginRight: 5 }}>
                {icon}
              </Grid>
            )}
            <Grid item>
              <DazzedParagraph14 strong className={titleClassName}>
                {title}
              </DazzedParagraph14>
            </Grid>
          </Grid>
        </Grid>
        {isCollapsible && (
          <Grid item>
            <span style={{ padding: 16 }}>
              <Chevron
                fill="var(--text-color-6)"
                style={{ transform: !isCollapsed ? 'rotate(180deg)' : 'rotate(0deg)' }}
              />
            </span>
          </Grid>
        )}
      </Grid>
      <Collapse in={!isCollapsed} className={classes.categoryBody}>
        {children}
      </Collapse>
    </div>
  )
}

export default GuidelineCategory
