import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'

const useStyles = makeStyles(theme => ({
  label: {
    padding: 5
  }
}))

const TypicalAdvanceFrequency = ({ checked, frequency }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return checked ? (
    <DazzedParagraph14 className={classes.label}>
      {t('dialogs.patientBrief.categories.typicalAdvanceFrequency.viewTitle', { frequency })}
    </DazzedParagraph14>
  ) : (
    <></>
  )
}

export default TypicalAdvanceFrequency
