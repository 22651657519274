import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import GrinTag from 'components/common/tags/GrinTag'
import { useTranslation } from 'react-i18next'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'

const useStyles = makeStyles(() => ({
  assigneeSection: {
    marginLeft: 50,
    marginTop: 3,
    marginBottom: 5,
    alignItems: 'center'
  },
  tag: {
    transform: 'scale(0.9)'
  },
  italic: {
    fontStyle: 'italic',
    color: 'var(--bg-color-45)'
  },
  body: {
    marginLeft: 50,
    marginTop: 3
  },
  mentioned: {
    marginLeft: 7,
    color: 'var(--text-color-16)',
    fontWeight: 600
  }
}))

const AssignedNoteBody = ({ assigneeName, formattedBody }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid container>
      <Grid container className={classes.assigneeSection}>
        <GrinTag
          className={classes.tag}
          tag={{ type: 'custom', value: t('pages.patients.patientSwitchableView.notes.noteBody.assignedTag') }}
          canDeleteCustom={false}
        />
        <span className={classes.italic}>
          {t('pages.patients.patientSwitchableView.notes.noteBody.assignedDescription')}
        </span>
        <span className={classes.mentioned}>{assigneeName}</span>
      </Grid>
      <DazzedParagraph14 strong className={classes.body}>
        {formattedBody}
      </DazzedParagraph14>
    </Grid>
  )
}

export default AssignedNoteBody
