import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import PreventiveLegend from './PreventiveLegend'
import GrinCheckbox from 'components/common/GrinCheckbox'
import PreventiveFeedback from './PreventiveFeedback/PreventiveFeedback'
import { useSelector } from 'react-redux'
import { IMAGE_MODES } from 'consts/scanSummaryConsts'
import DisabledOverlay from 'components/common/DisabledOverlay'

const useStyles = makeStyles(theme => ({
  preventivePanelRoot: {
    position: 'relative'
  },
  preventiveLegendContainer: {
    margin: 0
  },
  preventiveCheckboxContainer: {
    padding: '7px !important'
  },
  checkbox: {
    paddingLeft: 0,
    paddingRight: 5
  },
  preventiveFeedbackContainer: {
    marginTop: 12
  },
  preventiveToggleAll: {
    cursor: ({ disabled }) => (disabled ? 'default' : 'pointer'),
    textDecoration: 'underline',
    color: '#4151E6',
    userSelect: 'none',
    fontSize: ({ fontSize }) => fontSize || '16px'
  }
}))

const PreventivePanel = ({
  hasPreventiveLayering,
  preventiveLayers,
  togglePreventiveLayer,
  toggleAllLayers,
  imagesMode,
  isAnyPreventiveLayerVisible,
  feedbackData,
  onSubmitPreventiveReview,
  onTogglePreventiveMode,
  shouldDisplayFeedback = true,
  legendItemXs = 12,
  disabled = false,
  withDisabledOverlay = true,
  fontSize
}) => {
  const classes = useStyles({ fontSize, disabled })
  const { t } = useTranslation()

  const { isSavingPreventiveFeedback } = useSelector(state => state.treatmentReducer)

  return (
    <Grid container direction="column" justifyContent="space-between" className={classes.preventivePanelRoot}>
      <Grid item>
        <Grid container direction="column" spacing={2} className={classes.preventiveLegendContainer}>
          <Grid item>
            <PreventiveLegend
              disabled={disabled}
              hasMultiLayers={hasPreventiveLayering}
              layers={preventiveLayers}
              toggleLayer={togglePreventiveLayer}
              legendItemXs={legendItemXs}
              fontSize={fontSize}
            />
          </Grid>
          {hasPreventiveLayering ? (
            <Grid item className={classes.preventiveCheckboxContainer}>
              <DazzedParagraph16 className={classes.preventiveToggleAll} onClick={() => !disabled && toggleAllLayers()}>
                {t('dialogs.scanSummary.preventive.legendToggleAll')}
              </DazzedParagraph16>
            </Grid>
          ) : (
            <Grid item className={classes.preventiveCheckboxContainer}>
              <GrinCheckbox
                disabled={disabled}
                caption={t('dialogs.scanSummary.preventive.legendToggle')}
                className={classes.checkbox}
                checked={imagesMode === IMAGE_MODES.preventive}
                onChange={onTogglePreventiveMode}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      {shouldDisplayFeedback && (
        <Grid item className={classes.preventiveFeedbackContainer}>
          <PreventiveFeedback
            isLoading={isSavingPreventiveFeedback}
            show={isAnyPreventiveLayerVisible}
            feedbackData={feedbackData}
            onSubmit={onSubmitPreventiveReview}
          />
        </Grid>
      )}
      {disabled && withDisabledOverlay && <DisabledOverlay />}
    </Grid>
  )
}

export default PreventivePanel
