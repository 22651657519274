import React from 'react'
import { Grid } from '@material-ui/core'

/**
 * Displays an icon and text side by side.
 * @param {Object} props
 * @param {JSX.Element} props.icon
 * @param {JSX.Element} props.text
 * @param {string} [props.margin] - Spacing in pixels between the icon and text.
 */
const IconAndText = ({ icon, text, margin = '3px' }) => {
  return (
    <Grid container alignItems="center">
      <Grid item style={{ lineHeight: 0.8, marginRight: margin }}>
        {icon}
      </Grid>
      <Grid item>{text}</Grid>
    </Grid>
  )
}

export default IconAndText
