import {
  FastLarge,
  MissingToothLarge,
  ModerateLarge,
  NoMovementLarge,
  NotTrackingLarge,
  PartiallyTrackingLarge,
  SlowLarge,
  TrackingLarge,
  TrackingNoMovementLarge,
  UnknownLarge
} from 'components/common/icons'
import NoSignificantMovementLarge from 'components/common/icons/treatmentTracker/teethTrackingLegend/NoSignificantMovementLarge'
import { TreatmentTypes } from 'consts/treatmentConsts'
import useScans from 'hooks/useScans'
import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { ARCHES_DEVIATION_LIMITS, MONTH_SPEED_LIMITS } from '../ArchesViewer/archesViewerConsts'
import { txTrackerOverTimeByScan as txTrackerOverTimeByScanMock } from '../mockData'
import { PROGRESS_TYPES, StlProviders, TeethTrackingStatuses } from '../txTrackerConsts'
import MissingSpeedInfoLarge from 'components/common/icons/treatmentTracker/teethTrackingLegend/MissingSpeedInfoLarge'

export default () => {
  const { scanIdToNumberDict, getScanNumberByTimelineItemData } = useScans()

  const { treatment } = useSelector(state => state.treatmentReducer)
  const patientGrinScans = useSelector(state => state.patientsReducer.patient?.grinScans?.items || [])
  const { grinScanId } = useSelector(state => state.treatmentReducer.treatmentTrackerModal)
  const isTimelineV2 = useSelector(state => state.timelineReducer.isTimelineV2)

  const isAlignersTreatment = useMemo(() => {
    const txTrackerStls = JSON.parse(treatment.txTrackerStls || '{}')

    if (txTrackerStls.provider === StlProviders.Align) {
      return false
    }

    return treatment?.type === TreatmentTypes.Aligners
  }, [treatment])

  const isNoTeethMovement = ({ deviation }) => deviation === undefined || typeof deviation !== 'number'

  const getAsteriskData = toothData => {
    if (!toothData) {
      return { shouldApplyAsterisk: false }
    }

    const { unknown, low_confidence, no_movement_planned } = toothData

    if (!unknown && low_confidence && !no_movement_planned) {
      return {
        shouldApplyAsterisk: true,
        // explanation: `The result is with low certainty. Please review the tooth if the outcome doesn't seem correct \n (unknown: ${unknown}, low_confidence: ${low_confidence}, no_movement_planned: ${no_movement_planned})`
        explanation: 'Low certainty'
      }
    }

    if (unknown && no_movement_planned) {
      return {
        shouldApplyAsterisk: true,
        // explanation: `No Movement Planned (unknown: ${unknown}, low_confidence: ${low_confidence}, no_movement_planned: ${no_movement_planned})`
        explanation: 'Unknown'
      }
    }

    if (unknown && !no_movement_planned) {
      return {
        shouldApplyAsterisk: false,
        // explanation: `The result could not be determined, please review the tooth manually \n (unknown: ${unknown}, low_confidence: ${low_confidence}, no_movement_planned: ${no_movement_planned})`
        explanation: 'Unknown'
      }
    }

    return { shouldApplyAsterisk: false }
  }

  const getToothTrackingStatus = ({ toothData, treatmentType }) => {
    if (!toothData) {
      return TeethTrackingStatuses.MissingTooth
    }

    const { deviation, pastMonthSpeed } = toothData

    if (isAlignersTreatment) {
      if (toothData.unknown && !toothData.no_movement_planned) {
        return TeethTrackingStatuses.Unknown
      }

      if (!toothData.unknown && toothData.low_confidence && toothData.no_movement_planned) {
        return TeethTrackingStatuses.TrackingNoMovementPlanned
      }

      if (isNoTeethMovement({ deviation })) {
        return TeethTrackingStatuses.TrackingNoMovement
      }

      if (deviation <= ARCHES_DEVIATION_LIMITS.MINOR_OFFSET) {
        return TeethTrackingStatuses.Tracking
      } else if (deviation <= ARCHES_DEVIATION_LIMITS.SMALL_OFFSET) {
        return TeethTrackingStatuses.PartiallyTracking
      }

      return TeethTrackingStatuses.NotTracking
    } else {
      if (toothData.unknown && !toothData.no_movement_planned) {
        return TeethTrackingStatuses.Unknown
      } else if ((toothData.unknown && toothData.no_movement_planned) || pastMonthSpeed == null) {
        return TeethTrackingStatuses.MissingSpeedInfo
      } else if (pastMonthSpeed > MONTH_SPEED_LIMITS.FAST) {
        return TeethTrackingStatuses.Fast
      } else if (pastMonthSpeed > MONTH_SPEED_LIMITS.MINOR && pastMonthSpeed <= MONTH_SPEED_LIMITS.FAST) {
        return TeethTrackingStatuses.Moderate
      } else if (pastMonthSpeed > MONTH_SPEED_LIMITS.TINY && pastMonthSpeed <= MONTH_SPEED_LIMITS.MINOR) {
        return TeethTrackingStatuses.Slow
      } else {
        return TeethTrackingStatuses.NoSignificantMovement
      }
    }
  }

  const grinScan = useMemo(() => patientGrinScans.find(scan => scan.id === grinScanId), [grinScanId, patientGrinScans])

  const txTrackerOverTimeByTeeth = useMemo(() => {
    let allTeethData = {}

    patientGrinScans
      .filter(grinScan => !!grinScan.treatmentTrackerData && grinScan.treatmentTrackerStatus === 'completed')
      .forEach(grinScan => {
        const { teeth: currentScanTeethData } = JSON.parse(grinScan.treatmentTrackerData || '{}')

        Object.keys(currentScanTeethData).forEach(currTeethKey => {
          const currentTeethData = {
            ...currentScanTeethData[currTeethKey],
            createdAt: grinScan.createdAt,
            alignerNumber: grinScan.applianceIndex > 0 ? grinScan.applianceIndex : null,
            scanNumber: isTimelineV2
              ? getScanNumberByTimelineItemData(grinScan.id)
              : scanIdToNumberDict[grinScan.id] + 1
          }

          if (!allTeethData?.[currTeethKey]) {
            allTeethData[currTeethKey] = [currentTeethData]
          } else {
            allTeethData[currTeethKey] = [...allTeethData[currTeethKey], currentTeethData]
          }
        })
      })

    return allTeethData
  }, [patientGrinScans, scanIdToNumberDict, getScanNumberByTimelineItemData, isTimelineV2])

  const trackingStatuses = useMemo(
    () => ({
      [TeethTrackingStatuses.Tracking]: {
        borderColor: '#C0F7C5',
        dotBorderColor: '#C0F7C5',
        backgroundColor: '#D2FFD6',
        icon: <TrackingLarge />
      },
      [TeethTrackingStatuses.TrackingNoMovement]: {
        borderColor: '#C0F7C5',
        dotBorderColor: '#C0F7C5',
        backgroundColor: '#D2FFD6',
        icon: <TrackingNoMovementLarge />
      },
      [TeethTrackingStatuses.TrackingNoMovementPlanned]: {
        borderColor: '#C0F7C5',
        dotBorderColor: '#C0F7C5',
        backgroundColor: '#D2FFD6',
        icon: <TrackingNoMovementLarge />
      },
      [TeethTrackingStatuses.PartiallyTracking]: {
        borderColor: '#FFDBA4',
        dotBorderColor: '#FFDBA4',
        backgroundColor: '#FFECCF',
        icon: <PartiallyTrackingLarge />
      },
      [TeethTrackingStatuses.NotTracking]: {
        borderColor: '#F6B3B3',
        dotBorderColor: '#F6B3B3',
        backgroundColor: '#FFD0D0',
        icon: <NotTrackingLarge />
      },
      [TeethTrackingStatuses.Unknown]: {
        borderColor: '#CAD9FF',
        dotBorderColor: '#CAD9FF',
        backgroundColor: '#CAD9FF',
        icon: <UnknownLarge />
      },
      [TeethTrackingStatuses.Fast]: {
        icon: <FastLarge />,
        backgroundColor: '#DAE7F7',
        borderColor: 'transparent',
        dotBorderColor: '#BDD3EE'
      },
      [TeethTrackingStatuses.Moderate]: {
        icon: <ModerateLarge />,
        backgroundColor: '#DAE7F7',
        borderColor: 'transparent',
        dotBorderColor: '#BDD3EE'
      },
      [TeethTrackingStatuses.Slow]: {
        icon: <SlowLarge />,
        backgroundColor: '#DAE7F7',
        borderColor: 'transparent',
        dotBorderColor: '#BDD3EE'
      },
      [TeethTrackingStatuses.NoMovement]: {
        icon: <NoMovementLarge />,
        backgroundColor: '#DAE7F7',
        borderColor: 'transparent',
        dotBorderColor: 'transparent'
      },
      [TeethTrackingStatuses.NoSignificantMovement]: {
        icon: <NoSignificantMovementLarge />,
        backgroundColor: '#DAE7F7',
        borderColor: 'transparent',
        dotBorderColor: '#CAD9FF'
      },
      [TeethTrackingStatuses.MissingTooth]: {
        icon: <MissingToothLarge />,
        borderColor: '#DBEAFF',
        dotBorderColor: '#DBEAFF'
      },
      [TeethTrackingStatuses.MissingSpeedInfo]: {
        icon: <MissingSpeedInfoLarge />,
        backgroundColor: '#DBEAFF',
        borderColor: 'transparent',
        dotBorderColor: 'transparent'
      }
    }),
    []
  )

  const getToothMovementPrecantage = useCallback(({ currentProgress, totalPlannedMovement }) => {
    const progressCalc = 1 - Math.abs(totalPlannedMovement - currentProgress) / totalPlannedMovement

    if (progressCalc < 0) {
      return 0
    }
    return (progressCalc * 100).toFixed(0)
  }, [])

  const getProgressFieldByProgressType = useCallback(
    ({ currentProgress, currentRotation, currentTranslation, progressType }) => {
      switch (progressType) {
        case PROGRESS_TYPES.Overall:
          return currentProgress
        case PROGRESS_TYPES.Rotation:
          return currentRotation
        case PROGRESS_TYPES.Translation:
          return currentTranslation
        default:
          return null
      }
    },
    []
  )

  return {
    getToothTrackingStatus,
    getAsteriskData,
    trackingStatuses,
    getToothMovementPrecantage,
    getProgressFieldByProgressType,
    txTrackerDataByTeeth:
      grinScan && Object.keys(txTrackerOverTimeByScanMock).includes(grinScan?.id)
        ? txTrackerOverTimeByScanMock[grinScanId]
        : txTrackerOverTimeByTeeth
  }
}
