import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'

const useStyles = makeStyles(theme => ({
  feedCardRoot: {
    margin: 12,
    padding: `24px`,
    backgroundColor: '#fff',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.06)',
    borderRadius: 16
  },
  feedCardHeader: {
    marginBottom: 24
  }
}))

const ScanFeedCard = ({
  title,
  titleIcon,
  className = '',
  cardHeaderClassName = '',
  children,
  id = '',
  headerButton
}) => {
  const classes = useStyles()

  return (
    <div className={[classes.feedCardRoot, className].join(' ')} id={id}>
      {title && (
        <Grid
          container
          className={[classes.feedCardHeader, cardHeaderClassName].join(' ')}
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item>
            <Grid container alignItems="center">
              {titleIcon && <Grid item>{titleIcon}</Grid>}
              <Grid item>
                <DazzedParagraph14 bold>{title}</DazzedParagraph14>
              </Grid>
            </Grid>
          </Grid>
          {headerButton && <Grid item>{headerButton}</Grid>}
        </Grid>
      )}
      {children}
    </div>
  )
}

export default ScanFeedCard
