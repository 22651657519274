import React, { useState, useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import { LeftArrow } from 'components/common/icons'
import FormInput from 'components/common/FormInput'
import PhoneNumberInput from 'components/common/PhoneNumberInput'
import SelectInput from 'components/common/SelectInput'
import PrimaryButton from 'components/common/buttons/PrimaryButton'
import { isValidEmail } from 'utils/generalUtils'
import { TeamMemberStatuses } from 'consts/authConsts'
import { mapToUpdatePracticeMemberInputForSupport } from 'utils/mappers/doctorsMappers'
import { MEMBER_TYPES } from 'consts/appConsts'

export const useStyles = makeStyles({
  form: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#FFF',
    zIndex: 999,
    padding: '70px 80px'
  },
  leftArrow: {
    position: 'absolute',
    left: 30,
    top: 30,
    cursor: 'pointer'
  },
  content: {
    height: '100%',
    overflowY: 'auto'
  },
  leftContainer: {
    paddingRight: '7%'
  },
  rightContainer: {
    padding: '0 50px'
  },
  jobTitleContainer: {
    marginTop: 30
  },
  email: {
    marginTop: 50,
    marginBottom: 30
  }
})

const EditMemberModal = ({ handleClose, activeMember, handleSubmit }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [contactEmail, setContactEmail] = useState(activeMember.messagingPreferences?.contacts[0]?.email)
  const [roleDescription, setRoleDescription] = useState(activeMember.roleDescription)
  const [name, setName] = useState(activeMember.name)
  const [phoneNumber, setPhoneNumber] = useState(activeMember.countryCode + activeMember.phoneNumber)
  const [countryCode, setCountryCode] = useState(activeMember.countryCode)
  const [accessType, setAccessType] = useState(activeMember.accessType)
  const [errors, setErrors] = useState({})

  const accessTypeOptions = useMemo(
    () =>
      [
        {
          label: MEMBER_TYPES.manager.label,
          value: MEMBER_TYPES.manager.key
        },
        {
          label: MEMBER_TYPES.member.label,
          value: MEMBER_TYPES.member.key
        }
      ].concat(
        activeMember.accessType === 'owner'
          ? [
              {
                label: MEMBER_TYPES.owner.label,
                value: MEMBER_TYPES.owner.key
              }
            ]
          : []
      ),
    [activeMember]
  )

  const statusOptions = useMemo(
    () =>
      Object.values(TeamMemberStatuses).map(value => ({
        label: t(`common.practiceMemberStatuses.${value}`),
        value
      })),
    [t]
  )

  const validateFields = useCallback(() => {
    const errors = {}

    if (!isValidEmail(contactEmail)) {
      errors.email = t('dialogs.doctorCardEditor.editPracticeMember.emailError')
    }
    if (!roleDescription) {
      errors.roleDescription = t('errors.requiredField')
    }
    if (!name) {
      errors.name = t('errors.requiredField')
    }

    return errors
  }, [contactEmail, name, roleDescription, t])

  const onSubmit = useCallback(
    e => {
      e.preventDefault()
      e.stopPropagation()
      const errors = validateFields()

      if (!isEmpty(errors)) {
        setErrors(errors)
      } else {
        setErrors({})
        const inputs = mapToUpdatePracticeMemberInputForSupport(activeMember, {
          phoneNumber,
          countryCode,
          accessType,
          roleDescription,
          name,
          contactEmail,
          id: activeMember.id,
          _version: activeMember._version
        })

        if (!isEmpty(inputs)) {
          handleSubmit(inputs)
        } else {
          handleClose()
        }
      }
    },
    [
      accessType,
      activeMember,
      contactEmail,
      countryCode,
      handleClose,
      handleSubmit,
      name,
      phoneNumber,
      roleDescription,
      validateFields
    ]
  )

  return (
    <form className={classes.form} onSubmit={onSubmit}>
      <LeftArrow className={classes.leftArrow} color="var(--text-color-11)" onClick={handleClose} />
      <Grid container className={classes.content}>
        <Grid item sm={7} className={classes.leftContainer}>
          <FormInput
            title={t('dialogs.doctorCardEditor.editPracticeMember.name')}
            style={{ bright: true, thick: true }}
            value={name}
            keepErrorContainerWhenInactive={false}
            setValue={setName}
            errorMessage={errors.name}
          />
          <FormInput
            title={t('dialogs.doctorCardEditor.editPracticeMember.jobTitle')}
            style={{ bright: true, thick: true }}
            value={roleDescription}
            keepErrorContainerWhenInactive={false}
            setValue={setRoleDescription}
            containerClassName={classes.jobTitleContainer}
            errorMessage={errors.roleDescription}
          />
          <FormInput
            title={t('dialogs.doctorCardEditor.editPracticeMember.email')}
            style={{ bright: true, thick: true }}
            value={contactEmail}
            keepErrorContainerWhenInactive={false}
            setValue={setContactEmail}
            containerClassName={classes.email}
            errorMessage={errors.email}
          />
          <PhoneNumberInput
            title={t('dialogs.doctorCardEditor.editPracticeMember.phone')}
            style={{ bright: true, thick: true }}
            value={phoneNumber}
            setValue={setPhoneNumber}
            setCountryCode={code => setCountryCode(`+${code}`)}
          />
        </Grid>
        <Grid item sm={5} className={classes.rightContainer}>
          <SelectInput
            label={t('dialogs.doctorCardEditor.editPracticeMember.status')}
            style={{ bright: true, thick: true }}
            value={activeMember.status}
            options={statusOptions}
            disabled
          />
          <SelectInput
            label={t('dialogs.doctorCardEditor.editPracticeMember.accessType')}
            style={{ bright: true, thick: true }}
            value={accessType}
            options={accessTypeOptions}
            onChange={({ value }) => setAccessType(value)}
            disabled={accessType === 'owner'}
          />
        </Grid>
      </Grid>
      <PrimaryButton label={t('general.saveChanges')} type="submit" />
    </form>
  )
}

export default EditMemberModal
