import { makeStyles } from '@material-ui/styles'
import BaseModal from 'components/common/modals/BaseModal'
import React, { useCallback, useEffect, useState } from 'react'
import { trackEvent } from 'utils/analyticsUtils'
import useZoomMeeting from './useZoomMeeting'
import { logError } from 'utils/logUtils'
import Actions from 'actions'
import { prettyError } from '@grin-rnd/grin-api-sdk/dist/utils/ErrorUtils'
import PatientContextView from './PatientContextView/PatientContextView'
import { mapToCreateNoteInput } from 'utils/mappers/noteMappers'
import { useCreateNoteMutation } from 'store/modules/PatientNotes'
import useShowSnackbar from 'hooks/useShowSnackbar'
import { useTranslation } from 'react-i18next'
import useSendChatMessage from 'hooks/useSendChatMessage'
import { LiveCallTypes } from './liveCallConsts'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    padding: 16
  },
  dialogContent: {
    padding: '35px 24px 24px 24px!important'
  },
  meetingSdkElement: {
    width: '60%',
    height: '100%'
  },
  contentWrapper: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}))

const LiveCallModal = ({ isOpen, setIsOpen, isInstantMeeting = true }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [note, setNote] = useState('')
  const [isInitiated, setIsInitiated] = useState(false)

  const { meetingSdkElement, zoomClient, initZoom, createMeeting } = useZoomMeeting()
  const { sendMessage } = useSendChatMessage()
  const [createNote, { isError: isFailedCreatingNote }] = useCreateNoteMutation()
  useShowSnackbar({
    isOpen: isFailedCreatingNote,
    message: t('pages.patients.patientSwitchableView.notes.createNoteError')
  })

  const handleClose = useCallback(async () => {
    trackEvent('Live call modal - Closed')
    try {
      await zoomClient.endMeeting()
    } catch (ex) {
      logError('An error occured while trying to end meeting on closing modal (this not necessarily an issue', {
        error: prettyError(ex)
      })
    }
    setIsOpen(false)

    if (!!note.trim()) {
      const payload = mapToCreateNoteInput([note])
      await createNote({ variables: payload })
    }

    setNote('')
  }, [createNote, note, setIsOpen, zoomClient])

  const handleCreateMeeting = useCallback(async () => {
    try {
      const parentWidth = meetingSdkElement.parentElement.clientWidth
      const parentHeight = meetingSdkElement.parentElement.clientHeight

      const zoomWidth = parentWidth * 0.6
      const zoomHeight = parentHeight * 0.9

      await initZoom({ zoomWidth, zoomHeight })

      const meetingNumber = await createMeeting()

      if (!meetingNumber) {
        throw new Error('Failed creating meeting')
      }

      sendMessage({
        type: LiveCallTypes.InstantLiveCall,
        additionalFields: { meetingNumber }
      })
    } catch (ex) {
      logError('An error occured while trying to initiate zoom meeting', {
        error: prettyError(ex)
      })
      dispatch(
        Actions.showSnackbar({
          type: 'error',
          text: t('pages.patients.patientSidebar.items.liveCalls.createMeetingError')
        })
      )
    }
  }, [meetingSdkElement, initZoom, createMeeting, sendMessage, dispatch, t])

  useEffect(() => {
    const initiateMeeting = async () => {
      if (isInstantMeeting && meetingSdkElement && !isInitiated) {
        setIsInitiated(true)
        await handleCreateMeeting()
      }
    }

    initiateMeeting()
  }, [handleCreateMeeting, isInitiated, isInstantMeeting, meetingSdkElement])

  return (
    isOpen && (
      <BaseModal
        open={isOpen}
        handleClose={handleClose}
        className={classes.root}
        contentClassName={classes.dialogContent}
        contentWrapperClassName={classes.contentWrapper}
        disableEnforceFocus
      >
        <div id="meetingSDKElement" className={classes.meetingSdkElement}></div>
        <PatientContextView note={note} setNote={setNote} />
      </BaseModal>
    )
  )
}

export default LiveCallModal
