import { Auth } from 'aws-amplify'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Actions from '../actions'
import ROUTES from '../consts/routesConsts'
import WelcomeToGrin from './Auth/WelcomeToGrin'

const Welcome = ({ history, location }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    const timer = setTimeout(() => {
      const checkIfSignedIn = async () => {
        try {
          const user = await Auth.currentAuthenticatedUser()
          dispatch(Actions.resignInReceived(user))
        } catch (err) {
          history.push(ROUTES.SIGN_IN)
        }
      }

      checkIfSignedIn()
    }, 3000)

    return () => clearTimeout(timer)
  }, [history, dispatch])

  return <WelcomeToGrin />
}

export default withRouter(Welcome)
