import React, { useMemo } from 'react'
import { Chip } from '@material-ui/core'
import CloseIcon from './icons/Close'
import { makeStyles } from '@material-ui/styles'
import { isMobile } from 'utils/mobileUtils'
import { RedDot } from 'components/common/icons'

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: '6px',
    fontWeight: 500,
    textTransform: ({ textTransform }) => textTransform,
    backgroundColor: ({ color }) => color || 'var(--bg-color-16)',
    color: ({ textColor }) => textColor || 'var(--text-color-7)',
    '&.outlined': {
      border: '1px solid',
      borderColor: ({ color }) => color || 'var(--bg-color-16)',
      color: ({ color }) => color || 'var(--bg-color-16)',
      backgroundColor: ({ outlinedBackgroundColor }) => outlinedBackgroundColor
    }
  },
  deleteIconSmall: {
    width: ({ isMobile }) => (isMobile ? 22 : 24),
    height: ({ isMobile }) => (isMobile ? 22 : 24)
  },
  unseenIndicator: {
    marginLeft: -6,
    position: 'absolute',
    top: 3
  }
}))

const GrinChip = ({
  text,
  className,
  labelClassName,
  size = 'small',
  backgroundColor,
  outlinedBackgroundColor = 'white',
  textColor,
  variant = 'filled',
  textTransform = 'none',
  onClick,
  icon,
  onDelete,
  actionIcon,
  noActionIcon = false,
  showUnseenIndicator = false,
  automationsPrefix = ''
}) => {
  const classes = useStyles({
    color: backgroundColor,
    textColor,
    textTransform,
    outlinedBackgroundColor,
    isMobile: isMobile()
  })

  const id = useMemo(
    () => (automationsPrefix ? `${automationsPrefix}-tag-${text}` : `tag-${text}`),
    [automationsPrefix, text]
  )
  const closeBtnId = useMemo(
    () => (automationsPrefix ? `${automationsPrefix}-tag-${text}-close-button` : `tag-${text}-close-button`),
    [automationsPrefix, text]
  )

  return (
    <>
      <Chip
        label={text}
        size={size}
        classes={{
          root: [classes.root, variant, className].join(' '),
          label: labelClassName,
          deleteIconSmall: classes.deleteIconSmall
        }}
        icon={icon}
        onClick={onClick}
        onDelete={onDelete}
        id={id}
        deleteIcon={
          noActionIcon ? null : actionIcon || <CloseIcon id={closeBtnId} color={textColor || 'var(--text-color-6)'} />
        }
      />
      {showUnseenIndicator && <RedDot className={classes.unseenIndicator} />}
    </>
  )
}

export default GrinChip
