import React, { useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import GuidelineCategory from '../GuidelineCategory'
import AdministrativeInfoIcon from 'components/common/icons/PracticeGuidelines/AdministrativeInfo'
import GuidelineItem from './GuidelineItem'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { useSelector } from 'react-redux'
import { getCountryCode } from 'utils/stringUtils'
import moment from 'moment'
import { calculateTimezoneHoursOffset } from 'utils/dateUtils'
import Note from './Note'

const useStyles = makeStyles(theme => ({
  administrativeInfoHeader: {
    background: 'rgba(249, 249, 254, 1)'
  },
  administrativeInfoTitle: {
    fontWeight: 'bold!important'
  },
  itemContainer: {
    width: '50%',
    marginBottom: 10
  },
  itemsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  }
}))

const AdministrativeInfo = ({ administrativeInfo = {} }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { clinicData, practiceName, accountOwnerId } = useSelector(state => state.multiPracticeReducer.context)
  const { assignedDoctors } = useSelector(state => state.multiPracticeReducer)

  const formatTimeString = useCallback(time => moment(time, 'HH:mm').format('hh:mm A'), [])
  const practiceTimezoneOffset = useMemo(
    () => (clinicData?.practiceTimezone ? calculateTimezoneHoursOffset(clinicData.practiceTimezone) : ''),
    [clinicData]
  )
  const accountOwner = useMemo(
    () => Object.values(assignedDoctors.data).find(doc => doc.id === accountOwnerId),
    [accountOwnerId, assignedDoctors]
  )

  const fields = useMemo(
    () => [
      {
        title: t('dialogs.hiPatientGuidelinesViewer.administrativeInfo.guruName'),
        body: administrativeInfo.grinGuruDetails?.firstName
          ? `${administrativeInfo.grinGuruDetails?.firstName} ${administrativeInfo.grinGuruDetails?.lastName}`
          : '-'
      },
      {
        title: t('dialogs.hiPatientGuidelinesViewer.administrativeInfo.practiceName'),
        body: practiceName
      },
      {
        title: t('dialogs.hiPatientGuidelinesViewer.administrativeInfo.ownerName'),
        body: accountOwner?.name || '-'
      },
      {
        title: t('dialogs.hiPatientGuidelinesViewer.administrativeInfo.guruEmail'),
        body: administrativeInfo.grinGuruDetails?.email || '-'
      },
      {
        title: t('dialogs.hiPatientGuidelinesViewer.administrativeInfo.guruPhone'),
        body: administrativeInfo.grinGuruDetails?.phone?.phoneNumber
          ? `+${administrativeInfo.grinGuruDetails?.phone.countryCode}  ${administrativeInfo.grinGuruDetails?.phone.phoneNumber}`
          : '-'
      },
      {
        title: t('dialogs.hiPatientGuidelinesViewer.administrativeInfo.timezone'),
        body: clinicData.practiceTimezone
      },
      {
        title: t('dialogs.hiPatientGuidelinesViewer.administrativeInfo.practicePhone'),
        body: clinicData?.phone ? `${getCountryCode(clinicData.countryCode)}  ${clinicData.phone}` : '-'
      },
      {
        title: t('dialogs.hiPatientGuidelinesViewer.administrativeInfo.practiceHours'),
        body: `${formatTimeString(administrativeInfo.generalPracticeInfo?.openingHour) || '-'} - ${
          formatTimeString(administrativeInfo.generalPracticeInfo?.closingHour) || '-'
        } ${practiceTimezoneOffset ? `(UTC ${practiceTimezoneOffset}` : ''})`
      },
      {
        title: t('dialogs.hiPatientGuidelinesViewer.administrativeInfo.practiceDays'),
        body: (administrativeInfo.generalPracticeInfo?.practiceDays || [])
          .map(day => day.charAt(0).toUpperCase() + day.slice(1, 3))
          .join(', ')
      },
      {
        title: t('dialogs.hiPatientGuidelinesViewer.administrativeInfo.supplyPickupNote'),
        body: administrativeInfo.generalPracticeInfo?.supplyPickupNote
      },
      {
        title: t('dialogs.hiPatientGuidelinesViewer.administrativeInfo.emergenciesNote'),
        body: administrativeInfo.generalPracticeInfo?.emergenciesNote
      }
    ],
    [t, administrativeInfo, practiceName, accountOwner, clinicData, formatTimeString, practiceTimezoneOffset]
  )

  return (
    <GuidelineCategory
      isCollapsible
      isFirstCategory
      isLastCategory
      title={t('dialogs.hiPatientGuidelinesViewer.administrativeInfo.title')}
      icon={<AdministrativeInfoIcon />}
      headerClassName={classes.administrativeInfoHeader}
      titleClassName={classes.administrativeInfoTitle}
    >
      <div className={classes.itemsContainer}>
        {fields.map(({ title, body }) => (
          <GuidelineItem title={title} key={`administrative-info-${title}`} className={classes.itemContainer}>
            <DazzedParagraph14 strong>{body}</DazzedParagraph14>
          </GuidelineItem>
        ))}
        {administrativeInfo?.note && <Note note={administrativeInfo?.note} />}
      </div>
    </GuidelineCategory>
  )
}

export default AdministrativeInfo
