/* eslint-disable no-template-curly-in-string */
import moment from 'moment'
import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getTimeOfDay } from 'utils/dateUtils'

export default () => {
  const { patient } = useSelector(state => state.patientsReducer)
  const { firstName: patientFirstName } = patient?.details || {}

  const nextScanDate = useMemo(() => moment(patient?.cycles?.items?.[0]?.originalEndDate).format('MM-DD'), [patient])

  const templatesPresetsFields = useMemo(
    () => ({
      '${FIRST_NAME}': () => patientFirstName,
      '${NEXT_SCAN_DATE}': () => nextScanDate,
      '${TIME_OF_DAY}': () => getTimeOfDay()
    }),
    [nextScanDate, patientFirstName]
  )

  const applyTemplatePresetFields = useCallback(
    message => {
      let updatedMessage = message
      Object.keys(templatesPresetsFields).forEach(
        presetFieldKey =>
          (updatedMessage = updatedMessage.replaceAll(presetFieldKey, templatesPresetsFields[presetFieldKey]()))
      )
      return updatedMessage
    },
    [templatesPresetsFields]
  )

  return {
    applyTemplatePresetFields
  }
}
