import * as React from 'react'

export default () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.4706 7.6463V7.6463C11.0564 8.23209 12.0061 8.23209 12.5919 7.6463L14.3597 5.87853C15.1407 5.09749 15.1407 3.83116 14.3597 3.05011L12.9455 1.63589C12.1644 0.854845 10.8981 0.854845 10.117 1.63589L8.34927 3.40366C7.76348 3.98945 7.76348 4.93919 8.34927 5.52498V5.52498"
      stroke="#3C52EF"
      strokeWidth="1.5"
    />
    <path
      d="M7.64292 10.4747V10.4747C8.2287 11.0605 8.2287 12.0102 7.64292 12.596L5.87515 14.3638C5.0941 15.1448 3.82777 15.1448 3.04672 14.3638L1.63251 12.9496C0.851462 12.1685 0.851462 10.9022 1.63251 10.1212L3.40028 8.35338C3.98606 7.7676 4.93581 7.7676 5.5216 8.35338V8.35338"
      stroke="#3C52EF"
      strokeWidth="1.5"
    />
    <path d="M10.1172 5.87854L5.87455 10.1212" stroke="#3C52EF" strokeWidth="1.5" strokeLinecap="round" />
  </svg>
)
