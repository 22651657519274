import { useCallback, useMemo } from 'react'
import _ from 'lodash'
import i18n from '../resources/locales/i18n'

import {
  US_STATES,
  CA_STATES,
  DEFAULT_COUNTRY,
  COUNTRY_US,
  COUNTRY_AT,
  COUNTRY_BE,
  COUNTRY_BG,
  COUNTRY_BR,
  COUNTRY_CA,
  COUNTRY_HR,
  COUNTRY_CY,
  COUNTRY_CZ,
  COUNTRY_DK,
  COUNTRY_EE,
  COUNTRY_FI,
  COUNTRY_FR,
  COUNTRY_DE,
  COUNTRY_GR,
  COUNTRY_HU,
  COUNTRY_IN,
  COUNTRY_IE,
  COUNTRY_IL,
  COUNTRY_IT,
  COUNTRY_LV,
  COUNTRY_LT,
  COUNTRY_LU,
  COUNTRY_MT,
  COUNTRY_NL,
  COUNTRY_PL,
  COUNTRY_PT,
  COUNTRY_RO,
  COUNTRY_SK,
  COUNTRY_SI,
  COUNTRY_ES,
  COUNTRY_SE,
  COUNTRY_GB,
  COUNTRY_TR,
  COUNTRY_TC,
  COUNTRY_CH,
  BR_STATES,
  CH_STATES,
  COUNTRY_AU,
  AU_STATES,
  COUNTRY_NZ,
  NZ_STATES
} from '../consts/countryConsts'

const COUNTRIES = {
  [COUNTRY_US]: {
    key: COUNTRY_US,
    name: 'United States',
    states: US_STATES,
    defaultState: US_STATES[0],
    zipCodeProperties: {
      validationSchema: '(^\\d{5}$)|(^\\d{5}-\\d{4}$)',
      placeholder: i18n.t('pages.practiceInfo.zip.usPlaceholder'),
      errorMessage: i18n.t('pages.practiceInfo.zip.errorMessage', {
        validZipCode: i18n.t('pages.practiceInfo.zip.usPlaceholder')
      })
    }
  },
  [COUNTRY_AU]: {
    key: COUNTRY_AU,
    name: 'Australia',
    states: AU_STATES,
    defaultState: AU_STATES[0],
    zipCodeProperties: {
      validationSchema: '^[0-9]{4}$',
      placeholder: i18n.t('pages.practiceInfo.zip.auPlaceholder'),
      errorMessage: i18n.t('pages.practiceInfo.zip.errorMessage', {
        validZipCode: i18n.t('pages.practiceInfo.zip.auPlaceholder')
      })
    }
  },
  [COUNTRY_AT]: {
    key: COUNTRY_AT,
    name: 'Austria',
    states: [],
    defaultState: { key: '', name: '' },
    zipCodeProperties: {
      validationSchema: '(^\\d{4}$)',
      placeholder: i18n.t('pages.practiceInfo.zip.atPlaceholder'),
      errorMessage: i18n.t('pages.practiceInfo.zip.errorMessage', {
        validZipCode: i18n.t('pages.practiceInfo.zip.atPlaceholder')
      })
    }
  },
  [COUNTRY_BE]: {
    key: COUNTRY_BE,
    name: 'Belgium',
    states: [],
    defaultState: { key: '', name: '' },
    zipCodeProperties: {
      validationSchema: '(^\\d{4}$)',
      placeholder: i18n.t('pages.practiceInfo.zip.bePlaceholder'),
      errorMessage: i18n.t('pages.practiceInfo.zip.errorMessage', {
        validZipCode: i18n.t('pages.practiceInfo.zip.bePlaceholder')
      })
    }
  },
  [COUNTRY_BG]: {
    key: COUNTRY_BG,
    name: 'Bulgaria',
    states: [],
    defaultState: { key: '', name: '' },
    zipCodeProperties: {
      validationSchema: '(^\\d{4}$)',
      placeholder: i18n.t('pages.practiceInfo.zip.bgPlaceholder'),
      errorMessage: i18n.t('pages.practiceInfo.zip.errorMessage', {
        validZipCode: i18n.t('pages.practiceInfo.zip.bgPlaceholder')
      })
    }
  },
  [COUNTRY_BR]: {
    key: COUNTRY_BR,
    name: 'Brazil',
    states: BR_STATES,
    defaultState: { key: 'AC', name: 'Acre' },
    zipCodeProperties: {
      validationSchema: '(^\\d{5}-\\d{3}$)',
      placeholder: i18n.t('pages.practiceInfo.zip.brPlaceholder'),
      errorMessage: i18n.t('pages.practiceInfo.zip.errorMessage', {
        validZipCode: i18n.t('pages.practiceInfo.zip.brPlaceholder')
      })
    }
  },
  [COUNTRY_CA]: {
    key: COUNTRY_CA,
    name: 'Canada',
    states: CA_STATES,
    defaultState: CA_STATES[0],
    zipCodeProperties: {
      validationSchema: '^[ABCEGHJ-NPRSTVXY]\\d[ABCEGHJ-NPRSTV-Z]\\d[ABCEGHJ-NPRSTV-Z]\\d$',
      placeholder: i18n.t('pages.practiceInfo.zip.caPlaceholder'),
      errorMessage: i18n.t('pages.practiceInfo.zip.errorMessage', {
        validZipCode: i18n.t('pages.practiceInfo.zip.caPlaceholder')
      })
    }
  },
  [COUNTRY_HR]: {
    key: COUNTRY_HR,
    name: 'Croatia',
    states: [],
    defaultState: { key: '', name: '' },
    zipCodeProperties: {
      validationSchema: '(^\\d{5}$)',
      placeholder: i18n.t('pages.practiceInfo.zip.hrPlaceholder'),
      errorMessage: i18n.t('pages.practiceInfo.zip.errorMessage', {
        validZipCode: i18n.t('pages.practiceInfo.zip.hrPlaceholder')
      })
    }
  },
  [COUNTRY_CY]: {
    key: COUNTRY_CY,
    name: 'Cyprus',
    states: [],
    defaultState: { key: '', name: '' },
    zipCodeProperties: {
      validationSchema: '(^\\d{4}$)',
      placeholder: i18n.t('pages.practiceInfo.zip.cyPlaceholder'),
      errorMessage: i18n.t('pages.practiceInfo.zip.errorMessage', {
        validZipCode: i18n.t('pages.practiceInfo.zip.cyPlaceholder')
      })
    }
  },
  [COUNTRY_CZ]: {
    key: COUNTRY_CZ,
    name: 'Czech Republic',
    states: [],
    defaultState: { key: '', name: '' },
    zipCodeProperties: {
      validationSchema: '^\\d{3}\\s*\\d{2}$',
      placeholder: i18n.t('pages.practiceInfo.zip.czPlaceholder'),
      errorMessage: i18n.t('pages.practiceInfo.zip.errorMessage', {
        validZipCode: i18n.t('pages.practiceInfo.zip.czPlaceholder')
      })
    }
  },
  [COUNTRY_DK]: {
    key: COUNTRY_DK,
    name: 'Denmark',
    states: [],
    defaultState: { key: '', name: '' },
    zipCodeProperties: {
      validationSchema: '^\\d{4}$',
      placeholder: i18n.t('pages.practiceInfo.zip.dkPlaceholder'),
      errorMessage: i18n.t('pages.practiceInfo.zip.errorMessage', {
        validZipCode: i18n.t('pages.practiceInfo.zip.dkPlaceholder')
      })
    }
  },
  [COUNTRY_EE]: {
    key: COUNTRY_EE,
    name: 'Estonia',
    states: [],
    defaultState: { key: '', name: '' },
    zipCodeProperties: {
      validationSchema: '(^\\d{5}$)',
      placeholder: i18n.t('pages.practiceInfo.zip.eePlaceholder'),
      errorMessage: i18n.t('pages.practiceInfo.zip.errorMessage', {
        validZipCode: i18n.t('pages.practiceInfo.zip.eePlaceholder')
      })
    }
  },
  [COUNTRY_FI]: {
    key: COUNTRY_FI,
    name: 'Finland',
    states: [],
    defaultState: { key: '', name: '' },
    zipCodeProperties: {
      validationSchema: '(^\\d{5}$)',
      placeholder: i18n.t('pages.practiceInfo.zip.fiPlaceholder'),
      errorMessage: i18n.t('pages.practiceInfo.zip.errorMessage', {
        validZipCode: i18n.t('pages.practiceInfo.zip.fiPlaceholder')
      })
    }
  },
  [COUNTRY_FR]: {
    key: COUNTRY_FR,
    name: 'France',
    states: [],
    defaultState: { key: '', name: '' },
    zipCodeProperties: {
      validationSchema: '(^\\d{5}$)',
      placeholder: i18n.t('pages.practiceInfo.zip.frPlaceholder'),
      errorMessage: i18n.t('pages.practiceInfo.zip.errorMessage', {
        validZipCode: i18n.t('pages.practiceInfo.zip.frPlaceholder')
      })
    }
  },
  [COUNTRY_DE]: {
    key: COUNTRY_DE,
    name: 'Germany',
    states: [],
    defaultState: { key: '', name: '' },
    zipCodeProperties: {
      validationSchema: '(^\\d{5}$)',
      placeholder: i18n.t('pages.practiceInfo.zip.dePlaceholder'),
      errorMessage: i18n.t('pages.practiceInfo.zip.errorMessage', {
        validZipCode: i18n.t('pages.practiceInfo.zip.dePlaceholder')
      })
    }
  },
  [COUNTRY_GR]: {
    key: COUNTRY_GR,
    name: 'Greece',
    states: [],
    defaultState: { key: '', name: '' },
    zipCodeProperties: {
      validationSchema: '^\\d{3}\\s*\\d{2}$',
      placeholder: i18n.t('pages.practiceInfo.zip.grPlaceholder'),
      errorMessage: i18n.t('pages.practiceInfo.zip.errorMessage', {
        validZipCode: i18n.t('pages.practiceInfo.zip.grPlaceholder')
      })
    }
  },
  [COUNTRY_HU]: {
    key: COUNTRY_HU,
    name: 'Hungary',
    states: [],
    defaultState: { key: '', name: '' },
    zipCodeProperties: {
      validationSchema: '(^\\d{4}$)',
      placeholder: i18n.t('pages.practiceInfo.zip.huPlaceholder'),
      errorMessage: i18n.t('pages.practiceInfo.zip.errorMessage', {
        validZipCode: i18n.t('pages.practiceInfo.zip.huPlaceholder')
      })
    }
  },
  [COUNTRY_IN]: {
    key: COUNTRY_IN,
    name: 'India',
    states: [],
    defaultState: { key: '', name: '' },
    zipCodeProperties: {
      validationSchema: '(^\\d{6}$)',
      placeholder: i18n.t('pages.practiceInfo.zip.inPlaceholder'),
      errorMessage: i18n.t('pages.practiceInfo.zip.errorMessage', {
        validZipCode: i18n.t('pages.practiceInfo.zip.inPlaceholder')
      })
    }
  },
  [COUNTRY_IE]: {
    key: COUNTRY_IE,
    name: 'Ireland',
    states: [],
    defaultState: { key: '', name: '' },
    zipCodeProperties: {
      validationSchema: '(?:^[AC-FHKNPRTV-Y][0-9]{2}|D6W)[ -]?[0-9AC-FHKNPRTV-Y]{4}$',
      placeholder: i18n.t('pages.practiceInfo.zip.iePlaceholder'),
      errorMessage: i18n.t('pages.practiceInfo.zip.errorMessage', {
        validZipCode: i18n.t('pages.practiceInfo.zip.iePlaceholder')
      })
    }
  },
  [COUNTRY_IL]: {
    key: COUNTRY_IL,
    name: 'Israel',
    states: [],
    defaultState: { key: '', name: '' },
    zipCodeProperties: {
      validationSchema: '(^\\d{7}$)',
      placeholder: i18n.t('pages.practiceInfo.zip.ilPlaceholder'),
      errorMessage: i18n.t('pages.practiceInfo.zip.errorMessage', {
        validZipCode: i18n.t('pages.practiceInfo.zip.ilPlaceholder')
      })
    }
  },
  [COUNTRY_IT]: {
    key: COUNTRY_IT,
    name: 'Italy',
    states: [],
    defaultState: { key: '', name: '' },
    zipCodeProperties: {
      validationSchema: '(^\\d{5}$)',
      placeholder: i18n.t('pages.practiceInfo.zip.itPlaceholder'),
      errorMessage: i18n.t('pages.practiceInfo.zip.errorMessage', {
        validZipCode: i18n.t('pages.practiceInfo.zip.itPlaceholder')
      })
    }
  },
  [COUNTRY_LV]: {
    key: COUNTRY_LV,
    name: 'Latvia',
    states: [],
    defaultState: { key: '', name: '' },
    zipCodeProperties: {
      validationSchema: '^LV[- ]{0,1}\\d{4}$',
      placeholder: i18n.t('pages.practiceInfo.zip.lvPlaceholder'),
      errorMessage: i18n.t('pages.practiceInfo.zip.errorMessage', {
        validZipCode: i18n.t('pages.practiceInfo.zip.lvPlaceholder')
      })
    }
  },
  [COUNTRY_LT]: {
    key: COUNTRY_LT,
    name: 'Lithuania',
    states: [],
    defaultState: { key: '', name: '' },
    zipCodeProperties: {
      validationSchema: '^LT[- ]{0,1}\\d{5}$',
      placeholder: i18n.t('pages.practiceInfo.zip.ltPlaceholder'),
      errorMessage: i18n.t('pages.practiceInfo.zip.errorMessage', {
        validZipCode: i18n.t('pages.practiceInfo.zip.ltPlaceholder')
      })
    }
  },
  [COUNTRY_LU]: {
    key: COUNTRY_LU,
    name: 'Luxembourg',
    states: [],
    defaultState: { key: '', name: '' },
    zipCodeProperties: {
      validationSchema: '(^\\d{4}$)',
      placeholder: i18n.t('pages.practiceInfo.zip.luPlaceholder'),
      errorMessage: i18n.t('pages.practiceInfo.zip.errorMessage', {
        validZipCode: i18n.t('pages.practiceInfo.zip.luPlaceholder')
      })
    }
  },
  [COUNTRY_MT]: {
    key: COUNTRY_MT,
    name: 'Malta',
    states: [],
    defaultState: { key: '', name: '' },
    zipCodeProperties: {
      validationSchema: '^[A-Z]{3}\\s{0,1}\\d{4}$',
      placeholder: i18n.t('pages.practiceInfo.zip.mtPlaceholder'),
      errorMessage: i18n.t('pages.practiceInfo.zip.errorMessage', {
        validZipCode: i18n.t('pages.practiceInfo.zip.mtPlaceholder')
      })
    }
  },
  [COUNTRY_NL]: {
    key: COUNTRY_NL,
    name: 'Netherlands',
    states: [],
    defaultState: { key: '', name: '' },
    zipCodeProperties: {
      validationSchema: '^\\d{4}\\s{0,1}[A-Z]{2}$',
      placeholder: i18n.t('pages.practiceInfo.zip.nlPlaceholder'),
      errorMessage: i18n.t('pages.practiceInfo.zip.errorMessage', {
        validZipCode: i18n.t('pages.practiceInfo.zip.nlPlaceholder')
      })
    }
  },
  [COUNTRY_NZ]: {
    key: COUNTRY_NZ,
    name: 'New Zealand',
    states: NZ_STATES,
    defaultState: NZ_STATES[0],
    zipCodeProperties: {
      validationSchema: '^[0-9]{4}$',
      placeholder: i18n.t('pages.practiceInfo.zip.nzPlaceholder'),
      errorMessage: i18n.t('pages.practiceInfo.zip.errorMessage', {
        validZipCode: i18n.t('pages.practiceInfo.zip.nzPlaceholder')
      })
    }
  },
  [COUNTRY_PL]: {
    key: COUNTRY_PL,
    name: 'Poland',
    states: [],
    defaultState: { key: '', name: '' },
    zipCodeProperties: {
      validationSchema: '^\\d{2}-\\d{3}$',
      placeholder: i18n.t('pages.practiceInfo.zip.plPlaceholder'),
      errorMessage: i18n.t('pages.practiceInfo.zip.errorMessage', {
        validZipCode: i18n.t('pages.practiceInfo.zip.plPlaceholder')
      })
    }
  },
  [COUNTRY_PT]: {
    key: COUNTRY_PT,
    name: 'Portugal',
    states: [],
    defaultState: { key: '', name: '' },
    zipCodeProperties: {
      validationSchema: '^\\d{4}-\\d{3}$',
      placeholder: i18n.t('pages.practiceInfo.zip.ptPlaceholder'),
      errorMessage: i18n.t('pages.practiceInfo.zip.errorMessage', {
        validZipCode: i18n.t('pages.practiceInfo.zip.ptPlaceholder')
      })
    }
  },
  [COUNTRY_RO]: {
    key: COUNTRY_RO,
    name: 'Romania',
    states: [],
    defaultState: { key: '', name: '' },
    zipCodeProperties: {
      validationSchema: '(^\\d{6}$)',
      placeholder: i18n.t('pages.practiceInfo.zip.roPlaceholder'),
      errorMessage: i18n.t('pages.practiceInfo.zip.errorMessage', {
        validZipCode: i18n.t('pages.practiceInfo.zip.roPlaceholder')
      })
    }
  },
  [COUNTRY_SK]: {
    key: COUNTRY_SK,
    name: 'Slovakia',
    states: [],
    defaultState: { key: '', name: '' },
    zipCodeProperties: {
      validationSchema: '^\\d{3}\\s*\\d{2}$',
      placeholder: i18n.t('pages.practiceInfo.zip.skPlaceholder'),
      errorMessage: i18n.t('pages.practiceInfo.zip.errorMessage', {
        validZipCode: i18n.t('pages.practiceInfo.zip.skPlaceholder')
      })
    }
  },
  [COUNTRY_SI]: {
    key: COUNTRY_SI,
    name: 'Slovenia',
    states: [],
    defaultState: { key: '', name: '' },
    zipCodeProperties: {
      validationSchema: '(^\\d{4}$)',
      placeholder: i18n.t('pages.practiceInfo.zip.siPlaceholder'),
      errorMessage: i18n.t('pages.practiceInfo.zip.errorMessage', {
        validZipCode: i18n.t('pages.practiceInfo.zip.siPlaceholder')
      })
    }
  },
  [COUNTRY_ES]: {
    key: COUNTRY_ES,
    name: 'Spain',
    states: [],
    defaultState: { key: '', name: '' },
    zipCodeProperties: {
      validationSchema: '(^\\d{5}$)',
      placeholder: i18n.t('pages.practiceInfo.zip.esPlaceholder'),
      errorMessage: i18n.t('pages.practiceInfo.zip.errorMessage', {
        validZipCode: i18n.t('pages.practiceInfo.zip.esPlaceholder')
      })
    }
  },
  [COUNTRY_SE]: {
    key: COUNTRY_SE,
    name: 'Sweden',
    states: [],
    defaultState: { key: '', name: '' },
    zipCodeProperties: {
      validationSchema: '^\\d{3}\\s*\\d{2}$',
      placeholder: i18n.t('pages.practiceInfo.zip.sePlaceholder'),
      errorMessage: i18n.t('pages.practiceInfo.zip.errorMessage', {
        validZipCode: i18n.t('pages.practiceInfo.zip.sePlaceholder')
      })
    }
  },
  [COUNTRY_CH]: {
    key: COUNTRY_CH,
    name: 'Switzerland',
    states: CH_STATES,
    defaultState: { key: 'ZH', name: 'Zurich' },
    zipCodeProperties: {
      validationSchema: '^\\d{4}$',
      placeholder: i18n.t('pages.practiceInfo.zip.chPlaceholder'),
      errorMessage: i18n.t('pages.practiceInfo.zip.errorMessage', {
        validZipCode: i18n.t('pages.practiceInfo.zip.chPlaceholder')
      })
    }
  },
  [COUNTRY_TR]: {
    key: COUNTRY_TR,
    name: 'Turkey',
    states: [],
    defaultState: { key: '', name: '' },
    zipCodeProperties: {
      validationSchema: '(^\\d{5}$)',
      placeholder: i18n.t('pages.practiceInfo.zip.trPlaceholder'),
      errorMessage: i18n.t('pages.practiceInfo.zip.errorMessage', {
        validZipCode: i18n.t('pages.practiceInfo.zip.trPlaceholder')
      })
    }
  },
  [COUNTRY_TC]: {
    key: COUNTRY_TC,
    name: 'Turks and Caicos',
    states: [],
    defaultState: { key: '', name: '' },
    validationSchema: '(^\\d{5}$)',
    zipCodeProperties: null
  },
  [COUNTRY_GB]: {
    key: COUNTRY_GB,
    name: 'United Kingdom',
    states: [],
    defaultState: { key: '', name: '' },
    zipCodeProperties: {
      validationSchema:
        '^(([A-Z]{1,2}[0-9][A-Z0-9]?|ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA) ?[0-9][A-Z]{2}|BFPO ?[0-9]{1,4}|(KY[0-9]|MSR|VG|AI)[ -]?[0-9]{4}|[A-Z]{2} ?[0-9]{2}|GE ?CX|GIR ?0A{2}|SAN ?TA1)$',
      placeholder: i18n.t('pages.practiceInfo.zip.gbPlaceholder'),
      errorMessage: i18n.t('pages.practiceInfo.zip.errorMessage', {
        validZipCode: i18n.t('pages.practiceInfo.zip.gbPlaceholder')
      })
    }
  }
}

export const useCountry = country => {
  const states = useMemo(() => COUNTRIES[country]?.states || [], [country])
  const defaultState = useMemo(() => COUNTRIES[country]?.defaultState, [country])

  const getDefaultState = useCallback(
    c => COUNTRIES[c]?.defaultState || COUNTRIES[DEFAULT_COUNTRY.key].defaultState,
    []
  )

  const getCountryKey = useCallback(country => COUNTRIES[country]?.key || DEFAULT_COUNTRY.key, [])
  const getStateKey = useCallback(
    (state, country) =>
      _.get(
        COUNTRIES[country]?.states?.find(s => s.name === state),
        'key',
        COUNTRIES[country]?.defaultState.key
      ),
    []
  )

  const getZipCodeProperties = useCallback(
    c => COUNTRIES[c]?.zipCodeProperties || { validationSchema: '', placeholder: '', errorMessage: '' },
    []
  )

  const countriesOptions = useMemo(() => {
    return Object.keys(COUNTRIES)
      .map(country => ({
        value: country,
        label: COUNTRIES[country].name
      }))
      .sort((c1, c2) => (c1.label.localeCompare(c2) < 0 ? -1 : 1))
  }, [])

  const countriesWithRequiredStates = [COUNTRY_CA, COUNTRY_US]
  const hasZipcode = useMemo(() => !!COUNTRIES[country]?.zipCodeProperties, [country])

  return {
    countries: COUNTRIES,
    countriesOptions,
    states,
    defaultCountry: DEFAULT_COUNTRY,
    defaultState,
    countriesWithRequiredStates,
    getDefaultState,
    getStateKey,
    getCountryKey,
    getZipCodeProperties,
    hasZipcode
  }
}
