import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import DazzedHeading24 from 'components/common/text/DazzedHeading24'
import TxTrackerPending from 'components/common/icons/treatmentTracker/TxTrackerPending'

const useStyles = makeStyles(theme => ({
  pendingStateContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  imgContainer: {
    marginBottom: 20
  },
  label: {
    color: 'rgba(170, 187, 210, 1)'
  }
}))

const PendingState = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.pendingStateContainer}>
      <div className={classes.imgContainer}>
        <TxTrackerPending />
      </div>
      <DazzedHeading24 className={classes.label}>{t('dialogs.treatmentTracker.pendingState.body')}</DazzedHeading24>
    </div>
  )
}

export default PendingState
