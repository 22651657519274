import React, { useCallback, useMemo } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { DAYS_OF_WEEK } from 'consts/dateTimeConsts'
import { useTranslation } from 'react-i18next'
import { compareDayOfWeekNames } from 'utils/dateUtils'

const useStyles = makeStyles(theme => ({
  day: {
    padding: 8,
    width: 40,
    height: 40,
    borderRadius: '100%',
    color: 'var(--text-color-25)',
    border: '1px solid var(--text-color-25)',
    cursor: 'pointer',
    userSelect: 'none'
  },
  selected: {
    backgroundColor: 'var(--bg-color-43)',
    color: 'white'
  }
}))

const DaysOfWeekSelector = ({ days, updateDays }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const daysOfWeekOptions = useMemo(
    () =>
      DAYS_OF_WEEK.map(day => ({
        label: t(`general.daysOfWeek.${day}`)[0],
        value: day
      })),
    [t]
  )

  const handleDayClick = useCallback(
    selectedDay => {
      let updatedDays = [...(days || [])]

      if (updatedDays.some(day => day === selectedDay)) {
        updatedDays = updatedDays.filter(day => day !== selectedDay)
      } else {
        updatedDays = [...updatedDays, selectedDay]
      }

      updatedDays.sort(compareDayOfWeekNames)
      updateDays(updatedDays)
    },
    [updateDays, days]
  )

  return (
    <Grid container alignItems="center" wrap="nowrap" spacing={1}>
      {daysOfWeekOptions.map(day => (
        <Grid item key={day.value} onClick={() => handleDayClick(day.value)}>
          <DazzedParagraph14
            textAlign="center"
            className={`${classes.day} ${days?.includes?.(day.value) ? classes.selected : ''}`}
          >
            {day.label}
          </DazzedParagraph14>
        </Grid>
      ))}
    </Grid>
  )
}

export default DaysOfWeekSelector
