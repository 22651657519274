export const searchPatientSearchModels = /* GraphQL */ `
  query SearchPatientSearchModels(
    $filter: SearchablePatientSearchModelFilterInput
    $sort: SearchablePatientSearchModelSortInput
    $limit: Int
    $from: Int
  ) {
    searchPatientSearchModels(filter: $filter, sort: $sort, limit: $limit, from: $from) {
      total
      nextToken
      items {
        id
        name
        email
        program
        createdAt
        statusType
        patient {
          photo {
            key
            bucket
            region
          }
        }
        status {
          status {
            key
            type
            settings
          }
        }
        doctorName
      }
    }
  }
`

export const searchDoctorSearchModels = /* GraphQL */ `
  query SearchDoctorSearchModels(
    $filter: SearchableDoctorSearchModelFilterInput
    $sort: SearchableDoctorSearchModelSortInput
    $limit: Int
    $from: Int
  ) {
    searchDoctorSearchModels(filter: $filter, sort: $sort, limit: $limit, from: $from) {
      total
      nextToken
      items {
        id
        name
        email
        username
        accessType
        practiceName
        doctor {
          auth_status
        }
        accountOwner {
          clinic {
            practiceName
          }
        }
      }
    }
  }
`
