import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, useMediaQuery } from '@material-ui/core'
import NotificationTypeLabel from './BaseGrinNotification/NotificationTypeLabel'
import { GrinNotificationTypes } from '@grin-rnd/grin-api-sdk/dist/Enums/Notifications'
import reasonIcons from 'components/common/icons/notifyPractice/index'
import IconAndText from 'components/common/IconAndText'
import { makeStyles } from '@material-ui/styles'
import Tooltip from 'components/common/Tooltip'

const useStyles = makeStyles({
  reason: {
    maxWidth: ({ isSmallScreen }) => (isSmallScreen ? 70 : 185),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
})

const NeedsAttentionNotificationTitle = ({ reason }) => {
  const isSmallScreen = useMediaQuery('(max-width:1920px)')
  const classes = useStyles({ isSmallScreen })
  const { t } = useTranslation()

  return (
    <Grid container alignItems="center">
      <Grid item>
        <NotificationTypeLabel>
          {t(`dialogs.notificationCenterDialog.notificationTypes.${GrinNotificationTypes.NeedsAttention}`)}
        </NotificationTypeLabel>
      </Grid>
      <Grid item style={{ margin: '0 3px' }}>
        <NotificationTypeLabel>•</NotificationTypeLabel>
      </Grid>
      <Grid item>
        <Tooltip value={t(`dialogs.notifyPractice.reasons.${reason}`)} placement="bottom">
          <IconAndText
            icon={React.createElement(reasonIcons[reason], { color: 'rgba(114, 123, 140, 1)' })}
            text={
              <NotificationTypeLabel className={classes.reason}>
                {t(`dialogs.notifyPractice.reasons.${reason}`)}
              </NotificationTypeLabel>
            }
          />
        </Tooltip>
      </Grid>
    </Grid>
  )
}

export default NeedsAttentionNotificationTitle
