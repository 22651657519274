import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import NeedsAttentionDisplayOnlyTag from 'components/common/tags/NeedsAttentionDisplayOnlyTag'
import reasonIcons from 'components/common/icons/notifyPractice/index'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
  needsAttentionSection: {
    marginLeft: 35,
    marginTop: 3,
    marginBottom: 5,
    alignItems: 'center'
  },
  circle: {
    fontSize: 5,
    color: 'var(--bg-color-45)'
  },
  needsAttentionReason: {
    color: 'var(--bg-color-45)'
  },
  body: {
    marginLeft: 50,
    marginTop: 3
  },
  mentioned: {
    color: 'var(--text-color-16)',
    fontWeight: 600
  }
}))

const NeedsAttentionNoteBody = ({ reason, hiNote, needsAttentionMentions }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid container>
      <Grid container className={classes.needsAttentionSection}>
        <NeedsAttentionDisplayOnlyTag />
        <span className={classes.circle}>{`●`}</span>
        {React.createElement(reasonIcons[reason], { color: 'var(--bg-color-45)', style: { margin: 2 } })}
        <DazzedParagraph12 className={classes.needsAttentionReason}>
          {t(`dialogs.notifyPractice.reasons.${reason}`)}
        </DazzedParagraph12>
      </Grid>
      <Grid container>
        <DazzedParagraph14 strong className={classes.body}>
          <span className={classes.mentioned}>{needsAttentionMentions}</span>
          {hiNote}
        </DazzedParagraph14>
      </Grid>
    </Grid>
  )
}

export default NeedsAttentionNoteBody
