import Actions from 'actions'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import { useHistory } from 'react-router'
import useFeatureFlags from 'hooks/useFeatureFlags'
import { ROUTES } from 'consts'
import OggHeading40 from 'components/common/text/OggHeading40'
import AddPracticeMemberButton from './AddPracticeMemberButton'
import MembersTable from './Table/MembersTable'

const useStyles = makeStyles(theme => ({
  practiceMembersContainer: {
    minWidth: 850
  },
  addPracticeMemberButton: {
    textAlign: 'end',
    marginBottom: 10
  }
}))

const PracticeMembers = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { practiceMembers: isPracticeMembersFFEnabled = false } = useFeatureFlags()
  const history = useHistory()
  const dispatch = useDispatch()

  const doctor = useSelector(state => state.profileReducer.doctor)
  const { data: practiceMembers, isLoading } = useSelector(state => state.profileReducer.practiceMembers)

  const handleClickAddMember = useCallback(() => history.push(ROUTES.PRACTICE_MEMBERS_ADD_MEMBER), [history])
  const handleCancelInvitation = useCallback(
    ({ memberId, name }) => dispatch(Actions.cancelPracticeMemberInvitation({ id: memberId, name })),
    [dispatch]
  )
  const handleResendInvitation = useCallback(
    ({ values, doctorId, memberId }) =>
      dispatch(Actions.resendPracticeMemberInvitation({ ...values, doctorId, id: memberId })),
    [dispatch]
  )
  const handleDeactivateUser = useCallback(memberId => dispatch(Actions.deactivatePracticeMember(memberId)), [dispatch])
  const handleActivateUser = useCallback(
    memberId => dispatch(Actions.activatePracticeMember(memberId)),

    [dispatch]
  )
  const handleChangeAccessType = useCallback(
    (memberId, accessType) =>
      dispatch(
        Actions.changePracticeMemberAccess({
          id: memberId,
          accessType
        })
      ),
    [dispatch]
  )

  useEffect(() => {
    doctor.user && !isPracticeMembersFFEnabled && history.push(ROUTES.ACCOUNT_SETTINGS)
  }, [doctor.user, isPracticeMembersFFEnabled, history])

  if (!isPracticeMembersFFEnabled) {
    return null
  }

  return (
    <>
      <Grid container alignItems="flex-end" justifyContent="space-between" className={classes.practiceMembersContainer}>
        <Grid item xs={9}>
          <OggHeading40 textAlign="left" className={classes.heading}>
            {t('pages.accountSettings.practiceMembers.title')}
          </OggHeading40>
        </Grid>
        <Grid item xs={3} className={classes.addPracticeMemberButton}>
          <AddPracticeMemberButton onClick={handleClickAddMember} />
        </Grid>
      </Grid>
      <Grid container>
        <MembersTable
          practiceMembers={practiceMembers}
          onCancelInvitation={handleCancelInvitation}
          onResendInvitation={handleResendInvitation}
          onDeactivateUser={handleDeactivateUser}
          onActivateUser={handleActivateUser}
          onChangeAccessType={handleChangeAccessType}
          isLoading={isLoading}
        />
      </Grid>
    </>
  )
}

export default PracticeMembers
