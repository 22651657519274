import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import ErrorStateIcon from '../Icons/ErrorStateIcon'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import LinkButton from 'components/common/buttons/LinkButton'
import RefreshBold from 'components/common/icons/RefreshBold'

const NotificationCenterErrorState = ({ onRetry }) => {
  const { t } = useTranslation()

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ height: '100%' }}
      spacing={2}
    >
      <Grid item>
        <ErrorStateIcon />
      </Grid>
      <Grid item>
        <DazzedParagraph16 textAlign="center" bold>
          {t('dialogs.notificationCenterDialog.errorState.title')}
        </DazzedParagraph16>
        <DazzedParagraph16 textAlign="center" strong>
          {t('dialogs.notificationCenterDialog.errorState.subtitle')}
        </DazzedParagraph16>
      </Grid>
      <Grid item>
        <LinkButton
          icon={<RefreshBold />}
          fontSize={16}
          bold
          underline
          label={t('dialogs.notificationCenterDialog.errorState.retryButton')}
          onClick={onRetry}
        />
      </Grid>
    </Grid>
  )
}

export default NotificationCenterErrorState
