import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import OralScoreIcon from '../MiniTimeline/TimelineItems/ScanThemeIcons/OralScoreIcon'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import { OralScoreBackgroundColors, OralScoreCategories, OralScoreColorCodes } from 'consts/oralScoreConsts'

const useStyles = makeStyles(theme => ({
  oralScoreTagRoot: {
    padding: ({ withBackground }) => (withBackground ? '4px 6px' : 0),
    borderRadius: '6px',
    backgroundColor: ({ backgroundColor }) => backgroundColor
  },
  iconContainer: {
    lineHeight: 0.8,
    marginLeft: 2,
    marginRight: 6
  },
  categoryLabel: {
    fontSize: ({ fontSize }) => fontSize
  },
  scoreLabel: {
    color: ({ scoreTextColor }) => scoreTextColor,
    marginLeft: 6,
    fontSize: ({ fontSize }) => fontSize
  }
}))

const OralScoreTag = ({
  scoreValue,
  boldLabel = true,
  withBackground = true,
  withIcon = true,
  category = OralScoreCategories.OralScore,
  fontSize = '12px'
}) => {
  const classes = useStyles({
    scoreTextColor: OralScoreColorCodes[scoreValue],
    backgroundColor: withBackground ? OralScoreBackgroundColors[scoreValue] : 'transparent',
    withBackground,
    fontSize
  })

  const { t } = useTranslation()

  return (
    <Grid container alignItems="center" className={classes.oralScoreTagRoot}>
      {withIcon && (
        <Grid item className={classes.iconContainer}>
          <OralScoreIcon score={scoreValue} iconSize={16} />
        </Grid>
      )}
      <Grid item style={{ flex: 1 }}>
        <DazzedParagraph12 thickness={boldLabel ? 'bold' : 'strong'} className={classes.categoryLabel}>
          {t(`pages.patients.selectedPatient.timelineV2.oralScores.categories.${category}`)}
        </DazzedParagraph12>
      </Grid>
      <Grid item>
        <DazzedParagraph12 thickness="bold" className={classes.scoreLabel}>
          {t(`pages.patients.selectedPatient.timelineV2.oralScores.scores.${scoreValue}`)}
        </DazzedParagraph12>
      </Grid>
    </Grid>
  )
}

export default OralScoreTag
