import React, { useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import { Overdue } from 'components/common/icons'
import { calcOverdueDate } from 'utils/milestoneUtils'
import { trackEvent } from 'utils/analyticsUtils'
import { PATIENT_CARD_TABS } from 'consts/patientCard'
import { Grid } from '@material-ui/core'
import useOpenPatientCard from 'hooks/useOpenPatientCard'
import { useSelector } from 'react-redux'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 0,
    marginLeft: 0,
    padding: '10px 16px',
    backgroundColor: 'var(--bg-color-32)',
    color: 'var(--text-color-20)'
  },
  icon: {
    display: 'flex'
  },
  changeScanDateLabel: {
    color: 'var(--text-color-54)',
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  spacer: {
    width: 8
  }
}))

const ScanOverdueBanner = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const openPatientCard = useOpenPatientCard()

  const patient = useSelector(state => state.patientsReducer.patient)
  const overdueDays = useMemo(() => calcOverdueDate(patient), [patient])

  const handleChangeScanDateClicked = useCallback(() => {
    trackEvent('Scan overdue banner - change next scan date clicked', {
      overdueDays
    })
    openPatientCard({ tabIndex: PATIENT_CARD_TABS.scanSettings.index })
  }, [openPatientCard, overdueDays])

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container alignItems="center" className={classes.root}>
          <Grid item>
            <Overdue color="var(--text-color-20)" width="15px" height="15px" className={classes.icon} />
          </Grid>
          <Grid item className={classes.spacer} />
          <Grid item>
            <DazzedParagraph12>
              {t('pages.patients.selectedPatient.timeline.banners.scanOverdue')}&nbsp;
              <strong>
                {t(`pages.patients.selectedPatient.timeline.banners.scanOverdueDay${overdueDays === 1 ? '' : 's'}`, {
                  date: overdueDays
                })}
              </strong>
            </DazzedParagraph12>
          </Grid>
          <Grid item className={classes.spacer} />
          <Grid item>
            <DazzedParagraph12 strong onClick={handleChangeScanDateClicked} className={classes.changeScanDateLabel}>
              {t('pages.patients.selectedPatient.timeline.banners.scanOverdueChangeLabel')}
            </DazzedParagraph12>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ScanOverdueBanner
