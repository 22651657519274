import { TreatmentTypes } from 'consts/treatmentConsts'
import _ from 'lodash'
import moment from 'moment'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { CartesianGrid, Line, LineChart, ReferenceLine, XAxis, YAxis } from 'recharts'
import { rangeWithStep } from 'utils/arrayUtils'
import { generateMonthsRange } from 'utils/dateUtils'
import { PROGRESS_TYPES } from '../../txTrackerConsts'
import useTeethTracking from '../useTeethTracking'
import GraphDot from './GraphDot'

const REFERENCE_LINE_COLOR = '#F25700'
const TREATMENT_PLAN_COLOR = '#A4B3C8'

const ToothProgressGraph = ({
  toothData,
  toothNumber,
  isTxGoalsEnabled,
  currentScan,
  progressType,
  totalPlannedValue,
  treatmentType
}) => {
  const { t } = useTranslation()
  const { txTrackerDataByTeeth, getToothTrackingStatus, getAsteriskData, getProgressFieldByProgressType } =
    useTeethTracking()

  const plannedValue = useMemo(
    () =>
      progressType === PROGRESS_TYPES.Rotation
        ? toothData.totalPlannedRotation
        : progressType === PROGRESS_TYPES.Translation
        ? toothData.totalPlannedTranslation
        : toothData.totalPlannedMovement,
    [progressType, toothData]
  )

  const yAxisMax = useMemo(() => (Number(plannedValue) + 1).toFixed(1), [plannedValue])
  // 0 to 5 in steps of 0.5
  const yAxisTicks = useMemo(() => rangeWithStep({ end: yAxisMax, step: 0.25 }), [yAxisMax])

  const toothDataOverTime = useMemo(
    () => _.sortBy(txTrackerDataByTeeth[toothNumber], 'createdAt'),
    [toothNumber, txTrackerDataByTeeth]
  )

  // Generating all months passed from treatment start until latest treatment update
  const xAxisTicks = useMemo(
    () =>
      generateMonthsRange({
        originDate: toothDataOverTime[0].createdAt,
        targetDate: toothDataOverTime[toothDataOverTime.length - 1].createdAt
      }),
    [toothDataOverTime]
  )

  const graphData = useMemo(
    () =>
      toothDataOverTime.map(
        (
          {
            createdAt,
            currentProgress,
            currentRotation,
            currentTranslation,
            deviation,
            pastMonthSpeed,
            currentPlannedProgress,
            currentPlannedRotation,
            currentPlannedTranslation,
            chosenStage,
            scanNumber,
            alignerNumber,
            unknown,
            no_movement_planned,
            low_confidence
          },
          index
        ) => ({
          createdAt: moment(createdAt).valueOf(),
          currentScanCreatedAt: moment(currentScan.createdAt).valueOf(),
          value: getProgressFieldByProgressType({ currentProgress, currentRotation, currentTranslation, progressType }),
          scanNumber,
          alignerNumber,
          treatmentPlanValue: getProgressFieldByProgressType({
            currentProgress: currentPlannedProgress,
            currentRotation: currentPlannedRotation,
            currentTranslation: currentPlannedTranslation,
            progressType
          }),
          trackingStatus: getToothTrackingStatus({
            toothData: {
              deviation,
              pastMonthSpeed,
              unknown,
              no_movement_planned,
              low_confidence
            },
            treatmentType
          }),
          shouldApplyAsterisk: getAsteriskData({
            unknown,
            no_movement_planned,
            low_confidence
          }).shouldApplyAsterisk
        })
      ),
    [
      currentScan.createdAt,
      getProgressFieldByProgressType,
      getToothTrackingStatus,
      getAsteriskData,
      progressType,
      toothDataOverTime,
      treatmentType
    ]
  )

  const graphDataWithoutUnknown = useMemo(
    () => graphData.filter(data => data.trackingStatus !== 'unknown'),
    [graphData]
  )

  const monthTickFormatter = useCallback(tick => moment(tick).format('MMM'), [])

  return (
    <LineChart
      width={600}
      height={350}
      data={graphDataWithoutUnknown}
      margin={{
        top: 10,
        left: 10
      }}
    >
      <CartesianGrid stroke="#AEC7E1" strokeOpacity={0.5} fill="#F7FBFF" />
      <XAxis
        dataKey="createdAt"
        scale="time"
        type="number"
        height={45}
        domain={[graphData[0].createdAt, graphData[graphData.length - 1].createdAt]}
        ticks={xAxisTicks}
        tickFormatter={monthTickFormatter}
        label={
          <text x={275} y={348} fill="#00000085" fontSize="12" fontWeight="bold">
            {t('dialogs.treatmentTracker.teethTracking.table.tooltip.xAxisLabel')}
          </text>
        }
      />
      <YAxis
        type="number"
        width={65}
        ticks={yAxisTicks}
        domain={['dataMin', 'dataMax']}
        label={
          <text x={-100} y={65} fill="#00000085" fontSize="12" fontWeight="bold" transform="rotate(-90, 10, 50)">
            {t(
              `dialogs.treatmentTracker.teethTracking.table.tooltip.yAxisLabel${
                progressType === PROGRESS_TYPES.Rotation ? 'Degrees' : 'MM'
              }`
            )}
          </text>
        }
      />
      <>
        <text x={80} y={25} fill={TREATMENT_PLAN_COLOR} fontSize="12" fontWeight="bold">
          {t('dialogs.treatmentTracker.teethTracking.table.tooltip.movementEvalLabel')}
        </text>
        <line x1={180} y1={20} x2={230} y2={20} stroke="#5A6DDA" strokeWidth="2" />
        {isTxGoalsEnabled && (
          <>
            {treatmentType === TreatmentTypes.Aligners && (
              <text x={80} y={45} fill={TREATMENT_PLAN_COLOR} fontSize="12" fontWeight="bold">
                {t('dialogs.treatmentTracker.teethTracking.table.tooltip.txPlanLabel')}
              </text>
            )}
            <ReferenceLine
              y={totalPlannedValue}
              stroke={REFERENCE_LINE_COLOR}
              label={{
                position: {
                  x: 500,
                  y: -5
                },
                value: t('dialogs.treatmentTracker.teethTracking.table.tooltip.txGoal'),
                fill: REFERENCE_LINE_COLOR,
                fontSize: 14,
                fontWeight: 600
              }}
            />
            {treatmentType === TreatmentTypes.Aligners && (
              <Line
                type="monotone"
                stroke={TREATMENT_PLAN_COLOR}
                strokeDasharray="3 3"
                dataKey="treatmentPlanValue"
                y={plannedValue}
                dot={null}
              />
            )}
          </>
        )}
      </>
      <Line type="monotone" dataKey="value" stroke="var(--text-color-25)" dot={<GraphDot />} />
    </LineChart>
  )
}

export default ToothProgressGraph
