import { CircularProgress, Grid, makeStyles } from '@material-ui/core'
import Actions from 'actions'
import FormInput from 'components/common/FormInput'
import PrimaryButton from 'components/common/buttons/PrimaryButton'
import SecondaryButton from 'components/common/buttons/SecondaryButton'
import LegalGuardian from 'components/common/icons/LegalGuardian'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { CONTENT_HEIGHT } from 'consts/patientCard'
import usePatientFieldValidation from 'hooks/usePatientFieldValidation'
import useRolePermissions from 'hooks/useRolePermissions'
import _ from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { trackEvent } from 'utils/analyticsUtils'
import { calculateAge } from 'utils/patientUtils'
import LinkedAccountsSection from './LinkedAccountsSection'

const useStyles = makeStyles({
  container: {
    height: CONTENT_HEIGHT,
    padding: '45px 50px 0 5%',
    position: 'relative',
    overflowY: 'auto'
  },
  loaderContainer: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    zIndex: 999,
    backgroundColor: 'var(--bg-color-28)'
  },
  buttonsContainer: {
    paddingBottom: '50px'
  }
})

const LegalGuardianTab = ({ onUpdatePatientInfo, setActiveTab, firstTabIndex }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const { permissions } = useRolePermissions()

  const {
    details: patientDetails,
    id: patientId,
    isLead,
    _version: patientVersion,
    guardianId
  } = useSelector(state => state.patientsReducer.patientCard?.patient || {})

  const isLoadingDetails = useSelector(state => state.patientsReducer.patientCard?.isLoadingDetails)

  const [legalGuardianEmail, setLegalGuardianEmail] = useState(patientDetails?.parentEmail)
  const [legalGuardianFirstName, setLegalGuardianFirstName] = useState(patientDetails?.parentFirstName)
  const [legalGuardianLastName, setLegalGuardianLastName] = useState(patientDetails?.parentLastName)
  const [formErrors, setFormErrors] = useState(null)

  const isLoading = useSelector(state => state.patientsReducer?.isLoading)

  const { validateFields: validatePatientFields } = usePatientFieldValidation()
  const { under18 } = useMemo(() => calculateAge({ birthdate: patientDetails?.birthdate }), [patientDetails])
  const formInputStyle = useMemo(
    () => ({
      bright: true,
      thick: true
    }),
    []
  )

  const renderFormItem = useCallback(
    ({ title, style, value, setValue, allowAutoComplete, errorMessage }) => (
      <Grid key={title} item xs={4}>
        <FormInput
          title={title}
          style={style}
          value={value}
          setValue={setValue}
          allowAutoComplete={allowAutoComplete}
          errorMessage={errorMessage}
        />
      </Grid>
    ),
    []
  )

  const guardianFields = useMemo(
    () => [
      {
        title: t('dialogs.patientInfo.legalGuardian.firstNameGuardianLabel'),
        style: formInputStyle,
        value: legalGuardianFirstName,
        setValue: setLegalGuardianFirstName,
        allowAutoComplete: false,
        errorMessage: formErrors?.guardianFirstName
      },
      {
        title: t('dialogs.patientInfo.legalGuardian.lastNameGuardianLabel'),
        style: formInputStyle,
        value: legalGuardianLastName,
        setValue: setLegalGuardianLastName,
        allowAutoComplete: false,
        errorMessage: formErrors?.guardianLastName
      },
      {
        title: t('dialogs.patientInfo.legalGuardian.emailGuardianLabel'),
        style: formInputStyle,
        value: legalGuardianEmail,
        setValue: setLegalGuardianEmail,
        allowAutoComplete: false,
        errorMessage: formErrors?.guardianEmail
      }
    ],
    [t, formInputStyle, legalGuardianEmail, legalGuardianFirstName, legalGuardianLastName, formErrors]
  )

  const handleUpdatePatientInfo = useCallback(
    values => {
      onUpdatePatientInfo({
        id: patientId,
        isLead,
        _version: patientVersion,
        details: {
          ...patientDetails,
          ...values
        }
      })
    },
    [onUpdatePatientInfo, patientDetails, patientId, patientVersion, isLead]
  )

  const validateFields = useCallback(() => {
    let errors = {}
    validatePatientFields({
      nameFields: [
        { fieldName: 'guardianFirstName', fieldValue: legalGuardianFirstName },
        { fieldName: 'guardianLastName', fieldValue: legalGuardianLastName }
      ],
      emailFields: [{ fieldName: 'guardianEmail', fieldValue: legalGuardianEmail }],
      errorsDict: errors
    })

    if (!_.isEqual(errors, formErrors)) {
      setFormErrors(errors)
    }
    return errors
  }, [validatePatientFields, formErrors, legalGuardianFirstName, legalGuardianLastName, legalGuardianEmail])

  const resetForm = useCallback(() => {
    setLegalGuardianEmail(patientDetails?.parentEmail)
    setLegalGuardianFirstName(patientDetails?.parentFirstName)
    setLegalGuardianLastName(patientDetails?.parentLastName)
  }, [patientDetails])

  const handleSaveChanges = useCallback(() => {
    trackEvent('Patient card - legal guardian save pressed')
    let errors = validateFields()
    if (_.isEmpty(errors)) {
      handleUpdatePatientInfo({
        parentEmail: legalGuardianEmail.trim(),
        parentFirstName: legalGuardianFirstName.trim(),
        parentLastName: legalGuardianLastName.trim()
      })
    }
  }, [handleUpdatePatientInfo, legalGuardianEmail, legalGuardianFirstName, legalGuardianLastName, validateFields])

  const handleDiscardChanges = useCallback(() => {
    trackEvent('Patient card - legal guardian cancel pressed')
    if (patientDetails?.parentLastName || patientDetails?.parentFirstName || patientDetails?.parentEmail) {
      resetForm()
      setFormErrors(null)
    } else {
      setActiveTab(firstTabIndex)
    }
  }, [patientDetails, setActiveTab, firstTabIndex, resetForm])

  useEffect(() => {
    if (formErrors) {
      validateFields()
    }
  }, [legalGuardianFirstName, legalGuardianLastName, legalGuardianEmail, formErrors, validateFields])

  useEffect(() => {
    if (!guardianId) {
      return
    }

    dispatch(Actions.fetchPatientSiblings({ guardianId }))
  }, [guardianId, dispatch])

  return (
    <>
      {isLoading && (
        <div className={classes.loaderContainer}>
          <CircularProgress color="primary" />
        </div>
      )}
      <Grid justifyContent="space-between" container className={classes.container} direction="column">
        <Grid item>
          <Grid container direction="column" spacing={4}>
            {under18 && (
              <Grid item>
                <Grid container spacing={2}>
                  <Grid item>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item>
                        <LegalGuardian />
                      </Grid>
                      <Grid item>
                        <DazzedParagraph14 bold>
                          {t('dialogs.patientInfo.legalGuardian.legalGuardianSectionTitle')}
                        </DazzedParagraph14>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid spacing={6} container direction="row">
                      {guardianFields.map(item => renderFormItem(item))}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid item>
              <LinkedAccountsSection />
            </Grid>
          </Grid>
        </Grid>
        {permissions.editFamilyInfoTab && (
          <Grid item>
            <Grid className={classes.buttonsContainer} item>
              <Grid spacing={6} container direction="row" justifyContent="center">
                <Grid item>
                  <SecondaryButton
                    label={t('general.discard')}
                    isLoading={isLoadingDetails}
                    type="submit"
                    onClick={handleDiscardChanges}
                    disabled={isLoadingDetails}
                  />
                </Grid>
                <Grid item>
                  <PrimaryButton
                    label={t('dialogs.patientInfo.legalGuardian.primaryButton')}
                    isLoading={isLoadingDetails}
                    onClick={handleSaveChanges}
                    type="submit"
                    disabled={isLoadingDetails}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default LegalGuardianTab
