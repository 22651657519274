import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, TextField } from '@material-ui/core'
import { conditionTypes } from 'consts/automatedActionConsts'
import { useSelector } from 'react-redux'
import { Autocomplete } from '@material-ui/lab'
import { StatusTypes } from '@grin-rnd/grin-api-sdk/dist/Enums/Statuses'

const PatientStatus = ({ onChange = () => {}, params = {}, isSystem }) => {
  const { t } = useTranslation()

  const statuses = useSelector(state => state.treatmentReducer.statuses.types)

  const ordinaryStatuses = useMemo(
    () => [StatusTypes.ActiveTreatment, StatusTypes.Retention, StatusTypes.Observation, StatusTypes.NewLead],
    []
  )

  const options = useMemo(
    () =>
      statuses
        .filter(status => (isSystem ? true : ordinaryStatuses.includes(status)))
        .map(statusKey => ({ value: statusKey, label: t(`statuses.names.${statusKey}`) })),
    [isSystem, ordinaryStatuses, statuses, t]
  )

  return (
    <Grid container direction="column">
      <Grid item>
        <Autocomplete
          id="patient-statuses-selector"
          multiple
          fullWidth
          filterSelectedOptions
          style={{ minWidth: 300 }}
          options={options}
          value={params?.statuses || []}
          getOptionLabel={option => option.label}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={t('pages.accountSettings.automations.editorModal.patientStatus')}
            />
          )}
          onChange={(event, updatedSelectedStatuses, reason) => {
            onChange({ type: conditionTypes.PatientStatus, params: { statuses: updatedSelectedStatuses } })
          }}
        />
      </Grid>
    </Grid>
  )
}

export default PatientStatus
