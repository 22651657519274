import React, { useCallback, useMemo } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { trackEvent } from 'utils/analyticsUtils'
import {
  AccountSettingsQuickLink,
  GrinAssetsQuickLink,
  OrderScopesQuickLink,
  PracticeAnalyticsQuickLink,
  ShareFeedbackQuickLink
} from 'components/common/icons'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { ROUTES } from 'consts'
import useOrderScopes from 'hooks/useOrderScopes'
import actions from 'actions'
import { createIdForTest } from 'utils/testingUtils.js'

const useStyles = () =>
  makeStyles({
    container: {
      borderTop: '1px solid var(--bg-color-14)',
      borderBottom: '1px solid var(--bg-color-14)'
    },
    linkItem: {
      padding: '10px 20px 10px 20px',
      userSelect: 'none',
      cursor: 'pointer'
    }
  })()

const QuickLinks = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const grinAssetsLink = useSelector(state => state.appReducer.appconfig.app?.grinAssetsLink)

  const { openOrderScopesModal } = useOrderScopes()

  const handleLinkClick = useCallback((linkKey, onClick) => {
    trackEvent('Patients chat - Empty state - quick link clicked', {
      linkType: linkKey
    })
    onClick()
  }, [])

  const links = useMemo(
    () => [
      {
        key: 'accountSettings',
        icon: <AccountSettingsQuickLink />,
        label: t('common.appMenu.accountSettings'),
        handleClick: () => history.push(ROUTES.ACCOUNT_SETTINGS)
      },
      {
        key: 'orderScopes',
        icon: <OrderScopesQuickLink />,
        label: t('common.appMenu.orderScopes'),
        handleClick: openOrderScopesModal
      },
      {
        key: 'practiceAnalytics',
        icon: <PracticeAnalyticsQuickLink />,
        label: t('common.appMenu.practiceAnalytics'),
        handleClick: () => history.push(ROUTES.NEW_DOC_STATS)
      },
      {
        key: 'grinAssets',
        icon: <GrinAssetsQuickLink />,
        label: t('common.appMenu.marketingAssets'),
        handleClick: () => window.open(grinAssetsLink, '_blank')
      },
      {
        key: 'shareFeedback',
        icon: <ShareFeedbackQuickLink />,
        label: t('common.appMenu.shareFeedback'),
        handleClick: () => {
          trackEvent('App Feedback Modal - modal opened', {
            source: 'Empty state quick link'
          })
          dispatch(
            actions.toggleAppFeedbackDialog({
              value: true,
              description: t('dialogs.shareFeedback.feedbackDescription')
            })
          )
        }
      }
    ],
    [dispatch, grinAssetsLink, openOrderScopesModal, history, t]
  )

  return (
    <Grid container alignItems="center" className={classes.container}>
      {links.map((link, i) => (
        <Grid
          item
          id={createIdForTest({ componentId: 'empty-state-quick-link', title: link.label })}
          key={`${link.label}-${i}`}
          className={classes.linkItem}
          onClick={() => handleLinkClick(link.key, link.handleClick)}
        >
          <Grid container direction="column" alignItems="center" justifyContent="center">
            <Grid item>{link.icon}</Grid>
            <Grid item>
              <DazzedParagraph14 color={'var(--text-color-51)'}>{link.label}</DazzedParagraph14>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  )
}

export default QuickLinks
