import React, { useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import BaseModal from 'components/common/modals/BaseModal'
import { useDispatch, useSelector } from 'react-redux'
import Actions from 'actions'
import { Grid } from '@material-ui/core'
import CompareScansHeader from './CompareScansHeader'
import useScansComparison from './useScansComparison'
import { trackEvent } from 'utils/analyticsUtils'
import ScanSelection from './ScanSelection'
import CompareScansLinkButton from './CompareScansLinkButton'
import useScanLinkage from './useScanLinkage'
import usePreventive from '../ScanSummary/Preventive/usePreventive'
import { IMAGE_MODES } from 'consts/scanSummaryConsts'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: '100%'
  },
  title: {
    fontFamily: 'Dazzed',
    fontSize: 24,
    fontWeight: 600,
    textAlign: 'start!important',
    width: '100%',
    paddingTop: '15px !important',
    paddingLeft: 12,
    paddingBottom: '0px !important'
  },
  dialogContent: {
    padding: '0 48px 8px 48px!important'
  }
}))

const CompareScansDialog = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [imagesMode, setImagesMode] = useState(IMAGE_MODES.normal)

  const { isOpen } = useSelector(state => state.treatmentReducer.compareScans)
  const { scanSelectionOptions, isLinked, toggleLink } = useScansComparison()
  const {
    preventiveLayers,
    displayedPreventiveLayers,
    isAnyPreventiveLayerVisible,
    togglePreventiveLayer,
    toggleAllPreventiveLayers,
    clearActiveLayers
  } = usePreventive({ imagesMode, setImagesMode, defaultImageMode: IMAGE_MODES.normal })

  const scanLinkage = useScanLinkage({
    onPause: data => trackEvent('Compare Scans - video pause', data),
    onPlay: data => trackEvent('Compare Scans - video play', data),
    onSeek: data => trackEvent('Compare Scans - video seek', data)
  })

  const handleClose = useCallback(() => {
    trackEvent('Compare Scans - dialog closed')
    dispatch(Actions.setCompareScansDialogOpen({ isOpen: false }))
  }, [dispatch])

  return (
    <BaseModal
      open={isOpen}
      handleClose={handleClose}
      secondaryLabel={t('general.close')}
      onSecondaryBtnClick={handleClose}
      title={t('pages.patients.selectedPatient.timeline.compareScans.dialogTitle')}
      className={classes.root}
      titleClassName={classes.title}
      contentClassName={classes.dialogContent}
    >
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <CompareScansHeader />
        </Grid>
        <Grid item xs={12}>
          <Grid container alignItems="stretch">
            <Grid item id="compare-scans-left-scan">
              <ScanSelection
                side="left"
                scanSelectionOptions={scanSelectionOptions}
                scanLinkage={scanLinkage}
                preventiveLayers={preventiveLayers}
                displayedPreventiveLayers={displayedPreventiveLayers}
                isAnyPreventiveLayerVisible={isAnyPreventiveLayerVisible}
                onTogglePreventiveLayer={togglePreventiveLayer}
                onToggleAllPreventiveLayers={toggleAllPreventiveLayers}
                onClearActiveLayers={clearActiveLayers}
              />
            </Grid>
            <Grid item style={{ alignSelf: 'center' }} id="compare-scans-link-button">
              <CompareScansLinkButton onClick={toggleLink} isLinked={isLinked} />
            </Grid>
            <Grid item id="compare-scans-right-scan">
              <ScanSelection
                side="right"
                scanSelectionOptions={scanSelectionOptions}
                scanLinkage={scanLinkage}
                preventiveLayers={preventiveLayers}
                displayedPreventiveLayers={displayedPreventiveLayers}
                isAnyPreventiveLayerVisible={isAnyPreventiveLayerVisible}
                onTogglePreventiveLayer={togglePreventiveLayer}
                onToggleAllPreventiveLayers={toggleAllPreventiveLayers}
                onClearActiveLayers={clearActiveLayers}
              />
            </Grid>
          </Grid>
        </Grid>
        {scanLinkage.videoPartialLinkage && scanLinkage.linkEnabled && (
          <Grid item xs={12}>
            <DazzedParagraph14 strong>
              {t('pages.patients.selectedPatient.timeline.compareScans.partialVideoLinkage')}
            </DazzedParagraph14>
          </Grid>
        )}
      </Grid>
    </BaseModal>
  )
}

export default CompareScansDialog
