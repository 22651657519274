import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import { Search } from 'components/common/icons'

const useStyles = makeStyles(theme => ({
  searchInputRoot: {
    padding: 12,
    border: '1px solid rgba(114, 123, 140, 1)',
    borderRadius: 6
  },
  iconContainer: {
    lineHeight: 0.8,
    marginRight: 4
  },
  searchInput: {
    width: '100%',
    border: 'none',
    outline: 'none',
    fontSize: 14,
    fontFamily: 'Dazzed',
    color: 'rgba(114, 123, 140, 1)',
    '&::placeholder': {
      color: 'rgba(171, 176, 184, 1)'
    }
  }
}))

/**
 * @type {import('./PracticeSearchV3').SearchInputComponent}
 */
const SearchInput = ({ value = '', setValue = () => {} }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid container alignItems="center" className={classes.searchInputRoot}>
      <Grid item className={classes.iconContainer}>
        <Search width={18} height={18} color="rgba(114, 123, 140, 1)" />
      </Grid>
      <Grid item>
        <input
          className={classes.searchInput}
          placeholder={t('pages.patients.patientsList.searchByDoctor.search')}
          type="text"
          value={value}
          autoComplete="off"
          onChange={({ target }) => setValue(target.value)}
          autoFocus
          onKeyDown={e => e.stopPropagation()}
        />
      </Grid>
    </Grid>
  )
}

export default SearchInput
