import * as React from 'react'

const AddDoctor = () => (
  <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 12.9782C0 11.7781 0.715191 10.6935 1.81824 10.2208L3.84864 9.35058C5.86103 8.48813 8.13896 8.48813 10.1514 9.35058L12.1818 10.2208C13.2848 10.6935 14 11.7781 14 12.9782V15C14 15.5523 13.5523 16 13 16H1C0.447716 16 0 15.5523 0 15V12.9782Z"
      fill="#4451DE"
    />
    <circle cx="7" cy="4" r="4" fill="#4451DE" />
    <path d="M5 9L7 16L3.5 12L5 9Z" fill="white" />
    <path d="M9 9L7 16L10.5 12L9 9Z" fill="white" />
  </svg>
)

export default AddDoctor
