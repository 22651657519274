import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import useNotificationCenterTabs from './useNotificationCenterTabs'
import GrinTabs from 'components/common/tabs/GrinTabs'
import GrinTab from 'components/common/tabs/GrinTab'
import { LinearProgress } from '@material-ui/core'
import TabScrollButton from './TabScrollButton'

const useStyles = makeStyles(theme => ({
  tabsList: {
    borderBottom: '1px solid rgba(171, 176, 184, 1)',
    position: 'relative'
  },
  tabItem: {
    padding: 8,
    minWidth: 'auto!important',
    fontWeight: 500,
    fontSize: '13px!important'
  },
  linearProgress: {
    height: 3
  },
  linearProgressBar: {
    backgroundColor: 'var(--bg-color-7)'
  }
}))

const NotificationCenterTabs = ({ isLoading }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { tabs, selectedTab, handleSwitchTab } = useNotificationCenterTabs()
  const handleTabClicked = useCallback(
    (e, tabId) => {
      handleSwitchTab({ tab: tabId })
    },
    [handleSwitchTab]
  )

  return (
    <div>
      <GrinTabs
        value={selectedTab}
        onChange={handleTabClicked}
        className={classes.tabsList}
        variant="scrollable"
        scrollButtons={'on'}
        ScrollButtonComponent={TabScrollButton}
      >
        {tabs.map(tab => (
          <GrinTab
            key={tab.id}
            label={t(`dialogs.notificationCenterDialog.tabs.${tab.id}`)}
            value={tab.id}
            className={classes.tabItem}
            marginRight={16}
          />
        ))}
      </GrinTabs>
      {isLoading ? (
        <LinearProgress
          className={classes.linearProgress}
          classes={{
            bar: classes.linearProgressBar
          }}
        />
      ) : (
        <div className={classes.linearProgress} />
      )}
    </div>
  )
}

export default NotificationCenterTabs
