import React from 'react'
import { useTranslation } from 'react-i18next'
import TeethSelectionFlow from './Actions/TeethSelectionFlow'
import SetupElasticsPopup from './Actions/Elastics/SetupElasticsPopup'
import { GuidelineCategories, PatientBriefActions } from 'consts/hiToolsConsts'
import { Grid } from '@material-ui/core'
import TeethSelectionPreview from './Actions/TeethSelectionPreview'
import { sortTeeth } from 'utils/giUtils'
import NightTimeOnly from './Actions/NightTimeOnly'

const ElasticsAssistantCategory = ({ categoryValues = {}, onChange, viewMode }) => {
  const { t } = useTranslation()

  return (
    <div>
      <TeethSelectionFlow
        selections={categoryValues[PatientBriefActions.Elastics]}
        setSelections={selections => onChange({ [PatientBriefActions.Elastics]: selections })}
        addSelectionsButtonText={t('dialogs.patientBrief.actions.setupElastics')}
        withEditButton={false}
        viewMode={viewMode}
        category={GuidelineCategories.Elastics}
        TeethSelectionPopupComponent={SetupElasticsPopup}
        SelectionsPreviewComponent={({ selections: elastics }) => (
          <Grid container spacing={1}>
            {elastics.map((elastic, i) => (
              <Grid item key={`elastic-preview-${i}`} style={{ marginRight: 5 }}>
                <TeethSelectionPreview
                  label={`${elastic.name}:`}
                  selections={sortTeeth(Object.keys(elastic.selections)).map(
                    tooth => `${tooth}-${elastic.selections[tooth]}`
                  )}
                  viewMode={viewMode}
                  withPencil={false}
                />
              </Grid>
            ))}
          </Grid>
        )}
      />
      <NightTimeOnly
        checked={categoryValues[PatientBriefActions.NightTimeOnly]}
        onChange={isChecked => onChange({ [PatientBriefActions.NightTimeOnly]: isChecked })}
        viewMode={viewMode}
      />
    </div>
  )
}

export default ElasticsAssistantCategory
