import React from 'react'
import { useTranslation } from 'react-i18next'
import GenericGuidelineAction from './GenericGuidelineAction'
import { OkToAdvance, StayInAligner } from 'components/common/icons'

const StayInAlignerUntilReplacedOrAdvance = ({ shouldStayInAligner, shouldAdvance }) => {
  const { t } = useTranslation()

  return shouldStayInAligner ? (
    <GenericGuidelineAction
      icon={<StayInAligner />}
      text={t('dialogs.hiPatientGuidelinesViewer.actions.stayInAligner')}
    />
  ) : (
    shouldAdvance && (
      <GenericGuidelineAction icon={<OkToAdvance />} text={t('dialogs.hiPatientGuidelinesViewer.actions.advance')} />
    )
  )
}

export default StayInAlignerUntilReplacedOrAdvance
