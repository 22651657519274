import * as React from 'react'

export default () => {
  return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="6.5" cy="6.5" r="6.5" fill="#001335" />
      <path
        d="M6.54626 1.34595L6.8892 2.58221C7.37707 4.34095 8.75128 5.71516 10.51 6.20303L11.7463 6.54596L10.51 6.8889C8.75128 7.37676 7.37707 8.75098 6.8892 10.5097L6.54626 11.746L6.20333 10.5097C5.71546 8.75098 4.34125 7.37676 2.58251 6.8889L1.34625 6.54596L2.58251 6.20303C4.34125 5.71516 5.71546 4.34095 6.20333 2.58221L6.54626 1.34595Z"
        fill="white"
      />
    </svg>
  )
}
