import * as React from 'react'

export default () => (
  <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="2.72559" y="10.1943" width="9.6601" height="1.94312" rx="0.66624" stroke="white" />
    <path
      d="M4.00195 7.03068C4.00195 5.06834 5.59275 3.47754 7.55509 3.47754V3.47754C9.51744 3.47754 11.1082 5.06833 11.1082 7.03068V10.1952H4.00195V7.03068Z"
      stroke="white"
    />
    <path
      d="M5.49707 8.41577V7.10234C5.49707 5.88732 6.48204 4.90234 7.69707 4.90234H7.88434"
      stroke="white"
      strokeLinecap="round"
    />
    <path d="M13.1074 7.30762H13.9957" stroke="white" strokeLinecap="round" />
    <path d="M1.11523 7.30762H2.00352" stroke="white" strokeLinecap="round" />
    <path d="M7.55566 1.75586L7.55566 0.867574" stroke="white" strokeLinecap="round" />
    <path d="M10.332 2.5L10.7762 1.73072" stroke="white" strokeLinecap="round" />
    <path d="M4.33594 1.73047L4.78008 2.49975" stroke="white" strokeLinecap="round" />
    <path d="M12.3633 4.53223L13.1326 4.08808" stroke="white" strokeLinecap="round" />
    <path d="M1.97852 4.08789L2.74779 4.53203" stroke="white" strokeLinecap="round" />
  </svg>
)
