import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Mail2, RedDot } from 'components/common/icons'
import Tooltip from 'components/common/Tooltip'

const useStyles = makeStyles(theme => ({
  container: {
    padding: '0 5px',
    cursor: 'pointer'
  },
  unseenIndicator: {
    position: 'absolute',
    left: 12,
    bottom: 5
  },
  actions: {
    padding: 5
  },
  assignMemberButton: {
    cursor: 'pointer',
    marginLeft: 10
  },
  item: {
    display: 'flex',
    position: 'relative'
  }
}))

const AssigneeActions = ({ assigneeTag }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const handleUnseenIndicatorClicked = useCallback(() => {
    assigneeTag.onClick()
  }, [assigneeTag])

  return (
    <Grid container className={classes.container}>
      <Grid item className={classes.item} onClick={handleUnseenIndicatorClicked}>
        <Tooltip arrow={true} value={t('pages.patients.selectedPatient.header.seeNotes')} placement="bottom">
          <Mail2 />
        </Tooltip>
        {assigneeTag.showUnseenIndicator && (
          <div className={classes.unseenIndicator}>
            <RedDot />
          </div>
        )}
      </Grid>
    </Grid>
  )
}

export default AssigneeActions
