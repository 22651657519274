import React, { useCallback } from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import ActionCheckbox from './ActionCheckbox'

const CallForAppointmentOrConfirmIPR = ({ callForAppointment, confirmIPR, onChange }) => {
  const { t } = useTranslation()

  const handleToggleShouldCallForAppointment = useCallback(
    isChecked => {
      onChange({
        callForAppointment: isChecked,
        confirmIPR: false
      })
    },
    [onChange]
  )

  const handleToggleShouldConfirmIPR = useCallback(
    isChecked => {
      onChange({
        confirmIPR: isChecked,
        callForAppointment: false
      })
    },
    [onChange]
  )

  return (
    <Grid container direction="column" style={{ minWidth: 450 }}>
      <Grid item>
        <ActionCheckbox
          variant="radio"
          checked={callForAppointment}
          onChange={handleToggleShouldCallForAppointment}
          caption={t(
            'pages.accountSettings.practiceGuidelines.actions.callForAppointmentOrConfirmIPR.callForAppointment'
          )}
        />
      </Grid>
      <Grid item>
        <ActionCheckbox
          variant="radio"
          checked={confirmIPR}
          onChange={handleToggleShouldConfirmIPR}
          caption={t('pages.accountSettings.practiceGuidelines.actions.callForAppointmentOrConfirmIPR.confirmIPR')}
        />
      </Grid>
    </Grid>
  )
}

export default CallForAppointmentOrConfirmIPR
