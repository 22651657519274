import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid, Grow, IconButton } from '@material-ui/core'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import LinkButton from 'components/common/buttons/LinkButton'
import CloseIcon from 'components/common/icons/Close7x7'

const useStyles = makeStyles(theme => ({
  basePromptRoot: {
    position: 'absolute',
    right: 24,
    top: 24,
    padding: 8,
    borderRadius: 8,
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.15)',
    maxWidth: 590,
    zIndex: 9999
  }
}))

const BasePrompt = ({ className = '', text = '', icon, isOpen, onDismiss, buttonLabel, buttonOnClick }) => {
  const classes = useStyles()

  return (
    <Grow in={isOpen}>
      <Grid
        container
        direction="row"
        alignItems="center"
        spacing={2}
        className={[classes.basePromptRoot, className].join(' ')}
      >
        {icon && <Grid item>{icon}</Grid>}
        <Grid item style={{ flex: 1 }}>
          <DazzedParagraph12 thickness="strong">{text}</DazzedParagraph12>
        </Grid>
        {buttonLabel && buttonOnClick && (
          <Grid item>
            <LinkButton fontSize={12} underline label={buttonLabel} onClick={buttonOnClick} />
          </Grid>
        )}
        {onDismiss && (
          <Grid item>
            <IconButton onClick={onDismiss} size="small">
              <CloseIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </Grow>
  )
}

export default BasePrompt
