import React from 'react'
import { makeStyles, Tooltip as MuiTooltip } from '@material-ui/core'

const useStyles = () =>
  makeStyles({
    tooltipPopper: {
      opacity: 1
    },
    tooltipPopperArrow: {
      color: 'var(--bg-color-31)'
    },
    tooltipPopperArrowDark: {
      color: 'var(--bg-color-17)'
    },
    tooltipPopperArrowLight: {
      color: 'white'
    },
    tooltip: {
      opacity: 1,
      backgroundColor: 'var(--bg-color-31)',
      color: 'var(--text-color-7)'
    },
    tooltipDark: {
      backgroundColor: 'var(--bg-color-17)',
      color: 'var(--text-color-1)'
    },
    tooltipLight: {
      backgroundColor: 'white',
      color: 'black',
      boxShadow: '0px 0px 14px rgba(0, 0, 0, 0.2)',
      padding: 20,
      whiteSpace: 'break-spaces',
      fontWeight: 400,
      maxWidth: 500
    }
  })()

const Tooltip = ({
  open = undefined,
  children,
  value,
  placement = 'right',
  disableTooltip = false,
  arrow,
  variant = 'dark',
  delay,
  wrapperClassName = '',
  containerClassName = '',
  tooltipContentClassName = '',
  arrowClassName = '',
  popperProps = {},
  onClick = () => {}
}) => {
  const classes = useStyles()

  return (
    <MuiTooltip
      open={open}
      onClick={onClick}
      title={value}
      arrow={arrow}
      placement={placement}
      classes={{
        popper: classes.tooltipPopper,
        tooltip: [
          classes.tooltip,
          tooltipContentClassName,
          variant === 'dark' ? classes.tooltipDark : classes.tooltipLight
        ].join(' '),
        arrow: [
          classes.tooltipPopperArrow,
          arrowClassName,
          variant === 'dark' ? classes.tooltipPopperArrowDark : classes.tooltipPopperArrowLight
        ].join(' ')
      }}
      enterDelay={delay}
      disableHoverListener={disableTooltip}
      className={containerClassName}
      PopperProps={popperProps}
    >
      <div className={wrapperClassName}>{children}</div>
    </MuiTooltip>
  )
}

export default Tooltip
