import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import PracticePinIcon from 'components/NotificationCenter/Icons/PracticePinIcon'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import IconAndText from 'components/common/IconAndText'
import { ButtonBase } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { setSelectedPracticeId } from 'components/NotificationCenter/notificationCenterSlice'
import { trackEvent } from 'utils/analyticsUtils'
import Tooltip from 'components/common/Tooltip'

const useStyles = makeStyles(theme => ({
  practiceButton: {
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  practiceNameLabel: {
    fontWeight: 500,
    color: 'var(--text-color-62)'
  }
}))

const NotificationPracticeName = ({ practiceName, accountOwnerId, analyticsPayload = {} }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleClick = useCallback(
    e => {
      e.stopPropagation()
      trackEvent(`Notification Center - practice name clicked on notification`, {
        practiceName,
        accountOwnerId,
        ...analyticsPayload
      })
      dispatch(setSelectedPracticeId(accountOwnerId))
    },
    [dispatch, accountOwnerId, practiceName, analyticsPayload]
  )

  return (
    <ButtonBase disableRipple onClick={handleClick} className={classes.practiceButton}>
      <Tooltip
        value={t('dialogs.notificationCenterDialog.feed.actions.filterByPractice', { practiceName })}
        placement="bottom"
      >
        <IconAndText
          margin="5px"
          icon={<PracticePinIcon color={'var(--text-color-62)'} />}
          text={<DazzedParagraph12 className={classes.practiceNameLabel}>{practiceName}</DazzedParagraph12>}
        />
      </Tooltip>
    </ButtonBase>
  )
}

export default NotificationPracticeName
