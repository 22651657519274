import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { ArrowDropdownDown, ArrowDropdownUp } from 'components/common/icons'

const useStyles = makeStyles(theme => ({
  container: {
    width: '95%',
    margin: 10,
    padding: 5,
    cursor: 'pointer',
    border: '1px solid #CED0D4',
    borderRadius: 50
  },
  text: {
    marginLeft: 5,
    userSelect: 'none'
  },
  arrow: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  }
}))

const SearchDoctorTrigger = ({ isOpen, practiceName }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid container alignItems="center" justifyContent="space-between" spacing={1} className={classes.container}>
      <Grid item>
        <DazzedParagraph14 className={classes.text}>
          {practiceName ?? t('pages.patients.patientsList.searchByDoctor.placeholder')}
        </DazzedParagraph14>
      </Grid>
      <Grid item xs={1}>
        <div className={classes.arrow}>
          {isOpen ? <ArrowDropdownUp color="#757575" size={10} /> : <ArrowDropdownDown color="#757575" size={10} />}
        </div>
      </Grid>
    </Grid>
  )
}

export default SearchDoctorTrigger
