import React from 'react'
import { Chewies } from 'components/common/icons'
import { useTranslation } from 'react-i18next'
import GenericGuidelineAction from './GenericGuidelineAction'

const InstructForChewies = () => {
  const { t } = useTranslation()

  return (
    <GenericGuidelineAction
      icon={<Chewies />}
      text={t('dialogs.hiPatientGuidelinesViewer.actions.instructForChewies')}
    />
  )
}

export default InstructForChewies
