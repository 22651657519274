import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import BaseModal from 'components/common/modals/BaseModal'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import { trackEvent } from 'utils/analyticsUtils'
import { useDeleteNoteMutation } from 'store/modules/PatientNotes'
import useShowSnackbar from 'hooks/useShowSnackbar'
import { Warning } from 'components/common/icons'
import { useSelector } from 'react-redux'

const useStyles = makeStyles(theme => ({
  deleteModalIcon: {
    padding: '40px 40px 0px 40px'
  },
  deleteModalText: {
    marginTop: 35,
    marginBottom: 10
  }
}))

const DeleteNoteModal = ({ isOpen = false, setIsOpen, noteId }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { patient } = useSelector(state => state.patientsReducer)
  const [deleteNote, { isLoading, isError }] = useDeleteNoteMutation()

  useShowSnackbar({ isOpen: isError, message: t('pages.patients.patientSwitchableView.notes.deleteNotesError') })

  const handleDeleteNote = useCallback(async () => {
    trackEvent('Delete note modal - Delete note clicked', {
      noteId
    })
    await deleteNote({ id: noteId, patientGrinUserId: patient.user.id })
    setIsOpen(false)
  }, [deleteNote, noteId, patient.user.id, setIsOpen])

  const handleModalClosed = useCallback(() => {
    trackEvent('Delete note modal - Closed')
    setIsOpen(false)
  }, [setIsOpen])

  return (
    <BaseModal
      open={isOpen}
      onPrimaryBtnClick={handleDeleteNote}
      onSecondaryBtnClick={handleModalClosed}
      primaryLabel={t('general.yes')}
      secondaryLabel={t('general.cancel')}
      handleClose={handleModalClosed}
      isLoading={isLoading}
      buttonsHeight="36px"
      primaryButtonId="deleteNote-confirm"
      secondaryButtonId="deleteNote-cancel"
    >
      <Grid container direction="column" alignItems="center" className={classes.deleteModalIcon}>
        <Grid item>
          <Warning />
        </Grid>
        <Grid item>
          <DazzedParagraph16 strong className={classes.deleteModalText}>
            {t('dialogs.notes.deleteNote')}
          </DazzedParagraph16>
        </Grid>
      </Grid>
    </BaseModal>
  )
}

export default DeleteNoteModal
