import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Actions from 'actions'
import LinkButton from 'components/common/buttons/LinkButton'
import CustomDatePicker from 'components/common/CustomDatePicker'
import FormInput from 'components/common/FormInput'
import { LittleMan } from 'components/common/icons'
import WarningIcon from 'components/common/icons/NeedsAttentionLarge'
import BaseModal from 'components/common/modals/BaseModal'
import DazzedHeading18 from 'components/common/text/DazzedHeading18'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import ErrorMessage from 'components/common/text/ErrorMessage'
import TextArea from 'components/common/TextArea'
import { AsyncStatus } from 'consts'
import { isEmpty } from 'lodash'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useDebounce } from 'use-debounce'
import { trackEvent } from 'utils/analyticsUtils'
import useRecordsPatient from './useRecordsPatient'

const useStyles = makeStyles(theme => ({
  createRecordsPatientModal: {
    minWidth: 800
  },
  notesTextarea: {
    borderRadius: 8,
    padding: '16px!important',
    fontSize: 14,
    fontWeight: 500
  },
  existingPatientNoteContainer: {
    margin: '0px 12px 0px 12px',
    padding: 10,
    backgroundColor: 'var(--bg-color-62)',
    borderRadius: 8
  },
  dismissLabel: {
    fontWeight: '500 !important'
  },
  warningIcon: {
    marginLeft: 5
  }
}))

const EMPTY_VALUES = {
  firstName: '',
  lastName: '',
  birthDate: null,
  note: ''
}

const CreateRecordsPatientModal = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { searchPatients, searchResults } = useRecordsPatient()

  const [values, setValues] = useState(EMPTY_VALUES)
  const [didSave, setDidSave] = useState()
  const [errors, setErrors] = useState({})
  const [debouncedValues] = useDebounce(values, 100)
  const [shouldDisplayExistingPatientNote, setShouldDisplayExistingPatientNote] = useState(false)

  const { isOpen, saveStatus, initialValues } = useSelector(state => state.patientsReducer.createRecordsPatient)

  const debouncedPatientFullName = useMemo(() => {
    if (debouncedValues.firstName && debouncedValues.lastName) {
      return `${debouncedValues.firstName} ${debouncedValues.lastName}`
    }

    return ''
  }, [debouncedValues.firstName, debouncedValues.lastName])

  const validateForm = useCallback(() => {
    let errors = {}

    if (!values.firstName.trim()) {
      errors.firstName = t('errors.requiredField')
    }

    if (!values.lastName.trim()) {
      errors.lastName = t('errors.requiredField')
    }

    if (!values.birthDate) {
      errors.birthDate = t('errors.requiredField')
    }

    setErrors(errors)
    return isEmpty(errors)
  }, [values, t])

  const handleCancel = useCallback(() => {
    dispatch(Actions.setCreateRecordsPatientModalOpen({ isOpen: false }))
    trackEvent('Create records patient - cancel clicked')
  }, [dispatch])

  const handleSave = useCallback(() => {
    setDidSave(true)
    if (!validateForm()) {
      return
    }

    trackEvent('Create records patient - submit clicked', {
      firstName: values.firstName,
      lastName: values.lastName,
      birthDate: values.birthDate?.toISOString()
    })

    const birthDate = moment(values.birthDate).utcOffset(0, false).startOf('day').toISOString()
    dispatch(
      Actions.createRecordsPatient({
        firstName: values.firstName,
        lastName: values.lastName,
        birthDate,
        note: values.note
      })
    )
  }, [validateForm, values, dispatch])

  const handleDismissPatientExistingNoteClicked = useCallback(() => {
    trackEvent('Create records patient - Dismiss patient exists note clicked')
    setShouldDisplayExistingPatientNote(false)
  }, [])

  useEffect(() => {
    if (didSave) {
      validateForm()
    }
  }, [didSave, validateForm])

  useEffect(() => {
    if (isOpen) {
      trackEvent(`Create records patient - Loading initial values`, {
        initialValues: {
          ...initialValues,
          birthDate: initialValues.birthDate?.toISOString()
        }
      })

      setDidSave(false)
      setErrors({})
      setValues({
        ...EMPTY_VALUES,
        ...initialValues
      })
    }
  }, [isOpen, initialValues])

  useEffect(() => {
    if (isOpen && saveStatus === AsyncStatus.Completed) {
      dispatch(Actions.setCreateRecordsPatientModalOpen({ isOpen: false }))
    }
  }, [saveStatus, isOpen, dispatch])

  useEffect(() => {
    if (isOpen && debouncedPatientFullName) {
      searchPatients(debouncedPatientFullName)
    }
  }, [debouncedPatientFullName, isOpen, searchPatients])

  useEffect(() => {
    if (isOpen && searchResults.length > 0) {
      if (
        searchResults.some(
          patient => `${patient.firstName} ${patient.lastName}`.toLowerCase() === debouncedPatientFullName.toLowerCase()
        )
      ) {
        trackEvent('Create records patient - Patients exists note displayed')
        setShouldDisplayExistingPatientNote(true)
      } else {
        setShouldDisplayExistingPatientNote(false)
      }
    }
  }, [debouncedPatientFullName, isOpen, searchResults])

  return (
    <BaseModal
      title={t('dialogs.createRecordsPatientModal.title')}
      open={isOpen}
      handleClose={handleCancel}
      secondaryLabel={t('general.cancel')}
      onSecondaryBtnClick={handleCancel}
      isLoading={saveStatus === AsyncStatus.Loading}
      primaryLabel={t('dialogs.createRecordsPatientModal.saveButton')}
      onPrimaryBtnClick={handleSave}
      className={classes.createRecordsPatientModal}
      largerPrimaryButton
    >
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Grid container alignItems="center" spacing={1} style={{ lineHeight: 0.8 }}>
            <Grid item>
              <LittleMan />
            </Grid>
            <Grid item>
              <DazzedHeading18 className={classes.sectionHeader}>
                {t('dialogs.createRecordsPatientModal.patientInfo')}
              </DazzedHeading18>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <FormInput
                title={t('general.firstName')}
                value={values.firstName}
                setValue={firstName => setValues({ ...values, firstName })}
                style={{ bright: true, thick: true }}
                errorMessage={errors.firstName}
              />
            </Grid>
            <Grid item xs={6}>
              <FormInput
                title={t('general.lastName')}
                value={values.lastName}
                setValue={lastName => setValues({ ...values, lastName })}
                style={{ bright: true, thick: true }}
                errorMessage={errors.lastName}
              />
            </Grid>
          </Grid>
        </Grid>
        {shouldDisplayExistingPatientNote && (
          <Grid item className={classes.existingPatientNoteContainer}>
            <Grid container alignItems="center" spacing={2}>
              <WarningIcon className={classes.warningIcon} color="#CDAA60" />
              <Grid item>
                <DazzedParagraph14>
                  <Trans i18nKey={'dialogs.createRecordsPatientModal.existingPatientNote'} />
                </DazzedParagraph14>
              </Grid>
              <Grid item>
                <LinkButton
                  underline
                  fontSize={15}
                  labelClassName={classes.dismissLabel}
                  label={t('general.dismiss')}
                  onClick={handleDismissPatientExistingNoteClicked}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item xs={6}>
          <CustomDatePicker
            label={t('general.dateOfBirth')}
            inputVariant="outlined"
            value={values.birthDate}
            disableFuture
            disablePast={false}
            handleDateChange={birthDate => {
              trackEvent(`Create records patient - DOB set manually`, { birthDate: birthDate.toISOString() })
              setValues({ ...values, birthDate })
            }}
            error={errors.birthDate}
          />
        </Grid>
        <Grid item>
          <TextArea
            title={t('dialogs.createRecordsPatientModal.textInput')}
            value={values.note}
            setValue={note => setValues({ ...values, note })}
            className={classes.notesTextarea}
            style={{ bright: true, thick: true }}
            placeholder={t('dialogs.createRecordsPatientModal.notesPlaceholder')}
          />
        </Grid>
        {saveStatus === AsyncStatus.Failed && (
          <Grid item>
            <ErrorMessage
              text={t('dialogs.createRecordsPatientModal.failedToCreateErrorMessage')}
              active
              size="medium"
            />
          </Grid>
        )}
      </Grid>
    </BaseModal>
  )
}

export default CreateRecordsPatientModal
