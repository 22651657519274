import * as React from 'react'

const CallForAppointment = () => (
  <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 3C0 1.34315 1.34315 0 3 0H4C5.10457 0 6 0.895431 6 2C6 3.10457 5.10457 4 4 4H3V10H4C5.10457 10 6 10.8954 6 12C6 13.1046 5.10457 14 4 14H3C1.34315 14 0 12.6569 0 11V10V4V3Z"
      fill="#527195"
    />
    <path d="M8.82837 9.82836C10.3905 8.26627 10.3905 5.73361 8.82837 4.17151" stroke="#527195" />
    <path d="M10.5 11.5C12.9853 9.01472 12.9853 4.98528 10.5 2.5" stroke="#527195" />
  </svg>
)

export default CallForAppointment
