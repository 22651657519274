import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import Categories from './Categories'
import { useTranslation } from 'react-i18next'
import AdministrativeInfo from './PracticeGuidelines/AdministrativeInfo'

const useStyles = makeStyles(theme => ({
  root: {
    padding: 10,
    overflowY: 'auto'
  },
  lastUpdated: {
    paddingBottom: 15,
    color: 'var(--text-color-11)'
  }
}))

const TrackingGuidelines = ({
  lastUpdated,
  lastUpdatedBy,
  categories,
  categoriesData,
  administrativeInfo,
  generalNote
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid item>
        <DazzedParagraph12 className={classes.lastUpdated}>
          {t('dialogs.hiPatientGuidelinesViewer.lastUpdated', { lastUpdated, lastUpdatedBy })}
        </DazzedParagraph12>
      </Grid>
      <Grid item>
        {administrativeInfo && (
          <div style={{ marginBottom: 16 }}>
            <AdministrativeInfo administrativeInfo={administrativeInfo} />
          </div>
        )}
        <Categories categories={categories} categoriesData={categoriesData} generalNote={generalNote} />
      </Grid>
    </Grid>
  )
}

export default TrackingGuidelines
