import { API, graphqlOperation } from 'aws-amplify'
import { toFirstLetterUppercase } from './stringUtils'

export const fetchAll = async (query, variables, queryName, pageToken) => {
  let result = []

  const res = await API.graphql(
    graphqlOperation(query, {
      ...variables,
      nextToken: pageToken
    })
  )

  const { items, nextToken } = res.data[queryName] || {}

  if (nextToken) {
    return [...result, ...items, ...(await fetchAll(query, variables, queryName, nextToken))]
  } else {
    return [...result, ...items]
  }
}

export const getEntityIdAndVersion = async ({ entityType, id }) => {
  const queryName = `get${entityType}`
  const queryBody = `query ($id: ID!) {
    ${queryName}(id: $id) {
      _version
    }
  }
  `

  const res = await API.graphql(graphqlOperation(queryBody, { id }))
  const entity = res.data[queryName]

  if (!entity) {
    return null
  }

  return {
    id,
    _version: entity._version
  }
}

/**
 * Accepts a list of fields and transforms them into graphql query fields.
 * If the `fields` argument is a string, the function returns it as is.
 * Example:
 * ```
 * formatDynamicFields(['id', '_version', { patient: ['id', 'a_doctor'] }])
 * ```
 * returns
 * ```
 *  id
 *  _version
 *  patient {
 *    id
 *    a_doctor
 *  }
 * ```
 */
export const formatDynamicFields = (fields, trace = []) => {
  if (typeof fields === 'string') {
    return fields
  }

  if (!Array.isArray(fields)) {
    throw new Error(`expected an array or string but received '${typeof fields}'. trace: ${trace.join(' / ')}`)
  }

  let result = ''

  for (let i = 0; i < fields.length; i++) {
    const field = fields[i]
    const fieldType = typeof field

    if (fieldType === 'string') {
      result += `${field}\n`
    } else if (fieldType === 'object') {
      //eslint-disable-next-line no-loop-func
      Object.keys(field).forEach(nestedKey => {
        result += `${nestedKey} {\n${formatDynamicFields(field[nestedKey], [...trace, `${nestedKey}`])}}\n`
      })
    } else {
      throw new Error(
        `received an invalid field at index ${i} with type '${fieldType}'. trace: ${trace.join(' / ')} / ${field}`
      )
    }
  }

  return result
}

export const GraphQLMutationTypes = {
  Update: 'update',
  Delete: 'delete',
  Create: 'create'
}

/**
 * Gets an entity type, id and mutation input and performs a mutation on this entity with the latest _version fetched from the DB.
 * Incase this is a mutation of type "Create", no entity will be fetched
 *
 * This can be used as a shortcut instead of fetching the entity _version and then running the mutation.
 *
 * @param {Object} args
 * @param {string} args.entityType - The entity type, e.g. GrinUser.
 * @param {string} args.id - The id of the entity to update.
 * @param {object} args.input - The mutation input, excluding the `id` and `_version`.
 * @param {any} args.fields - The fields to return from the mutation. @see formatDynamicFields
 * @param {any} args.mutationType - The mutation type to perform, either delete, update or create @see GraphQLMutationTypes
 */
export const mutateEntity = async ({
  id,
  entityType,
  mutationType = GraphQLMutationTypes.Update,
  input = {},
  fields = ['id', '_version']
}) => {
  let entity = {}

  if (mutationType !== GraphQLMutationTypes.Create) {
    entity = await getEntityIdAndVersion({ entityType, id })

    if (!entity) {
      throw new Error(`could not find an entity with id '${id}' of type '${entityType}'`)
    }
  }

  if (!Object.values(GraphQLMutationTypes).includes(mutationType)) {
    throw new Error(`mutationType should be from GraphQLMutationTypes dict (Update/Delete/Create)`)
  }

  const upperCaseMutationType = toFirstLetterUppercase(mutationType)
  const mutationName = `${mutationType}${entityType}`
  const inputType = `${upperCaseMutationType}${entityType}Input!`
  const condition = `Model${entityType}ConditionInput`
  const mutationBody = `mutation ($input: ${inputType}, $condition: ${condition}) {
    ${mutationName}(input: $input, condition: $condition) {
      ${formatDynamicFields(fields)}
    }
  }`

  const res = await API.graphql(graphqlOperation(mutationBody, { input: { id, _version: entity._version, ...input } }))
  return res.data[mutationName]
}
