import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import { trackEvent } from 'utils/analyticsUtils'
import { useTranslation } from 'react-i18next'
import CategoryItems from './CategoryItems'
import CategoryNote from './CategoryNote'
import CollapsibleCategory from './CollapsibleCategory'

const useStyles = makeStyles(theme => ({
  disabledOverlay: {
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    position: 'absolute',
    background: '#fff',
    opacity: 0.7,
    zIndex: 9999
  }
}))

const GuidelineCategory = ({
  type,
  withSwitch = true,
  isEnabled = false,
  isCollapsed,
  onCollapseClick,
  note,
  items,
  onChange,
  icon
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const handleCollapseToggled = useCallback(() => {
    onCollapseClick({ categoryKey: type })
  }, [onCollapseClick, type])

  const handleCategoryToggle = useCallback(
    e => {
      e.stopPropagation()
      trackEvent(`Practice guidelines - Toggled category ${isEnabled ? 'off' : 'on'}`, {
        category: type
      })
      onCollapseClick({
        categoryKey: type,
        shouldCollapse: isEnabled
      })
      onChange({
        [type]: {
          isEnabled: !isEnabled,
          note,
          items
        }
      })
    },
    [isEnabled, items, note, onChange, type, onCollapseClick]
  )

  const handleCategoryItemsChanged = useCallback(
    updatedItems =>
      onChange({
        [type]: {
          isEnabled,
          note,
          items: updatedItems
        }
      }),
    [isEnabled, note, onChange, type]
  )

  const handleCategoryNoteChanged = useCallback(
    newNote =>
      onChange({
        [type]: {
          isEnabled,
          note: newNote,
          items
        }
      }),
    [isEnabled, items, onChange, type]
  )

  return (
    <CollapsibleCategory
      id={type}
      withSwitch
      icon={icon}
      title={t(`pages.accountSettings.practiceGuidelines.categories.${type}.title`)}
      tooltip={t(`pages.accountSettings.practiceGuidelines.categories.${type}.tooltip`)}
      isCollapsed={isCollapsed}
      onCollapseClick={handleCollapseToggled}
      isEnabled={isEnabled}
      onSwitchClick={handleCategoryToggle}
    >
      <div className={!isEnabled ? classes.disabledOverlay : ''}></div>
      <CategoryItems category={type} itemsData={items} onChange={handleCategoryItemsChanged} />
      <CategoryNote note={note} onChange={handleCategoryNoteChanged} />
    </CollapsibleCategory>
  )
}

export default GuidelineCategory
