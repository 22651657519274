import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import BaseModal from 'components/common/modals/BaseModal'
import TextAreaAutosize from 'components/common/TextareaAutosize'
import { trackEvent } from 'utils/analyticsUtils'
import { Grid } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import Actions from 'actions'
import { AsyncStatus } from 'consts'

const useStyles = makeStyles(theme => ({
  editMessageDialogRoot: {
    maxWidth: '550px',
    width: '550px'
  },
  messageInput: {
    width: '470px!important',
    height: '120px!important',
    padding: '16px!important',
    border: '1px solid rgba(207, 207, 207, 1)!important',
    borderRadius: 8,
    fontFamily: 'Dazzed',
    fontSize: 14
  }
}))

const EditMessageDialog = ({ isOpen, onClose, messageId, content }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { loadStatus } = useSelector(state => state.chatReducer.messageActions.editMessage)

  const [updatedContent, setUpdatedContent] = useState('')
  const [didSaveChanges, setDidSaveChanges] = useState(false)

  const hasChanges = useMemo(() => content !== updatedContent, [content, updatedContent])

  const handleCancel = useCallback(() => {
    trackEvent('Edit message - cancel clicked', { messageId })
    onClose()
  }, [messageId, onClose])

  const handleConfirmEdit = useCallback(() => {
    trackEvent('Edit message - confirm clicked', { messageId })
    setDidSaveChanges(true)
    dispatch(
      Actions.editChatMessage({
        messageId,
        content: updatedContent
      })
    )
  }, [messageId, updatedContent, dispatch])

  useEffect(() => {
    if (isOpen) {
      setUpdatedContent(content)
      setDidSaveChanges(false)
    }
  }, [isOpen, content])

  useEffect(() => {
    if (isOpen && didSaveChanges && loadStatus === AsyncStatus.Completed) {
      onClose()
    }
  }, [isOpen, didSaveChanges, loadStatus, onClose])

  return (
    <BaseModal
      open={isOpen}
      handleClose={handleCancel}
      withCloseIcon
      isLoading={loadStatus === AsyncStatus.Loading}
      primaryLabel={t('general.confirm')}
      onPrimaryBtnClick={handleConfirmEdit}
      isPrimaryDisabled={!hasChanges}
      secondaryLabel={t('general.cancel')}
      onSecondaryBtnClick={handleCancel}
      title={t('pages.patients.selectedPatient.chat.messageActions.editMessageDialog.title')}
      className={classes.editMessageDialogRoot}
    >
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <TextAreaAutosize
          value={updatedContent}
          setValue={setUpdatedContent}
          style={{ bright: true, thick: true }}
          rowsMax={5}
          className={classes.messageInput}
        />
      </Grid>
    </BaseModal>
  )
}

export default EditMessageDialog
