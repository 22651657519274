import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import FormInput from 'components/common/FormInput'
import ActionCheckbox from './ActionCheckbox'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'

const useStyles = makeStyles(theme => ({
  container: {
    marginLeft: 456,
    marginTop: -50
  },
  checkbox: {
    padding: '0px 9px 0px 0px'
  },
  inputWrapper: {
    margin: '0px !important',
    border: 'none !important'
  },
  input: {
    textAlign: 'center',
    width: '40px !important',
    border: 'none !important',
    borderBottom: '1px solid !important',
    fontSize: '14px !important',
    fontFamily: 'Dazzed'
  }
}))

const LimitSetForXDays = ({ isChecked, limitDays, onChange }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const handleToggleLimitSet = useCallback(
    isChecked => {
      onChange({
        isChecked,
        limitDays
      })
    },
    [limitDays, onChange]
  )

  const handleDaysChanged = useCallback(
    newDays => {
      const newDaysNumber = Number(newDays)

      if (!!newDays && (newDays > 90 || newDays < 1)) {
        return
      }

      onChange({
        isChecked,
        limitDays: !newDays ? '' : newDaysNumber
      })
    },
    [isChecked, onChange]
  )

  return (
    <Grid container direction="column" className={classes.container}>
      <Grid item>
        <Grid container alignItems="center">
          <Grid item>
            <ActionCheckbox
              checked={isChecked}
              onChange={handleToggleLimitSet}
              caption={t('pages.accountSettings.practiceGuidelines.actions.limitSetForXDays.limitSet')}
            />
          </Grid>
          <Grid item>
            <FormInput
              type="number"
              value={limitDays}
              setValue={handleDaysChanged}
              style={{ bright: true, thick: false }}
              keepErrorContainerWhenInactive={false}
              inputClassName={classes.input}
              inputWrapperClassName={classes.inputWrapper}
              inputSuffix={t('pages.accountSettings.practiceGuidelines.actions.limitSetForXDays.days')}
              maxLength={2}
              validationRules={{ required: true, pattern: '^[0-9]+$' }}
            />
          </Grid>
          <Grid item>
            <DazzedParagraph14>
              {t('pages.accountSettings.practiceGuidelines.actions.limitSetForXDays.days')}
            </DazzedParagraph14>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default LimitSetForXDays
