import { isEmpty } from 'lodash'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { generateHoursList } from 'utils/dateUtils'

export default ({ onSend, onClose, isModalOpen }) => {
  const { t } = useTranslation()

  const recipientTimezone = useSelector(state => state.patientsReducer.patient?.user?.timezone)

  const [selectedDate, setSelectedDate] = useState()
  const [selectedTime, setSelectedTime] = useState()
  const [errors, setErrors] = useState({})

  const maxDate = useMemo(() => moment().add(1, 'month'), [])
  const timeOptions = useMemo(() => generateHoursList(5, '00:00', '23:55', 'hh:mm a'), [])

  const selectedTimestamp = useMemo(
    () => moment(`${selectedDate?.format('YYYY-MM-DD')}T${selectedTime}`).toISOString(),
    [selectedDate, selectedTime]
  )

  const recipientScheduleTime = useMemo(
    () => (recipientTimezone ? moment(selectedTimestamp).tz(recipientTimezone) : selectedTimestamp),
    [selectedTimestamp, recipientTimezone]
  )
  const recipintTimezoneOffset = useMemo(() => {
    if (!recipientTimezone) {
      return ''
    }

    const offsetHours = moment().tz(recipientTimezone).utcOffset() / 60
    return offsetHours > 0 ? `+${offsetHours}` : offsetHours
  }, [recipientTimezone])

  const handleSend = useCallback(() => {
    let errors = {}

    if (moment(selectedTimestamp).isBefore(moment().add(2, 'minutes'))) {
      errors.time = t('pages.patients.selectedPatient.chat.scheduleMessagePopup.dateTooClose')
    }

    setErrors(errors)
    if (!isEmpty(errors)) {
      return
    }

    onSend({ scheduleTime: selectedTimestamp })
    onClose()
  }, [onSend, selectedTimestamp, t, onClose])

  useEffect(() => {
    if (isModalOpen) {
      setSelectedDate(moment().add(1, 'day').startOf('day'))
      setSelectedTime(moment().add(1, 'day').hour(9).startOf('hour').format('HH:mm'))
      setErrors({})
    }
  }, [isModalOpen])

  return {
    recipientTimezone,
    selectedDate,
    selectedTime,
    setSelectedDate,
    setSelectedTime,
    errors,
    maxDate,
    timeOptions,
    recipientScheduleTime,
    recipintTimezoneOffset,
    handleSend
  }
}
