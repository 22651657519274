import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { StlProviders } from '../txTrackerConsts'
import { TreatmentTypes } from 'consts/treatmentConsts'
import useScans from 'hooks/useScans'

export default ({ grinScanId }) => {
  const { scanIdToNumberDict } = useScans()

  const patientGrinScans = useSelector(state => state.patientsReducer.patient?.grinScans?.items || [])
  const { treatment } = useSelector(state => state.treatmentReducer)

  const calculatedTreatmentType = useMemo(() => {
    if (!treatment) {
      return null
    }

    const { provider } = JSON.parse(treatment.txTrackerStls || '{}')
    return provider === StlProviders.Align ? TreatmentTypes.Braces : treatment.type
  }, [treatment])

  const grinScan = useMemo(() => patientGrinScans.find(scan => scan.id === grinScanId), [grinScanId, patientGrinScans])
  const scanNumber = useMemo(() => scanIdToNumberDict?.[grinScan?.id] + 1, [grinScan, scanIdToNumberDict])
  const parsedTxTrackerData = useMemo(
    () => JSON.parse(grinScan?.treatmentTrackerData || '{}'),
    [grinScan?.treatmentTrackerData]
  )
  const parsedStls = useMemo(() => JSON.parse(treatment?.txTrackerStls || '{}'), [treatment?.txTrackerStls])
  const archModels = useMemo(() => {
    if (!!parsedStls?.stls) {
      return {
        baseArchModels: {
          upper: parsedTxTrackerData?.archModels?.upperS3Keys?.baseStl,
          lower: parsedTxTrackerData?.archModels?.lowerS3Keys?.baseStl
        },
        endArchModels: {
          upper: parsedTxTrackerData?.archModels?.upperS3Keys?.finalStl,
          lower: parsedTxTrackerData?.archModels?.lowerS3Keys?.finalStl
        },
        currentArchModels: {
          upper: parsedTxTrackerData?.archModels?.upperS3Keys?.currentPlannedStl,
          lower: parsedTxTrackerData?.archModels?.lowerS3Keys?.currentPlannedStl
        }
      }
    }

    return null
  }, [parsedStls, parsedTxTrackerData])
  const alignerNumber = useMemo(() => (grinScan?.withAligner ? grinScan.applianceIndex : null), [grinScan])
  const initAlignerNumber = useMemo(() => Object.keys(parsedStls?.stls?.lower || {})?.[0], [parsedStls])
  const endAlignerNumber = useMemo(() => Object.keys(parsedStls?.stls?.lower || {})?.pop(), [parsedStls])

  return {
    archModels,
    calculatedTreatmentType,
    scanNumber,
    initAlignerNumber,
    endAlignerNumber,
    alignerNumber,
    parsedTxTrackerData
  }
}
