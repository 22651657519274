import React from 'react'
import { makeStyles, Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Video from './icons/Video'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import MessageTypeWrapper from '../../MessageTypeWrapper'
import MessageFooter from '../../MessageFooter'

const useStyles = makeStyles({
  messageContent: {
    padding: 8,
    borderRadius: 8,
    backgroundColor: 'var(--bg-color-12)'
  },
  timestamp: {
    backgroundColor: '#223455',
    padding: 8,
    borderRadius: 8
  },
  footerTimestamp: {
    marginTop: 8
  }
})

const InstantLiveCallMessage = ({
  id,
  content,
  alignLeft,
  link,
  name,
  userType,
  isFirstMessage,
  isLastMessage,
  timestamp,
  lastEditedAt,
  withMessageActions,
  readStatus
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <MessageTypeWrapper
      id={id}
      isFirstMessage={isFirstMessage}
      isLastMessage={isLastMessage}
      alignLeft={alignLeft}
      name={name}
      userType={userType}
      isBroadcast={false}
      lastEditedAt={lastEditedAt}
      withMessageActions={withMessageActions}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        className={classes.messageContent}
      >
        <Grid item>
          <Video />
        </Grid>
        <Grid item>
          <DazzedParagraph14 color="white">
            {t('pages.patients.selectedPatient.chat.instantLiveCallMessage.title')}
          </DazzedParagraph14>
        </Grid>
      </Grid>
      <div className={classes.footerTimestamp}>
        <MessageFooter readStatus={readStatus} sendDate={timestamp} />
      </div>
    </MessageTypeWrapper>
  )
}

export default InstantLiveCallMessage
