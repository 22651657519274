import { GrinNotificationTypes } from '@grin-rnd/grin-api-sdk/dist/Enums/Notifications'
import NewMessageBubble from './NewMessageBubble'
import NewScanBubble from './NewScanBubble'
import PatientAssignedBubble from './PatientAssignedBubble'
import NeedsAttentionBubble from './NeedsAttentionBubble'

export default {
  [GrinNotificationTypes.NewMessage]: NewMessageBubble,
  [GrinNotificationTypes.NewScan]: NewScanBubble,
  [GrinNotificationTypes.PatientAssigned]: PatientAssignedBubble,
  [GrinNotificationTypes.NeedsAttention]: NeedsAttentionBubble
}
