import * as React from 'react'

export default () => (
  <svg width="21" height="15" viewBox="0 0 21 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="3.83118" y="0.86377" width="5.49075" height="5.49075" rx="2.74538" stroke="#212121" />
    <path
      d="M1 12.3599C1 9.65914 3.18942 7.46973 5.8902 7.46973H7.26288C9.96367 7.46973 12.1531 9.65914 12.1531 12.3599V12.7031C12.1531 13.3191 11.6537 13.8184 11.0378 13.8184H2.11531C1.49934 13.8184 1 13.3191 1 12.7031V12.3599Z"
      stroke="#212121"
    />
    <path d="M14 4L20 10" stroke="#212121" strokeWidth="2" />
    <path d="M20 4L14 10" stroke="#212121" strokeWidth="2" />
  </svg>
)
