import React from 'react'
import { useTranslation } from 'react-i18next'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'

const AlignerNumber = ({ alignerNumber, totalAlignerNumber, className = '' }) => {
  const { t } = useTranslation()

  return (
    <DazzedParagraph14 strong className={className}>
      {t('pages.patients.selectedPatient.timelineV2.scanHeader.alignerNumber', { alignerNumber })}
      {totalAlignerNumber ? (
        <span style={{ color: 'var(--text-color-62)' }}>
          &nbsp;
          {t('pages.patients.selectedPatient.timelineV2.totalAlignerNumber', {
            totalAlignerNumber
          })}
        </span>
      ) : (
        <></>
      )}
    </DazzedParagraph14>
  )
}

export default AlignerNumber
