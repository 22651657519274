import React, { useCallback, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useDispatch, useSelector } from 'react-redux'
import BaseModal from 'components/common/modals/BaseModal'
import Actions from 'actions'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { trackEvent } from 'utils/analyticsUtils'
import { DisableMFAWarning } from 'components/common/icons'

const useStyles = makeStyles(theme => ({
  root: {
    padding: 32,
    width: 640,
    height: 450
  },
  dialogContent: {
    padding: '0 24px 24px 24px!important'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  }
}))

const MFADisableMethodModal = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { isOpen, methodType } = useSelector(state => state.profileReducer.mfaSettings.disableMethodModal)

  const handleClose = useCallback(() => {
    dispatch(Actions.setDisableMfaMethodModalOpen({ isOpen: false }))
  }, [dispatch])

  const handleDisableMethod = useCallback(async () => {
    trackEvent('MFA Settings - Disable mfa method clicked', {
      methodType
    })
    dispatch(Actions.disableMFAMethod())
    handleClose()
  }, [dispatch, handleClose, methodType])

  useEffect(() => {
    if (isOpen) {
      trackEvent('Mfa settings - Disable method modal opened', {
        methodType
      })
    }
  }, [isOpen, methodType])

  return (
    isOpen && (
      <BaseModal
        open={isOpen}
        className={classes.root}
        handleClose={handleClose}
        contentClassName={classes.dialogContent}
        onPrimaryBtnClick={handleClose}
        primaryLabel={t('general.cancel')}
        largerPrimaryButton
        onSecondaryBtnClick={handleDisableMethod}
        secondaryLabel={t('general.disable')}
      >
        <Grid container direction="column" alignItems="center" justifyContent="center" style={{ height: '100%' }}>
          <Grid item>
            <DisableMFAWarning />
          </Grid>
          <Grid item>
            <DazzedParagraph16 bold>
              {t('pages.accountSettings.mfaSettings.disableMethodModal.title')}
            </DazzedParagraph16>
          </Grid>
          <Grid item style={{ marginTop: 15 }}>
            <DazzedParagraph14 strong textAlign="center">
              {t('pages.accountSettings.mfaSettings.disableMethodModal.description')}
            </DazzedParagraph14>
          </Grid>
        </Grid>
      </BaseModal>
    )
  )
}

export default MFADisableMethodModal
