import React, { useCallback, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import ScanTooltipArrow from 'components/common/icons/timelineV2/mini-timeline/ScanTooltipArrow'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import GrinPopper from 'components/common/menu/GrinPopper'
import DazzedParagraph11 from 'components/common/text/DazzedParagraph11'
import moment from 'moment'
import ScanThumbnail from 'components/Patients/Timeline/V2/ScanThumbnail'
import { Grid } from '@material-ui/core'
import { getObjectUrl } from 'utils/mediaUtils'
import TrackStatusTag from 'components/Patients/Timeline/V2/Tags/TrackStatusTag'
import UrgentScanTag from '../Tags/UrgentScanTag'
import AlignerNumber from '../AlignerNumber'
import { isEmpty } from 'lodash'
import OralStatus from './OralStatus'
import useFeatureFlags from 'hooks/useFeatureFlags'

const DENSE_MODE_HEIGHT_THRESHOLD = 10

const useStyles = makeStyles(theme => ({
  bodyContainer: {
    padding: '16px 20px',
    boxShadow: '0px 4px 14px 0px rgba(0, 0, 0, 0.1)',
    border: 'none'
  },
  dateLabel: {
    color: 'var(--text-color-62)',
    marginTop: -8
  },
  thumbnailContainer: {
    width: ({ scansCount }) => (scansCount <= 1 ? '155px' : '115px')
  },
  divider: {
    background: 'rgba(232, 232, 232, 1)',
    width: '100%',
    height: 1,
    margin: '6px 0'
  }
}))

const GrinScanTooltip = ({
  anchorEl,
  isOpen,
  date,
  scanNumber,
  alignerNumber,
  totalAlignerNumber,
  scans = [],
  scanType,
  trackStatus,
  urgency = {},
  oralScores = {},
  onTrackReasonsClick = () => {}
}) => {
  const classes = useStyles({ scansCount: scans.length })
  const { t } = useTranslation()
  const { oralHygieneScore: oralHygieneScoreFF } = useFeatureFlags()

  const [denseMode, setDenseMode] = useState(false)

  const offTrackReasons = useMemo(() => scans.map(scan => scan.offTrackReasons || []).flat(), [scans])
  const withOralScores = useMemo(() => oralHygieneScoreFF && !isEmpty(oralScores), [oralHygieneScoreFF, oralScores])

  const determineDenseMode = useCallback(
    popperBodyRef => {
      // Usually the last two scan items are too close to the bottom of the screen. As a result the scan tooltip overflows to the bottom of the screen.
      // This is what we do in order to solve this:
      // 1) Calculate the distance from the anchor element to the bottom of the screen.
      // 2) Calculate the popper element height.
      // 3) Check of the distance is less than half of the popper height (plus some threshold). If yes, fix the popper placement.

      const popperHeight = popperBodyRef.getBoundingClientRect().height
      const elementBottomCoord = anchorEl.getBoundingClientRect().bottom
      const distanceToBottom = window.innerHeight - elementBottomCoord

      if (distanceToBottom < popperHeight / 2 + DENSE_MODE_HEIGHT_THRESHOLD) {
        setDenseMode(true)
      } else {
        setDenseMode(false)
      }
    },
    [anchorEl]
  )

  const handlePopperEnter = useCallback(
    popperBodyRef => {
      if (anchorEl && popperBodyRef) {
        determineDenseMode(popperBodyRef)
      }
    },
    [anchorEl, determineDenseMode]
  )

  return (
    <GrinPopper
      customAnchorEl={anchorEl}
      open={isOpen}
      placement={denseMode ? 'right-end' : 'right'}
      arrowComponent={denseMode ? null : <ScanTooltipArrow />}
      arrowPlacement="left"
      className={classes.bodyContainer}
      onEnter={handlePopperEnter}
    >
      <Grid container direction="column" spacing={1} justifyContent="center">
        <Grid item>
          <Grid container>
            <Grid item>
              <DazzedParagraph14 bold>
                {t(`pages.patients.selectedPatient.timelineV2.miniTimeline.scanTooltip.scanNumber`, { scanNumber })}
              </DazzedParagraph14>
            </Grid>
            {!!alignerNumber && (
              <Grid item style={{ marginLeft: 3 }}>
                <AlignerNumber alignerNumber={alignerNumber} totalAlignerNumber={totalAlignerNumber} />
              </Grid>
            )}
            <Grid item style={{ marginLeft: 9 }}>
              <UrgentScanTag urgencyStatus={urgency.urgencyStatus} reason={urgency.reason} denseMode />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <DazzedParagraph11 thickness="thin" className={classes.dateLabel}>
            {moment(date).format('LLL')} ({moment(date).fromNow()})
          </DazzedParagraph11>
        </Grid>
        <Grid item>
          <Grid container direction="row" spacing={1} justifyContent="center">
            {scans.map(scan => (
              <Grid item key={`scan-tooltip-${scan.id}`}>
                <ScanThumbnail
                  mediaType="image"
                  src={getObjectUrl(scan.thumbnail)}
                  status={scan.scanSummaryStatus}
                  withAligner={scan.withAligner}
                  renderAlignerIconOverlay={scanType === 'doubleScan' || scan.withAligner}
                  containerClassName={classes.thumbnailContainer}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>

        <TrackStatusTag
          trackStatus={trackStatus}
          reasons={offTrackReasons}
          reasonsOnClick={onTrackReasonsClick}
          withLabel
        />

        {withOralScores && (
          <>
            <Grid item>
              <div className={classes.divider} />
            </Grid>
            <Grid item>
              <OralStatus oralScores={oralScores} />
            </Grid>
          </>
        )}
      </Grid>
    </GrinPopper>
  )
}

export default React.memo(GrinScanTooltip)
