import { tap, withLatestFrom, pluck, ignoreElements, filter, map } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { logReduxActionError, logInfo } from '../utils/logUtils'
import Actions from '../actions'
import * as analyticsService from '../utils/analyticsUtils'
import { extractUserDataFromState, mapErrorPayload } from '../utils/mappers/logMappers'

export const logRisasActionEpic = (action$, state$) =>
  action$.pipe(
    withLatestFrom(state$),
    map(([action, state]) => ({
      action: action.type,
      username: state.profileReducer.doctor?.username,
      email: state.profileReducer.doctor?.email
    })),
    filter(({ username, email }) => !!username && !!email && email.includes('risas')),
    tap(({ action, username, email }) => logInfo('risas action: ' + action, { action, username, email })),
    ignoreElements()
  )

export const updateUserEpic = (action, state) =>
  action.pipe(
    ofType(Actions.DOCTOR_DETAILS_RECEIVED),
    pluck('payload'),
    withLatestFrom(state),
    tap(([payload, state]) => {
      const doctor = payload.doctor
      const practice = payload.practice
      const planOverrides = JSON.parse(payload.accountOwner?.user?.planOverrides || '{}')
      const user = {
        email: doctor.email,
        username: doctor.username,
        name: doctor.name,
        role: doctor.roleDescription,
        practiceName: practice.details.practiceName,
        grinPlanKey: practice.billing?.grinPlanKey,
        hiGroup: planOverrides.general?.hiGroup
      }
      analyticsService.setUser(user)
    }),
    ignoreElements()
  )

export const removeUserEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.SIGNOUT_REQUESTED),
    withLatestFrom(state$),
    tap(([, state]) => {
      analyticsService.trackEvent(
        'Sign out',
        {
          practiceName: state.practiceReducer.details?.practiceName,
          doctorName: state.profileReducer.doctor?.name,
          practiceRole: state.profileReducer.doctor?.roleDescription,
          email: state.profileReducer.doctor?.email
        },
        { send_immediately: true },
        () => analyticsService.setUser()
      )
    }),
    ignoreElements()
  )

export const logOperationFailedEpic = (action, state) =>
  action.pipe(
    filter(action => action.type.includes('FAILED') || action.type.includes('REJECTED')),
    withLatestFrom(state),
    tap(([action, state]) => {
      logReduxActionError(
        { type: action.type },
        {
          ...extractUserDataFromState(state),
          error: mapErrorPayload(action.payload)
        }
      )
    }),
    ignoreElements()
  )
