import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import GrinChip from 'components/common/GrinChip'
import OffTrackGrinScanItem from 'components/common/icons/timelineV2/mini-timeline/OffTrackGrinScanItem'
import OnTrackGrinScanItem from 'components/common/icons/timelineV2/mini-timeline/OnTrackGrinScanItem'
import DazzedParagraph11 from 'components/common/text/DazzedParagraph11'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import Tooltip from 'components/common/Tooltip'
import HiConditionList from 'components/Patients/Chat/MessageTypes/HI/HiConditionsList'
import { ScanTrackingStatus, TreatmentTrackOptions } from 'consts/giConsts'
import useRolePermissions from 'hooks/useRolePermissions'
import { keyBy } from 'lodash'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  root: {
    width: 'fit-content',
    borderRadius: 6,
    padding: 5,
    lineHeight: 0.8,
    backgroundColor: ({ trackStatus }) =>
      trackStatus === TreatmentTrackOptions.OnTrack ? 'rgba(222, 246, 233, 1)' : 'rgba(248, 234, 231, 1)'
  },
  reasons: {
    marginLeft: 5,
    textDecoration: 'underline',
    '&.clickable': {
      cursor: 'pointer'
    }
  },
  tooltipWrapper: {
    display: 'inline-block'
  }
}))

const TrackStatusTag = ({ trackStatus, reasons = [], reasonsOnClick, withLabel }) => {
  const classes = useStyles({ trackStatus })
  const { t } = useTranslation()
  const { isHiUser } = useRolePermissions()

  const trackingConditions = useMemo(() => keyBy(reasons), [reasons])
  const isTrackingDismissed = useMemo(() => trackStatus === ScanTrackingStatus.NotRequired, [trackStatus])
  const isTrackingPendingOrNotRequired = useMemo(() => !trackStatus, [trackStatus])

  return isTrackingPendingOrNotRequired || (isTrackingDismissed && !isHiUser) ? (
    <></>
  ) : (
    <Grid item>
      {withLabel && (
        <DazzedParagraph11 thickness="bold" color={'var(--text-color-62)'}>
          {t(`pages.patients.selectedPatient.timelineV2.miniTimeline.scanTooltip.trackingStatus`)}
        </DazzedParagraph11>
      )}

      {isTrackingDismissed && isHiUser ? (
        <GrinChip
          text={t(`pages.patients.selectedPatient.timelineV2.trackStatus.dismissed`)}
          backgroundColor="#E4E7EC"
        />
      ) : (
        <Grid container className={classes.root} alignItems="center">
          <Grid item style={{ marginRight: 3 }}>
            {trackStatus === TreatmentTrackOptions.OnTrack ? <OnTrackGrinScanItem /> : <OffTrackGrinScanItem />}
          </Grid>
          <Grid item>
            <DazzedParagraph12 thickness="strong">
              {t(`pages.patients.selectedPatient.timelineV2.trackStatus.${trackStatus}`)}
              {reasons.length > 0 && (
                <Tooltip
                  value={<HiConditionList conditions={trackingConditions} includeOtherTag />}
                  wrapperClassName={classes.tooltipWrapper}
                  placement="right"
                  variant="light"
                  arrow
                >
                  <span
                    className={[classes.reasons, reasonsOnClick ? 'clickable' : ''].join(' ')}
                    onClick={reasonsOnClick || (() => {})}
                  >
                    {t(
                      `pages.patients.selectedPatient.timelineV2.trackStatus.${
                        reasons.length > 1 ? 'reasons' : 'reason'
                      }`,
                      {
                        reasons: reasons.length
                      }
                    )}
                  </span>
                </Tooltip>
              )}
            </DazzedParagraph12>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default TrackStatusTag
