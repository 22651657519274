import * as React from 'react'

export default () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 7.25H5.25V8V14C5.25 15.5188 6.48122 16.75 8 16.75H12C13.5188 16.75 14.75 15.5188 14.75 14V8V7.25H14H6Z"
      stroke="#3C52EF"
      strokeWidth="1.5"
    />
    <path d="M4 5H16" stroke="#3C52EF" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M8 3H12L13 5H7L8 3Z" fill="#3C52EF" />
  </svg>
)
