import * as React from 'react'

export default () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="1" width="16" height="16" rx="3" fill="#CAD9FF" stroke="#829DE3" strokeWidth="2" />
    <path d="M3 5.97217L7 9.13883L3 12.3055V5.97217Z" fill="#869ED2" />
    <path d="M7 5.97217L11 9.13883L7 12.3055V5.97217Z" fill="#869ED2" />
    <path d="M11 5.97217L15 9.13883L11 12.3055V5.97217Z" fill="#869ED2" />
  </svg>
)
