import React from 'react'
import Message from '../Message'

const MessageAuditItem = ({ message, auditItem }) => {
  return (
    <Message
      {...message}
      content={auditItem.newContent}
      timestamp={auditItem.timestamp}
      isDeleted={auditItem.action === 'delete'}
      lastEditedAt={null}
      withMessageActions={false}
    />
  )
}

export default MessageAuditItem
