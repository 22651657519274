import { NotificationCenterTabs, NotificationCenterTabsConfiguration } from 'consts/notificationCenterConsts'
import { useDispatch, useSelector } from 'react-redux'
import { switchTab } from '../notificationCenterSlice'
import { useCallback, useMemo } from 'react'
import { trackEvent } from 'utils/analyticsUtils'
import { NotificationMediums } from '@grin-rnd/grin-api-sdk/dist/Enums/Notifications'
import { hasIntersection } from 'utils/arrayUtils'

export default () => {
  const dispatch = useDispatch()
  const { selectedTab } = useSelector(state => state.notificationCenterReducer)
  const { doctor } = useSelector(state => state.profileReducer)

  const messagingPreferenceEntities = useMemo(
    () =>
      doctor?.user?.messagingPreferences?.entities
        .filter(entity => entity.medium.includes(NotificationMediums.GrinNotification))
        .map(entity => entity.type) || [],
    [doctor]
  )

  const checkTabVisibilityByEntities = useCallback(
    tabId =>
      hasIntersection(
        messagingPreferenceEntities,
        NotificationCenterTabsConfiguration[tabId].MessagingPreferenceEntities
      ),
    [messagingPreferenceEntities]
  )

  const handleSwitchTab = useCallback(
    ({ tab }) => {
      trackEvent(`Notification Center - tab switched`, { newTab: tab, previousTab: selectedTab })
      dispatch(switchTab({ tab }))
    },
    [dispatch, selectedTab]
  )

  const tabs = useMemo(
    () =>
      [
        {
          id: NotificationCenterTabs.All,
          condition: checkTabVisibilityByEntities(NotificationCenterTabs.All)
        },
        {
          id: NotificationCenterTabs.NeedsAttention,
          condition: checkTabVisibilityByEntities(NotificationCenterTabs.NeedsAttention)
        },
        {
          id: NotificationCenterTabs.Scans,
          condition: checkTabVisibilityByEntities(NotificationCenterTabs.Scans)
        },
        {
          id: NotificationCenterTabs.Messages,
          condition: checkTabVisibilityByEntities(NotificationCenterTabs.Messages)
        },
        {
          id: NotificationCenterTabs.Assigned,
          condition: checkTabVisibilityByEntities(NotificationCenterTabs.Assigned)
        },
        {
          id: NotificationCenterTabs.NewPatients,
          condition: false && checkTabVisibilityByEntities(NotificationCenterTabs.NewPatients) // Notifications not implemented yet
        }
      ].filter(t => t.condition),
    [checkTabVisibilityByEntities]
  )

  return {
    tabs,
    selectedTab,
    handleSwitchTab
  }
}
