import { TreatmentTypes } from 'consts/treatmentConsts'
import { StlProviders } from './txTrackerConsts'

export default () => {
  const calculateTreatmentType = ({ treatmentType, provider }) => {
    switch (provider) {
      case StlProviders['3M']:
      case StlProviders['uLab']:
        return TreatmentTypes.Aligners
      case StlProviders.KLOwen:
      case StlProviders.Align:
        return TreatmentTypes.Braces
      default:
        return treatmentType
    }
  }

  return {
    calculateTreatmentType
  }
}
