import { AccessibleForward, Replay } from '@material-ui/icons'
import Actions from 'actions'
import { Copy, ShareScan, TreatmentTrackerIcon } from 'components/common/icons'
import Autochart from 'components/common/icons/scan-item-actions/Autochart'
import SendScanReview from 'components/common/icons/scan-item-actions/SendScanReview'
import ShareProgress from 'components/common/icons/scan-item-actions/ShareProgress'
import CompareScans from 'components/common/icons/timelineV2/scan-feed/CompareScans'
import TagScan from 'components/common/icons/timelineV2/scan-feed/TagScan'
import useFeatureFlags from 'hooks/useFeatureFlags'
import usePlanLimits from 'hooks/usePlanLimits'
import useRolePermissions from 'hooks/useRolePermissions'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { trackEvent } from 'utils/analyticsUtils'
import { isUserOfAnyPracticeRole } from 'utils/authUtils'
import { getEnvironment } from 'utils/awsUtils'
import { isMobile } from 'utils/mobileUtils'
import { isScanEligableForBeforeAfter, scanHasScanSummaryCenterPose } from 'utils/scanUtils'
import TxTrackerButtonContent from './TxTrackerButtonContent'

/**
 * A hook that returns the list of actions available for a grin scan timeline item.
 * **Note:** for legacy reasons, it is required that the patient will be fully loaded into the state.
 *
 * @prop {Array<string>} timelineItemScanIds - The list of scan ids associated with the timeline item.
 * @prop {string} selectedScanId - The currently selected scan id: some actions are scan contextual (for example scan review). If not provided, such actions will be excluded.
 * @prop {Object} [analyticsPayload] - (Optional) an object that will be added to all analytics.
 * @returns {Array<{ icon, label, elementId, onClick, tooltip }>} A list of available action objects.
 */
export default ({
  scanNumber = '',
  timelineItemScanIds = [],
  selectedScanId = '',
  withAligner,
  analyticsPayload = {}
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { permissions } = useRolePermissions()
  const {
    autochart: autochartFF,
    treatmentTracker: treatmentTrackerFF,
    tagScanForNonHI: tagScanForNonHIFF
  } = useFeatureFlags()
  const { isChurnedPlan, isHI: isHiPractice } = usePlanLimits()

  const { patient } = useSelector(state => state.patientsReducer)
  const { grinScans: patientScans } = useSelector(state => state.treatmentReducer)

  const isPatientActive = useMemo(
    () => patient?.user?.isActive && !patient?.treatments.items[0]?.currentStatus?.statusKey?.includes('transferred'),
    [patient]
  )
  const grinScans = useMemo(
    () => patientScans?.filter(scan => timelineItemScanIds.includes(scan.id)),
    [patientScans, timelineItemScanIds]
  )
  const selectedScan = useMemo(
    () => patientScans.find(scan => scan.id === selectedScanId),
    [selectedScanId, patientScans]
  )
  const beforeAfterScan = useMemo(() => grinScans.find(isScanEligableForBeforeAfter), [grinScans])
  const autochartScan = useMemo(() => grinScans.find(scan => scan.autochartId), [grinScans])
  const txTrackerScan = useMemo(() => grinScans.find(scan => !!scan.treatmentTrackerStatus), [grinScans])

  const actions = useMemo(
    () =>
      [
        {
          icon: <SendScanReview />,
          label: t('pages.patients.selectedPatient.timeline.actions.sendReview'),
          onClick: () => dispatch(Actions.openScanReviewEditor(selectedScan)),
          visible: selectedScan && !isChurnedPlan && !isMobile() && permissions.recordScanReviews && isPatientActive,
          elementId: `scan-review`
        },
        {
          icon: <CompareScans />,
          label: t('pages.patients.selectedPatient.timelineV2.scanFeed.scanActions.compareScans'),
          visible: true,
          elementId: 'compare-scans',
          onClick: () =>
            dispatch(
              Actions.setCompareScansDialogOpen({
                isOpen: true,
                left:
                  scanNumber > 1
                    ? {
                        scanIndex: scanNumber - 2
                      }
                    : null,
                right: {
                  scanIndex: scanNumber - 1,
                  withAligners: withAligner
                }
              })
            )
        },
        {
          icon: <ShareProgress />,
          label: t('pages.patients.selectedPatient.timeline.actions.shareProgress'),
          onClick: () =>
            dispatch(
              Actions.toggleBeforeAfterDialog({
                open: true,
                scanId: beforeAfterScan.id,
                patientId: patient.id,
                analytics: {
                  ...analyticsPayload,
                  source: 'timeline',
                  scanIndex: scanNumber
                }
              })
            ),
          visible:
            isPatientActive && !isChurnedPlan && scanNumber > 1 && beforeAfterScan && permissions.shareScanProgress,
          elementId: `share-progress`
        },
        {
          icon: <TagScan />,
          label: t('pages.patients.selectedPatient.timeline.actions.tagScan'),
          onClick: () =>
            dispatch(
              Actions.setTagScanModalOpen({
                isOpen: true
              })
            ),
          visible: permissions.treatmentTracking || (!isHiPractice && isUserOfAnyPracticeRole() && tagScanForNonHIFF),
          elementId: `tag-scan`
        },
        {
          icon: <Autochart />,
          label: t('pages.patients.selectedPatient.timeline.actions.autochart'),
          onClick: () =>
            dispatch(
              Actions.autochartToggleDialog({
                isOpen: true,
                grinScanId: selectedScan.id,
                patientId: selectedScan.patientId
              })
            ),
          visible: !isMobile() && autochartFF && autochartScan,
          elementId: 'autochart'
        },
        {
          icon: <ShareScan />,
          label: t('pages.patients.selectedPatient.timeline.actions.shareScan'),
          onClick: () => {
            trackEvent('Share scan modal - opened', {
              ...analyticsPayload,
              analyticSource: 'Timeline'
            })
            dispatch(Actions.toggleShareNewScanModal({ isOpen: true, grinScanId: selectedScan.id }))
          },
          visible:
            selectedScan && !isChurnedPlan && permissions.shareScan && scanHasScanSummaryCenterPose(selectedScan),
          elementId: 'shareScan'
        },
        {
          icon: <TreatmentTrackerIcon />,
          label: <TxTrackerButtonContent treatmentTrackerStatus={txTrackerScan?.treatmentTrackerStatus} />,
          onClick: () => {
            trackEvent('Treatment tracker popup - opened', {
              ...analyticsPayload,
              grinScanId: selectedScan.id,
              patientId: patient.id
            })
            dispatch(Actions.setTreatmentTrackerModalOpen({ isOpen: true, grinScanId: txTrackerScan.id }))
          },
          visible: !isMobile() && !!treatmentTrackerFF && !!txTrackerScan,
          elementId: 'treatmentTracker',
          tooltip: t(
            `pages.patients.selectedPatient.timeline.actions.txTrackerTooltip.${txTrackerScan?.treatmentTrackerStatus}`
          ),
          analyticsName: 'Treatment Tracker'
        },
        {
          icon: <Replay />,
          label: t('pages.patients.selectedPatient.timeline.actions.regenerateScan'),
          onClick: () => {
            dispatch(Actions.qaToggleRegenerateScanModal({ isOpen: true, grinScanId: selectedScan.id }))
          },
          visible: !isMobile() && permissions.regenerateScan && selectedScan,
          elementId: 'regenerateScan'
        },
        {
          label: t('pages.patients.selectedPatient.timeline.actions.copyScanId'),
          visible: selectedScan && (permissions.copyScanId || getEnvironment() !== 'production'),
          icon: <Copy />,
          elementId: 'copy-scan-id',
          onClick: () => navigator.clipboard.writeText(selectedScan.id)
        },
        {
          label: 'Tx Tracker Admin',
          visible: permissions.txTrackerAdminModal,
          icon: <AccessibleForward />,
          elementId: 'tx-tracker-admin',
          onClick: () => {
            dispatch(Actions.toggleTxTrackerModalOpen(true))
          }
        }
      ].filter(action => action.visible),
    [
      t,
      selectedScan,
      isChurnedPlan,
      permissions,
      isPatientActive,
      scanNumber,
      beforeAfterScan,
      isHiPractice,
      autochartFF,
      autochartScan,
      txTrackerScan,
      treatmentTrackerFF,
      tagScanForNonHIFF,
      dispatch,
      withAligner,
      patient,
      analyticsPayload
    ]
  )

  return actions
}
