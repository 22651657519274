import React, { useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { PatientSideBarChatIcon, PatientSideBarLiveCallsIcon, PatientSideBarNotesIcon } from 'components/common/icons'
import SidebarItem from './SidebarItem'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { PatientSidebarItemKeys } from './patientSidebarConsts'
import { trackEvent } from 'utils/analyticsUtils'
import { setActiveItem } from './patientSidebarSlice'
import useQueryParams from 'hooks/useQueryParams'
import useFeatureFlags from 'hooks/useFeatureFlags'

const useStyles = makeStyles(theme => ({
  patientSidebarRoot: {
    position: 'absolute',
    top: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
    borderLeft: '1px solid #e6e6e6',
    borderTop: '1px solid #e6e6e6',
    width: 'var(--patients-side-bar-collapsed-width)',
    zIndex: 999,
    height: '100%',
    background: 'white',
    transition: 'all 0.15s ease-in-out',
    overflow: 'hidden',
    '&:hover': {
      width: 130
    }
  }
}))

const PatientSidebar = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()

  const { setQueryParams, queryParams } = useQueryParams()
  const { liveCalls: liveCallsFF } = useFeatureFlags()

  const { activeItem } = useSelector(state => state.patientSidebarReducer)
  const { patient } = useSelector(state => state.patientsReducer)

  const patientFFs = useMemo(() => JSON.parse(patient?.user?.featureFlags?.flags || '{}'), [patient])

  const items = useMemo(
    () =>
      [
        {
          key: PatientSidebarItemKeys.Chat,
          title: t('pages.patients.patientSidebar.items.chat.title'),
          icon: PatientSideBarChatIcon,
          condition: () => true
        },
        {
          key: PatientSidebarItemKeys.Notes,
          title: t('pages.patients.patientSidebar.items.notes.title'),
          icon: PatientSideBarNotesIcon,
          condition: () => true
        },
        {
          key: PatientSidebarItemKeys.LiveCalls,
          title: t('pages.patients.patientSidebar.items.liveCalls.title'),
          icon: PatientSideBarLiveCallsIcon,
          condition: () => liveCallsFF && !!patientFFs.liveCallsAvailable
        }
      ].filter(item => item.condition()),
    [liveCallsFF, patientFFs.liveCallsAvailable, t]
  )

  const handleItemClicked = useCallback(
    itemKey => {
      trackEvent('Patient side bar - Item clicked', {
        itemKey
      })
      setQueryParams({ ...queryParams, patientView: itemKey })
      dispatch(setActiveItem(itemKey))
    },
    [dispatch, queryParams, setQueryParams]
  )

  return (
    <div className={classes.patientSidebarRoot} id="patient-sidebar">
      {items.map(item => (
        <SidebarItem
          id={`patient-side-bar-id-${item.key}`}
          key={`patient-side-bar-id-${item.key}`}
          icon={item.icon}
          title={item.title}
          isActive={item.key === activeItem}
          onClick={() => handleItemClicked(item.key)}
        />
      ))}
    </div>
  )
}

export default PatientSidebar
