import { Grid, makeStyles } from '@material-ui/core'
import SyncIcon from '@material-ui/icons/Sync'
import actions from 'actions'
import {
  STL_BY_REQUEST_PROVIDERS,
  StlProviders,
  TreatmentSTLsStatuses,
  TxTrackerValidationSteps
} from 'components/Patients/TreatmentTracker/txTrackerConsts'
import SelectInput from 'components/common/SelectInput'
import Tooltip from 'components/common/Tooltip'
import { QuestionMarkIcon } from 'components/common/icons'
import { CONTENT_HEIGHT } from 'consts/patientCard'
import { TreatmentTypes } from 'consts/treatmentConsts'
import useTxTrackerValidations from 'hooks/useTxTrackerValidations'
import useUploadSTLs from 'hooks/useUploadSTLs'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import DriveUploaderDropzone from './DriveUploaderDropzone'
import InvalidFilesModal from './InvalidFilesModal'
import PreviouslyUploadedStls from './PreviouslyUploadedStls'
import UploadCompletedModal from './UploadCompletedModal'
import { trackEvent } from 'utils/analyticsUtils'

const useStyles = makeStyles({
  container: {
    height: CONTENT_HEIGHT,
    padding: '30px 50px 10px 5%',
    overflowY: 'auto'
  },
  recommendationContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  recommendation: {
    background: '#ECEFFF',
    borderRadius: 10,
    height: '100%',
    width: '95%',
    marginRight: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  uploadedSTLsTitleContainer: {
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'space-between'
  },
  requestInProgress: {
    color: '#FFA34D',
    fontWeight: 'bold',
    fontSize: 13,
    display: 'flex'
  }
})

const TxTrackerSetup = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [isUploaderComponentLoading, setIsUploaderComponentLoading] = useState(false)
  const [isValidationInProgress, setIsValidationInProgress] = useState(false)

  const {
    id: treatmentId,
    patientId,
    type: treatmentType,
    txTrackerStls,
    currentStatus
  } = useSelector(state => state.treatmentReducer.treatment)

  const patientStatusType = useMemo(() => currentStatus?.status?.type, [currentStatus])

  const { status: requestSTLsStatus } = useSelector(state => state.txTrackerReducer.requestSTLs)

  const { runTxTrackerValidations, ValidationErrors, providerOptions } = useTxTrackerValidations({
    treatmentType,
    txTrackerStls,
    patientId,
    setIsValidationInProgress
  })

  const {
    handleRequestProviderSTLs,
    invalidFiles,
    setInvalidFiles,
    provider,
    setProvider,
    validationError,
    setValidationError,
    isUploadCompletedOpen,
    setIsUploadCompletedOpen,
    treatmentStlsStatus,
    stls,
    existingStlsProvider,
    DROPZONE_CONTAINER_ID
  } = useUploadSTLs({
    setIsUploaderComponentLoading,
    runTxTrackerValidations,
    ValidationErrors,
    patientId,
    treatmentId,
    treatmentType,
    txTrackerStls
  })

  const recommendationText = useMemo(() => {
    switch (provider) {
      case StlProviders['3M']:
        return t('dialogs.patientInfo.txTrackerSetup.recommendationMoreThan5')
      case StlProviders.Align:
        return t('dialogs.patientInfo.txTrackerSetup.uploadFirstAndLastOnly')
      case StlProviders.KLOwen:
        return t('dialogs.patientInfo.txTrackerSetup.uploadFirstAndLastOnly')
      case StlProviders.Spark:
        return ''
      case StlProviders.uLab:
        return t('dialogs.patientInfo.txTrackerSetup.recommendationMoreThan5')
      case StlProviders.Other: {
        if (treatmentType === TreatmentTypes.Aligners) {
          return t('dialogs.patientInfo.txTrackerSetup.recommendationMoreThan5')
        }

        return t('dialogs.patientInfo.txTrackerSetup.uploadFirstAndLastOnly')
      }
      default:
        return ''
    }
  }, [provider, t, treatmentType])

  const handleChangeProvider = useCallback(
    async ({ value: providerName }) => {
      const { validationError } = await runTxTrackerValidations({
        provider: providerName,
        step: TxTrackerValidationSteps.SelectProvider
      })

      if (!STL_BY_REQUEST_PROVIDERS.includes(providerName) && !validationError) {
        setIsUploaderComponentLoading(true)
      }

      if (validationError) {
        trackEvent('STL Upload Validation Error', {
          validationError,
          provider: providerName,
          treatmentType
        })
      }

      setValidationError(validationError)
      setProvider(providerName)
    },
    [treatmentType, setProvider, runTxTrackerValidations, setValidationError]
  )

  useEffect(() => () => dispatch(actions.clearProviderStlsRequestStatus()), [dispatch])

  return (
    <div className={classes.container}>
      <InvalidFilesModal isOpen={!!invalidFiles} invalidFiles={invalidFiles} onClose={() => setInvalidFiles(null)} />
      <UploadCompletedModal isOpen={isUploadCompletedOpen} onClose={() => setIsUploadCompletedOpen(false)} />
      <Grid container direction="column">
        <Grid container spacing={1}>
          <Grid item xs={2}>
            <SelectInput
              selectStyle={{ fontSize: 14, fontWeight: 500, marginTop: -2 }}
              showArrow={true}
              dropdownClassName={classes.dropdown}
              style={{ bright: true, thick: true }}
              value={provider}
              options={providerOptions}
              keepErrorContainerWhenInactive={false}
              placeholder={t('dialogs.patientInfo.txTrackerSetup.selectProviderDropdown')}
              onChange={handleChangeProvider}
              disabled={patientStatusType === 'invited'}
            />
          </Grid>
          <Grid item xs={6} className={classes.recommendationContainer}>
            {provider && (
              <>
                <div className={classes.recommendation}>{recommendationText}</div>
                <Tooltip
                  placement={'right'}
                  value={t('dialogs.patientInfo.txTrackerSetup.recommendationTooltip')}
                  variant="dark"
                >
                  <div>
                    <QuestionMarkIcon fontSize="inherit" />
                  </div>
                </Tooltip>
              </>
            )}
          </Grid>
          <Grid item xs={4} className={classes.uploadedSTLsTitleContainer}>
            <div>{t('dialogs.patientInfo.txTrackerSetup.uploadedSTLs')}</div>
            {treatmentStlsStatus === TreatmentSTLsStatuses.PendingFilesTransfer && (
              <div className={classes.requestInProgress}>
                <SyncIcon style={{ fontSize: 16, marginRight: 4 }} />
                {t('dialogs.patientInfo.txTrackerSetup.requestInProgress')}
              </div>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={1} style={{ height: 300 }}>
          <Grid item xs={8} style={{ height: '100%' }}>
            <DriveUploaderDropzone
              dropzoneContainerId={DROPZONE_CONTAINER_ID}
              isUploaderComponentLoading={isUploaderComponentLoading}
              provider={provider}
              providerOptions={providerOptions}
              onRequestProviderSTLs={handleRequestProviderSTLs}
              requestSTLsStatus={requestSTLsStatus}
              validationError={validationError}
              isValidationInProgress={isValidationInProgress}
              patientStatusType={patientStatusType}
            />
          </Grid>
          <Grid item xs={4} style={{ height: '100%' }}>
            <PreviouslyUploadedStls stls={stls} treatmentType={treatmentType} provider={existingStlsProvider} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default TxTrackerSetup
