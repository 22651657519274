import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import UntrackedTeethSelectionPopup from './Actions/UntrackedTeethSelectionPopup'
import TeethSelectionFlow from './Actions/TeethSelectionFlow'
import TeethSelectionPreview from './Actions/TeethSelectionPreview'
import TeethAttachmentsSelectionPopup from './Actions/TeethAttachmentsSelectionPopup'
import { GuidelineCategories, PatientBriefActions } from 'consts/hiToolsConsts'
import NightTimeOnly from './Actions/NightTimeOnly'
import TypicalAdvanceFrequency from './Actions/TypicalAdvanceFrequency'

const AlignersTxTrackingCategory = ({ categoryValues = {}, onChange, viewMode }) => {
  const { t } = useTranslation()

  return (
    <Grid container spacing={1} direction="column">
      <Grid item>
        <TeethSelectionFlow
          selections={categoryValues[PatientBriefActions.UntrackedTeeth]}
          setSelections={selections => onChange({ [PatientBriefActions.UntrackedTeeth]: selections })}
          addSelectionsButtonText={t('dialogs.patientBrief.actions.setUntrackedTeeth')}
          viewMode={viewMode}
          category={GuidelineCategories.AlignersTxTracking}
          TeethSelectionPopupComponent={UntrackedTeethSelectionPopup}
          SelectionsPreviewComponent={({ selections }) => (
            <TeethSelectionPreview
              viewMode={viewMode}
              label={t('dialogs.patientBrief.untrackedTeethPopup.listPreviewLabel')}
              selections={selections}
            />
          )}
        />
      </Grid>
      <Grid item>
        <TeethSelectionFlow
          selections={categoryValues[PatientBriefActions.Attachments]}
          setSelections={selections => onChange({ [PatientBriefActions.Attachments]: selections })}
          addSelectionsButtonText={t('dialogs.patientBrief.actions.setAttachments')}
          viewMode={viewMode}
          category={GuidelineCategories.AlignersTxTracking}
          TeethSelectionPopupComponent={TeethAttachmentsSelectionPopup}
          SelectionsPreviewComponent={({ selections }) => (
            <TeethSelectionPreview
              viewMode={viewMode}
              label={t('dialogs.patientBrief.setAttachmentsPopup.listPreviewLabel')}
              selections={selections}
            />
          )}
        />
      </Grid>
      <Grid item>
        <TypicalAdvanceFrequency
          frequency={categoryValues[PatientBriefActions.TypicalAdvanceFrequency]?.frequency}
          onChange={frequency =>
            onChange({
              [PatientBriefActions.TypicalAdvanceFrequency]: {
                ...categoryValues[PatientBriefActions.TypicalAdvanceFrequency],
                frequency
              }
            })
          }
          viewMode={viewMode}
        />
      </Grid>
      <Grid item>
        <NightTimeOnly
          checked={categoryValues[PatientBriefActions.NightTimeOnly]}
          onChange={isChecked => onChange({ [PatientBriefActions.NightTimeOnly]: isChecked })}
          viewMode={viewMode}
        />
      </Grid>
    </Grid>
  )
}

export default AlignersTxTrackingCategory
