import { Auth } from 'aws-amplify'
import { COGNITO_MFA_METHODS } from 'consts/mfaConsts'
import { useCallback } from 'react'
import { trackEvent } from 'utils/analyticsUtils'
import { logError, logInfo } from 'utils/logUtils'

export default () => {
  const getQrCodeLink = ({ username, secret, email }) =>
    `otpauth://totp/${username}?secret=${secret}&issuer=Grin: ${email}`

  const confirmTOTP = useCallback(async confirmationCode => {
    const cognitoUser = await Auth.currentAuthenticatedUser()

    try {
      await Auth.verifyTotpToken(cognitoUser, confirmationCode)
      await Auth.setPreferredMFA(cognitoUser, 'TOTP')

      trackEvent('MFA - Confirmed TOTP code')
      logInfo('MFA - Confirmed TOTP code')
      return { success: true }
    } catch (error) {
      logError('An error occured while verifying mfa totp code', {
        username: cognitoUser.username,
        error
      })
      return { success: false, error }
    }
  }, [])

  const initSMSVerification = async ({ phoneNumber }) => {
    const cognitoUser = await Auth.currentAuthenticatedUser()

    try {
      await Auth.updateUserAttributes(cognitoUser, {
        phone_number: phoneNumber
      })
      await Auth.verifyCurrentUserAttribute('phone_number')
      return { success: true }
    } catch (error) {
      logError('An error occured while initiating mfa sms verification', {
        username: cognitoUser.username,
        error
      })
      return { success: false, error }
    }
  }

  const confirmSMS = async ({ confirmationCode }) => {
    const cognitoUser = await Auth.currentAuthenticatedUser()

    try {
      await Auth.verifyCurrentUserAttributeSubmit('phone_number', confirmationCode)
      await Auth.setPreferredMFA(cognitoUser, 'SMS_MFA')

      trackEvent('MFA - Confirmed SMS code')
      logInfo('MFA - Confirmed SMS code')

      return { success: true }
    } catch (error) {
      logError('An error occured while verifying mfa sms code', {
        username: cognitoUser.username,
        error
      })
      return {
        success: false,
        error
      }
    }
  }

  const verifyCode = async ({ cognitoUser, confirmationCode, mfaType }) => {
    const updateCognitoUser = await Auth.confirmSignIn(cognitoUser, confirmationCode, mfaType)

    trackEvent(`MFA - Confirmed ${mfaType === COGNITO_MFA_METHODS.SMS ? 'SMS' : 'App'} code`)
    logInfo(`MFA - Confirmed ${mfaType === COGNITO_MFA_METHODS.SMS ? 'SMS' : 'App'} code`)

    return updateCognitoUser
  }

  return {
    getQrCodeLink,
    confirmTOTP,
    initSMSVerification,
    confirmSMS,
    verifyCode
  }
}
