import { useMemo } from 'react'
import useLoadGrinNotifications from './useLoadGrinNotifications'
import { calculateDaysAgo } from 'utils/dateUtils'

export const NotificationGroups = {
  Today: 'today',
  Last7Days: 'last7days',
  Older: 'older'
}

export default () => {
  const { grinNotifications = [], fetchNextPage, hasMorePages } = useLoadGrinNotifications({ refetchOnMount: false })

  const groupConfigurations = useMemo(
    () => [
      {
        key: NotificationGroups.Today,
        predicate: grinNotification => calculateDaysAgo(grinNotification.eventDate) < 1
      },
      {
        key: NotificationGroups.Last7Days,
        predicate: grinNotification => calculateDaysAgo(grinNotification.eventDate) < 7
      },
      {
        key: NotificationGroups.Older,
        predicate: grinNotification => true
      }
    ],
    []
  )

  /**
   * @type {Array<GrinNotificationsGroup>}
   */
  const notificationGroups = useMemo(() => {
    const groupsDict = grinNotifications.reduce((groups, notification) => {
      const groupKey = groupConfigurations.find(g => g.predicate(notification))?.key || NotificationGroups.Older
      const groupData = groups[groupKey] || { key: groupKey }

      return {
        ...groups,
        [groupKey]: {
          ...groupData,
          notifications: [...(groupData.notifications || []), notification]
        }
      }
    }, {})

    return Object.values(groupsDict)
  }, [groupConfigurations, grinNotifications])

  return {
    notificationGroups,
    totalNotifications: grinNotifications.length,
    fetchNextPage,
    hasMorePages
  }
}
