import { Grid, makeStyles, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import usePatientsFilterData from 'components/Search/hooks/usePatientsFilterData'
import GrinTag from 'components/common/tags/GrinTag'
import { AsyncStatus } from 'consts'
import { conditionTypes } from 'consts/automatedActionConsts'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const useStyles = makeStyles(() => ({
  tag: {
    margin: 5
  }
}))

const PatientHasTags = ({ onChange = () => {}, params = {} }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const customTagsLoadingState = useSelector(state => state.practiceReducer.aggregatedCustomTags.status)
  const { programTags, treatmentTags, customTags } = usePatientsFilterData()

  const allTags = useMemo(
    () => [...programTags, ...treatmentTags, ...customTags],
    [customTags, programTags, treatmentTags]
  )

  const selectedTags = useMemo(() => params?.tags || [], [params?.tags])
  const options = useMemo(() => allTags.map(tag => ({ value: tag.value, label: tag.label })), [allTags])

  const handleDeleteFromList = useCallback(
    tag =>
      onChange({
        type: conditionTypes.PatientHasTags,
        params: { tags: selectedTags.filter(currTag => currTag.value !== tag.value) }
      }),
    [onChange, selectedTags]
  )

  const handleRenderTags = useCallback(
    () =>
      (customTagsLoadingState === AsyncStatus.Loading ? [] : selectedTags).map(tag => (
        <GrinTag
          key={tag.value}
          tag={allTags.find(currTag => currTag.value === tag.value)}
          canDeleteSystem
          canDeleteCustom
          onDelete={() => handleDeleteFromList(tag)}
          className={classes.tag}
        />
      )),
    [allTags, classes.tag, handleDeleteFromList, selectedTags, customTagsLoadingState]
  )

  return (
    <Grid container direction="column">
      <Grid item>
        <Autocomplete
          id="patient-tags-selector"
          multiple
          fullWidth
          filterSelectedOptions
          loading={customTagsLoadingState === AsyncStatus.Loading}
          style={{ minWidth: 300 }}
          ListboxProps={{ style: { maxHeight: 200 } }}
          options={options}
          value={params?.tags || []}
          getOptionLabel={option => option.label}
          getOptionSelected={option => selectedTags.some(selectedTag => selectedTag.value === option.value)}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={t('pages.accountSettings.automations.editorModal.patientHasTags')}
            />
          )}
          onChange={(event, updatedSelectedTags) => {
            onChange({ type: conditionTypes.PatientHasTags, params: { tags: updatedSelectedTags } })
          }}
          renderTags={handleRenderTags}
        />
      </Grid>
    </Grid>
  )
}

export default PatientHasTags
