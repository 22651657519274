import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import TeethTrackingLegend from './TeethTrackingLegend'
import TeethGrid from './TeethGrid'
import useFeatureFlags from 'hooks/useFeatureFlags'

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    borderTop: '1px solid var(--border-color-37)',
    padding: 8,
    borderBottomLeftRadius: 32,
    borderBottomRightRadius: 32
  }
}))

const TeethTracking = ({ treatmentTrackerData, currentScan, scanNumber, treatmentType, disabled = false }) => {
  const classes = useStyles()
  const { txTrackerTxGoals } = useFeatureFlags()

  return (
    <Grid container direction="column" justifyContent="center" wrap="nowrap" className={classes.container}>
      <Grid item>
        <TeethGrid
          teethData={treatmentTrackerData.teeth}
          isTxGoalsEnabled={txTrackerTxGoals}
          currentScan={currentScan}
          scanNumber={scanNumber}
          treatmentType={treatmentType}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12}>
        <TeethTrackingLegend treatmentType={treatmentType} />
      </Grid>
    </Grid>
  )
}

export default TeethTracking
