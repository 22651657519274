import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'

const CountdownV2 = ({ targetTime, renderer, refreshInterval = 1000 }) => {
  const [completed, setCompleted] = useState(false)
  const [timeLeft, setTimeLeft] = useState(null)

  const calculateTimeLeft = useCallback(() => {
    const now = moment()
    if (moment(targetTime).isBefore(now)) {
      setCompleted(true)
      return {}
    }

    const target = moment(targetTime)
    const duration = moment.duration(target.diff())

    return {
      days: duration.days(),
      hours: duration.hours(),
      minutes: duration.minutes(),
      seconds: duration.seconds()
    }
  }, [targetTime])

  useEffect(() => {
    if (!timeLeft && targetTime && !completed) {
      setTimeLeft(calculateTimeLeft())
    }
  }, [calculateTimeLeft, completed, targetTime, timeLeft])

  useEffect(() => {
    if (!completed) {
      const timer = setInterval(() => {
        setTimeLeft(calculateTimeLeft())
      }, refreshInterval)

      return () => clearInterval(timer)
    } else {
      setTimeLeft(null)
    }
  }, [calculateTimeLeft, completed, refreshInterval])

  useEffect(() => {
    if (timeLeft?.seconds === 0) {
      setCompleted(true)
    }
  }, [timeLeft])

  useEffect(() => {
    if (targetTime) {
      setCompleted(false)
    }
  }, [targetTime])

  return renderer({ completed, ...timeLeft })
}

export default CountdownV2
