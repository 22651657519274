import { Grid } from '@material-ui/core'
import { ScanFeedPageSectionNames } from 'consts/timelineConsts'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import ScanFeedCard from './ScanFeedCard'
import TeethTracking from 'components/Patients/TreatmentTracker/TeethTracking/TeethTracking'
import { useSelector } from 'react-redux'
import useTxTracker from 'components/Patients/TreatmentTracker/useTxTracker'

const TxTrackerCard = ({ treatmentTrackerData }) => {
  const { t } = useTranslation()
  const { calculateTreatmentType } = useTxTracker()

  const { treatment } = useSelector(state => state.treatmentReducer)

  const treatmentType = useMemo(
    () =>
      calculateTreatmentType({
        treatmentType: treatment.type,
        provider: JSON.parse(treatment.txTrackerStls || '{}')?.provider
      }),
    [calculateTreatmentType, treatment]
  )

  return (
    <ScanFeedCard
      id={ScanFeedPageSectionNames.TxTracker}
      title={t(`pages.patients.selectedPatient.timelineV2.scanFeed.txTracker.title`)}
    >
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <TeethTracking disabled treatmentTrackerData={treatmentTrackerData} treatmentType={treatmentType} />
        </Grid>
      </Grid>
    </ScanFeedCard>
  )
}

export default TxTrackerCard
