import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { IconButton } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import ClearIcon from '@material-ui/icons/Clear'
import DazzedParagraph16 from '../../../common/text/DazzedParagraph16'

export const useStyles = makeStyles({
  featureContainer: {
    marginBottom: 15,
    borderBottom: '1px solid var(--border-color-7)'
  },
  featureSubContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 25,
    marginBottom: 15,
    paddingLeft: 15,
    borderLeft: '2px solid var(--border-color-1)'
  },
  featureText: {
    fontWeight: 500
  },
  featureIconContainer: {
    position: 'relative',
    left: 10
  },
  featureIcon: {
    color: 'var(--border-color-1)'
  }
})

const ENABLE_FEATURE = 'ENABLE_FEATURE'
const DISABLE_FEATURE = 'DISABLE_FEATURE'

const FeatureItem = ({ feature, action, onClick, disabled = false }) => {
  const classes = useStyles()

  return (
    <div key={feature.key} className={classes.featureContainer}>
      <div className={classes.featureSubContainer}>
        <DazzedParagraph16 className={classes.featureText}>{feature.label}</DazzedParagraph16>
        {!disabled && (
          <IconButton className={classes.featureIconContainer} onClick={() => onClick(feature, action)}>
            {action === ENABLE_FEATURE && <AddIcon className={classes.featureIcon} />}
            {action === DISABLE_FEATURE && <ClearIcon className={classes.featureIcon} />}
          </IconButton>
        )}
      </div>
    </div>
  )
}

export default FeatureItem
