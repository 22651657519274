import { makeStyles } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import usePracticeTeamMembers from 'hooks/usePracticeTeamMembers'
import React, { useMemo } from 'react'
import AssignedNoteBody from './AssignedNoteBody'
import NeedsAttentionNoteBody from './NeedsAttentionNoteBody'
import NeedsAttentionResolvedNoteBody from './NeedsAttentionResolvedNoteBody'

const useStyles = makeStyles(theme => ({
  noteItemRoot: {
    width: '100%',
    backgroundColor: '#F3F3F4',
    borderRadius: 10,
    padding: 10,
    marginBottom: 12
  },
  body: {
    marginLeft: 50,
    marginTop: 3
  },
  mentioned: {
    color: 'var(--text-color-16)',
    fontWeight: 600
  }
}))

const getFormattedMention = name => (name ? ` @${name}` : '')
const getFormattedBody = body => body?.split('\n').map((line, index) => <div key={index}>{line}</div>)

const NoteBody = ({ body, noteParsedMetadata }) => {
  const classes = useStyles()
  const { practiceTeamMembers } = usePracticeTeamMembers()

  const { noteType, reason, selectedPracticeMembersIds, hiNote, assigneeName } = noteParsedMetadata

  const formattedBody = useMemo(() => getFormattedBody(body), [body])
  const formattedAssignee = useMemo(() => getFormattedMention(assigneeName), [assigneeName])
  const formattedNeedsAttentionMentions = useMemo(
    () =>
      selectedPracticeMembersIds
        ?.map(id => practiceTeamMembers.find(member => member.id === id)?.name)
        ?.map(getFormattedMention),
    [practiceTeamMembers, selectedPracticeMembersIds]
  )

  return noteType === 'needsAttentionResolved' ? (
    <NeedsAttentionResolvedNoteBody hiNote={getFormattedBody(hiNote)} />
  ) : noteType === 'needsAttention' ? (
    <NeedsAttentionNoteBody
      hiNote={getFormattedBody(hiNote)}
      reason={reason}
      needsAttentionMentions={formattedNeedsAttentionMentions}
    />
  ) : assigneeName ? (
    <AssignedNoteBody assigneeName={formattedAssignee} formattedBody={formattedBody} />
  ) : (
    <DazzedParagraph14 strong className={classes.body}>
      {formattedBody}
    </DazzedParagraph14>
  )
}

export default NoteBody
