import { createSlice } from '@reduxjs/toolkit'
import { PatientSidebarItemKeys } from './patientSidebarConsts'

const initialState = { activeItem: PatientSidebarItemKeys.Chat }

const patientSidebarSlice = createSlice({
  name: 'patientSidebar',
  initialState,
  reducers: {
    setActiveItem: (state, { payload: value }) => {
      state.activeItem = value
    }
  }
})

export const { setActiveItem } = patientSidebarSlice.actions

export default patientSidebarSlice.reducer
