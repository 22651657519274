import * as React from 'react'

const PauseScans = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginRight: 2, marginTop: 4 }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9ZM5 4H8V14H5V4ZM13 4H10V14H13V4Z"
      fill="#527195"
    />
  </svg>
)

export default PauseScans
