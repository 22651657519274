import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import ScanDateLabel from 'components/common/ScanDateLabel'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  item: {
    cursor: 'pointer',
    userSelect: 'none'
  }
}))

const ScanItem = ({
  alignerNumber,
  scanNumber,
  scanCreatedAt,
  withDaysAgo,
  createdAtFormat,
  fontSize,
  textClassName = ''
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid container alignItems="center" spacing={1} className={classes.item}>
      <Grid item>
        <DazzedParagraph16 bold className={textClassName}>
          {t('dialogs.treatmentTracker.header.scanNumber', { scanNumber })}
        </DazzedParagraph16>
      </Grid>
      {!!alignerNumber && (
        <Grid item>
          <DazzedParagraph16 strong className={textClassName}>
            {t('dialogs.treatmentTracker.header.alignerNumber', { alignerNumber })}
          </DazzedParagraph16>
        </Grid>
      )}
      <Grid item>
        <ScanDateLabel
          withDaysAgo={withDaysAgo}
          dateFornmat={createdAtFormat}
          timestamp={scanCreatedAt}
          color="var(--text-color-11)"
        />
      </Grid>
    </Grid>
  )
}

export default ScanItem
