import { Grid, makeStyles } from '@material-ui/core'
import NeedsAttentionDisplayOnlyTag from 'components/common/tags/NeedsAttentionDisplayOnlyTag'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import React from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
  needsAttentionSection: {
    marginLeft: 35,
    marginTop: 3,
    marginBottom: 5,
    alignItems: 'center'
  },
  italic: {
    fontStyle: 'italic',
    color: 'var(--bg-color-45)'
  },
  body: {
    marginLeft: 50,
    marginTop: 3
  }
}))

const NeedsAttentionResolvedNoteBody = ({ hiNote }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid container>
      <Grid container className={classes.needsAttentionSection}>
        <NeedsAttentionDisplayOnlyTag
          color="rgba(255, 242, 215, 0.8)"
          textColor="rgba(219, 97, 93, 0.5)"
          circleColor="rgba(219, 97, 93, 0.5)"
        />
        <span className={classes.italic}>{t('pages.patients.patientSwitchableView.notes.noteBody.resolved')}</span>
      </Grid>
      <Grid container>
        <DazzedParagraph14 strong className={classes.body}>
          {hiNote}
        </DazzedParagraph14>
      </Grid>
    </Grid>
  )
}

export default NeedsAttentionResolvedNoteBody
