import React, { useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { ButtonBase } from '@material-ui/core'
import NotificationCenterBellIcon from 'components/common/icons/notificationCenter/NotificationCenterBellIcon'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import Tooltip from 'components/common/Tooltip'
import { trackEvent } from 'utils/analyticsUtils'
import { useDispatch, useSelector } from 'react-redux'
import { setNotificationCenterOpen } from 'components/NotificationCenter/notificationCenterSlice'
import { NOTIFICATION_CENTER_MAX_NOTIFICATIONS_ON_BELL } from 'consts/notificationCenterConsts'
import { api } from 'store/api'

const useStyles = makeStyles(theme => ({
  bellIconRoot: {
    backgroundColor: 'var(--bg-color-16)',
    borderRadius: '100%',
    width: '40px',
    height: '40px',
    position: 'relative'
  },
  unreadNotificationsIndicatorContainer: {
    background: 'rgba(244, 67, 54, 1)',
    borderRadius: '100%',
    width: 21,
    height: 21,
    position: 'absolute',
    top: '-5px',
    right: '-5px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  unreadNotificationsIndicatorLabel: {
    color: 'white'
  }
}))

const NotificationCenterBellButton = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { data } = useSelector(api.endpoints.fetchTotalUnreadNotifications.select(null))

  const unreadNotificationsCount = useMemo(() => {
    const total = data?.total
    if (total > NOTIFICATION_CENTER_MAX_NOTIFICATIONS_ON_BELL) {
      return NOTIFICATION_CENTER_MAX_NOTIFICATIONS_ON_BELL
    }

    return total
  }, [data])
  const hasUnreadNotifications = useMemo(() => unreadNotificationsCount > 0, [unreadNotificationsCount])

  const handleClick = useCallback(() => {
    trackEvent(`App Header - Notification Center bell clicked`, {
      unreadNotificationsCount
    })
    dispatch(setNotificationCenterOpen({ isOpen: true }))
  }, [unreadNotificationsCount, dispatch])

  return (
    <Tooltip
      value={
        hasUnreadNotifications
          ? t('common.appHeader.notificationCenterButton.unreadNotificationsTooltip', {
              count: unreadNotificationsCount
            })
          : t('common.appHeader.notificationCenterButton.tooltip')
      }
      placement="bottom"
      arrow
    >
      <ButtonBase className={classes.bellIconRoot} onClick={handleClick}>
        <NotificationCenterBellIcon />
        {hasUnreadNotifications && (
          <div className={classes.unreadNotificationsIndicatorContainer}>
            <DazzedParagraph12 thickness="bold" className={classes.unreadNotificationsIndicatorLabel}>
              {unreadNotificationsCount}
            </DazzedParagraph12>
          </div>
        )}
      </ButtonBase>
    </Tooltip>
  )
}

export default NotificationCenterBellButton
