import React, { useCallback } from 'react'
import { Grid } from '@material-ui/core'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import MembersTable from '../PracticeMembers/Table/MembersTable'
import ProfilePicture from 'components/common/ProfilePicture'
import { updateS3PracticeLogoKey } from 'utils/storageUtils'
import { useHistory } from 'react-router'
import { AsyncStatus, ROUTES } from 'consts'
import DSOAddMemberButton from './DSOAddMemberButton'
import { trackEvent } from 'utils/analyticsUtils'
import { useDispatch, useSelector } from 'react-redux'
import Actions from 'actions'

const DSOPractices = ({ practices = [] }) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const { status } = useSelector(state => state.multiPracticeReducer.dsoPractices)

  const handleClickAddMember = useCallback(
    ({ practiceAccountOwnerId, practiceName }) => {
      trackEvent('DSO Members - Add practice member clicked', {
        practiceAccountOwnerId,
        practiceName
      })
      history.push(ROUTES.DSO_MEMBERS_ADD_USER, { isDSO: false, practiceName, practiceAccountOwnerId })
    },
    [history]
  )
  const handleCancelInvitation = useCallback(
    ({ memberId, name }, accountOwnerId) =>
      dispatch(Actions.dsoCancelPracticeMemberInvitation({ id: memberId, name, accountOwnerId })),
    [dispatch]
  )
  const handleResendInvitation = useCallback(
    ({ values, doctorId, memberId }, accountOwnerId) =>
      dispatch(Actions.dsoResendPracticeMemberInvitation({ ...values, doctorId, id: memberId, accountOwnerId })),
    [dispatch]
  )
  const handleDeactivateUser = useCallback(
    (memberId, accountOwnerId) => dispatch(Actions.dsoDeactivatePracticeMember({ memberId, accountOwnerId })),
    [dispatch]
  )
  const handleActivateUser = useCallback(
    (memberId, accountOwnerId) => dispatch(Actions.dsoActivatePracticeMember({ memberId, accountOwnerId })),
    [dispatch]
  )
  const handleChangeAccessType = useCallback(
    ({ memberId, accessType, accountOwnerId }) =>
      dispatch(Actions.dsoChangePracticeMemberAccess({ memberId, accountOwnerId, accessType })),
    [dispatch]
  )

  return practices.map(({ accountOwnerId, practiceName, practiceMembers, practiceLogo }) => (
    <Grid container direction="column" spacing={1} key={`dso-practice-${accountOwnerId}`}>
      <Grid item>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <ProfilePicture photo={updateS3PracticeLogoKey(practiceLogo)} name={practiceName} size="small" />
              </Grid>
              <Grid item>
                <DazzedParagraph16 bold>{practiceName}</DazzedParagraph16>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <DSOAddMemberButton
              onClick={() => handleClickAddMember({ practiceAccountOwnerId: accountOwnerId, practiceName })}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <MembersTable
          practiceMembers={practiceMembers}
          onCancelInvitation={({ memberId, name }) => handleCancelInvitation({ memberId, name }, accountOwnerId)}
          onResendInvitation={({ values, doctorId, memberId }) =>
            handleResendInvitation({ values, doctorId, memberId }, accountOwnerId)
          }
          onDeactivateUser={memberId => handleDeactivateUser(memberId, accountOwnerId)}
          onActivateUser={memberId => handleActivateUser(memberId, accountOwnerId)}
          onChangeAccessType={(memberId, accessType) =>
            handleChangeAccessType({ memberId, accessType, accountOwnerId })
          }
          isLoading={status === AsyncStatus.Loading}
        />
      </Grid>
    </Grid>
  ))
}

export default DSOPractices
