import React, { useCallback, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import GrinFloatingPopup from 'components/common/menu/GrinFloatingPopup'
import { useDispatch, useSelector } from 'react-redux'
import { Pinned } from 'components/common/icons'
import PinnedNotesFloatingPopupContent from './PinnedNotesFloatingPopupContent'
import { useTranslation } from 'react-i18next'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { trackEvent } from 'utils/analyticsUtils'
import Actions from 'actions'
import { PATIENT_CARD_TABS } from 'consts/patientCard'
import usePatientNotes from 'hooks/RTK/usePatientNotes'

const useStyles = makeStyles(theme => ({
  container: {
    padding: '15px 20px 0px 20px'
  },
  title: {
    fontSize: 13,
    fontWeight: 600,
    color: '#727B8C',
    cursor: 'pointer'
  },
  pinnedIcon: {
    paddingTop: 4
  },
  triggerContainer: {
    display: 'flex',
    alignItems: 'center',
    userSelect: 'none'
  }
}))

const PinnedNotes = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { notes } = usePatientNotes()

  const { id: patientId } = useSelector(state => state.patientsReducer.patient)

  const [tooltipOpen, setTooltipOpen] = useState(false)

  const pinnedNotes = useMemo(() => notes?.filter(note => note?.isPinned), [notes])
  const hasPinnedAnyNotes = useMemo(() => pinnedNotes?.length > 0, [pinnedNotes])

  const handleClose = useCallback(() => setTooltipOpen(false), [])
  const handleOpenPatientCard = useCallback(() => {
    trackEvent('Pinned notes - open patient card notes tab')
    setTooltipOpen(false)
    dispatch(
      Actions.togglePatientCard({
        isModalOpen: true,
        patientId,
        selectedTab: PATIENT_CARD_TABS.notes.index
      })
    )
  }, [dispatch, patientId])

  return (
    hasPinnedAnyNotes && (
      <div onMouseOver={() => setTooltipOpen(true)} onMouseLeave={handleClose}>
        <GrinFloatingPopup
          title={t('pages.patients.selectedPatient.header.pinnedNotes.title')}
          titleIcon={<Pinned color="var(--text-color-11)" className={classes.pinnedIcon} />}
          titleClassName={classes.title}
          onTitleClick={handleOpenPatientCard}
          placement="right-start"
          containerClassName={classes.container}
          triggerComponent={
            <div className={classes.triggerContainer}>
              <DazzedParagraph14 strong color="var(--text-color-54)">
                {pinnedNotes.length}
              </DazzedParagraph14>
              <Pinned />
            </div>
          }
          open={tooltipOpen}
          onClose={handleClose}
        >
          <PinnedNotesFloatingPopupContent notes={pinnedNotes} />
        </GrinFloatingPopup>
      </div>
    )
  )
}

export default PinnedNotes
