import { CircularProgress, makeStyles } from '@material-ui/core'
import { STL_BY_REQUEST_PROVIDERS } from 'components/Patients/TreatmentTracker/txTrackerConsts'
import PrimaryButton from 'components/common/buttons/PrimaryButton'
import NeedsAttention from 'components/common/icons/NeedsAttention'
import DazzedHeading13 from 'components/common/text/DazzedHeading13'
import DazzedHeading20 from 'components/common/text/DazzedHeading20'
import { AsyncStatus } from 'consts'
import React from 'react'
import { useTranslation } from 'react-i18next'
import ValidationErrorContent from './ValidationErrorContent'

const useStyles = makeStyles({
  dropzone: {
    height: '100%',
    marginTop: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  stlByRequestUpload: {
    flexDirection: 'column',
    textAlign: 'center',
    display: 'flex',
    height: '100%',
    width: '100%',
    border: '1px dashed #abb0b8',
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'default'
  },
  noProviderPlaceholder: {
    flex: 1,
    display: 'flex',
    height: '100%',
    color: 'lightgray',
    border: '1px dashed #abb0b8',
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    fontStyle: 'italic',
    cursor: 'default'
  },
  patientNotRegistered: {
    flex: 1,
    display: 'flex',
    height: '100%',
    border: '1px dashed #abb0b8',
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    color: '#505050',
    fontStyle: 'italic',
    cursor: 'default'
  }
})

const DriveUploaderDropzone = ({
  mode = 'practice',
  dropzoneContainerId,
  isUploaderComponentLoading,
  isValidationInProgress,
  provider,
  providerOptions,
  validationError,
  onRequestProviderSTLs,
  requestSTLsStatus,
  patientStatusType
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const UploaderComponent = () => {
    if (isUploaderComponentLoading || isValidationInProgress) {
      return <CircularProgress size={18} style={{ position: 'absolute' }} />
    }

    if (patientStatusType === 'invited') {
      return (
        <div className={classes.patientNotRegistered}>
          <div style={{ marginRight: 5 }}>
            <NeedsAttention circleColor="gray" />
          </div>
          {t('dialogs.patientInfo.txTrackerSetup.patientNotRegistered')}
        </div>
      )
    }

    if (!provider) {
      return (
        <div className={classes.noProviderPlaceholder}>
          {t('dialogs.patientInfo.txTrackerSetup.selectProviderPlaceholder')}
        </div>
      )
    }

    if (validationError) {
      return (
        <ValidationErrorContent
          providerOptions={providerOptions}
          provider={provider}
          validationError={validationError}
        />
      )
    } else if (STL_BY_REQUEST_PROVIDERS.includes(provider) && mode === 'practice') {
      return (
        <div className={classes.stlByRequestUpload}>
          <DazzedHeading20>
            {t('dialogs.patientInfo.txTrackerSetup.specialProviders.title', {
              provider: providerOptions.find(p => p.value === provider).label
            })}
          </DazzedHeading20>
          <br />
          <DazzedHeading13>
            {requestSTLsStatus === AsyncStatus.Completed
              ? t('dialogs.patientInfo.txTrackerSetup.specialProviders.requestSTLsSubmitted')
              : t('dialogs.patientInfo.txTrackerSetup.specialProviders.description', {
                  provider: providerOptions.find(p => p.value === provider).label
                })}
          </DazzedHeading13>
          <br />
          <br />
          {requestSTLsStatus !== AsyncStatus.Completed ? (
            <PrimaryButton
              label={t('dialogs.patientInfo.txTrackerSetup.specialProviders.requestSTLs')}
              isLoading={requestSTLsStatus === AsyncStatus.Loading}
              onClick={onRequestProviderSTLs}
            />
          ) : null}
        </div>
      )
    }

    return <></>
  }

  return (
    <div className={classes.dropzone} id={dropzoneContainerId}>
      <UploaderComponent />
    </div>
  )
}

export default DriveUploaderDropzone
