import React, { useCallback, useEffect, useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useDispatch, useSelector } from 'react-redux'
import { Trans, useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import { AsyncStatus, ROUTES } from 'consts'
import Actions from 'actions'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import OggHeading40 from 'components/common/text/OggHeading40'
import Chevron from 'components/common/icons/ChevrolRightSmall'
import { trackEvent } from 'utils/analyticsUtils'
import NewMemberForm from './NewMemberForm'
import useRolePermissions from 'hooks/useRolePermissions'

const useStyles = makeStyles(theme => ({
  heading: {
    marginTop: 10,
    marginBottom: 5
  },
  container: {
    width: 500
  },
  activeLink: {
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer'
    }
  },
  chevronContainer: {
    marginLeft: 3,
    marginTop: 5,
    width: 20
  }
}))

const DSOAddMember = ({ history }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { permissions } = useRolePermissions()

  const status = useSelector(state => state.multiPracticeReducer.dsoPractices.status)
  const errors = useSelector(state => state.profileReducer.practiceMembers.errors)

  const { isDSO, practiceName, practiceAccountOwnerId } = useMemo(() => history.location.state || {}, [history])

  const handleSubmit = useCallback(
    values => {
      if (isDSO) {
        const { accessType, ...payload } = values

        trackEvent('DSO Members - DSO user invited')
        dispatch(Actions.inviteNewDSO(payload))
      } else {
        trackEvent('DSO Members - Add practice member clicked')
        dispatch(
          Actions.dsoInvitePracticeMember({
            memberInput: values,
            practiceAccountOwnerId
          })
        )
      }
    },
    [dispatch, isDSO, practiceAccountOwnerId]
  )

  const handleCancel = useCallback(() => {
    trackEvent(`DSO Members - Add ${isDSO ? 'DSO' : 'practice member'} canceled`, {
      practiceAccountOwnerId
    })
    history.push(ROUTES.DSO_MEMBERS)
  }, [history, isDSO, practiceAccountOwnerId])

  useEffect(() => {
    if (!permissions.dsoMembers) {
      history.push(ROUTES.ACCOUNT_SETTINGS)
    }
  }, [history, permissions])

  return (
    <div className={classes.container}>
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <DazzedParagraph12 className={classes.activeLink} onClick={handleCancel}>
            {t('pages.accountSettings.dsoMembers.addMember.breadcrumbs.dsoMembers')}
          </DazzedParagraph12>
        </Grid>
        <Grid item className={classes.chevronContainer}>
          <Chevron />
        </Grid>
        <Grid item>
          <DazzedParagraph12 thickness="bold">
            {t(
              `pages.accountSettings.dsoMembers.addMember.breadcrumbs.${isDSO ? 'addDSOMember' : 'addPracticeMembers'}`
            )}
          </DazzedParagraph12>
        </Grid>
      </Grid>
      <OggHeading40 textAlign="left" className={classes.heading}>
        {t(`pages.accountSettings.dsoMembers.addMember.${isDSO ? 'dsoMemberTitle' : 'practiceMemberTitle'}`)}
      </OggHeading40>
      <DazzedParagraph12>
        <Trans
          i18nKey={`pages.accountSettings.dsoMembers.addMember.${
            isDSO ? 'dsoMemberSubtitle' : 'practiceMemberSubtitle'
          }`}
          components={{ bold: <b>{''}</b> }}
          values={{ practiceName }}
        />
      </DazzedParagraph12>
      <NewMemberForm
        isDSO={isDSO}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        errors={errors}
        isLoading={status === AsyncStatus.Loading}
        primaryButtonLabel={t('pages.accountSettingsdsoMembers.addMember.form.sendInviteBtn')}
      />
    </div>
  )
}

export default DSOAddMember
