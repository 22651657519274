import { AsyncStatus } from 'consts'
import Actions from '../actions'

const initialState = {
  notifyPractice: {
    savingStatus: null
  },
  tagScanModal: {
    isOpen: false,
    scanId: null
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.HI_NOTIFY_PRACTICE:
      return {
        ...state,
        notifyPractice: {
          ...state.notifyPractice,
          savingStatus: AsyncStatus.Loading
        }
      }
    case Actions.HI_NOTIFY_PRACTICE_RECEIVED:
      return {
        ...state,
        notifyPractice: {
          ...state.notifyPractice,
          savingStatus: AsyncStatus.Completed
        }
      }
    case Actions.HI_NOTIFY_PRACTICE_FAILED:
      return {
        ...state,
        notifyPractice: {
          ...state.notifyPractice,
          savingStatus: AsyncStatus.Failed
        }
      }
    case Actions.SUBMIT_SCAN_TRACKING_DATA_RECEIVED:
      return {
        ...state,
        tagScanModal: {
          isOpen: false
        }
      }
    case Actions.SET_TAG_SCAN_MODAL_OPEN:
      return {
        ...state,
        tagScanModal: {
          isOpen: action.payload.isOpen
        }
      }
    default:
      return state
  }
}
