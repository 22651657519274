import * as React from 'react'
import { isMobile } from 'react-device-detect'

function SvgSearch({ width = 32, height = 32, color = '#001335', ...props }) {
  return isMobile ? (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        stroke="none !important"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.77381 8.86046C11.3255 6.86771 11.1853 3.9847 9.35342 2.15279C7.36974 0.169123 4.15355 0.169123 2.16988 2.15279C0.18621 4.13646 0.18621 7.35261 2.16988 9.33628C4.0018 11.1682 6.88484 11.3083 8.87759 9.75674C8.88617 9.76645 8.89512 9.77598 8.90439 9.78525L11.5982 12.479C11.8462 12.727 12.2482 12.727 12.4962 12.479C12.7441 12.2311 12.7441 11.8291 12.4962 11.5811L9.80238 8.88732C9.79305 8.87805 9.78352 8.8691 9.77381 8.86046ZM8.41432 3.03897C9.89424 4.51885 9.89424 6.9182 8.41432 8.39811C6.93447 9.87797 4.5351 9.87797 3.05523 8.39811C1.57534 6.9182 1.57534 4.51885 3.05523 3.03897C4.5351 1.55909 6.93447 1.55909 8.41432 3.03897Z"
        fill="#8F92A1"
      />
    </svg>
  ) : (
    <svg width={width} height={height} viewBox="0 0 32 32" fill="none" {...props}>
      <path d="M21.93 14.714a7.214 7.214 0 11-14.43 0 7.214 7.214 0 0114.43 0z" stroke={color} />
      <path d="M19.857 19.858L26 26" stroke={color} strokeLinecap="round" />
    </svg>
  )
}

export default SvgSearch
