import React, { useCallback, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { CircularProgress, Grid } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import Actions from 'actions'
import AutomationRule from './AutomationRule'
import { useTranslation } from 'react-i18next'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { trackEvent } from 'utils/analyticsUtils'
import AutomationRulesEmptyState from './AutomationRulesEmptyState'

const useStyles = makeStyles(theme => ({
  automationRulesContainer: {
    marginTop: 50
  },
  addNewRule: {
    color: 'var(--text-color-25)',
    textDecoration: 'underline',
    userSelect: 'none',
    cursor: 'pointer'
  }
}))

const AutomationRules = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { isLoading, data: rules } = useSelector(state => state.practiceReducer.automationRules)
  const customTagsLoadingState = useSelector(state => state.practiceReducer.aggregatedCustomTags.status)
  const { doctor } = useSelector(state => state.profileReducer)

  const toggleNewRuleModal = useCallback(() => {
    trackEvent('Automation Rules - Add new rule clicked')
    dispatch(
      Actions.toggleRuleEditorModal({
        isOpen: true
      })
    )
  }, [dispatch])

  useEffect(() => {
    if (!rules && doctor?.id) {
      dispatch(Actions.fetchDoctorAutomationRules())
    }
  }, [dispatch, doctor?.id, rules])

  useEffect(() => {
    if (!customTagsLoadingState) {
      dispatch(Actions.fetchAggregatedPracticeCustomTags())
    }
  }, [customTagsLoadingState, dispatch])

  const newRuleClickable = (
    <Grid item onClick={toggleNewRuleModal}>
      <DazzedParagraph14 strong className={classes.addNewRule}>
        {t(`pages.accountSettings.automations.addNewRule`)}
      </DazzedParagraph14>
    </Grid>
  )

  return isLoading && !rules ? (
    <Grid container justifyContent="center">
      <Grid item>
        <CircularProgress size={35} />
      </Grid>
    </Grid>
  ) : (
    rules && (
      <Grid container direction="column" className={classes.automationRulesContainer} spacing={1}>
        <Grid item>
          {!rules.length ? (
            <AutomationRulesEmptyState />
          ) : (
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Grid container direction="row-reverse">
                  {newRuleClickable}
                </Grid>
              </Grid>
              {rules.map(currRule => (
                <Grid item key={currRule.id}>
                  <AutomationRule rule={currRule} />
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
        <Grid item>
          <Grid container>{newRuleClickable}</Grid>
        </Grid>
      </Grid>
    )
  )
}

export default AutomationRules
