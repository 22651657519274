import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import GrinAiAvatar from 'components/common/icons/GrinAiAvatar'
import ProfilePicture from 'components/common/ProfilePicture'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import usePracticeTeamMembers from 'hooks/usePracticeTeamMembers'
import moment from 'moment'
import React, { useMemo } from 'react'

const useStyles = makeStyles(theme => ({
  circle: {
    margin: '0 6px',
    fontSize: 5,
    color: 'rgba(0, 0, 0, 0.3)',
    verticalAlign: 'middle'
  },
  createdAtAgo: {
    color: 'var(--text-color-11)'
  }
}))

const CreatorInfo = ({ userId, noteParsedMetadata, createdAt }) => {
  const classes = useStyles()

  const { practiceTeamMembers } = usePracticeTeamMembers()

  const currentMember = useMemo(
    () => practiceTeamMembers?.find(member => member?.grinUserId === userId),
    [practiceTeamMembers, userId]
  )

  const { creatorName: fallbackCreatorName, creatorRoleDescription: fallbackCreatorRoleDescription } =
    noteParsedMetadata

  const name = useMemo(() => currentMember?.name || fallbackCreatorName, [currentMember, fallbackCreatorName])
  const roleDescription = useMemo(
    () => currentMember?.doctor?.roleDescription || fallbackCreatorRoleDescription,
    [currentMember, fallbackCreatorRoleDescription]
  )

  return (
    <Grid container alignItems="center" wrap="nowrap" spacing={1}>
      <Grid item>
        {userId === 'Grin' ? (
          <GrinAiAvatar />
        ) : (
          <ProfilePicture size="small" photo={currentMember?.photo} name={name} className={classes.profilePicture} />
        )}
      </Grid>
      <Grid item>
        <Grid container direction="column">
          <Grid item>
            <DazzedParagraph12>
              <strong>{name}</strong>
              {roleDescription ? (
                <>
                  <span className={classes.circle}>{`●`}</span>
                  <span style={{ fontWeight: 400 }}>{roleDescription}</span>
                </>
              ) : (
                ''
              )}
            </DazzedParagraph12>
          </Grid>
          <Grid item>
            <DazzedParagraph12 className={classes.createdAtAgo}>{moment(createdAt).fromNow()}</DazzedParagraph12>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CreatorInfo
