import Actions from 'actions'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

export default ({ isOpen, type = 'error', message }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    if (isOpen) {
      dispatch(
        Actions.showSnackbar({
          type,
          text: message
        })
      )
    }
  }, [dispatch, isOpen, message, t, type])
}
