import React from 'react'
import { PaymentIcon } from 'react-svg-credit-card-payment-icons'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  creditCardIcon: {
    width: 24,
    marginLeft: 10,
    marginTop: 4
  }
})

const CreditCardPaymentIcons = () => {
  const classes = useStyles()
  return (
    <div>
      {' '}
      {['mastercard', 'visa', 'amex', 'discover'].map(brand => (
        <PaymentIcon type={brand} id={brand} className={classes.creditCardIcon} />
      ))}
    </div>
  )
}

export default CreditCardPaymentIcons
