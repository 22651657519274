import React, { useCallback, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import GrinMenu from 'components/common/menu/GrinMenu'
import SearchDoctorTrigger from './SearchDoctorTrigger'
import { useDispatch, useSelector } from 'react-redux'
import Actions from 'actions'
import { useDebounce } from 'use-debounce'
import { scrollBar } from 'styles/theme'
import { trackEvent } from 'utils/analyticsUtils'
import SearchByDoctorMenuContent from './SearchByDoctorMenuContent'

const useStyles = makeStyles(theme => ({
  menu: {
    borderRadius: 10,
    maxHeight: '70%',
    overflow: 'auto',
    ...scrollBar
  }
}))

const MENU_MARGIN = 30
const SEARCH_DOCTOR_FILTER_MENU = 'search-doctor-filter-menu'

const SearchByDoctorV2 = ({ handleDoctorChange }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [searchFilter, setSearchFilter] = useState('')
  const [selectedDoctor, setSelectedDoctor] = useState(null)
  const [menuWidth, setMenuWidth] = useState(null)
  const [debouncedSearchFilter] = useDebounce(searchFilter, 300)

  const isLoadingDoctors = useSelector(state => state.appReducer.doctorSearchIsLoading)
  const doctorsResults = useSelector(state => state.appReducer.doctorSearchResults)

  const handleClose = useCallback(() => {
    setIsMenuOpen(false)
    setSearchFilter('')
  }, [])
  const handleOpen = useCallback(e => {
    const menuElement = document.getElementById('search-doctor-filter-menu')
    trackEvent('Doctors filter - Menu opened')
    setMenuWidth(menuElement.getBoundingClientRect().width - MENU_MARGIN)
    setIsMenuOpen(true)
  }, [])

  const handleShowAllPractices = useCallback(() => {
    trackEvent('Doctors filter - All practices clicked')
    setSearchFilter('')
    handleDoctorChange('')
    setSelectedDoctor(null)
    setIsMenuOpen(false)
  }, [handleDoctorChange])

  const handleDoctorSelected = useCallback(
    doctor => {
      trackEvent('Doctors filter - doctor selected', {
        doctorId: doctor.id,
        doctorName: doctor.name
      })
      setSelectedDoctor(doctor)
      setSearchFilter(doctor.name)
      handleDoctorChange(doctor.id)
      handleClose()
    },
    [handleClose, handleDoctorChange]
  )

  useEffect(() => {
    dispatch(Actions.requestSearchDoctor(debouncedSearchFilter))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchFilter])

  useEffect(() => {
    if (isMenuOpen) {
      dispatch(Actions.requestSearchDoctor())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMenuOpen])

  return (
    <GrinMenu
      id={SEARCH_DOCTOR_FILTER_MENU}
      open={isMenuOpen}
      onOpen={handleOpen}
      onClose={handleClose}
      transformOrigin={{ vertical: -45, horizontal: 0 }}
      className={classes.menu}
      triggerComponent={<SearchDoctorTrigger isOpen={isMenuOpen} practiceName={selectedDoctor?.practiceName} />}
      disableMenuFocus
    >
      <SearchByDoctorMenuContent
        width={menuWidth}
        searchFilter={searchFilter}
        setSearchFilter={setSearchFilter}
        onShowAllPractices={handleShowAllPractices}
        isLoadingDoctors={isLoadingDoctors}
        doctorsResults={doctorsResults}
        selectedDoctor={selectedDoctor}
        onDoctorSelected={handleDoctorSelected}
        onClose={handleClose}
      />
    </GrinMenu>
  )
}

export default SearchByDoctorV2
