import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import BaseModal from '../../common/modals/BaseModal'
import DazzedParagraph16 from '../../common/text/DazzedParagraph16'
import DazzedParagraph12 from '../../common/text/DazzedParagraph12'
import FormInput from '../../common/FormInput'
import { isValidEmail } from '../../../utils/generalUtils'
import BrightCheckbox from '../../common/inputs/BrightCheckbox'

const useStyles = makeStyles(theme => ({
  root: {
    width: 500,
    textAlign: 'center'
  },
  formContainer: {
    marginTop: 30
  },
  checkbox: {
    width: '100%'
  }
}))

const ChangeUserEmailModal = ({
  isOpen,
  onSubmit,
  onCancel,
  userFullname,
  username,
  currentEmail,
  isLoading = false,
  isDoctor = false
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [resetPassword, setResetPassword] = useState(false)
  const [sendWelcomeEmail, setSendWelcomeEmail] = useState(false)
  const [newEmail, setNewEmail] = useState('')
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const handleClose = useCallback(() => {
    setIsSubmitted(false)
    onCancel()
    setErrorMessage('')
    setResetPassword(false)
    setSendWelcomeEmail(false)
  }, [onCancel])

  const hasEmailChanged = useMemo(() => newEmail !== currentEmail, [currentEmail, newEmail])

  const handleSubmit = useCallback(() => {
    if (!isValidEmail(newEmail)) {
      setErrorMessage('Email is not valid')
      return
    } else {
      setErrorMessage('')
    }

    onSubmit({ newEmail, resetPassword, sendWelcomeEmail, username })
    setIsSubmitted(true)
  }, [newEmail, onSubmit, resetPassword, sendWelcomeEmail, username])

  useEffect(() => {
    setNewEmail(currentEmail)
  }, [currentEmail])

  useEffect(() => {
    if (isOpen && isSubmitted && !isLoading) {
      handleClose()
    }
  }, [handleClose, isLoading, isOpen, isSubmitted])

  return (
    <BaseModal
      open={isOpen}
      onPrimaryBtnClick={handleSubmit}
      onSecondaryBtnClick={handleClose}
      primaryLabel={t('general.confirm')}
      isPrimaryDisabled={!hasEmailChanged}
      secondaryLabel={t('general.cancel')}
      handleClose={handleClose}
      title={t('pages.opsDashboard.changeEmailModal.title')}
      className={classes.root}
      isLoading={isLoading}
    >
      <DazzedParagraph16 textAlign="center">
        {t('pages.opsDashboard.changeEmailModal.body', { name: userFullname })}
      </DazzedParagraph16>
      <DazzedParagraph12 textAlign="center">
        {t('pages.opsDashboard.changeEmailModal.currentEmail', { email: currentEmail })}
      </DazzedParagraph12>
      <div className={classes.formContainer}>
        <FormInput
          value={newEmail}
          setValue={email => setNewEmail(email.toLowerCase())}
          style={{ bright: true, thick: true }}
          placeholder={t('pages.opsDashboard.changeEmailModal.emailPlaceholder')}
          errorMessage={errorMessage}
        />
        {isDoctor && (
          <BrightCheckbox
            disabled={!hasEmailChanged}
            checked={resetPassword}
            onChange={event => setResetPassword(event.target.checked)}
            label={t('pages.opsDashboard.changeEmailModal.resetPassword')}
            className={classes.checkbox}
          />
        )}
        {isDoctor && (
          <BrightCheckbox
            disabled={!hasEmailChanged}
            checked={sendWelcomeEmail}
            onChange={event => setSendWelcomeEmail(event.target.checked)}
            label={t('pages.opsDashboard.changeEmailModal.sendWelcomeEmail')}
            className={classes.checkbox}
          />
        )}
      </div>
    </BaseModal>
  )
}

export default ChangeUserEmailModal
