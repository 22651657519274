import {
  catchError,
  concat,
  empty,
  filter,
  from,
  map,
  mapTo,
  mergeMap,
  of,
  pluck,
  switchMap,
  withLatestFrom
} from 'rxjs'
import Actions from '../actions'
import { ofType } from 'redux-observable'
import { API, graphqlOperation } from 'aws-amplify'
import { getParsedIdToken, isUserOfAnyAdminRole, isUserOfRole } from 'utils/authUtils'
import { Roles } from 'consts/authConsts'
import {
  getDoctorWithFFs,
  getTemplatesByTypeAndByDoctorId,
  practiceGuidelinesByDoctorIdSorted,
  searchDoctorSearchModelsForHI,
  tagsByDoctorId
} from 'graphql/customQueries'
import i18n from '../resources/locales/i18n'
import { TEMPLATES_TYPES } from 'consts/templatesConsts'

export const fetchHiAssignedDoctorsTrigger = action$ =>
  action$.pipe(
    ofType(Actions.SIGNIN_RECEIVED, Actions.DOCTOR_DETAILS_REQUESTED),
    filter(() => isUserOfRole([Roles.HI, Roles.GI])),
    map(() => getParsedIdToken()),
    map(idToken => JSON.parse(idToken.extraData || '{}')),
    map(extraData => Actions.hiFetchAssignedDoctors({ hiGroup: extraData.hiGroup }))
  )

export const fetchHiAssignedDoctorsEpic = action$ =>
  action$.pipe(
    ofType(Actions.HI_FETCH_ASSIGNED_DOCTORS),
    pluck('payload'),
    switchMap(({ hiGroup }) =>
      from(
        API.graphql(
          graphqlOperation(searchDoctorSearchModelsForHI, {
            limit: 500,
            filter: { hiGroup: { eq: hiGroup } }
          })
        )
      ).pipe(
        map(res => res.data.searchDoctorSearchModels.items),
        mergeMap(data => of(Actions.hiFetchAssignedDoctorsReceived(data))),
        catchError(err => of(Actions.hiFetchAssignedDoctorsFailed(err)))
      )
    )
  )

export const fetchAssignedDoctorsFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.HI_FETCH_ASSIGNED_DOCTORS_FAILED),
    map(() =>
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('errors.failedToLoadHIDoctors')
      })
    )
  )

export const fetchHiPracticeContextTrigger = (action$, state$) =>
  action$.pipe(
    ofType(Actions.PATIENT_RECEIVED),
    filter(() => isUserOfAnyAdminRole()),
    withLatestFrom(state$),
    map(([action, state]) => {
      const patient = action.payload.patient
      const doctor = patient?.doctor
      return {
        doctorId: patient?.patientDoctorId,
        isHI: isUserOfRole([Roles.HI]),
        isAdmin: isUserOfRole([Roles.Admin]),
        isDSO: isUserOfRole([Roles.DsoSupport]),
        grinPlan: state.billingReducer.grinPlans?.[doctor?.user.grinPlanKey]
      }
    }),
    mergeMap(({ doctorId, isHI, isAdmin, isDSO, grinPlan }) => {
      return concat(
        of(Actions.mpSetContextPractice({ doctorId, grinPlan })),
        of(Actions.mpFetchCustomTagsByDoctor({ doctorId })),
        isHI || isAdmin ? of(Actions.hiFetchPracticeGuidelines({ doctorId })) : empty(),
        isHI ? of(Actions.hiFetchPracticeSavedReplies({ doctorId })) : empty(),
        isHI || isDSO || isAdmin ? of(Actions.mpFetchPracticeFFs({ doctorId })) : empty()
      )
    })
  )

export const fetchHiPracticeGuidelinesEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.HI_FETCH_PRACTICE_GUIDELINES),
    pluck('payload'),
    switchMap(({ doctorId }) =>
      concat(
        from(
          API.graphql(
            graphqlOperation(practiceGuidelinesByDoctorIdSorted, {
              doctorId,
              sortDirection: 'DESC',
              limit: 1
            })
          )
        ).pipe(
          map(res => res.data.practiceGuidelinesByDoctorIdSorted?.items[0]), // Use the latest guidelines
          mergeMap(practiceGuidelines => of(Actions.hiFetchPracticeGuidelinesReceived(practiceGuidelines))),
          catchError(error => of(Actions.hiFetchPracticeGuidelinesFailed(error)))
        )
      )
    )
  )

export const fetchPracticeSavedReplies = (action$, state$) =>
  action$.pipe(
    ofType(Actions.HI_FETCH_PRACTICE_SAVED_REPLIES),
    withLatestFrom(state$),
    map(([action, state]) => ({
      doctorId: action.payload.doctorId,
      savedRepliesByPractice: state.multiPracticeReducer.savedRepliesByPractice
    })),
    switchMap(({ doctorId, savedRepliesByPractice }) =>
      from(
        savedRepliesByPractice[doctorId]
          ? Promise.resolve(savedRepliesByPractice[doctorId])
          : API.graphql(
              graphqlOperation(getTemplatesByTypeAndByDoctorId, {
                type: TEMPLATES_TYPES.CUSTOM,
                doctorId: { eq: doctorId },
                limit: 1000
              })
            ).then(res => res.data?.templatesByTypeAndByDoctorId?.items)
      ).pipe(
        mergeMap(savedReplies => of(Actions.hiFetchPracticeSavedRepliesReceived({ doctorId, savedReplies }))),
        catchError(err => of(Actions.hiFetchPracticeSavedRepliesFailed(err)))
      )
    )
  )

export const mpFetchCustomTagsByDoctorEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.MP_FETCH_CUSTOM_TAGS_BY_DOCTOR),
    withLatestFrom(state$),
    map(([action, state]) => ({
      doctorId: action.payload.doctorId,
      customTagsByPractice: state.multiPracticeReducer.customTagsByPractice
    })),
    switchMap(({ doctorId, customTagsByPractice }) =>
      from(
        customTagsByPractice[doctorId]
          ? Promise.resolve(customTagsByPractice[doctorId])
          : API.graphql(graphqlOperation(tagsByDoctorId, { tagDoctorId: doctorId })).then(res =>
              res.data?.tagsByDoctorId.items?.filter(tag => !tag._deleted)
            )
      ).pipe(
        mergeMap(tags => of(Actions.mpFetchCustomTagsByDoctorReceived({ doctorId, customTags: tags }))),
        catchError(err => of(Actions.mpFetchCustomTagsByDoctorFailed(err)))
      )
    )
  )

export const mpFetchCustomTagsByDoctorFailed = action$ =>
  action$.pipe(
    ofType(Actions.MP_FETCH_CUSTOM_TAGS_BY_DOCTOR_FAILED),
    mapTo(
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('messages.tags.failedToFetchTags')
      })
    )
  )

export const mpFetchPracticeFFsEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.MP_FETCH_PRACTICE_FFS),
    pluck('payload'),
    switchMap(({ doctorId }) =>
      concat(
        from(
          API.graphql(
            graphqlOperation(getDoctorWithFFs, {
              id: doctorId
            })
          )
        ).pipe(
          map(res => JSON.parse(res.data.getDoctor.user?.featureFlags?.flags || '{}')),
          mergeMap(doctorWithFFs => of(Actions.mpFetchPracticeFFsReceived(doctorWithFFs))),
          catchError(error => of(Actions.mpFetchPracticeFFsFailed(error)))
        )
      )
    )
  )

export const mpFetchDsoPracticesEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.MP_FETCH_DSO_PRACTICES),
    withLatestFrom(state$),
    map(([action, state]) => ({
      dsoDoctorId: state.profileReducer.doctor.id
    })),
    switchMap(({ dsoDoctorId }) =>
      concat(
        from(API.get('grinServerlessApi', `/accounts/v3/dso/practices/${dsoDoctorId}`)).pipe(
          mergeMap(practicesData => of(Actions.mpFetchDsoPracticesReceived(practicesData))),
          catchError(error => of(Actions.mpFetchDsoPracticesFailed(error)))
        )
      )
    )
  )

export const dsoInviteDoctorEpic = action$ =>
  action$.pipe(
    ofType(Actions.DSO_INVITE_DOCTOR),
    pluck('payload'),
    switchMap(payload =>
      from(
        API.post('grinServerlessApi', '/accounts/v3/dso/practices', {
          body: payload
        })
      ).pipe(
        mergeMap(res =>
          concat(
            of(Actions.dsoInviteDoctorReceived(res?.data)),
            of(
              Actions.showSnackbar({
                type: 'success',
                text: i18n.t('pages.accountSettings.dsoMembers.inviteDoctor.modal.doctorInvitedSuccessfully')
              })
            )
          )
        ),
        catchError(err => of(Actions.dsoInviteDoctorFailed(err)))
      )
    )
  )
