import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Actions from 'actions'
import PrimaryButton from 'components/common/buttons/PrimaryButton'
import FormInput from 'components/common/FormInput'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import { Roles } from 'consts/authConsts'
import { useAllFeatureFlags } from 'hooks/useAllFeatureFlags'
import useFeatureFlags from 'hooks/useFeatureFlags'
import useRolePermissions from 'hooks/useRolePermissions'
import _ from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { trackEvent } from 'utils/analyticsUtils'
import { isUserOfRole } from 'utils/authUtils'
import { useStyles as useGlobalCardStyles } from './EditDialogStyle'
import FeatureItem from './FeatureItem'
import ScanSummarySettings from './ScanSummarySettings'

export const useStyles = makeStyles({
  leftContainer: {
    padding: 20,
    paddingRight: 10,
    borderBottomRightRadius: 30,
    height: '100%'
  },
  rightContainer: {
    padding: 20,
    paddingRight: 10,
    backgroundColor: 'var(--bg-color-15)',
    height: '100%'
  },
  featuresListContainer: {
    overflowY: 'scroll',
    paddingTop: 12,
    paddingRight: 10,
    '&::-webkit-scrollbar': {
      width: 3,
      background: 'none'
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'var(--text-color-11)'
    }
  },
  title: {
    fontWeight: 600,
    marginBottom: 20
  },
  fullHeight: {
    height: '100%'
  },
  fullWidth: {
    width: '100%'
  },
  featureFlagsContainer: {
    width: '100%',
    height: 'max(65%, 400px)',
    maxHeight: '80%',
    overflowY: 'scroll',
    overflowX: 'hidden'
  },
  searchFeatures: {
    padding: '0 20px !important',
    margin: 10,
    borderRadius: 30,
    width: '350px !important',
    borderColor: 'var(--bg-color-7) !important',
    boxShadow: '0px 0px 8px -3px var(--bg-color-7)'
  }
})

const ENABLE_FEATURE = 'ENABLE_FEATURE'
const DISABLE_FEATURE = 'DISABLE_FEATURE'

const FeaturesTab = () => {
  const classes = useStyles()
  const globalCardStyles = useGlobalCardStyles()
  const { t } = useTranslation()
  const { permissions } = useRolePermissions()
  const dispatch = useDispatch()
  const [featuresSearch, setFeaturesSearch] = useState('')

  const user = useSelector(state => state.supportReducer.doctorsDashboard.selectedDoctor.user)
  const accountOwnerUser = useSelector(
    state => state.supportReducer.doctorsDashboard.selectedDoctor?.accountOwner?.user
  )

  const accountOwner = useSelector(state =>
    state.supportReducer.doctorsDashboard.selectedDoctor.accountOwnerId
      ? state.supportReducer.doctorsDashboard.selectedDoctor.accountOwner
      : state.supportReducer.doctorsDashboard.selectedDoctor || {}
  )

  const originalAppSettings = useMemo(() => JSON.parse(user.appSettings || '{}'), [user])
  const userFeatureFlags = useFeatureFlags(user, accountOwnerUser)
  const { doctorFeatureFlags } = useAllFeatureFlags()
  const mappedFeatureFlags = useMemo(
    () =>
      _.sortBy(
        doctorFeatureFlags.map(ff => ({
          ...ff,
          isDisabled:
            !permissions.opsDashboardEditDoctor.featuresTab.write || !isUserOfRole(ff.availableFor || [Roles.Admin])
        })),
        'isDisabled'
      ),
    [permissions, doctorFeatureFlags]
  )

  const [updatedFeatureFlags, setUpdatedFeatureFlags] = useState(null)
  const [scanSummarySettings, setScanSummarySettings] = useState({})

  const featureFlags = useMemo(() => updatedFeatureFlags || userFeatureFlags, [updatedFeatureFlags, userFeatureFlags])

  const availableFeatureFlags = useMemo(
    () =>
      mappedFeatureFlags
        .filter(feature => !featureFlags[feature.key])
        .filter(
          feature =>
            feature.label?.toLowerCase().includes(featuresSearch.toLowerCase()) ||
            feature.key.toLowerCase().includes(featuresSearch.toLowerCase())
        ),
    [mappedFeatureFlags, featureFlags, featuresSearch]
  )

  const enabledFeatureFlags = useMemo(
    () =>
      mappedFeatureFlags
        .filter(feature => featureFlags[feature.key])
        .filter(
          feature =>
            feature.label.toLowerCase().includes(featuresSearch.toLowerCase()) ||
            feature.key.toLowerCase().includes(featuresSearch.toLowerCase())
        ),
    [mappedFeatureFlags, featureFlags, featuresSearch]
  )

  const handleClick = (feature, action) => {
    if (action === ENABLE_FEATURE) {
      const updatedFlags = {
        [feature.key]: true
      }

      setUpdatedFeatureFlags({
        ...featureFlags,
        ...updatedFlags
      })
    } else {
      const { [feature.key]: remove, ...restFeatureFlags } = featureFlags
      setUpdatedFeatureFlags(restFeatureFlags)
    }
  }

  const handleSave = useCallback(() => {
    trackEvent('Support - Edit doctor FeatureFlags - saved clicked', {
      featureFlags,
      practiceName: accountOwner.clinic?.practiceName,
      userId: user.id
    })
    dispatch(
      Actions.supportUpdateDoctorFF({
        id: user.featureFlags.id,
        _version: user.featureFlags._version,
        flags: JSON.stringify(featureFlags)
      })
    )

    if (permissions.opsDashboardEditScanSummarySettings) {
      dispatch(
        Actions.supportUpdateAppSettings({
          userId: user.id,
          changes: {
            scanSummarySettings
          }
        })
      )
    }
  }, [user, featureFlags, dispatch, permissions, scanSummarySettings, accountOwner])

  useEffect(() => {
    setScanSummarySettings(originalAppSettings.scanSummarySettings)
  }, [originalAppSettings])

  console.log('avail', availableFeatureFlags, enabledFeatureFlags)

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      className={globalCardStyles.tabWithButtonContainer}
    >
      <Grid item className={classes.featureFlagsContainer}>
        <Grid>
          <FormInput
            value={featuresSearch}
            setValue={setFeaturesSearch}
            style={{ bright: true, thick: true }}
            inputWrapperClassName={classes.searchFeatures}
            placeholder={t('dialogs.doctorCardEditor.searchFeatureFlags')}
          />
        </Grid>
        <Grid container spacing={4} className={classes.fullHeight}>
          <Grid item xs={6}>
            <div className={classes.leftContainer}>
              <DazzedParagraph16 className={classes.title} strong>
                {t('dialogs.doctorCardEditor.allExistingFeatures')}
              </DazzedParagraph16>
              <div className={classes.featuresListContainer}>
                {availableFeatureFlags.map(feature => (
                  <FeatureItem
                    key={feature.key}
                    feature={feature}
                    action={ENABLE_FEATURE}
                    onClick={handleClick}
                    disabled={feature.isDisabled}
                  />
                ))}
              </div>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.rightContainer}>
              <DazzedParagraph16 className={classes.title} strong>
                {t('dialogs.doctorCardEditor.enabledForThisAccount')}
              </DazzedParagraph16>
              <div className={classes.featuresListContainer}>
                {enabledFeatureFlags.map(feature => (
                  <FeatureItem
                    key={feature.key}
                    feature={feature}
                    action={DISABLE_FEATURE}
                    onClick={handleClick}
                    disabled={feature.isDisabled}
                  />
                ))}
              </div>
            </div>
          </Grid>
          <Grid item className={classes.fullWidth}>
            <ScanSummarySettings
              enabled={permissions.opsDashboardEditScanSummarySettings}
              settings={scanSummarySettings}
              onChange={settings => setScanSummarySettings(settings)}
            />
          </Grid>
        </Grid>
      </Grid>

      {!!permissions.opsDashboardEditDoctor.featuresTab.write && (
        <Grid item>
          <PrimaryButton label={t('general.save')} onClick={handleSave} />
        </Grid>
      )}
    </Grid>
  )
}

export default FeaturesTab
