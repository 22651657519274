import Actions from 'actions'
import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export default ({
  currentViewerMode,
  setCurrentViewerMode,
  currentPose,
  setCurrentPose,
  currentViewerSettings,
  setCurrentViewerSettings
}) => {
  const dispatch = useDispatch()

  const { linkEnabled: isLinked } = useSelector(state => state.treatmentReducer.compareScans)
  const { syncedViewerMode, syncedPose, syncedViewerSettings } = useSelector(
    state => state.txTrackerReducer.compareStls
  )

  const viewerMode = useMemo(
    () => (isLinked ? syncedViewerMode : currentViewerMode),
    [currentViewerMode, isLinked, syncedViewerMode]
  )

  const pose = useMemo(() => (isLinked ? syncedPose : currentPose), [currentPose, isLinked, syncedPose])

  const viewerSettings = useMemo(
    () => (isLinked ? syncedViewerSettings : currentViewerSettings),
    [currentViewerSettings, isLinked, syncedViewerSettings]
  )

  const handleSetViewerMode = useCallback(
    newViewerMode =>
      isLinked
        ? dispatch(Actions.txTrackerCompareUpdateViewerMode(newViewerMode))
        : setCurrentViewerMode(newViewerMode),
    [dispatch, isLinked, setCurrentViewerMode]
  )

  const handleSetPose = useCallback(
    newPose => (isLinked ? dispatch(Actions.txTrackerCompareSetPose(newPose)) : setCurrentPose(newPose)),
    [dispatch, isLinked, setCurrentPose]
  )

  const handleSetViewerSettings = useCallback(
    newViewerSettings => {
      isLinked
        ? dispatch(Actions.txTrackerCompareUpdateViewerSettings(newViewerSettings))
        : setCurrentViewerSettings(newViewerSettings)
    },
    [dispatch, isLinked, setCurrentViewerSettings]
  )

  // Sync the pose, viewer mode and layers visibility for each viewer state
  // so it will stay the same once unlinked
  useEffect(() => {
    if (syncedViewerMode) {
      setCurrentViewerMode(syncedViewerMode)
    }
  }, [setCurrentViewerMode, syncedViewerMode])

  useEffect(() => {
    if (syncedPose) {
      setCurrentPose(syncedPose)
    }
  }, [setCurrentPose, syncedPose])

  useEffect(() => {
    if (syncedViewerSettings) {
      setCurrentViewerSettings(viewerSettings)
    }
  }, [viewerSettings, setCurrentPose, setCurrentViewerSettings, syncedViewerSettings, syncedPose])

  return {
    handleSetViewerMode,
    handleSetPose,
    handleSetViewerSettings,
    pose,
    viewerMode,
    viewerSettings
  }
}
