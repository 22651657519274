import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Actions from 'actions'
import LinkButton from 'components/common/buttons/LinkButton'
import DazzedHeading36 from 'components/common/text/DazzedHeading36'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import { AsyncStatus } from 'consts'
import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import ToothLoader from 'resources/webapp-overlay-loader.gif'
import { trackEvent } from 'utils/analyticsUtils'
import VerificationCodeInput from '../common/VerificationCodeInput'
import Countdown from '../common/CountdownV2'
import useReferralLogin from './useReferralLogin'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 104
  },
  title: {
    fontSize: 32,
    fontWeight: 500
  },
  resendButton: {
    fontSize: 14
  }
}))

const ReferralLogin = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { globalLoadingState } = useSelector(state => state.referralsReducer)
  const { resendLoadingState, verifyLoadingState, verificationCodeError } = useSelector(
    state => state.referralsReducer.authorization
  )
  const { otp, getToken, completeAuth, requestVerificationCode, verifyCode } = useReferralLogin()

  const handleResend = useCallback(() => {
    trackEvent('Referrals - resend code clicked')
    requestVerificationCode()
  }, [requestVerificationCode])

  const handleVerifyCode = useCallback(
    password => {
      trackEvent('Referrals - code inserted')
      verifyCode({ password })
    },
    [verifyCode]
  )

  useEffect(() => {
    if (!completeAuth()) {
      requestVerificationCode()
    }
  }, [completeAuth, requestVerificationCode])

  useEffect(() => {
    const token = getToken()
    if (token && token.isExpired) {
      dispatch(Actions.referralsToggleSessionExpiredModal(true))
    }
  }, [dispatch, getToken])

  return (
    <Grid container direction="column" alignItems="center" spacing={4} className={classes.root}>
      {globalLoadingState === AsyncStatus.Loading ? (
        <Grid item>
          <img src={ToothLoader} alt="Loading" />
        </Grid>
      ) : (
        <>
          <Grid item>
            <DazzedHeading36 className={classes.title}>{t('pages.referrals.login.title')}</DazzedHeading36>
          </Grid>
          <Grid item>
            <DazzedParagraph16 strong textAlign="center">
              {t('pages.referrals.login.codeSent', { destination: otp.destination })}
            </DazzedParagraph16>
            <DazzedParagraph16 strong textAlign="center">
              {t('pages.referrals.login.pleaseEnterPasswordBelow')}
            </DazzedParagraph16>
          </Grid>
          <Grid item>
            <VerificationCodeInput
              onVerifyCode={handleVerifyCode}
              verifyLoadingState={verifyLoadingState}
              resendLoadingState={resendLoadingState}
              verificationCodeError={verificationCodeError}
            />
          </Grid>
          <Grid item>
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <DazzedParagraph14 strong>{t('pages.referrals.login.didntGetIt')}</DazzedParagraph14>
              </Grid>
              <Grid item>
                <Countdown
                  targetTime={new Date(otp.cooldownTime)}
                  renderer={({ completed, seconds }) =>
                    completed ? (
                      <LinkButton
                        label={t('pages.referrals.login.resendPassword')}
                        onClick={handleResend}
                        labelClassName={classes.resendButton}
                        isLoading={resendLoadingState === AsyncStatus.Loading}
                        loaderSize={14}
                        disabled={verifyLoadingState === AsyncStatus.Loading}
                      />
                    ) : (
                      <DazzedParagraph14 strong>{t('pages.referrals.login.resendIn', { seconds })}</DazzedParagraph14>
                    )
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default ReferralLogin
