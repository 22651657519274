import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import BaseModal from 'components/common/modals/BaseModal'
import NegativeFeedback from '../../../ScanSummary/NegativeFeedback'
import { ScanSummaryRatingOptions } from 'consts/scanSummaryConsts'

const useStyles = makeStyles(theme => ({
  scanSummaryFeedbackModalRoot: {
    maxWidth: 1200,
    width: 'auto!important'
  }
}))

const ScanSummaryFeedbackModal = ({ images, onSubmit, onClose, isOpen }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const handleSubmitReview = useCallback(
    (isPositive, feedbackData) => {
      onSubmit({
        ...feedbackData,
        feedback: ScanSummaryRatingOptions.Negative
      })
    },
    [onSubmit]
  )

  return (
    <BaseModal
      open={isOpen}
      handleClose={onClose}
      withCloseIcon
      className={classes.scanSummaryFeedbackModalRoot}
      title={t('dialogs.scanSummary.negativeFeedback.title')}
      variant="alert"
    >
      <NegativeFeedback images={images} onClose={onClose} onSubmitReview={handleSubmitReview} />
    </BaseModal>
  )
}

export default ScanSummaryFeedbackModal
