import React from 'react'
import { makeStyles } from '@material-ui/core'
import CategoryItems from './CategoryItems'
import GuidelineCategory from './GuidelineCategory'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  root: {
    padding: 10,
    overflowY: 'auto'
  },
  lastUpdated: {
    paddingBottom: 15,
    color: 'var(--text-color-11)'
  },
  categoriesContainer: {
    display: 'flex',
    flexDirection: 'column'
  }
}))

const Categories = ({ categories, categoriesData, actions, generalNote }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.categoriesContainer}>
      {categories.map((category, index) => (
        <GuidelineCategory
          icon={category.icon}
          title={category.title}
          isFirstCategory={index === 0}
          isLastCategory={index === categories.length - 1 && !generalNote}
        >
          <CategoryItems
            categoryComponent={category.component}
            items={categoriesData?.[category.key]?.items}
            actions={actions}
            note={categoriesData?.[category.key]?.note}
          />
        </GuidelineCategory>
      ))}
      {generalNote && (
        <GuidelineCategory isCollapsible isLastCategory title={t('dialogs.hiPatientGuidelinesViewer.generalNote')}>
          {generalNote}
        </GuidelineCategory>
      )}
    </div>
  )
}

export default Categories
