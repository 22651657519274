import React, { useCallback, useMemo, useState } from 'react'
import { APPLIANCE_SETS_MAX_NUMBER, APPLIANCE_SETS_MIN_NUMBER } from 'consts/treatmentConsts'
import { Grid, makeStyles } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { useTranslation } from 'react-i18next'
import { conditionTypes } from 'consts/automatedActionConsts'
import ErrorMessage from 'components/common/text/ErrorMessage'
import { InfoTransparent } from 'components/common/icons'
import Tooltip from 'components/common/Tooltip'

const useStyles = makeStyles(({ errorMessage }) => ({
  input: {
    outline: 'none',
    fontSize: 16,
    textAlign: 'center',
    height: 32,
    width: 80,
    border: 'none',
    borderBottom: ({ errorMessage }) => (errorMessage ? '1px solid red' : '1px solid var(--border-color-11)'),
    paddingLeft: 14
  }
}))

const RemainingAlignersCondition = ({ onChange, params, errors }) => {
  const errorMessage = useMemo(() => errors.conditions?.[conditionTypes.RemainingAligners], [errors])
  const classes = useStyles({ errorMessage })
  const { t } = useTranslation()

  const [remainingAligners, setRemainingAligners] = useState(params?.remainingAligners || '')

  const handleRemainingAlignersChange = useCallback(
    newRemainingAligners => {
      onChange({
        type: conditionTypes.RemainingAligners,
        params: {
          remainingAligners: newRemainingAligners
        }
      })
      setRemainingAligners(newRemainingAligners)
    },
    [onChange]
  )

  return (
    <Grid container direction="column">
      <Grid item>
        <Grid container alignItems="center">
          <Grid item>
            <DazzedParagraph14 strong>
              {t('pages.accountSettings.automations.editorModal.enterRemainingAlignersNumber')}
            </DazzedParagraph14>
          </Grid>
          <Grid item>
            <input
              value={remainingAligners}
              type="number"
              inputMode="numeric"
              pattern="[0-9]*"
              placeholder={t('pages.accountSettings.automations.editorModal.enterAlignerNumberPlaceholder')}
              className={`${classes.input} ${errorMessage ? classes.errorMessageInput : ''}`}
              onChange={({ target }) => handleRemainingAlignersChange(target.value)}
              min={APPLIANCE_SETS_MIN_NUMBER}
              max={APPLIANCE_SETS_MAX_NUMBER}
            />
          </Grid>
          <Grid item>
            <Tooltip
              value={t('pages.accountSettings.automations.editorModal.remainingAlignersTooltip')}
              variant="dark"
              placement="right"
              arrow
            >
              <InfoTransparent />
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>{errorMessage && <ErrorMessage text={errorMessage} active={!!errorMessage} />}</Grid>
    </Grid>
  )
}
export default RemainingAlignersCondition
