import { AsyncStatus } from 'consts'
import useFeatureFlags from 'hooks/useFeatureFlags'
import { useSelector } from 'react-redux'
import { useFetchNotesQuery } from 'store/modules/PatientNotes'

export default () => {
  const { grinDesignRefresh: grinDesignRefreshFF } = useFeatureFlags()

  const { user } = useSelector(state => state.patientsReducer.patient)
  const { data: legacyNotes, status } = useSelector(state => state.patientsReducer.notes)
  const { data: rtkNotes, isLoading } = useFetchNotesQuery(
    { patientGrinUserId: user?.id },
    { skip: !user?.id && !grinDesignRefreshFF }
  )

  return {
    notes: grinDesignRefreshFF ? rtkNotes : legacyNotes,
    status: isLoading || status === AsyncStatus.Loading ? AsyncStatus.Loading : status
  }
}
