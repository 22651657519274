import React from 'react'
import Note from './PracticeGuidelines/Note'

const CategoryItems = ({ categoryComponent, items, note }) => {
  return (
    <div>
      {React.createElement(categoryComponent, {
        items
      })}
      {note && (
        <div style={{ width: '100%' }}>
          <Note note={note} />
        </div>
      )}
    </div>
  )
}

export default CategoryItems
