import React, { useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import ScanFeedCard from './ScanFeedCard'
import ImagesViewer from 'components/common/ImagesViewer'
import { Grid } from '@material-ui/core'
import ScanDateLabel from 'components/common/ScanDateLabel'
import LinkButton from 'components/common/buttons/LinkButton'
import DownloadIcon from 'components/common/icons/Download3'
import { trackEvent } from 'utils/analyticsUtils'
import { downloadFileFromUrl } from 'utils/fileUtils'
import moment from 'moment'
import useOpenPatientCard from 'hooks/useOpenPatientCard'
import { PATIENT_CARD_TABS } from 'consts/patientCard'

const useStyles = makeStyles(theme => ({
  compositeImageContainer: {
    borderRadius: 4,
    boxShadow: '0px 4.29px 4.29px 0px rgba(0, 0, 0, 0.25)',
    marginBottom: 16
  },
  compositeImage: {
    width: '100%',
    cursor: 'pointer'
  }
}))

const OralRecordsCard = ({ compositeImageSrc = '', date, patientName = '', analyticsPayload = {} }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const openPatientCard = useOpenPatientCard()

  const [isImageViewerOpen, setIsImageViewerOpen] = useState(false)

  const handleOpenRecordsTab = useCallback(() => {
    trackEvent('Scan Feed - Uploaded Records - open records tab clicked', analyticsPayload)
    openPatientCard({
      tabIndex: PATIENT_CARD_TABS.records.index
    })
  }, [analyticsPayload, openPatientCard])

  const handleDownload = useCallback(() => {
    trackEvent('Scan Feed - Uploaded Records - download composite records clicked', analyticsPayload)
    downloadFileFromUrl(compositeImageSrc, `Oral Records Composite - ${patientName} ${moment(date).format('LLL')}`)
  }, [analyticsPayload, compositeImageSrc, date, patientName])

  return (
    <ScanFeedCard
      title={t('pages.patients.selectedPatient.timelineV2.scanFeed.oralRecords.title')}
      headerButton={
        <LinkButton
          label={t('pages.patients.selectedPatient.timelineV2.scanFeed.oralRecords.openRecordsTabButton')}
          onClick={handleOpenRecordsTab}
        />
      }
    >
      <div className={classes.compositeImageContainer}>
        <img
          src={compositeImageSrc}
          alt="Oral Records Composite"
          className={classes.compositeImage}
          onClick={() => setIsImageViewerOpen(true)}
        />
        <ImagesViewer
          imageUrls={[compositeImageSrc]}
          isVisible={isImageViewerOpen}
          onClose={() => setIsImageViewerOpen(false)}
        />
      </div>
      <Grid container justifyContent="space-between">
        <Grid item style={{ lineHeight: 0.8 }}>
          <LinkButton
            icon={<DownloadIcon height={13} width={13} />}
            label={t('pages.patients.selectedPatient.timelineV2.scanFeed.oralRecords.downloadRecordsButton')}
            onClick={handleDownload}
          />
        </Grid>
        <Grid item>
          <ScanDateLabel timestamp={date} withDaysAgo />
        </Grid>
      </Grid>
    </ScanFeedCard>
  )
}

export default OralRecordsCard
