import { makeStyles } from '@material-ui/styles'
import NotificationBubbles from 'components/NotificationCenter/Icons/NotificationBubbles'
import React from 'react'

const useStyles = makeStyles(theme => ({
  bubbleBackground: {
    backgroundColor: 'white',
    borderRadius: '100%',
    height: 24,
    width: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 3,
    borderStyle: 'solid',
    borderColor: ({ isUnread }) =>
      isUnread ? 'var(--notification-center-unread-bgcolor)' : 'var(--notification-center-read-bgcolor)'
  }
}))

export default ({ notificationType, isUnread }) => {
  const classes = useStyles({ isUnread })
  const icon = NotificationBubbles[notificationType]
  return !icon ? <></> : <div className={classes.bubbleBackground}>{React.createElement(icon)}</div>
}
