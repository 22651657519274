import * as React from 'react'

const UpperArch = ({ isSelected }) => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="24" cy="24" r="24" fill={isSelected ? '#B9C7FF' : '#DEEBFA'} />
    <path
      d="M24.1084 9.69336C34.9256 9.69336 39.23 20.7872 40.9241 28.695C41.6905 32.2724 38.1004 34.8801 34.6231 33.7432C31.0682 32.5809 26.967 31.4885 24.1084 31.4885C21.2252 31.4885 17.0292 32.5998 13.4023 33.7734C9.91815 34.9006 6.34486 32.2649 7.13994 28.6903C8.89882 20.7828 13.2934 9.69336 24.1084 9.69336Z"
      fill={isSelected ? '#4151E6' : '#8FB1DA'}
    />
    <path
      d="M19.6055 12.6699C19.7873 13.61 20.6829 14.891 22.1226 14.5268C23.5395 14.1682 23.81 12.6702 23.6282 11.7301C23.4464 10.7901 22.4973 10.749 21.3865 11.0085C20.2756 11.268 19.4237 11.7299 19.6055 12.6699Z"
      fill="white"
    />
    <path
      d="M15.8522 15.3455C16.38 16.2692 17.65 17.3464 18.7288 16.565C19.7906 15.796 19.4256 14.1668 18.8977 13.2431C18.3699 12.3193 17.5469 12.5429 16.7059 13.1235C15.8649 13.7041 15.3243 14.4218 15.8522 15.3455Z"
      fill="white"
    />
    <path
      d="M16.4032 17.379C16.1674 16.8383 15.6281 16.5131 15.0396 16.4719L14.1606 16.4103C13.4416 16.3599 12.7795 16.853 12.7651 17.5736C12.7604 17.811 12.7719 18.0485 12.8127 18.2518C12.8831 18.6023 13.0703 18.9612 13.2637 19.2593C13.5854 19.7555 14.0681 20.128 14.6293 20.3144C14.8711 20.3947 15.1289 20.4571 15.3463 20.4571C16.2735 20.4571 16.9047 19.5369 16.7703 18.6196C16.7513 18.4902 16.7293 18.3656 16.7036 18.2518C16.6383 17.9623 16.5229 17.6536 16.4032 17.379Z"
      fill="white"
    />
    <path
      d="M12.978 20.4669C12.1256 19.8673 11.005 20.1763 10.5898 21.1255L9.97649 22.5277C9.57191 23.4526 10.0149 24.5836 10.9348 24.9742L11.726 25.3101C12.6442 25.6999 13.6665 25.3928 14.2143 24.5625C15.0065 23.3618 14.6668 21.6549 13.4678 20.8115L12.978 20.4669Z"
      fill="white"
    />
    <path
      d="M10.4709 26.2018C9.41862 25.9834 8.42893 26.7309 8.35301 27.8016L8.22137 29.6578C8.14665 30.7114 8.99073 31.6189 10.0442 31.6176L11.1065 31.6163C12.1043 31.6151 12.9784 30.9757 13.28 30.0265L13.3292 29.8713C13.8165 28.3372 12.8491 26.6955 11.264 26.3665L10.4709 26.2018Z"
      fill="white"
    />
    <path
      d="M28.5172 12.6699C28.3354 13.61 27.4398 14.891 26.0002 14.5268C24.5832 14.1682 24.3127 12.6702 24.4945 11.7301C24.6763 10.7901 25.6254 10.749 26.7363 11.0085C27.8471 11.268 28.699 11.7299 28.5172 12.6699Z"
      fill="white"
    />
    <path
      d="M32.2706 15.3455C31.7427 16.2692 30.4727 17.3464 29.3939 16.565C28.3321 15.796 28.6971 14.1668 29.225 13.2431C29.7529 12.3193 30.5758 12.5429 31.4169 13.1235C32.2579 13.7041 32.7984 14.4218 32.2706 15.3455Z"
      fill="white"
    />
    <path
      d="M31.7195 17.379C31.9553 16.8383 32.4946 16.5131 33.0831 16.4719L33.9622 16.4103C34.6812 16.3599 35.3432 16.853 35.3576 17.5736C35.3624 17.811 35.3509 18.0485 35.31 18.2518C35.2396 18.6023 35.0524 18.9612 34.8591 19.2593C34.5374 19.7555 34.0546 20.128 33.4934 20.3144C33.2516 20.3947 32.9938 20.4571 32.7764 20.4571C31.8493 20.4571 31.2181 19.5369 31.3524 18.6196C31.3714 18.4902 31.3935 18.3656 31.4191 18.2518C31.4844 17.9623 31.5998 17.6536 31.7195 17.379Z"
      fill="white"
    />
    <path
      d="M35.1442 20.4669C35.9967 19.8673 37.1172 20.1763 37.5324 21.1255L38.1458 22.5277C38.5504 23.4526 38.1073 24.5836 37.1875 24.9742L36.3962 25.3101C35.478 25.6999 34.4558 25.3928 33.9079 24.5625C33.1157 23.3618 33.4554 21.6549 34.6544 20.8115L35.1442 20.4669Z"
      fill="white"
    />
    <path
      d="M37.6523 26.2018C38.7046 25.9834 39.6943 26.7309 39.7702 27.8016L39.9019 29.6578C39.9766 30.7114 39.1325 31.6189 38.079 31.6176L37.0168 31.6163C36.0189 31.6151 35.1448 30.9757 34.8433 30.0265L34.794 29.8713C34.3067 28.3372 35.2741 26.6955 36.8592 26.3665L37.6523 26.2018Z"
      fill="white"
    />
  </svg>
)

export default UpperArch
