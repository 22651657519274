import React from 'react'
import { Grid } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { TwoFactorAuthLockOn } from 'components/common/icons'
import { useTranslation } from 'react-i18next'

const TwoFactorAuthIsOn = () => {
  const { t } = useTranslation()

  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item>
        <TwoFactorAuthLockOn />
      </Grid>
      <Grid item>
        <DazzedParagraph14 bold>{t('pages.accountSettings.mfaSettings.setupModal.setupIsDone')}</DazzedParagraph14>
      </Grid>
    </Grid>
  )
}

export default TwoFactorAuthIsOn
