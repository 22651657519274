import { IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import useRolePermissions from 'hooks/useRolePermissions'
import React from 'react'
import { useTranslation } from 'react-i18next'
import TextAreaAutosize from '../common/TextareaAutosize'
import UpIcon from '../common/icons/Up'

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-end',
    paddingTop: 10,
    paddingRight: '5%'
  },
  textAreaContainer: {
    flex: 1,
    borderRadius: 40,
    border: '1px solid var(--text-color-4)',
    overflow: 'hidden',
    padding: '4px 20px',
    backgroundColor: ({ isLoading }) => (isLoading ? 'var(--bg-color-2)' : 'var(--bg-color-10)')
  },
  textArea: {
    border: 'none!important'
  },
  button: {
    marginLeft: '10px',
    width: '50px',
    height: '50px',
    border: 'solid 1px var(--border-color-1)'
  }
}))

const NoteInput = ({ note, setNote, isLoading, onAddNote }) => {
  const classes = useStyles({ isLoading })
  const { t } = useTranslation()
  const { permissions } = useRolePermissions()

  return (
    <div className={classes.container}>
      <div className={classes.textAreaContainer}>
        <TextAreaAutosize
          rowsMax={4}
          style={{ bright: true }}
          className={classes.textArea}
          placeholder={t('dialogs.notes.addNote')}
          keepErrorContainerWhenInactive={false}
          value={note}
          isDisabled={isLoading || !permissions.addNotes}
          setValue={setNote}
          id="add-note-input"
          isLoading={isLoading}
        />
      </div>
      <IconButton onClick={onAddNote} className={classes.button} id="add-note-button">
        <UpIcon />
      </IconButton>
    </div>
  )
}

export default NoteInput
