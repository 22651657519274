import * as React from 'react'

const SmallSearch = ({ color = '#7B98B0', ...props }) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ lineHeight: 0.8 }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.04858 4.63314C1.04858 2.65358 2.65333 1.04883 4.63289 1.04883C6.61246 1.04883 8.21721 2.65358 8.21721 4.63314C8.21721 6.6127 6.61246 8.21745 4.63289 8.21745C2.65333 8.21745 1.04858 6.6127 1.04858 4.63314ZM4.63289 0.0488281C2.10105 0.0488281 0.048584 2.10129 0.048584 4.63314C0.048584 7.16498 2.10105 9.21745 4.63289 9.21745C5.71868 9.21745 6.7163 8.83998 7.50168 8.2091L10.542 11.2494L11.2491 10.5423L8.20879 7.502C8.83971 6.7166 9.21721 5.71896 9.21721 4.63314C9.21721 2.10129 7.16474 0.0488281 4.63289 0.0488281Z"
        fill={color}
      />
    </svg>
  )
}

export default SmallSearch
