import React, { useCallback, useState } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { ArrowDropdownUp, Gender as GenderIcon } from '../../../common/icons'
import Tooltip from '../../../common/Tooltip'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { useTranslation } from 'react-i18next'
import GrinMenu from 'components/common/menu/GrinMenu'
import GrinMenuItem from 'components/common/menu/GrinMenuItem'
import ArrowDropdownDown from 'components/common/icons/ArrowDropdownUp'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import { GenderTypes } from 'consts/patientsConsts'

const useStyles = ({ editable }) =>
  makeStyles({
    menu: {
      boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.15)',
      borderRadius: '10px',
      width: '193px'
    },
    trigger: {
      cursor: ({ editable }) => (editable ? 'pointer' : '')
    },
    genderIcon: {
      marginRight: 5,
      marginTop: 4
    },
    unavailable: {
      color: 'var(--text-color-12)'
    },
    arrow: {
      marginLeft: 5
    }
  })({ editable })

const PatientGender = ({ gender, editable, onChange }) => {
  const { t } = useTranslation()
  const classes = useStyles({ editable })

  const [currentGender, setCurrentGender] = useState(gender)
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const handleOpen = useCallback(() => {
    if (!editable) {
      return
    }

    setIsMenuOpen(true)
  }, [editable])

  const handleGenderSelected = useCallback(
    (e, genderKey) => {
      if (!editable) {
        return
      }

      setCurrentGender(genderKey)
      setIsMenuOpen(false)
      e.preventDefault()
      e.stopPropagation()
      onChange(genderKey)
    },
    [editable, onChange]
  )

  return (
    <GrinMenu
      className={classes.menu}
      onOpen={handleOpen}
      onClose={() => setIsMenuOpen(false)}
      open={isMenuOpen}
      transformOrigin={{ vertical: -25, horizontal: -25 }}
      triggerComponent={
        <Tooltip value={t('general.gender.label')} arrow placement="top" variant="dark">
          <Grid container alignItems="center" justifyContent="center" className={classes.trigger}>
            <Grid item className={classes.genderIcon}>
              <GenderIcon />
            </Grid>
            <Grid item>
              <DazzedParagraph14 className={!gender ? classes.unavailable : ''}>
                {gender ? t(`general.gender.${currentGender}`) : t('general.NA')}
              </DazzedParagraph14>
            </Grid>
            {editable && (
              <Grid item>
                <div className={classes.arrow}>{isMenuOpen ? <ArrowDropdownUp /> : <ArrowDropdownDown />}</div>
              </Grid>
            )}
          </Grid>
        </Tooltip>
      }
    >
      {Object.values(GenderTypes).map((genderKey, index) => (
        <GrinMenuItem key={`${genderKey}-${index}`} onClick={e => handleGenderSelected(e, genderKey)}>
          <DazzedParagraph12>{t(`general.gender.${genderKey}`)}</DazzedParagraph12>
        </GrinMenuItem>
      ))}
    </GrinMenu>
  )
}

export default PatientGender
