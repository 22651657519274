import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import DazzedParagraph11 from 'components/common/text/DazzedParagraph11'
import OralScoreTag from '../Tags/OralScoreTag'
import { OralScoreCategories } from 'consts/oralScoreConsts'

const OralStatus = ({ oralScores = {} }) => {
  const { t } = useTranslation()

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <DazzedParagraph11 thickness="bold" color={'var(--text-color-62)'}>
          {t(`pages.patients.selectedPatient.timelineV2.miniTimeline.scanTooltip.oralScore`)}
        </DazzedParagraph11>
      </Grid>
      <Grid item>
        <OralScoreTag
          scoreValue={oralScores.hygieneScore}
          category={OralScoreCategories.Hygiene}
          withBackground
          withIcon
          boldLabe
        />
      </Grid>
      <Grid item>
        <OralScoreTag
          scoreValue={oralScores.perioRisk}
          category={OralScoreCategories.PerioRisk}
          withBackground={false}
          withIcon={false}
          boldLabel={false}
        />
      </Grid>
      <Grid item>
        <OralScoreTag
          scoreValue={oralScores.dentalRisk}
          category={OralScoreCategories.DentalRisk}
          withBackground={false}
          withIcon={false}
          boldLabel={false}
        />
      </Grid>
    </Grid>
  )
}

export default OralStatus
