import { useTranslation } from 'react-i18next'
import { createNumberRanges } from 'utils/arrayUtils'

export default () => {
  const { t } = useTranslation()

  const generateAlignersUploadedStls = stls => {
    const availableLowerSTLs = createNumberRanges(Object.keys(stls || {}))
    return availableLowerSTLs.map(([rangeStart, rangeEnd], i) =>
      rangeEnd
        ? `${rangeStart}-${rangeEnd}${availableLowerSTLs.length > 1 && i !== availableLowerSTLs.length - 1 ? ', ' : ''}`
        : rangeStart
    )
  }

  const generateBracesUploadedStls = stls =>
    Object.keys(stls || {})
      .map(key => t(`dialogs.patientInfo.txTrackerSetup.${key}`))
      .join(', ')

  return {
    generateAlignersUploadedStls,
    generateBracesUploadedStls
  }
}
