import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Trans, useTranslation } from 'react-i18next'
import DazzedHeading24 from 'components/common/text/DazzedHeading24'
import TxTrackerFailed from 'components/common/icons/treatmentTracker/TxTrackerFailed'
import { Grid } from '@material-ui/core'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import moment from 'moment'

const useStyles = makeStyles(theme => ({
  pendingStateContainer: {
    height: '100%'
  },
  title: {
    fontWeight: 'bold'
  },
  button: {
    textDecoration: 'underline',
    color: 'var(--text-color-25)',
    cursor: 'pointer'
  }
}))

const FailedState = ({ onRerunRequestClicked = () => {}, requestStatus, requestedAt }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid
      container
      className={classes.pendingStateContainer}
      alignItems="center"
      justifyContent="center"
      direction="column"
      spacing={2}
    >
      <Grid item>
        <TxTrackerFailed />
      </Grid>
      <Grid item>
        <DazzedHeading24 className={classes.title}>{t('dialogs.treatmentTracker.failedState.title')}</DazzedHeading24>
      </Grid>
      <Grid item>
        <DazzedParagraph16 strong>
          {requestStatus === 'pending' ? (
            t('dialogs.treatmentTracker.messages.rerunRequestPending', {
              requestedAt: moment(requestedAt).format('lll')
            })
          ) : (
            <Trans
              i18nKey={'dialogs.treatmentTracker.failedState.text'}
              components={{
                a: <span className={classes.button} onClick={onRerunRequestClicked} />
              }}
            />
          )}
        </DazzedParagraph16>
      </Grid>
    </Grid>
  )
}

export default FailedState
