import * as React from 'react'

export default () => (
  <svg width="126" height="40" viewBox="0 0 126 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_27_5)">
      <path
        d="M69.9391 16.6498C71.7359 16.2781 73.5113 15.8059 75.2574 15.2354C74.2137 7.10729 65.6072 7.01772 65.6072 7.01772C62.0645 7.01772 59.1536 8.24373 56.8745 10.6957C54.6724 13.0797 53.5713 16.0165 53.5713 19.5059C53.5713 22.9616 54.6328 25.8354 56.7558 28.1274C59.0355 30.5798 62.0805 31.8059 65.8907 31.8059C67.9911 31.8059 69.8906 31.5665 71.589 31.0875C73.2875 30.6085 74.5167 30.1295 75.2766 29.6506L75.2879 19.0557C75.2883 19.0058 75.2773 18.9565 75.2556 18.9118C75.234 18.867 75.2023 18.8281 75.1632 18.7982C75.1241 18.7682 75.0787 18.748 75.0306 18.7393C74.9825 18.7305 74.933 18.7334 74.8862 18.7477C73.53 19.1788 70.177 20.1142 66.4439 20.7048V22.8693C67.7063 22.8929 68.5442 23.0071 68.9578 23.2118C69.5946 23.5427 70.1944 24.2382 70.1944 25.2986V26.326C70.1963 26.652 70.134 26.975 70.0112 27.2759C69.8884 27.5768 69.7077 27.8494 69.4797 28.0776C68.9086 28.6409 67.9935 29.0188 66.5298 29.0188C63.8254 29.0188 61.5977 27.7644 60.7878 25.8686C59.8154 23.594 59.9398 22.3424 59.9398 19.4207C59.9398 15.8624 59.9642 14.6659 61.1003 12.7735C62.0196 11.2408 63.8108 10.5267 65.3566 10.5731C65.3566 10.5731 67.9953 10.3365 69.2173 12.7371C70.0897 14.4547 69.9391 16.6498 69.9391 16.6498Z"
        fill="white"
      />
      <path
        d="M94.8099 13.8134C95.5636 13.8134 96.1952 13.558 96.7049 13.0471C96.9513 12.8086 97.1465 12.5205 97.2781 12.201C97.4097 11.8815 97.4748 11.5376 97.4693 11.1911C97.4693 10.4218 97.2145 9.77715 96.7049 9.25699C96.1952 8.73683 95.5636 8.4769 94.8099 8.47722C94.4701 8.4716 94.1328 8.53819 93.8195 8.67276C93.5062 8.80733 93.2237 9.00694 92.9901 9.2589C92.4895 9.77907 92.2393 10.4238 92.2393 11.193C92.2393 11.9006 92.4941 12.5142 93.0037 13.0337C93.5133 13.5532 94.1154 13.8131 94.8099 13.8134Z"
        fill="white"
      />
      <path
        d="M83.1752 15.2701C83.3836 15.159 83.6318 15.3367 83.6314 15.5767L83.6271 19.1306C84.2324 18.2873 84.9452 17.5302 85.7468 16.8795C86.5193 16.253 87.3037 15.9397 88.1002 15.9397C89.6235 15.9397 90.3853 16.5199 90.3856 17.6803C90.3856 18.2844 90.1582 18.9168 89.7033 19.5775C89.2484 20.2382 88.7708 20.5691 88.2706 20.5704C88.0355 20.5637 87.804 20.5105 87.5887 20.4138C87.332 20.3063 87.0813 20.1843 86.8379 20.0483C86.2009 19.7258 85.7691 19.5636 85.5422 19.5617C84.8824 19.5617 84.3651 19.8112 83.9903 20.3103C83.6156 20.8094 83.4278 21.6855 83.4272 22.9385V28.2996C83.4273 28.3872 83.4327 28.4747 83.4432 28.5616V31.2141L78.4961 31.2117V17.334C78.4961 17.334 80.9908 16.4359 83.1752 15.2701Z"
        fill="white"
      />
      <path
        d="M92.5215 31.2128V28.8371C92.5215 28.8199 92.5215 28.8036 92.5215 28.7864V21.2665C92.5215 21.2272 92.5215 21.1908 92.5215 21.1525V17.3078C92.5215 17.3078 94.8007 16.4562 97.0134 15.3579C97.0633 15.3338 97.1184 15.3228 97.1735 15.3261C97.2286 15.3295 97.2821 15.3469 97.3289 15.3769C97.3756 15.4069 97.4143 15.4484 97.4411 15.4977C97.468 15.5469 97.4823 15.6023 97.4826 15.6587V20.5495C97.4751 20.7559 97.4704 20.9513 97.4704 21.1276V28.7514C97.4706 28.8042 97.4747 28.8568 97.4826 28.909V31.2128H92.5215Z"
        fill="white"
      />
      <path
        d="M110.112 31.2039V28.9048C110.116 28.8459 110.119 28.7851 110.119 28.7199V21.8596C110.119 21.7724 110.115 21.6881 110.112 21.6048L110.076 21.1929C110.003 20.6454 109.836 20.1933 109.573 19.8403C109.209 19.3533 108.731 19.1097 108.14 19.1093C107.757 19.1049 107.375 19.1698 107.014 19.3009C106.617 19.4585 106.239 19.663 105.888 19.9102C105.706 20.0264 105.615 20.2352 105.615 20.5367V28.7175C105.615 28.7798 105.615 28.8377 105.621 28.8938V31.2154H100.67V17.3477C103.021 16.4467 104.586 15.6914 105.316 15.3188C105.365 15.2939 105.42 15.2823 105.476 15.2851C105.531 15.288 105.585 15.3051 105.632 15.335C105.678 15.3648 105.717 15.4064 105.744 15.4558C105.771 15.5052 105.785 15.5607 105.785 15.6172V18.3784C106.649 17.5661 107.456 16.9626 108.207 16.5679C108.958 16.1732 109.822 15.9759 110.799 15.9759C112.051 15.9759 113.074 16.4285 113.869 17.3338C114.664 18.2391 115.062 19.4576 115.063 20.9893V28.7175C115.063 28.7654 115.063 28.8109 115.067 28.855V31.2125H110.11"
        fill="white"
      />
    </g>
    <path d="M0.276367 30.6396C20.064 30.6396 36.5182 18.4281 39.9065 2.33236" stroke="#FFBD39" strokeWidth="1.65125" />
    <defs>
      <clipPath id="clip0_27_5">
        <rect width="61.495" height="24.7887" fill="white" transform="translate(53.5713 7.01772)" />
      </clipPath>
    </defs>
  </svg>
)
