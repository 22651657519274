import React from 'react'
import { useTranslation } from 'react-i18next'
import GenericGuidelineAction from './GenericGuidelineAction'
import { PauseScansIcon } from 'components/common/icons'

const PauseScans = ({ isChecked }) => {
  const { t } = useTranslation()

  return (
    isChecked && (
      <GenericGuidelineAction
        icon={<PauseScansIcon />}
        text={t('dialogs.hiPatientGuidelinesViewer.actions.pauseScans')}
      />
    )
  )
}

export default PauseScans
