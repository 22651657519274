import React, { useCallback, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Fade, Grid } from '@material-ui/core'
import { GrinNotificationStatuses } from '@grin-rnd/grin-api-sdk/dist/Enums/Notifications'
import NotificationHeader from './NotificationHeader'
import ProfilePicture from 'components/common/ProfilePicture'
import NotificationBubble from './NotificationBubble'
import { isDevAdmin } from 'utils/authUtils'
import { getEnvironment } from 'utils/awsUtils'
import { trackEvent } from 'utils/analyticsUtils'
import Tooltip from 'components/common/Tooltip'
import { useSetGrinNotificationStatusMutation } from 'store/modules/NotificationCenter'
import NotificationActions from './NotificationActions/NotificationActions'
import NotificationPracticeName from './NotificationPracticeName'
import { useSelector } from 'react-redux'

const useStyles = makeStyles(theme => ({
  notificationRoot: {
    width: '100%',
    borderRadius: 8,
    backgroundColor: 'var(--notification-center-unread-bgcolor)',
    padding: '4px 16px 8px 16px',
    marginBottom: ({ isLastItem }) => (isLastItem ? 0 : 10),
    cursor: 'pointer',
    position: 'relative',
    transition: 'background-color 0.3s ease-in, opacity 0.2s ease, height: 0.3s ease',
    '&.read': {
      backgroundColor: 'var(--notification-center-read-bgcolor)'
    },
    '&.deleted': {
      opacity: 0.2
    },
    '&.hidden': {
      height: '0px',
      overflow: 'hidden'
    }
  },
  profilePictureContainer: {
    position: 'relative',
    marginRight: 4
  },
  bubbleIconContainer: {
    position: 'absolute',
    right: -7,
    bottom: -7
  },
  notificationActionsContainer: {
    position: 'absolute',
    right: 8,
    top: 8
  }
}))

/**
 * @param {Object} props
 * @param {GrinNotification} props.grinNotification
 * @param {boolean} props.isLastItem
 * @param {S3Object} props.profilePicture - An S3 object to display as the profile picture / avatar
 * @param {string} [props.profilePictureUserName] - In case there is no profile picture, this name is used for displaying initials.
 * @param {JSX.Element} [props.customTitle] - A custom title to display at the top, instead of the standard notification type.
 * @param {string} [props.titleClassName]
 * @param {(grinNotification: GrinNotification) => void} [props.onClick] - Fired when the notification is clicked.
 * @param {boolean} [props.markAsRead=true] - If true, the notification will be marked as read when clicked.
 * @param {boolean} [props.showPracticeName] - If true, the practice name associated with this notification will be displayed.
 * @param {Object} [props.analyticsPayload] - Additional data to send with the analytics event.
 * @param {any} props.children
 */
const BaseGrinNotification = ({
  grinNotification,
  isLastItem = false,
  profilePicture,
  profilePictureUserName,
  customTitle,
  titleClassName = '',
  analyticsPayload = {},
  onClick,
  markAsRead = true,
  showPracticeName = false,
  children
}) => {
  const classes = useStyles({ isLastItem })
  const [setNotificationStatus, { isLoading }] = useSetGrinNotificationStatusMutation()
  const { unreadOnly } = useSelector(state => state.notificationCenterReducer.filters)
  const [actionsMenuVisible, setActionsMenuVisible] = useState(false)

  const notificationAnalyticsPayload = useMemo(
    () => ({
      notificationType: grinNotification.type,
      notificationId: grinNotification.id,
      notificationDate: grinNotification.eventDate,
      notificationStatus: grinNotification.status,
      relatedPatientId: grinNotification.relatedPatientId,
      ...analyticsPayload
    }),
    [analyticsPayload, grinNotification]
  )

  const isUnread = useMemo(() => grinNotification.status === GrinNotificationStatuses.New, [grinNotification])
  const isDeleted = useMemo(() => grinNotification.status === GrinNotificationStatuses.Deleted, [grinNotification])
  const shouldDisappear = useMemo(() => (unreadOnly && !isUnread) || isDeleted, [isUnread, unreadOnly, isDeleted])

  const handleMarkAsRead = useCallback(async () => {
    if (isUnread && !isLoading) {
      await setNotificationStatus({
        grinNotificationId: grinNotification.id,
        status: GrinNotificationStatuses.Read
      })
    }
  }, [isUnread, isLoading, grinNotification, setNotificationStatus])

  const handleNotificationBodyClicked = useCallback(() => {
    trackEvent(`Notification Center - notification clicked`, notificationAnalyticsPayload)

    if (markAsRead) {
      handleMarkAsRead()
    }

    if (onClick) {
      onClick(grinNotification)
    }
  }, [grinNotification, onClick, notificationAnalyticsPayload, markAsRead, handleMarkAsRead])

  const handleRightClick = useCallback(() => {
    if (isDevAdmin() || getEnvironment() !== 'production') {
      navigator.clipboard.writeText(grinNotification.id)
    }
  }, [grinNotification])

  return (
    <Fade in={!shouldDisappear} enter={false} unmountOnExit timeout={500}>
      <Grid
        container
        direction="column"
        className={[
          classes.notificationRoot,
          isDeleted ? 'deleted' : '',
          !isUnread ? 'read' : '',
          shouldDisappear ? 'hidden' : ''
        ].join(' ')}
        onClick={handleNotificationBodyClicked}
        onContextMenu={handleRightClick}
        onMouseEnter={() => setActionsMenuVisible(true)}
        onMouseLeave={() => setActionsMenuVisible(false)}
      >
        <div className={classes.notificationActionsContainer}>
          <NotificationActions grinNotification={grinNotification} isVisible={actionsMenuVisible} />
        </div>
        <Grid item style={{ marginBottom: 3 }}>
          <NotificationHeader
            eventDate={grinNotification.eventDate}
            notificationStatus={grinNotification.status}
            notificationType={grinNotification.type}
            titleClassName={titleClassName}
            customTitle={customTitle}
          />
        </Grid>
        <Grid item style={{ marginBottom: 8 }}>
          <Grid container spacing={1} wrap="nowrap" alignItems="center">
            <Grid item>
              <Tooltip
                value={profilePictureUserName}
                placement="bottom"
                wrapperClassName={classes.profilePictureContainer}
              >
                <ProfilePicture name={profilePictureUserName} photo={profilePicture} size="small" />
                <div className={classes.bubbleIconContainer}>
                  <NotificationBubble notificationType={grinNotification.type} isUnread={isUnread} />
                </div>
              </Tooltip>
            </Grid>
            <Grid item>{children}</Grid>
          </Grid>
        </Grid>
        {showPracticeName && grinNotification.payload.practiceName && (
          <Grid item>
            <NotificationPracticeName
              accountOwnerId={grinNotification.relatedAccountOwnerId}
              practiceName={grinNotification.payload.practiceName}
              analyticsPayload={notificationAnalyticsPayload}
            />
          </Grid>
        )}
      </Grid>
    </Fade>
  )
}

export default BaseGrinNotification
