import { makeStyles } from '@material-ui/core/styles'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Actions from '../../actions'
import BaseModal from '../common/modals/BaseModal'
import DazzedParagraph16 from '../common/text/DazzedParagraph16'
import { useTranslation } from 'react-i18next'
import { trackEvent } from 'utils/analyticsUtils'
import { isMobile } from 'utils/mobileUtils'

const useBillingRequestUpgradePlanStyles = makeStyles({
  firstParagraph: {
    marginBottom: 20
  }
})

const BillingUpgradePlanWarningModal = () => {
  const classes = useBillingRequestUpgradePlanStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const isOpen = useSelector(state => state.billingReducer.upgradePlan.isUpgradePlanWarningModalOpen)

  const handleCancel = useCallback(() => {
    dispatch(Actions.setUpgradePlanWarningModalVisibility(false))
  }, [dispatch])

  const handleSelectPlan = useCallback(() => {
    handleCancel()
    trackEvent('Billing upgrade plan modal - contact support clicked')
    window.open('mailto:support@get-grin.com', '_blank')
  }, [handleCancel])

  return (
    <BaseModal
      open={isOpen}
      title={t('dialogs.upgradePlan.warningTitle')}
      onSecondaryBtnClick={handleCancel}
      secondaryLabel={t('general.close')}
      onPrimaryBtnClick={handleSelectPlan}
      primaryLabel={t('dialogs.upgradePlan.contactSupport')}
      handleClose={handleCancel}
      largerButtons={!isMobile()}
      variant="alert"
      withCloseIcon={false}
    >
      <DazzedParagraph16 className={classes.firstParagraph} textAlign="center">
        {t('dialogs.upgradePlan.warningDescription1')}
      </DazzedParagraph16>
    </BaseModal>
  )
}

export default BillingUpgradePlanWarningModal
