import React, { useMemo } from 'react'
import { ScanTrackingStatus, TreatmentTrackOptions } from 'consts/giConsts'
import OnTrackGrinScanItemIcon from 'components/common/icons/timelineV2/mini-timeline/OnTrackGrinScanItem'
import OffTrackGrinScanItemIcon from 'components/common/icons/timelineV2/mini-timeline/OffTrackGrinScanItem'
import FallbackGrinScanItemIcon from 'components/common/icons/timelineV2/mini-timeline/NormalGrinScanItem'
import NotRequiredGrinScanItem from 'components/common/icons/timelineV2/mini-timeline/NotRequiredGrinScanItem'
import useRolePermissions from 'hooks/useRolePermissions'

const ScanTrackingIcon = ({ trackStatus }) => {
  const { isHiUser } = useRolePermissions()
  const isDismissed = useMemo(() => trackStatus === ScanTrackingStatus.NotRequired, [trackStatus])

  return trackStatus === TreatmentTrackOptions.OnTrack ? (
    <OnTrackGrinScanItemIcon />
  ) : trackStatus === TreatmentTrackOptions.OffTrack ? (
    <OffTrackGrinScanItemIcon />
  ) : isDismissed && isHiUser ? (
    <NotRequiredGrinScanItem />
  ) : (
    <FallbackGrinScanItemIcon />
  )
}

export default ScanTrackingIcon
