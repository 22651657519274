// Action Types
const FETCH_REJECTED = 'FETCH_REJECTED'
const LOCATION_CHANGED = '@@router/LOCATION_CHANGE'
const HIDE_SNACKBAR = 'HIDE_SNACKBAR'
const FILE_UPLOAD_REQUESTED = 'FILE_UPLOAD_REQUESTED'
const FILE_UPLOAD_RECEIVED = 'FILE_UPLOAD_RECEIVED'
const REQUEST_SEARCH_DOCTOR = 'REQUEST_SEARCH_DOCTOR'
const DOCTOR_SEARCH_RECEIVED = 'DOCTOR_SEARCH_RECEIVED'
const DOCTOR_SEARCH_FAILED = 'DOCTOR_SEARCH_FAILED'
const SHOW_SNACKBAR = 'SHOW_SNACKBAR'
const CONFIGURE_PUBSUB = 'CONFIGURE_PUBSUB'
const REQUEST_SUBSCRIBE_TO_NOTIFICATIONS = 'REQUEST_SUBSCRIBE_TO_NOTIFICATIONS'
const REQUEST_SUBSCRIBE_TO_NOTIFICATIONS_FAILED = 'REQUEST_SUBSCRIBE_TO_NOTIFICATIONS_FAILED'
const SUBSCRIBE_TO_CONTEXT_NOTIFICATIONS = 'SUBSCRIBE_TO_CONTEXT_NOTIFICATIONS'
const SUBSCRIBE_TO_CONTEXT_NOTIFICATIONS_FAILED = 'SUBSCRIBE_TO_CONTEXT_NOTIFICATIONS_FAILED'
const UNSUBSCRIBE_FROM_CONTEXT_NOTIFICATIONS = 'UNSUBSCRIBE_FROM_CONTEXT_NOTIFICATIONS'
const PUBSUB_CONNECT_FAILED = 'PUBSUB_CONNECT_FAILED'
const PUBSUB_RESET = 'PUBSUB_RESET'
const NOTIFICATION_RECEIVED = 'NOTIFICATION_RECEIVED'
const NOTIFICATION_RECEIVED_FAILED = 'NOTIFICATION_RECEIVED_FAILED'
const SET_HELP_WIDGET_VISIBILITY = 'SET_HELP_WIDGET_VISIBILITY'
const OPEN_HELP_POPUP = 'OPEN_HELP_POPUP'
const AWS_CONFIGURED = 'AWS_CONFIGURED'
const LOOK_FOR_NEW_VERSION = 'LOOK_FOR_NEW_VERSION'
const FORCE_REFRESH = 'FORCE_REFRESH'
const DISMISS_FORCE_REFRESH = 'DISMISS_FORCE_REFRESH'
const SHOW_ALERT = 'SHOW_ALERT'
const HIDE_ALERT = 'HIDE_ALERT'
const SHOW_PROMPT = 'SHOW_PROMPT'
const HIDE_PROMPT = 'HIDE_PROMPT'
const SET_RC_LANDING_PAGE_FOOTER_VARIANT = 'SET_RC_LANDING_PAGE_FOOTER_VARIANT'
const SET_RC_CONTENT_VISIBLE = 'SET_RC_CONTENT_VISIBLE'
const SET_PATIENTS_LIST_EXPANDED = 'SET_PATIENTS_LIST_EXPANDED'
const MOBILE_SET_SELECTED_TAB = 'MOBILE_SET_SELECTED_TAB'
const SET_IS_MOBILE = 'SET_IS_MOBILE'
const FETCH_APP_CONFIG = 'FETCH_APP_CONFIG'
const FETCH_APP_CONFIG_RECEIVED = 'FETCH_APP_CONFIG_RECEIVED'
const FETCH_APP_CONFIG_FAILED = 'FETCH_APP_CONFIG_FAILED'
const SET_HAS_NOTIFICATIONS_INDICATOR = 'SET_HAS_NOTIFICATIONS_INDICATOR'
const TOGGLE_APP_FEEDBACK_DIALOG = 'TOGGLE_FEEDBACK_DIALOG'
const SUBMIT_APP_FEEDBACK = 'SUBMIT_APP_FEEDBACK'
const SUBMIT_APP_FEEDBACK_RECEIVED = 'SUBMIT_APP_FEEDBACK_RECEIVED'
const SUBMIT_APP_FEEDBACK_FAILED = 'SUBMIT_APP_FEEDBACK_FAILED'
const FETCH_STATIC_DATA = 'FETCH_STATIC_DATA'
const FETCH_STATIC_DATA_RECEIVED = 'FETCH_STATIC_DATA_RECEIVED'
const FETCH_STATIC_DATA_FAILED = 'FETCH_STATIC_DATA_FAILED'
const FETCH_ABOUT_DATA = 'FETCH_ABOUT_DATA'
const FETCH_ABOUT_DATA_RECEIVED = 'FETCH_ABOUT_DATA_RECEIVED'
const FETCH_ABOUT_DATA_FAILED = 'FETCH_ABOUT_DATA_FAILED'
const CHECK_LOGIN_CHANGE = 'CHECK_LOGIN_CHANGE'
const TOGGLE_QUICK_ACTIONS_OPEN = 'TOGGLE_QUICK_ACTIONS_OPEN'

// Action Creators
const fetchRejected = payload => ({
  type: FETCH_REJECTED,
  payload
})

const showSnackbar = ({ text, type, time }) => ({
  type: SHOW_SNACKBAR,
  payload: {
    snackbarType: type,
    snackbarText: text,
    snackbarTime: time
  }
})

const hideSnackbar = () => ({
  type: HIDE_SNACKBAR
})

const requestFileUpload = () => ({
  type: FILE_UPLOAD_REQUESTED
})

const fileUploadReceived = () => ({
  type: FILE_UPLOAD_RECEIVED
})

const requestSearchDoctor = payload => ({
  type: REQUEST_SEARCH_DOCTOR,
  payload
})

const doctorSearchReceived = payload => ({
  type: DOCTOR_SEARCH_RECEIVED,
  payload
})

const doctorSearchFailed = payload => ({
  type: DOCTOR_SEARCH_FAILED,
  payload
})

const configurePubsub = payload => ({
  type: CONFIGURE_PUBSUB,
  payload
})

const pubsubReset = payload => ({
  type: PUBSUB_RESET,
  payload
})

const requestSubscribeToNotifications = payload => ({
  type: REQUEST_SUBSCRIBE_TO_NOTIFICATIONS,
  payload
})

const requestSubscribeToNotificationsFailed = payload => ({
  type: REQUEST_SUBSCRIBE_TO_NOTIFICATIONS_FAILED,
  payload
})

const pubsubConnectFailed = payload => ({
  type: PUBSUB_CONNECT_FAILED,
  payload
})

const notificationReceived = payload => ({
  type: NOTIFICATION_RECEIVED,
  payload
})

const notificationReceivedFailed = payload => ({
  type: NOTIFICATION_RECEIVED_FAILED,
  payload
})

const subscribeToContextNotifications = payload => ({
  type: SUBSCRIBE_TO_CONTEXT_NOTIFICATIONS,
  payload
})

const subscribeToContextNotificationsFailed = payload => ({
  type: SUBSCRIBE_TO_CONTEXT_NOTIFICATIONS_FAILED,
  payload
})

const unsubscribeFromContextNotifications = payload => ({
  type: UNSUBSCRIBE_FROM_CONTEXT_NOTIFICATIONS,
  payload
})

const setHelpWidgetVisibility = payload => ({
  type: SET_HELP_WIDGET_VISIBILITY,
  payload
})

const openHelpPopup = () => ({
  type: OPEN_HELP_POPUP
})

const awsConfigured = () => ({
  type: AWS_CONFIGURED
})

const lookForNewVersion = () => ({
  type: LOOK_FOR_NEW_VERSION
})

const forceRefresh = payload => ({
  type: FORCE_REFRESH,
  payload
})

const dismissForceRefresh = () => ({
  type: DISMISS_FORCE_REFRESH
})

const showAlert = payload => ({
  type: SHOW_ALERT,
  payload
})

const hideAlert = payload => ({
  type: HIDE_ALERT,
  payload
})

const showPrompt = payload => ({
  type: SHOW_PROMPT,
  payload
})

const hidePrompt = payload => ({
  type: HIDE_PROMPT,
  payload
})

const setRcLandingPagaeFooterVariant = payload => ({
  type: SET_RC_LANDING_PAGE_FOOTER_VARIANT,
  payload
})

const setRcContentVisible = payload => ({
  type: SET_RC_CONTENT_VISIBLE,
  payload
})

const setPatientsListExpanded = payload => ({
  type: SET_PATIENTS_LIST_EXPANDED,
  payload
})

const mobileSetSelectedTab = payload => ({
  type: MOBILE_SET_SELECTED_TAB,
  payload
})

const setIsMobile = payload => ({
  type: SET_IS_MOBILE,
  payload
})

const fetchAppConfig = payload => ({
  type: FETCH_APP_CONFIG,
  payload
})

const fetchAppConfigReceived = payload => ({
  type: FETCH_APP_CONFIG_RECEIVED,
  payload
})

const fetchAppConfigFailed = payload => ({
  type: FETCH_APP_CONFIG_FAILED,
  payload
})

const setHasNotificationsIndicator = payload => ({
  type: SET_HAS_NOTIFICATIONS_INDICATOR,
  payload
})

const toggleAppFeedbackDialog = payload => ({
  type: TOGGLE_APP_FEEDBACK_DIALOG,
  payload
})

const submitAppFeedback = payload => ({
  type: SUBMIT_APP_FEEDBACK,
  payload
})

const submitAppFeedbackReceived = payload => ({
  type: SUBMIT_APP_FEEDBACK_RECEIVED,
  payload
})

const submitAppFeedbackFailed = payload => ({
  type: SUBMIT_APP_FEEDBACK_FAILED,
  payload
})

const fetchStaticData = payload => ({
  type: FETCH_STATIC_DATA,
  payload
})

const fetchStaticDataReceived = payload => ({
  type: FETCH_STATIC_DATA_RECEIVED,
  payload
})

const fetchStaticDataFailed = payload => ({
  type: FETCH_STATIC_DATA_FAILED,
  payload
})

const fetchAboutData = payload => ({
  type: FETCH_ABOUT_DATA,
  payload
})

const fetchAboutDataReceived = payload => ({
  type: FETCH_ABOUT_DATA_RECEIVED,
  payload
})

const fetchAboutDataFailed = payload => ({
  type: FETCH_ABOUT_DATA_FAILED,
  payload
})

const checkLoginChange = payload => ({
  type: CHECK_LOGIN_CHANGE,
  payload
})

const toggleQuickActionsOpen = payload => ({
  type: TOGGLE_QUICK_ACTIONS_OPEN,
  payload
})

export default {
  FETCH_REJECTED,
  LOCATION_CHANGED,
  SHOW_SNACKBAR,
  HIDE_SNACKBAR,
  FILE_UPLOAD_REQUESTED,
  FILE_UPLOAD_RECEIVED,
  REQUEST_SEARCH_DOCTOR,
  DOCTOR_SEARCH_RECEIVED,
  DOCTOR_SEARCH_FAILED,
  CONFIGURE_PUBSUB,
  PUBSUB_RESET,
  REQUEST_SUBSCRIBE_TO_NOTIFICATIONS,
  REQUEST_SUBSCRIBE_TO_NOTIFICATIONS_FAILED,
  SUBSCRIBE_TO_CONTEXT_NOTIFICATIONS,
  SUBSCRIBE_TO_CONTEXT_NOTIFICATIONS_FAILED,
  UNSUBSCRIBE_FROM_CONTEXT_NOTIFICATIONS,
  PUBSUB_CONNECT_FAILED,
  NOTIFICATION_RECEIVED,
  NOTIFICATION_RECEIVED_FAILED,
  SET_HELP_WIDGET_VISIBILITY,
  OPEN_HELP_POPUP,
  AWS_CONFIGURED,
  LOOK_FOR_NEW_VERSION,
  FORCE_REFRESH,
  DISMISS_FORCE_REFRESH,
  SHOW_ALERT,
  HIDE_ALERT,
  SHOW_PROMPT,
  HIDE_PROMPT,
  SET_RC_LANDING_PAGE_FOOTER_VARIANT,
  SET_RC_CONTENT_VISIBLE,
  SET_PATIENTS_LIST_EXPANDED,
  MOBILE_SET_SELECTED_TAB,
  SET_IS_MOBILE,
  FETCH_APP_CONFIG,
  FETCH_APP_CONFIG_FAILED,
  FETCH_APP_CONFIG_RECEIVED,
  SET_HAS_NOTIFICATIONS_INDICATOR,
  TOGGLE_APP_FEEDBACK_DIALOG,
  SUBMIT_APP_FEEDBACK,
  SUBMIT_APP_FEEDBACK_FAILED,
  SUBMIT_APP_FEEDBACK_RECEIVED,
  FETCH_STATIC_DATA,
  FETCH_STATIC_DATA_RECEIVED,
  FETCH_STATIC_DATA_FAILED,
  FETCH_ABOUT_DATA,
  FETCH_ABOUT_DATA_RECEIVED,
  FETCH_ABOUT_DATA_FAILED,
  CHECK_LOGIN_CHANGE,
  TOGGLE_QUICK_ACTIONS_OPEN,
  toggleQuickActionsOpen,
  fetchStaticData,
  fetchStaticDataReceived,
  fetchStaticDataFailed,
  fetchAboutData,
  fetchAboutDataReceived,
  fetchAboutDataFailed,
  requestSearchDoctor,
  doctorSearchReceived,
  doctorSearchFailed,
  fetchRejected,
  showSnackbar,
  hideSnackbar,
  requestFileUpload,
  fileUploadReceived,
  configurePubsub,
  pubsubReset,
  requestSubscribeToNotifications,
  requestSubscribeToNotificationsFailed,
  subscribeToContextNotifications,
  subscribeToContextNotificationsFailed,
  unsubscribeFromContextNotifications,
  pubsubConnectFailed,
  notificationReceived,
  notificationReceivedFailed,
  setHelpWidgetVisibility,
  openHelpPopup,
  awsConfigured,
  lookForNewVersion,
  forceRefresh,
  dismissForceRefresh,
  showAlert,
  hideAlert,
  showPrompt,
  hidePrompt,
  setRcLandingPagaeFooterVariant,
  setRcContentVisible,
  setPatientsListExpanded,
  mobileSetSelectedTab,
  setIsMobile,
  fetchAppConfig,
  fetchAppConfigFailed,
  fetchAppConfigReceived,
  setHasNotificationsIndicator,
  toggleAppFeedbackDialog,
  submitAppFeedback,
  submitAppFeedbackReceived,
  submitAppFeedbackFailed,
  checkLoginChange
}
