import React, { useCallback, useMemo } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import IconButton from 'components/common/buttons/IconButton'
import { Trash2 } from 'components/common/icons'
import PdfThumbnail from 'components/common/icons/SavedFiles/PdfThumbnail'
import VideoThumbnail from 'components/common/icons/SavedFiles/VideoThumbnail'
import GifThumbnail from 'components/common/icons/SavedFiles/GifThumbnail'
import ImageThumbnail from 'components/common/icons/SavedFiles/ImageThumbnail'
import { trackEvent } from 'utils/analyticsUtils'
import { useDispatch } from 'react-redux'
import Actions from 'actions'
import { getObjectForDownload, getObjectUrl } from 'utils/mediaUtils'
import { blobToBase64, fileTypeToMessageType } from 'utils/fileUtils'
import fileExtension from 'file-extension'
import { fileTypes } from 'consts/fileConsts'
import { v4 } from 'uuid'

const useStyles = makeStyles(() => ({
  filesContainer: {
    padding: 10
  },
  fileItem: {
    cursor: 'pointer',
    padding: 5,
    '&:hover': {
      backgroundColor: 'var(--bg-color-68)',
      borderRadius: 6
    },
    '&:hover $actionsContainer': {
      display: 'flex'
    }
  },
  actionsContainer: {
    display: 'none'
  },
  thumbnail: {
    display: 'flex',
    alignItems: 'center'
  }
}))

const DisplaySavedFiles = ({ files, editable, onSelectFile, handleCloseSavedFiles }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const fileThumbnails = useMemo(
    () => ({
      [fileTypes.pdf]: <PdfThumbnail className={classes.thumbnail} />,
      [fileTypes.video]: <VideoThumbnail className={classes.thumbnail} />,
      [fileTypes.gif]: <GifThumbnail className={classes.thumbnail} />,
      [fileTypes.image]: <ImageThumbnail className={classes.thumbnail} />
    }),
    [classes]
  )

  const getFileType = useCallback(fileName => {
    const extension = fileExtension(fileName)

    switch (extension) {
      case 'jpg':
      case 'jpeg':
      case 'png':
        return fileTypes.image
      case 'gif':
        return fileTypes.gif
      case 'pdf':
        return fileTypes.pdf
      case 'mp4':
      case 'mov':
        return fileTypes.video
      default:
        return ''
    }
  }, [])

  const handleDeleteFile = useCallback(
    (file, e) => {
      e.stopPropagation()
      trackEvent('Saved files - Practice library - Delete file clicked', {
        fileId: file.id
      })
      dispatch(Actions.deleteSavedFile(file))
    },
    [dispatch]
  )
  const handleSelectFile = useCallback(
    async file => {
      trackEvent('Saved files - Saved file selected', {
        fileName: file.title
      })
      dispatch(Actions.toggleSavedFileLoading(true))
      const fileData = await getObjectForDownload(getObjectUrl(file.attachments[0]))
      dispatch(Actions.toggleSavedFileLoading(false))

      handleCloseSavedFiles()
      if (!fileData) {
        dispatch(Actions.sendSavedFileFailed())
        return
      }

      const extension = fileExtension(file.name)
      const fileId = v4()

      onSelectFile([
        {
          id: fileId,
          data: await blobToBase64(fileData),
          file: new File([fileData], file.name),
          extension,
          originalKey: file.name,
          fileTitle: file.title,
          messageType: fileTypeToMessageType(getFileType(file.name)),
          type: fileData.type
        }
      ])
    },
    [dispatch, getFileType, handleCloseSavedFiles, onSelectFile]
  )

  return (
    <Grid container spacing={2} className={classes.filesContainer}>
      {files.map(currFile => (
        <Grid item key={currFile.name} xs={6} className={classes.fileItem} onClick={() => handleSelectFile(currFile)}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Grid container wrap="nowrap" alignItems="center" spacing={1}>
                <Grid item>{fileThumbnails[getFileType(currFile.name)]}</Grid>
                <Grid item>
                  <DazzedParagraph12>{currFile.name}</DazzedParagraph12>
                </Grid>
                {editable && (
                  <Grid item className={classes.actionsContainer}>
                    <Grid container alignItems="center">
                      <Grid item>
                        <IconButton size="small" onClick={e => handleDeleteFile(currFile, e)}>
                          <Trash2 />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  )
}

export default DisplaySavedFiles
