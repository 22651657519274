import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { MiniTimelineThemes } from 'consts/timelineConsts'
import ScanTrackingIcon from './ScanTrackingIcon'
import OralScoreIcon from './OralScoreIcon'
import TxTrackerIcon from './TxTrackerIcon'

const useStyles = makeStyles(theme => ({
  scanTimelineIcon: {
    display: 'inline-flex',
    fontFamily: 'auto',
    border: '3px solid transparent',
    borderRadius: '100%',
    padding: 0
  },
  selected: {
    borderColor: '#3C52EF'
  },
  squareSelected: {
    borderColor: '#3C52EF',
    borderRadius: 8
  }
}))

const GrinScanIcon = ({ isSelected, theme, trackStatus, oralScore, txTrackerSummary }) => {
  const classes = useStyles({ theme, trackStatus })

  const themeIconRenderers = useMemo(
    () => ({
      [MiniTimelineThemes.ScanTracking]: () => <ScanTrackingIcon trackStatus={trackStatus} />,
      [MiniTimelineThemes.OralScore]: () => <OralScoreIcon score={oralScore} />,
      [MiniTimelineThemes.TxTracker]: () => <TxTrackerIcon txTrackerSummary={txTrackerSummary} />
    }),
    [oralScore, trackStatus, txTrackerSummary]
  )

  const themeIconSelectedIcon = useMemo(
    () => ({
      [MiniTimelineThemes.ScanTracking]: () => classes.selected,
      [MiniTimelineThemes.OralScore]: () => classes.selected,
      [MiniTimelineThemes.TxTracker]: () => classes.squareSelected
    }),
    [classes.selected, classes.squareSelected]
  )

  const icon = useMemo(() => {
    const iconRenderer = themeIconRenderers[theme]
    if (!iconRenderer) {
      return null
    }

    const icon = iconRenderer()
    return icon
  }, [themeIconRenderers, theme])

  const selectedIcon = useMemo(() => {
    const selectedIconRenderer = themeIconSelectedIcon[theme]
    if (!selectedIconRenderer) {
      return null
    }

    const icon = selectedIconRenderer()
    return icon
  }, [theme, themeIconSelectedIcon])

  return <span className={[classes.scanTimelineIcon, isSelected ? selectedIcon : ''].join(' ')}>{icon}</span>
}

export default GrinScanIcon
