import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import ScanView from 'components/common/ScanView/ScanView'

const useStyles = makeStyles(theme => ({
  media: {
    height: 'unset'
  }
}))

const ScanSummaryWithVideo = ({ scanVideo, scanSummaryData = '{}' }) => {
  const classes = useStyles()

  const [selectedItemIndex, setSelectedItemIndex] = useState(0)

  return (
    <ScanView
      openImagesInViewer
      grinScanVideo={scanVideo}
      scanSummaryData={scanSummaryData}
      selectedOptionIndex={selectedItemIndex}
      onOptionSelect={setSelectedItemIndex}
      mediaClassName={classes.media}
      hidePreventive
    />
  )
}

export default ScanSummaryWithVideo
