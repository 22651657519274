import React, { useCallback, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import LinkButton from 'components/common/buttons/LinkButton'
import { useTranslation } from 'react-i18next'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import TextAreaAutosize from 'components/common/TextareaAutosize'

const Modes = {
  Edit: 'edit',
  View: 'view'
}

const useStyles = props =>
  makeStyles({
    container: {
      margin: '30px 0px 15px 0px'
    },
    addNote: {
      fontSize: 14
    },
    input: {
      border: 'none!important'
    },
    messageInputContainer: {
      width: 640,
      border: '1px solid var(--bg-color-65)',
      backgroundColor: ({ mode }) => (mode === Modes.View ? 'var(--bg-color-2)' : undefined),
      borderRadius: 10
    },
    button: {
      textDecoration: 'underline'
    }
  })(props)

const CategoryNote = ({ note, onChange, containerClassName = '', customTitle = '', titleClassName = '' }) => {
  const { t } = useTranslation()

  const [text, setText] = useState('')
  const [mode, setMode] = useState(Modes.View)

  const classes = useStyles({ mode })

  const handleSave = useCallback(() => {
    onChange(text)
    setMode(Modes.View)
  }, [onChange, text])

  const handleCancel = useCallback(() => {
    setText(note)
    setMode(Modes.View)
  }, [note])

  useEffect(() => {
    setText(note)
  }, [note])

  return (
    <div className={[classes.container, containerClassName].join(' ')}>
      {mode === Modes.View && !note ? (
        <LinkButton
          label={t('pages.accountSettings.practiceGuidelines.note.label')}
          labelClassName={classes.addNote}
          onClick={() => setMode(Modes.Edit)}
        />
      ) : (
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <DazzedParagraph14 bold className={titleClassName}>
              {customTitle || t('pages.accountSettings.practiceGuidelines.note.title')}
            </DazzedParagraph14>
          </Grid>
          <Grid item className={classes.messageInputContainer}>
            <TextAreaAutosize
              isDisabled={mode === Modes.View}
              inputStyle={{ fontSize: 14, fontWeight: 500, height: 100, overflow: 'auto' }}
              rowsMax={4}
              style={{ bright: true }}
              className={classes.input}
              keepErrorContainerWhenInactive={false}
              value={text || ''}
              setValue={value => setText(value)}
            />
          </Grid>
          <Grid item>
            <Grid container alignItems="center" spacing={1}>
              {mode === Modes.Edit ? (
                <>
                  <Grid item>
                    <LinkButton label={t('general.cancel')} onClick={handleCancel} labelClassName={classes.button} />
                  </Grid>
                  <Grid item>
                    <LinkButton label={t('general.save')} onClick={handleSave} labelClassName={classes.button} />
                  </Grid>
                </>
              ) : (
                <Grid item>
                  <LinkButton
                    label={t('general.edit')}
                    onClick={() => setMode(Modes.Edit)}
                    labelClassName={classes.button}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  )
}

export default CategoryNote
