import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Tooltip from 'components/common/Tooltip'
import ScanActionButton from 'components/Patients/Timeline/ScanActionButton'
import React, { useCallback, useMemo } from 'react'
import { useResizeDetector } from 'react-resize-detector/build/withPolyfill'
import { scrollBar } from 'styles/theme'
import { trackEvent } from 'utils/analyticsUtils'
import useScanActions from './useScanActions'

const useStyles = makeStyles(theme => ({
  scanActionsRoot: {
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    ...scrollBar,
    '&::-webkit-scrollbar': {
      height: 4,
      background: 'var(--bg-color-timelineV2)'
    }
  },
  scanActionButton: {
    margin: '0 10px 0 0!important',
    width: '100%',
    padding: 0,
    borderRight: '1px solid rgba(227, 236, 245, 1)',
    '&.lastItem': {
      marginRight: '0px!important',
      border: 0
    }
  },
  scanActionLabel: {
    fontWeight: 'bold!important'
  },
  scanActionIcon: {
    marginLeft: 10
  }
}))

const MINIMIZED_BUTTON_WIDTH = 40
const NORMAL_BUTTON_WIDTH = 135

const ScanActions = ({ analyticsPayload, scanNumber, displayedScanId, timelineItemScanIds, withAligner }) => {
  const classes = useStyles()

  const { width, ref } = useResizeDetector()

  const scanActions = useScanActions({
    analyticsPayload,
    scanNumber,
    selectedScanId: displayedScanId,
    timelineItemScanIds,
    withAligner
  })

  const maximizedButtons = useMemo(() => {
    const totalActions = scanActions.length

    const estimateWidth = normalButtons =>
      normalButtons * NORMAL_BUTTON_WIDTH + (totalActions - normalButtons) * MINIMIZED_BUTTON_WIDTH

    let normal = 0

    // Calculate maximum number of normal buttons so they fit in the container without causing scrolling
    while (estimateWidth(normal + 1) <= width && normal < totalActions) {
      normal++
    }

    return normal
  }, [width, scanActions.length])

  const handleActionClicked = useCallback(
    action => {
      trackEvent(`Scan feed - scan action clicked`, {
        ...analyticsPayload,
        action: action.analyticsName || action.label
      })
      action.onClick()
    },
    [analyticsPayload]
  )

  return (
    <Grid
      container
      className={classes.scanActionsRoot}
      alignItems="center"
      justifyContent="flex-start"
      wrap="nowrap"
      ref={ref}
    >
      {scanActions.map((action, i) => (
        <Grid item key={`scan-action-${action.elementId}`}>
          <Tooltip value={action.tooltip || action.label} placement="bottom">
            <ScanActionButton
              key={`scan-action-${action.elementId}`}
              icon={action.icon}
              onClick={() => handleActionClicked(action)}
              elementId={action.elementId}
              className={[classes.scanActionButton, i === scanActions.length - 1 ? 'lastItem' : ''].join(' ')}
              labelClassName={classes.scanActionLabel}
              iconClassName={classes.scanActionIcon}
              flexWrap="nowrap"
            >
              {i < maximizedButtons ? action.label : ''}
            </ScanActionButton>
          </Tooltip>
        </Grid>
      ))}
    </Grid>
  )
}

export default ScanActions
