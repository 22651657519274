import { API, graphqlOperation } from 'aws-amplify'
import { SortDirections } from 'consts/filterConsts'
import { searchDoctorSearchModels, searchPatientSearchModels } from 'graphql/quickActions.graphql'
import _ from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { buildDoctorsSearchFilter, buildPatientSearchFilter } from 'utils/searchUtils'

const resultMappers = {
  patient: patient => ({
    type: 'patient',
    ...patient,
    status: patient.status?.status || { type: patient.statusType }
  }),
  doctor: doctorSM => ({
    type: 'doctor',
    ...doctorSM,
    practiceName: doctorSM.practiceName || doctorSM.accountOwner?.clinic?.practiceName,
    status: doctorSM.accessType === 'owner' ? 'confirmed' : !doctorSM.doctor ? 'invited' : doctorSM.doctor?.auth_status
  }),
  grinScan: grinScan => ({
    type: 'grinScan',
    id: grinScan.id
  })
}

export default ({ loadMore, setLoadMore }) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [searchResults, setSearchResults] = useState(null)

  const getDebouncedSearchResults = useDebouncedCallback(async () => {
    if (!searchTerm) {
      setSearchResults([])
    }

    setIsLoading(true)

    const [patients, doctors] = await Promise.all([
      API.graphql(
        graphqlOperation(searchPatientSearchModels, {
          limit: 5,
          filter: buildPatientSearchFilter(searchTerm)
        })
      ).then(({ data }) => data?.searchPatientSearchModels?.items?.map(p => ({ ...p, type: 'patient' })) || []),
      API.graphql(
        graphqlOperation(searchDoctorSearchModels, {
          limit: loadMore ? 50 : 5,
          filter: buildDoctorsSearchFilter({ filterTerm: searchTerm, includeMembers: true }),
          sort: {
            field: 'accessType',
            direction: SortDirections.Desc
          }
        })
      ).then(({ data }) => data?.searchDoctorSearchModels?.items?.map(p => ({ ...p, type: 'doctor' })) || [])
    ])

    setSearchResults([...patients, ...doctors])
    setIsLoading(false)
  }, 1000)

  useEffect(() => {
    if (searchTerm?.length > 2) {
      getDebouncedSearchResults()
    }
  }, [searchTerm, getDebouncedSearchResults, loadMore])

  const mappedSearchResults = useMemo(
    () => searchResults?.map(result => resultMappers[result.type](result)),
    [searchResults]
  )

  const searchResultsGroups = useMemo(
    () => (mappedSearchResults ? _.groupBy(mappedSearchResults, 'type') : null),
    [mappedSearchResults]
  )

  return {
    searchResultsGroups,
    searchTerm,
    isLoading,
    setSearchTerm,
    setSearchResults
  }
}
