import { Fade, Paper, Popper } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    zIndex: 1300
  },
  paper: {
    position: 'relative',
    boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.15)'
  },
  arrowContainer: {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    top: ({ arrowPlacement }) => (arrowPlacement === 'bottom' ? '97%' : '50%'),
    left: ({ arrowPlacement }) => (arrowPlacement === 'left' ? 0 : arrowPlacement === 'bottom' ? '50%' : '100%'),
    bottom: ({ arrowPlacement }) => (arrowPlacement === 'left' ? '50' : '100%')
  }
}))

const GrinPopper = React.forwardRef(
  (
    {
      id = '',
      children,
      onClose,
      transformOrigin,
      className = '',
      triggerComponent,
      anchorOrigin,
      onOpen,
      open,
      automaticallyOpen = false,
      customAnchorEl,
      style = {},
      placement = 'bottom',
      onFocus = () => {},
      arrowComponent,
      arrowPlacement = 'right',
      onEnter = () => {},
      zIndex,
      modifiers
    },
    ref
  ) => {
    const classes = useStyles({ arrowPlacement })

    const [anchorEl, setAnchorEl] = useState(null)
    const triggerComponentRef = useRef()

    const isOpen = useMemo(() => open && Boolean(anchorEl), [anchorEl, open])

    const handleClose = useCallback(
      e => {
        setAnchorEl(null)

        if (onClose) {
          onClose(e)
        }
      },
      [onClose]
    )

    const handleOpen = useCallback(
      e => {
        if (!!isOpen) {
          return
        }
        setAnchorEl(e.currentTarget)
        if (onOpen) {
          onOpen(e)
        }
      },
      [isOpen, onOpen]
    )

    useEffect(() => {
      setAnchorEl(customAnchorEl || (automaticallyOpen && triggerComponentRef.current))
    }, [customAnchorEl, automaticallyOpen])

    return (
      <>
        {triggerComponent && (
          <span id={id} onClick={handleOpen} ref={triggerComponentRef}>
            {triggerComponent}
          </span>
        )}
        <Popper
          ref={ref}
          transition
          anchorEl={anchorEl}
          open={isOpen}
          onClose={handleClose}
          onFocus={onFocus}
          className={classes.root}
          placement={placement}
          onClick={e => e.stopPropagation()}
          style={{ zIndex }}
          modifiers={modifiers}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={200} onEnter={onEnter}>
              <Paper
                variant="outlined"
                classes={{
                  root: `${classes.paper} ${className}`
                }}
              >
                {arrowComponent && <div className={classes.arrowContainer}>{arrowComponent}</div>}
                {children}
              </Paper>
            </Fade>
          )}
        </Popper>
      </>
    )
  }
)

export default GrinPopper
