import * as React from 'react'

const Center = ({ isSelected }) => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="24" cy="24" r="24" fill={isSelected ? '#B9C7FF' : '#DEEBFA'} />
    <path
      d="M9 19.6784C9 17.2439 10.3065 15.0172 12.654 14.372C15.155 13.6847 19.0212 13 24.5 13C29.9788 13 33.845 13.6847 36.346 14.372C38.6935 15.0172 40 17.2439 40 19.6784V29.1205C40 32.1307 38.0565 34.7662 35.0826 35.2321C32.5034 35.6362 28.9466 36 24.5 36C20.0534 36 16.4966 35.6362 13.9174 35.2321C10.9435 34.7662 9 32.1307 9 29.1205V19.6784Z"
      fill={isSelected ? '#4151E6' : '#8FB1DA'}
    />
    <path
      d="M11.7669 29C9.95713 29 9.97487 26.4077 10.1267 24.9563C10.1827 24.4201 10.6867 24.0706 11.2184 24.1595L12.5082 24.3753C12.9492 24.4491 13.2874 24.8059 13.3024 25.2528C13.3456 26.5337 13.2376 29 11.7669 29Z"
      fill="white"
      stroke={isSelected ? '#4151E6' : '#8FB1DA'}
      strokeWidth="0.5"
    />
    <path
      d="M15.0132 29.5C13.2034 29.5 13.2212 26.9077 13.373 25.4563C13.429 24.9201 13.933 24.5706 14.4647 24.6595L15.7545 24.8753C16.1955 24.9491 16.5337 25.3059 16.5487 25.7528C16.5919 27.0337 16.4839 29.5 15.0132 29.5Z"
      fill="white"
      stroke={isSelected ? '#4151E6' : '#8FB1DA'}
      strokeWidth="0.5"
    />
    <path
      d="M18.5148 30C16.3039 30 16.3866 27.3109 16.5879 25.8768C16.6586 25.3728 17.1312 25.0509 17.6357 25.1183L19.7201 25.3967C20.1751 25.4574 20.5298 25.8193 20.5357 26.2782C20.5525 27.566 20.3425 30 18.5148 30Z"
      fill="white"
      stroke={isSelected ? '#4151E6' : '#8FB1DA'}
      strokeWidth="0.5"
    />
    <path
      d="M22.4999 30.5C20.2418 30.5 20.3918 27.6672 20.6041 26.2661C20.6728 25.8125 21.0721 25.5 21.531 25.5L23.6629 25.5C24.1381 25.5 24.5466 25.8345 24.5947 26.3073C24.7384 27.7219 24.736 30.5 22.4999 30.5Z"
      fill="white"
      stroke={isSelected ? '#4151E6' : '#8FB1DA'}
      strokeWidth="0.5"
    />
    <path
      d="M37.3796 29C39.1894 29 39.1716 26.4077 39.0198 24.9563C38.9638 24.4201 38.4598 24.0706 37.9281 24.1595L36.6383 24.3753C36.1973 24.4491 35.8591 24.8059 35.8441 25.2528C35.8009 26.5337 35.9089 29 37.3796 29Z"
      fill="white"
      stroke={isSelected ? '#4151E6' : '#8FB1DA'}
      strokeWidth="0.5"
    />
    <path
      d="M34.1333 29.5C35.943 29.5 35.9253 26.9077 35.7735 25.4563C35.7174 24.9201 35.2135 24.5706 34.6817 24.6595L33.392 24.8753C32.9509 24.9491 32.6128 25.3059 32.5977 25.7528C32.5546 27.0337 32.6625 29.5 34.1333 29.5Z"
      fill="white"
      stroke={isSelected ? '#4151E6' : '#8FB1DA'}
      strokeWidth="0.5"
    />
    <path
      d="M30.6317 30C32.8426 30 32.7599 27.3109 32.5586 25.8768C32.4879 25.3728 32.0153 25.0509 31.5108 25.1183L29.4263 25.3967C28.9714 25.4574 28.6167 25.8193 28.6107 26.2782C28.594 27.566 28.804 30 30.6317 30Z"
      fill="white"
      stroke={isSelected ? '#4151E6' : '#8FB1DA'}
      strokeWidth="0.5"
    />
    <path
      d="M26.6465 30.5C28.9047 30.5 28.7547 27.6672 28.5424 26.2661C28.4737 25.8125 28.0744 25.5 27.6155 25.5L25.4836 25.5C25.0084 25.5 24.5999 25.8345 24.5518 26.3073C24.4081 27.7219 24.4105 30.5 26.6465 30.5Z"
      fill="white"
      stroke={isSelected ? '#4151E6' : '#8FB1DA'}
      strokeWidth="0.5"
    />
    <path
      d="M27 20C29.4978 20 30.213 22.5465 30.4178 24.0049C30.4946 24.5518 30.0523 25 29.5 25H25.405C24.8912 25 24.4635 24.6111 24.4694 24.0974C24.486 22.6572 24.813 20 27 20Z"
      fill="white"
      stroke={isSelected ? '#4151E6' : '#8FB1DA'}
      strokeWidth="0.5"
    />
    <path
      d="M22 20C19.5022 20 18.787 22.5465 18.5822 24.0049C18.5054 24.5518 18.9477 25 19.5 25H23.595C24.1088 25 24.5365 24.6111 24.5306 24.0974C24.514 22.6572 24.187 20 22 20Z"
      fill="white"
      stroke={isSelected ? '#4151E6' : '#8FB1DA'}
      strokeWidth="0.5"
    />
    <path
      d="M32.5984 19.5C34.7146 19.5 35.2874 22.1594 35.4424 23.5992C35.4974 24.1098 35.111 24.5391 34.6 24.5905L31.546 24.8975C30.9868 24.9537 30.4949 24.5384 30.5009 23.9764C30.5179 22.3854 30.7971 19.5 32.5984 19.5Z"
      fill="white"
      stroke={isSelected ? '#4151E6' : '#8FB1DA'}
      strokeWidth="0.5"
    />
    <path
      d="M16.4016 19.5C14.2854 19.5 13.7126 22.1594 13.5576 23.5992C13.5026 24.1098 13.889 24.5391 14.4 24.5905L17.454 24.8975C18.0132 24.9537 18.5051 24.5384 18.4991 23.9764C18.4821 22.3854 18.2029 19.5 16.4016 19.5Z"
      fill="white"
      stroke={isSelected ? '#4151E6' : '#8FB1DA'}
      strokeWidth="0.5"
    />
    <path
      d="M37.1788 19C38.8419 19 39.3134 21.3102 39.4471 22.62C39.4983 23.1215 39.1248 23.5471 38.6247 23.61L36.573 23.8678C36.0021 23.9395 35.4923 23.5166 35.5024 22.9413C35.5282 21.4738 35.7793 19 37.1788 19Z"
      fill="white"
      stroke={isSelected ? '#4151E6' : '#8FB1DA'}
      strokeWidth="0.5"
    />
    <path
      d="M11.8212 19C10.1581 19 9.68656 21.3102 9.55289 22.62C9.50172 23.1215 9.87518 23.5471 10.3753 23.61L12.427 23.8678C12.9979 23.9395 13.5077 23.5166 13.4976 22.9413C13.4718 21.4738 13.2207 19 11.8212 19Z"
      fill="white"
      stroke={isSelected ? '#4151E6' : '#8FB1DA'}
      strokeWidth="0.5"
    />
  </svg>
)

export default Center
