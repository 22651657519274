import * as React from 'react'

const LowerArch = ({ isSelected }) => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="24" cy="24" r="24" transform="rotate(-180 24 24)" fill={isSelected ? '#B9C7FF' : '#DEEBFA'} />
    <path
      d="M23.8916 38.3066C13.0744 38.3066 8.77004 27.2128 7.07589 19.305C6.3095 15.7276 9.89958 13.1199 13.3769 14.2568C16.9318 15.4191 21.033 16.5115 23.8916 16.5115C26.7748 16.5115 30.9708 15.4002 34.5977 14.2266C38.0818 13.0994 41.6551 15.7351 40.8601 19.3097C39.1012 27.2172 34.7066 38.3066 23.8916 38.3066Z"
      fill={isSelected ? '#4151E6' : '#8FB1DA'}
    />
    <path
      d="M28.3945 35.3301C28.2127 34.39 27.3171 33.109 25.8774 33.4732C24.4605 33.8318 24.19 35.3298 24.3718 36.2699C24.5536 37.2099 25.5027 37.251 26.6135 36.9915C27.7244 36.732 28.5763 36.2701 28.3945 35.3301Z"
      fill="white"
    />
    <path
      d="M32.1478 32.6545C31.62 31.7308 30.35 30.6536 29.2712 31.435C28.2094 32.204 28.5744 33.8332 29.1023 34.7569C29.6301 35.6807 30.4531 35.4571 31.2941 34.8765C32.1351 34.2959 32.6757 33.5782 32.1478 32.6545Z"
      fill="white"
    />
    <path
      d="M31.5968 30.621C31.8326 31.1617 32.3719 31.4869 32.9604 31.5281L33.8394 31.5898C34.5584 31.6402 35.2205 31.147 35.2349 30.4264C35.2396 30.189 35.2281 29.9515 35.1873 29.7482C35.1169 29.3977 34.9297 29.0389 34.7363 28.7407C34.4146 28.2445 33.9319 27.872 33.3707 27.6856C33.1289 27.6053 32.8711 27.5429 32.6537 27.5429C31.7265 27.5429 31.0953 28.4631 31.2297 29.3804C31.2487 29.5098 31.2707 29.6344 31.2964 29.7482C31.3617 30.0377 31.4771 30.3464 31.5968 30.621Z"
      fill="white"
    />
    <path
      d="M35.022 27.5331C35.8744 28.1327 36.995 27.8237 37.4102 26.8745L38.0235 25.4723C38.4281 24.5474 37.9851 23.4164 37.0652 23.0258L36.274 22.6899C35.3558 22.3001 34.3335 22.6072 33.7857 23.4375C32.9935 24.6382 33.3332 26.3451 34.5322 27.1885L35.022 27.5331Z"
      fill="white"
    />
    <path
      d="M37.5291 21.7982C38.5814 22.0166 39.5711 21.2691 39.647 20.1984L39.7786 18.3422C39.8533 17.2886 39.0093 16.3811 37.9558 16.3824L36.8935 16.3837C35.8957 16.3849 35.0216 17.0243 34.72 17.9735L34.6708 18.1287C34.1835 19.6628 35.1509 21.3045 36.736 21.6335L37.5291 21.7982Z"
      fill="white"
    />
    <path
      d="M19.4828 35.3301C19.6646 34.39 20.5602 33.109 21.9998 33.4732C23.4168 33.8318 23.6873 35.3298 23.5055 36.2699C23.3237 37.2099 22.3746 37.251 21.2637 36.9915C20.1529 36.732 19.301 36.2701 19.4828 35.3301Z"
      fill="white"
    />
    <path
      d="M15.7294 32.6545C16.2573 31.7308 17.5273 30.6536 18.6061 31.435C19.6679 32.204 19.3029 33.8332 18.775 34.7569C18.2471 35.6807 17.4242 35.4571 16.5831 34.8765C15.7421 34.2959 15.2016 33.5782 15.7294 32.6545Z"
      fill="white"
    />
    <path
      d="M16.2805 30.621C16.0447 31.1617 15.5054 31.4869 14.9169 31.5281L14.0378 31.5898C13.3188 31.6402 12.6568 31.147 12.6424 30.4264C12.6376 30.189 12.6491 29.9515 12.69 29.7482C12.7604 29.3977 12.9476 29.0388 13.1409 28.7407C13.4626 28.2445 13.9454 27.872 14.5066 27.6856C14.7484 27.6053 15.0062 27.5429 15.2236 27.5429C16.1507 27.5429 16.7819 28.4631 16.6476 29.3804C16.6286 29.5098 16.6065 29.6344 16.5809 29.7482C16.5156 30.0377 16.4002 30.3464 16.2805 30.621Z"
      fill="white"
    />
    <path
      d="M12.8558 27.5331C12.0033 28.1327 10.8828 27.8237 10.4676 26.8745L9.85423 25.4723C9.44965 24.5474 9.89267 23.4164 10.8125 23.0258L11.6038 22.6899C12.522 22.3001 13.5442 22.6072 14.0921 23.4375C14.8843 24.6382 14.5446 26.3451 13.3456 27.1885L12.8558 27.5331Z"
      fill="white"
    />
    <path
      d="M10.3477 21.7982C9.2954 22.0166 8.3057 21.2691 8.22978 20.1984L8.09814 18.3422C8.02343 17.2886 8.8675 16.3811 9.92097 16.3824L10.9832 16.3837C11.9811 16.3849 12.8552 17.0243 13.1567 17.9735L13.206 18.1287C13.6933 19.6628 12.7259 21.3045 11.1408 21.6335L10.3477 21.7982Z"
      fill="white"
    />
  </svg>
)

export default LowerArch
