import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import OggHeading40 from 'components/common/text/OggHeading40'
import ProfilePicture from 'components/common/ProfilePicture'
import { CLINIC_LOGO_FOLDER } from 'consts/settings'
import { PLAN_GROUP_COLORS } from 'consts/grinPlanConsts'
import GrinChip from 'components/common/GrinChip'
import { getFullAddress } from 'utils/mappers/doctorsMappers'
import { toUTC } from 'utils/stringUtils'

export const useStyles = makeStyles({
  container: {
    margin: '40px 30px 25px 25px'
  },
  clinicLogo: {
    borderRadius: 0,
    height: 82,
    width: 82
  },
  centralSection: {
    flex: 1
  },
  practiceName: {
    fontSize: '36px'
  },
  timezone: {
    color: 'var(--text-color-11)',
    marginLeft: 5
  },
  stats: {
    textAlign: 'center',
    padding: 10
  },
  leftStats: {
    borderRight: '1px solid var(--border-color-21)'
  },
  leftStatsLabel: {
    maxWidth: 100
  },
  rightStatsLabel: {
    maxWidth: 70
  },
  statsNumber: {
    fontWeight: 600,
    marginTop: 5
  }
})

const EditDoctorDialogHeader = ({ doctor, activePracticeMembers, activeUsers }) => {
  const { clinic, user } = doctor
  const classes = useStyles()
  const { t } = useTranslation()

  const grinPlans = useSelector(state => state.billingReducer.grinPlans)
  const grinPlanKey = user?.grinPlanKey
  const planGroup = grinPlans[grinPlanKey]?.planGroup

  const mapToFullPath = useCallback(s3Object => {
    return !s3Object
      ? null
      : {
          ...s3Object,
          key: s3Object ? `${CLINIC_LOGO_FOLDER}/${s3Object.key}` : null
        }
  }, [])

  const UTC = useMemo(() => toUTC(doctor.user?.timezone), [doctor])

  return (
    <div className={classes.container}>
      <Grid container spacing={3} alignItems="center">
        <Grid item>
          <ProfilePicture
            photo={mapToFullPath(clinic?.logo)}
            name={clinic.practiceName}
            className={classes.clinicLogo}
            size="custom"
          />
        </Grid>
        <Grid item className={classes.centralSection}>
          <OggHeading40 textAlign="left" className={classes.practiceName}>
            {clinic.practiceName}
          </OggHeading40>
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <GrinChip text={t(`grinPlans.${planGroup}`)} backgroundColor={PLAN_GROUP_COLORS[planGroup]?.color} />
            </Grid>
            <Grid item>
              <DazzedParagraph12>
                {getFullAddress(clinic)}
                <span className={classes.timezone}>{`(${UTC})`}</span>
              </DazzedParagraph12>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item className={[classes.stats, classes.leftStats].join(' ')}>
              <DazzedParagraph12 className={classes.leftStatsLabel}>
                {t('dialogs.doctorCardEditor.activePracticeMembers')}
              </DazzedParagraph12>
              <DazzedParagraph14 textAlign="center" className={classes.statsNumber}>
                {activePracticeMembers}
              </DazzedParagraph14>
            </Grid>
            <Grid item className={classes.stats}>
              <DazzedParagraph12 className={classes.rightStatsLabel}>
                {t('dialogs.doctorCardEditor.paidPatients')}
              </DazzedParagraph12>
              <DazzedParagraph14 textAlign="center" className={classes.statsNumber}>
                {activeUsers}
              </DazzedParagraph14>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default EditDoctorDialogHeader
