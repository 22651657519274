import { makeStyles } from '@material-ui/styles'
import React from 'react'
import { Route, Switch } from 'react-router'
import ROUTES from '../../consts/routesConsts'
import AccountSettingsNavigation from './AccountSettingsNavigation'
import NotificationSettings from './NotificationsCenter/NotificationSettings'
import Billing from './Billing'
import EditProfile from './EditProfile'
import Services from './Services/Services'
import CustomMessages from './CustomMessages/CustomMessages'
import PracticeProfile from './PracticeProfile'
import PracticeMembers from './PracticeMembers/PracticeMembers'
import AddPracticeMember from './PracticeMembers/AddPracticeMember/AddPracticeMember'
import About from './About'
import AdvancedActions from './AdvancedActions/AdvancedActions'
import Automations from './Services/Automations/Automations'
import PracticeGuidelines from './PracticeGuidelines/PracticeGuidelines'
import DSOMembers from './DSOMembers/DSOMembers'
import DSOAddMember from './DSOMembers/DSOAddMember/DSOAddMember'
import MFASettings from './MFASettings/MFASettings'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    paddingLeft: 150,
    marginTop: 100
  },
  pageContainer: {
    marginRight: 80,
    marginLeft: 80
  }
}))

const AccountSettings = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <AccountSettingsNavigation />
      <div className={classes.pageContainer}>
        <Switch>
          <Route exact path={ROUTES.ACCOUNT_SETTINGS} component={EditProfile} />
          <Route path={ROUTES.BILLING} component={Billing} />
          <Route exact path={ROUTES.NOTIFICATION_SETTINGS} component={NotificationSettings} />
          <Route exact path={ROUTES.SERVICES} component={Services} />
          <Route exact path={ROUTES.CUSTOM_MESSAGES} component={CustomMessages} />
          <Route exact path={ROUTES.PRACTICE_PROFILE} component={PracticeProfile} />
          <Route exact path={ROUTES.DSO_MEMBERS} component={DSOMembers} />
          <Route exact path={ROUTES.DSO_MEMBERS_ADD_USER} component={DSOAddMember} />
          <Route exact path={ROUTES.PRACTICE_MEMBERS} component={PracticeMembers} />
          <Route exact path={ROUTES.PRACTICE_MEMBERS_ADD_MEMBER} component={AddPracticeMember} />
          <Route exact path={ROUTES.ABOUT} component={About} />
          <Route exact path={ROUTES.ADVANCED_ACTIONS} component={AdvancedActions} />
          <Route exact path={ROUTES.AUTOMATIONS} component={Automations} />
          <Route exact path={ROUTES.PRACTICE_GUIDELINES} component={PracticeGuidelines} />
          <Route exact path={ROUTES.MFA_SETTINGS} component={MFASettings} />
        </Switch>
      </div>
    </div>
  )
}

export default AccountSettings
