import { makeStyles } from '@material-ui/styles'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import TextAreaAutosize from 'components/common/TextareaAutosize'
import { useCreateNoteMutation } from 'store/modules/PatientNotes'
import { trackEvent } from 'utils/analyticsUtils'
import { mapToCreateNoteInput } from 'utils/mappers/noteMappers'
import PrimaryButton from 'components/common/buttons/PrimaryButton'
import { NoteInputPlusIcon } from 'components/common/icons'
import useShowSnackbar from 'hooks/useShowSnackbar'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex'
  },
  inputContainer: {
    flex: 1,
    borderRadius: 40,
    border: '1px solid var(--text-color-4)',
    overflow: 'hidden',
    padding: '4px 20px',
    backgroundColor: ({ isLoading }) => (isLoading ? 'var(--bg-color-2)' : 'var(--bg-color-10)')
  },
  noteInput: {
    border: 'none!important',
    fontSize: '14px !important'
  },
  addButton: {
    marginLeft: 10
  }
}))

const NoteInput = ({ note, setNote }) => {
  const { t } = useTranslation()

  const [createNote, { isLoading, isError: isFailedCreatingNote }] = useCreateNoteMutation()
  useShowSnackbar({
    isOpen: isFailedCreatingNote,
    message: t('pages.patients.patientSwitchableView.notes.createNoteError')
  })
  const classes = useStyles({ isLoading })

  const handleAddNote = useCallback(async () => {
    const payload = mapToCreateNoteInput([note])

    trackEvent('Patient notes - Note added', payload)
    await createNote({ variables: payload })

    setNote('')
  }, [createNote, note, setNote])

  return (
    <div className={classes.container}>
      <div className={classes.inputContainer}>
        <TextAreaAutosize
          rowsMax={4}
          style={{ bright: true }}
          className={classes.noteInput}
          placeholder={t('dialogs.notes.addNote')}
          keepErrorContainerWhenInactive={false}
          value={note}
          isDisabled={isLoading}
          setValue={setNote}
          id="addNote-input"
        />
      </div>
      <div className={classes.addButton}>
        <PrimaryButton
          icon={<NoteInputPlusIcon style={{ marginRight: 5 }} />}
          disableIconMargin
          isLoading={isLoading}
          label={t('general.add')}
          onClick={handleAddNote}
          width={80}
          disabled={!note.trim()}
          id="addNote-button"
        />
      </div>
    </div>
  )
}

export default NoteInput
