import Actions from 'actions'
import {
  GuidelineCategories,
  GuidelineItems,
  ActionTypes,
  PatientBriefActions,
  PatientBriefModalMode
} from 'consts/hiToolsConsts'
import { isEmpty } from 'lodash'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

export default ({ practiceGuidelines = {} }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const categoryValidators = useMemo(
    () => ({
      [GuidelineCategories.Elastics]: categoryValues =>
        isEmpty(categoryValues?.items?.[PatientBriefActions.Elastics]) &&
        t('dialogs.patientBrief.validationErrors.pleaseSetUpElastics'),
      [GuidelineCategories.TxGoals]: categoryValues =>
        !Object.values(categoryValues.items).some(txGoal => txGoal.isChecked) &&
        t('dialogs.patientBrief.validationErrors.noTxGoalsSelected'),
      [GuidelineCategories.IPR]: categoryValues =>
        !categoryValues.items[PatientBriefActions.AlignerNumberReached] &&
        t('dialogs.patientBrief.validationErrors.missingAlignerNo'),
      [GuidelineCategories.RPE]: categoryValues =>
        !categoryValues.items[PatientBriefActions.NumberOfTurns] &&
        t('dialogs.patientBrief.validationErrors.missingNumberOfTurns')
    }),
    [t]
  )

  const getDefaultItemsForCategory = useCallback(
    category => {
      switch (category) {
        case GuidelineCategories.AlignersTxTracking: {
          const categoryItems = practiceGuidelines.categories?.[GuidelineCategories.AlignersTxTracking]?.items
          return {
            typicalAdvanceFrequency: categoryItems?.[GuidelineItems.TypicalAdvanceFrequency]?.frequency
          }
        }
        case GuidelineCategories.BracesTxTracking: {
          const categoryItems = practiceGuidelines.categories?.[GuidelineCategories.BracesTxTracking]?.items
          return {
            wireSequence:
              categoryItems?.[GuidelineItems.ReadinessForWireChange]?.[ActionTypes.TypicalWireSequence]?.value
          }
        }

        default:
          return {}
      }
    },
    [practiceGuidelines]
  )

  const getDefaultCategories = useCallback(({ treatmentType }) => {
    let categories = [GuidelineCategories.OralHygiene]
    if (treatmentType === 'Aligners') {
      categories.push(GuidelineCategories.AlignersTxTracking)
    } else if (treatmentType === 'Braces') {
      categories.push(GuidelineCategories.BracesTxTracking)
    }

    return categories
  }, [])

  const buildDefaultPatientBrief = useCallback(
    ({ treatmentType }) => {
      if (!practiceGuidelines?.categories) {
        return {}
      }

      const defaultCategories = getDefaultCategories({ treatmentType })
      const categories = Object.keys(practiceGuidelines.categories)
        .filter(category => practiceGuidelines.categories[category].isEnabled)
        .reduce(
          (patientBrief, category) => ({
            ...patientBrief,
            [category]: {
              isEnabled: defaultCategories.includes(category),
              note: '',
              items: getDefaultItemsForCategory(category)
            }
          }),
          {}
        )

      return {
        categories,
        customTitle: '',
        practiceNotes: ''
      }
    },
    [practiceGuidelines, getDefaultItemsForCategory, getDefaultCategories]
  )

  const createPatientBrief = useCallback(
    ({ patientId, customTitle, practiceNotes, categories, a_doctor, doctorId }) => {
      dispatch(
        Actions.createPatientBrief({
          patientId,
          customTitle,
          practiceNotes,
          categories,
          a_doctor,
          doctorId
        })
      )
    },
    [dispatch]
  )

  const validatePatientBrief = useCallback(
    values => {
      const categoryErrors = Object.keys(values.categories)
        .filter(category => values.categories[category].isEnabled && categoryValidators[category])
        .map(category => {
          const categoryValues = values.categories[category]
          const validator = categoryValidators[category]
          return { category, error: validator(categoryValues) }
        })
        .filter(({ error }) => !!error)
        .reduce(
          (final, { category, error }) => ({
            ...final,
            [category]: error
          }),
          {}
        )

      return {
        categories: categoryErrors
      }
    },
    [categoryValidators]
  )

  const loadPatientBriefValues = useCallback(
    ({ patientBrief, mode }) => {
      let categories = JSON.parse(patientBrief.categories || '{}')
      if (mode === PatientBriefModalMode.View) {
        categories = Object.keys(categories)
          .filter(category => categories[category].isEnabled)
          .reduce((final, category) => ({ ...final, [category]: categories[category] }), {})
      } else {
        const existingCategoryKeys = Object.keys(categories)
        const newCategories = Object.keys(practiceGuidelines.categories)
          .filter(
            categoryKey =>
              !existingCategoryKeys.includes(categoryKey) && practiceGuidelines.categories[categoryKey]?.isEnabled
          )
          .reduce(
            (final, category) => ({
              ...final,
              [category]: {
                ...practiceGuidelines.categories[category],
                isEnabled: false,
                note: '',
                items: getDefaultItemsForCategory(category)
              }
            }),
            {}
          )

        categories = {
          ...categories,
          ...newCategories
        }
      }

      return {
        categories,
        customTitle: mode === PatientBriefModalMode.Update ? '' : patientBrief.customTitle,
        practiceNotes: patientBrief.practiceNotes
      }
    },
    [getDefaultItemsForCategory, practiceGuidelines]
  )

  return {
    buildDefaultPatientBrief,
    createPatientBrief,
    validatePatientBrief,
    loadPatientBriefValues
  }
}
