import React from 'react'
import { NotifyPractice as NotifyPracticeIcon } from 'components/common/icons'
import { useTranslation } from 'react-i18next'
import GenericGuidelineAction from './GenericGuidelineAction'

const NotifyPractice = () => {
  const { t } = useTranslation()

  return (
    <GenericGuidelineAction
      icon={<NotifyPracticeIcon />}
      text={t('dialogs.hiPatientGuidelinesViewer.actions.notifyPractice')}
    />
  )
}

export default NotifyPractice
