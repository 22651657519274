import Mixpanel from 'mixpanel-browser'
import { getEnvironment } from '../utils/awsUtils'
import { isMobile } from 'utils/mobileUtils'
import { log } from './logUtils'
const devtoken = 'fefa54022b68c299fe93196c78cc06e4'
const productiontoken = '59ebae22815065eb0bede8de75f74018'

let initialized = false

export const trackScreen = screen => {
  if (!initialized) return
  Mixpanel.track('Screen navigated', {
    screen
  })
}

export const initMixpanel = async history => {
  let token = null
  const env = getEnvironment()

  switch (env) {
    case 'production':
      token = productiontoken
      break
    default:
      token = devtoken
  }

  const shouldInitMixpanelTracking = ['staging', 'production'].includes(env)

  if (shouldInitMixpanelTracking) {
    await Mixpanel.init(token, {
      error_reporter: (msg, err) => {
        log('mixpanel error', { error: err?.message ?? '', message: msg?.message ?? '' }, 'error')
      }
    })
    history.listen((location, action) => {
      trackScreen(location.pathname)
    })
    initialized = true
  }
}

export const setUser = user => {
  if (!initialized) return
  if (!user) {
    Mixpanel.reset()
    return
  }

  Mixpanel.identify(user.username)

  Mixpanel.people.set({
    $email: user.email,
    $username: user.username,
    $name: user.name,
    $role: user.role,
    $practiceName: user.practiceName,
    $grinPlanKey: user.grinPlanKey,
    $hiGroup: user.hiGroup
  })
}

export const trackEvent = (event, props, options, callback = () => {}) => {
  if (getEnvironment() !== 'production') {
    console.log('Mixpanel:', event, props)
  }

  if (!initialized) {
    return
  }

  Mixpanel.track(
    'Event: ' + event,
    {
      event,
      ...props,
      isMobile: isMobile(),
      type: 'event',
      version: process.env.REACT_APP_VERSION
    },
    options,
    callback
  )
}

export const registerMixpanelUser = async username => {
  if (!initialized) return
  const distinctId = await Mixpanel.get_distinct_id()
  Mixpanel.alias(username, distinctId)
}

//for screens with sensitive data
export const pauseTracking = () => {
  Mixpanel.opt_out_tracking()
}

export const resumeTracking = () => {
  Mixpanel.opt_in_tracking()
}

export const timeEvent = event => {
  if (!initialized) return
  Mixpanel?.time_event(event)
}

export const finishTimeEvent = (event, props) => {
  if (!initialized) return
  Mixpanel?.track(event, { version: process.env.REACT_APP_VERSION, ...props })
}
