import React, { useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { Grid, InputBase } from '@material-ui/core'
import SearchIcon from 'components/common/icons/SmallSearch2'

const useStyles = makeStyles(theme => ({
  authProfilesSearchRoot: {
    background: 'rgba(222, 234, 244, 1)',
    width: ({ isExpanded }) => (isExpanded ? '168px' : '28px'),
    height: 28,
    borderRadius: ({ isExpanded }) => (isExpanded ? '8px' : '100%'),
    overflow: 'hidden',
    transition: 'width 0.1s ease, border-radius 0.15s ease'
  },
  iconContainer: {
    lineHeight: 0.8,
    padding: ({ isExpanded }) => (isExpanded ? '8px 4px 8px 8px' : 8),
    cursor: ({ isExpanded }) => (isExpanded ? 'default' : 'pointer')
  },
  searchInput: {
    width: '137px',
    fontSize: 14,
    fontWeight: 500,
    color: 'rgba(123, 152, 176, 1)',
    padding: 0,
    overflow: 'hidden'
  }
}))

const AuthProfilesSearch = ({ value = '', setValue = () => {} }) => {
  const { t } = useTranslation()

  const [isExpanded, setIsExpanded] = useState(false)

  const handleBlur = useCallback(() => {
    if (!value) {
      setIsExpanded(false)
    }
  }, [value])

  const classes = useStyles({ isExpanded })

  return (
    <Grid container direction="row" alignItems="center" className={classes.authProfilesSearchRoot}>
      <Grid item className={classes.iconContainer} onClick={() => setIsExpanded(true)}>
        <SearchIcon />
      </Grid>
      {isExpanded && (
        <Grid item>
          <InputBase
            autoFocus
            onChange={e => setValue(e.target.value)}
            onKeyDown={e => e.stopPropagation()}
            onBlur={handleBlur}
            classes={{ input: classes.searchInput }}
            placeholder={t('common.appMenu.authProfiles.searchAccountsPlaceholder')}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default AuthProfilesSearch
