import React, { useCallback, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import ScanFeedCard from './ScanFeedCard'
import { trackEvent } from 'utils/analyticsUtils'
import ScanView from 'components/common/ScanView/ScanView'
import { IMAGE_MODES } from 'consts/scanSummaryConsts'
import usePreventive from 'components/Patients/Timeline/ScanSummary/Preventive/usePreventive'
import ToggleAlignersButton from './ToggleAlignersButton'
import ScanSummarySharpenButton from 'components/Patients/Timeline/ScanSummary/ScanSummaryModal/ScanSummarySharpenButton'
import useFeatureFlags from 'hooks/useFeatureFlags'
import useScanSummaryImageActions from './useScanSummaryImageActions'
import useDownloadScanSummaryImages from './useDownloadScanSummaryImages'
import useScanSummaryFeedback from './useScanSummaryFeedback'
import ScanSummaryFeedbackModal from './Modals/ScanSummaryFeedbackModal'
import WithAlignerTransparent from 'components/common/icons/timelineV2/WithAlignerTransparent'
import useImageDrawingCustomActions from './useImageDrawingCustomActions'
import useRolePermissions from 'hooks/useRolePermissions'

const useStyles = makeStyles(theme => ({
  grinScanCard: {
    position: 'relative',
    padding: '0px!important'
  },
  scanViewRoot: {
    padding: '0!important'
  },
  scanViewActionsContainer: {
    padding: `10px!important`
  },
  media: {
    borderRadius: '16px 16px 0 0!important',
    width: '100%',
    padding: '0px!important',
    marginBottom: '0!important'
  },
  alignersToggleContainer: {
    position: 'absolute',
    top: 16,
    right: 16,
    zIndex: 999
  },
  optionMediaClassName: {
    borderRadius: 10
  },
  selectedOptionClassName: {
    borderRadius: '10px!important'
  },
  unselectedOptionClassName: {
    opacity: '1!important'
  }
}))

/**
 * @param videoS3Object `{ region, bucket, key }`
 * @param scanSummaryStatus see `ScanSummaryStatus`
 * @param scanSummaryData the raw `scanSummaryData` json from the GrinScan
 * @param setWithAligners Optional - a callback that's fired when users toggle whether to view the scan with our without aligners. If null, the toggle button is hidden. Called with a boolean value.
 */
const GrinScanCard = ({
  patientName,
  scanNumber,
  grinScanId,
  videoS3Object = {},
  scanSummaryStatus,
  scanSummaryData = '{}',
  isDoubleScan,
  withAligner,
  setWithAligners,
  analyticsPayload = {}
}) => {
  const classes = useStyles()

  const { scanSummaryPreventive: preventiveFF } = useFeatureFlags()
  const { permissions } = useRolePermissions()

  const [displayedTileIndex, setDisplayedTileIndex] = useState(0)
  const [sharpeningEnabled, setSharpeningEnabled] = useState(true)
  const [imagesMode, setImagesMode] = useState(IMAGE_MODES.sharpened)
  const [selectedPoses, setSelectedPoses] = useState([])
  const [sketchesByScan, setSketchesByScan] = useState({})

  const isDisplayingImage = useMemo(() => displayedTileIndex > 0, [displayedTileIndex])
  const parsedScanSummaryData = useMemo(() => JSON.parse(scanSummaryData), [scanSummaryData])
  const normalImages = useMemo(
    () => Object.entries(parsedScanSummaryData?.[IMAGE_MODES.normal] || {}),
    [parsedScanSummaryData]
  )

  const defaultImageMode = useMemo(
    () => (sharpeningEnabled ? IMAGE_MODES.sharpened : IMAGE_MODES.normal),
    [sharpeningEnabled]
  )

  const currentSketchedPoses = useMemo(() => sketchesByScan[grinScanId] || {}, [sketchesByScan, grinScanId])

  const {
    preventiveLayers,
    displayedPreventiveLayers,
    isAnyPreventiveLayerVisible,
    togglePreventiveLayer,
    toggleAllPreventiveLayers,
    clearActiveLayers
  } = usePreventive({
    imagesMode,
    setImagesMode,
    defaultImageMode
  })

  const downloadScanSummaryImages = useDownloadScanSummaryImages({
    displayedPreventiveLayers,
    patientName,
    scanNumber,
    imagesMode,
    parsedScanSummaryData,
    selectedPoses,
    sketchedPoses: currentSketchedPoses
  })

  const scanSummaryImageActions = useScanSummaryImageActions({
    parsedScanSummaryData,
    isAnyPreventiveLayerVisible,
    analyticsPayload,
    displayedPreventiveLayers,
    selectedPoses,
    imagesMode,
    sketchedPoses: currentSketchedPoses,
    downloadScanSummaryImages
  })

  const {
    scanSummaryRating,
    setSelectedScanSummaryRating,
    submitScanSummaryFeedback,
    isFeedbackModalOpen,
    setIsFeedbackModalOpen
  } = useScanSummaryFeedback({ grinScanId, scanNumber, analyticsPayload, parsedScanSummaryData })

  const imageDrawingActions = useImageDrawingCustomActions()

  const setCurrentScanSketchedPoses = useCallback(
    sketchedPoses =>
      setSketchesByScan({
        ...sketchesByScan,
        [grinScanId]: sketchedPoses
      }),
    [grinScanId, sketchesByScan]
  )

  const handleVideoPlay = useCallback(() => {
    trackEvent(`Scan - scan video played`, analyticsPayload)
  }, [analyticsPayload])

  const handleToggleWithAligners = useCallback(() => {
    const newValue = !withAligner
    setWithAligners(newValue)
    trackEvent('Scan - toggle aligners button clicked', {
      ...analyticsPayload,
      newValue: newValue ? 'With Aligners' : 'Without Aligners'
    })
  }, [withAligner, analyticsPayload, setWithAligners])

  const handleToggleSharpening = useCallback(
    isEnabled => {
      setSharpeningEnabled(isEnabled)
      trackEvent('Scan - toggle sharpning clicked', {
        ...analyticsPayload,
        isEnabled
      })
    },
    [analyticsPayload]
  )

  const handleImageViewerOpened = useCallback(
    ({ pose }) => {
      trackEvent('Scan Summary - view pose in image viewer', { pose, ...analyticsPayload })
    },
    [analyticsPayload]
  )

  const handleSelectedOptionChanged = useCallback(
    (optionIndex, optionData, eventTrigger) => {
      if (optionIndex === displayedTileIndex) {
        return
      }

      setDisplayedTileIndex(optionIndex)
      trackEvent('Scan Summary - click on pose', {
        ...analyticsPayload,
        eventTrigger,
        pose: optionData?.type === 'video' ? 'video' : optionData?.pose
      })
    },
    [analyticsPayload, displayedTileIndex]
  )

  const handleScanSummaryPoseChecked = useCallback(
    ({ pose }) => {
      trackEvent('Scan Summary - pose checked', { ...analyticsPayload, pose })
    },
    [analyticsPayload]
  )

  const handleScanSummaryPoseUnchecked = useCallback(
    ({ pose }) => {
      trackEvent('Scan Summary - pose unchecked', { ...analyticsPayload, pose })
    },
    [analyticsPayload]
  )

  const handleMarkImageClicked = useCallback(
    ({ pose = 'center' }) => {
      trackEvent('Scan Summary - mark image clicked', { ...analyticsPayload, pose })
    },
    [analyticsPayload]
  )

  return (
    <>
      <ScanFeedCard className={classes.grinScanCard}>
        <div className={classes.alignersToggleContainer}>
          {setWithAligners ? (
            <ToggleAlignersButton withAligners={withAligner} onClick={handleToggleWithAligners} />
          ) : withAligner ? (
            <WithAlignerTransparent />
          ) : (
            <></>
          )}
        </div>
        {isDisplayingImage && (
          <ScanSummarySharpenButton
            isImageAvailable
            sharpenedImages={sharpeningEnabled}
            setSharpenedImages={handleToggleSharpening}
          />
        )}
        <div className={classes.grinScanCardBody}>
          <ScanView
            grinScanVideo={videoS3Object}
            scanSummaryStatus={scanSummaryStatus}
            scanSummaryData={scanSummaryData}
            selectedOptionIndex={displayedTileIndex}
            onOptionSelect={handleSelectedOptionChanged}
            mediaClassName={classes.media}
            defaultImagesMode={defaultImageMode}
            hidePreventive={!preventiveFF}
            preventiveLayers={preventiveLayers}
            displayedPreventiveLayers={displayedPreventiveLayers}
            isAnyPreventiveLayerVisible={isAnyPreventiveLayerVisible}
            onTogglePreventiveLayer={togglePreventiveLayer}
            onToggleAllPreventiveLayers={toggleAllPreventiveLayers}
            onClearActiveLayers={clearActiveLayers}
            onPlay={handleVideoPlay}
            rootClasName={classes.scanViewRoot}
            actionsContainerClassName={classes.scanViewActionsContainer}
            optionMediaClassName={classes.optionMediaClassName}
            selectedOptionClassName={classes.selectedOptionClassName}
            unselectedOptionClassName={classes.unselectedOptionClassName}
            withPosesSelection
            selectedPoses={selectedPoses}
            setSelectedPoses={setSelectedPoses}
            scanSummaryImageActions={scanSummaryImageActions}
            withScanSummaryRating
            scanSummaryRating={scanSummaryRating}
            setScanSummaryRating={setSelectedScanSummaryRating}
            preventivePanelProps={{ fontSize: '14px', legendItemXs: 6, withDisabledOverlay: true }}
            openImagesInViewer
            onImageViewerOpened={handleImageViewerOpened}
            onPoseChecked={handleScanSummaryPoseChecked}
            onPoseUnchecked={handleScanSummaryPoseUnchecked}
            withImageDrawing={permissions.scanSummaryAnnotations}
            onMarkImageClicked={handleMarkImageClicked}
            imageDrawingModalProps={{
              customActions: imageDrawingActions
            }}
            sketchedPoses={currentSketchedPoses}
            setSketchedPoses={setCurrentScanSketchedPoses}
            analyticsPayload={analyticsPayload}
            withKeyboardShortcuts
          />
        </div>
      </ScanFeedCard>
      <ScanSummaryFeedbackModal
        images={normalImages}
        isOpen={isFeedbackModalOpen}
        onClose={() => setIsFeedbackModalOpen(false)}
        onSubmit={submitScanSummaryFeedback}
      />
    </>
  )
}

export default React.memo(GrinScanCard)
