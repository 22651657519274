import { api, REQUEST_TYPE } from 'store/api'
import { ModuleTags } from './consts'
import { getUserNotesSorted, noteFields } from 'graphql/notes.graphql'
import { GraphQLMutationTypes } from 'utils/graphqlUtils'
import { logError } from 'utils/logUtils'
import { prettyError } from '@grin-rnd/grin-api-sdk/dist/utils/ErrorUtils'
import { removeWhere, mutateArrayItem } from 'utils/arrayUtils'

export const PatientNotes = api.injectEndpoints({
  endpoints: build => ({
    fetchNotes: build.query({
      query: ({ patientGrinUserId }) => ({
        query: getUserNotesSorted(noteFields),
        queryName: 'userNotesSorted',
        type: REQUEST_TYPE.GRAPHQL_FETCH_ALL,
        variables: { grinUserId: patientGrinUserId }
      }),
      transformResponse: notes => notes.filter(note => !note._deleted),
      providesTags: [ModuleTags.PatientNotes]
    }),
    createNote: build.mutation({
      query: ({ variables, fields = noteFields }) => ({
        type: REQUEST_TYPE.GRAPHQL_MUTATION,
        mutationType: GraphQLMutationTypes.Create,
        entityType: 'UserNote',
        variables,
        fields
      }),
      async onQueryStarted({ variables }, { dispatch, queryFulfilled }) {
        try {
          const result = await queryFulfilled
          const { data: newNote } = result

          dispatch(
            api.util.updateQueryData('fetchNotes', { patientGrinUserId: newNote.grinUserId }, draftNotes => {
              draftNotes.push(newNote)
            })
          )
        } catch (error) {
          console.log('error', error)
          logError('An error occured while trying to create a patient note', {
            ...variables,
            error: prettyError(error)
          })
        }
      }
    }),
    updateNote: build.mutation({
      query: ({ variables, id, fields }) => ({
        type: REQUEST_TYPE.GRAPHQL_MUTATION,
        entityType: 'UserNote',
        variables,
        id,
        fields
      }),
      async onQueryStarted({ id, variables }, { dispatch, queryFulfilled }) {
        // Optimistically update the state
        const patch = dispatch(
          api.util.updateQueryData('fetchNotes', { patientGrinUserId: variables.grinUserId }, draftNotes => {
            draftNotes = mutateArrayItem({ array: draftNotes, item: { id, ...variables } })
          })
        )

        try {
          await queryFulfilled
        } catch (error) {
          // Revert the optimistic update incase of an error
          patch.undo()
          logError('An error occured while trying to update a patients note', {
            noteId: id,
            error: prettyError(error)
          })
        }
      }
    }),
    deleteNote: build.mutation({
      query: ({ id, patientGrinUserId }) => ({
        type: REQUEST_TYPE.GRAPHQL_MUTATION,
        entityType: 'UserNote',
        mutationType: GraphQLMutationTypes.Delete,
        id
      }),
      invalidatesTags: [ModuleTags.PatientNotes],
      async onQueryStarted({ id, patientGrinUserId }, { dispatch, queryFulfilled, getCacheEntry }) {
        const patch = dispatch(
          api.util.updateQueryData('fetchNotes', { patientGrinUserId }, draftNotes => {
            draftNotes = removeWhere(draftNotes, item => item.id === id)
          })
        )

        try {
          await queryFulfilled
        } catch (error) {
          patch.undo()
          logError('An error occured while trying to delete a patients note', {
            noteId: id,
            error: prettyError(error)
          })
        }
      }
    })
  })
})

export const { useFetchNotesQuery, useUpdateNoteMutation, useDeleteNoteMutation, useCreateNoteMutation } = PatientNotes
