import * as React from 'react'

export default () => (
  <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.05 0.837524C1.05589 0.837524 0.25 1.64341 0.25 2.63752V7.13752C0.25 8.13164 1.05589 8.93752 2.05 8.93752H8.35C9.34411 8.93752 10.15 8.13164 10.15 7.13752V2.63752C10.15 1.64341 9.34411 0.837524 8.35 0.837524H2.05ZM13.7523 2.18753L10.1523 3.98753V5.78753L13.7523 7.58753V2.18753Z"
      fill="#3C52EF"
    />
  </svg>
)
