import * as React from 'react'

export default ({ color = 'var(--text-color-25' }) => (
  <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.63936 0.063806C6.60209 0.0311865 6.55621 0.00999376 6.50721 0.00276256C6.45821 -0.00446863 6.40817 0.00256783 6.36306 0.0230303C6.31796 0.0434927 6.2797 0.076515 6.25287 0.118147C6.22604 0.15978 6.21177 0.20826 6.21176 0.25779V2.17443H4.96941C3.65144 2.17444 2.38746 2.698 1.45552 3.62995C0.523571 4.56189 7.32198e-06 5.82587 0 7.14384V8.37272C7.45853e-06 8.43208 0.0205063 8.48963 0.0580341 8.53563C0.0955619 8.58164 0.147819 8.61328 0.205976 8.62521C0.264133 8.63714 0.324626 8.62863 0.377236 8.60112C0.429846 8.57361 0.471349 8.52879 0.494732 8.47422C0.900988 7.52629 1.57646 6.71841 2.43744 6.15068C3.29841 5.58295 4.30706 5.28032 5.33837 5.28031H6.21176V7.81813C6.21177 7.86766 6.22604 7.91614 6.25287 7.95777C6.2797 7.9994 6.31796 8.03243 6.36306 8.05289C6.40817 8.07335 6.45821 8.08039 6.50721 8.07316C6.55621 8.06592 6.60209 8.04473 6.63936 8.01211L10.9596 4.23194C10.9872 4.20774 11.0093 4.17791 11.0245 4.14446C11.0397 4.111 11.0475 4.07469 11.0475 4.03796C11.0475 4.00123 11.0397 3.96491 11.0245 3.93146C11.0093 3.89801 10.9872 3.86818 10.9596 3.84398L6.63936 0.063806Z"
      fill={color}
    />
  </svg>
)
