import React from 'react'
import { makeStyles } from '@material-ui/styles'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'

const useStyles = makeStyles(theme => ({
  notificationBody: {
    maxWidth: 250
  },
  text: {
    lineHeight: '22px',
    '&.ellipsis': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  }
}))

/**
 * Renders a generic standard title and description as used in most notifications.
 * @param {Object} props
 * @param {string} props.title - The title of the notification.
 * @param {string} props.text - The description of the notification. Should be up to one line.
 * @param {boolean} [props.withEllipsis=false] - Whether the text should have an ellipsis.
 * @returns {JSX.Element}
 */
const GenericNotificationBody = ({ title, text, withEllipsis = false }) => {
  const classes = useStyles()

  return (
    <div className={classes.notificationBody}>
      <DazzedParagraph14 bold className={classes.text}>
        {title}
      </DazzedParagraph14>
      <DazzedParagraph14 strong className={[classes.text, withEllipsis ? 'ellipsis' : ''].join(' ')}>
        {text}
      </DazzedParagraph14>
    </div>
  )
}

export default GenericNotificationBody
