import React, { useMemo } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { trackEvent } from 'utils/analyticsUtils'
import { setCameraPosition, adjustCameraZoom } from 'components/common/Grin3DViewer/Grin3DViewerUtils'
import {
  ArchViewerCenter,
  ArchViewerLeft,
  ArchViewerLower,
  ArchViewerRight,
  ArchViewerUpper,
  MagnifyingGlassMinus,
  MagnifyingGlassPlus
} from 'components/common/icons'
import { Poses, ViewerModes } from 'components/common/Grin3DViewer/Grin3DViewerConsts'

const POSE_POSITIONS = {
  LEFT: { x: 60, y: 0, z: -10 },
  CENTER: { x: 0, y: 0, z: 40 },
  RIGHT: { x: -60, y: 0, z: -10 }
}

const useStyles = makeStyles(() => ({
  control: {
    userSelect: 'none',
    cursor: 'pointer',
    marginBottom: 5
  },
  magnify: {
    userSelect: 'none',
    cursor: 'pointer'
  },
  controls: {
    position: 'absolute',
    top: 80,
    right: 15,
    zIndex: 999
  }
}))

const PoseController = ({ camera, currentPose, onSetPose, viewerMode, onSetViewerMode, cameraControls }) => {
  const classes = useStyles()

  const controls = useMemo(
    () => [
      {
        type: Poses.Upper,
        icon: ArchViewerUpper,
        onClick: () => {
          trackEvent('Treatment Tracker - Arches viewer - selected pose', {
            pose: 'upper'
          })
          onSetPose(Poses.Upper)
          setCameraPosition({ camera, ...POSE_POSITIONS.CENTER })
          onSetViewerMode(ViewerModes.Occlusal)
        }
      },
      {
        type: Poses.Left,
        icon: ArchViewerLeft,
        onClick: () => {
          trackEvent('Treatment Tracker - Arches viewer - selected pose', {
            pose: 'left'
          })
          onSetPose(Poses.Left)
          onSetViewerMode(ViewerModes.Regular)
          setCameraPosition({ camera, ...POSE_POSITIONS.RIGHT })
        }
      },
      {
        type: Poses.Center,
        icon: ArchViewerCenter,
        onClick: () => {
          trackEvent('Treatment Tracker - Arches viewer - selected pose', {
            pose: 'center'
          })
          onSetPose(Poses.Center)
          onSetViewerMode(ViewerModes.Regular)
          setCameraPosition({ camera, ...POSE_POSITIONS.CENTER })
        }
      },
      {
        type: Poses.Right,
        icon: ArchViewerRight,
        onClick: () => {
          trackEvent('Treatment Tracker - Arches viewer - selected pose', {
            pose: 'right'
          })
          onSetPose(Poses.Right)
          onSetViewerMode(ViewerModes.Regular)
          setCameraPosition({ camera, ...POSE_POSITIONS.LEFT })
        }
      },
      {
        type: Poses.Lower,
        icon: ArchViewerLower,
        onClick: () => {
          trackEvent('Treatment Tracker - Arches viewer - selected pose', {
            pose: 'lower'
          })
          onSetPose(Poses.Lower)
          onSetViewerMode(ViewerModes.Occlusal)
          setCameraPosition({ camera, ...POSE_POSITIONS.CENTER })
        }
      }
    ],
    [onSetPose, camera, onSetViewerMode]
  )

  const magnifiers = useMemo(
    () => [
      {
        icon: <MagnifyingGlassPlus />,
        onClick: () => {
          trackEvent('Treatment Tracker - Arches viewer - Zoom in clicked')
          adjustCameraZoom({ camera, controls: cameraControls, zoomAddition: 2 })
        }
      },
      {
        icon: <MagnifyingGlassMinus />,
        onClick: () => {
          trackEvent('Treatment Tracker - Arches viewer - Zoom out clicked')
          adjustCameraZoom({ camera, controls: cameraControls, zoomAddition: -2 })
        }
      }
    ],
    [camera, cameraControls]
  )

  return (
    <Grid container direction="column" justifyContent="center" alignItems="flex-end" spacing={1}>
      <Grid item style={{ position: 'relative' }}>
        <Grid container alignItems="center" justifyContent="center" spacing={1}>
          {magnifiers.map((control, index) => (
            <Grid item key={`magnifier-control-${index}`} onClick={control.onClick} className={classes.magnify}>
              {control.icon}
            </Grid>
          ))}
        </Grid>
      </Grid>
      <div className={classes.controls}>
        <Grid container direction="column" alignItems="center">
          {controls.map((control, index) => (
            <Grid item key={`pose-control-${index}`} onClick={control.onClick} className={classes.control}>
              {React.createElement(control.icon, {
                isSelected: control.type === currentPose
              })}
            </Grid>
          ))}
        </Grid>
      </div>
    </Grid>
  )
}

export default PoseController
