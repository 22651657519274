import { formatDynamicFields } from 'utils/graphqlUtils'

export const grinNotificationFields = `
  id
  eventDate
  type
  category
  status
  payload
  bundleCount
  relatedAccountOwnerId
  relatedEntityId
  relatedPatientId
  relatedPatientSM {
    name
    profilePicture {
      bucket
      key
      region
    }
  }
`

export const searchGrinNotifications = (fields = grinNotificationFields) => /* GraphQL */ `
  query SearchGrinNotifications(
    $filter: SearchableGrinNotificationFilterInput
    $sort: SearchableGrinNotificationSortInput
    $limit: Int
    $from: Int
  ) {
    searchGrinNotifications(
      filter: $filter
      sort: $sort
      limit: $limit
      from: $from
    ) {
      nextToken
      items {
        ${formatDynamicFields(fields)}
      }
    }
  }
`
