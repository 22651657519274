import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import NotificationItems from './NotificationItems'

const useStyles = makeStyles(theme => ({
  notificationsGroupRoot: {
    padding: '0 16px 16px 16px',
    marginBottom: 16
  },
  notificationsGroupLabel: {
    fontWeight: 500,
    color: 'var(--text-color-62)',
    marginBottom: 16
  }
}))

/**
 * @param {object} props
 * @param {GrinNotificationsGroup} props.notificationGroup
 * @param {bool} props.showPracticeNames
 */
const NotificationsGroup = ({ notificationGroup, showPracticeNames }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.notificationsGroupRoot}>
      <DazzedParagraph16 className={classes.notificationsGroupLabel}>
        {t(`dialogs.notificationCenterDialog.feed.groups.${notificationGroup.key}`)}
      </DazzedParagraph16>
      {notificationGroup.notifications.map((grinNotification, i) =>
        React.createElement(NotificationItems(grinNotification.type), {
          key: grinNotification.id,
          grinNotification,
          options: {
            isLastItem: i === notificationGroup.notifications.length - 1,
            showPracticeName: showPracticeNames
          }
        })
      )}
    </div>
  )
}

export default NotificationsGroup
