import React, { useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import ScanView from 'components/common/ScanView/ScanView'
import usePreventive from 'components/Patients/Timeline/ScanSummary/Preventive/usePreventive'
import { IMAGE_MODES } from 'consts/scanSummaryConsts'
import useFeatureFlags from 'hooks/useFeatureFlags'
import { trackEvent } from 'utils/analyticsUtils'
import { Grid } from '@material-ui/core'
import usePatientContextView from './usePatientContextView'
import ScansNavigation from 'components/common/ScansNavigation/ScansNavigation'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import { useTranslation } from 'react-i18next'
import TextAreaAutosize from 'components/common/TextareaAutosize'
import DazzedParagraph12 from 'components/common/text/DazzedParagraph12'

const useStyles = makeStyles(theme => ({
  root: {
    width: '39%'
  },
  scanViewRoot: {
    padding: '0!important'
  },
  scanViewActionsContainer: {
    padding: `10px!important`
  },
  media: {
    width: '100%',
    padding: '0px!important',
    marginBottom: '0!important'
  },
  optionMediaClassName: {
    borderRadius: 10
  },
  selectedOptionClassName: {
    borderRadius: '10px!important'
  },
  unselectedOptionClassName: {
    opacity: '1!important'
  },
  input: {
    border: 'none!important'
  },
  inputContainer: {
    position: 'relative',
    borderRadius: 10,
    backgroundColor: '#F2F3F5',
    border: ({ errorMessage }) => `1px solid ${errorMessage ? 'red' : 'var(--bg-color-65)'}`,
    paddingLeft: 10
  },
  saveButton: {
    position: 'absolute',
    bottom: 50,
    right: 10
  },
  note: {
    color: 'var(--text-color-12)'
  }
}))

const PatientContextView = ({ note, setNote }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    currentGrinScan,
    hasNextScan,
    navigateToNextScan,
    navigateToPreviousScan,
    hasPreviousScan,
    scanNumber,
    alignerNumber,
    sortedScans,
    handleGrinScanSelected
  } = usePatientContextView()
  const { scanSummaryPreventive: preventiveFF } = useFeatureFlags()
  const {
    preventiveLayers,
    displayedPreventiveLayers,
    isAnyPreventiveLayerVisible,
    togglePreventiveLayer,
    toggleAllPreventiveLayers,
    clearActiveLayers
  } = usePreventive({
    scanId: currentGrinScan?.id,
    imagesMode: IMAGE_MODES.sharpened
  })

  const [displayedTileIndex, setDisplayedTileIndex] = useState(0)
  const [selectedPoses, setSelectedPoses] = useState([])

  const handleVideoPlay = useCallback(() => {
    trackEvent(`Live call modal - scan video played`)
  }, [])

  const handleSelectedOptionChanged = useCallback(
    (optionIndex, optionData, eventTrigger) => {
      if (optionIndex === displayedTileIndex) {
        return
      }

      setDisplayedTileIndex(optionIndex)
      trackEvent('Live call modal - click on pose', {
        eventTrigger,
        pose: optionData?.type === 'video' ? 'video' : optionData?.pose
      })
    },
    [displayedTileIndex]
  )

  return (
    sortedScans.length > 0 && (
      <Grid container direction="column" wrap="nowrap" className={classes.root}>
        <Grid item>
          <ScansNavigation
            scanNumber={scanNumber}
            alignerNumber={alignerNumber}
            scanCreatedAt={currentGrinScan?.createdAt}
            nextDisabled={!hasNextScan}
            onNextClicked={navigateToNextScan}
            previousDisabled={!hasPreviousScan}
            onPreviousClicked={navigateToPreviousScan}
            grinScans={sortedScans}
            navigateToScanByScanId={handleGrinScanSelected}
          />
        </Grid>
        <Grid item>
          {currentGrinScan && (
            <ScanView
              grinScanVideo={currentGrinScan.video}
              scanSummaryStatus={currentGrinScan.scanSummaryStatus}
              scanSummaryData={currentGrinScan.scanSummaryData}
              selectedOptionIndex={displayedTileIndex}
              onOptionSelect={handleSelectedOptionChanged}
              mediaClassName={classes.media}
              defaultImagesMode={IMAGE_MODES.sharpened}
              hidePreventive={!preventiveFF}
              preventiveLayers={preventiveLayers}
              displayedPreventiveLayers={displayedPreventiveLayers}
              isAnyPreventiveLayerVisible={isAnyPreventiveLayerVisible}
              onTogglePreventiveLayer={togglePreventiveLayer}
              onToggleAllPreventiveLayers={toggleAllPreventiveLayers}
              onClearActiveLayers={clearActiveLayers}
              onPlay={handleVideoPlay}
              rootClassName={classes.scanViewRoot}
              actionsContainerClassName={classes.scanViewActionsContainer}
              optionMediaClassName={classes.optionMediaClassName}
              selectedOptionClassName={classes.selectedOptionClassName}
              unselectedOptionClassName={classes.unselectedOptionClassName}
              selectedPoses={selectedPoses}
              setSelectedPoses={setSelectedPoses}
              preventivePanelProps={{ fontSize: '14px', legendItemXs: 6, withDisabledOverlay: true }}
              withImageDrawing={false}
              withKeyboardShortcuts
            />
          )}
        </Grid>
        <Grid item style={{ borderTop: '1px #E8E8E8 solid', padding: 10 }}>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <DazzedParagraph16 bold>
                {t('pages.patients.patientSidebar.items.liveCalls.appointmentNotes')}
              </DazzedParagraph16>
            </Grid>
            <Grid item>
              <DazzedParagraph12 className={classes.note}>
                {t('pages.patients.patientSidebar.items.liveCalls.note')}
              </DazzedParagraph12>
            </Grid>
            <Grid item className={classes.inputContainer}>
              <TextAreaAutosize
                inputStyle={{
                  fontSize: 14,
                  fontWeight: 500,
                  height: 200,
                  backgroundColor: '#F2F3F5',
                  overflow: 'auto'
                }}
                rowsMax={4}
                style={{ bright: true }}
                className={classes.input}
                placeholder={t('pages.patients.patientSidebar.items.liveCalls.inputPlaceholder')}
                keepErrorContainerWhenInactive={false}
                value={note}
                setValue={newNote => setNote(newNote)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  )
}

export default PatientContextView
