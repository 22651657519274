import React from 'react'
import { CircularProgress, Grid, List, ListItem, makeStyles } from '@material-ui/core'
import { Search } from 'components/common/icons'
import DazzedParagraph16 from 'components/common/text/DazzedParagraph16'
import { useTranslation } from 'react-i18next'
import DoctorListItem from './DoctorListItem'

const useStyles = makeStyles(theme => ({
  searchInput: {
    display: 'flex',
    width: 245,
    fontFamily: 'Dazzed',
    height: 45,
    fontSize: 14,
    paddingLeft: 5,
    background: 'rgba(0, 0, 0, 0)',
    border: 'none',
    color: '#898484',
    '&:focus': {
      outline: 'none'
    },
    '::placeholder': {
      color: '#898484'
    }
  },
  searchIconContainer: {
    paddingTop: 4
  },
  menuContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  allPractices: {
    padding: 25
  },
  divider: {
    width: '100%',
    height: 1,
    backgroundColor: '#E8E8E8'
  }
}))

const SearchByDoctorMenuContent = ({
  width,
  searchFilter,
  setSearchFilter,
  onShowAllPractices,
  isLoadingDoctors,
  doctorsResults,
  selectedDoctor,
  onDoctorSelected,
  onClose
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <List id="doctor-search-list" style={{ width: width }} className={classes.menuContent}>
      <ListItem>
        <Grid container alignItems="center">
          <Grid item className={classes.searchIconContainer}>
            <Search width={20} height={20} color="#9B9B9B" />
          </Grid>
          <Grid item xs={11}>
            <input
              className={classes.searchInput}
              placeholder={t('pages.patients.patientsList.searchByDoctor.search')}
              type="text"
              name="doctors-filter-search"
              value={searchFilter}
              autoComplete="off"
              onChange={({ target }) => setSearchFilter(target.value)}
              autoFocus
              // Preventing focusing on List instead of input while typing
              onKeyDown={e => e.stopPropagation()}
            />
          </Grid>
        </Grid>
      </ListItem>
      <div className={classes.divider} />
      <ListItem button className={classes.allPractices} onClick={onShowAllPractices}>
        <DazzedParagraph16>{t('pages.patients.patientsList.searchByDoctor.allPractices')}</DazzedParagraph16>
      </ListItem>
      <div className={classes.divider} />
      {!isLoadingDoctors ? (
        doctorsResults?.length ? (
          doctorsResults.map((doctor, index) => (
            <div style={{ width: '94%' }}>
              <DoctorListItem
                onDoctorSelected={() => onDoctorSelected(doctor)}
                photo={doctor.photo}
                practiceName={doctor.practiceName}
                email={doctor.email}
                index={index}
              />
              <div key={`doctors-search-item-${index}-divider`} className={classes.divider} />
            </div>
          ))
        ) : (
          <ListItem>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item>
                <DazzedParagraph16>{t('pages.patients.patientsList.searchByDoctor.noDoctorsFound')}</DazzedParagraph16>
              </Grid>
            </Grid>
          </ListItem>
        )
      ) : (
        <ListItem>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        </ListItem>
      )}
    </List>
  )
}

export default SearchByDoctorMenuContent
