import * as React from 'react'

const StayInAligner = () => (
  <svg
    width="28"
    height="16"
    viewBox="0 0 28 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ paddingRight: 7 }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.98872 4.85753C6.91999 5.02787 6.83822 5.19432 6.74343 5.3554C6.60904 5.58376 6.36385 5.72396 6.09888 5.72396H4.11572C3.63187 5.72396 3.22125 5.37406 3.17367 4.89255C3.1508 4.66108 3.12826 4.38926 3.11094 4.08752C3.0656 4.28187 3.00544 4.47357 2.9305 4.66094C2.80971 4.96292 2.51723 5.16093 2.192 5.16093H0.893494C0.476376 5.16093 0.122395 4.8593 0.0813833 4.4442C0.0400739 4.02609 0 3.45531 0 2.81441C0 2.37296 0.019012 1.96479 0.0443446 1.61685C0.0928079 0.951228 0.659809 0.467884 1.3272 0.467884H1.52327C2.0949 0.467884 2.60601 0.824003 2.804 1.36025L2.96875 1.80644C3.01878 1.94193 3.06117 2.07943 3.09594 2.21833C3.10495 2.00347 3.11704 1.80059 3.13071 1.61283C3.18693 0.840701 3.84465 0.280021 4.61882 0.280021H4.84377C5.57037 0.280021 6.24069 0.671212 6.59811 1.30383L6.77639 1.61937C6.849 1.74788 6.91342 1.87959 6.96966 2.01377C7.16603 0.838588 8.22824 0 9.45853 0C10.5505 0 11.5323 0.664933 11.9376 1.67886L12.0003 1.83581L12.0624 1.68047C12.4677 0.666544 13.4495 0.00161117 14.5415 0.00161117C15.7718 0.00161117 16.834 0.8402 17.0303 2.01539C17.0866 1.8812 17.151 1.74949 17.2236 1.62098L17.4019 1.30544C17.7593 0.672823 18.4296 0.281633 19.1562 0.281633H19.3812C20.1554 0.281633 20.8131 0.842312 20.8693 1.61444C20.883 1.80212 20.895 2.00491 20.904 2.21968C20.9388 2.08076 20.9812 1.94324 21.0312 1.80773L21.196 1.36154C21.394 0.825292 21.9051 0.469173 22.4767 0.469173H22.6728C23.3402 0.469173 23.9072 0.952517 23.9557 1.61814C23.9748 1.88148 23.9904 2.17933 23.9968 2.5H17.5V5.60889C17.4017 5.5464 17.3177 5.46081 17.2566 5.35701C17.1618 5.19593 17.08 5.02948 17.0113 4.85914C17.0003 5.05476 16.9886 5.24486 16.9769 5.42454C16.9269 6.18966 16.2874 6.77496 15.5206 6.77496H13.3578C12.7614 6.77496 12.231 6.41822 11.9997 5.88289C11.7679 6.41732 11.238 6.77335 10.6422 6.77335H8.47939C7.71265 6.77335 7.07306 6.18805 7.0231 5.42293C7.01137 5.24325 6.99969 5.05315 6.98872 4.85753ZM19 5.72557H19V4H19V5.72557Z"
      fill="#527195"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M25 4H19V10H16L22 16L28 10H25V4Z" fill="#527195" />
  </svg>
)

export default StayInAligner
