import actions from 'actions'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useQueryParams from './useQueryParams'

/**
 * A hook that makes navigating to a specific patient easy.
 * @param {Object} props
 * @param {string} props.patientId - The ID of the patient to navigate to.
 * @param {string} [props.timelineItemId] - The ID of the timeline item to select when navigated.
 */
export default ({ patientId, timelineItemId = '' }) => {
  const dispatch = useDispatch()

  const currentPatientId = useSelector(state => state.patientsReducer.patient?.id)
  const patientSM = useSelector(state => state.chatReducer.rooms.find(patientSM => patientSM.id === patientId))
  const { selectedTimelineItemId } = useSelector(state => state.timelineReducer)
  const { addQueryParam } = useQueryParams()

  const navigateToPatientRoom = useCallback(() => {
    dispatch(
      actions.chatRoomSelected({
        patientId,
        timelineItemId,
        roomId: patientSM?.roomId,
        previewDetails: {
          name: patientSM?.name,
          photo: patientSM?.profilePicture
        }
      })
    )
  }, [dispatch, patientSM, timelineItemId, patientId])

  const navigateToTimelineItem = useCallback(() => {
    addQueryParam({ timelineItem: timelineItemId })
  }, [timelineItemId, addQueryParam])

  const selectPatient = useCallback(() => {
    if (!patientId) {
      return
    }

    if (currentPatientId !== patientId) {
      navigateToPatientRoom()
      return
    }

    // The currently selected patient is the same as the provided patientId,
    // Check if we just need to navigate to a different timeline item.

    if (timelineItemId && selectedTimelineItemId !== timelineItemId) {
      navigateToTimelineItem()
    }
  }, [
    currentPatientId,
    patientId,
    navigateToPatientRoom,
    timelineItemId,
    selectedTimelineItemId,
    navigateToTimelineItem
  ])

  return selectPatient
}
