import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { rangeArray } from 'utils/arrayUtils'
import { Grid } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

const useStyles = makeStyles(theme => ({
  messageSkeleton: {
    margin: 10,
    borderRadius: 16
  }
}))

const MessageHistorySkeleton = ({ amount = 3 }) => {
  const classes = useStyles()

  return (
    <Grid container direction="column" alignItems="center" justifyContent="cente">
      {rangeArray(amount).map(i => (
        <Grid item key={`skeleton-${i}`}>
          <Grid container alignItems="flex-end" direction={'row'}>
            <Grid item>
              <Skeleton variant="rect" width={500} height={100} className={[classes.messageSkeleton].join(' ')} />
            </Grid>
            <Grid item>
              <Skeleton variant="circle" width={40} height={40} />
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  )
}

export default MessageHistorySkeleton
