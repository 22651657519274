import { useCallback, useMemo, useState } from 'react'
import { DEFAULT_NUM_OF_DISPLAYED_ITEMS } from 'consts/timelineConsts'

export default ({ timelineItems, setSelectedTimelineItem, analyticsPayload = {} }) => {
  const [numOfDisplayedItems, setNumOfDisplayedItems] = useState(DEFAULT_NUM_OF_DISPLAYED_ITEMS)

  const displayedTimelineItems = useMemo(
    () => timelineItems.slice(-numOfDisplayedItems).reverse(),
    [numOfDisplayedItems, timelineItems]
  )
  const hasMoreItems = useMemo(() => numOfDisplayedItems < timelineItems.length, [numOfDisplayedItems, timelineItems])

  const loadMoreItems = useCallback(() => {
    const updatedNumOfDisplayedItems = numOfDisplayedItems + DEFAULT_NUM_OF_DISPLAYED_ITEMS

    setNumOfDisplayedItems(updatedNumOfDisplayedItems)
  }, [numOfDisplayedItems])

  const handleSelectTimelineItem = useCallback(
    ({ timelineItem, section }) => {
      setSelectedTimelineItem({
        timelineItemId: timelineItem.id,
        section,
        analyticsPayload: {
          ...analyticsPayload,
          scanNumber: timelineItem.payload.scanNumber,
          source: 'Main Feed',
          timelineItemType: timelineItem.type
        }
      })
    },
    [setSelectedTimelineItem, analyticsPayload]
  )

  return {
    displayedTimelineItems,
    hasMoreItems,
    loadMoreItems,
    handleSelectTimelineItem
  }
}
