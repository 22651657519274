import { API, graphqlOperation, Storage } from 'aws-amplify'
import { push } from 'connected-react-router'
import { AsyncStatus } from 'consts'
import { Roles } from 'consts/authConsts'
import * as chatGraphql from 'graphql/chat.graphql'
import { isEmpty } from 'lodash'
import moment from 'moment'
import { ofType } from 'redux-observable'
import i18n from 'resources/locales/i18n'
import { concat, forkJoin, from, of } from 'rxjs'
import {
  catchError,
  filter,
  ignoreElements,
  map,
  mapTo,
  mergeMap,
  pluck,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs/operators'
import { trackEvent } from 'utils/analyticsUtils'
import { isUserOfAnyPracticeRole, isUserOfRole } from 'utils/authUtils'
import { setChatRoomResolved, uploadMessageWithMedia } from 'utils/chatUtils'
import { logInfo } from 'utils/logUtils'
import { isMobile } from 'utils/mobileUtils'
import { v4 as uuidv4, v4 } from 'uuid'
import Actions from '../actions'
import {
  BlueDotModes,
  DELETED_MESSAGE_CONTENT,
  PATIENTS_LIST_PAGE_SIZE,
  PATIENTS_LIST_PRELOAD_PAGES
} from '../consts/chatConsts'
import { AWS_DATE_TIME_UTC } from '../consts/dateTimeConsts'
import ROUTES from '../consts/routesConsts'
import {
  getScheduledMessage,
  scheduledMessagesByRoomIdSorted,
  searchPatientSearchModels
} from '../graphql/customQueries'
import { createMessage, updateMessage } from '../graphql/mutations'
import { getMessage, listPromotions, messagesByRoomIdSorted } from '../graphql/queries'
import {
  mapToBroadcastMessage,
  mapToImageMessage,
  mapToLastPromotionMessages,
  mapToLocalBroadcastMesage,
  mapToPromotions,
  mapToScanSummaryImageMessages
} from '../utils/mappers/messagesMapper'
import { mapLeadsSMToPatientsSM } from '../utils/mappers/patientsMapper'
import { createPatientsListFilter } from '../utils/searchUtils'
import { withS3Details } from '../utils/storageUtils'

export const requestPatientMessagesEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.PATIENT_MESSAGES_REQUESTED),
    pluck('payload'),
    map(payload => ({
      ...payload,
      scheduleTime: moment().toISOString()
    })),
    switchMap(({ roomId, nextToken, method, scheduleTime }) =>
      forkJoin({
        messages: API.graphql(
          graphqlOperation(messagesByRoomIdSorted, {
            roomId,
            sortDirection: 'DESC',
            limit: 20,
            nextToken
          })
        ),
        scheduledMessages: API.graphql(
          graphqlOperation(scheduledMessagesByRoomIdSorted, {
            roomId,
            limit: 100,
            sortDirection: 'DESC',
            scheduleTime: {
              ge: scheduleTime
            }
          })
        )
      }).pipe(
        mergeMap(({ messages, scheduledMessages }) =>
          of(
            Actions.patientMessagesReceived({
              messages: messages?.data?.messagesByRoomIdSorted,
              scheduledMessages: scheduledMessages?.data?.scheduledMessagesByRoomIdSorted,
              method,
              scheduleTime
            })
          )
        ),
        catchError(error => of(Actions.patientMessagesFailed(error)))
      )
    )
  )

const getImageFileFromS3 = key =>
  Storage.get(key)
    .then(fetch)
    .then(res => res.blob())

export const createScanSummaryImageMessageEpic = action$ =>
  action$.pipe(
    ofType(Actions.CREATE_SCAN_SUMMARY_IMAGE_MESSAGE),
    pluck('payload'),
    mergeMap(messageModel => {
      if (messageModel.cache?.includes('private')) {
        return of(
          Actions.requestPatientImageMessageCreate({
            ...messageModel
          })
        )
      }

      const key = JSON.parse(messageModel.content).key

      return from(getImageFileFromS3(key)).pipe(
        switchMap(file =>
          of(
            Actions.requestPatientImageMessageCreate({
              ...messageModel,
              content: file
            })
          )
        )
      )
    }),
    catchError(error => of(Actions.fetchRejected(error)))
  )

export const requestPatientImageMessageCreationEpic = action$ =>
  action$.pipe(
    ofType(Actions.PATIENT_IMAGE_MESSAGE_CREATE_REQUESTED),
    pluck('payload'),
    map(mapToImageMessage),
    mergeMap(({ messageModel, cache, content }) =>
      concat(
        of(Actions.messageAdded({ ...messageModel, isLocal: true, cache })),
        from(
          Storage.put(messageModel.id, content, {
            contentType: 'image/jpeg'
          })
        ).pipe(
          map(data => JSON.stringify(withS3Details(messageModel.id))),
          mergeMap(content =>
            from(
              API.graphql(
                graphqlOperation(createMessage, {
                  input: { ...messageModel, content }
                })
              )
            ).pipe(
              map(({ data }) => data?.createMessage),
              mergeMap(message => of(Actions.patientMessageCreateReceived(message))),
              catchError(error => of(Actions.fetchRejected(error)))
            )
          )
        )
      )
    )
  )

export const sendMessageEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.SEND_CHAT_MESSAGE),
    filter(({ payload }) => !payload.scheduleTime),
    withLatestFrom(state$),
    map(([{ payload }, state]) => {
      const newId = uuidv4()
      return {
        messageModel: {
          id: payload.id || newId,
          grinUserId: state.profileReducer.doctor.user.id,
          uploadingDate: moment.utc().format(AWS_DATE_TIME_UTC),
          roomId: payload.roomId,
          members: payload.members,
          type: payload.type,
          content: payload.content,
          readBy: payload.readBy,
          templateId: payload.templateId,
          promotionId: payload.promotionId,
          href: payload.baseUrl,
          owner: payload.patientDoctorUsername || state.profileReducer.doctor.username,
          entityRelatedId: payload.entityRelatedId,
          messageType: payload.messageType,
          metadata: payload?.metadata,
          meetingNumber: payload?.meetingNumber
        },
        s3Key: `${payload.id || `${payload.roomId}/${newId}`}${payload.extension ? `.${payload.extension}` : ''}`,
        cache: payload.cache
      }
    }),
    mergeMap(({ messageModel, s3Key, cache, username }) =>
      concat(
        of(
          Actions.messageAdded({
            ...messageModel,
            isLocal: true,
            cache
          })
        ),
        from(uploadMessageWithMedia({ messageInput: messageModel, s3Key })).pipe(
          mergeMap(message => of(Actions.patientMessageCreateReceived(message))),
          catchError(error => of(Actions.sendChatMessageFailed(error)))
        )
      )
    )
  )

export const sendScheduledMessageEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.SEND_CHAT_MESSAGE),
    filter(({ payload }) => !!payload.scheduleTime),
    withLatestFrom(state$),
    map(([{ payload }, state]) => {
      const newId = uuidv4()
      return {
        messageModel: {
          id: payload.id || newId,
          grinUserId: state.profileReducer.doctor.user.id,
          uploadingDate: moment.utc().format(AWS_DATE_TIME_UTC),
          roomId: payload.roomId,
          members: payload.members,
          type: payload.type,
          content: payload.content,
          readBy: payload.readBy,
          templateId: payload.templateId,
          promotionId: payload.promotionId,
          href: payload.baseUrl,
          owner: payload.patientDoctorUsername || state.profileReducer.doctor.username,
          entityRelatedId: payload.entityRelatedId,
          metadata: payload?.metadata
        },
        s3Key: `${payload.id || `${payload.roomId}/${newId}`}${payload.extension ? `.${payload.extension}` : ''}`,
        scheduleTime: payload.scheduleTime,
        cache: payload.cache
      }
    }),
    mergeMap(({ messageModel, s3Key, cache, username, scheduleTime }) =>
      concat(
        of(
          Actions.scheduledMessageAdded({
            roomId: messageModel.roomId,
            owner: messageModel.owner,
            id: messageModel.id,
            scheduleTime,
            isLocal: true,
            cache,
            payload: JSON.stringify({
              ...messageModel
            })
          })
        ),
        from(uploadMessageWithMedia({ messageInput: messageModel, s3Key, scheduleTime })).pipe(
          mergeMap(message => of(Actions.scheduledMessageSent(message))),
          catchError(error => of(Actions.sendChatMessageFailed(error)))
        )
      )
    )
  )

export const promotionMessageSentSideEffects = action$ =>
  action$.pipe(
    ofType(Actions.PATIENT_MESSAGE_CREATE_RECEIVED),
    filter(message => message.type === 'promotion'),
    map(message => Actions.fetchLastPromotionMessages({ roomId: message.roomId }))
  )

export const addLocalScanReviewToChatEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.REQUEST_SEND_SCAN_REVIEW),
    withLatestFrom(state$),
    filter(([action, state]) => !action.payload.isRetry),
    map(([action, state]) => ({
      uploadingDate: action.payload.uploadingDate,
      roomId: state.chatReducer.activeRoomId,
      grinUserId: state.profileReducer.doctor.user.id,
      members: [state.profileReducer.doctor.owner, state.patientsReducer.patient.owner],
      type: 'newScanReview',
      cache: action.payload.blob,
      readBy: state.profileReducer.doctor.owner,
      s3UniqueId: action.payload.s3UniqueId,
      uploadStatus: AsyncStatus.Loading
    })),
    mergeMap(({ cache, s3UniqueId, ...restMessageProps }) => {
      const messageModel = {
        id: uuidv4(),
        ...restMessageProps
      }
      return of(
        Actions.messageAdded({
          ...messageModel,
          isLocal: true,
          cache,
          s3UniqueId
        })
      )
    })
  )

export const onMessageNotificationReceivedEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.NOTIFICATION_RECEIVED),
    withLatestFrom(state$),
    map(([action, state]) => ({
      notification: action.payload,
      doctor: state.profileReducer.doctor
    })),
    filter(({ notification }) => notification.entityType === 'newMessage'),
    switchMap(({ notification, doctor }) =>
      from(API.graphql(graphqlOperation(getMessage, { id: notification.entityId }))).pipe(
        map(({ data }) => data?.getMessage),
        mergeMap(message =>
          of(
            Actions.messageAdded({
              ...message,
              uploadingDate: message.uploadingDate ?? message.createdAt,
              isSelf: doctor.user.id === message.grinUserId
            })
          )
        ),
        catchError(error => of(Actions.fetchRejected(error)))
      )
    )
  )

export const onScheduledMessageNotificationReceived = (action$, state$) =>
  action$.pipe(
    ofType(Actions.NOTIFICATION_RECEIVED),
    withLatestFrom(state$),
    map(([action, state]) => ({
      notification: action.payload,
      doctor: state.profileReducer.doctor
    })),
    filter(({ notification }) => notification.type === 'scheduledMessageCreated'),
    switchMap(({ notification, doctor }) =>
      from(API.graphql(graphqlOperation(getScheduledMessage, { id: notification.entityId }))).pipe(
        map(({ data }) => data?.getScheduledMessage),
        mergeMap(scheduledMessage =>
          of(
            Actions.scheduledMessageAdded({
              ...scheduledMessage,
              isSelf: doctor.user.id === scheduledMessage.grinUserId
            })
          )
        ),
        catchError(error => of(Actions.fetchRejected(error)))
      )
    )
  )

export const onScheduledMessageUpdatedEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.NOTIFICATION_RECEIVED),
    withLatestFrom(state$),
    map(([action, state]) => ({
      notification: action.payload
    })),
    filter(
      ({ notification }) =>
        notification.type === 'scheduledMessageSent' || notification.type === 'scheduledMessageDeleted'
    ),
    map(({ notification }) => Actions.deleteScheduledMessageReceived({ id: notification.entityId }))
  )

export const setMessageRead = (action$, state$) =>
  action$.pipe(
    ofType(Actions.MESSAGE_ADDED),
    withLatestFrom(state$),
    map(([action, state]) => ({
      message: action.payload,
      patient: state.router.location.pathname.includes(state.patientsReducer.patient.id)
        ? state.patientsReducer.patient
        : {},
      doctor: state.profileReducer.doctor
    })),
    filter(({ message, patient, doctor }) => patient.user?.rooms?.items?.includes(message.roomId)),
    tap(({ message, patient, doctor }) => {
      const { _deleted, isSelf, _lastChangedAt, ...messageToUpdate } = message
      if (doctor.user.id !== message.grinUserId) {
        API.graphql(
          graphqlOperation(updateMessage, {
            input: {
              ...messageToUpdate,
              readBy: `${message.readBy || ''}&${doctor.username}`
            }
          })
        )
      }
    }),
    ignoreElements()
  )

export const setMessageUnread = (action$, state$) =>
  action$.pipe(
    ofType(Actions.MESSAGE_ADDED),
    withLatestFrom(state$),
    map(([action, state]) => ({
      doctorUserId: state.profileReducer.doctor.user.id,
      message: action.payload,
      currentlyOpenedPatient: state.router.location.pathname.includes(state.patientsReducer.patient.id)
        ? state.patientsReducer.patient
        : {},
      patientSM: state.chatReducer.rooms.find(patientSM => patientSM.roomId === action.payload.roomId)
    })),
    filter(({ message, doctorUserId }) => doctorUserId !== message.grinUserId),
    filter(({ patientSM }) => !!patientSM),
    filter(
      ({ message, currentlyOpenedPatient }) => !currentlyOpenedPatient.user?.rooms?.items.includes(message.roomId)
    ),
    map(({ message, patientSM }) => ({
      message,
      roomId: patientSM.roomId
    })),
    mergeMap(({ message, roomId }) =>
      of(
        Actions.updateRoomReadStatus({
          [roomId]: false
        })
      )
    )
  )

export const setMessageUnreadRoomNotInPage = (action$, state$) =>
  action$.pipe(
    ofType(Actions.MESSAGE_ADDED),
    withLatestFrom(state$),
    map(([action, state]) => ({
      message: action.payload,
      rooms: state.chatReducer.rooms,
      messageRoomId: action.payload.roomId,
      doctorId: state.profileReducer.doctor.id,
      searchFilter: state.chatReducer.filter.searchFilter,
      tagsFilter: state.chatReducer.filter.tagsFilter,
      isSelf: action.payload.isSelf,
      paginationIssueFF: !!JSON.parse(state.profileReducer.doctor.user?.featureFlags?.flags || '{}').paginationIssueFF
    })),
    filter(
      ({ rooms, messageRoomId, isSelf }) => !isSelf && !rooms.find(patientSM => patientSM.roomId === messageRoomId)
    ),
    mergeMap(({ searchFilter, tagsFilter, paginationIssueFF, sortDirection }) =>
      of(
        Actions.fetchRooms({
          searchFilter,
          tagsFilter,
          addToTop: true,
          customLimit: 1,
          customFrom: 0,
          withoutLoader: true,
          shouldIgnoreNextToken: paginationIssueFF ? true : false
        })
      )
    )
  )

export const addSelfMessageToChat = action$ =>
  action$.pipe(
    ofType(Actions.PATIENT_MESSAGE_CREATE_RECEIVED),
    pluck('payload'),
    mergeMap(message => of(Actions.messageAdded(message)))
  )

export const timelineSelectedNavigationEpic = action$ =>
  action$.pipe(
    ofType(Actions.TIMELINE_SELECTED),
    map(({ payload }) => payload.patientId),
    filter(patientId => !window.location.pathname.includes(patientId)),
    map(patientId => push(`${ROUTES.PATIENTS}/${patientId}${isMobile() ? '/timeline' : ''}${window.location.search}`))
  )

export const chatRoomSelectedNavigationEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.CHAT_ROOM_SELECTED),
    withLatestFrom(state$),
    map(([{ payload }, state]) => ({
      patientId: payload.patientId,
      timelineItemId: payload.timelineItemId,
      disableRedirect: payload.disableRedirect,
      isMobile: state.appReducer.mobile?.isMobile
    })),
    filter(({ patientId, disableRedirect }) => !window.location.pathname.includes(patientId) && !disableRedirect),
    map(({ patientId, isMobile, timelineItemId }) => {
      const queryParams = new URLSearchParams(window.location.search)
      queryParams.delete('timelineItem')
      queryParams.delete('patientView')

      if (timelineItemId) {
        queryParams.append('timelineItem', timelineItemId)
      }

      const filteredQuery = queryParams.toString()
      const searchString = filteredQuery ? `?${filteredQuery.toString()}` : ''

      return push(`${ROUTES.PATIENTS}/${patientId}${isMobile ? '/chat' : ''}${searchString}`)
    })
  )

export const markRoomReadUponSelectionEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.CHAT_ROOM_SELECTED),
    withLatestFrom(state$),
    map(([action, state]) => ({
      roomId: action.payload.roomId,
      blueDotMode: JSON.parse(state.practiceReducer?.accountOwner?.user?.appSettings || '{}').blueDotMode,
      patientSM: state.chatReducer.rooms.find(patientSM => patientSM.roomId === action.payload.roomId)
    })),
    filter(
      ({ blueDotMode, patientSM, roomId }) =>
        roomId && isUserOfAnyPracticeRole() && blueDotMode === BlueDotModes.view && !patientSM?.isChatRoomResolved
    ),
    map(({ roomId, patientSM }) =>
      Actions.markRoomRead({
        roomId,
        analytics: {
          reason: 'chat-room-selected',
          patientId: patientSM.id
        }
      })
    )
  )

export const markRoomUnreadEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.MARK_ROOM_UNREAD),
    withLatestFrom(state$),
    map(([action, state]) => ({
      roomId: action.payload.roomId,
      doctorsUsername: state.profileReducer.doctor.user.username
    })),
    switchMap(({ roomId, doctorsUsername }) =>
      from(setChatRoomResolved({ roomId, isResolved: false })).pipe(
        mergeMap(() =>
          of(
            Actions.updateRoomReadStatus({
              [roomId]: false
            })
          )
        ),
        catchError(err => of(Actions.fetchRejected(err)))
      )
    )
  )

export const markRoomAsReadEpic = action$ =>
  action$.pipe(
    ofType(Actions.MARK_ROOM_READ),
    pluck('payload'),
    mergeMap(({ roomId }) =>
      from(API.post('grinServerlessApi', `/chat/v1/rooms/${roomId}/markRead`)).pipe(
        mergeMap(() => of(Actions.markRoomReadReceived({ roomId }))),
        catchError(({ response }) => of(Actions.markRoomReadFailed({ response, roomId })))
      )
    )
  )

export const markRoomAsReadAnayltics = (action$, state$) =>
  action$.pipe(
    ofType(Actions.MARK_ROOM_READ, Actions.MARK_ROOM_UNREAD),
    withLatestFrom(state$),
    map(([action, state]) => ({
      eventName: action.type === Actions.MARK_ROOM_READ ? 'Chat - Mark Room as Read' : 'Chat - Mark Room as Unread',
      eventData: {
        roomId: action.payload.roomId,
        reason: action.payload.analytics?.reason || 'unset',
        patientId: action.payload.analytics?.patientId,
        blueDotMode: JSON.parse(state.practiceReducer?.accountOwner?.user?.appSettings || '{}').blueDotMode
      }
    })),
    tap(({ eventName, eventData }) => trackEvent(eventName, eventData)),
    ignoreElements()
  )

export const markRoomAsReadFailed = action$ =>
  action$.pipe(
    ofType(Actions.MARK_ROOM_READ_FAILED),
    mapTo(
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('messages.chat.failedToMarkRead')
      })
    )
  )

export const deleteMessageEpic = action$ =>
  action$.pipe(
    ofType(Actions.DELETE_MESSAGE),
    pluck('payload'),
    switchMap(message =>
      from(
        API.graphql(
          graphqlOperation(updateMessage, {
            input: { ...message, content: DELETED_MESSAGE_CONTENT, type: 'text' }
          })
        )
      ).pipe(
        mergeMap(({ data }) => of(Actions.deletedMessageReceived(data.updateMessage))),
        catchError(() => of(Actions.deleteMessageFailed()))
      )
    )
  )

export const deletedMessageReceivedEpic = action$ =>
  action$.pipe(
    ofType(Actions.DELETED_MESSAGE_RECEIVED),
    mapTo(
      Actions.showSnackbar({
        type: 'success',
        text: i18n.t('messages.chat.deletedSuccessfully')
      })
    )
  )

export const deleteMessageFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.DELETE_MESSAGE_FAILED),
    mapTo(
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('messages.chat.failedToDelete')
      })
    )
  )

export const onMessageDeletedNotificationReceivedEpic = action$ =>
  action$.pipe(
    ofType(Actions.NOTIFICATION_RECEIVED),
    pluck('payload'),
    filter(notification => notification.entityType === 'Message' && notification.method === 'DELETE'),
    map(notification => Actions.softDeleteChatMessageReceived({ messageId: notification.entityId }))
  )

export const broadcastMessageResultNotificationReceivedEpic = action$ =>
  action$.pipe(
    ofType(Actions.NOTIFICATION_RECEIVED),
    pluck('payload'),
    filter(notification => notification.type === 'broadcastMessageResult'),
    map(notification => {
      const { deliveredCount, failedCount } = JSON.parse(notification.body)
      const type = deliveredCount === 0 ? 'error' : failedCount > 0 ? 'warning' : 'success'
      return Actions.showSnackbar({
        type,
        time: 10000,
        text: i18n.t(`broadcastResultToast.${type}`, { delivered: deliveredCount, total: deliveredCount + failedCount })
      })
    })
  )

export const copyMessageEpic = action$ =>
  action$.pipe(
    ofType(Actions.COPY_MESSAGE),
    pluck('payload'),
    map(message => {
      navigator.clipboard.writeText(message.content)
      return Actions.showSnackbar({
        type: 'success',
        text: i18n.t('messages.chat.messageCopied')
      })
    })
  )

export const requestRoomsEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.FETCH_ROOMS),
    withLatestFrom(state$),
    map(([action, state]) => ({
      payload: action.payload,
      newSearch: action.payload?.newSearch,
      tagsFilter: action.payload?.tagsFilter,
      doctorId: state.practiceReducer.accountOwner.id,
      accountOwnerId: state.profileReducer.accountOwnerId,
      searchFilter: action.payload?.searchFilter,
      sortDirection: state.chatReducer.sort.direction,
      doctorFilter: action.payload?.doctorFilter,
      rooms: state.chatReducer.rooms,
      isAdmin: isUserOfRole([Roles.Admin]),
      allowedGroups: JSON.parse(state.profileReducer.doctor.user?.allowed_groups_permissions || '[]'),
      hiAssignedDoctos: state.multiPracticeReducer.assignedDoctors?.data?.map(doctorSM => doctorSM.id),
      shouldIgnoreNextToken: !!action.payload.shouldIgnoreNextToken
    })),
    map(
      ({
        isAdmin,
        accountOwnerId,
        doctorId,
        tagsFilter,
        rooms,
        payload,
        newSearch,
        allowedGroups,
        searchFilter,
        doctorFilter,
        hiAssignedDoctos,
        shouldIgnoreNextToken,
        sortDirection
      } = {}) => {
        const patientsListfilter = createPatientsListFilter({
          isAdmin,
          doctorId: accountOwnerId || doctorId,
          filter: {
            tagsFilter,
            searchFilter,
            doctorFilter: doctorFilter ? [doctorFilter] : hiAssignedDoctos
          },
          allowedGroups
        })

        return { filter: patientsListfilter, rooms, payload, newSearch, shouldIgnoreNextToken, sortDirection }
      }
    ),
    switchMap(({ filter, rooms, newSearch, addToTop, payload, shouldIgnoreNextToken, sortDirection } = {}) => {
      const startTime = new Date().getTime()
      const queryPayload = {
        limit: payload.customLimit || PATIENTS_LIST_PAGE_SIZE * PATIENTS_LIST_PRELOAD_PAGES,
        filter: isEmpty(filter) ? null : filter,
        from: payload.customFrom ?? (newSearch ? 0 : rooms?.length || 0),
        sort: {
          field: 'lastMessageTimestamp',
          direction: sortDirection
        }
      }

      logInfo('Fetch rooms - query payload', { queryPayload, actionPayload: payload })

      return from(API.graphql(graphqlOperation(searchPatientSearchModels, queryPayload))).pipe(
        map(res => mapLeadsSMToPatientsSM(res.data?.searchPatientSearchModels)),
        map(res => (shouldIgnoreNextToken ? { ...res, nextToken: undefined } : res)),
        tap(data =>
          logInfo(`Fetch rooms searchPatientSearchModels response`, { data, took: new Date().getTime() - startTime })
        ),
        mergeMap(data => of(Actions.roomsReceived({ newSearch, addToTop: payload.addToTop, ...data }))),
        catchError(error =>
          concat(
            of(Actions.fetchRoomsFailed(error)),
            of(
              Actions.showSnackbar({
                type: 'error',
                text: i18n.t('messages.somethingWentWrong')
              })
            )
          )
        )
      )
    })
  )

export const listPromotionsEpic = action$ =>
  action$.pipe(
    ofType(Actions.FETCH_PROMOTIONS),
    switchMap(() =>
      from(API.graphql(graphqlOperation(listPromotions, { limit: 1000 }))).pipe(
        map(res => res.data.listPromotions.items),
        map(mapToPromotions),
        mergeMap(data => of(Actions.fetchPromotionsReceived(data))),
        catchError(err => of(Actions.fetchPromotionsFailed(err)))
      )
    )
  )

export const fetchLastPromotionMessagesEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.FETCH_LAST_PROMOTION_MESSAGES),
    withLatestFrom(state$),
    map(([action, state]) => ({
      roomId: action.payload.roomId,
      cachedRoomId: state.chatReducer.promotions.lastMessages.roomId
    })),
    filter(({ roomId, cachedRoomId }) => roomId !== cachedRoomId),
    switchMap(({ roomId, cachedRoomId }) =>
      from(
        API.graphql(
          graphqlOperation(messagesByRoomIdSorted, {
            roomId,
            filter: { type: { eq: 'promotion' } },
            sortDirection: 'DESC',
            limit: 1000
          })
        ).then(res => mapToLastPromotionMessages(res.data.messagesByRoomIdSorted.items))
      ).pipe(
        mergeMap(data => of(Actions.lastPromotionMessagesReceived({ roomId, messages: data }))),
        catchError(err => of(Actions.lastPromotionMessagesFailed(err)))
      )
    )
  )

export const createBroadcastMessageEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.CREATE_BROADCAST_MESSAGE),
    pluck('payload'),
    withLatestFrom(state$),
    map(mapToBroadcastMessage),
    mergeMap(({ body, localMessage }) =>
      concat(
        of(Actions.createBroadcastLocalMessage(localMessage)),
        from(
          API.post('grinServerlessApi', '/chat/v1/messages/broadcast', {
            body
          })
        ).pipe(
          mergeMap(message => of(Actions.createBroadcastMessageReceived())),
          catchError(error => of(Actions.createBroadcastMessageFailed(error)))
        )
      )
    )
  )

export const createBroadcastFileMessageEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.CREATE_BROADCAST_FILE_MESSAGE),
    pluck('payload'),
    map(messageProps => {
      const newId = v4()
      return {
        message: {
          ...messageProps,
          id: messageProps.id ?? newId
        },
        s3Key: `${messageProps.id ?? `${v4()}/${newId}`}${messageProps.extension ? `.${messageProps.extension}` : ''}`
      }
    }),
    withLatestFrom(state$),
    mergeMap(([{ message, s3Key }, state]) =>
      concat(
        of(
          Actions.createBroadcastLocalMessage(
            mapToLocalBroadcastMesage({
              payload: message,
              doctor: state.profileReducer.doctor,
              isLocal: true,
              isBroadcast: !state.chatReducer.broadcast.isPersonalMessage
            })
          )
        ),
        from(
          Storage.put(s3Key, message.content, {
            contentType: message.contentType
          })
        ).pipe(
          map(data => JSON.stringify(withS3Details(s3Key))),
          mergeMap(content => {
            const { cache, contentType, extension, ...restMessage } = message
            return of(
              Actions.createBroadcastMessage({
                ...restMessage,
                content
              })
            )
          }),
          catchError(error => of(Actions.createBroadcastFileMessageFailed(error)))
        )
      )
    )
  )

export const createBroadcastMessageReceivedEpic = action$ =>
  action$.pipe(
    ofType(Actions.CREATE_BROADCAST_MESSAGE_RECEIVED),
    mapTo(
      Actions.showSnackbar({
        type: 'success',
        text: i18n.t('messages.chat.boradcast.sentSuccessfully')
      })
    )
  )

export const createBroadcastMessageFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.CREATE_BROADCAST_MESSAGE_FAILED, Actions.CREATE_BROADCAST_FILE_MESSAGE_FAILED),
    mapTo(
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('messages.chat.boradcast.failedToSend')
      })
    )
  )

export const resetBroadcastStateEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.FETCH_ROOMS),
    withLatestFrom(state$),
    map(([action, state]) => ({
      isBroadcastModeOn: state.chatReducer.broadcast.isModeOn,
      isFetchingMoreRooms: action.payload?.withoutLoader
    })),
    filter(({ isBroadcastModeOn, isFetchingMoreRooms }) => isBroadcastModeOn && !isFetchingMoreRooms),
    mapTo(Actions.resetBroadcastState())
  )

export const sendSelectedScanSummaryImagesEpic = (action$, state$) =>
  action$.pipe(
    ofType(Actions.SEND_SELECTED_SCAN_SUMMARY_IMAGES),
    withLatestFrom(state$),
    switchMap(([action, state]) =>
      from(mapToScanSummaryImageMessages([action, state])).pipe(
        mergeMap(messageInputs => {
          const observables = messageInputs.map(({ isOriginalImage, ...messageModel }) =>
            isOriginalImage
              ? of(Actions.createScanSummaryImageMessage(messageModel))
              : of(Actions.requestPatientImageMessageCreate(messageModel))
          )
          return concat(...observables)
        })
      )
    )
  )

export const deleteScheduledMessageEpic = action$ =>
  action$.pipe(
    ofType(Actions.DELETE_SCHEDULED_MESSAGE),
    pluck('payload'),
    mergeMap(({ id, _version }) =>
      from(API.del('grinServerlessApi', `/chat/v1/messages/scheduled/${id}`)).pipe(
        mergeMap(() => of(Actions.deleteScheduledMessageReceived({ id }))),
        catchError(({ response }) => of(Actions.deleteScheduledMessageFailed({ id, response })))
      )
    )
  )

export const deleteScheduledMessageFailedEpic = action$ =>
  action$.pipe(
    ofType(Actions.DELETE_SCHEDULED_MESSAGE_FAILED),
    pluck('payload'),
    mapTo(
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('messages.chat.failedToDelete')
      })
    )
  )

export const softDeleteMessageEpic = action$ =>
  action$.pipe(
    ofType(Actions.SOFT_DELETE_CHAT_MESSAGE),
    pluck('payload'),
    mergeMap(({ messageId, withSnackbar = false }) =>
      from(API.del('grinServerlessApi', `/chat/v1/messages/${messageId}/soft`)).pipe(
        mergeMap(res => of(Actions.softDeleteChatMessageReceived({ messageId }))),
        catchError(ex => of(Actions.softDeleteChatMessageFailed({ error: ex, messageId })))
      )
    )
  )

export const softDeleteMessageFailed = action$ =>
  action$.pipe(
    ofType(Actions.SOFT_DELETE_CHAT_MESSAGE_FAILED),
    mapTo(
      Actions.showSnackbar({
        type: 'error',
        text: i18n.t('errors.failedToSoftDeleteMessage')
      })
    )
  )

export const toggleUploadFileSizeExceededEpic = action$ =>
  action$.pipe(
    ofType(Actions.TOGGLE_UPLOAD_FILE_SIZE_EXCEEDED),
    pluck('payload'),
    map(({ message }) =>
      Actions.showSnackbar({
        type: 'error',
        text: message
      })
    )
  )

export const editChatMessageEpic = action$ =>
  action$.pipe(
    ofType(Actions.EDIT_CHAT_MESSAGE),
    pluck('payload'),
    switchMap(({ messageId, content }) =>
      from(
        API.put('grinServerlessApi', '/chat/v1/messages', {
          body: { messageId, content }
        })
      ).pipe(
        mergeMap(res =>
          concat(
            of(Actions.editChatMessageReceived({ updatedMessage: res.updatedMessage })),
            of(Actions.showSnackbar({ type: 'success', text: i18n.t('messages.chat.messageUpdatedSuccessfully') }))
          )
        ),
        catchError(err =>
          concat(
            of(Actions.editChatMessageFailed(err)),
            of(
              Actions.showSnackbar({
                type: 'error',
                text: i18n.t('errors.somethingWentWrongTryAgain')
              })
            )
          )
        )
      )
    )
  )

export const messageEditedLiveUpdatesEpic = action$ =>
  action$.pipe(
    ofType(Actions.NOTIFICATION_RECEIVED),
    pluck('payload'),
    filter(({ eventType }) => eventType === 'messageEdited'),
    map(({ updatedMessage }) => Actions.editChatMessageReceived({ updatedMessage }))
  )

export const fetchMessageAuditEpic = action$ =>
  action$.pipe(
    ofType(Actions.FETCH_MESSAGE_AUDIT),
    pluck('payload'),
    switchMap(({ messageId }) =>
      from(
        API.graphql(
          graphqlOperation(chatGraphql.getMessage(chatGraphql.chat.messageWithAudit), {
            id: messageId
          })
        )
      ).pipe(
        mergeMap(res => of(Actions.fetchMessageAuditReceived({ message: res.data.getMessage }))),
        catchError(err => of(Actions.fetchMessageAuditFailed(err)))
      )
    )
  )
