import moment from 'moment'
import i18n from 'resources/locales/i18n'

export const calculateTimeDifference = (date1, date2, measurement) => {
  const firstDate = moment(date1)
  const secondDate = moment(date2)
  return firstDate.diff(secondDate, measurement)
}

export const generateHoursList = (step = 30, start = '00:00', end = '23:30', labelFormat = 'hh:mm a') => {
  const startTime = moment(start, 'HH:mm')
  const endTime = moment(end, 'HH:mm')
  if (endTime.isBefore(startTime)) {
    endTime.add(1, 'day')
  }
  const timeStops = []
  while (startTime <= endTime) {
    timeStops.push({
      value: new moment(startTime).format('HH:mm'),
      label: new moment(startTime).format(labelFormat)
    })
    startTime.add(step, 'minutes')
  }
  return timeStops
}

export const compareDayOfWeekNames = (a, b) => {
  const order = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
  return order.indexOf(a) - order.indexOf(b)
}

export const calculateTimezoneHoursOffset = timezone => {
  const offsetHours = moment().tz(timezone).utcOffset() / 60
  return offsetHours > 0 ? `+${offsetHours}` : offsetHours.toString()
}

export const generateMonthsRange = ({ originDate, targetDate }) => {
  const monthDates = []

  const startDate = moment(originDate).startOf('month')
  const endDate = moment(targetDate).startOf('month')

  let tempDate = startDate.clone()

  while (tempDate.isSameOrBefore(endDate)) {
    monthDates.push(tempDate.clone().valueOf())
    tempDate.add(1, 'month')
  }

  return monthDates
}

export const createdAtComparator = (obj1, obj2) => {
  const timestamp1 = new Date(obj1.createdAt).getTime()
  const timestamp2 = new Date(obj2.createdAt).getTime()
  return timestamp1 - timestamp2
}

export const getTimeOfDay = () => {
  const now = new Date()
  const hour = now.getHours()

  if (hour >= 5 && hour < 12) {
    return i18n.t('general.timeOfDay.morning')
  } else if (hour >= 12 && hour < 17) {
    return i18n.t('general.timeOfDay.afterNoon')
  } else if (hour >= 17 && hour < 21) {
    return i18n.t('general.timeOfDay.evening')
  }

  return i18n.t('general.timeOfDay.night')
}

/**
 * Gets a date and calculates the number of days that has passed since that date.
 *
 * @param {String} date - A date in ISO format.
 * @param {String} [relativeTo] - A date in ISO format by which the calculation is done. Default is now.
 * @returns {number} The number of days that passed between `date` and `relativeTo`.
 */
export const calculateDaysAgo = (date, relativeTo = new Date().toISOString()) =>
  moment(relativeTo).startOf('day').diff(moment(date).startOf('day'), 'days')
