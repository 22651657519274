import { searchAllPracticeTeamMembers, searchPracticeAccountOwner } from 'graphql/practiceMembers.graphql'
import { api, REQUEST_TYPE } from 'store/api'
import { ModuleTags } from './consts'

export const Practice = api.injectEndpoints({
  endpoints: build => ({
    fetchPracticeTeamMembers: build.query({
      query: ({ accountOwnerId, dso, hiGroup }) => ({
        query: searchAllPracticeTeamMembers,
        queryName: 'searchDoctorSearchModels',
        type: REQUEST_TYPE.GRAPHQL_FETCH_ALL,
        variables: {
          filter: {
            or: [
              {
                id: { eq: accountOwnerId }
              },
              {
                accountOwnerId: { eq: accountOwnerId }
              },
              dso &&
                dso !== 'none' && {
                  allowedGroups: { matchPhrase: `${dso}#` }
                },
              hiGroup && {
                allowedGroups: { matchPhrase: `${hiGroup}#HI` }
              }
            ].filter(condition => !!condition)
          }
        }
      }),
      transformResponse: docSMs => docSMs.filter(docSM => docSM.doctor?.auth_status !== 'disabled'),
      providesTags: [ModuleTags.PracticeTeamMembers]
    }),
    fetchPracticeAccountOwnerSM: build.query({
      query: ({ accountOwnerId, accountOwnerFields }) => ({
        query: searchPracticeAccountOwner(accountOwnerFields),
        queryName: 'searchDoctorSearchModels',
        type: REQUEST_TYPE.GRAPHQL,
        variables: {
          filter: {
            id: { eq: accountOwnerId }
          }
        }
      }),
      transformResponse: res => res.searchDoctorSearchModels.items[0],
      providesTags: [ModuleTags.PracticeTeamMembers]
    })
  })
})

export const { useFetchPracticeTeamMembersQuery, useFetchPracticeAccountOwnerSMQuery } = Practice
