import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import Tooltip from 'components/common/Tooltip'

const useStyles = makeStyles(theme => ({
  link: {
    textDecoration: 'none'
  },
  iconContainer: {
    width: 26,
    height: 26,
    backgroundColor: ({ iconBackgroundColor }) => iconBackgroundColor,
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  label: {
    fontSize: ({ fontSize }) => fontSize
  }
}))

const ScanStatItem = ({
  label,
  icon,
  onClick = () => {},
  href,
  fontSize = 14,
  iconBackgroundColor = 'rgba(0,0,0,0.1)',
  tooltip = ''
}) => {
  const classes = useStyles({ fontSize, iconBackgroundColor })

  return (
    <Tooltip value={tooltip} placement="top">
      <a href={href} className={classes.link}>
        <Grid container alignItems="center" spacing={1} className={classes.scanStatItemContainer} onClick={onClick}>
          <Grid item>
            <div className={classes.iconContainer}>{icon}</div>
          </Grid>
          <Grid item>
            <DazzedParagraph14 strong className={classes.label}>
              {label}
            </DazzedParagraph14>
          </Grid>
        </Grid>
      </a>
    </Tooltip>
  )
}

export default ScanStatItem
