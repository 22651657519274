import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Skeleton } from '@material-ui/lab'

const useStyles = makeStyles(theme => ({
  loadingStateRoot: {
    marginTop: 20,
    padding: 16
  },
  notificationSkeleton: {
    borderRadius: '8px',
    width: '100%',
    height: '83px',
    background: '#EEEEEE',
    marginBottom: 12
  }
}))

const NotificationCenterLoadingState = () => {
  const classes = useStyles()

  return (
    <div className={classes.loadingStateRoot}>
      <Skeleton variant="rect" className={classes.notificationSkeleton} />
      <Skeleton variant="rect" className={classes.notificationSkeleton} />
      <Skeleton variant="rect" className={classes.notificationSkeleton} />
      <Skeleton variant="rect" className={classes.notificationSkeleton} />
      <Skeleton variant="rect" className={classes.notificationSkeleton} />
    </div>
  )
}

export default NotificationCenterLoadingState
