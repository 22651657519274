import React, { useEffect, useState } from 'react'
import Viewer from 'react-viewer'

const ImageViewerWrapper = ({ visible, activeIndex, onClose, ...rest }) => {
  const [activeImageIndex, setActiveImageIndex] = useState(activeIndex || 0)

  useEffect(() => {
    if (visible) {
      const handleDocumentClick = (element: PointerEvent) => {
        if (element.target.parentElement.classList.contains('react-viewer')) {
          onClose()
        }
      }

      document.addEventListener('click', handleDocumentClick)

      return () => {
        document.removeEventListener('click', handleDocumentClick)
      }
    }
  }, [onClose, visible])

  return (
    <Viewer
      {...rest}
      visible={visible}
      onClose={onClose}
      activeIndex={activeImageIndex}
      onChange={(activeImage, newIndex) => setActiveImageIndex(newIndex)}
    />
  )
}

export default ImageViewerWrapper
