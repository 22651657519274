import React, { useRef } from 'react'
import TitleWithText from 'components/Patients/PatientGuidelines/PatientBrief/Categories/TitleWithText'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { sortTeeth } from 'utils/giUtils'
import NightTimeOnly from '../Actions/NightTimeOnly'
import TeethGridPopup from './TeethGridPopup'
import UntrackedToothGridItem from 'components/common/inputs/TeethSelectionGrid/UntrackedToothGridItem'
import GenericToothGridItem from 'components/common/inputs/TeethSelectionGrid/GenericToothGridItem'
import TypicalAdvanceFrequency from '../Actions/TypicalAdvanceFrequency'

const AlignersTxTracking = ({ items }) => {
  const { t } = useTranslation()

  const untrackedTeethRef = useRef()
  const attachmentsRef = useRef()

  const { untrackedTeeth, attachments, nightTimeOnly, typicalAdvanceFrequency } = items

  return (
    <>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          {untrackedTeeth && (
            <>
              <TitleWithText
                title={`${t(`dialogs.hiPatientGuidelinesViewer.items.untrackedTeeth`)}:`}
                text={<span ref={untrackedTeethRef}>{sortTeeth(untrackedTeeth).join(', ')}</span>}
              />
              <TeethGridPopup
                anchorEl={untrackedTeethRef.current}
                teeth={untrackedTeeth}
                toothGridItemComponent={UntrackedToothGridItem}
                offset={{ y: 20, x: 380 }}
              />
            </>
          )}
        </Grid>
        <Grid item>
          {attachments && (
            <>
              <TitleWithText
                title={`${t(`dialogs.hiPatientGuidelinesViewer.items.attachments`)}:`}
                text={<span ref={attachmentsRef}>{sortTeeth(attachments).join(', ')}</span>}
              />
              <TeethGridPopup
                anchorEl={attachmentsRef.current}
                teeth={attachments}
                toothGridItemComponent={GenericToothGridItem}
                offset={{ y: 20, x: 375 }}
              />
            </>
          )}
        </Grid>
      </Grid>
      <TypicalAdvanceFrequency checked={typicalAdvanceFrequency} frequency={typicalAdvanceFrequency?.frequency} />
      <NightTimeOnly checked={nightTimeOnly} />
    </>
  )
}

export default AlignersTxTracking
