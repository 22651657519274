import * as React from 'react'

const defaultColor = '#9BA3B2'
const activeColor = '#3C52EF'

export default ({ isActive = false }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.4993 18.4993H15.4993V15.4993H18.4993V13.4993H15.4993V10.4993H13.4993V13.4993H10.4993V15.4993H13.4993V18.4993ZM6.99928 19.9993V8.97428C6.99928 8.42428 7.19928 7.95761 7.59928 7.57428C7.99928 7.19095 8.47428 6.99928 9.02428 6.99928H19.9993C20.5493 6.99928 21.0201 7.19511 21.4118 7.58678C21.8034 7.97845 21.9993 8.44928 21.9993 8.99928V16.9993L16.9993 21.9993H8.99928C8.44928 21.9993 7.97845 21.8034 7.58678 21.4118C7.19511 21.0201 6.99928 20.5493 6.99928 19.9993ZM2.02428 6.24928C1.92428 5.69928 2.03261 5.20345 2.34928 4.76178C2.66595 4.32011 3.09928 4.04928 3.64928 3.94928L14.4993 2.02428C15.0493 1.92428 15.5451 2.03261 15.9868 2.34928C16.4284 2.66595 16.6993 3.09928 16.7993 3.64928L17.0493 4.99928H8.99928C7.89928 4.99928 6.95761 5.39095 6.17428 6.17428C5.39095 6.95761 4.99928 7.89928 4.99928 8.99928V18.5493C4.73261 18.3993 4.50345 18.1993 4.31178 17.9493C4.12011 17.6993 3.99928 17.4159 3.94928 17.0993L2.02428 6.24928Z"
      fill={isActive ? activeColor : defaultColor}
    />
  </svg>
)
