import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import { CircularProgress, Grid } from '@material-ui/core'
import DazzedParagraph12 from '../text/DazzedParagraph12'

const useStyles = makeStyles(theme => ({
  label: {
    fontSize: ({ fontSize }) => fontSize,
    fontWeight: 500,
    cursor: ({ disabled }) => (disabled ? 'default' : 'pointer'),
    color: ({ disabled }) => (disabled ? 'var(--text-color-13)' : 'var(--text-color-25)'),
    textDecoration: ({ underline }) => (underline ? 'underline' : 'none')
  },
  iconContainer: {
    cursor: ({ disabled }) => (disabled ? 'default' : 'pointer'),
    filter: ({ disabled }) => (disabled ? 'grayscale(80%) brightness(1.2)' : 'none'),
    lineHeight: 0.8
  }
}))

const LinkButton = ({
  label = '',
  onClick = () => {},
  icon,
  iconContainerClassName = '',
  labelClassName = '',
  disabled = false,
  isLoading = false,
  loaderSize = 12,
  underline = false,
  fontSize = 12,
  containerJustifyContent = 'flex-start',
  spacing = 1,
  bold = false
}) => {
  const classes = useStyles({ disabled, underline, fontSize, bold })

  const handleClick = useCallback(() => {
    if (!disabled) {
      onClick()
    }
  }, [disabled, onClick])

  return (
    <Grid container direction="row" alignItems="center" justifyContent={containerJustifyContent} spacing={spacing}>
      {isLoading ? (
        <Grid item>
          <CircularProgress size={loaderSize} />
        </Grid>
      ) : (
        <>
          {icon && (
            <Grid item className={[classes.iconContainer, iconContainerClassName].join(' ')} onClick={handleClick}>
              {icon}
            </Grid>
          )}
          <Grid item onClick={handleClick}>
            <DazzedParagraph12
              className={[classes.label, labelClassName].join(' ')}
              thickness={bold ? 'bold' : 'strong'}
            >
              {label}
            </DazzedParagraph12>
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default LinkButton
