import useScans from 'hooks/useScans'
import { sortBy } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

export default () => {
  const [currentGrinScan, setCurrentGrinScan] = useState()

  const { scanIdToNumberDict } = useScans()
  const grinScans = useSelector(state => state.patientsReducer.patient?.grinScans?.items || [])

  const sortedScans = useMemo(() => sortBy(grinScans, 'createdAt'), [grinScans])
  const currentScanIndex = useMemo(
    () => sortedScans.findIndex(scan => scan.id === currentGrinScan?.id),
    [currentGrinScan, sortedScans]
  )
  const alignerNumber = useMemo(
    () => (currentGrinScan?.withAligner ? currentGrinScan.applianceIndex : null),
    [currentGrinScan]
  )
  const scanNumber = useMemo(() => scanIdToNumberDict?.[currentGrinScan?.id] + 1, [currentGrinScan, scanIdToNumberDict])
  const nextScan = useMemo(() => {
    if (currentScanIndex !== -1) {
      return sortedScans[currentScanIndex + 1]
    }

    return null
  }, [currentScanIndex, sortedScans])
  const previousScan = useMemo(() => {
    if (currentScanIndex !== -1) {
      return sortedScans[currentScanIndex - 1]
    }

    return null
  }, [currentScanIndex, sortedScans])

  const navigateToNextScan = useCallback(() => {
    if (!nextScan) {
      return
    }

    setCurrentGrinScan(sortedScans[currentScanIndex + 1])
  }, [currentScanIndex, sortedScans, nextScan])

  const navigateToPreviousScan = useCallback(() => {
    if (!previousScan) {
      return
    }

    setCurrentGrinScan(sortedScans[currentScanIndex - 1])
  }, [currentScanIndex, sortedScans, previousScan])

  const handleGrinScanSelected = useCallback(
    scanId => {
      setCurrentGrinScan(grinScans.find(scan => scan.id === scanId))
    },
    [grinScans]
  )

  useEffect(() => {
    if (grinScans.length > 0 && !currentGrinScan) {
      setCurrentGrinScan(grinScans[grinScans.length - 1])
    }
  }, [currentGrinScan, grinScans])

  return {
    scanNumber,
    currentGrinScan,
    navigateToNextScan,
    navigateToPreviousScan,
    handleGrinScanSelected,
    hasNextScan: !!nextScan,
    hasPreviousScan: !!previousScan,
    alignerNumber,
    sortedScans
  }
}
