import { makeStyles, Menu } from '@material-ui/core'
import { AssignmentOutlined } from '@material-ui/icons'
import Actions from 'actions'
import {
  AccountSettings,
  DesktopVersion,
  GrinAssets,
  Help,
  MobileInvitePatient,
  OrderGrinScopes,
  PracticeAnalytics
} from 'components/common/icons'
import AddAccount from 'components/common/icons/AddAccount'
import LatestReleasesIcon from 'components/common/icons/LatestReleasesIcon'
import ShareFeedback from 'components/common/icons/ShareFeedbackIcon'
import { ROUTES } from 'consts'
import useGaFlags from 'hooks/useGaFlags'
import useOrderScopes from 'hooks/useOrderScopes'
import usePlanLimits from 'hooks/usePlanLimits'
import useRolePermissions from 'hooks/useRolePermissions'
import { countAuthProfiles } from 'logic/loginProfilesLogic'
import React, { Fragment, useCallback, useMemo } from 'react'
import { isMobile as isMobileDevice } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { trackEvent } from 'utils/analyticsUtils'
import { isDesktopForced, isMobile, setForceDesktop } from 'utils/mobileUtils'
import { getKistOrder } from 'utils/storageUtils'
import AppProfileMenuItem from './AppProfileMenuItem'
import AuthProfiles from './AuthProfiles/AuthProfiles'

const useStyles = makeStyles({
  menu: {
    width: ({ authProfilesCount, switchAccountsGaFlag }) =>
      !switchAccountsGaFlag ? '246px' : authProfilesCount > 1 ? '338px' : '280px'
  },
  appProfiles: {
    margin: '12px 0'
  }
})

const AppProfileMenu = ({ anchorEl, handleClick, handleClose, doctorId }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { permissions } = useRolePermissions()
  const grinAssetsLink = useSelector(state => state.appReducer.appconfig.app?.grinAssetsLink)
  const planLimits = usePlanLimits()
  const hasDraftOrder = getKistOrder()
  const gaFlags = useGaFlags()

  const isOpen = useMemo(() => Boolean(anchorEl), [anchorEl])
  const authProfilesCount = useMemo(() => countAuthProfiles(), [])

  const { openOrderScopesModal } = useOrderScopes()

  const handleLoginToAnotherAccount = useCallback(() => {
    window.open(ROUTES.SIGN_IN, '_blank')
    trackEvent('App Profile - login to another account clicked')
  }, [])

  const classes = useStyles({ switchAccountsGaFlag: gaFlags?.switchAccounts, authProfilesCount })

  const appMenuActions = useMemo(
    () => [
      {
        label: t('common.appMenu.opsDashboard'),
        to: ROUTES.SUPPORT_DASHBOARD,
        handleClose,
        condition: permissions.opsDashboard
      },
      {
        label: t('common.appMenu.accountSettings'),
        icon: <AccountSettings />,
        to: ROUTES.ACCOUNT_SETTINGS,
        handleClose,
        condition: !isMobile()
      },
      {
        label: t('common.appMenu.orderScopes'),
        hasIndicator: isMobile() && hasDraftOrder,
        disabled: planLimits.maxScopes <= 0,
        handleClose,
        icon: <OrderGrinScopes />,
        onClickExtra: openOrderScopesModal,
        condition: permissions.billing
      },
      {
        label: t('common.appMenu.invitePatient'),
        handleClose,
        icon: <MobileInvitePatient />,
        to: ROUTES.INVITE_PATIENT,
        condition: isMobile() && permissions.invitePatient
      },
      {
        label: t('common.appMenu.practiceAnalytics'),
        icon: <PracticeAnalytics />,
        to: ROUTES.NEW_DOC_STATS,
        handleClose,
        condition:
          (permissions.practiceAnalytics || permissions.dsoAnalytics || permissions.distributorAnalytics) && !isMobile()
      },
      {
        label: t('common.appMenu.quickDoctorSignup'),
        icon: <AssignmentOutlined />,
        onClickExtra: () => window.open(ROUTES.QUICK_SIGN_UP, '_blank'),
        handleClose,
        condition: permissions.quickSignUp
      },
      {
        label: isDesktopForced() ? t('common.appMenu.mobileVersion') : t('common.appMenu.desktopVersion'),
        onClickExtra: () => setForceDesktop(!isDesktopForced()),
        icon: <DesktopVersion />,
        handleClose,
        condition: isMobileDevice
      },
      {
        label: t('common.appMenu.marketingAssets'),
        icon: <GrinAssets />,
        handleClose,
        onClickExtra: () => window.open(grinAssetsLink, '_blank')
      },
      {
        label: t('common.appMenu.shareFeedback'),
        icon: <ShareFeedback />,
        handleClose,
        onClickExtra: () => {
          trackEvent('App Feedback Modal - modal opened', {
            source: 'Profile menu'
          })
          dispatch(
            Actions.toggleAppFeedbackDialog({
              value: true,
              description: t('dialogs.shareFeedback.feedbackDescription')
            })
          )
        }
      },
      {
        label: t('common.appMenu.latestReleases.title'),
        icon: <LatestReleasesIcon />,
        to: ROUTES.LATEST_RELEASE_NOTES,
        handleClose,
        condition: !isMobile()
      },
      {
        label: t('common.appMenu.help'),
        onClickExtra: () => dispatch(Actions.openHelpPopup()),
        icon: <Help />,
        handleClose
      },
      {
        label: t('common.appMenu.logInToAnotherAccount.button'),
        subtitle: t('common.appMenu.logInToAnotherAccount.subtitle'),
        onClickExtra: handleLoginToAnotherAccount,
        handleClose,
        icon: <AddAccount />,
        condition: gaFlags?.switchAccounts && !isMobile()
      },
      {
        id: 'header-menu-logout',
        label: t('common.appMenu.logOut'),
        variant: 'button',
        handleClose,
        onClickExtra: () => dispatch(Actions.requestSignOut())
      }
    ],
    [
      t,
      handleClose,
      permissions,
      planLimits,
      hasDraftOrder,
      openOrderScopesModal,
      grinAssetsLink,
      gaFlags,
      handleLoginToAnotherAccount,
      dispatch
    ]
  )

  return (
    <Fragment>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={isOpen}
        onClose={handleClose}
        classes={{ paper: classes.menu }}
      >
        {appMenuActions.map(
          (action, index) => action.condition !== false && <AppProfileMenuItem key={index} {...action} />
        )}
        {gaFlags?.switchAccounts && !isMobile() && <AuthProfiles className={classes.appProfiles} />}
      </Menu>
    </Fragment>
  )
}

export default AppProfileMenu
