import React, { useCallback } from 'react'
import { Grid } from '@material-ui/core'
import ScanViewTileItem from './ScanViewTileItem'
import { isMobile } from 'utils/mobileUtils'
import { removeWhere } from 'utils/arrayUtils'

const ScanViewTiles = ({
  options = [],
  selectedOptionIndex,
  onOptionSelect = () => {},
  direction = 'row',
  xs = 2,
  optionMediaClassName,
  selectedOptionClassName,
  unselectedOptionClassName,
  withPosesSelection = false,
  selectedPoses = [],
  setSelectedPoses = () => {},
  onPoseChecked = ({ pose }) => {},
  onPoseUnchecked = ({ pose }) => {}
}) => {
  const handlePoseSelectionChanged = useCallback(
    ({ pose, value }) => {
      if (selectedPoses.includes(pose)) {
        setSelectedPoses(removeWhere(selectedPoses, p => p === pose))
        onPoseUnchecked({ pose })
      } else {
        setSelectedPoses([...selectedPoses, pose])
        onPoseChecked({ pose })
      }
    },
    [setSelectedPoses, selectedPoses, onPoseChecked, onPoseUnchecked]
  )

  return (
    <Grid container direction={direction} justifyContent="space-between" alignItems="center">
      {options.map((option, i) => (
        <Grid item key={`scan-view-tile-${i}`} xs={xs} id={`scan-summary-thumbnail-${i}`}>
          <ScanViewTileItem
            mediaType={option.type}
            src={option.src}
            onClick={() => onOptionSelect(i)}
            isSelected={i === selectedOptionIndex}
            mediaProps={{
              autoPlay: isMobile()
            }}
            optionMediaClassName={optionMediaClassName}
            selectedOptionClassName={selectedOptionClassName}
            unselectedOptionClassName={unselectedOptionClassName}
            withCheckbox={withPosesSelection && option.type === 'img'}
            isChecked={selectedPoses.includes(option.pose)}
            setIsChecked={value => handlePoseSelectionChanged({ pose: option.pose, value })}
          />
        </Grid>
      ))}
    </Grid>
  )
}

export default React.memo(ScanViewTiles)
