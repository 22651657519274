import { makeStyles, Backdrop } from '@material-ui/core'
import { Auth } from 'aws-amplify'
import WebappOverlayLoader from 'resources/webapp-overlay-loader.gif'
import React, { Fragment, useCallback, useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, useHistory, useLocation } from 'react-router-dom'
import Actions from 'actions'
import ROUTES from 'consts/routesConsts'
import Patient from './Patient'
import PatientsList from './PatientsList/PatientsList'
import { isOnFreePlan } from 'utils/billingUtils'
import ChatEmptyState from './Chat/ChatEmptyState/ChatEmptyState'
import { Chevron } from '../common/icons'
import PatientsFilter from '../Search/PatientsFilter'
import useParsedFilters from 'hooks/useParsedFilters'
import useBroadcast from 'hooks/useBroadcast'
import usePatientsFilter from 'components/Search/hooks/usePatientsFilter'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    overflowY: 'hidden',
    overflowX: 'hidden',
    flex: 1,
    height: ({ isTrial }) => (isTrial ? 'calc(100% - 80px - 64px)' : 'calc(100% - 80px)')
  },
  loader: {
    zIndex: 9999,
    backgroundColor: '#f5f5f590',
    color: 'var(--border-color-5)'
  },
  patientsListContainer: {
    width: ({ expanded }) => (expanded ? '20%' : '40px'),
    minWidth: ({ expanded }) => (expanded ? 'var(--patients-list-min-width)' : '40px'),
    maxWidth: 'var(--patients-list-max-width)',
    // 82px is the App header, fixes new Safari scrolling issue
    maxHeight: 'calc(100vh - 82px)',
    borderRight: '1px solid var(--border-color-3)',
    position: 'relative',
    left: 0,
    top: 0,
    transition: '.2s',
    '& > div': {
      transition: '.2s',
      opacity: ({ expanded }) => (expanded ? 1 : 0),
      pointerEvents: ({ expanded }) => (expanded ? 'auto' : 'none')
    }
  },
  patientsPageBody: {
    height: '100%',
    width: ({ expanded }) => `calc(100% - ${expanded ? 'var(--patients-list-min-width)' : '40px'})`,
    maxWidth: ({ expanded }) => `calc(100% - ${expanded ? 'var(--patients-list-min-width)' : '40px'})`
  },
  collapseBtn: {
    position: 'absolute',
    right: 0,
    top: 35,
    width: 35,
    height: 35,
    transform: 'translate(50%, -50%)',
    cursor: 'pointer',
    zIndex: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    border: '1px solid var(--border-color-3)',
    backgroundColor: 'var(--bg-color-10)'
  },
  collapseIcon: {
    transform: ({ expanded }) => (expanded ? 'rotate(90deg)' : 'rotate(270deg)')
  },
  collapseBtnNew: {
    position: 'absolute',
    right: 0,
    top: 44,
    width: 33,
    height: 33,
    transform: 'translate(50%, -50%)',
    cursor: 'pointer',
    zIndex: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    backgroundColor: 'var(--bg-color-10)'
  },
  collapseBtnSpanNew: {
    width: 33,
    height: 33,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    border: '1px solid var(--border-color-21)'
  },
  collapseIconNew: {
    transform: ({ expanded }) => (expanded ? '' : 'rotate(180deg)')
  }
}))

const Patients = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { pathname } = useLocation()

  const { setDoctorFilter, patientsSearchFilter, setPatientsSearchFilter } = usePatientsFilter()

  const { pinnedPatient, overlayLoader, filter } = useSelector(state => state.chatReducer)
  const { isFetchingStaticData } = useSelector(state => state.appReducer)

  const expanded = useSelector(state => state.appReducer.isPatientsListExpanded)
  const userPlan = useSelector(state => state.practiceReducer.billing.grinPlan)
  const doctor = useSelector(state => state.profileReducer.doctor)
  const isWelcomeDoctorModalOpen = useSelector(state => state.profileReducer.showWelcomePopup)
  const { showBroadcastState, isModeOn: isBroadcastModeOn } = useBroadcast()

  const patientsTagsFilter = useMemo(() => filter?.tagsFilter, [filter])
  const showAppLoader = useMemo(() => isFetchingStaticData || overlayLoader, [isFetchingStaticData, overlayLoader])

  const { isFilterActivated } = useParsedFilters(patientsTagsFilter)

  const [isFilterOpen, setIsFilterOpen] = useState(null) //have to use null as initial value because we need to skip initial renders

  const classes = useStyles({ isTrial: isOnFreePlan(userPlan), expanded })

  useEffect(() => {
    const checkIfSignedIn = async () => {
      try {
        await Auth.currentAuthenticatedUser()
      } catch (err) {
        history.push(ROUTES.WELCOME)
      }
    }

    checkIfSignedIn()
  }, [history])

  const handleClickSetExpanded = useCallback(() => {
    dispatch(Actions.setPatientsListExpanded(!expanded))
  }, [dispatch, expanded])

  useEffect(() => {
    if (pathname === ROUTES.PATIENTS && pinnedPatient) {
      dispatch(Actions.unpinChatRoom())
    }
  }, [dispatch, pathname, pinnedPatient])

  const renderPatientsPage = () => (
    <Fragment>
      <PatientsFilter
        isFilterOpen={isFilterOpen}
        setIsFilterOpen={setIsFilterOpen}
        isFilterActivated={isFilterActivated}
        freeTextFilter={patientsSearchFilter}
        onFreeTextFilterChange={setPatientsSearchFilter}
      />
      <div className={classes.container}>
        <div className={classes.patientsListContainer}>
          {doctor.id && <PatientsList setDoctorFilter={setDoctorFilter} patientsSearchFilter={patientsSearchFilter} />}
          <span className={classes.collapseBtnNew} onClick={handleClickSetExpanded}>
            <div className={classes.collapseBtnSpanNew}>
              <Chevron className={classes.collapseIconNew} />
            </div>
          </span>
        </div>
        <div className={classes.patientsPageBody}>
          <Route path={ROUTES.PATIENTS} exact>
            {doctor.id && !showBroadcastState && (
              <ChatEmptyState name={doctor?.name} showNoPatientSelected={isBroadcastModeOn} />
            )}
          </Route>

          {isBroadcastModeOn && (showBroadcastState ? <Patient /> : <ChatEmptyState showNoPatientSelected />)}
          {!isBroadcastModeOn && (
            <Route path={`${ROUTES.PATIENTS}/:patientId`} exact>
              <Patient isFilterOpen={isFilterOpen} />
            </Route>
          )}
        </div>
      </div>
    </Fragment>
  )

  const renderLoader = () => (
    <Backdrop className={classes.loader} open={showAppLoader}>
      <img src={WebappOverlayLoader} alt="Loader" />
    </Backdrop>
  )

  if (showAppLoader) {
    return renderLoader()
  } else {
    return isWelcomeDoctorModalOpen ? null : renderPatientsPage()
  }
}

export default React.memo(Patients)
