import { makeStyles } from '@material-ui/styles'
import Actions from 'actions'
import SortArrows from 'components/common/icons/SortArrows'
import { SortDirections } from 'consts/filterConsts'
import useQueryParams from 'hooks/useQueryParams'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { trackEvent } from 'utils/analyticsUtils'

const useStyles = ({ width }) =>
  makeStyles(theme => ({
    container: {
      margin: '0px 10px'
    },
    button: {
      width: 220,
      height: 45,
      display: 'flex',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      background: 'white',
      borderRadius: 30,
      outline: 'none',
      border: 'none',
      fontSize: 14,
      fontFamily: 'Dazzed',
      fontWeight: 400,
      color: '#898484',
      boxShadow: '0px 2px 30px rgba(0, 0, 0, 0.1)',
      cursor: 'pointer'
    }
  }))({ width })

const PatientsSort = () => {
  const classes = useStyles({})
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { addQueryParam, queryParams } = useQueryParams()

  const queryParamsSortDirection = useMemo(() => queryParams.sortDirection, [queryParams])
  const direction = useSelector(state => state.chatReducer.sort.direction)

  const handleChangeSortDirection = useCallback(
    newSortDirection => {
      trackEvent('Patients Sort - changeSortDirection', { newSortDirection })
      addQueryParam({ sortDirection: newSortDirection })
      dispatch(Actions.changePatientsSortDirection(newSortDirection))
    },
    [dispatch, addQueryParam]
  )

  useEffect(() => {
    const newSortDirection = queryParamsSortDirection || SortDirections.Desc

    if (!queryParamsSortDirection) {
      handleChangeSortDirection(newSortDirection)
    }
  }, [dispatch, queryParamsSortDirection, handleChangeSortDirection])

  return (
    <div className={classes.container}>
      <button
        className={classes.button}
        onClick={() =>
          handleChangeSortDirection(
            queryParamsSortDirection === SortDirections.Desc ? SortDirections.Asc : SortDirections.Desc
          )
        }
      >
        <SortArrows style={{ transform: `rotate(${direction === SortDirections.Asc ? '0' : '180'}deg)` }} />
        <span>{t(`pages.patients.patientsList.patientSort.${direction}Button`)}</span>
      </button>
    </div>
  )
}

export default PatientsSort
