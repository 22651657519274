import * as React from 'react'

export default () => (
  <svg width="34" height="24" viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="1" width="24" height="16" rx="2" stroke="#CED0D4" strokeWidth="1.5" />
    <path
      d="M1 10L7.29289 3.70711C7.68342 3.31658 8.31658 3.31658 8.70711 3.70711L14.2929 9.29289C14.6834 9.68342 15.3166 9.68342 15.7071 9.29289L18.2929 6.70711C18.6834 6.31658 19.3166 6.31658 19.7071 6.70711L25 12"
      stroke="#CED0D4"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <circle cx="25" cy="15" r="9" fill="#F2F3F5" />
    <circle cx="25" cy="15" r="6" fill="#F2F3F5" stroke="#CED0D4" strokeWidth="1.5" />
    <path d="M29 11L21 19" stroke="#CED0D4" strokeWidth="1.5" />
  </svg>
)
