import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { ButtonBase } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'

const useStyles = makeStyles(theme => ({
  buttonRoot: {
    justifyContent: 'flex-start'
  }
}))

const ClearFilterButton = ({ onClick = () => {}, className = '' }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <ButtonBase onClick={onClick} className={[classes.buttonRoot, className].join(' ')}>
      <DazzedParagraph14 textAlign="left" strong>
        {t('pages.patients.patientsList.searchByDoctor.allPractices')}
      </DazzedParagraph14>
    </ButtonBase>
  )
}

export default ClearFilterButton
