import React, { useCallback, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import { GrinSwooshLogo, UnavailableCloud } from './common/icons'
import DazzedParagraph16 from './common/text/DazzedParagraph16'
import { useTranslation } from 'react-i18next'
import LinkButton from './common/buttons/LinkButton'
import { trackEvent } from 'utils/analyticsUtils'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    padding: 30
  },
  text: {
    whiteSpace: 'pre-line'
  },
  contactLink: {
    textDecoration: 'underline',
    fontSize: 16,
    marginLeft: 4
  }
}))

const ServiceUnavailable = ({ contentText, needHelpText, showIcon = true, ...props }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleContactSupportClicked = useCallback(() => {
    trackEvent('Platform unavailable page - Contact support clicked')
    window.open('mailto:support@get-grin.com', '_blank')
  }, [])

  useEffect(() => {
    trackEvent('Platform unavailable page - navigated')
  }, [dispatch])

  return (
    <Grid container direction="column" alignItems="center" className={classes.container}>
      <Grid item xs={1}>
        <GrinSwooshLogo />
      </Grid>
      <Grid item xs={7}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{ height: '100%' }}
          spacing={3}
        >
          <Grid item>{showIcon && <UnavailableCloud />}</Grid>
          <Grid item>
            <DazzedParagraph16 textAlign="center" className={classes.text}>
              {contentText ? contentText : t('pages.unavailable.content')}
            </DazzedParagraph16>
          </Grid>
          <Grid item>
            <Grid container alignItems="center">
              <Grid item>
                <DazzedParagraph16>{needHelpText ? needHelpText : t('pages.unavailable.emergency')} </DazzedParagraph16>
              </Grid>
              <Grid item>
                <LinkButton
                  label={t('pages.unavailable.contact')}
                  onClick={handleContactSupportClicked}
                  labelClassName={classes.contactLink}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ServiceUnavailable
