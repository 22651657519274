import React, { useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import BaseModal from 'components/common/modals/BaseModal'
import FormInput from 'components/common/FormInput'

const useStyles = makeStyles(theme => ({
  popupBody: {
    padding: '0 30px'
  }
}))

const EditDisplayNameModal = ({ displayName, isOpen, setIsOpen = () => {}, onSaveClicked = () => {} }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [newDisplayName, setNewDisplayName] = useState(displayName)

  const handleSaveClicked = useCallback(() => {
    onSaveClicked(newDisplayName)
    setIsOpen(false)
  }, [setIsOpen, onSaveClicked, newDisplayName])

  return (
    <BaseModal
      variant="alert"
      open={isOpen}
      title={t('common.appMenu.authProfiles.editDisplayNamePopup.title')}
      handleClose={() => setIsOpen(false)}
      primaryLabel={t('general.saveChanges')}
      onPrimaryBtnClick={handleSaveClicked}
      secondaryLabel={t('general.cancel')}
      onSecondaryBtnClick={() => setIsOpen(false)}
      largerPrimaryButton
      isPrimaryDisabled={!newDisplayName?.trim()}
    >
      <div className={classes.popupBody}>
        <FormInput
          style={{ bright: true, thick: true }}
          value={newDisplayName || ''}
          setValue={setNewDisplayName}
          title={t('common.appMenu.authProfiles.editDisplayNamePopup.inputLabel')}
          autoFocus
          onKeyDown={e => e.stopPropagation()}
        />
      </div>
    </BaseModal>
  )
}

export default EditDisplayNameModal
