import { formatDynamicFields } from 'utils/graphqlUtils'

const messageFields = `
  id
  grinUserId
  roomId
  uploadingDate
  owner
  members
  a_readers
  a_writers
  type
  content
  readBy
  pictures {
    bucket
    region
    key
  }
  href
  hrefLabel
  promotionId
  promotionData
  templateId
  metadata
  isTransferred
  isBroadcast
  entityRelatedId
  isDeleted
  grinScan {
    id
    trackingStatus
    trackingDetails
    metadata
    video {
      bucket
      region
      key
    }
  }
  lastEditedAt
  createdAt
  updatedAt
  _version
  _deleted
  _lastChangedAt
`

export const chat = {
  message: messageFields,
  messageWithAudit: `${messageFields} audit`
}

export const getMessage = fields => /* GraphQL */ `
  query GetMessage(
    $id: ID!
  ) {
    getMessage(
      id: $id  
    ) {
      ${formatDynamicFields(fields)}
    }
  }
`
