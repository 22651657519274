import { makeStyles } from '@material-ui/core'
import { TreatmentTypes } from 'consts/treatmentConsts'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import usePreviouslyUploadedStls from './usePreviouslyUploadedStls'
import useTxTracker from 'components/Patients/TreatmentTracker/useTxTracker'

const useStyles = makeStyles({
  uploadedSTLs: {
    background: '#F2F3F5',
    height: '100%',
    marginTop: 10,
    borderRadius: 10,
    padding: 20
  },
  stlsRange: {
    lineHeight: '30px'
  }
})

const PreviouslyUploadedStls = ({ stls, treatmentType, provider }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { calculateTreatmentType } = useTxTracker()

  const calculatedTreatmentType = calculateTreatmentType({ treatmentType, provider })

  const { generateAlignersUploadedStls, generateBracesUploadedStls } = usePreviouslyUploadedStls()

  const upperStls = useMemo(() => {
    if (calculatedTreatmentType === TreatmentTypes.Aligners) {
      return generateAlignersUploadedStls(stls?.upper)
    }

    return generateBracesUploadedStls(stls?.upper)
  }, [generateAlignersUploadedStls, generateBracesUploadedStls, stls, calculatedTreatmentType])

  const lowerStls = useMemo(() => {
    if (calculatedTreatmentType === TreatmentTypes.Aligners) {
      return generateAlignersUploadedStls(stls?.lower)
    }

    return generateBracesUploadedStls(stls?.lower)
  }, [generateAlignersUploadedStls, generateBracesUploadedStls, stls, calculatedTreatmentType])

  return (
    <div className={classes.uploadedSTLs}>
      {stls ? (
        <>
          <div className={classes.stlsRange}>
            {t('dialogs.patientInfo.txTrackerSetup.upperLabel')}: {upperStls}
          </div>
          <div className={classes.stlsRange}>
            {t('dialogs.patientInfo.txTrackerSetup.lowerLabel')}: {lowerStls}
          </div>
        </>
      ) : (
        <div>{t('dialogs.patientInfo.txTrackerSetup.noStls')}</div>
      )}
    </div>
  )
}
export default PreviouslyUploadedStls
