import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import CollapsibleCategory from '../CollapsibleCategory'
import AdministrativeInfo from 'components/common/icons/PracticeGuidelines/AdministrativeInfo'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import GrinGuruForm from './GrinGuruForm'
import GeneralPracticeInfo from './GeneralPracticeInfo'
import { Grid } from '@material-ui/core'
import CategoryNote from '../CategoryNote'

const useStyles = makeStyles(theme => ({
  administrativeInfoContainer: {
    marginTop: 20,
    maxWidth: 650
  },
  notesContainer: {
    marginTop: '0px!important'
  },
  notesTitle: {
    fontWeight: '500!important'
  }
}))

const AdministrativeInfoCategory = ({
  id,
  isCollapsed,
  onCollapseClick,
  administrativeInfo = {},
  setAdministrativeInfo = () => {},
  errors = {}
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <CollapsibleCategory
      id={id}
      title={t('pages.accountSettings.practiceGuidelines.administrativeInfo.title')}
      icon={<AdministrativeInfo />}
      isCollapsed={isCollapsed}
      onCollapseClick={() => onCollapseClick({ categoryKey: id })}
      withSwitch={false}
      errorMessage={errors.general}
    >
      <Grid container direction="column" spacing={4} className={classes.administrativeInfoContainer}>
        <Grid item>
          <DazzedParagraph14 strong>
            {t('pages.accountSettings.practiceGuidelines.administrativeInfo.description')}
          </DazzedParagraph14>
        </Grid>
        <Grid item>
          <GrinGuruForm
            values={administrativeInfo.grinGuruDetails}
            errors={errors.grinGuruDetails}
            setValues={data =>
              setAdministrativeInfo({
                ...administrativeInfo,
                grinGuruDetails: data
              })
            }
          />
        </Grid>
        <Grid item>
          <GeneralPracticeInfo
            values={administrativeInfo.generalPracticeInfo}
            errors={errors.generalPracticeInfo}
            setValues={data =>
              setAdministrativeInfo({
                ...administrativeInfo,
                generalPracticeInfo: data
              })
            }
          />
        </Grid>
        <Grid item>
          <CategoryNote
            containerClassName={classes.notesContainer}
            titleClassName={classes.notesTitle}
            customTitle={t('pages.accountSettings.practiceGuidelines.administrativeInfo.additionalNotes')}
            note={administrativeInfo.note || ''}
            onChange={value =>
              setAdministrativeInfo({
                ...administrativeInfo,
                note: value
              })
            }
          />
        </Grid>
      </Grid>
    </CollapsibleCategory>
  )
}

export default AdministrativeInfoCategory
