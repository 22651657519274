const FETCH_PATIENT_TIMELINE_ITEMS = 'FETCH_PATIENT_TIMELINE_ITEMS'
const FETCH_PATIENT_TIMELINE_ITEMS_RECEIVED = 'FETCH_PATIENT_TIMELINE_ITEMS_RECEIVED'
const FETCH_PATIENT_TIMELINE_ITEMS_FAILED = 'FETCH_PATIENT_TIMELINE_ITEMS_FAILED'
const SELECT_TIMELINE_ITEM = 'SELECT_TIMELINE_ITEM'
const FETCH_TIMELINE_ITEM = 'FETCH_TIMELINE_ITEM'
const FETCH_TIMELINE_ITEM_RECEIVED = 'FETCH_TIMELINE_ITEM_RECEIVED'
const FETCH_TIMELINE_ITEM_FAILED = 'FETCH_TIMELINE_ITEM_FAILED'
const NEW_SCAN_REVIEW_RECEIVED = 'NEW_SCAN_REVIEW_RECEIVED'
const TIMELINE_ITEM_LIVE_UPDATE_RECEIVED = 'TIMELINE_ITEM_LIVE_UPDATE_RECEIVED'
const TIMELINE_SWITCH_SCAN_WITH_ALIGNERS = 'TIMELINE_SWITCH_SCAN_WITH_ALIGNERS'
const TIMELINE_SWITCH_SCAN_WITH_ALIGNERS_RECEIVED = 'TIMELINE_SWITCH_SCAN_WITH_ALIGNERS_RECEIVED'
const TIMELINE_SWITCH_SCAN_WITH_ALIGNERS_FAILED = 'TIMELINE_SWITCH_SCAN_WITH_ALIGNERS_FAILED'
const TIMELINE_FETCH_ORAL_RECORDS = 'TIMELINE_FETCH_ORAL_RECORDS'
const TIMELINE_FETCH_ORAL_RECORDS_RECEIVED = 'TIMELINE_FETCH_ORAL_RECORDS_RECEIVED'
const TIMELINE_FETCH_ORAL_RECORDS_FAILED = 'TIMELINE_FETCH_ORAL_RECORDS_FAILED'
const SELECT_TIMELINE_ITEM_BY_SCAN_ID = 'SELECT_TIMELINE_ITEM_BY_SCAN_ID'
const SET_MINI_TIMELINE_THEME = 'SET_MINI_TIMELINE_THEME'

const fetchPatientTimelineItems = payload => ({
  type: FETCH_PATIENT_TIMELINE_ITEMS,
  payload
})

const fetchPatientTimelineItemsReceived = payload => ({
  type: FETCH_PATIENT_TIMELINE_ITEMS_RECEIVED,
  payload
})

const fetchPatientTimelineItemsFailed = payload => ({
  type: FETCH_PATIENT_TIMELINE_ITEMS_FAILED,
  payload
})

const selectTimelineItem = payload => ({
  type: SELECT_TIMELINE_ITEM,
  payload
})

const fetchTimelineItem = payload => ({
  type: FETCH_TIMELINE_ITEM,
  payload
})

const fetchTimelineItemReceived = payload => ({
  type: FETCH_TIMELINE_ITEM_RECEIVED,
  payload
})

const fetchTimelineItemFailed = payload => ({
  type: FETCH_TIMELINE_ITEM_FAILED,
  payload
})

const newScanReviewReceived = payload => ({
  type: NEW_SCAN_REVIEW_RECEIVED,
  payload
})

const timelineItemLiveUpdateReceived = payload => ({
  type: TIMELINE_ITEM_LIVE_UPDATE_RECEIVED,
  payload
})

const timelineSwitchScanWithAligners = payload => ({
  type: TIMELINE_SWITCH_SCAN_WITH_ALIGNERS,
  payload
})

const timelineSwitchScanWithAlignersReceived = payload => ({
  type: TIMELINE_SWITCH_SCAN_WITH_ALIGNERS_RECEIVED,
  payload
})

const timelineSwitchScanWithAlignersFailed = payload => ({
  type: TIMELINE_SWITCH_SCAN_WITH_ALIGNERS_FAILED,
  payload
})

const timelineFetchOralRecords = payload => ({
  type: TIMELINE_FETCH_ORAL_RECORDS,
  payload
})

const timelineFetchOralRecordsReceived = payload => ({
  type: TIMELINE_FETCH_ORAL_RECORDS_RECEIVED,
  payload
})

const timelineFetchOralRecordsFailed = payload => ({
  type: TIMELINE_FETCH_ORAL_RECORDS_FAILED,
  payload
})

const selectTimelineItemByScanId = payload => ({
  type: SELECT_TIMELINE_ITEM_BY_SCAN_ID,
  payload
})

const setMiniTimelineTheme = payload => ({
  type: SET_MINI_TIMELINE_THEME,
  payload
})

export default {
  FETCH_PATIENT_TIMELINE_ITEMS,
  FETCH_PATIENT_TIMELINE_ITEMS_RECEIVED,
  FETCH_PATIENT_TIMELINE_ITEMS_FAILED,
  SELECT_TIMELINE_ITEM,
  FETCH_TIMELINE_ITEM,
  FETCH_TIMELINE_ITEM_RECEIVED,
  FETCH_TIMELINE_ITEM_FAILED,
  NEW_SCAN_REVIEW_RECEIVED,
  TIMELINE_ITEM_LIVE_UPDATE_RECEIVED,
  TIMELINE_SWITCH_SCAN_WITH_ALIGNERS,
  TIMELINE_SWITCH_SCAN_WITH_ALIGNERS_RECEIVED,
  TIMELINE_SWITCH_SCAN_WITH_ALIGNERS_FAILED,
  TIMELINE_FETCH_ORAL_RECORDS,
  TIMELINE_FETCH_ORAL_RECORDS_RECEIVED,
  TIMELINE_FETCH_ORAL_RECORDS_FAILED,
  SELECT_TIMELINE_ITEM_BY_SCAN_ID,
  SET_MINI_TIMELINE_THEME,
  fetchPatientTimelineItems,
  fetchPatientTimelineItemsReceived,
  fetchPatientTimelineItemsFailed,
  selectTimelineItem,
  fetchTimelineItem,
  fetchTimelineItemReceived,
  fetchTimelineItemFailed,
  newScanReviewReceived,
  timelineItemLiveUpdateReceived,
  timelineSwitchScanWithAligners,
  timelineSwitchScanWithAlignersReceived,
  timelineSwitchScanWithAlignersFailed,
  timelineFetchOralRecords,
  timelineFetchOralRecordsReceived,
  timelineFetchOralRecordsFailed,
  selectTimelineItemByScanId,
  setMiniTimelineTheme
}
