import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Actions from 'actions'
import PrimaryButton from 'components/common/buttons/PrimaryButton'
import SecondaryButton from 'components/common/buttons/SecondaryButton'
import BaseModal from 'components/common/modals/BaseModal'
import moment from 'moment'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { trackEvent } from 'utils/analyticsUtils'
import { API } from 'aws-amplify'
import { Copy } from 'components/common/icons'
import { logError, logInfo } from 'utils/logUtils'

const useStyles = makeStyles({
  root: {
    height: '100%',
    maxWidth: '100%',
    padding: 32,
    alignItems: 'space-between'
  },
  mainContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  buttonsContainer: {
    display: 'flex'
  }
})

const TxTrackerAdminModal = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const isOpen = useSelector(state => state.txTrackerReducer.txTrackerAdminModal.isOpen)
  const patient = useSelector(state => state.patientsReducer.patient)
  const grinScans = useSelector(state => state.treatmentReducer.grinScans)

  const [isLoading, setIsLoading] = useState(false)

  const handleClose = useCallback(() => {
    dispatch(Actions.toggleTxTrackerModalOpen(false))
  }, [dispatch])

  const rerunAndDelete = useCallback(() => {
    setIsLoading(true)
    trackEvent('Tx Tracker Admin - rerun and delete all clicked', { patientId: patient.id })
    API.post('grinServerlessApi', '/treatment-tracker/v1/ops/rerunAndDelete', {
      body: {
        patientId: patient.id
      }
    })
      .then(r => {
        setIsLoading(false)
        dispatch(Actions.setTreatmentTrackerModalOpen({ isOpen: false }))
        logInfo('Rerun & Delete succeeded', { patientId: patient.id, response: r })
      })
      .catch(ex => {
        logError('Failed to rerun & delete', { patientId: patient.id, error: ex })
        setIsLoading(false)
      })
  }, [patient, dispatch])

  const initTxTracker = useCallback(() => {
    setIsLoading(true)
    trackEvent('Tx Tracker Admin - init/rerun clicked', { patientId: patient.id })
    API.post('grinServerlessApi', '/treatment-tracker/v1/ops/init', {
      body: {
        patientId: patient.id
      }
    })
      .then(r => {
        setIsLoading(false)
        dispatch(Actions.setTreatmentTrackerModalOpen({ isOpen: false }))
        logInfo('Init/rerun succeeded', { patientId: patient.id, response: r })
      })
      .catch(ex => {
        logError('Failed to init/rerun', { patientId: patient.id, error: ex })
        setIsLoading(false)
      })
  }, [patient, dispatch])

  const deleteAll = useCallback(() => {
    setIsLoading(true)
    trackEvent('Tx Tracker Admin - delete all clicked', { patientId: patient.id })
    API.post('grinServerlessApi', '/treatment-tracker/v1/ops/deleteAll', {
      body: {
        patientId: patient.id
      }
    })
      .then(r => {
        setIsLoading(false)
        dispatch(Actions.setTreatmentTrackerModalOpen({ isOpen: false }))
        logInfo('Delete All succeeded', { patientId: patient.id, response: r })
      })
      .catch(ex => {
        logError('Failed to deleteAll', { patientId: patient.id, error: ex })
        setIsLoading(false)
      })
  }, [patient, dispatch])

  return (
    <BaseModal
      open={isOpen}
      handleClose={handleClose}
      className={classes.root}
      contentClassName={classes.dialogContent}
      disableEnforceFocus
      title={t('dialogs.treatmentTracker.title')}
      titleClassName={classes.title}
    >
      <Grid className={classes.mainContent}>
        <Grid container direction="row">
          <Grid item>Patient Name:</Grid>
          <Grid item>{patient?.details?.name}</Grid>
          <Copy
            style={{ cursor: 'pointer' }}
            onClick={() => {
              alert('PatientId copied')
              navigator.clipboard.writeText(patient?.id)
            }}
          />
        </Grid>
        <br />
        {grinScans?.filter(s => !!s.treatmentTrackerStatus).length === 0 ? (
          <>No Scans With Tx Tracker Status</>
        ) : (
          grinScans
            ?.filter(s => !!s.treatmentTrackerStatus)
            .sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))
            .map(s => (
              <Grid container direction="row" key={s.createdAt}>
                <Grid item>Aligner:</Grid>
                <Grid item>
                  {s.applianceIndex} ({moment(s.createdAt).format('LL')}), Running date:
                  {moment(JSON.parse(s.treatmentTrackerData || '{}').startedAt).format('LLL')} -{' '}
                  {s.treatmentTrackerStatus} {s.withAligner ? ' - Scan is marked With Aligner' : ''}
                  <Copy
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      alert('ScanId copied')
                      navigator.clipboard.writeText(s.id)
                    }}
                  />
                </Grid>
              </Grid>
            ))
        )}
        <br />
        <div className={classes.buttonsContainer}>
          <PrimaryButton isLoading={isLoading} label="Rerun + delete all" onClick={rerunAndDelete} />
          <SecondaryButton isLoading={isLoading} label="Init/Rerun" onClick={initTxTracker} />
          <SecondaryButton isLoading={isLoading} label="Just delete all" onClick={deleteAll} />
        </div>
      </Grid>
    </BaseModal>
  )
}

export default TxTrackerAdminModal
