import React, { useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import DazzedParagraph14 from 'components/common/text/DazzedParagraph14'
import { useTranslation } from 'react-i18next'
import { LiveCallsPlusIcon } from 'components/common/icons'
import PrimaryButton from 'components/common/buttons/PrimaryButton'
import LiveCallModal from './LiveCallModal'
import { trackEvent } from 'utils/analyticsUtils'

const useStyles = makeStyles(theme => ({
  liveCallsRoot: {
    padding: 20,
    height: '100%',
    backgroundColor: 'white'
  },
  instantMeetingBtn: {
    color: 'var(--text-color-16)',
    border: '1px solid var(--text-color-16)'
  }
}))

const LiveCalls = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [isLiveCallModalOpen, setIsLiveCallModalOpen] = useState(false)

  const handleStartInstantMeeting = useCallback(() => {
    trackEvent('Live call - Start instant meeting clicked')
    setIsLiveCallModalOpen(true)
  }, [])

  return (
    <>
      <Grid container direction="column" wrap="nowrap" className={classes.liveCallsRoot}>
        <Grid item xs={12}>
          <DazzedParagraph14 bold color="var(--text-color-18)">
            {t('pages.patients.patientSwitchableView.liveCalls.title')}
          </DazzedParagraph14>
        </Grid>
        <Grid item xs={12} style={{ height: '100%' }}>
          <Grid container direction="column" alignItems="center" justifyContent="center">
            <Grid item>
              <PrimaryButton
                variant="outlined"
                fontWeight={500}
                width={260}
                height={50}
                className={classes.instantMeetingBtn}
                icon={<LiveCallsPlusIcon />}
                label={t('pages.patients.patientSwitchableView.liveCalls.buttons.instantMeetingLabel')}
                onClick={handleStartInstantMeeting}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {isLiveCallModalOpen && <LiveCallModal isOpen={isLiveCallModalOpen} setIsOpen={setIsLiveCallModalOpen} />}
    </>
  )
}

export default LiveCalls
