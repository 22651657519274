import React from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  errorMessage: {
    opacity: 0,
    color: ({ bright }) => (bright ? 'red' : 'var(--text-color-10)'),
    marginTop: '2px',
    marginBottom: '5px',
    fontWeight: 500,
    height: '17px',
    fontSize: '12px',
    transition: 'opacity .25s ease',
    fontFamily: 'Dazzed',
    '&.active': {
      opacity: 1
    },
    '&.center': {
      textAlign: 'center'
    },
    '&.small': {
      fontSize: 12
    },
    '&.medium': {
      fontSize: 16
    }
  }
}))

const ErrorMessage = ({ text, active, center = false, dark = false, size = 'small' }) => {
  const classes = useStyles({ bright: !dark })

  return <div className={[classes.errorMessage, active && 'active', center && 'center', size].join(' ')}>{text}</div>
}

export default ErrorMessage
