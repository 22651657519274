import { useMemo } from 'react'
import { useAssignPatient } from '../components/Patients/AssignPatient/useAssignPatient'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

export default ({ note = '', selectedPracticeMembersIds = [] }) => {
  const { t } = useTranslation()

  const { id: currentDoctorId } = useSelector(state => state.profileReducer.doctor)
  const { practiceMembersSelectOptions } = useAssignPatient({
    withUnassignOption: false
  })

  const selectedMembersNames = useMemo(
    () =>
      practiceMembersSelectOptions
        .filter(member => selectedPracticeMembersIds.includes(member.value))
        .map(member => member.label),
    [practiceMembersSelectOptions, selectedPracticeMembersIds]
  )

  const noteWithMentions = useMemo(() => {
    if (note && selectedPracticeMembersIds.length > 0) {
      const mentionsString = selectedMembersNames
        .reduce((finalString, memberName) => `${finalString} @${memberName}`, '')
        .trim()

      return (
        <>
          <strong>{mentionsString}</strong> "{note}"
        </>
      )
    }

    return note
  }, [note, selectedMembersNames, selectedPracticeMembersIds.length])

  const isAwaitingCurrentMembersAttention = useMemo(() => {
    if (!selectedPracticeMembersIds?.length) {
      return true
    }

    return selectedPracticeMembersIds.includes(currentDoctorId)
  }, [currentDoctorId, selectedPracticeMembersIds])

  const selectedPracticeMembersTooltip = useMemo(() => {
    if (isAwaitingCurrentMembersAttention) {
      return ''
    } else if (!selectedMembersNames.length) {
      return t('dialogs.patientBriefQuickActions.specifiedForAnotherMember')
    }

    return t('dialogs.patientBriefQuickActions.disabledOverlayTooltip', {
      members: selectedMembersNames.join(', ')
    })
  }, [isAwaitingCurrentMembersAttention, selectedMembersNames, t])

  return {
    noteWithMentions,
    selectedMembersNames,
    selectedPracticeMembersTooltip,
    isAwaitingCurrentMembersAttention
  }
}
