import * as React from 'react'

export default ({ color = '#EB5757', ...props }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="12" cy="12" r="12" fill={color} />
      <path d="M10.8 5.6001H13.2V14.4001H10.8V5.6001Z" fill="white" />
      <path d="M10.8 16.8H13.2V19.2H10.8V16.8Z" fill="white" />
    </svg>
  )
}
