import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Tooltip from 'components/common/Tooltip'
import MessageOverlayInvalidScan from 'components/common/icons/gi/MessageOverlayInvalidScan'
import MessageOverlayNegativeHI from 'components/common/icons/gi/MessageOverlayNegativeHI'
import MessageOverlayPositiveHI from 'components/common/icons/gi/MessageOverlayPositiveHI'
import { ScanTrackingStatus, TrackingConditionsTypes, TreatmentTrackOptions } from 'consts/giConsts'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import UntrackedTeethList from './HI/UntrackedTeethList'
import ScanMessageTrackingTooltip from './ScanMessageTrackingTooltip'

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 8
  },
  brainIconContainer: {
    marginTop: 3,
    marginRight: 4
  },
  feedbackOverlay: {
    position: 'absolute',
    bottom: -10,
    left: 20
  }
}))

const ScanMessageTrackingStatus = ({ grinScanId, trackingStatus, trackingDetails }) => {
  const classes = useStyles()

  const patient = useSelector(state => state.patientsReducer.patient)
  const parsedTrackingDetails = useMemo(() => JSON.parse(trackingDetails || '{}'), [trackingDetails])
  const patientTrackingSettings = useMemo(
    () => JSON.parse(patient?.treatments?.items[0]?.giTrackSettings || '{}'),
    [patient]
  )

  const isInvalidScan = useMemo(
    () => parsedTrackingDetails?.conditions?.[TrackingConditionsTypes.InvalidScan],
    [parsedTrackingDetails]
  )

  return trackingStatus === ScanTrackingStatus.Pending ? (
    <Grid container direction="column" className={classes.container}>
      {patientTrackingSettings.untrackedTeeth?.length ? (
        <Grid item>
          <UntrackedTeethList teethList={patientTrackingSettings?.untrackedTeeth} />
        </Grid>
      ) : (
        <></>
      )}
    </Grid>
  ) : trackingStatus === ScanTrackingStatus.Submitted ? (
    <a href={`#message-scan-${grinScanId}`}>
      <div className={classes.feedbackOverlay}>
        <Tooltip
          placement="right"
          variant="light"
          value={
            <ScanMessageTrackingTooltip parsedTrackingDetails={parsedTrackingDetails} isInvalidScan={isInvalidScan} />
          }
        >
          {parsedTrackingDetails.trackStatus === TreatmentTrackOptions.OnTrack ? (
            <MessageOverlayPositiveHI />
          ) : isInvalidScan ? (
            <MessageOverlayInvalidScan />
          ) : (
            <MessageOverlayNegativeHI />
          )}
        </Tooltip>
      </div>
    </a>
  ) : (
    <></>
  )
}

export default ScanMessageTrackingStatus
